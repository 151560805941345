<template>
  <DropdownTooltip
    theme="info-tooltip"
    :distance="8"
    :triggers="['click']"
    :popperShowTriggers="['click']"
    placement="bottom-center"
  >
    <template #tooltip-label>
      <div class="is-flex">
        <span
          class="reference-component__link"
          :class="[{'is-disabled': user.enabled === false }]"
        >
          {{ text }}
        </span>
        <span
          v-if="isShowDetails"
          class="reference-component__desc"
        > ({{ user.email }})</span>
      </div>
    </template>
    <template #tooltip-content="{ hide }">
      <span
        v-click-outside="hide.bind()"
        class="reference-component__tooltip"
      >
        <Avatar
          :src="user?.pictureUrl"
          size="sm"
          class="reference-component__tooltip-avatar"
        />
        <span>
          <BaseSubHeading
            size="large"
            weight="400"
            class="reference-component__tooltip-username"
          >
            {{ user.username }}
          </BaseSubHeading>
          <BaseSubHeading
            size="large"
            weight="400"
            color="monochrome-05"
            class="reference-component__tooltip-username"
          >
            {{ user.teamRolesString }}
          </BaseSubHeading>
        </span>
      </span>
    </template>
  </DropdownTooltip>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import { Avatar } from "@/components/Avatar"
import { User } from "@/models"
import { Prop } from "vue-property-decorator"
import { directive as ClickOutside } from "click-outside-vue3"

const components = {
  DropdownTooltip,
  Avatar,
}

@Options({
  name: "UserReferenceTooltip",
  components,
  directives: {
    ClickOutside,
  },
})
export default class UserReferenceTooltip extends Vue {
  @Prop() readonly user: User
  @Prop() readonly text: any
  @Prop() readonly isShowDetails: boolean
}
</script>
