import { ReferenceExtension } from "@/plugins/extensions/Reference"
import AtSuggestion from "@/components/Reference/At/AtSuggestion"
import { InitComponent } from "@/components/Reference"

export const ReferenceAt = ReferenceExtension.extend({
  name: "referenceAt",
}).configure({
  suggestion: AtSuggestion,
  pasteRules: "@\\w+\\s\\w+", // @FirstName LastName
  component: InitComponent 
})

