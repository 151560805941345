import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@icon/close.svg'


const _hoisted_1 = { class: "toast" }
const _hoisted_2 = { class: "toast__content" }
const _hoisted_3 = {
  key: 1,
  class: "toast__message body-text body-text-medium-400"
}
const _hoisted_4 = { class: "body-text-medium-600" }
const _hoisted_5 = { class: "toast__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertText = _resolveComponent("AlertText")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["toast__card", [
          `toast__card-${_ctx.alert.type.toLowerCase()}`,
          _ctx.alert.timeout === 0 && 'toast__card--hidden',
          _ctx.alert.timeout === -1 && 'toast__card--hidden alert__message--removed',
        ]]),
        onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelAlertTimeout && _ctx.cancelAlertTimeout(...args))),
        onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.restartAlertTimeout && _ctx.restartAlertTimeout(...args)))
      }, [
        (_ctx.alert.content?.length)
          ? (_openBlock(), _createBlock(_component_AlertText, {
              key: 0,
              content: _ctx.alert.content
            }, null, 8, ["content"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.alert.name) + " ", 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.alert.title), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.alert.message), 1)
            ])),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "toast__close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlert')))
          }, [
            _createVNode(_component_icon, {
              data: _imports_0,
              fill: false,
              width: "1em",
              height: "1em",
              color: "#000000"
            })
          ])
        ])
      ], 34)
    ])
  ]))
}