import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ObserverComponent = _resolveComponent("ObserverComponent")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass(_ctx.elementClass)
      }, [
        _renderSlot(_ctx.$slots, "default", {
          item: item,
          index: index
        })
      ], 2))
    }), 128)),
    _createElementVNode("li", null, [
      _createVNode(_component_ObserverComponent, {
        options: _ctx.options,
        last: _ctx.last,
        onIntersect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('intersect')))
      }, null, 8, ["options", "last"])
    ])
  ]))
}