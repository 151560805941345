import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition } from "vue"
import _imports_0 from '@icon/flag.svg'
import _imports_1 from '@icon/dots-horizontal-center.svg'
import _imports_2 from '@icon/doubleCheck.svg'
import _imports_3 from '@icon/pen.svg'


const _hoisted_1 = { class: "to-do__item__wrapper" }
const _hoisted_2 = { class: "to-do__item__name-wrapper" }
const _hoisted_3 = { class: "to-do__item__info-user" }
const _hoisted_4 = {
  type: "button",
  class: "to-do__item__checkbox to-do__item__user-info__checkbox"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "to-do__item__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_AvatarGroupToDo = _resolveComponent("AvatarGroupToDo")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _component_Editor = _resolveComponent("Editor")!
  const _directive_ticket = _resolveDirective("ticket")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["to-do__item", [
        { 'is-show-users': _ctx.isSent },
        `is-${_ctx.item.status.toLowerCase()}`
      ]])
    }, [
      _createElementVNode("div", null, [
        (_ctx.item.urgent)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _imports_0,
              class: "to-do__item__flag",
              fill: true,
              width: "14",
              height: "14",
              color: "var(--serviceAlert-02)"
            }))
          : _createCommentVNode("", true)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
        (_ctx.ticketParams?.relatedUrl)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.getRouteHref(_ctx.ticketParams.relatedUrl),
              class: _normalizeClass(["link to-do__item__name", [{ 'check-done': _ctx.doneToDo }]])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.name), 1)
              ]),
              _: 1
            }, 8, ["to", "class"]))
          : _withDirectives((_openBlock(), _createBlock(_component_BaseSubHeading, {
              key: 1,
              class: _normalizeClass(["to-do__item__name", [{ 'check-done': _ctx.doneToDo }]]),
              size: "large",
              weight: "400",
              color: "monochrome-06"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.name), 1)
              ]),
              _: 1
            }, 8, ["class"])), [
              [_directive_ticket, _ctx.ticketParams]
            ])
      ])), [
        [_directive_tooltip, {
          content: _ctx.item.name,
          theme: 'info-tooltip',
          distance: 10,
          skidding: 16,
          placement: 'bottom',
          popperClass: 'to-do__tooltip-info',
        }]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "link is-large",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onExpand && _ctx.onExpand(...args)))
      }, [
        (_ctx.item.structuredDescription.html)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _imports_1,
              fill: true,
              width: "1.5em",
              height: "1.5em"
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isSent)
        ? (_openBlock(), _createBlock(_component_VDropdown, {
            key: 0,
            disabled: _ctx.item.owners.length <= 1,
            triggers: ['hover'],
            popperHideTriggers: ['hover'],
            popperShowTriggers: ['hover'],
            showGroup: `to-do-${_ctx.index}`,
            placement: "bottom-end",
            popperClass: "to-do__item__info__wrapper",
            theme: "info-dropdown"
          }, {
            popper: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.owners, (owner, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "to-do__item__info"
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_Avatar, {
                      src: owner.pictureUrl,
                      gap: owner.getInitials()
                    }, null, 8, ["src", "gap"]),
                    _createVNode(_component_BaseSubHeading, {
                      size: "large",
                      weight: "400",
                      color: "monochrome-06",
                      class: "to-do__item__user-info__name"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(owner.username), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("button", _hoisted_4, [
                    (_ctx.item.ownersCompletionStatuses[owner.id])
                      ? (_openBlock(), _createBlock(_component_icon, {
                          key: 0,
                          data: _imports_2,
                          color: _ctx.isDark ? 'var(--markingGreen-03)' : 'var(--markingGreen-02)',
                          fill: true,
                          original: "",
                          width: "24",
                          height: "24"
                        }, null, 8, ["color"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, "   "))
                  ])
                ]))
              }), 128))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_AvatarGroupToDo, {
                avatars: _ctx.item.owners,
                type: "avatarGroup"
              }, null, 8, ["avatars"])
            ]),
            _: 1
          }, 8, ["disabled", "showGroup"]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["to-do__item__checkbox", [{ 'pointer-none': _ctx.isSent }]]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCheckboxClick && _ctx.onCheckboxClick(...args)))
      }, [
        (_ctx.getToDoIcon.icon)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _ctx.getToDoIcon.icon,
              color: _ctx.getToDoIcon.color,
              fill: true,
              original: "",
              width: "24",
              height: "24"
            }, null, 8, ["data", "color"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.onBeforeEnterDescription,
      onBeforeLeave: _ctx.onBeforeLeaveDescription
    }, {
      default: _withCtx(() => [
        (_ctx.showDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Editor, {
                id: "body-description",
                type: "read",
                class: "to-do__item__description",
                targetConfig: _ctx.typeConfig,
                value: _ctx.item.structuredDescription.html,
                extensions: _ctx.extensions,
                isBodyColor: true
              }, null, 8, ["targetConfig", "value", "extensions"]),
              _createElementVNode("button", {
                type: "button",
                class: "link is-medium-500 to-do__item__edit-button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
              }, [
                _createVNode(_component_icon, {
                  data: _imports_3,
                  width: "1.15em",
                  height: "1.15em"
                }),
                _createTextVNode(" Edit ")
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeEnter", "onBeforeLeave"])
  ]))
}