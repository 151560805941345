<template>
  <div class="header__menu">
    <button
      v-click-outside="hide"
      class="body-text body-text-large-400"
      :class="[{'clicked__button' : opened}, {'is-disabled' : !visibleMenuItems.length}]"
      @click="toggle"
    >
      <icon
        data="@icon/menu.svg"
        :fill="true"
        width="1.5em"
        height="1.5em"
      />
    </button>
    <div
      v-if="opened"
      class="header__menu__box"
    >
      <ul class="header__menu__list">
        <li
          v-for="(item, index) in visibleMenuItems"
          :key="index"
          class="header__menu__item"
        >
          <BaseSubHeading
            size="large"
            weight="400"
            color="monochrome-05"
            className="header__menu__button"
            @click="onClickRouteLink(item)"
          >
            <icon
              :data="item.icon"
              :fill="item.fill"
              class="header__menu__icon"
              :color="item.iconColor"
              width="1.5em"
              height="1.5em"
            />
            {{ item.label }}
          </BaseSubHeading>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import storyPoker from "@icon/storyPoker.svg"
import retrospective from "@icon/retrospective.svg"
import roadmap from "@icon/roadmap.svg"
import { directive as ClickOutside } from "click-outside-vue3"
import { User, Team } from "@/models"
import { Options, Vue } from "vue-class-component"
import { VNode } from "vue"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
import BaseSubHeading from "@/components/Base/BaseSubHeading.vue"

interface IMenu {
  icon: VNode;
  iconColor?: string;
  label: string;
  to: string;
  fill: boolean;
  visible: boolean;
}
@Options({
  name: "HeaderMenu",
  components: { BaseSubHeading },
  directives: {
    ClickOutside,
  },

})
export default class HeaderMenu extends Vue {
  @Getter(Getters.GET_CURRENT_PROJECT_ID) projectId: string
  @Getter(Getters.GET_USER_CURRENT_PROFILE) user: User
  @Getter(Getters.GET_SESSION_TEAM) selectedTeam: Team

  opened = false
  showAdminMenu = true

  showItemsBasedOnParams = {
    storypoker: ["teamId", "projectId"],
    retros: ["accountId", "projectId"],
    roadmap: ["accountId"],
  }

  menuUser: IMenu[] = [
    {
      icon: roadmap,
      label: "Roadmap",
      to: "roadmap",
      fill: true,
      visible: true,
    },
    {
      icon: retrospective,
      label: "Retrospectives",
      to: "retros",
      fill: true,
      visible: false,
    },
    {
      icon: storyPoker,
      label: "Story Poker",
      to: "storypoker",
      fill: true,
      visible: false,
    },
  ]

  selectedItemTo = ""

  get visibleMenuItems() {
    return this.menuUser.filter(item => item.visible)
  }

  get currentTeamName() {
    return this.$route.params?.teamId ?? ""
  }

  created() {
    this.watchOpened()
  }

  onClickRouteLink(item) {
    const projectName = this.$route.params?.projectId

    if (item.to === "roadmap") {
      if (!projectName) {
        this.$router.push({ name: item.to })
        return
      }
      this.$router.push({ name: item.to, query: { p: projectName } })
      return
    }

    if (item.to === "storypoker") {
      this.$router.push({ name: item.to })
      return
    }

    this.$router.push({ name: item.to })
  }

  toggle() {
    this.opened = !this.opened
    if (this.opened) {
      this.watchOpened()
    }
  }

  hide() {
    this.opened = false
  }

  watchOpened() {
    const params = Object.keys(this.$route.params)

    this.menuUser.forEach((item) => {
      const paramsCheck = this.showItemsBasedOnParams[item.to]

      if (paramsCheck) {
        item.visible = paramsCheck.every(param => params.includes(param))
      }
    })
  }
}
</script>
