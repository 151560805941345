import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multiselect__option__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_BorderedTeam = _resolveComponent("BorderedTeam")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_DropdownBasic = _resolveComponent("DropdownBasic")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    as: _ctx.validationTag,
    modelValue: _ctx.selectedTeam,
    name: "Team",
    rules: _ctx.rules,
    validateOnBlur: _ctx.validateOnBlur
  }, {
    default: _withCtx(({ errors, resetField }) => [
      _createVNode(_component_DropdownBasic, {
        id: "team",
        value: _ctx.selectedTeam,
        options: _ctx.dropdownTeams,
        trackBy: 'name',
        label: 'name',
        searchable: false,
        placeholder: "",
        optionPosition: _ctx.optionPosition,
        closeOnSelect: true,
        appendDropDownToBody: _ctx.appendDropDownToBody,
        scrollableContainer: _ctx.scrollableContainer,
        hideSelected: true,
        noOptions: _ctx.loading ? _ctx.loadingResult : 'No teams found',
        class: _normalizeClass([[_ctx.className], "dropdown__select-team"]),
        parent: "button",
        onOnOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onOpen'))),
        onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose'))),
        onOnSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSelectTeam($event.value))),
        onCheckOpenDropdown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('checkOpenDropdown')))
      }, {
        placeholder: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({'select-team__title-wrapper_alert':errors[0]})
          }, [
            _renderSlot(_ctx.$slots, "placeholder", {}, () => [
              _createVNode(_component_BaseSubHeading, {
                weight: "500",
                size: "medium",
                className: "select-team__title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Select Team ")
                ]),
                _: 1
              })
            ])
          ], 2)
        ]),
        singleLabel: _withCtx(({ option }) => [
          _renderSlot(_ctx.$slots, "singleLabel", { option: option }, () => [
            _createVNode(_component_BorderedTeam, {
              src: option.picture?.pictureUrl,
              isFull: _ctx.isFull,
              teamName: option.name
            }, null, 8, ["src", "isFull", "teamName"])
          ])
        ]),
        option: _withCtx(({ option }) => [
          _createVNode(_component_Avatar, {
            src: option.picture?.pictureUrl || null,
            size: _ctx.optionLabelSize,
            class: _normalizeClass([{'avatar-select__gap': option.id === 'unassignTeam'}, "multiselect__option__avatar"]),
            type: "teamsGroup"
          }, null, 8, ["src", "size", "class"]),
          _createElementVNode("span", _hoisted_1, _toDisplayString(option.name), 1)
        ]),
        _: 2
      }, 1032, ["value", "options", "optionPosition", "appendDropDownToBody", "scrollableContainer", "noOptions", "class"])
    ]),
    _: 3
  }, 8, ["as", "modelValue", "rules", "validateOnBlur"]))
}