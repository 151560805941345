<template>
  <span>
    Sprint #{{ sprint?.sprintSequenceNumber ?? $t("none") }} {{ sprint?.startDate ?? $t("none") }} - {{ sprint?.endDate ?? $t("none") }}
    <slot />
  </span>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
@Options({
  name: "NotificationSprintHeader",
  components: {},
})
export default class NotificationSprintHeader extends Vue {
  @Prop() readonly sprint
}
</script>
