import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "layout",
  class: "layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.accountId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header),
        _createVNode(_component_Alert),
        _createVNode(_component_router_view)
      ]))
    : _createCommentVNode("", true)
}