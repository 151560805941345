import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reference-user" }
const _hoisted_2 = { class: "reference-user__left" }
const _hoisted_3 = { class: "reference-user__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_BaseCaption = _resolveComponent("BaseCaption")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.result,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    command: _ctx.command,
    onOnClick: _ctx.onClick
  }, {
    item: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            src: option.pictureUrl,
            gap: option.getInitials(),
            size: "sm"
          }, null, 8, ["src", "gap"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseSubHeading, {
            weight: "400",
            size: "medium",
            color: "monochrome-06",
            className: "reference-user__title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.fullName), 1)
            ]),
            _: 2
          }, 1024),
          (_ctx.hasDuplicateName(option.fullName))
            ? (_openBlock(), _createBlock(_component_BaseCaption, {
                key: 0,
                size: "large",
                color: "monochrome-05",
                className: "reference-user__desc"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" (" + _toDisplayString(option.email) + ") ", 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["searchList", "componentType", "selectedIndex", "command", "onOnClick"]))
}