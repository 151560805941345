import { mixins, Options } from "vue-class-component"
import { Emit, Prop, Watch } from "vue-property-decorator"
import { gsap } from "gsap"
import cloneDeep from "lodash/cloneDeep"
import { Action, Getter } from "s-vuex-class"

import BacklogCommonMetrics from "@/components/Backlog/BacklogCommonMetrics"

import { moveTo } from "@/mixins/moveTo"
import {
  AlertContentItem,
  AlertType,
  ChangeItemOwner,
  ChangeItemStatus,
  DefinitionOfDone,
  Epic,
  Item,
  ItemQueryPriority,
  ItemQueryPriorityType,
  ServerResponse,
  StructuredDescription,
  Task,
  Team,
  UpdateElementInBacklog,
  UpdateObjectValue,
  UpdateTaskList
} from "@/models"
import { $t } from "@/plugins/i18n"
import { Actions, Getters, store } from "@/store"
import { AlertActions, alertModule } from "@/store/modules/alert.module"
import { BacklogActions, backlogModule } from "@/store/modules/backlog.module"
import {
  reverseScaleAnimation,
  slideItemAnimationLeft,
  slideItemAnimationRight,
  storyOpeningAnimation,
  storyScalingAnimation
} from "@/utils"

@Options({
  name: "StoryCommon",
  emits: ["updateBacklogInstanceName", "unsavedChanges", "onForceUpdateEditItem"]
})
export class StoryCommon extends mixins(moveTo, BacklogCommonMetrics) {
  @Prop({ default: true }) readonly continueUnsave: boolean
  @Prop() readonly epic: Epic
  @Prop({ default: null }) readonly customIndexForNewItem: number | null

  @Getter(Getters.GET_ITEM_CLONE) itemClone: Item
  @Getter(Getters.GET_CURRENT_PROJECT_ID) readonly projectId: string
  @Getter(Getters.GET_BACKLOG_CREATED_ITEM) readonly backlogCreatedItem: Item | null
  @Getter(Getters.TASK_LIST_WAS_EDITED) taskIsEdited: boolean
  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean
  @Getter(Getters.GET_SESSION_TEAM_ID) readonly teamId: string
  @Getter(Getters.GET_SESSION_TEAM) currentTeam: Team
  @Getter(Getters.GET_DEFINITIONS_LIST) enabledDefinitions: DefinitionOfDone[]

  @Action(Actions.POST_ITEM) postItem: ({
    item,
    onlySend
  }: {
    item: Item
    onlySend?: boolean
  }) => void
  @Action(Actions.UPDATE_TASKS_WITH_CLONE) setTasks: (tasks: Task[]) => void
  @Action(Actions.UPDATE_ITEM_CLONE) updateItemClone: (itemField: string) => void
  @Action(Actions.CHANGE_OWNER_ITEM) updateOwner: (changeOwner: ChangeItemOwner) => void
  @Action(Actions.CHANGE_STATUS_ITEM) updateStatus: ({
    changeStatus
  }: {
    changeStatus: ChangeItemStatus
  }) => void
  @Action(Actions.RESOLVE_ITEM_ID) resolveItemId: ({
    sequenceNumber,
    onlyGet
  }: {
    sequenceNumber: number
    onlyGet?: boolean
  }) => Promise<string>
  @Action(Actions.PUT_TASKS_WITH_CHANGES_CHECKING) saveTasks: (payload: UpdateTaskList) => void
  @Action(Actions.RESET_CLONE) resetClone: () => void
  @Action(Actions.CHANGE_SPRINT_PRIORITY) changeSprintPriority: (payload: ItemQueryPriority) => void
  @Action(Actions.RESET_ITEM) resetItem: () => void
  @Action(Actions.RESET_TASKS) resetTasksList: () => void

  gsapAnimationLeft: gsap.core.Timeline | null = null
  gsapAnimationRight: gsap.core.Timeline | null = null
  htmlElementInList: HTMLElement | null = null
  isLazySave = false
  showModalEstimationRequired = false
  showNavigationsBtn = false
  itemSequenceNumber: null | number = null
  nextBtnItemSequence: null | number = null
  prevBtnItemSequence: null | number = null
  showLeftNavigationBtn = false
  showRightNavigationBtn = false
  showBottomNavigationBtn = false
  navigationSideBtnPositionTop = 0
  navigationSideBtnPositionBottom = 0
  showSkeletonByNavigation = false
  goToAnotherItem: boolean | null = null
  unsavedChanges = false
  continuedUnsaveChanges = false
  itemId = ""
  get inActiveSprint() {
    return this.item?.sprint?.isStarted
  }

  get itemIsLoaded() {
    return (
      !this.$wait.is("get.item") &&
      !this.$wait.is("loadingItemLeft") &&
      !this.$wait.is("loadingItemRight")
    )
  }

  get itemIsEdited() {
    if (!this.itemIsLoaded || !this.$route.query.i) return false
    if (this.backlogCreatedItem && !this.backlogCreatedItem?.id) return true
    return this.itemId ? this.item.isModified(this.item.unsaveFields) : this.item.isModified()
  }

  get loadingAttachment() {
    return this.$wait.is("get.attachment")
  }

  get estimatedTasksCount() {
    return Task.getEstimatedTasksCount({ tasks: this.tasks })
  }

  created() {
    this.onShowItem()
  }

  async onOpenItem() {
    if (!this.$route.query.i) return
    this.$wait.start("get.item")
    this.onResetItem()
    this.resetBtn()
    this.itemSequenceNumber = Number(this.$route.query.i)
    this.itemId = this.itemSequenceNumber
      ? await this.resolveItemId({
          sequenceNumber: this.itemSequenceNumber,
          onlyGet: true
        })
      : ""
    if (this.itemId) {
      await this.get({ itemId: this.itemId })
      if (!this.$route.query.i) {
        this.onResetItem()
        this.onForceUpdateEditItem()
        return
      }
      this.$wait.end("get.item")
      window.addEventListener("resize", this.setNavigationBtnPosition)
    } else {
      const newItem = this.backlogCreatedItem ? new Item(this.backlogCreatedItem) : new Item()
      newItem.epic = this.epic
      this.setItem(newItem)
      this.handleInput({
        id: "team",
        value: this.currentTeam
      } as any)
    }
    this.setAnimationData()
    this.setInitialState()
    const newBacklogInstanceName = Item.getBacklogInstanceNameByItem(this.item)
    this.updateBacklogInstanceName(newBacklogInstanceName)
    this.$wait.end("get.item")
    this.setNavigation()
    this.$nextTick(() => {
      window.addEventListener("resize", this.setNavigationBtnPosition)
    })
  }

  @Emit()
  updateBacklogInstanceName(instanceName) {
    return instanceName
  }

  setNavigation() {
    this.showNavigationsBtn = true
    this.setNavigationBtnPosition()

    this.setBtnData()
  }

  setNavigationBtnPosition() {
    const container = document.querySelector("#edit-item__left")
    if (container) {
      const rect = container.getBoundingClientRect()
      this.navigationSideBtnPositionTop = rect.top
      this.navigationSideBtnPositionBottom = rect.bottom
    }
  }

  async setBtnData() {
    let data = store.getters[Getters[this.backlogSetup.storeGetter]] as ServerResponse<Item>

    if (this.backlogSetup?.sprintInfo?.id) {
      data = data[this.backlogSetup.sprintInfo?.id]
    }
    if (!data) return
    const dataContent = data.content
    const currentItemPosition = dataContent.findIndex(
      item => item.sequenceNumber === this.itemSequenceNumber
    )
    const lastElementIndex = dataContent.length - 1
    let isLast = data?.last ?? true
    if (currentItemPosition === lastElementIndex && !isLast) {
      const successfulRequest = await this.updateBacklog({
        page: this.backlogSetup.filter.page + 1
      })
      if (!successfulRequest) {
        isLast = true
        return
      }
      return this.setBtnData()
    }

    this.prevBtnItemSequence =
      currentItemPosition > 0 ? dataContent[currentItemPosition - 1]?.sequenceNumber : null
    this.nextBtnItemSequence =
      currentItemPosition < lastElementIndex
        ? dataContent[currentItemPosition + 1]?.sequenceNumber
        : null
  }

  async updateBacklog(payload = { page: 1 }) {
    this.backlogSetup.filter.page = payload.page ?? 1
    return await backlogModule[BacklogActions[this.backlogSetup.backlogGetAction]](
      this.backlogSetup
    )
  }

  async handleInput(params: UpdateObjectValue<Item>) {
    await this.updateItemValue(params)

    if (!this.item?.id) return

    if (this.item.getAutoSaveFields(params["id"])) {
      this.updateItemClone(params["id"])
      await this.updateItem({ item: this.itemClone, notSetItem: true })
    }

    if (params["id"] === "owner") {
      await this.updateOwner({
        itemId: this.item?.id ?? "",
        ownerId: params["value"]?.id
      })
      this.updateItemClone(params["id"])
    }
  }

  async checkRemoveItemFromBacklog() {
    if (!this.item.id) return
    const itemInBacklog = await this.checkItemInBacklog()
    if (itemInBacklog) {
      this.onSetBacklogItem(itemInBacklog)
      return
    }
    await this.onRemoveItemWithAnimation({
      itemId: this.item.id,
      nameBacklog: this.backlogType,
      sprintId: this.backlogSetup.sprintInfo?.id
    })
  }

  async checkItemInBacklog() {
    if (!this.item.id) return
    const backlogSetup = cloneDeep(this.backlogSetup)
    backlogSetup.filter.page = 1
    backlogSetup.filter.onlyGet = true
    backlogSetup.filter.itemIds = [this.item.id]
    const itemInBacklogResponse =
      await backlogModule[BacklogActions[backlogSetup.backlogGetAction]](backlogSetup)
    const itemInBacklog = itemInBacklogResponse.content
    return itemInBacklog?.[0]
  }

  statusUpdate({ statusId, message }: { statusId: string; message?: StructuredDescription }) {
    this.updateStatus({
      changeStatus: {
        projectId: this.projectId,
        itemId: this.item.id as string,
        oldStatus: this.item.status,
        status: statusId,
        message: message,
        itemTitle: this.item.title
      }
    })
  }

  async onHideItem() {
    if (!this.itemIsLoaded) {
      this.onScale()
      this.onResetAll()
      return
    }
    await this.checkModified()
    // TODO: need to refactor closing item so that methods for opening work only after the previous element is closed
    if (this.$route.query.i) return
    this.onScale()
    this.onResetAll()
  }

  async checkModified() {
    if (
      (this.item.isModified() || this.taskIsEdited || this.itemMoved) &&
      !this.continuedUnsaveChanges
    ) {
      if (this.teamId) {
        this.backlogSetup.filter.setFilterTeams([this.teamId])
      }
      await this.moveItemToAnotherBacklog(this.item)
      await this.getMetrics(this.backlogSetup), this.setInitialState()
      await this.checkRemoveItemFromBacklog()
    }
  }

  onResetItem() {
    this.resetItem()
    this.resetTasksList()
    this.resetBtn()
    this.setInitialState()
  }

  onResetAll() {
    this.onResetItem()
    this.setCreatedBacklogItem(null)
    this.onRemoveItemQuery()
    this.resetInlineItem()
    this.onForceUpdateEditItem()
  }

  @Emit()
  resetInlineItem() {
    //
  }

  async onRemoveItemWithAnimation(params: {
    itemId: string
    nameBacklog: string
    sprintId?: string
  }) {
    if (!this.htmlElementInList) return
    await new Promise(resolve => {
      reverseScaleAnimation(this.htmlElementInList as any)
        .play()
        .eventCallback("onComplete", () => {
          resolve(this.onRemoveItem(params))
          this.htmlElementInList = null
        })
    })
  }

  onRemoveItem(params) {
    this.removeItemFromBacklog(params)
    this.getAllMetrics()
  }

  onSetBacklogItem(item) {
    this.insertItemInBacklog({
      backlogName: this.backlogType,
      item,
      sprintId: this.backlogSetup?.sprintInfo?.id,
      index: null
    })
  }

  @Watch("loadingAttachment")
  onLazySave() {
    if (this.isLazySave && !this.loadingAttachment) {
      this.isLazySave = false
      this.save()
    }
  }

  async saveItem() {
    await this.onUpdateTasks()
    await this.save()
  }

  async save() {
    if (this.$wait.is("itemSave") || this.isLazySave || !this.itemIsEdited) return
    if (this.loadingAttachment) {
      this.isLazySave = true
      return
    }
    this.$wait.start("itemSave")
    await this.resetClone()
    this.itemId ? await this.onUpdateItem() : await this.onCreateItem()
    this.$emit("saveItem")

    this.$wait.end("itemSave")
  }

  async onUpdateItem() {
    await this.updateItem({ item: this.item })
    await this.checkModified()
  }

  async onCreateItem() {
    // Check can add item to the sprint
    if (this.backlogSetup.sprintInfo?.status === "STARTED" && !this.canAddItemIntoActiveSprint())
      return

    // Handle input for tasks
    this.handleInput({ id: "tasks", value: this.tasks } as any)
    // Handle input for definitions of done
    this.handleInput({
      id: "definitionsOfDone",
      value: this.enabledDefinitions
    } as any)

    // Post the item to the server
    await this.postItem({ item: this.item })
    // Replace the current route query with the item's sequence number
    await this.$router.replace({
      query: {
        ...this.$route.query,
        i: this.item.sequenceNumber
      }
    })
    // Set the itemId to the created item's id
    this.itemId = this.item?.id ?? ""
    // Set the tasks for the item
    this.setTasks(this.tasks)
    // Check if the item exists in the backlog
    const itemInBacklog = await this.checkItemInBacklog()
    // Insert the item into the appropriate backlog
    const index = 1
    this.insertItem({
      item: this.item,
      index,
      backlogName: itemInBacklog ? this.backlogType : "backlogUnprioritized",
      sprintId: this.item.sprintId
    })
    // Reset the created backlog item
    this.setCreatedBacklogItem(null)
    // Reset the inline item
    this.resetInlineItem()
    // Set the item's initial state
    this.setInitialState()
    this.getAllMetrics()
  }

  onRemoveItemQuery() {
    if (!this.$route.query.i) return
    this.$router.go(-1)
  }

  async onUpdateTasks() {
    if (this.item?.id) {
      await this.saveTasks({
        tasks: this.tasks,
        item: this.item
      })
      const taskOwners = {
        value: this.tasks.filter(task => task.owner).map(e => e.owner),
        id: "taskOwners"
      } as any
      await this.handleInput(taskOwners)
    }
  }

  itemLeftBeforeEnter(animateElement) {
    const elementWrapper = document.getElementById("edit-item__left") as HTMLElement
    this.getHtmlElementInList()
    this.gsapAnimationLeft = storyOpeningAnimation({
      animateElement,
      itemElement: this.htmlElementInList,
      elementWrapper
    })
      .eventCallback("onComplete", () => {
        this.$emit("showItem", true)
      })
      .play()
  }

  getHtmlElementInList() {
    this.htmlElementInList =
      this.$route.query.i !== "create"
        ? document.getElementById(`story-${this.$route.query.i}`)
        : null
  }

  itemLeftBeforeLeave() {
    this.$emit("showItem", false)
    this.showNavigationsBtn = false
    if (!this.gsapAnimationLeft) {
      this.onCompleteAnimation()
      window.removeEventListener("resize", this.setNavigationBtnPosition)
      return
    }
    this.gsapAnimationLeft.reverse(0).eventCallback("onReverseComplete", () => {
      this.onCompleteAnimation()
    })

    window.removeEventListener("resize", this.setNavigationBtnPosition)
  }

  onCompleteAnimation() {
    this.$emit("hideItem")
    this.onHideItem()
  }

  onScale() {
    if (!this.htmlElementInList) return
    storyScalingAnimation(this.htmlElementInList).play()
  }

  itemRightBeforeLeave() {
    if (!this.gsapAnimationRight) return
    this.gsapAnimationRight.reverse(0)
  }

  itemRightBeforeEnter(animateElement) {
    const elementWrapper = document.getElementById("edit-item__right") as HTMLElement
    this.gsapAnimationRight = storyOpeningAnimation({
      animateElement,
      itemElement: this.htmlElementInList,
      elementWrapper
    }).play()
  }

  setAnimationData() {
    this.htmlElementInList =
      this.$route.query.i !== "create"
        ? document.getElementById(`story-${this.$route.query.i}`)
        : null
    const elementWrapperRight = document.getElementById("edit-item__right") as HTMLElement
    const elementWrapperLeft = document.getElementById("edit-item__left") as HTMLElement
    const elementRight = this.$refs.itemRight as HTMLElement
    const elementLeft = this.$refs.itemLeft as HTMLElement
    if (!elementRight || !elementLeft) return
    this.gsapAnimationRight = storyOpeningAnimation({
      animateElement: elementWrapperRight,
      itemElement: this.htmlElementInList,
      elementWrapper: elementRight
    })
    this.gsapAnimationLeft = storyOpeningAnimation({
      animateElement: elementLeft,
      itemElement: this.htmlElementInList,
      elementWrapper: elementWrapperLeft
    })
  }

  async moveItemToAnotherBacklog(item) {
    if (!this.backlogCreatedItem || this.backlogCreatedItem.id !== item.id || !this.item.id) {
      return
    }

    const sprint = this.backlogCreatedItem?.sprint
    const isActiveSprint = sprint?.status === "STARTED"
    const storyPoint = item.storyPoints !== null
    if (!isActiveSprint) {
      return
    }

    const alertContent: AlertContentItem[] = []
    if (
      (!this.backlogCreatedItem?.sprintIsContinuous && (!this.estimatedTasks || !storyPoint)) ||
      !this.estimatedTasks
    ) {
      const toSprintNumber = this.backlogSetup.sprintInfo?.sequenceNumber
        ? `#${this.backlogSetup.sprintInfo.sequenceNumber}`
        : ""
      alertContent.push(
        {
          text: `Item #${item.sequenceNumber} `,
          type: "bold"
        },
        {
          text: "moved back to the ",
          type: "regular"
        },
        {
          text: `${$t(`backlog.titles['${this.backlogSetup.storeBacklogId}']`) + toSprintNumber}`,
          type: "bold"
        }
      )
    } else {
      const toSprintNumber = sprint?.sequenceNumber ? `#${sprint.sequenceNumber}` : ""
      alertContent.push(
        {
          text: `Item #${item.sequenceNumber} `,
          type: "bold"
        },
        {
          text: "estimated and moved to the ",
          type: "regular"
        },
        {
          text: `${
            $t(`backlog.titles['${this.backlogCreatedItem?.backlogSetup?.storeBacklogId}']`) +
            toSprintNumber
          }`,
          type: "bold"
        }
      )
      const priority = this.backlogCreatedItem.priority
      this.handleInput({
        id: "sprint",
        value: sprint
      } as any)

      this.handleInput({
        id: "priority",
        value: priority
      } as any)

      await this.changeSprintPriority({
        previousItemId: priority?.previousItemId ?? null,
        itemId: item.id ?? "",
        nextItemId: priority?.nextItemId ?? null,
        priorityType: priority?.priorityType as ItemQueryPriorityType,
        sprintId: sprint?.id ?? ""
      })
      this.insertItem({
        item,
        index: this.backlogCreatedItem.priority?.index ?? 0,
        backlogName: this.backlogCreatedItem?.backlogSetup?.backlogType ?? "",
        sprintId: this.backlogCreatedItem?.sprint?.id ?? ""
      })
    }
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent
    })
    this.setCreatedBacklogItem(null)
  }

  onCloseEstimationModal() {
    this.showModalEstimationRequired = false
  }

  onShowModalEstimationRequired() {
    this.showModalEstimationRequired = true
  }

  resetBtn() {
    this.itemSequenceNumber = 0
    this.nextBtnItemSequence = 0
    this.prevBtnItemSequence = 0
  }

  async goToNextItem() {
    this.onGoToAnotherItem(true)
  }

  async onGoToAnotherItem(next) {
    this.goToAnotherItem = next
    if (this.unsavedChanges) {
      this.$modal.show("unsaved-changes")
      return
    }

    const animationMethod = next ? slideItemAnimationRight : slideItemAnimationLeft
    this.showSkeletonByNavigation = true
    this.showNavigationsBtn = false
    const elementLeft = this.$refs.itemLeft as HTMLElement
    const elementRight = this.$refs.itemRight as HTMLElement
    const skeletonLeft = document.getElementById("skeletonLeft")
    const skeletonRight = document.getElementById("skeletonRight")
    animationMethod(elementLeft, skeletonLeft).play()
    animationMethod(elementRight, skeletonRight)
      .play()
      .eventCallback("onComplete", () => {
        this.navigateAfterHidingItem(next)
      })
  }

  async navigateAfterHidingItem(next) {
    await this.checkModified()
    await this.$router.replace({
      query: {
        ...this.$route.query,
        i: next ? this.nextBtnItemSequence : this.prevBtnItemSequence
      }
    })
    this.onForceUpdateEditItem()
  }

  async goToPrevItem() {
    this.onGoToAnotherItem(false)
  }

  @Emit()
  onForceUpdateEditItem() {
    return
  }

  @Watch("itemIsLoaded")
  setInitialState() {
    this.item.setInitialState()
  }

  @Watch("taskIsEdited")
  @Watch("itemIsEdited")
  unsavedItem() {
    this.unsavedChanges = this.itemIsLoaded ? this.taskIsEdited || this.itemIsEdited : false
    this.$emit("unsavedChanges", this.unsavedChanges)
  }

  @Watch("backlogSetup.storeGetter")
  navigationDataUpdate() {
    this.setBtnData()
  }

  @Watch("$route.query.i")
  async onShowItem() {
    if (!this.$route.query.i || this.$wait.is("itemSave") || this.showSkeletonByNavigation) return
    await this.onOpenItem()
  }

  @Watch("continueUnsave")
  onContinueUnsave() {
    if (this.goToAnotherItem !== null) {
      this.item.setInitialState()
      this.continuedUnsaveChanges = true
      this.$nextTick(() => {
        this.onGoToAnotherItem(this.goToAnotherItem)
      })
    }
  }

  createEditorData(params: UpdateObjectValue<Item>) {
    this.updateItemValue(params)
    this.setInitialState()
  }

  @Emit()
  moveToUnprioritized() {
    this.closeEstimationModal()
  }

  closeEstimationModal() {
    this.showModalEstimationRequired = false
  }
}
