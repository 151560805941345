import { ConvertRoleWithAccess } from "@/models/User";
import { store, Getters } from "@/store"

import { computed } from 'vue';
import { createAcl, defineAclRules } from 'vue-simple-acl';

const user = computed(() => store.getters[Getters.GET_USER_CURRENT_PROFILE]);
const currentTeam = computed(() => store.getters[Getters.GET_SESSION_TEAM]);
const accounId = computed(() => store.getters[Getters.GET_ACCOUNT_ID]);

const rules = () => defineAclRules((setRule) => {
  setRule('admin', (user) => user?.isAdmin(accounId.value))
  setRule('superAdmin', (user) => user?.superAdmin)
  setRule('is-email-verified', (user) => user?.emailVerified)
  setRule('canAddInlineItem', (user) => {
    if (!currentTeam.value || !user) return false

    const rolesOfAccess = currentTeam.value.rolesOfAccessToItemCreation as string[]
    const { roles } = user

    return roles.some(role => rolesOfAccess.includes(ConvertRoleWithAccess[role]))
  })
});

const simpleAcl = createAcl({
  user,
  rules,
});

export default simpleAcl;