import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SprintRetrospectiveRequested = _resolveComponent("SprintRetrospectiveRequested")!
  const _component_SprintRetrospectiveToDo = _resolveComponent("SprintRetrospectiveToDo")!

  return (_ctx.notification.reminder)
    ? (_openBlock(), _createBlock(_component_SprintRetrospectiveRequested, {
        key: 0,
        notification: _ctx.notification
      }, null, 8, ["notification"]))
    : (_openBlock(), _createBlock(_component_SprintRetrospectiveToDo, {
        key: 1,
        notification: _ctx.notification
      }, null, 8, ["notification"]))
}