import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlagProvider = _resolveComponent("FlagProvider")!

  return (_openBlock(), _createBlock(_component_FlagProvider, { config: _ctx.unleashConfig }, {
    default: _withCtx(() => [
      (_ctx.layout)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["config"]))
}