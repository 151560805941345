import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/milestoneIcon.svg'


const _hoisted_1 = { class: "label-pic is-orange" }
const _hoisted_2 = { className: "notification__update-item__content" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    projectName: _ctx.notification.projectName,
    class: "notification__item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.notification.milestoneName) + " Completed ", 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          class: "link is-large",
          target: "_blank",
          href: _ctx.notification.url
        }, _toDisplayString(_ctx.notification.milestoneName), 9, _hoisted_3),
        _createVNode(_component_BaseSubHeading, {
          size: "medium",
          color: "monochrome-07",
          weight: "400"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notification.milestoneDate), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["notification", "projectName"]))
}