<template>
  <BaseSubHeading
    v-if="teams.length <= 1"
    size="large"
    weight="400"
    color="monochrome-05"
    className="header__breadcrumb"
  >
    /
    <router-link
      :to="routeLink"
    >
      {{ title }}
    </router-link>
  </BaseSubHeading>
  <TeamSelectionDropdown
    v-else
    optionLabelSize="md"
    className="team-selection"
    @onSelectTeam="onSelectDropdownTeam"
  >
    <template #placeholder>
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        className="header__breadcrumb"
      >
        /<span>{{ title }}</span>
      </BaseSubHeading>
    </template>
  </TeamSelectionDropdown>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import TeamSelectionDropdown from "@/components/Team/TeamSelectionDropdown.vue"
import { Team } from "@/models/Team"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
import { Item } from "@/models"
@Options({
  name: "SupportCaseDropdown",
  components: {
    TeamSelectionDropdown,
  },
})

export default class SupportCaseDropdown extends Vue {
  @Getter(Getters.GET_ITEM) item: Item
  @Getter(Getters.GET_TEAMS) teams: Team[]

  get title() {
    return Item.getBacklogNameByItem(this.item)
  }

  get routeLink() {
    return {
      name: "backlog",
      params: {
        ...this.$route.params,
        teamId: this.teams[0]?.urlName ?? "",
      },
      query: Item.getBacklogQueryByItem(this.item),
    }
  }

  onSelectDropdownTeam(team: Team) {
    this.$router.push({
      name: "backlog",
      params: {
        ...this.$route.params,
        teamId: team.urlName ?? "",
      },
      query: Item.getBacklogQueryByItem(this.item),
    })
  }
}
</script>
