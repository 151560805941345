import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-editor__picker custom-editor__button" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      ref: "picker",
      type: "button",
      tabindex: -1,
      class: "custom-editor__picker-button heading-level heading-level-3",
      title: _ctx.title,
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleColorPicker && _ctx.toggleColorPicker(...args)), ["prevent"]))
    }, [
      _createVNode(_component_icon, {
        data: _ctx.icon,
        width: "1em",
        height: "1em"
      }, null, 8, ["data"])
    ], 8, _hoisted_2)), [
      [_directive_click_outside, _ctx.hideColorPicker]
    ]),
    (_ctx.showColorPicker)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          ref: "pickerDropdown",
          class: "custom-editor__picker-list",
          style: _normalizeStyle({ top: _ctx.top + 'px', left: _ctx.left + 'px' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass([{ 'is-active': _ctx.isActive ? _ctx.isActive(color) : null }, "custom-editor__picker-item"])
            }, [
              _createElementVNode("button", {
                style: _normalizeStyle({ background: color}),
                type: "button",
                onClick: _withModifiers(($event: any) => (_ctx.action(color)), ["prevent"])
              }, null, 12, _hoisted_3)
            ], 2))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ]))
}