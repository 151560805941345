import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/task-icon.svg'


const _hoisted_1 = { class: "label-pic is-purple" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "notification__update-item__initiator"
}
const _hoisted_4 = { class: "notification__update-time-left__tasks__left" }
const _hoisted_5 = { class: "notification__update-time-left__tasks__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, { notification: _ctx.notification }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px",
          color: "#6A1B9A"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "button-level button-level-large notification__simple-link",
            target: "_blank",
            href: _ctx.notification.content?.itemUrl ?? '#'
          }, " Item #" + _toDisplayString(_ctx.notification.content?.sequenceNumber ?? _ctx.$t("none")), 9, _hoisted_2),
          _createTextVNode(),
          (_ctx.initiatorFullName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "@" + _toDisplayString(_ctx.initiatorFullName), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" assigned you the Owner of the following Tasks ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notification.content?.tasks, (task, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          className: "notification__update-time-left__tasks"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              color: "markingOrange-02",
              weight: "500"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" New ")
              ]),
              _: 1
            }),
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              color: "monochrome-07",
              weight: "400",
              className: "notification__update-time-left__tasks__name"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              color: "monochrome-06",
              darkColor: "monochrome-03",
              weight: "500",
              className: "notification__update-time-left__tasks__type"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.type), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_BaseSubHeading, {
              size: "small",
              color: "monochrome-07",
              weight: "500",
              className: "notification__update-time-left__tasks__estimation-gap"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" - ")
              ]),
              _: 1
            })
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["notification"]))
}