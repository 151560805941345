<template>
  <div
    v-click-outside="hide"
    class="user-search"
  >
    <BorderedInput
      id="name"
      className="user-search__input"
      :modelValue="searchText"
      :onWrapEvent="true"
      :placeholder="placeholder"

      @update:modelValue="onSearchUser"
    />

    <ul
      v-if="users.length"
      class="user-search__list"
    >
      <li
        v-for="(item, index) in users"
        :key="index"
        class="user-search__item"
      >
        <button
          type="button"
          class="user-search__button"
          @click.prevent="onUserSelect(item)"
        >
          <slot :item="item">
            <User :user="item" />
          </slot>
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Prop, Emit } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"

import { BorderedInput } from "@/components/Form"
import UserComponent from "./index.vue"
import { FilterUser, User } from "@/models"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { directive as ClickOutside } from "click-outside-vue3"


@Options({
  name: "UserSearch",
  components: {
    BorderedInput,
    User: UserComponent,
  },
  directives: {
    ClickOutside,
  },
})
export default class UserSearch extends Vue {
  @Getter(Getters.GET_USERS) readonly foundUsers: User[]

  @Action(Actions.GET_USERS) readonly searchUser: (filter: FilterUser) => void

  searchText = ""
  users: User[] = []
  filter: FilterUser = FilterUser.createDefault()

  @Prop() readonly exclude: User[]
  @Prop({ default: "Search user" }) readonly placeholder: string

  addUsers() {
    if (this.exclude) {
      this.users = this.foundUsers.filter(user => !this.exclude.some(t => t.id === user.id))
    } else {
      this.users = this.foundUsers
    }
  }

  hide() {
    this.users.length = 0
  }

  @Emit("input")
  async onSearchUser({ value }) {
    if (value) {
      this.filter.text = value
      await this.searchUser(this.filter)
      this.addUsers()
    } else {
      this.users = []
    }
  }

  @Emit("onSelect")
  onUserSelect(user: User): void {
    const index = this.users.findIndex(t => t.id === user.id)
    this.users.splice(index, 1)
    this.searchText = ""
  }
}
</script>
