import { RouteLocationRaw } from "vue-router"
import { store, Actions } from "@/store"
import { FilterSprint } from "@/models/Filter/FilterSprint"
export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  const filterParams = FilterSprint.createDefault()
  const activeSprintList = await store.dispatch(Actions.ACTIVE_SPRINTS, {
    filterParams,
  })
  if (!activeSprintList.length) {
    return next({
      name: "allSprints",
      params: to.params,
    })
  }
  if (activeSprintList.length === 1) {
    // if we have one active sprint - go to that Active Sprint Overview
    return next({
      name: "sprint",
      params: {
        projectId: to.params.projectId,
        teamId: activeSprintList[0].sprint.team.urlName,
        sprintId: activeSprintList[0].sprint.sequenceNumber,
      },
    })
  }
  next()
}
