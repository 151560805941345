import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/more-vertical.svg'
import _imports_1 from '@icon/trash.svg'


const _hoisted_1 = { class: "dropdown-single__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createBlock(_component_Multiselect, {
    id: _ctx.id,
    ref: `multiselect-${_ctx.id}`,
    modelValue: _ctx.value,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      _ctx.onInput
    ],
    class: _normalizeClass(["dropdown-single dropdown-single_icon", [`is-position-right`]]),
    options: _ctx.options,
    showLabels: false,
    trackBy: _ctx.trackBy,
    label: _ctx.label,
    placeholder: "",
    searchable: false,
    allowEmpty: false,
    closeOnSelect: _ctx.closeOnSelect,
    onOpen: _ctx.onOpen
  }, {
    placeholder: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          _createVNode(_component_icon, {
            data: _imports_0,
            width: _ctx.size.width,
            height: _ctx.size.height,
            fill: false,
            color: "#7D92A7"
          }, null, 8, ["width", "height"])
        ])
      ])
    ]),
    option: _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.option.label) + " ", 1),
      _createVNode(_component_icon, {
        data: _imports_1,
        fill: false,
        color: "#7D92A7",
        width: "16",
        height: "16"
      })
    ]),
    _: 3
  }, 8, ["id", "modelValue", "options", "trackBy", "label", "closeOnSelect", "onOpen", "onUpdate:modelValue"]))
}