import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/check.svg'
import _imports_1 from '@icon/attention.svg'
import _imports_2 from '@icon/warningIcon.svg'
import _imports_3 from '@icon/close.svg'


const _hoisted_1 = { class: "alert" }
const _hoisted_2 = { class: "alert__content" }
const _hoisted_3 = { class: "alert__card__header" }
const _hoisted_4 = { class: "alert__title" }
const _hoisted_5 = { class: "alert__card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_AlertText = _resolveComponent("AlertText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["alert__card", [
          `alert__card-${_ctx.alert.type.toLowerCase()}`,
          _ctx.alert.timeout === 0 && `alert__card--hidden`,
          _ctx.alert.timeout === -1 && `alert__card--hidden alert__message--removed`
        ]])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.alert.type === 'SUCCESS')
              ? (_openBlock(), _createBlock(_component_icon, {
                  key: 0,
                  data: _imports_0,
                  fill: false,
                  width: "16px",
                  height: "16px",
                  color: "#4CAB26"
                }))
              : _createCommentVNode("", true),
            (_ctx.alert.type === 'ERROR')
              ? (_openBlock(), _createBlock(_component_icon, {
                  key: 1,
                  data: _imports_1,
                  fill: true,
                  width: "16px",
                  height: "16px",
                  color: "#D32F2F"
                }))
              : _createCommentVNode("", true),
            (_ctx.alert.type === 'WARNING')
              ? (_openBlock(), _createBlock(_component_icon, {
                  key: 2,
                  data: _imports_2,
                  fill: true,
                  width: "16px",
                  height: "16px",
                  color: "#C6A700"
                }))
              : _createCommentVNode("", true),
            (_ctx.alert.contentTitle?.length)
              ? (_openBlock(), _createBlock(_component_AlertText, {
                  key: 3,
                  content: _ctx.alert.contentTitle
                }, null, 8, ["content"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            class: "alert__close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlert')))
          }, [
            _createVNode(_component_icon, {
              data: _imports_3,
              fill: false,
              width: "1em",
              height: "1em",
              color: "#000000"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.alert.content?.length)
            ? (_openBlock(), _createBlock(_component_AlertText, {
                key: 0,
                content: _ctx.alert.content
              }, null, 8, ["content"]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ])
  ]))
}