import {
  FilterBacklog,
  ItemStatus,
  TypeConfig,
  UploadAttachmentFn,
  User,
} from "@/models"
import { Actions, Getters, Modules } from "@/store"
import { $t } from "@/plugins/i18n"
import { isLocalHost, isStaging } from "@/app.config"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { compareAsc, startOfDay } from "date-fns"
import { ExtendValueForChart } from "@/models/Chart/Chart"

export const observerOptions = {
  root: null,
  scrollMargin: "0px 0px 240px 0px",
  threshold: 0.5,
}

const getMap = (modules: Modules, actions: typeof Actions | typeof Getters, isGetter?: boolean) => {
  const map = {}

  for (const key in actions) {
    map[key] = isGetter ? `${modules}/get/${key}` : `${modules}/${key}`
  }
  return map
}

export const addClassToBody = (className: string) => document.body.classList.add(className)

export const removeClassFromBody = (className: string) => document.body.classList.remove(className)

export const replaceTextDecorationTags = html => html.replace(/style="[^"]*"/gi, "").replace(/<br\s*\/?>|<\/?(b|i|u|s|sup|sub|font|strong|em)\s*>/gi, "")

export const compareOnlyDates = (startDate: Date, enDate: Date) => compareAsc(startOfDay(startDate), startOfDay(enDate))

export const replaceHtmlTags = html => html ? html.replace(/<\/?[a-zA-Z]+>/gi, "").replace(/&nbsp;/g, " ").trim() : ""

export const replaceHtmlTagsV2 = html => html
  ? html
    .replace(/<\/?[a-zA-Z]+[^>]*>/gi, "")
    .replace(/&nbsp;/g, " ")
    .trim()
  : ""

export const replaceHtmlTagsForEditor = html => html
  .replace(/<(\/?)(p|br|span)([^>]*)>/gi, tag => tag
    .includes("style=") ? tag : "")
  .replace(/&nbsp;/g, " ").trim()

export const replaceDashToSpace = text => text.replace(/-+/g, " ")

export const toLowerCaseWithTrim = text => text.toLowerCase().trim()

export const checkValidDate = (dateString) => {
  const dateWithoutTime = dateString.toString().split("T")[0]
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(dateWithoutTime)
}

export const stripHtml = (html) => {
  const tmp = document.createElement("div")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

export const setBacklogQuery = (mainId) => {
  const query = (mainId ? mainId : "BacklogProduct") as string
  let main = $t("backlog.query.BacklogProduct")
  if (query.includes("BacklogSprint")) {
    const sequenceNumber = query.split("#")[1]
    main = `${$t("backlog.query.BacklogSprint")}#${sequenceNumber}`
  } else {
    main = $t(`backlog.query['${query}']`)
  }

  return main
}

export const hideElementByAccount = (currentUser: User) => {
  if (!isStaging() && !isLocalHost()) {
    return !currentUser.accounts.find(account => account.urlName === "project-simple")
  }
  return false
}

export const parseBacklogQuery = (queryMain) => {
  const query = (queryMain && queryMain.length && queryMain !== "currentSprint" ? queryMain : "product") as string
  let main = $t("backlog.main.product")

  if (query?.includes("sprint")) {
    const sequenceNumber = query?.split("#")[1]
    main = `${$t("backlog.main.sprint")}#${sequenceNumber}`
  } else {
    main = $t(`backlog.main['${query}']`)
  }

  return main
}

export const getActionsMap = (modules: Modules, actions: typeof Actions) => getMap(modules, actions)

export const getGettersMap = (modules: Modules, actions: typeof Getters) => getMap(modules, actions, true)

export const stepBackInBrowserHistory = route => !route.query?.login && !!window.history.state.back || route.query?.i


export const uploadAttachments = async ({
  upload, files, view, config, position, type, editor,
}: {
  upload: UploadAttachmentFn,
  files,
  view,
  config: TypeConfig | null,
  type?: string,
  position?: number,
  editor?: any,
}) => {
  const { schema } = view.state
  const insertPosition = position || view.state.selection.$to.pos + 1


  if (!type) {
    const tempIds = name => `temp-${name}-${Date.now()}-${Math.random()}`

    const tempNode = schema.nodes.attachment.create({
      id: tempIds(files[0].name),
      title: files[0].name,
      isSkeletor: true,
    })
    view.dispatch(view.state.tr.insert(insertPosition, tempNode))
  }


  let filesMetadata
  try {
    filesMetadata = await upload({
      attachments: files as Blob[],
      ...(config ? {
        resourceType: config.type,
        targetId: config.targetId,
        excludeDuplicate: true,
      } : {}),
    })
  } catch (error) {
    console.error("Error uploading files:", error)
    return
  }

  let deleteFrom = null
  let deleteTo = null

  view.state.doc.descendants((node, pos) => {
    if (node.attrs.isSkeletor) {
      deleteFrom = pos
      deleteTo = pos + node.nodeSize
    }
  })

  if (deleteFrom !== null && deleteTo !== null) {
    view.dispatch(view.state.tr.delete(deleteFrom, deleteTo))
  }

  filesMetadata.forEach((file) => {
    if (editor) {
      filePreviewForEditor({ file, editor, position: insertPosition, view })
    } else {
      const newNode = schema.nodes.attachment.create({
        id: file.id,
        title: file.name,
        info: JSON.stringify(file),
        linkText: "",
      })
      view.dispatch(view.state.tr.insert(insertPosition, newNode))
    }
  })
  return {}
}

export const filePreviewForEditor = ({ file, editor, position, view }) => {
  const node = editor.schema.nodes.referenceHashFile.create({
    id: file.id,
    info: JSON.stringify(file),
    loading: false,
    configType: ReferenceType.FILE,
    linkText: "",
    title: file.name,
  })
  const transaction = view.state.tr.insert(position || 0, node)
  view.dispatch(transaction)
}

export const inputOnlyNumber = (event) => {
  if (!/\d/.test(event.key)) return event.preventDefault()
}

export const sortTask = (a, b) => Number(a.order) - Number(b.order)

export const wait = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds))

export const getImageSize = async (url: string): Promise<{ width, height }> => new Promise((resolve) => {
  const img = document.createElement("img")
  img.src = url
  img.onload = () => {
    resolve({ width: img.width, height: img.height })
  }
})

export const parsingProjectIdAndItemNumber = (itemParams: string) => {
  const parts = itemParams.split("-")
  const itemId = parts.pop() as string
  const projectId = parts.join("-")
  return {
    itemId,
    projectId,
  }
}

export const capitalizeOnlyFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const parsingServerResponseContent = ({ originalContent, serverResponseContent, numberOfPage, originalNumberOfPage } : {
  originalContent: any[],
  serverResponseContent: any[],
  numberOfPage: number,
  originalNumberOfPage?: number,
}) => {
  if (numberOfPage !== 0 && numberOfPage === originalNumberOfPage) {
    const copyOriginalContent = [...originalContent]
    serverResponseContent.forEach((item) => {
      if (originalContent.some(originalItem => originalItem.id === item.id)) return
      copyOriginalContent.push(item)
    })
    return copyOriginalContent
  }
  return numberOfPage === 0 ? serverResponseContent : [
    ...originalContent,
    ...serverResponseContent,
  ]
}

export const splitAndCapitalize = (string: string, delimiter = "_") => {
  if (!string) return ""
  return string
    .toString()
    .split(delimiter)
    .map(word => capitalizeOnlyFirstLetter(word))
    .join(" ")
}

export const getInitials = (name: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstPart, lastPart, ..._rest] = name.split(" ")

  let numberOfSymbols = 1
  if (!lastPart) {
    numberOfSymbols = 2
  }

  const initials = firstPart.substring(0, numberOfSymbols) + (lastPart?.substring(0, 1) ?? "")
  return initials.toUpperCase()
}

export const searchItemByText = (value) => {
  const trimValue = value.trim()
  const id = /^[0-9]+$/.test(trimValue) ? "sequenceNumberPrefix" : "text"
  return {
    id,
    value: trimValue,
  }
}

export const intervalCallback = <T>(config: {
  data: T[],
  interval: number,
  callback: (element: T) => void,
  onIntervalEndCallback?: () => void
}) => {
  let iterationIndex = 0

  const intervalId = setInterval(() => {
    const dataElement = config.data.at(iterationIndex)
    if (!dataElement) {
      // iteration is out of data array range
      clearInterval(intervalId)
      config.onIntervalEndCallback?.()
      return
    }

    config.callback(dataElement)
    iterationIndex++
  }, config.interval)
}

export const getValueByVariableName = ({ variableName, isDark } : { variableName: string, isDark?: boolean }) => {
  const root = isDark ?
    document.querySelector(":root.dark") as HTMLElement :
    document.querySelector(":root") as HTMLElement

  if (!root) {
    return ""
  }

  return getComputedStyle(root)?.getPropertyValue(variableName) as any
}

export const excludedStatusFromBacklog = ({ backlogFilter, itemStatus } : { backlogFilter: FilterBacklog, itemStatus: ItemStatus }) => {
  if (backlogFilter.storyStatuses?.length && !backlogFilter.storyStatuses.includes(itemStatus)) return true
  return !backlogFilter.storyStatuses?.includes(itemStatus) && backlogFilter.excludeStoryStatuses?.includes(itemStatus)
}

export const emailValidate = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return regex.test(email)
}

export const parsingUrlName = (value) => {
  const REGEX = /[^a-zA-Z0-9]/g

  const editedValue = value
    .replace(REGEX, "-")
    .replace(/-+/g, "-")
    .toLowerCase()
  return editedValue
}

export const extendValueForChart = (params: ExtendValueForChart) => {
  const { datasets, step } = params
  if (!datasets?.length) return 0
  const percent = params.percent / 100
  const data: number[] = []
  datasets.forEach((dataset) => {
    dataset.data.forEach((value) => {
      data.push(value)
    })
  })
  // Get the max value from the data
  const maxDataValue = Math.max(...data)
  // Add percent to the max value to make the chart look better
  const maxValue = Math.ceil((maxDataValue + maxDataValue * percent) / step) * step
  return maxValue === maxDataValue ? maxValue + step : maxValue
}
