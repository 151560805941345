import { Module, Mutation, Action } from "vuex-class-modules"
import { store } from "../store"
import { AlertActions, alertModule } from "./alert.module"
import { Modules } from "../modules"
import { Endpoint } from "@/services/endpoints"
import { Backlog } from "@/models/Backlog"
import { $t } from "@/plugins/i18n"
import {
  ExistsByNameOrUrlName,
  Project,
  ProjectOverview,
  ProjectWithStats,
} from "@/models/Project"
import { Team } from "@/models/Team"
import { ProjectPreview } from "@/models/Project"
import { ServerResponse } from "@/models/ServerResponse"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { forceUpdateToken } from "@/plugins/keycloak"
import { BaseModule } from "@/models/BaseModule"
import { accountModule } from "./account.module"
import { apiService } from "@/services/api.service"
import { FilterProject } from "@/models/Filter/FilterProject"
import { UpdateObjectValue } from "@/models/common"
import { parsingUrlName } from "@/utils/helpers"

export enum ProjectActions {
  GET_PROJECT = "GET_PROJECT",
  GET_PROJECTS = "GET_PROJECTS",
  CREATE_PROJECT = "CREATE_PROJECT",
  GET_PROJECT_BACKLOG = "GET_PROJECT_BACKLOG",
  GET_PROJECT_BACKLOG_MAP = "GET_PROJECT_BACKLOG_MAP",
  GET_PROJECT_NAME = "GET_PROJECT_NAME",
  CREATE_INITIAL_PROJECT = "CREATE_INITIAL_PROJECT",
  GET_PROJECT_TEAMS = "GET_PROJECT_TEAMS",
  ADD_PROJECT_TEAM = "ADD_PROJECT_TEAM",
  ASSIGN_PROJECT_TEAMS = "ASSIGN_PROJECT_TEAMS",
  GET_CURRENT_PROJECT = "GET_CURRENT_PROJECT",
  GET_CURRENT_PROJECTS = "GET_CURRENT_PROJECTS",
  GET_CURRENT_PROJECT_ID = "GET_CURRENT_PROJECT_ID",
  GET_PROJECT_BY_CURRENT_ID = "GET_PROJECT_BY_CURRENT_ID",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  SET_PROJECTS = "SET_PROJECTS",
  SET_CURRENT_PROJECT_ID = "SET_CURRENT_PROJECT_ID",
  ADD_TEAM_TO_PROJECT = "ADD_TEAM_TO_PROJECT",
  DELETE_TEAMS_TO_PROJECT = "DELETE_TEAMS_TO_PROJECT",
  PROJECT_OVERVIEW = "PROJECT_OVERVIEW",
  PROJECT_OVERVIEW_BY_ID = "PROJECT_OVERVIEW_BY_ID",
  GET_CLEAR_PROJECT_TEAMS = "GET_CLEAR_PROJECT_TEAMS",
  PROJECT_OVERVIEW_SERVER_RESPONSE = "PROJECT_OVERVIEW_SERVER_RESPONSE",
  CLEAR_PROJECT_OVERVIEWS = "CLEAR_PROJECT_OVERVIEWS",
  GET_PROJECT_WITH_STATS = "GET_PROJECT_WITH_STATS",
  GET_PROJECT_WITH_STATS_TEAM = "GET_PROJECT_WITH_STATS_TEAM",
  GET_PROJECT_WITH_STATS_USER = "GET_PROJECT_WITH_STATS_USER",
  CLEAR_PROJECT_WITH_STATS = "CLEAR_PROJECT_WITH_STATS",
  GET_PROJECT_WITH_STATS_SERVER_RESPONSE = "GET_PROJECT_WITH_STATS_SERVER_RESPONSE",
  SEARCH_PROJECTS_WITH_STATS = "SEARCH_PROJECTS_WITH_STATS",
  SEARCH_PROJECT_RESOURCE = "SEARCH_PROJECT_RESOURCE",
  REMOVE_TEAM_FROM_LOCAL_LIST = "REMOVE_TEAM_FROM_LOCAL_LIST",
  UPDATE_PROJECT_OVERVIEW_STATS = "UPDATE_PROJECT_OVERVIEW_STATS",
  GET_PROJECTS_AT_RISK = "GET_PROJECTS_AT_RISK",
  UPDATE_PROJECT_VALUE = "UPDATE_PROJECT_VALUE",
  FILTER_BY_PROJECT = "FILTER_BY_PROJECT",
  CREATE_DEMO_PROJECT = "CREATE_DEMO_PROJECT",
  RECREATE_DEMO_PROJECT = "RECREATE_DEMO_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  REPLACE_PROJECT_OVERVIEW_IN_LIST = "REPLACE_PROJECT_OVERVIEW_IN_LIST",
  CHECK_EXIST_PROJECT_BY_URL_OR_NAME = "CHECK_EXIST_PROJECT_BY_URL_OR_NAME",
  SHOW_DEMO_WIDGET = "SHOW_DEMO_WIDGET",
  ADD_TEAMS_TO_PROJECT = "ADD_TEAMS_TO_PROJECT",
  UPDATE_PROJECT_WITHOUT_MESSAGE = "UPDATE_PROJECT_WITHOUT_MESSAGE",
}

@Module
class ProjectModule extends BaseModule {
  project: Project = Project.createDefault()
  projects: Project[] = []
  backlogMap: { [key: string]: Backlog} = {}
  teams: Team[] = []
  currentProjectId: string
  projectOverview: ProjectOverview[] = []
  projectOverviewResponse: ServerResponse<ProjectOverview> | null
  projectsWithStats: ProjectWithStats[] = []
  projectsWithStatsResponse: ServerResponse<ProjectWithStats>
  projectsAtRisk: ProjectPreview[]
  filterByProject: Project | null = null
  showDemoWidget = false

  get accountId() {
    return accountModule.accountId
  }

  get accountCreating() {
    return accountModule.accountCreating
  }

  get [`get/${ProjectActions.GET_PROJECT}`]() {
    return this.project
  }

  get [`get/${ProjectActions.GET_PROJECTS_AT_RISK}`]() {
    return this.projectsAtRisk
  }

  get [`get/${ProjectActions.GET_PROJECTS}`]() {
    return this.projects
  }

  get [`get/${ProjectActions.GET_PROJECT_WITH_STATS}`]() {
    return this.projectsWithStats
  }

  get [`get/${ProjectActions.GET_PROJECT_WITH_STATS_SERVER_RESPONSE}`]() {
    return this.projectsWithStatsResponse
  }

  get [`get/${ProjectActions.GET_PROJECT_TEAMS}`]() {
    return this.teams
  }

  get [`get/${ProjectActions.GET_PROJECT_BACKLOG_MAP}`]() {
    return this.backlogMap
  }

  get [`get/${ProjectActions.GET_PROJECT_NAME}`]() {
    return this.project?.name
  }

  get [`get/${ProjectActions.GET_CURRENT_PROJECT_ID}`]() {
    return this.currentProjectId
  }

  get [`get/${ProjectActions.GET_PROJECT_BY_CURRENT_ID}`]() {
    return this.projects.find(item => item.id === this.currentProjectId)
  }

  get [`get/${ProjectActions.PROJECT_OVERVIEW}`]() {
    return this.sortProjectOverviewByDemo(this.projectOverview)
  }

  get [`get/${ProjectActions.PROJECT_OVERVIEW_SERVER_RESPONSE}`]() {
    if (!this.projectOverviewResponse) return null
    this.projectOverviewResponse.content = this.sortProjectOverviewByDemo(this.projectOverviewResponse.content)
    return this.projectOverviewResponse
  }

  get [`get/${ProjectActions.FILTER_BY_PROJECT}`]() {
    return this.filterByProject
  }

  get [`get/${ProjectActions.SHOW_DEMO_WIDGET}`]() {
    return this.showDemoWidget
  }

  sortProjectOverviewByDemo(projectsOverview: ProjectOverview[]) {
    return projectsOverview.sort((a, b) => {
      if (a.project.demo) {
        return -1
      }
      if (b.project.demo) {
        return 1
      }
      return 0
    })
  }

  @Mutation
  setProject(project: Project) {
    this.project = project
    this.currentProjectId = project.id
  }

  @Mutation
  setProjects(projects: Project[]) {
    this.projects = projects
  }

  @Mutation
  setProjectValue(payload: UpdateObjectValue<Project>) {
    Object.assign(this.project, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setProjectOverviewStats({ field, value, projectId } : {field: string, value: number, projectId: string}) {
    this.projectOverview = this.projectOverview.map((e) => {
      if (e.project.id === projectId) {
        e.stats[field] = value
        return e
      } else {
        return e
      }
    })
  }

  @Mutation
  addProjectsWithStats(projectsWithStats: ProjectWithStats[]) {
    this.projectsWithStats = [...this.projectsWithStats, ...projectsWithStats]
  }

  @Mutation
  setProjectsWithStats(projectsWithStats: ProjectWithStats[]) {
    this.projectsWithStats = projectsWithStats
  }

  @Mutation
  setProjectsWithStatsResponse(response: ServerResponse<ProjectWithStats>) {
    this.projectsWithStatsResponse = response
  }

  @Mutation
  setBacklog({ projectId, backlog }: { projectId: string, backlog: Backlog }) {
    this.backlogMap[projectId] = backlog
  }

  @Mutation
  addTeam(team: Team) {
    if (!this.teams.some(t => t.id === team.id)) {
      this.teams.push(team)
    }
  }

  @Mutation
  setTeams(teams: Team[]) {
    this.teams = teams
  }

  @Mutation
  setAtRisk(projects: ProjectPreview[]) {
    this.projectsAtRisk = projects
  }

  @Mutation
  setCurrentProjectId(projectId: string) {
    this.currentProjectId = projectId
  }

  @Mutation
  setProjectOverviewResponse(response: ServerResponse<ProjectOverview> | null) {
    this.projectOverviewResponse = response
  }

  @Mutation
  addProjectOverview(projectOverview: ProjectOverview[]) {
    this.projectOverview = [...this.projectOverview, ...projectOverview]
  }

  @Mutation
  setProjectOverview(projectOverview: ProjectOverview[]) {
    this.projectOverview = projectOverview
  }

  @Mutation
  clearTeams() {
    this.teams = []
  }

  @Mutation
  setFilterByProject(filterByProject: Project | null) {
    this.filterByProject = filterByProject
  }

  @Mutation
  replaceProjectOverviewInList(projectOverview: ProjectOverview) {
    const findProjectInList = this.projectOverview.find(e => e.project.id === projectOverview.project.id || e.project.demo && projectOverview.project.demo)
    if (!findProjectInList) {
      this.projectOverview.push(projectOverview)
    } else {
      this.projectOverview = this.projectOverview.map((e) => {
        if (e.project.id === projectOverview.project.id || e.project.demo && projectOverview.project.demo) {
          return projectOverview
        } else {
          return e
        }
      })
    }
  }

  @Mutation
  removeProjectFromList(removeProjectId: string) {
    this.projectOverview = this.projectOverview.filter(projectOverview => projectOverview.project.id !== removeProjectId)
    this.projects = this.projects.filter(project => project.id !== removeProjectId)
    this.projectsWithStats = this.projectsWithStats.filter(projectWithStats => projectWithStats.project.id !== removeProjectId)
    if (this.projectOverviewResponse) {
      this.projectOverviewResponse.content = this.projectOverviewResponse.content.filter(project => project.project.id !== removeProjectId)
    }
  }

  @Mutation
  updateShowDemoWidget(value: boolean) {
    this.showDemoWidget = value
  }

  @Action
  [ProjectActions.CREATE_INITIAL_PROJECT]() {
    this.setProject(Project.createDefault())
    this.setTeams([])
  }

  @Action
  [ProjectActions.ADD_PROJECT_TEAM](team: Team) {
    this.addTeam(team)
  }

  @Action
  async [ProjectActions.GET_PROJECT]({ projectId, onlyGet } : {projectId: string, onlyGet?: boolean}) {
    const data = await apiService.get<Project>(Endpoint.PROJECT_BY_ID(this.accountId, projectId))
    return this.handleResponse<Project>(data, (data) => {
      const project = Project.create(data)
      if (!onlyGet) {
        this.setProject(project)
      }
      return project
    })
  }

  @Action
  async [ProjectActions.GET_PROJECTS](filter?) {
    if (filter) {
      filter.size = 100
    }
    const data = await apiService.get<ServerResponse<Project>>(Endpoint.PROJECT(this.accountId), filter ?? {
      size: 100,
    })
    return this.handleResponse<ServerResponse<Project>>(data, (data) => {
      const projects = data.content.map(project => Project.create(project))
      this.setProjects(projects)
      return projects
    })
  }

  @Action
  async [ProjectActions.SEARCH_PROJECT_RESOURCE](name: string) {
    const data = await apiService.get<ProjectPreview>(Endpoint.PROJECT_SEARCH(this.accountId), {
      name,
    })
    return this.handleResponse<ProjectPreview>(data, (data) => {
      const { id, name } = data

      return [{
        id: id,
        textPreview: name,
      }]
    })
  }

  @Action
  async [ProjectActions.CHECK_EXIST_PROJECT_BY_URL_OR_NAME]({ name, checkByField } : { name: string, checkByField: string }) {
    const requestParams = checkByField ? {
      [checkByField]: name,
    } : {
      name,
      urlName: parsingUrlName(name),
    }
    const data = await apiService.post<ExistsByNameOrUrlName>(Endpoint.CHECK_EXIST_PROJECT_BY_URL_OR_NAME(this.accountId), requestParams)
    return this.handleResponse<ExistsByNameOrUrlName>(data, data => data)
  }

  @Action
  async [ProjectActions.GET_PROJECT_WITH_STATS_TEAM](params: FilterProject) {
    const filter = FilterProject.create(params)
    const accountId = filter.accountId ? filter.accountId : this.accountId

    const data = await apiService.get<ServerResponse<ProjectWithStats>>(Endpoint.GET_PROJECT_WITH_STATS_TEAM(accountId, filter.teamId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<ProjectWithStats>>(data, (data) => {
      this.setProjectsWithStatsResponse(data)
      if (filter.page === 1) {
        this.setProjectsWithStats(data.content)
      } else {
        this.addProjectsWithStats(data.content)
      }
    })
  }

  @Action
  async [ProjectActions.GET_PROJECT_WITH_STATS_USER](params: FilterProject) {
    const filter = FilterProject.create(params)

    const data = await apiService.get<ServerResponse<ProjectWithStats>>(Endpoint.GET_PROJECT_WITH_STATS_USER(this.accountId, filter.userId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<ProjectWithStats>>(data, (data) => {
      this.setProjectsWithStatsResponse(data)
      if (filter.page === 1) {
        this.setProjectsWithStats(data.content)
      } else {
        this.addProjectsWithStats(data.content)
      }
    })
  }

  @Action
  async [ProjectActions.SEARCH_PROJECTS_WITH_STATS](params: FilterProject) {
    const filter = FilterProject.create(params)
    const data = await apiService.get<ServerResponse<ProjectOverview>>(Endpoint.ADMIN_PROJECT_SEARCH(filter.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<ProjectWithStats>>(data, (data) => {
      this.setProjectsWithStatsResponse(data)
      if (filter.page === 1) {
        this.setProjectsWithStats(data.content)
      } else {
        this.addProjectsWithStats(data.content)
      }
    })
  }

  @Action
  async [ProjectActions.GET_CURRENT_PROJECTS]() {
    const data = await apiService.get<ServerResponse<Project>>(Endpoint.USER_CURRENT_PROJECTS_BY_ACCOUNT(this.accountId))
    return this.handleResponse<ServerResponse<Project>>(data, (data) => {
      this.setProjects(data.content.map(project => Project.create(project)))
    })
  }

  @Action
  async [ProjectActions.CREATE_PROJECT](project: Project) {
    const data = await apiService.post<Project>(Endpoint.PROJECT(this.accountId), project.getJsonObj())
    return this.handleResponse<Project>(data, async (data) => {
      if (!this.accountCreating) {
        const alertContent: AlertContentItem[] = [
          {
            text: "Project ",
            type: "regular",
          },
          {
            text: `${project.name} `,
            type: "bold",
          },
          {
            text: $t("alert.created"),
            type: "regular",
          },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }
      await forceUpdateToken()
      await this[ProjectActions.ASSIGN_PROJECT_TEAMS]({
        project: data,
        teamIds: this.teams.map(team => team.id),
      })
      const newProject = Project.create(data)
      this.setProject(newProject)
      return newProject
    })
  }

  @Action
  async [ProjectActions.UPDATE_PROJECT](project: Project) {
    const teamIds = this.teams.map(team => team.id)
    const data = await apiService.put<Project>(Endpoint.PROJECT_BY_ID(this.accountId, project.id), {
      ...project,
      teamIds: teamIds,
    })
    return this.handleResponse<Project>(data, async (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Project ",
          type: "regular",
        },
        {
          text: `${project.name} `,
          type: "bold",
        },
        {
          text: $t("alert.updated"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setProject(Project.create(data))
    })
  }

  @Action
  async [ProjectActions.UPDATE_PROJECT_WITHOUT_MESSAGE]({ project, onlySend }: { project: Project, onlySend: boolean }) {
    const data = await apiService.put<Project>(Endpoint.PROJECT_BY_ID(this.accountId, project.id), project)
    return this.handleResponse<Project>(data, async (data) => {
      const project = Project.create(data)
      if (!onlySend) {
        this.setProject(project)
      }
      return project
    })
  }

  @Action
  async [ProjectActions.ASSIGN_PROJECT_TEAMS](payload: { project: Project, teamIds: string[] }) {
    const data = await apiService.put<Project>(Endpoint.PROJECT_BY_ID(this.accountId, payload.project.id), {
      ...payload.project,
      teamIds: payload.teamIds,
    })
    return this.handleResponse<Project>(data)
  }

  @Action
  async [ProjectActions.ADD_TEAMS_TO_PROJECT](payload: { projectId?: string, teamIds: string[] }) {
    const projectId = payload.projectId ?? this.currentProjectId
    const data = await apiService.post<string[]>(Endpoint.ADD_TEAMS_TO_PROJECT(this.accountId, projectId), payload.teamIds)
    return this.handleResponse<string[]>(data, () => {
      this.setProjectValue({
        id: "teamIds",
        value: payload.teamIds,
      } as any)
    })
  }

  @Action
  async [ProjectActions.GET_CURRENT_PROJECT_ID](projectId?: string) {
    if (projectId) {
      this.setCurrentProjectId(projectId)
      await this[ProjectActions.GET_PROJECT]({ projectId })
    } else {
      await this[ProjectActions.GET_PROJECTS]()
      this.setCurrentProjectId(this.projects[0].id)
    }
  }

  @Action
  async [ProjectActions.GET_PROJECT_TEAMS](projectId: string) {
    const data = await apiService.get<Team[]>(Endpoint.TEAM_IN_PROJECT(this.accountId, projectId))
    return this.handleResponse<Team[]>(data, (data) => {
      const teams = data.map(team => Team.create(team))
      this.setTeams(teams)
      return teams
    })
  }

  @Action
  async [ProjectActions.GET_CURRENT_PROJECT]() {
    if (this.currentProjectId) {
      await this[ProjectActions.GET_PROJECT]({
        projectId: this.currentProjectId,
      })
    }
  }

  @Action
  async [ProjectActions.SET_CURRENT_PROJECT_ID](projectId: string) {
    this.setCurrentProjectId(projectId)
  }

  @Action
  async [ProjectActions.FILTER_BY_PROJECT](project: Project) {
    this.setFilterByProject(project)
  }

  @Action
  async [ProjectActions.SET_PROJECTS](projects: Project[]) {
    this.setProjects(projects)
  }

  @Action
  async [ProjectActions.ADD_TEAM_TO_PROJECT](payload: { projectId: string, team: Team }) {
    const data = await apiService.post<Project>(Endpoint.ADD_PROJECT_TO_TEAM(this.accountId, payload.projectId, payload.team.id))
    return this.handleResponse<Project>(data, () => {
      const alertContent : AlertContentItem[] = [
        {
          text: "Team ",
          type: "regular",
        },
        {
          text: `${payload.team.name} `,
          type: "bold",
        },
        {
          text: "added to Project",
          type: "regular",
        },
      ]
      if (!this.accountCreating) {
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertContent,
        })
      }
    })
  }

  @Action
  async [ProjectActions.DELETE_TEAMS_TO_PROJECT](payload: { projectId: string, team: Team }) {
    const data = await apiService.delete<Project>(Endpoint.ADD_PROJECT_TO_TEAM(this.accountId, payload.projectId, payload.team.id))
    this.handleResponse<Project>(data, async () => {
      const alertContent : AlertContentItem[] = [
        {
          text: "Team ",
          type: "regular",
        },
        {
          text: `${payload.team.name} `,
          type: "bold",
        },
        {
          text: "was removed from Project",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [ProjectActions.PROJECT_OVERVIEW](params: FilterProject) {
    const filter = FilterProject.create(params)
    const accountId = filter.accountId ? filter.accountId : this.accountId

    const endpoint = filter.q ? Endpoint.PROJECT_OVERVIEW_SEARCH(accountId) : Endpoint.PROJECT_OVERVIEW(accountId)
    const data = await apiService.get<ServerResponse<ProjectOverview>>(endpoint, filter.getJsonObj())
    return this.handleResponse<ServerResponse<ProjectOverview>>(data, (data) => {
      this.setProjectOverviewResponse(data)
      if (filter.page === 1) {
        this.setProjectOverview(data.content)
      } else {
        this.addProjectOverview(data.content)
      }
    })
  }

  @Action
  async [ProjectActions.PROJECT_OVERVIEW_BY_ID](payload: { projectId: string, onlyGet?: boolean }) {
    const data = await apiService.get<ProjectOverview>(Endpoint.PROJECT_OVERVIEW_BY_ID(this.accountId, payload.projectId))
    return this.handleResponse<ProjectOverview>(data, (data) => {
      if (!payload.onlyGet) {
        this.setProjectOverviewResponse(null)
        this.setProjectOverview([data])
      }
      return data
    })
  }

  @Action
  async [ProjectActions.DELETE_PROJECT]({ project, accountId } : { project: Project, accountId: string }) {
    const data = await apiService.delete<void>(Endpoint.DELETE_PROJECT(accountId, project.id))
    return this.handleResponse<void>(data, () => {
      const alertContent : AlertContentItem[] = [
        {
          text: "Project ",
          type: "regular",
        },
        {
          text: `${project.name} `,
          type: "bold",
        },
        {
          text: "deleted",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.removeProjectFromList(project.id)
    })
  }

  @Action
  async [ProjectActions.GET_PROJECTS_AT_RISK]() {
    const data = await apiService.get<ProjectPreview[]>(Endpoint.PROJECT_OVERVIEW_AT_RISK(this.accountId))
    return this.handleResponse<ProjectPreview[]>(data, (data) => {
      this.setAtRisk(data)
    })
  }

  @Action
  async [ProjectActions.CREATE_DEMO_PROJECT]() {
    const data = await apiService.post<Project>(Endpoint.CREATE_DEMO_PROJECT(this.accountId))
    return this.handleResponse<Project>(data, data => Project.create(data))
  }

  @Action
  async [ProjectActions.RECREATE_DEMO_PROJECT]() {
    const data = await apiService.post<Project>(Endpoint.RECREATE_DEMO_PROJECT(this.accountId))
    return this.handleResponse<Project>(data, data => Project.create(data))
  }

  @Action
  [ProjectActions.CLEAR_PROJECT_OVERVIEWS]() {
    this.setProjectOverview([])
  }

  @Action
  [ProjectActions.UPDATE_PROJECT_VALUE](payload: UpdateObjectValue<Project>) {
    this.setProjectValue(payload)
  }

  @Action
  [ProjectActions.CLEAR_PROJECT_WITH_STATS]() {
    this.setProjectsWithStats([])
  }

  @Action
  [ProjectActions.GET_CLEAR_PROJECT_TEAMS]() {
    this.clearTeams()
  }

  @Action
  [ProjectActions.REPLACE_PROJECT_OVERVIEW_IN_LIST](projectOverview: ProjectOverview) {
    this.replaceProjectOverviewInList(projectOverview)
  }

  @Action
  [ProjectActions.UPDATE_PROJECT_OVERVIEW_STATS]({ field, value, projectId } : {field: string, value: number, projectId: string}) {
    this.setProjectOverviewStats({ field, value, projectId })
  }


  @Action
  [ProjectActions.REMOVE_TEAM_FROM_LOCAL_LIST](team: Team) {
    const filterTeams = this.teams.filter(e => e.id !== team.id)
    this.setTeams(filterTeams)
  }

  @Action
  [ProjectActions.SHOW_DEMO_WIDGET](value: boolean) {
    this.updateShowDemoWidget(value)
  }
}

export const projectModule = new ProjectModule({
  store,
  name: Modules.PROJECT,
})
