import { HttpError } from "@/models/Error"
import axios, { AxiosRequestConfig, Method } from "axios"
import { Endpoint } from "./endpoints"
import { errorHandlerService } from "./error-handler.service"
import { Observable, Subject, map } from "rxjs"
import { catchError, of } from "rxjs"
import { trackService } from "./track.service"

interface Response<T> {
  response?: T,
  error?: HttpError
}

export type RequestBody = { [key: string]: any } | number | string | undefined | null
export type RequestParams = { [key: string]: any } | undefined

class ApiService {
  async request<T>(method: Method, endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    try {
      const { data } = await axios[method](endpoint.url, body, params)
      trackService.setPageLoadEventData(method, endpoint.logUrl, body)
      return data
    } catch (e) {
      if (endpoint.logsError) {
        errorHandlerService.onApiError(e)
      }
      return new Promise(resolve => resolve(new HttpError(e as HttpError)))
    }
  }

  async get<T>(endpoint: Endpoint, params?: RequestParams): Promise<T | HttpError> {
    return this.request("get", endpoint, { params })
  }
  async getBlob<T>(endpoint: Endpoint): Promise<T | HttpError> {
    return this.request("get", endpoint, { responseType: "blob" })
  }
  async post<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("post", endpoint, body, this.getConfig(body, params))
  }
  async put<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("put", endpoint, body, { params })
  }
  async patch<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("patch", endpoint, body, this.getConfig(body, params))
  }
  async delete<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("delete", endpoint, { data: body, params: params })
  }

  request$<T>(method: Method, endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Observable<T> {
    const subject = new Subject<T>()
    try {
      axios[method](endpoint.url, body, params).then(({ data }) => {
        subject.next(data)
      })
    } catch (e) {
      errorHandlerService.onApiError(e)
      subject.error(e as HttpError)
    }
    return subject
  }

  get$<T>(endpoint: Endpoint, params?: RequestParams): Observable<Response<T>> {
    return this.request$<T>("get", endpoint, { params })
      .pipe(
        map((response: T) => ({ response })),
        catchError((error: HttpError) => of({ error })),
      )
  }
  getBlob$<T>(endpoint: Endpoint): Promise<T | HttpError> {
    return this.request("get", endpoint, { responseType: "blob" })
  }
  post$<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("post", endpoint, body, this.getConfig(body, params))
  }
  put$<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("put", endpoint, body, { params })
  }
  patch$<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("patch", endpoint, body, { params })
  }
  delete$<T>(endpoint: Endpoint, body?: RequestBody, params?: RequestParams): Promise<T | HttpError> {
    return this.request("delete", endpoint, { data: body, params: params })
  }

  getConfig(data: RequestBody, params?: RequestParams): AxiosRequestConfig {
    const config = { ...params } as AxiosRequestConfig
    if (data instanceof FormData) {
      config.headers = {
        "Content-Type": "multipart/form-data",
      }
    }
    else if (typeof data === "string") {
      config.headers = {
        "Content-Type": "text/plain",
      }
    }
    return config
  }
}

export const apiService = new ApiService()
