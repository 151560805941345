import {
  ItemStatusActions,
  itemStatusModule,
} from "@/store/modules/item/itemStatus.module"

export class Status {
  id: string
  label: string
  statusPriority?: number

  constructor({ id, label, statusPriority } : { id: string, label: string, statusPriority?:number}) {
    this.id = id ?? ""
    this.label = label ?? ""
    this.statusPriority = statusPriority ?? 0
  }

  get stringId() {
    return `is_${this.id.toLowerCase()}`
  }

  static create({ label, id, statusPriority } : { label: string, id: string, statusPriority:number }): Status {
    return new Status({ id, label, statusPriority })
  }

  static createById({ id } : { id: string }): Status {
    const status = itemStatusModule[`get/${ItemStatusActions.GET_STATUS_BY_ID}`](id)
    return new Status(status)
  }
}
