import mitt from 'mitt'

const emitter = mitt();

export const emitterEmit = (key: string, value?: any) => {
  emitter.emit(key, value)
}

export const emitterOn = (key: string, value?: any) => {
  emitter.on(key, value)
}

export const emitterDeleteByKey = (key: string) => {
  emitter.all.delete(key)
}

export const emitterDeleteAll = () => {
  emitter.all.clear()
}