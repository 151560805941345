import { Filter } from "@/models/Pageable"
import { GoalStatus } from "../Goal"

export class FilterGoal extends Filter {
  statuses?: GoalStatus[]
  projectId?: string
  demo?: boolean

  constructor(props) {
    super(props)

    this.statuses = props.statuses ?? []
    this.projectId = props.projectId ?? ""
    this.demo = props.demo
  }

  getJsonObj() {
    const data = {
      statuses: this.statuses,
      projectId: this.projectId,
      demo: this.demo,
    }

    return super.getJsonObj({ params: data })
  }

  get hasProjectFilter() {
    return this.projectId?.length
  }

  static create(props) {
    return new FilterGoal(props)
  }

  static createDefault() {
    const props = {}

    return new FilterGoal(props)
  }
}
