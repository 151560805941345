import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.haveBrowserHistory)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)), ["prevent","stop"]))
      }, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          _createTextVNode(" Back ")
        ])
      ]))
    : _createCommentVNode("", true)
}