// decorators.js
import { createDecorator } from "vue-class-component"
import { debounce } from "debounce"

const Debounce = (waitMs: number) => createDecorator((options, key) => {
  const originalMethod = options.methods[key]
  const debounceMethod = debounce(originalMethod, waitMs)

  options.methods[key] = async function (...value) {
    await debounceMethod.apply(this, value)
  }
})

export default Debounce
