import { Module, Action, Mutation } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import {
  HealthCheckQuestion,
  CreateSprintHealthCheckDto,
  HealthCheckSummary,
  HealthCheck,
} from "@/models/HealthCheck"

import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { ProjectActions, projectModule } from "./project.module"

export enum HealthCheckActions {
  GET_HEALTH_CHECK_QUESTIONS = "GET_HEALTH_CHECK_QUESTIONS",
  SAVE_HEALTH_CHECK = "SAVE_HEALTH_CHECK",
  GET_HEALTH_CHECK_SUMMARY = "GET_HEALTH_CHECK_SUMMARY",
}

@Module
class HealthCheckModule extends BaseModule {
  healthCheckSummary: HealthCheckSummary = new HealthCheckSummary()
  questions: HealthCheckQuestion[] = [
    {
      title: "How happy are you with the results of this Sprint?",
      id: "SPRINT_HAPPINESS",
      options: [
        {
          id: "HEALTHY",
          label: "Absolutely",
          icon: "face-happy",
        },
        {
          id: "NEUTRAL",
          label: "Its OK",
          icon: "face-normally",
        },
        {
          id: "UNHEALTHY",
          label: "Not Really",
          icon: "face-sadness",
        },
      ],
    },
    {
      title: "How would you rate your Work-Life Balance?",
      id: "WORK_LIFE_BALANCE",
      options: [
        {
          id: "HEALTHY",
          label: "Very Good",
          icon: "face-happy",
        },
        {
          id: "UNHEALTHY",
          label: "Feeling Overworked",
          icon: "face-sadness",
        },
      ],
    },
    {
      title: "Is Work Satisfying?",
      id: "IS_WORK_SATISFYING",
      options: [
        {
          id: "HEALTHY",
          label: "Absolutely",
          icon: "face-happy",
        },
        {
          id: "NEUTRAL",
          label: "Its OK",
          icon: "face-normally",
        },
        {
          id: "UNHEALTHY",
          label: "Not Really",
          icon: "face-sadness",
        },
      ],
    },
    {
      title: "What's your overall feeling about Team environment and Project?",
      id: "ATTITUDE",
      options: [
        {
          id: "HEALTHY",
          label: "Energized",
          icon: "face-happy",
        },
        {
          id: "NEUTRAL",
          label: "Concerned",
          icon: "face-normally",
        },
        {
          id: "UNHEALTHY",
          label: "Disappointed",
          icon: "face-sadness",
        },
      ],
    },
  ]

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${HealthCheckActions.GET_HEALTH_CHECK_QUESTIONS}`]() {
    return this.questions
  }

  get [`get/${HealthCheckActions.GET_HEALTH_CHECK_SUMMARY}`]() {
    return this.healthCheckSummary
  }

  @Mutation
  setHealthCheckSummary(healthCheckSummary: HealthCheckSummary) {
    this.healthCheckSummary = healthCheckSummary
  }

  @Action
  async [HealthCheckActions.SAVE_HEALTH_CHECK]({ projectId, sprintId, survey, alertMessage } : { projectId: string, sprintId: string, survey: CreateSprintHealthCheckDto[], alertMessage?: AlertContentItem[] }) {
    const data = await apiService.post<void>(Endpoint.SAVE_HEALTH_CHECK(projectId, sprintId), survey)
    return this.handleResponse<void>(data, () => {
      if (alertMessage) {
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertMessage,
        })
      }
    })
  }

  @Action
  async [HealthCheckActions.GET_HEALTH_CHECK_SUMMARY]({ projectId, sprintId } : { projectId?: string, sprintId: string }) {
    const data = await apiService.get<HealthCheck>(Endpoint.HEALTH_CHECK_SUMMARY(projectId ?? this.projectId, sprintId))
    return this.handleResponse<HealthCheck>(data, (data) => {
      const healthCheckSummary = new HealthCheckSummary(data)
      this.setHealthCheckSummary(healthCheckSummary)
      return healthCheckSummary
    })
  }
}

export const healthCheckModule = new HealthCheckModule({
  store,
  name: Modules.HEALTH_CHECK,
})
