import { User } from "./User"

export type EstimationProps = Partial<Estimation>

export class Estimation {
  user: User
  dev: number | null
  qa: number | null

  constructor(props: EstimationProps) {
    if (!props) return

    this.user = props.user ?? User.createDefault()
    this.dev = props.dev ?? null
    this.qa = props.qa ?? null
  }

  static create(props: EstimationProps): Estimation {
    return new Estimation(props)
  }
}
