import { FormatDistanceFn } from "date-fns/types"

const formatDistanceLocale = {
  now: "now",
  lessThanXSeconds: "{{count}} sec",
  xSeconds: "{{count}} sec",
  halfAMinute: "30 sec",
  lessThanXMinutes: "{{count}} min",
  xMinutes: "{{count}} min",
  aboutXHours: "{{count}} hr",
  xHours: "{{count}} hr",
  xDays: "{{count}} day",
  aboutXWeeks: "{{count}} w",
  xWeeks: "{{count}} w",
  aboutXMonths: "{{count}} mon",
  xMonths: "{{count}} mon",
  aboutXYears: "{{count}} year",
  xYears: "{{count}} year",
  overXYears: "{{count}} year",
  almostXYears: "{{count}} year",
}

const formatDistance: FormatDistanceFn = (token, count, options) => {
  options = options || {}

  const result = formatDistanceLocale[token].replace("{{count}}", count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return result + " ago"
    } else {
      return "in " + result
    }
  }

  return count <= 10 && token === "xSeconds" ? formatDistanceLocale["now"] : result
}

export default formatDistance
