import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from "vuex-class-modules"
import { store } from "../store"
import { Modules } from "../modules"
import {
  Alert,
  AlertType,
  ShowAlertConfig,
} from "@/models/Alert"
import { cutTextByNumberOfSymbols } from "@/helpers/cutTextByNumberOfSymbols"

export enum AlertActions {
  GET_ALERTS = "GET_ALERTS",
  SHOW_ALERT = "SHOW_ALERT",
  SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT",
  CLEAR_REMOVED_ALERTS = "CLEAR_REMOVED_ALERTS",
}

const TIMEOUT = 4000

@Module
class AlertModule extends VuexModule {
  alerts: Alert[] = []

  get [`get/${AlertActions.GET_ALERTS}`]() {
    return this.alerts
  }

  @Mutation
  addAlert(alert: Alert) {
    this.alerts = [...this.alerts, alert]
  }

  @Mutation
  clearRemovedAlerts() {
    this.alerts = this.alerts.filter(alert => alert.timeout !== -1)
  }

  @Mutation
  updateAlertEstimate(type) {
    const alertToUpdate = this.alerts.find(a => a.uniqueType === type)
    if (alertToUpdate) {
      clearTimeout(alertToUpdate.timeoutCallbackId)
      alertToUpdate.timeout = TIMEOUT
      alertToUpdate.setupTimeout()
    }
  }

  @Action
  [AlertActions.SHOW_ALERT](payload: ShowAlertConfig) {
    const { type = AlertType.SUCCESS, timeout = TIMEOUT, theme, content = [], contentTitle = [], uniqueType } = payload
    if (uniqueType && this.alerts.map(a => a.uniqueType).includes(uniqueType)) {
      this.updateAlertEstimate(uniqueType)
    } else {
      this.addAlert(new Alert({
        name,
        content,
        contentTitle,
        type,
        timeout,
        theme,
        uniqueType,
      }))
    }
  }

  @Action
  [AlertActions.SHOW_ERROR_ALERT](payload: ShowAlertConfig) {
    const { type = AlertType.ERROR, timeout = TIMEOUT, theme, content = [], contentTitle = [] } = payload
    this.addAlert(new Alert({
      name,
      type,
      timeout,
      theme,
      content,
      contentTitle,
    }))
  }

  @Action
  [AlertActions.CLEAR_REMOVED_ALERTS]() {
    this.clearRemovedAlerts()
  }
}

export const alertModule = new AlertModule({ store, name: Modules.ALERT })
