import { config } from "@/app.config"
import { ResourceType } from "@/models"

import { EndpointTypes } from "./endpoint.types"

export const BASE_URL = "/api/v1"

class Url {
  public url: string
  public logUrl: string
  public externalUrl: string
  public logsError: boolean

  constructor(url, logUrl?: string, logsError = true) {
    this.url = url
    this.logUrl = logUrl || url
    this.externalUrl = `${config.VUE_APP_FILES_LOCATION}${BASE_URL}${url}`
    this.logsError = logsError
  }
}

export class Endpoint extends Url {
  // login
  static readonly [EndpointTypes.LOGIN] = () => new Url("/on-login")

  // chat-gpt
  static readonly [EndpointTypes.CHAT_GPT_ASK] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v2/ai-db-assistant/ask`,
      "/account/${accountId}/v2/ai-db-assistant/ask"
    )
  static readonly [EndpointTypes.CHAT_GPT_RESET] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/ai-db-assistant/reset`,
      "/account/${accountId}/v1/ai-db-assistant/reset"
    )

  // account
  static readonly [EndpointTypes.GET_ACCOUNTS] = () => new Url("admin/account")
  static readonly [EndpointTypes.GET_ACCOUNT] = (accountId: string) =>
    new Url(`/account/${accountId}`, "/account/${accountId}")
  static readonly [EndpointTypes.GET_ACCOUNT_ADMIN] = (accountId: string) =>
    new Url(`admin/account/${accountId}`, "/account/${accountId}")
  static readonly [EndpointTypes.GET_ACCOUNT_SUPERADMIN] = (accountId: string) =>
    new Url(`superadmin/account/${accountId}`, "superadmin/account/${accountId}")
  static readonly [EndpointTypes.POST_ACCOUNT] = () => new Url("/account")
  static readonly [EndpointTypes.ACCOUNT_PICTURE] = (accountId: string) =>
    new Url(`admin/account/${accountId}/picture`, "admin/account/${accountId}/picture")
  static readonly [EndpointTypes.ACCOUNT_CONTACT_INFO_SUPER_ADMIN] = (accountId: string) =>
    new Url(
      `superadmin/account/${accountId}/contactInformation`,
      "superadmin/account/${accountId}/contactInformation"
    )
  static readonly [EndpointTypes.ACCOUNT_CONTACT_INFO] = (accountId: string) =>
    new Url(
      `admin/account/${accountId}/contactInformation`,
      "admin/account/${accountId}/contactInformation"
    )
  static readonly [EndpointTypes.ACCOUNT_RESOLVE] = () => new Url("account/resolve")
  static readonly [EndpointTypes.ACCOUNT_CHECK] = () => new Url("account/check")
  static readonly [EndpointTypes.ACCOUNT_ENABLE] = (accountId: string) =>
    new Url(`admin/account/${accountId}/enabled`, "admin/account/${accountId}/enabled")

  // user
  static readonly [EndpointTypes.USER_CURRENT] = () => new Url("/user/current")
  static readonly [EndpointTypes.USER_CURRENT_ROLE] = role =>
    new Url(`/user/current/roles/${role}`, "/user/current/roles/${role}")
  static readonly [EndpointTypes.USER_CURRENT_PICTURE] = () => new Url("/user/current/picture")
  static readonly [EndpointTypes.USER_CURRENT_PROFILE] = () => new Url("/user/current/profile")
  static readonly [EndpointTypes.UPDATE_PROFILE_SETTINGS] = () => new Url("/user/current/settings")
  static readonly [EndpointTypes.USER_CURRENT_ACCOUNTS] = () => new Url("/user/current/accounts")
  static readonly [EndpointTypes.USER_CURRENT_PROJECTS] = () => new Url("/user/current/projects")
  static readonly [EndpointTypes.USER_CURRENT_PROJECTS_BY_ACCOUNT] = (accountId: string) =>
    new Url(
      `/user/current/account/${accountId}/projects`,
      "/user/current/account/${accountId}/projects"
    )
  static readonly [EndpointTypes.GET_USERS] = (accountId: string) =>
    new Url(`/account/${accountId}/v2/user`, "/account/${accountId}/v2/user")
  static readonly [EndpointTypes.GET_USERS_EXTENDED] = (accountId: string) =>
    new Url(`/account/${accountId}/v2/user/extended`, "/account/${accountId}/v2/user/extended")
  static readonly [EndpointTypes.GET_USER_BY_ID] = (id: string) =>
    new Url(`/user/${id}`, "/user/${id}")
  static readonly [EndpointTypes.ADMIN_USER_BY_ID] = (accountId: string, userId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/${userId}`,
      "/account/${accountId}/v1/admin/users/${userId}"
    )
  static readonly [EndpointTypes.SUPERADMIN_USER_BY_ID] = (accountId: string, userId: string) =>
    new Url(
      `/superadmin/user/${userId}/account/${accountId}`,
      "/superadmin/user/${userId}/account/${accountId}"
    )

  static readonly [EndpointTypes.USER_PICTURE_UPDATE] = (accountId: string, userId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/${userId}/picture`,
      "/account/${accountId}/v1/admin/users/${userId}/picture"
    )
  static readonly [EndpointTypes.USER_SETTINGS] = (accountId: string, userId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/${userId}/settings`,
      "/account/${accountId}/v1/admin/users/${userId}/settings"
    )

  // userManagement
  static readonly [EndpointTypes.GET_ALL_USERS] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/admin/users`, "/account/${accountId}/v1/admin/users")
  static readonly [EndpointTypes.GET_ALL_USERS_SUPERADMIN] = () =>
    new Url("/superadmin/user/search")
  static readonly [EndpointTypes.USER_BY_ID] = (accountId: string, userId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/${userId}`,
      "/account/${accountId}/v1/admin/users/${userId}"
    )
  static readonly [EndpointTypes.USER_ADD_PROJECT] = (accountId: string, userId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/${userId}/projects`,
      "/account/${accountId}/v1/admin/users/${userId}/projects"
    )
  static readonly [EndpointTypes.USER_DEACTIVATION] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/admin/users`, "/account/${accountId}/v1/admin/users")
  static readonly [EndpointTypes.USER_PERMISSIONS] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/users/permissions`,
      "/account/${accountId}/v1/admin/users/permissions"
    )

  // invitations
  static readonly [EndpointTypes.INVITATION] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation`,
      "/account/${accountId}/v1/admin/invitation"
    )
  static readonly [EndpointTypes.INVITATION_WITH_FEEDBACK] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/users`,
      "/account/${accountId}/v1/admin/invitation/users"
    )
  static readonly [EndpointTypes.INVITATION_CHECK] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/check`,
      "/account/${accountId}/v1/admin/invitation/check"
    )
  static readonly [EndpointTypes.INVITATION_RESEND_BY_ID] = (accountId: string, id: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/${id}/resend`,
      "/account/${accountId}/v1/admin/invitation/${id}/resend"
    )
  static readonly [EndpointTypes.INVITATION_RESEND_BY_EMAIL] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/resend`,
      "/account/${accountId}/v1/admin/invitation/resend"
    )
  static readonly [EndpointTypes.INVITATION_ACCEPT] = (id: string) =>
    new Url(`/invitation/${id}/accept`, "/invitation/${id}/accept")
  static readonly [EndpointTypes.INVITATION_CANCEL] = (accountId: string, id: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/${id}/cancel`,
      "/account/${accountId}/v1/admin/invitation/${id}/cancel"
    )
  static readonly [EndpointTypes.INVITE_TO_TEAM_LINK] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/invitation/team/invitation/link`,
      "/account/${accountId}/v1/admin/invitation/team/invitation/link"
    )

  // project
  static readonly [EndpointTypes.CREATE_DEMO_PROJECT] = (accountId: string) =>
    new Url(`/account/${accountId}/demoProject`, "/account/${accountId}/demoProject")
  static readonly [EndpointTypes.RECREATE_DEMO_PROJECT] = (accountId: string) =>
    new Url(
      `/account/${accountId}/demoProject/recreate`,
      "/account/${accountId}/demoProject/recreate"
    )
  static readonly [EndpointTypes.PROJECT] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/project`, "/account/${accountId}/v1/project")
  static readonly [EndpointTypes.DELETE_PROJECT] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/project/${projectId}`,
      "/account/${accountId}/v1/admin/project/${projectId}"
    )
  static readonly [EndpointTypes.PROJECT_BY_ID] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/project/${projectId}`,
      "/account/${accountId}/v1/project/${projectId}"
    )
  static readonly [EndpointTypes.PROJECT_BACKLOG] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/project/${projectId}/backlog`,
      "/account/${accountId}/v1/project/${projectId}/backlog"
    )
  static readonly [EndpointTypes.ADD_PROJECT_TO_TEAM] = (
    accountId: string,
    projectId: string,
    teamId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/project/${projectId}/team/${teamId}`,
      "/account/${accountId}/v1/project/${projectId}/team/${teamId}"
    )
  static readonly [EndpointTypes.PROJECT_RESOLVE] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/project/resolve`, "/account/${accountId}/v1/project/resolve")
  static readonly [EndpointTypes.ADMIN_PROJECT_SEARCH] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/admin/project/search`)
  static readonly [EndpointTypes.PROJECT_SEARCH] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/project/find`, "/account/${accountId}/v1/project/find")
  static readonly [EndpointTypes.CHECK_EXIST_PROJECT_BY_URL_OR_NAME] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/project/existsByNameOrUrlName`,
      "/account/${accountId}/v1/project/existsByNameOrUrlName"
    )
  static readonly [EndpointTypes.ADD_TEAMS_TO_PROJECT] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/project/${projectId}/teams`,
      "/account/${accountId}/v1/project/${projectId}/teams"
    )
  // project overview
  static readonly [EndpointTypes.PROJECT_OVERVIEW] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/projectOverview`, "/account/${accountId}/v1/projectOverview")
  static readonly [EndpointTypes.PROJECT_OVERVIEW_SEARCH] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/projectOverview/search`,
      "/account/${accountId}/v1/projectOverview/search"
    )
  static readonly [EndpointTypes.PROJECT_OVERVIEW_BY_ID] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/projectOverview/${projectId}`,
      "/account/${accountId}/v1/projectOverview/${projectId}"
    )
  static readonly [EndpointTypes.PROJECT_OVERVIEW_AT_RISK] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/project/atRisk`, "/account/${accountId}/v1/project/atRisk")

  // admin
  static readonly [EndpointTypes.START_SUBSCRIPTION] = (id: string) =>
    new Url(
      `/admin/account/${id}/paymentSubscription/startCheckout`,
      "/admin/account/${id}/paymentSubscription/startCheckout"
    )
  static readonly [EndpointTypes.CONFIRM_SUBSCRIPTION] = (id: string) =>
    new Url(
      `/admin/account/${id}/paymentSubscription/confirmCheckout`,
      "/admin/account/${id}/paymentSubscription/confirmCheckout"
    )

  // item
  static readonly [EndpointTypes.GET_ITEMS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/item`, "/project/${projectId}/v1/item")
  static readonly [EndpointTypes.GET_ITEM] = (projectId: string, itemId: string) =>
    new Url(`/project/${projectId}/v1/item/${itemId}`, "/project/${projectId}/v1/item/${itemId}")
  static readonly [EndpointTypes.POST_ITEM] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/item`, "/project/${projectId}/v1/item")
  static readonly [EndpointTypes.CHANGE_STATUS_ITEM] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/changeStatus`,
      "/project/${projectId}/v1/item/${itemId}/changeStatus"
    )
  static readonly [EndpointTypes.CHANGE_OWNER_ITEM] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/owner`,
      "/project/${projectId}/v1/item/${itemId}/owner"
    )
  static readonly [EndpointTypes.GET_ITEM_FILES] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/fileMetadata`,
      "/project/${projectId}/v1/item/${itemId}/fileMetadata"
    )
  static readonly [EndpointTypes.GET_PROJECT_STATS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/item/bugStats`, "/project/${projectId}/v1/item/bugStats")
  static readonly [EndpointTypes.ITEM_DEFINITION_OF_DONE] = (
    projectId: string,
    itemId: string,
    definitionOfDoneId: string
  ) =>
    new Url(
      `/api/v1/project/${projectId}/v1/item/${itemId}/definitionOfDone/${definitionOfDoneId}`,
      "/api/v1/project/${projectId}/v1/item/${itemId}/definitionOfDone/${definitionOfDoneId}"
    )
  static readonly [EndpointTypes.ASSIGN_EPIC] = (projectId: string, epicId: string) =>
    new Url(
      `/project/${projectId}/v1/item/epic/${epicId}`,
      "/project/${projectId}/v1/item/epic/${epicId}"
    )
  static readonly [EndpointTypes.DELETE_ITEM_FROM_EPIC] = (projectId: string, epicId: string) =>
    new Url(
      `/project/${projectId}/v1/item/epic/${epicId}`,
      "/project/${projectId}/v1/item/epic/${epicId}"
    )
  static readonly [EndpointTypes.DELETE_ITEM_FILE] = (
    projectId: string,
    itemId: string,
    fileMetadataId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/fileMetadata/${fileMetadataId}`,
      "/project/${projectId}/v1/item/${itemId}/fileMetadata/${fileMetadataId}"
    )
  static readonly [EndpointTypes.RESOLVE_ITEM_ID] = (projectId: string, sequenceNumber: number) =>
    new Url(
      `/project/${projectId}/v1/item/resolve/${sequenceNumber}`,
      "/project/${projectId}/v1/item/resolve/${sequenceNumber}"
    )
  static readonly [EndpointTypes.MOVE_TO_ITEM] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/taskMoveTo`,
      "/project/${projectId}/v1/item/${itemId}/taskMoveTo"
    )
  static readonly [EndpointTypes.UPDATE_ITEM_READINESS] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/readiness`,
      "/project/${projectId}/v1/item/${itemId}/readiness"
    )
  static readonly [EndpointTypes.ITEM_PROGRESS] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/progress`,
      "/project/${projectId}/v1/item/${itemId}/progress"
    )
  static readonly [EndpointTypes.IMPORT_ITEM_TO_EPIC] = (projectId: string, epicId: string) =>
    new Url(
      `/project/${projectId}/v1/item/epic/${epicId}`,
      "/project/${projectId}/v1/item/epic/${epicId}"
    )
  static readonly [EndpointTypes.CHANGE_TEAM_FOR_ITEM] = (projectId: string, itemId) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/team`,
      "/project/${projectId}/v1/item/${itemId}/team"
    )
  static readonly [EndpointTypes.CHANGE_TEAM_FOR_ITEMS_BATCH] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/item/team`, "/project/${projectId}/v1/item/team")
  static readonly [EndpointTypes.DEFINITION_OF_DONE] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/definitionOfDone`,
      "/project/${projectId}/v1/item/${itemId}/definitionOfDone"
    )
  static readonly [EndpointTypes.TASKS_LIST] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/tasks`,
      "/project/${projectId}/v1/item/${itemId}/tasks"
    )
  static readonly [EndpointTypes.TASKS_ESTIMATION] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/tasks/estimation`,
      "/project/${projectId}/v1/item/${itemId}/tasks/estimation"
    )
  static readonly [EndpointTypes.UPDATE_ITEM_DETAILS] = (projectId: string, itemId: string) =>
    new Url(`/project/${projectId}/v1/item/${itemId}`, "/project/${projectId}/v1/item/${itemId}")
  static readonly [EndpointTypes.UPDATE_TEAM] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/team`,
      "/project/${projectId}/v1/item/${itemId}/team"
    )
  static readonly [EndpointTypes.SPRINT_ITEMS] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/items`,
      "/project/${projectId}/v1/sprint/${sprintId}/items"
    )

  // comment
  static readonly [EndpointTypes.GET_COMMENTS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/comment`, "/project/${projectId}/v1/comment")
  static readonly [EndpointTypes.GET_ITEM_COMMENT] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/comment/itemChangeStatusOrSprintReview`,
      "/project/${projectId}/v1/comment/itemChangeStatusOrSprintReview"
    )
  static readonly [EndpointTypes.GET_COMMENT] = (projectId: string, commentId: string) =>
    new Url(
      `/project/${projectId}/v1/comment/${commentId}`,
      "/project/${projectId}/v1/comment/${commentId}"
    )
  static readonly [EndpointTypes.GET_COMMENTS_BY_SOURCE] = (
    projectId: string,
    sourceId: string,
    sourceType: string
  ) =>
    new Url(
      `/project/${projectId}/v1/comment/source/${sourceId}/${sourceType}`,
      "/project/${projectId}/v1/comment/source/${sourceId}/${sourceType}"
    )
  static readonly [EndpointTypes.POST_COMMENT] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/comment`, "/project/${projectId}/v1/comment")
  static readonly [EndpointTypes.UPDATE_COMMENT_DESCRIPTION] = (
    projectId: string,
    commentId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/comment/${commentId}`,
      "/project/${projectId}/v1/comment/${commentId}"
    )
  static readonly [EndpointTypes.UPDATE_STATUS_DESCRIPTION] = (
    projectId: string,
    commentId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/comment/${commentId}/structuredDescription`,
      "/project/${projectId}/v1/comment/${commentId}/structuredDescription"
    )
  static readonly [EndpointTypes.DELETE_COMMENT] = (projectId: string, commentId: string) =>
    new Url(
      `/project/${projectId}/v1/comment/${commentId}`,
      "/project/${projectId}/v1/comment/${commentId}"
    )
  static readonly [EndpointTypes.GET_COMMENT_COUNT] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/comment/count`, "/project/${projectId}/v1/comment/count")

  // task
  static readonly [EndpointTypes.ITEM_TASKS] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/tasks`,
      "/project/${projectId}/v1/item/${itemId}/tasks"
    )

  // toDo
  static readonly [EndpointTypes.TODO] = (accountId: string) =>
    new Url(`/account/${accountId}/v2/todoItem`, "/account/${accountId}/v2/todoItem")
  static readonly [EndpointTypes.TODO_ITEM_RESOLVE] = (accountId: string, todoItemId: string) =>
    new Url(
      `/account/${accountId}/v2/todoItem/${todoItemId}/resolve`,
      "/account/${accountId}/v2/todoItem/${todoItemId}/resolve"
    )
  static readonly [EndpointTypes.TODO_ITEM_UNRESOLVE] = (accountId: string, todoItemId: string) =>
    new Url(
      `/account/${accountId}/v2/todoItem/${todoItemId}/unresolve`,
      "/account/${accountId}/v2/todoItem/${todoItemId}/unresolve"
    )
  static readonly [EndpointTypes.TODO_COUNT] = (accountId: string) =>
    new Url(`/account/${accountId}/v2/todoItem/count`, "/account/${accountId}/v2/todoItem/count")
  static readonly [EndpointTypes.PUT_TODO] = (accountId: string, todoItemId: string) =>
    new Url(
      `/account/${accountId}/v2/todoItem/${todoItemId}`,
      "/account/${accountId}/v2/todoItem/${todoItemId}"
    )

  // attachment
  static readonly [EndpointTypes.ATTACHMENT_UPLOAD_TARGET] = (
    projectId: string,
    resourceType: ResourceType,
    targetId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/attachment/${resourceType}/${targetId}`,
      "/project/${projectId}/v1/attachment/${resourceType}/${targetId}"
    )
  static readonly [EndpointTypes.ATTACHMENT_UPLOAD] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/attachment/`, "/project/${projectId}/v1/attachment/")
  static readonly [EndpointTypes.ATTACHMENT_VIEW] = (projectId: string, fileMetadataId: string) =>
    new Url(
      `/project/${projectId}/v1/attachment/${fileMetadataId}`,
      "/project/${projectId}/v1/attachment/${fileMetadataId}"
    )
  static readonly [EndpointTypes.DELETE_FILE_FROM_TARGET] = (
    projectId: string,
    fileMetadataId: string,
    resourceType: string,
    targetId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/attachment/${fileMetadataId}/${resourceType}/${targetId}`,
      "/project/${projectId}/v1/attachment/${fileMetadataId}/${resourceType}/${targetId}"
    )
  static readonly [EndpointTypes.DELETE_FILE] = (projectId: string, fileMetadataId: string) =>
    new Url(
      `/project/${projectId}/v1/attachment/${fileMetadataId}`,
      "/project/${projectId}/v1/attachment/${fileMetadataId}"
    )
  static readonly [EndpointTypes.ATTACHMENT_VIDEO_URL] = (
    projectId: string,
    attachmentId: string,
    token: string
  ) =>
    new Url(
      `/project/${projectId}/v1/attachment/${attachmentId}/streaming?access_token=${token}`,
      "project/${projectId}/v1/attachment/${attachmentId}/streaming?access_token=${token}"
    )

  // attachment by account
  static readonly [EndpointTypes.ATTACHMENT_UPLOAD_TARGET_BY_ACCOUNT] = (
    accountId: string,
    resourceType: ResourceType,
    targetId: string
  ) =>
    new Url(
      `/account/${accountId}/v2/attachment/${resourceType}/${targetId}`,
      "/account/${accountId}/v2/attachment/${resourceType}/${targetId}"
    )
  static readonly [EndpointTypes.ATTACHMENT_UPLOAD_BY_ACCOUNT] = (accountId: string) =>
    new Url(`/account/${accountId}/v2/attachment/`, "/account/${accountId}/v2/attachment/")
  static readonly [EndpointTypes.ATTACHMENT_BY_ACCOUNT] = (
    accountId: string,
    fileMetadataId: string
  ) =>
    new Url(
      `/account/${accountId}/v2/attachment/${fileMetadataId}`,
      "/account/${accountId}/v2/attachment/${fileMetadataId}"
    )

  // team
  static readonly [EndpointTypes.TEAMS] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team`, "/account/${accountId}/v1/team")
  static readonly [EndpointTypes.TEAMS_WITH_USERS] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team/extended`, "/account/${accountId}/v1/team/extended")
  static readonly [EndpointTypes.TEAMS_WITH_USERS_SEARCH] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/team/extended/search`,
      "/account/${accountId}/v1/team/extended/search"
    )
  static readonly [EndpointTypes.TEAMS_SEARCH_ADMIN] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/team/search`,
      "/account/${accountId}/v1/admin/team/search"
    )
  static readonly [EndpointTypes.TEAM_UPDATE_ADMIN] = (accountId: string, teamId: string) =>
    new Url(
      `/account/${accountId}/v1/admin/team/${teamId}`,
      "/account/${accountId}/v1/admin/team/${teamId}"
    )
  static readonly [EndpointTypes.TEAM] = (accountId: string, teamId: string) =>
    new Url(`/account/${accountId}/v1/team/${teamId}`, "/account/${accountId}/v1/team/${teamId}")
  static readonly [EndpointTypes.CHECK_TEAM_NAME] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team/find`, "/account/${accountId}/v1/team/find")
  static readonly [EndpointTypes.TEAM_RESOLVE] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team/resolve`, "/account/${accountId}/v1/team/resolve")
  static readonly [EndpointTypes.TEAM_PICTURE] = (accountId: string, teamId: string) =>
    new Url(
      `/account/${accountId}/v1/team/${teamId}/picture`,
      "/account/${accountId}/v1/team/${teamId}/picture"
    )
  static readonly [EndpointTypes.TEAM_IN_PROJECT] = (accountId: string, projectId: string) =>
    new Url(
      `/account/${accountId}/v1/team/project/${projectId}`,
      "/account/${accountId}/v1/team/project/${projectId}"
    )
  static readonly [EndpointTypes.TEAM_USER_ADD] = (accountId: string, teamId: string) =>
    new Url(
      `/account/${accountId}/v1/team/${teamId}/user`,
      "/account/${accountId}/v1/team/${teamId}/user"
    )
  static readonly [EndpointTypes.TEAM_USER_DELETE] = (accountId: string, teamId: string, userId) =>
    new Url(
      `/account/${accountId}/v1/team/${teamId}/user/${userId}`,
      "/account/${accountId}/v1/team/${teamId}/user/${userId}"
    )
  static readonly [EndpointTypes.GET_PROJECT_WITH_STATS_TEAM] = (
    accountId: string,
    teamId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/projectWithStats/team/${teamId}`,
      "/account/${accountId}/v1/projectWithStats/team/${teamId}"
    )
  static readonly [EndpointTypes.GET_PROJECT_WITH_STATS_USER] = (
    accountId: string,
    userId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/projectWithStats/user/${userId}`,
      "/account/${accountId}/v1/projectWithStats/user/${userId}"
    )
  static readonly [EndpointTypes.TEAM_CREATE_WITH_USERS] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team/bulk`, "/account/${accountId}/v1/team/bulk")
  static readonly [EndpointTypes.TEAM_WITH_EXTENDED_USERS] = (accountId: string, teamId: string) =>
    new Url(
      `/account/${accountId}/v1/team/${teamId}/extended`,
      "/account/${accountId}/v1/team/${teamId}/extended"
    )

  // picture
  static readonly [EndpointTypes.PICTURE] = () => new Url("/picture")

  // backlog
  static readonly [EndpointTypes.BACKLOG] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/item`, "/project/${projectId}/v2/item")
  static readonly [EndpointTypes.PRODUCT_BACKLOG] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/item/extended`, "/project/${projectId}/v2/extended")
  static readonly [EndpointTypes.CHANGE_ITEM_PRIORITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/item/changePriority`,
      "/project/${projectId}/v1/item/changePriority"
    )
  static readonly [EndpointTypes.CHANGE_ITEM_PRIORITY_BATCH] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/item/changePriorityBatch`,
      "/project/${projectId}/v1/item/changePriorityBatch"
    )
  static readonly [EndpointTypes.BACKLOG_RESULT_COUNT] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/item/count`, "/project/${projectId}/v2/item/count")
  static readonly [EndpointTypes.BACKLOG_ANALYTICS] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/metrics/backlogs/analytics`,
      "/project/${projectId}/v1/metrics/backlogs/analytics"
    )

  // epic
  static readonly [EndpointTypes.GET_EPIC] = (projectId: string, epicId: string) =>
    new Url(`/project/${projectId}/v1/epic/${epicId}`, "/project/${projectId}/v1/epic/${epicId}")
  static readonly [EndpointTypes.GET_EPICS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/epic`, "/project/${projectId}/v1/epic")
  static readonly [EndpointTypes.POST_EPIC] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/epic`, "/project/${projectId}/v1/epic")
  static readonly [EndpointTypes.UPDATE_EPIC] = (projectId: string, epicId: string) =>
    new Url(`/project/${projectId}/v1/epic/${epicId}`, "/project/${projectId}/v1/epic/${epicId}")
  static readonly [EndpointTypes.CHANGE_EPIC_PRIORITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/epic/changePriority`,
      "/project/${projectId}/v1/epic/changePriority"
    )
  static readonly [EndpointTypes.CHANGE_EPIC_PRIORITY_IN_MILESTONE] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/epic/changeMilestonePriority`,
      "/project/${projectId}/v1/epic/changeMilestonePriority"
    )
  static readonly [EndpointTypes.LIST_OF_MILESTONE_CHARTS] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/milestone/chartInfo`,
      "/project/${projectId}/v1/milestone/chartInfo"
    )
  static readonly [EndpointTypes.MOVE_EPIC_TO_MILESTONE] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/epic/moveToMilestone`,
      "/project/${projectId}/v1/epic/moveToMilestone"
    )
  static readonly [EndpointTypes.RESOLVE_EPIC_ID] = (projectId: string, sequenceNumber: number) =>
    new Url(
      `/project/${projectId}/v1/epic/resolve/${sequenceNumber}`,
      "/project/${projectId}/v1/epic/resolve/${sequenceNumber}"
    )
  static readonly [EndpointTypes.ARCHIVE_EPIC] = (projectId: string, epicId: string) =>
    new Url(
      `/project/${projectId}/v1/epic/${epicId}/archive`,
      "/project/${projectId}/v1/epic/${epicId}/archive"
    )
  static readonly [EndpointTypes.MERGE_EPIC] = (
    projectId: string,
    epicId: string,
    targetEpicId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/epic/${epicId}/mergeTo/${targetEpicId}`,
      "/project/${projectId}/v1/epic/${epicId}/mergeTo/${targetEpicId}"
    )
  static readonly [EndpointTypes.RESTORE_EPIC] = (projectId: string, epicId: string) =>
    new Url(
      `/project/${projectId}/v1/epic/${epicId}/restore`,
      "/project/${projectId}/v1/epic/${epicId}/restore"
    )

  // sprint
  static readonly [EndpointTypes.SPRINTS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/sprint`, "/project/${projectId}/v1/sprint")
  static readonly [EndpointTypes.SPRINT] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}`,
      "/project/${projectId}/v1/sprint/${sprintId}"
    )
  static readonly [EndpointTypes.SPRINT_NOT_COMPLETED] = (projectId: string, teamId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/team/${teamId}/notCompleted`,
      "/project/${projectId}/v1/sprint/team/${teamId}/notCompleted"
    )
  static readonly [EndpointTypes.RESOLVE_SPRINT_ID] = (
    projectId: string,
    teamId: string,
    sequenceNumber: number
  ) =>
    new Url(
      `/project/${projectId}/v1/sprint/team/${teamId}/resolve/${sequenceNumber}`,
      "/project/${projectId}/v1/sprint/team/${teamId}/resolve/${sequenceNumber}"
    )
  static readonly [EndpointTypes.SPRINT_START] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/start`,
      "/project/${projectId}/v1/sprint/${sprintId}/start"
    )
  static readonly [EndpointTypes.SPRINT_COMPLETE] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/complete`,
      "/project/${projectId}/v1/sprint/${sprintId}/complete"
    )
  static readonly [EndpointTypes.SPRINT_HALT] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/halt`,
      "/project/${projectId}/v1/sprint/${sprintId}/halt"
    )
  static readonly [EndpointTypes.GET_SPRINT_NUMBER] = (projectId: string, teamId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/team/${teamId}/resolve/nextSequenceNumber`,
      "/project/${projectId}/v1/sprint/team/${teamId}/resolve/nextSequenceNumber"
    )
  static readonly [EndpointTypes.SPRINT_START_REVIEW] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/startReview`,
      "/project/${projectId}/v1/sprint/${sprintId}/startReview"
    )
  static readonly [EndpointTypes.SPRINT_COMPLETE_REVIEW] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/completeReview`,
      "/project/${projectId}/v1/sprint/${sprintId}/completeReview"
    )
  static readonly [EndpointTypes.CAN_START_SPRINT] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/canStart`,
      "/project/${projectId}/v1/sprint/${sprintId}/canStart"
    )
  static readonly [EndpointTypes.SPRINT_QUOTE] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/sprint/quote`, "/project/${projectId}/v1/sprint/quote")
  static readonly [EndpointTypes.SPRINT_EXISTS_CONTINUOUS] = (projectId: string, teamId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${teamId}/existsContinuousSprint`,
      "/project/${projectId}/v1/sprint/${teamId}/existsContinuousSprint"
    )
  static readonly [EndpointTypes.EDIT_SUMMARY] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/summary`,
      "/project/${projectId}/v1/sprint/${sprintId}/summary"
    )
  static readonly [EndpointTypes.SPRINT_CAPACITY] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/sprint/capacity`, "/project/${projectId}/v1/sprint/capacity")
  static readonly [EndpointTypes.SPRINT_TIME_OFFS] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/timeOffs`,
      "/project/${projectId}/v1/sprint/${sprintId}/timeOffs"
    )

  // sprint analytics
  static readonly [EndpointTypes.SPRINT_ANALYTICS] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/analytics`,
      "/project/${projectId}/v1/sprint/${sprintId}/analytics"
    )
  static readonly [EndpointTypes.SPRINT_ANALYTICS_PERFORMANCE] = (
    projectId: string,
    sprintId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/analytics/performance`,
      "/project/${projectId}/v1/sprint/${sprintId}/analytics/performance"
    )
  static readonly [EndpointTypes.SPRINT_ANALYTICS_QUALITY] = (
    projectId: string,
    sprintId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/analytics/quality`,
      "/project/${projectId}/v1/sprint/${sprintId}/analytics/quality"
    )
  static readonly [EndpointTypes.SPRINT_ANALYTICS_TEAM] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/analytics/team`,
      "/project/${projectId}/v1/sprint/${sprintId}/analytics/team"
    )

  // sprint review
  static readonly [EndpointTypes.SPRINT_REVIEW] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprintReview/${sprintId}`,
      "/project/${projectId}/v1/sprintReview/${sprintId}"
    )

  // retrospective
  static readonly [EndpointTypes.RETROSPECTIVE_PUT_NOTE] = (
    projectId: string,
    retrospectiveId: string,
    noteId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/note/${noteId}`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/note/${noteId}"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_REVIEWED] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/notes/reviewed/bulk`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/notes/reviewed/bulk"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_NOTES] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/note`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/note"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_COMPLETE] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/complete`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/complete"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_START] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/retrospective/sprint/${sprintId}/start`,
      "/project/${projectId}/v1/retrospective/sprint/${sprintId}/start"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_POST] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/retrospective/sprint/${sprintId}/request`,
      "/project/${projectId}/v1/retrospective/sprint/${sprintId}/request"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_CHANGE_PRIORITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/retrospective/changePriority`,
      "/project/${projectId}/v1/retrospective/changePriority"
    )
  static readonly [EndpointTypes.GET_RETROSPECTIVE] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}"
    )
  static readonly [EndpointTypes.GET_LIST_OF_RETROSPECTIVE] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/retrospective`, "/project/${projectId}/v1/sprint")
  static readonly [EndpointTypes.GET_RETROSPECTIVE_BY_SPRINT_ID] = (
    projectId: string,
    sprintId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/sprint/${sprintId}`,
      "/project/${projectId}/v1/retrospective/sprint/${sprintId}"
    )
  static readonly [EndpointTypes.GET_RETROSPECTIVE_STATUS] = (
    projectId: string,
    sprintId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/sprint/${sprintId}/status`,
      "/project/${projectId}/v1/retrospective/sprint/${sprintId}/status"
    )

  static readonly [EndpointTypes.RETROSPECTIVE_PUT_STACK] = (
    projectId: string,
    retrospectiveId: string,
    stackId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/stack/${stackId}`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/stack/${stackId}"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_POST_STACK] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/stack`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/stack"
    )
  static readonly [EndpointTypes.RETROSPECTIVE_POST_STACK_NOTE] = (
    projectId: string,
    retrospectiveId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/retrospective/${retrospectiveId}/stack/note`,
      "/project/${projectId}/v1/retrospective/${retrospectiveId}/stack/note"
    )

  // sprints with stats
  static readonly [EndpointTypes.SPRINT_METRICS] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprintStats/${sprintId}`,
      "/project/${projectId}/v1/sprintStats/${sprintId}"
    )
  static readonly [EndpointTypes.SPRINT_WITH_STATS] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprintWithStats/${sprintId}`,
      "/project/${projectId}/v1/sprintWithStats/${sprintId}"
    )
  static readonly [EndpointTypes.SPRINTS_WITH_STATS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/sprintWithStats`, "/project/${projectId}/v1/sprintWithStats")

  // storypoker
  static readonly [EndpointTypes.GET_STORY_POKER] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/storyPoker/${itemId}`,
      "/project/${projectId}/v1/storyPoker/${itemId}"
    )
  static readonly [EndpointTypes.ADD_STORY_POKER_ESTIMATION] = (
    projectId: string,
    storyPokerId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/storyPoker/${storyPokerId}/estimation`,
      "/project/${projectId}/v1/storyPoker/${storyPokerId}/estimation"
    )
  static readonly [EndpointTypes.CREATE_STORY_POKER] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/storyPoker`, "/project/${projectId}/v1/storyPoker")
  static readonly [EndpointTypes.GET_STORY_POKER_URL] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/item/${itemId}/storyPokerUrl`,
      "/project/${projectId}/v1/item/${itemId}/storyPokerUrl"
    )
  static readonly [EndpointTypes.COMPLETE_STORY_POKER] = (
    projectId: string,
    storyPokerId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/storyPoker/${storyPokerId}/complete`,
      "/project/${projectId}/v1/storyPoker/${storyPokerId}/complete"
    )

  // backlog metrics
  static readonly [EndpointTypes.METRICS_EPIC] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/metrics/epic`, "/project/${projectId}/v1/metrics/epic")
  static readonly [EndpointTypes.METRICS_SEVERITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/metrics/severity`,
      "/project/${projectId}/v1/metrics/severity"
    )
  static readonly [EndpointTypes.METRICS_BUG] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/metrics/bugs`, "/project/${projectId}/v2/metrics/bugs")
  static readonly [EndpointTypes.METRICS_BACKLOG] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/metrics/backlog`, "/project/${projectId}/v1/metrics/backlog")
  static readonly [EndpointTypes.METRICS_TEAM_BACKLOGS] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/metrics/backlogs`,
      "/project/${projectId}/v1/metrics/backlogs"
    )
  static readonly [EndpointTypes.ITEMS_METRICS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/metrics`, "/project/${projectId}/v1/metrics")
  static readonly [EndpointTypes.METRICS_BY_TYPE] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/metrics/type`, "/project/${projectId}/v1/metrics/type")
  // milestone
  static readonly [EndpointTypes.POST_MILESTONE] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/milestone`, "/project/${projectId}/v1/milestone")
  static readonly [EndpointTypes.GET_MILESTONE] = (projectId: string, milestoneId: string) =>
    new Url(
      `/project/${projectId}/v1/milestone/${milestoneId}`,
      "/project/${projectId}/v1/milestone/${milestoneId}"
    )
  static readonly [EndpointTypes.CHANGE_MILESTONE_DURATION] = (
    projectId: string,
    milestoneId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/milestone/${milestoneId}/duration`,
      "/project/${projectId}/v1/milestone/${milestoneId}/duration"
    )
  static readonly [EndpointTypes.GET_MILESTONES] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/milestone`, `/project/${projectId}/v1/milestone`)
  static readonly [EndpointTypes.GET_MILESTONES_REVIEW] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/milestoneReview`, "/project/${projectId}/v1/milestoneReview")
  static readonly [EndpointTypes.POST_MILESTONE_EPIC] = (
    projectId: string,
    milestoneId: string,
    epicId: string
  ) =>
    new Url(
      `/project/${projectId}/v1/milestone/${milestoneId}/epic/${epicId}`,
      "/project/${projectId}/v1/milestone/${milestoneId}/epic/${epicId}"
    )
  static readonly [EndpointTypes.MILESTONES_PERIOD] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v1/milestone/period`,
      "/project/${projectId}/v1/milestone/period"
    )

  // subscription
  static readonly [EndpointTypes.GET_SUBSCRIPTION] = (
    resourceType: ResourceType,
    resourceId: string
  ) =>
    new Url(
      `/subscription/${resourceType}/${resourceId}`,
      "/subscription/${resourceType}/${resourceId}",
      false
    )
  static readonly [EndpointTypes.SET_SUBSCRIPTION] = () => new Url("/subscription")
  static readonly [EndpointTypes.GET_SUBSCRIPTION_TOKEN] = () => new Url("/subscription/slt")
  static readonly [EndpointTypes.STREAM_SUBSCRIPTION] = () => new Url("/api/v1/subscription/stream")
  // five-whys
  static readonly [EndpointTypes.FIVE_WHYS_BY_ID] = (projectId: string, itemId: string) =>
    new Url(
      `/project/${projectId}/v1/fiveWhys/${itemId}`,
      "/project/${projectId}/v1/fiveWhys/${itemId}"
    )
  static readonly [EndpointTypes.FIVE_WHYS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/fiveWhys`, "/project/${projectId}/v1/fiveWhys")

  // email template
  static readonly [EndpointTypes.GET_EMAIL_TEMPLATES] = () => new Url("/superadmin/emailTemplates")
  static readonly [EndpointTypes.GET_EMAIL_TEMPLATE_TYPE] = (type: string) =>
    new Url(`/superadmin/emailTemplates/${type}`, "/superadmin/emailTemplates/${type}")
  static readonly [EndpointTypes.POST_EMAIL_TEMPLATE_RENDER] = (type: string) =>
    new Url(
      `/superadmin/emailTemplates/${type}/render`,
      "/superadmin/emailTemplates/${type}/render"
    )
  static readonly [EndpointTypes.EMAIL_SEND] = (type: string) =>
    new Url(`/superadmin/emailTemplates/${type}/send`, "/superadmin/emailTemplates/${type}/send")

  // search
  static readonly [EndpointTypes.SEARCH] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/search`, "/account/${accountId}/v1/search")
  static readonly [EndpointTypes.SEARCH_PROJECT] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/project/search`, "/account/${accountId}/v1/project/search")
  static readonly [EndpointTypes.SEARCH_TEAM] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/team/search`, "/account/${accountId}/v1/team/search")
  static readonly [EndpointTypes.SEARCH_SPRINT] = (accountId: string) =>
    new Url(`/account/${accountId}/sprint/search`, "/account/${accountId}/sprint/search")
  static readonly [EndpointTypes.SEARCH_ITEM] = (accountId: string) =>
    new Url(`/account/${accountId}/item/search`, "/account/${accountId}/item/search")
  static readonly [EndpointTypes.SEARCH_EPIC] = (accountId: string) =>
    new Url(`/account/${accountId}/epic/search`, "/account/${accountId}/epic/search")
  static readonly [EndpointTypes.SEARCH_MILESTONE] = (accountId: string) =>
    new Url(`/account/${accountId}/milestone/search`, "/account/${accountId}/milestone/search")
  static readonly [EndpointTypes.SEARCH_LABELS] = (projectId: string) =>
    new Url(`/project/${projectId}/v1/label`, "/project/${projectId}/v1/label") // ?

  // my work
  static readonly [EndpointTypes.MY_WORK_PRIORITIZED] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/myWork/prioritized`, "/account/${accountId}/v1/prioritized")
  static readonly [EndpointTypes.MY_WORK_CHANGE_PRIORITY] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/myWork/changePriority`,
      "/account/${accountId}/v1/myWork/changePriority"
    )
  static readonly [EndpointTypes.MY_WORK_STATS] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/myWork/prioritized/stats`,
      "/account/${accountId}/v1/myWork/prioritized/stats`"
    )

  // logging
  static readonly [EndpointTypes.LOG_METRIC_COUNT] = () => new Url("/fe/metric/count")
  static readonly [EndpointTypes.LOG_METRIC_TIME] = () => new Url("/fe/metric/time")
  static readonly [EndpointTypes.LOG_METRIC_PAGELOAD] = () => new Url("/fe/metric/pageLoad")
  static readonly [EndpointTypes.LOG_ERROR] = () => new Url("/fe/log/error", "/fe/log/error", false)

  // notifications
  static readonly [EndpointTypes.NOTIFICATIONS_MARK_AS_READ] = () =>
    new Url("/user/current/notification/markAsRead")
  static readonly [EndpointTypes.SET_NOTIFICATIONS_AS_READ] = () =>
    new Url("/user/current/notification/markAsRead/in")
  static readonly [EndpointTypes.GET_NOTIFICATIONS] = () => new Url("/user/current/notification")
  static readonly [EndpointTypes.GET_NOTIFICATIONS_COUNT] = () =>
    new Url("/user/current/notification/count")

  // goal
  static readonly [EndpointTypes.POST_GOAL] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/goal`, "/project/${projectId}/v2/goal")
  static readonly [EndpointTypes.UPDATE_GOAL] = (projectId: string, goalId: string) =>
    new Url(`/project/${projectId}/v2/goal/${goalId}`, "/project/${projectId}/v2/goal/${goalId}")
  static readonly [EndpointTypes.FEATURE_GOAL] = (projectId: string, goalId: string) =>
    new Url(
      `/project/${projectId}/v2/goal/${goalId}/feature`,
      "/project/${projectId}/v2/goal/${goalId}/feature"
    )
  static readonly [EndpointTypes.CHANGE_GOAL_PRIORITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v2/goal/changePriority`,
      "/project/${projectId}/v2/goal/changePriority"
    )
  static readonly [EndpointTypes.ADD_SPECIFIC_FEATURE] = (
    projectId: string,
    goalId: string,
    featureId: string
  ) =>
    new Url(
      `/project/${projectId}/v2/goal/${goalId}/feature/${featureId}`,
      "/project/${projectId}/v2/goal/${goalId}/feature/${featureId}"
    )
  static readonly [EndpointTypes.SEARCH_GOAL] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/goal/search`, "/account/${accountId}/v1/goal/search")
  static readonly [EndpointTypes.GET_GOAL] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/goal`, "/account/${accountId}/v1/goal")
  // feature
  static readonly [EndpointTypes.SEARCH_FEATURE] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/feature/search`, "/account/${accountId}/v2/feature/search")
  static readonly [EndpointTypes.FEATURE] = (projectId: string) =>
    new Url(`/project/${projectId}/v2/feature`, "/project/${projectId}/v2/feature")
  static readonly [EndpointTypes.UPDATE_FEATURE] = (projectId: string, featureId: string) =>
    new Url(
      `/project/${projectId}/v2/feature/${featureId}`,
      "/project/${projectId}/v2/feature/${featureId}"
    )
  static readonly [EndpointTypes.UPDATE_FEATURE_EPIC] = (projectId: string, featureId: string) =>
    new Url(
      `/project/${projectId}/v2/feature/${featureId}/epic`,
      "/project/${projectId}/v2/feature/${featureId}/epic"
    )
  static readonly [EndpointTypes.ADD_PROJECT_FEATURE] = (
    projectId: string,
    featureId: string,
    toProjectId: string
  ) =>
    new Url(
      `/project/${projectId}/v2/feature/${featureId}/toProject/${toProjectId}`,
      "/project/${accountId}/v2/feature/${featureId}/toProject/${toProjectId}"
    )
  static readonly [EndpointTypes.CHANGE_FEATURE_PRIORITY] = (projectId: string) =>
    new Url(
      `/project/${projectId}/v2/feature/changePriority`,
      "/project/${projectId}/v2/feature/changePriority"
    )

  // email verification
  static readonly [EndpointTypes.EMAIL_VERIFICATION_SEND] = () =>
    new Url("/emailVerification/send", "/emailVerification/send")
  static readonly [EndpointTypes.EMAIL_VERIFICATION_RESEND] = () =>
    new Url("/emailVerification/resend", "/emailVerification/resend")

  // health check
  static readonly [EndpointTypes.SAVE_HEALTH_CHECK] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/healthCheck`,
      "/project/${projectId}/v1/sprint/${sprintId}/healthCheck"
    )
  static readonly [EndpointTypes.HEALTH_CHECK_SUMMARY] = (projectId: string, sprintId: string) =>
    new Url(
      `/project/${projectId}/v1/sprint/${sprintId}/healthCheck/summary`,
      "/project/${projectId}/v1/sprint/${sprintId}/healthCheck/summary"
    )

  // jira import
  static readonly [EndpointTypes.JIRA_IMPORT_START] = (accountId: string) =>
    new Url(`/account/${accountId}/v1/jira-import`, "/account/${accountId}/v1/admin/v1/jira-import")
  static readonly [EndpointTypes.JIRA_IMPORT_USERS] = (accountId: string, importId: string) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/users`,
      "/account/${accountId}/v1/admin/v1/jira-import"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_TEST] = (accountId: string, importId: string) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/test`,
      "/account/${accountId}/v1/admin/v1/jira-import/${importId}/test"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_USERS_START] = (accountId: string, importId: string) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/users/start`,
      "/account/${accountId}/v1/admin/v1/jira-import/${importId}/users/start"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_BACKLOG_START] = (
    accountId: string,
    importId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/import/start`,
      "/account/${accountId}/v1/admin/v1/jira-import/${importId}/import/start"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_BACKLOG_FINISH] = (importId: string) =>
    new Url(`/api/internal/make/${importId}/finish`, "api/internal/make/${importId}/finish")
  static readonly [EndpointTypes.JIRA_IMPORT_BACKLOG_SYNC_DISABLE] = (
    accountId: string,
    importId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/sync/disable`,
      "/account/${accountId}/v1/admin/v1/jira-import/${importId}/sync/disable"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_BACKLOG_SYNC_ENABLE] = (
    accountId: string,
    importId: string
  ) =>
    new Url(
      `/account/${accountId}/v1/jira-import/${importId}/sync/enable`,
      "/account/${accountId}/v1/admin/v1/jira-import/${importId}/sync/enable"
    )
  static readonly [EndpointTypes.JIRA_IMPORT_STATUS] = (accountId: string) =>
    new Url(
      `/account/${accountId}/v1/jira-import/inProgress`,
      "/account/${accountId}/v1/jira-import/inProgress"
    )
}
