import { Invitation } from "."
import { DeveloperRoles } from "./User"
import { Base } from "./Base"
import { Project } from "./Project"
import {
  Picture,
  User,
  UserWithRole,
} from "./User"
import { getInitials as getInitialsHelper } from "@/utils/helpers"

export enum TeamRolesWithAccess {
  ENGINEERING_MANAGERS = "ENGINEERING_MANAGERS",
  PRODUCT_OWNERS_AND_PM = "PRODUCT_OWNERS_AND_PM",
  SCRUM_MASTER = "SCRUM_MASTER"
}

export class TeamProfile extends Base<TeamProfile> {
  name = ""
  description = ""
  enabled = false

  constructor(props?: TeamProfile) {
    super()
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  isModified(keys?: (keyof TeamProfile)[], inverse?: boolean) {
    let isModified = false
    if (!isModified) {
      isModified = super.isModified(keys, inverse)
    }
    return isModified
  }
 }

export interface TeamProps {
  name: string;
  id: string;
  users: User[];
  picture: Picture;
  projects: Project[];
  description?: string;
  enabled: boolean;
  size?: number
  projectsCount?: number
  usersAndRoles?: UserWithRole[]
  rolesOfAccessToItemCreation?: TeamRolesWithAccess[]
  usersToInvite?: Invitation[]
  projectIds?: string[]
  urlName?: string
  demo: boolean
}

export interface TeamPreview {
  name: string;
  id: string;
  picture: Picture;
  users?: User[]
  accountId: string;
  enabled: boolean;
  size?: number
  projectsCount?: number
  urlName?: string
}

export interface TeamDTO {
  name: string;
  id: string;
  users: User[];
  picture: Picture;
  projects: Project[];
  description?: string;
  enabled: boolean;
  size?: number;
  projectsCount?: number;
  urlName: string;
  demo: boolean;
}

export class Team {
  name: string
  id: string
  users: User[]
  picture: Picture
  projects: Project[]
  description?: string
  enabled: boolean
  size?: number
  projectsCount?: number
  usersAndRoles?: UserWithRole[]
  rolesOfAccessToItemCreation: TeamRolesWithAccess[]
  usersToInvite: Invitation[]
  projectIds: string[]
  urlName: string
  demo: boolean
  constructor(props?: TeamProps) {
    this.setDefaultData()

    if (!props) {
      return
    }

    const {
      name,
      id,
      users,
      picture,
      projects,
      description,
      enabled,
      size,
      projectsCount,
      usersAndRoles,
      rolesOfAccessToItemCreation,
      usersToInvite,
      projectIds,
      urlName,
      demo,
    } = props

    this.name = name ?? ""
    this.id = id ?? ""
    this.users = users?.map(user => User.create(user)) ?? []
    this.picture = picture ?? { pictureUrl: null }
    this.projects = projects ?? []
    this.description = description ?? ""
    this.enabled = enabled ?? false
    this.size = size ?? 0
    this.projectsCount = projectsCount ?? 0
    this.usersAndRoles = usersAndRoles ?? []
    this.rolesOfAccessToItemCreation = rolesOfAccessToItemCreation ?? []
    this.usersToInvite = usersToInvite ?? []
    this.projectIds = projectIds ?? []
    this.urlName = urlName ?? ""
    this.demo = demo ?? false
  }


  getInitials(): string {
    return getInitialsHelper(this.name)
  }

  setDefaultData() {
    this.name = ""
    this.id = ""
    this.users = []
    this.projects = []
    this.description = ""
    this.enabled = false
    this.size = 0
    this.projectsCount = 0
    this.usersAndRoles = []
    this.rolesOfAccessToItemCreation = [
      TeamRolesWithAccess.ENGINEERING_MANAGERS,
      TeamRolesWithAccess.PRODUCT_OWNERS_AND_PM,
      TeamRolesWithAccess.SCRUM_MASTER,
    ]
    this.usersToInvite = []
    this.projectIds = []
    this.urlName = ""
    this.demo = false
  }
  static create(teamDTO: TeamDTO): Team {
    if (teamDTO) {
      return new Team(teamDTO)
    }
    return new Team()
  }

  static createDefault(): Team {
    return new Team()
  }

  get usersDevOps() {
    return this.users.filter(u => u.teamRole === "DEVOPS")
  }

  get usersEngineer() {
    return this.users.filter(u => u.teamRole === "ENGINEER")
  }

  get usersUi_ux() {
    return this.users.filter(u => u.teamRole === "UI_UX")
  }

  get usersQa() {
    return this.users.filter(u => u.teamRole === "QA")
  }

  get usersWithDeveloperRole() {
    return this.users.filter(u => DeveloperRoles[u.teamRole])
  }

  getJsonObj() {
    return {
      name: this.name.trim(),
      id: this.id,
      users: this.users,
      picture: this.picture,
      projects: this.projects,
      description: this.description,
      enabled: this.enabled,
      size: this.size,
      projectsCount: this.projectsCount,
      usersAndRoles: this.usersAndRoles,
      rolesOfAccessToItemCreation: this.rolesOfAccessToItemCreation,
      usersToInvite: this.usersToInvite,
      projectIds: this.projects.map(project => project.id),
      urlName: this.name.trim().toLowerCase().replace(/\s/g, "-"),
    }
  }
}
