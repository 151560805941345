import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-fancybox", "href"]
const _hoisted_2 = ["id", "src"]
const _hoisted_3 = { class: "video-attachment" }
const _hoisted_4 = { class: "video-attachment__video ready-to-play" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "video-attachment__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!

  return (_openBlock(), _createElementBlock("a", {
    id: "videoPath",
    "data-fancybox": _ctx.fancyboxData,
    href: _ctx.videoPath,
    "data-type": "video",
    class: "video-attachment__link"
  }, [
    _createElementVNode("img", {
      id: _ctx.videoPath + 'img',
      src: _ctx.img,
      alt: "Thumbnail",
      class: "video-attachment__poster"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("video", {
          class: "video-attachment__video",
          src: _ctx.videoPath
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseSubHeading, {
          color: "monochrome-07",
          weight: "400",
          size: "large",
          class: "video-attachment__title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Video: " + _toDisplayString(_ctx.file.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseSubHeading, {
          size: "medium",
          weight: "500",
          color: "monochrome-05",
          darkColor: "monochrome-04",
          class: "video-attachment__size"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatBytes(_ctx.file.size.toString())), 1)
          ]),
          _: 1
        })
      ])
    ])
  ], 8, _hoisted_1))
}