<template>
  <div class="definition">
    <div class="definition__title">
      <BaseSubHeading
        v-if="!change"
        className="definition__title_item"
        size="medium"
        weight="400"
      >
        Identify the steps that need to be completed for this effort to be marked as done.
      </BaseSubHeading>
      <BaseSubHeading
        v-if="change"
        className="definition__title_item"
        size="medium"
        weight="400"
      >
        Select "Definition of Done" criteria(s) that are appropriate to confirm the doneness of the effort.
      </BaseSubHeading>
      <button
        v-if="!change"
        class="definition__title_add"
        @click="onEdit"
      >
        <icon
          data="@icon/pen.svg"
          color="#336DFF"
          width="1.5em"
          height="1.5em"
        />
        Edit
      </button>
    </div>

    <transition name="transitionCheck">
      <ul
        v-if="!change && itemId"
        class="definition__content custom-scroll"
      >
        <li
          v-for="(item, index) in enabledDefinitions"
          :key="index"
          :style="getDefinitionStyle(index)"
        >
          <BasicCheckbox
            :label="item.definition"
            :value="item.definition"
            :modelValue="item.done"
            class="definition__checkbox"
            :class="{'definition__checkbox_check':item.done}"
            checkType="check"
            @update:modelValue="onCheckboxUpdate"
          />
        </li>
      </ul>
      <ul
        v-else
        class="definition__content custom-scroll"
      >
        <li
          v-for="(item, index) in allDefinitionsList"
          :key="index"
        >
          <BasicCheckbox
            :label="item.definition"
            :value="item.definition"
            :modelValue="item.enabled"
            :isDisabled="isMandatory(item)"
            class="definition__item_switch"
            checkType="switch"
            @update:modelValue="onSwitchUpdate"
          />
        </li>
      </ul>
    </transition>
    <AddBasicInput
      v-if="change"
      @enter="onAddCustomCheckbox"
    />
  </div>

  <template v-if="showCompleteButtons">
    <div
      v-if="showFiveWhysButton"
      class="item-post-mortem"
    >
      <BaseSubHeading
        size="medium"
        weight="400"
        className="item-post-mortem__description"
        color="monochrome-05"
      >
        To mark Support Case completed, please fill out the 5 Why's
      </BaseSubHeading>
      <button
        class="item-post-mortem__button secondary-button definition__gradient-button"
        :disabled="!definitionsReadyToComplete"
        @click.prevent="$emit('fillPostMortem')"
      >
        Fill out 5 Why's
        <icon
          data="@icon/completed.svg"
          :fill="true"
          color="#4CAB26"
          width="1.25em"
          height="1.25em"
        />
      </button>
    </div>

    <BaseSubHeading
      v-else
      size="medium"
      weight="400"
      className="item-completed"
    >
      <BaseSubHeading
        v-if="!inActiveSprint && enableMarkAsCompleted"
        size="medium"
        weight="400"
        className="item-completed__description"
        color="monochrome-05"
      >
        Item must be in an Active Sprint to be marked as "Completed"
      </BaseSubHeading>
      <div v-else>
        &nbsp;
      </div>
      <button
        class="item-completed__button secondary-button definition__gradient-button"
        :disabled="!enableMarkAsCompleted || !inActiveSprint"
        @click="$emit('itemCompleted')"
      >
        Mark as Completed
        <icon
          data="@icon/completed.svg"
          :fill="true"
          color="#4CAB26"
          width="1.25em"
          height="1.25em"
        />
      </button>
    </BaseSubHeading>
  </template>
  <div
    v-if="change && itemId"
    class="definition__buttons-wrapper"
  >
    <ControlButtons
      :small="true"
      @save="save"
      @cancel="cancel"
    />
  </div>
  <Teleport to="body">
    <ModalDefinitionDone
      :config="taskModalData"
      @confirm="onDefinitionModalConfirm"
    />
  </Teleport>
</template>

<script lang="ts">
import BasicCheckbox from "@/components/Form/BasicCheckbox.vue"
import AddBasicInput from "@/components/Form/AddBasicInput.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
import DropdownSingleLabel from "@/components/Dropdown/DropdownSingleLabel.vue"
import ModalDefinitionDone from "@/components/Modal/ModalDefinitionDone.vue"
import { Emit, Prop } from "vue-property-decorator"
import {
  DefinitionOfDone,
  DefinitionOfDoneProps,
  Item,
  ModalTypesForCratingTask,
  PostMortem,
  Project,
  Task,
  UpdateObjectValue,
} from "@/models"
import { Options, Vue } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import { Getters } from "@/store/getters"
import { Actions } from "@/store/actions"
@Options({
  name: "DefinitionOfDoneComponent",
  components: {
    BasicCheckbox,
    AddBasicInput,
    DropdownSingleLabel,
    ControlButtons,
    ModalDefinitionDone,
  },
  emits: ["update", "itemCompleted", "fillPostMortem"],
})
export default class DefinitionOfDoneComponent extends Vue {
  @Prop({ required: true }) readonly item: Item
  @Prop({ default: false }) readonly edit: boolean
  @Prop({ default: "" }) readonly itemType: string
  @Prop({ default: false }) readonly isDone: boolean
  @Prop({ default: false }) readonly inActiveSprint: boolean

  @Getter(Getters.GET_CURRENT_PROJECT_ID) readonly projectId: string
  @Getter(Getters.GET_PROJECT) readonly project: Project
  @Getter(Getters.GET_DEFINITIONS_DEFAULT) readonly defaultDefinitionsList: DefinitionOfDone[]
  @Getter(Getters.GET_TASKS) readonly tasks: Task[]
  @Getter(Getters.GET_DEFINITIONS_LIST) readonly enabledDefinitions: DefinitionOfDone[]
  @Getter(Getters.GET_FIVE_WHYS) readonly postMortem: PostMortem

  @Action(Actions.GET_TASKS) getTasks: ({ itemId } : {itemId: string}) => void
  @Action(Actions.CREATE_NEW_TASK) createTask: () => void
  @Action(Actions.CHANGE_NEW_TASK) changeTask: (payload: UpdateObjectValue<Task>) => void
  @Action(Actions.PUT_DEFINITION_OF_DONE) saveDefinitionOfDone: ({ item, definitionsOfDone } : {item: Item, definitionsOfDone: DefinitionOfDone[]}) => void
  @Action(Actions.UPDATE_DEFINITION_OF_DONE_LIST) updateDefinitionOfDoneList: (definition: DefinitionOfDone[]) => void
  @Action(Actions.SET_MODAL_TYPE_FOR_CREATING_NEW_TASK) onShowModalCreateTask: (payload: ModalTypesForCratingTask) => void
  numberOfColumns = 2

  minNumberOfLines = 2

  change = true
  definitionsListSingleColumnBreakpoint = 5

  definitionLabel = ""

  taskModalData = {
    tasksCount: 0,
    tasksCountBugs: 0,
    taskHours: 0,
    taskHoursBugs: 0,
    taskType: "",
    taskTypeBugs: "",
    definitionLabel: "",
  }

  allDefinitionsList: DefinitionOfDone[] = []

  importDefinitionsList: DefinitionOfDone[] = []

  get taskTypesList() {
    return Task.getTaskTypes(this.tasks)
  }


  get showFiveWhysButton(): boolean {
    if (this.item.isSupportCase && !this.postMortem?.resolution) {
      const levels = ["NONE", "TRIVIAL", "MEDIUM", "HIGH", "CRITICAL"]
      let a = levels.indexOf(this.project.fiveWhysThresholdSeverityLevel)
      let b = levels.indexOf(this.item.severityLevelParsed?.id ?? "NONE")
      return a <= b
    }
    return false
  }

  get itemId() {
    return this.item?.id ?? ""
  }

  get enableMarkAsCompleted() {
    return !this.change && this.definitionsReadyToComplete && !this.isDone
  }

  get definitionsReadyToComplete() {
    return !!this.enabledDefinitions.length && this.enabledDefinitions.every(definition => definition.done)
  }

  get showCompleteButtons() {
    return !this.change && this.itemId && !this.isDone
  }

  onUpdateAllDefinitionsList() {
    this.allDefinitionsList = [...this.defaultDefinitionsList].map((def) => {
      if (this.enabledDefinitions.find(e => e.definition === def.definition) || this.isMandatory(def)) {
        def.enabled = true
      }
      return def
    })
    this.enabledDefinitions.forEach((e) => {
      if (!this.allDefinitionsList.find(def => e.definition === def.definition)) {
        e.enabled = true
        this.allDefinitionsList.push(e)
      }
    })
  }

  async created() {
    this.change = this.edit
    this.onUpdateAllDefinitionsList()
  }

  isMandatory(def: DefinitionOfDoneProps): boolean {
    let mandatory = false
    if (def.types) {
      def.types.forEach((type) => {
        if (this.taskTypesList.has(type)) {
          mandatory = true
        }
      })
    }
    return mandatory
  }

  onCheckboxUpdate(value: boolean, label: string) {
    const definition = this.enabledDefinitions.find(definition => definition.definition === label)

    if (!definition) return false

    let taskFilter = [...this.tasks]
    let checkDefTypeInTasks = true
    let taskFilterBugs = taskFilter.filter(e => e.typeParsed.id === "BUG" && Number(e.estimatedHours) !== 0)

    let defTypeInTasks = taskFilter.filter((e) => {
      if (definition.types?.includes(e.typeParsed.id)) {
        if (checkDefTypeInTasks) {
          let payload: any = { id: "typeParsed", value: e.typeParsed }
          this.createTask()
          this.changeTask(payload)
          this.definitionLabel = definition.definition
        }
        checkDefTypeInTasks = false
        return e
      }
    })

    taskFilter = taskFilter.filter(e => definition.types?.includes(e.typeParsed.id) && Number(e.estimatedHours) !== 0)

    let inTaskType = false

    this.taskTypesList.forEach((taskType) => {
      if (!inTaskType && !definition?.done && taskFilter.length) {
        inTaskType = !!definition?.types?.includes(taskType)
      }
      if (!inTaskType && !definition?.done && taskFilterBugs.length) {
        inTaskType = taskType === "BUG"
      }
    })

    if (inTaskType && definition?.types) {
      const taskType = definition.types[0] === "BUG" ? "DEV" : definition.types[0]
      this.taskModalData = {
        tasksCountBugs: definition!.types?.includes("DEV") ? Task.getEstimatedTasksItemsCount({
          tasks: this.tasks,
          type: "BUG",
        }) : 0,
        tasksCount: Task.getEstimatedTasksItemsCount({
          tasks: this.tasks,
          type: taskType,
        }),
        taskHours: Task.getEstimatedTasksCount({
          tasks: this.tasks,
          type: taskType,
        }),
        taskHoursBugs: definition!.types?.includes("DEV") ? Task.getEstimatedTasksCount({
          tasks: this.tasks,
          type: "BUG",
        }) : 0,
        taskType: taskType as string,
        taskTypeBugs: definition!.types?.includes("DEV") ? "Bug" : "",
        definitionLabel: definition.definition,
      }
      this.$modal.show("definition-of-done-modal")
      return false
    }

    if (defTypeInTasks.length && definition.done) {
      this.onShowModalCreateTask("DEFINITION")
      return false
    } else {
      const updateDefinition = [...this.enabledDefinitions].map((def) => {
        if (def.definition === label) {
          def.done = value
        }
        return def
      })
      definition.done = value
      this.updateDefinitionOfDone(updateDefinition)
    }
  }

  async onDefinitionModalConfirm(config) {
    let id = config.definitionLabel
    const updateDefinition = [...this.enabledDefinitions].map((def) => {
      if (def.definition === id) {
        def.done = true
      }
      return def
    })
    this.$modal.hide("definition-of-done-modal")
    await this.updateDefinitionOfDone(updateDefinition)
    this.getTasks({ itemId: this.itemId })
  }

  onSwitchUpdate(value: boolean, id: string) {
    const definition = [
      ...this.allDefinitionsList,
    ].find(definition => definition.definition === id)
    if (definition) {
      definition.enabled = value
      definition.done = false
      this.importDefinitionsList.find(def => def.definition === definition.definition) ?
        this.importDefinitionsList = this.importDefinitionsList.filter(def => def.definition !== definition.definition) :
        this.importDefinitionsList.push(definition)
    }
    if (!this.itemId) {
      this.updateDefinitionOfDone(this.importDefinitionsList)
    }
  }

  onAddCustomCheckbox(definition: string) {
    if (definition.length === 0 || this.allDefinitionsList.some(def => def.definition === definition.trim())) return
    this.allDefinitionsList.push(DefinitionOfDone.create({
      definition,
      done: false,
      enabled: true,
    }))
    this.onSwitchUpdate(true, definition)
  }

  save() {
    this.change = false
    this.updateDefinitionOfDone(this.importDefinitionsList)
    this.importDefinitionsList = []
  }

  cancel() {
    this.change = false
    this.importDefinitionsList = []
    this.onUpdateAllDefinitionsList()
  }

  onEdit() {
    this.importDefinitionsList = [
      ...this.enabledDefinitions,
      ...this.importDefinitionsList,
    ]
    this.change = true
  }

  getDefinitionStyle(index: number) {
    let maxLinesForColumn = Math.ceil(this.enabledDefinitions.length / (this.minNumberOfLines * this.numberOfColumns)) * this.minNumberOfLines
    return `grid-column-start: ${index < maxLinesForColumn ? 1 : 2}; grid-row-start: ${index < maxLinesForColumn ? 0 : index - (maxLinesForColumn - 1)}`
  }

  async updateDefinitionOfDone(value: DefinitionOfDone[]) {
    await this.updateDefinitionOfDoneList(value)
    if (this.itemId) {
      await this.saveDefinitionOfDone({
        item: this.item,
        definitionsOfDone: value,
      })
      await this.getTasks({ itemId: this.itemId })
    }
  }
}
</script>
