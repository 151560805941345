import { User } from "./User"
import { v4 as uuidv4 } from "uuid"
import { $t } from "@/plugins/i18n"
import { Base } from "./Base"
import { ReferencedResourcePreviews } from "./Search"
import { Item } from "./Item"
import { replaceHtmlTags } from "@/utils/helpers"
import { StructuredDescriptionHelper } from "./EditorData"
import {
  updateReferencesByResourcePreviews,
} from "@/utils/updateReferencesByResourcePreviews"

export type ModalTypesForCratingTask = "RE_OPEN" | "IN_PROGRESS" | "DEFINITION" | null

export type TotalOption = {
  label: string,
  value: number
}

export type TaskOptions = {
  id: string,
  label: string,
  icon?: object,
  iconOption?: iconOption
}

export type iconOption = {
  width?: string,
  height?: string,
  color?: string
}

export type TaskTypeOption = {
  id: TaskType,
  label: string
}

interface StructuredDescription {
  html: string;
  json: string;
  text: string;
}

const parsedTaskTypes: TaskTypeOption[] = [
  {
    id: "DESIGN",
    label: $t("task.types.design"),
  },
  {
    id: "UI_UX",
    label: $t("task.types.ui/ux"),
  },
  {
    id: "DEV",
    label: $t("task.types.dev"),
  },
  {
    id: "QA",
    label: $t("task.types.qa"),
  },
  {
    id: "BUG",
    label: $t("task.types.bug"),
  },
  {
    id: "DEVOPS",
    label: $t("task.types.devops"),
  },
]

export type TaskType = "DEV" | "QA" | "UI_UX" | "DEVOPS" | "DESIGN" | "BUG"

export interface ITask {
  name: string
  type: TaskType
  description?: string
  fileMetadataIds?: string[]
  typeParsed?: TaskTypeOption
  estimatedHours?: number
  order?: number
  id?: string
  itemId?: string
  owner?: User
  ownerId?: string
  estimationModificationTime?: string
  outdatedEstimation?: boolean
  structuredDescription?: StructuredDescriptionHelper
  referencedResourcePreviews?: ReferencedResourcePreviews
  text: string
  html: string
  json: string
}

export interface UpdateTaskList {
  tasks: Task[],
  item: Item,
  projectId?: string,
  removedTask?: Task
}

export interface TaskIdAndEstimation {
  taskId: string,
  estimation: number
}

export interface PatchTaskList {
  taskIdsAndEstimation: TaskIdAndEstimation[],
  item: Item,
  projectId?: string,
}

export class Task extends Base<Task> implements ITask {
  id?: string
  name: string
  type: TaskType
  typeParsed: TaskTypeOption
  estimatedHours?: number
  fileMetadataIds?: string[]
  order: number
  owner?: User
  ownerId?: string
  estimationModificationTime?: string
  itemId?: string // Potentialy unused prop
  outdatedEstimation?: boolean
  oldEstimation?: number
  structuredDescription?: StructuredDescriptionHelper
  referencedResourcePreviews?: ReferencedResourcePreviews
  text: string
  html: string
  json: string

  constructor(props?: ITask) {
    super()
    this.setDefaultData()

    if (!props) return

    this.set(props)
  }

  set(props: Partial<Task>) {
    this.id = props.id ?? ""
    this.name = props.name ?? ""
    this.type = props.type ?? "DEV"
    this.typeParsed = this.getParsedType(this.type)
    this.estimatedHours = props.estimatedHours ?? undefined
    this.oldEstimation = props.oldEstimation ?? undefined
    this.fileMetadataIds = props.fileMetadataIds ?? []
    this.order = props.order ?? 1
    this.owner = props.owner ? User.create(props.owner) : undefined
    this.ownerId = props.ownerId ?? ""
    this.estimationModificationTime = props.estimationModificationTime ?? ""
    this.outdatedEstimation = props.outdatedEstimation ?? false
    this.structuredDescription = new StructuredDescriptionHelper(props.structuredDescription)
    if (props.referencedResourcePreviews && this.structuredDescription?.html) {
      this.structuredDescription.html = updateReferencesByResourcePreviews(props.referencedResourcePreviews, this.structuredDescription.html)
    }
    if (this.structuredDescription) {
      this.html = this.structuredDescription.html
      this.text = this.structuredDescription.text
      this.json = this.structuredDescription.json
    } else {
      this.text = props.text ?? ""
      this.html = props.html ?? ""
      this.json = props.json ?? ""
    }
    return this
  }

  setDefaultData() {
    this.id = uuidv4() // Todo remove uuid
    this.name = ""
    this.text = ""
    this.html = ""
    this.json = ""
    this.type = "DEV"
    this.typeParsed = this.getParsedType()
    this.fileMetadataIds = []
    this.order = 1
    this.owner = undefined
    this.ownerId = ""
    this.estimationModificationTime = ""
    this.oldEstimation = undefined
  }

  static create(props?: ITask): Task {
    return new Task(props)
  }

  static createDefault(
    { itemId, order }: { itemId?: string; order?: number } = {
      itemId: "",
      order: 1,
    },
  ): Task {
    return new Task({
      name: "",
      type: "DEV",
      itemId,
      json: "",
      text: "",
      html: "",
      order,
    })
  }

  static putIndexes(tasks: Task[]) {
    return tasks.map((item, order) =>
      Object.assign(item, {
        order: order + 1,
      }),
    )
  }

  static get types() {
    return parsedTaskTypes
  }

  static getTotalValue({ type, tasks }: { type?: TaskType; tasks: Task[] }) {
    if (!type) {
      return tasks
        .filter(item => item.isEstimated)
        .reduce((acc, item) => acc + Number(item.estimatedHours), 0)
    }
    return tasks
      .filter(item => item.typeParsed && item.typeParsed.id === type && item.isEstimated)
      .reduce((acc, item) => acc + Number(item.estimatedHours), 0)
  }

  static getTaskTypes(tasks: Task[]) {
    return new Set(tasks.filter(task => task.isNotEmpty).map(task => task.typeParsed.id))
  }

  get simpleDiff() {
    return {
      name: this.name,
      typeParsed: this.typeParsed,
      estimatedHours: this.estimatedHours,
      order: this.order,
      owner: this.owner,
      html: replaceHtmlTags(this.html),
    }
  }

  getParsedType(type = "DEV") {
    const item = Task.types.find(item => item.id === type)

    return item ? item : Task.types[0]
  }

  getJsonObj() {
    return {
      id: this.id,
      name: replaceHtmlTags(this.name),
      type: this.typeParsed.id,
      estimatedHours: this.estimatedHours,
      order: this.order,
      ownerId: this.owner?.id,
      fileMetadataIds: this.fileMetadataIds,
      estimationModificationTime: this.estimationModificationTime,
      outdatedEstimation: this.outdatedEstimation,
      structuredDescription: {
        html: this.structuredDescription?.html,
        json: this.structuredDescription?.json,
        text: this.structuredDescription?.text,
      },
    }
  }

  get isNotEmpty(): boolean {
    return !!this.name.trim()
  }

  get isEstimated() {
    return this.estimatedHours !== null && this.estimatedHours !== undefined
  }

  get status() {
    return this.oldEstimation !== null
      ? this.estimatedHours == 0
        ? "Done"
        : "In Progress"
      : "Added"
  }

  get typeString() {
    return this.getParsedType(this.type)?.label ?? ""
  }

  get ownerFullName() {
    return this.owner ? `@${this.owner.firstName} ${this.owner.lastName}` : "Unassigned"
  }

  static getEstimatedTasksCount({ tasks, type }: { tasks: Task[]; type?: TaskType }) {
    const estimatedTasks = tasks.filter(
      e => e.estimatedHours !== null && e.estimatedHours !== undefined,
    )

    const filteredTasks = type
      ? estimatedTasks.filter(e => e.typeParsed.id === type)
      : estimatedTasks

    return filteredTasks.reduce((prev, next) => prev + Number(next.estimatedHours), 0)
  }

  static getEstimatedTasksItemsCount({ tasks, type }: { tasks: Task[]; type?: TaskType }) {
    return tasks
      ?.filter(e => e.isNotEmpty)
      .filter(e => e.typeParsed.id === type)
      ?.filter(e => e.estimatedHours! > 0)?.length
  }

  static checkCompletionOfAllTasks(tasks: Task[]) {
    return tasks.every(e => e.estimatedHours === 0)
  }

  static getItemEstimation(tasks) {
    if (!tasks.length) return undefined
    if (Task.checkCompletionOfAllTasks(tasks)) return 0
    const estimatedTasks = tasks.filter(e => !!e.estimatedHours)
    if (!estimatedTasks.length) return undefined
    return estimatedTasks.reduce((prev, next) => prev + Number(next.estimatedHours), 0)
  }
}
