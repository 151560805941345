import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@icon/menu.svg'


const _hoisted_1 = { class: "header__menu" }
const _hoisted_2 = {
  key: 0,
  class: "header__menu__box"
}
const _hoisted_3 = { class: "header__menu__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: _normalizeClass(["body-text body-text-large-400", [{'clicked__button' : _ctx.opened}, {'is-disabled' : !_ctx.visibleMenuItems.length}]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createVNode(_component_icon, {
        data: _imports_0,
        fill: true,
        width: "1.5em",
        height: "1.5em"
      })
    ], 2)), [
      [_directive_click_outside, _ctx.hide]
    ]),
    (_ctx.opened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleMenuItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "header__menu__item"
              }, [
                _createVNode(_component_BaseSubHeading, {
                  size: "large",
                  weight: "400",
                  color: "monochrome-05",
                  className: "header__menu__button",
                  onClick: ($event: any) => (_ctx.onClickRouteLink(item))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon, {
                      data: item.icon,
                      fill: item.fill,
                      class: "header__menu__icon",
                      color: item.iconColor,
                      width: "1.5em",
                      height: "1.5em"
                    }, null, 8, ["data", "fill", "color"]),
                    _createTextVNode(" " + _toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}