<template>
  <div
    class="progress-bar"
    :class="[
      {'is-active': isMore},
      `is-${color}`
    ]"
  >
    <div
      v-if="showLabels"
      class="progress-bar__header"
      >
      <slot name="label">
        <BaseSubHeading
          size="medium"
          weight="400"
          color="monochrome-05"
          darkColor="monochrome-03"
          className="progress-bar__label"
        >
          {{ desc.label }}
        </BaseSubHeading>
      </slot>
      <BaseSubHeading
        v-if="showInfo"
        size="medium"
        weight="400"
        color="monochrome-07"
        className="progress-bar__meta"
      >
        <b>{{ desc.currentValue }}</b> of <b>{{ desc.maxValue }}</b>
      </BaseSubHeading>
    </div>

    <div class="progress-bar__body">
      <div class="progress-bar__line">
        <span
          class="progress-bar__bg"
          :style="{ width: isMore ? '100%' : lineWidth + '%'}"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ProgressBarConfig } from "@/models"
import { ProgressBarColor } from "@/models/ProgressBar"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "ProgressBar",
})
export default class ProgressBar extends Vue {
  @Prop(Object) readonly desc: ProgressBarConfig
  @Prop({ default: true }) readonly showLabels: boolean
  @Prop({ default: true }) readonly showInfo: boolean
  @Prop({ default: "green" }) readonly color: ProgressBarColor
  gradientDotLessLimit = 6

  get lineWidth() {
    if (this.desc) {
      const { currentValue, maxValue } = this.desc
      const calculateValue = (+currentValue * 100) / +maxValue

      return calculateValue
    }
    return 0
  }

  get isMore() {
    const { currentValue, maxValue } = this.desc
    return currentValue > maxValue
  }
}
</script>
