<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="notification.projectName"
  >
    <template #pic>
      <div class="label-pic is-blue">
        <icon
          data="@icon/list-unordered-v2.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
          color="#1565C0"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        ToDo task added for you
      </BaseSubHeading>
    </template>
    <template #content>
      <ul>
        <li
          v-for="(todo, index) in notification.toDos"
          :key="index"
          class="notification__todo"
        >
          <BaseSubHeading
            v-if="!todo.relatedUrl"
            v-ticket="{ wholeLink: true, todo: notification.getProjectId(todo) }"
            size="medium"
            color="monochrome-06"
            darkColor="monochrome-03"
            weight="400"
            className="notification__todo-item"
          >
            {{ todo.description ?? $t("none") }}
          </BaseSubHeading>

          <a
            v-else
            :href="todo?.relatedUrl"
          >
            {{ todo.description ?? $t("none") }}
          </a>

          <icon
            v-if="todo.urgent"
            data="@icon/flag.svg"
            :fill="true"
            width="14"
            height="14"
            class="notification__todo-icon"
            color="#D32F2F"
          />
        </li>
      </ul>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationTodo } from "@/models/Notification/NotificationTodo"
import { ticket } from "@/directives"

@Options({
  name: "TODO_ASSIGNMENT",
  components: {
    NotificationTemplate,
  },
  directives: {
    ticket,
  },
})
export default class TODO_ASSIGNMENT extends Vue {
  @Prop() readonly notification: NotificationTodo
}
</script>
