import { RouteLocationRaw } from "vue-router"
import isEqual from "lodash/isEqual"

import { CustomError } from "@/models"
import { $keycloak } from "@/plugins/keycloak"
import { Actions, Getters, store } from "@/store"

export default async (
  to,
  from,
  next: (arg?: RouteLocationRaw) => void
): Promise<void | boolean> => {
  const auth = $keycloak.authenticated
  if (!auth || isEqual(to.params, from.params)) {
    next()
    return
  }
  const { params } = to
  try {
    if (params.accountId) {
      const storeAccount = store.getters[Getters.GET_SESSION_ACCOUNT]
      if (storeAccount?.urlName !== params.accountId) {
        const accountId = await store.dispatch(Actions.RESOLVE_SESSION_ACCOUNT, params.accountId)
        const account = await store.dispatch(Actions.GET_SESSION_ACCOUNT, accountId)
        if (!account.enabled) {
          return next({
            name: "DisabledAccount"
          })
        }
      }
    } else {
      await store.dispatch(Actions.RESET_SESSION_ACCOUNT)
    }

    if (!params.projectId) {
      await store.dispatch(Actions.RESET_SESSION_PROJECT)
    } else {
      await store.dispatch(Actions.RESOLVE_SESSION_PROJECT_ID, {
        name: params.projectId
      })
    }

    if (!params.teamId) {
      await store.dispatch(Actions.RESET_SESSION_TEAM)
    } else if (store.getters[Getters.GET_SESSION_TEAM]?.urlName !== params.teamId) {
      await store.dispatch(Actions.RESOLVE_SESSION_TEAM_ID, {
        name: params.teamId
      })
    }

    if (params.sprintId) {
      const teamId = store.getters[Getters.GET_SESSION_TEAM_ID]
      if (teamId) {
        await store.dispatch(Actions.RESOLVE_SESSION_SPRINT_ID, {
          sequenceNumber: params.sprintId,
          teamId
        })
      } else {
        console.error("Can not resolve sprint id because team id is null.")
      }
    } else {
      await store.dispatch(Actions.RESET_SESSION_SPRINT_ID)
    }

    if (params.itemId) {
      await store.dispatch(Actions.RESOLVE_ITEM_ID, {
        sequenceNumber: params.itemId
      })
    } else {
      await store.dispatch(Actions.RESET_SESSION_ITEM_ID)
    }

    if (params.epicId) {
      await store.dispatch(Actions.RESOLVE_EPIC_ID, params.epicId)
    }
  } catch (error) {
    console.error(error)
    return next({
      name: "Error",
      query: {
        code: error instanceof CustomError ? error.code : 404
      }
    })
  }
  return next()
}
