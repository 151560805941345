import {
  Module,
  Mutation,
  Action,
} from "vuex-class-modules"
import { store } from "../store"
import { Modules } from "../modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { ServerResponse } from "@/models/ServerResponse"
import { BaseModule } from "@/models/BaseModule"
import { FilterNotification } from "@/models/Filter"
import { Notification, NotificationInit } from "@/models/Notification"


export enum NotificationActions {
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_RESPONSE = "GET_NOTIFICATIONS_RESPONSE",
  GET_NEW_NOTIFICATIONS = "GET_NEW_NOTIFICATIONS",
  GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT",
  READ_NOTIFICATIONS = "READ_NOTIFICATIONS",
  SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT",
  SET_NOTIFICATION_READ = "SET_NOTIFICATION_READ",
}

@Module
class NotificationModule extends BaseModule {
  notifications: Notification[] = []
  serverResponse: ServerResponse<Notification> | null
  count = 0

  get [`get/${NotificationActions.GET_NOTIFICATIONS_COUNT}`]() {
    return this.count
  }

  get [`get/${NotificationActions.GET_NOTIFICATIONS_RESPONSE}`]() {
    return this.serverResponse
  }

  get [`get/${NotificationActions.GET_NOTIFICATIONS}`]() {
    return this.notifications
  }

  get [`get/${NotificationActions.GET_NEW_NOTIFICATIONS}`]() {
    return this.notifications.filter(item => !item.read)
  }

  @Mutation
  setResponse(serverResponse: ServerResponse<Notification> | null) {
    this.serverResponse = serverResponse
  }

  @Mutation
  setNotifications(content: Notification[]) {
    this.notifications = content
  }

  @Mutation
  addNotifications(content: Notification[]) {
    this.notifications = [...this.notifications, ...content]
  }

  @Mutation
  setNotificationsCount(count: number) {
    this.count = count
  }

  @Mutation
  markAsReadNotification() {
    this.notifications = this.notifications.map((item) => {
      if (!item.read) {
        return Object.assign(item, { read: true })
      } else {
        return item
      }
    })
  }

  @Action
  async [NotificationActions.GET_NOTIFICATIONS_RESPONSE](filterNotification: FilterNotification) {
    const filter = filterNotification.getJsonObj()
    this.setResponse(null)

    const data = await apiService.get<ServerResponse<Notification>>(Endpoint.GET_NOTIFICATIONS(), filter)
    return this.handleResponse<ServerResponse<Notification>>(data, (data) => {
      const content: any[] = data.content.map(notification => NotificationInit.create(notification))
      this.setResponse(data)

      content.forEach((e, index) => {
        if (e.type === "ITEM_UPDATED") {
          content.splice(index, 1, ...NotificationInit.parseItemNotification(e))
        }
        if (e.type === "ITEM_SUB_TASK_ADDED_UPDATE") {
          content.splice(index, 1, ...NotificationInit.parseItemNotificationTasksUpdate(e))
        }
        if (e.type === "BUG_WATERMARK_EXCEEDED") {
          content.splice(index, 1, ...NotificationInit.parseBugWatermarkNotification(e))
        }
        if (e.type === "ITEM_CREATED") {
          content.splice(index, 1, ...NotificationInit.parseItemCreatedNotification(e))
        }
      })

      if (filterNotification.page === 0) {
        this.setNotifications(content)
      } else {
        this.addNotifications(content)
      }
    })
  }

  @Action
  async [NotificationActions.GET_NOTIFICATIONS_COUNT]() {
    const data = await apiService.get<number>(Endpoint.GET_NOTIFICATIONS_COUNT())

    return this.handleResponse<number>(data, (data) => {
      const count = data
      this.setNotificationsCount(count)
      return count
    })
  }

  @Action
  async [NotificationActions.READ_NOTIFICATIONS]() {
    const data = await apiService.post<any>(Endpoint.NOTIFICATIONS_MARK_AS_READ())

    this.markAsReadNotification()
    return this.handleResponse<number>(data, () => data)
  }

  @Action
  async [NotificationActions.SET_NOTIFICATIONS_COUNT](count: number) {
    this.setNotificationsCount(count)
  }

  @Action
  async [NotificationActions.SET_NOTIFICATION_READ](notificationsIds: string[]) {
    const count = await apiService.post<any>(Endpoint.NOTIFICATIONS_MARK_AS_READ(), notificationsIds)
    this.setNotificationsCount(count)
  }
}

export const notificationModule = new NotificationModule({
  store,
  name: Modules.NOTIFICATION,
})
