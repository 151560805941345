<template>
  <transition :name="!hideButtons ? 'collapse-and-hide' : 'fade'">
    <div
      v-if="!hideButtons"
      class="content__control-buttons"
    >
      <button
        v-if="!hideCancel"
        type="button"
        :tabindex="0"
        class="secondary-button btn content__control-buttons__cancel"
        :class="[{'secondary-button_small':small}]"
        @click.stop="$emit('cancel')"
      >
        <slot name="cancel">
          {{ $t("cancel") }}
        </slot>
      </button>

      <button
        v-if="!hideSave"
        type="button"
        :tabindex="0"
        class="primary-button btn content__control-buttons__save"
        :class="[{'primary-button_small':small}]"
        :disabled="actionDisabled"
        @click.stop="$emit('save')"
      >
        <slot name="save">
          {{ $t("save") }}
        </slot>
      </button>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
@Options({
  name: "ControlButtons",
  components: {},
})
export default class ControlButtons extends Vue {
  @Prop({ default: false }) readonly small: boolean
  @Prop({ default: false }) readonly hideCancel: boolean
  @Prop({ default: false }) readonly hideSave: boolean
  @Prop({ default: false }) readonly hideButtons: boolean
  @Prop() readonly actionDisabled
}
</script>
