<template>
  <CustomModal
    id="modal-theme"
    modalClass="custom-modal_margin-custom is-large modal-theme"
    width="936"
  >
    <template #header>
      <BaseHeading
        level="1"
      >
        Pick a Theme to please your eyes!
      </BaseHeading>
    </template>

    <template #body>
      <BaseSubHeading
        size="large"
        weight="400"
      >
        <div class="modal-theme__wrapper">
          <div
            class="modal-theme__button"
            :class="!darkTheme && 'modal-theme__button--active'"
            @click="onClick(false)"
          >
            <div class="modal-theme__light modal-theme__image" />
            <span>Light</span>
          </div>
          <div
            class="modal-theme__button"
            :class="darkTheme && 'modal-theme__button--active'"
            @click="onClick(true)"
          >
            <div class="modal-theme__dark modal-theme__image" />
            <span>Dark</span>
          </div>
        </div>
      </BaseSubHeading>
    </template>

    <template #footer>
      <ControlButtons
        :hideCancel="true"
        @save="onApply"
      >
        <template #save>
          Apply
        </template>
      </ControlButtons>
    </template>
  </CustomModal>
</template>

<script lang="ts">
import CustomModal from "@/components/Modal/index.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
import { Options, Vue } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import { Getters } from "@/store/getters"
import { Actions } from "@/store"
import { User, UserSettings } from "@/models"

@Options({
  name: "ModalTheme",
  components: {
    CustomModal,
    ControlButtons,
  },
})

export default class ModalTheme extends Vue {
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User

  @Action(Actions.UPDATE_PROFILE_SETTINGS) updateProfileSettings: ({ settings, immediate } : { settings: UserSettings, immediate?: boolean }) => void

  darkTheme = false

  mounted() {
    this.darkTheme = !!this.currentUser.settings?.darkTheme
  }

  onClick(darkTheme: boolean) {
    this.darkTheme = darkTheme
  }

  onApply() {
    this.updateProfileSettings({
      settings: {
        ...this.currentUser.settings,
        darkTheme: this.darkTheme,
      },
      immediate: true,
    })
    this.$modal.hide("modal-theme")
  }
}
</script>
