import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { $t } from "@/plugins/i18n"
import { ServerResponse } from "@/models/ServerResponse"
import { store } from "../store"
import { Modules } from "../modules"
import {
  SprintInfo,
  Sprint,
  SprintMetrics,
  SprintWithStats,
  UpdateObjectValue,
  SprintStatus,
  Quote,
  FilterBacklogMetrics,
  BacklogMetrics,
  ItemQueryPriority,
  SprintCapacityFilter,
  SprintCapacity,
  TimeOff,
  SprintAnalytics,
  SprintAnalyticsPerformance,
  SprintAnalyticsQuality,
  SprintAnalyticsTeam,
} from "@/models"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { SprintReview } from "@/models/SprintReview"
import { projectModule } from "./project.module"
import { BaseModule } from "@/models/BaseModule"
import { FilterSprint } from "@/models/Filter/FilterSprint"
import { Comment } from "@/models"

export enum SprintActions {
  GET_SPRINTS = "GET_SPRINTS",
  GET_SPRINT = "GET_SPRINT",
  GET_SPRINT_BY_SEQUENCE_NUMBER = "GET_SPRINT_BY_SEQUENCE_NUMBER",
  CREATE_SPRINT = "CREATE_SPRINT",
  UPDATE_SPRINT = "UPDATE_SPRINT",
  ADD_SPRINT_ITEM = "ADD_SPRINT_ITEM",
  REMOVE_SPRINT_ITEM = "REMOVE_SPRINT_ITEM",
  NOT_COMPLETED_SPRINTS = "NOT_COMPLETED_SPRINTS",
  RESET_NOT_COMPLETED_SPRINTS = "RESET_NOT_COMPLETED_SPRINTS",
  SPRINT_METRICS = "SPRINT_METRICS",
  SET_DEFAULT_SPRINT_METRICS = "SET_DEFAULT_SPRINT_METRICS",
  UPDATE_SPRINT_VALUE = "UPDATE_SPRINT_VALUE",
  RESET_SPRINT = "RESET_SPRINT",
  START_SPRINT = "START_SPRINT",
  COMPLETE_SPRINT = "COMPLETE_SPRINT",
  HALT_SPRINT = "HALT_SPRINT",
  GET_CURRENT_SPRINT_ID = "GET_CURRENT_SPRINT_ID",
  SET_CURRENT_SPRINT_ID = "SET_CURRENT_SPRINT_ID",
  RESOLVE_AND_SET_SPRINT_ID = "RESOLVE_AND_SET_SPRINT_ID",
  SPRINTS_WITH_STATS = "SPRINTS_WITH_STATS",
  SPRINT_WITH_STATS = "SPRINT_WITH_STATS",
  GET_SPRINT_NUMBER = "GET_SPRINT_NUMBER",
  RESET_SPRINT_NUMBER = "RESET_SPRINT_NUMBER",
  UPDATE_SPRINT_DURATION = "UPDATE_SPRINT_DURATION",
  SPRINT_START_REVIEW = "SPRINT_START_REVIEW",
  SPRINT_COMPLETE_REVIEW = "SPRINT_COMPLETE_REVIEW",
  CAN_START_SPRINT = "CAN_START_SPRINT",
  GET_SPRINT_REVIEW = "GET_SPRINT_REVIEW",
  UPDATE_ITEM_IN_SPRINT_REVIEW = "UPDATE_ITEM_IN_SPRINT_REVIEW",
  SET_ITEM_COMMENTS_IN_SPRINT_REVIEW = "SET_ITEM_COMMENTS_IN_SPRINT_REVIEW",
  SPRINT_QUOTE = "SPRINT_QUOTE",
  SPRINTS_WITH_STATS_RESPONSE = "SPRINTS_WITH_STATS_RESPONSE",
  ACTIVE_SPRINTS = "ACTIVE_SPRINTS",
  SPRINT_EXISTS_CONTINUOUS = "SPRINT_EXISTS_CONTINUOUS",
  UPDATE_SPRINT_METRICS_BY_FILTER = "UPDATE_SPRINT_METRICS_BY_FILTER",
  UPDATE_SPRINT_METRIC_UPDATE_FLAG = "UPDATE_SPRINT_METRIC_UPDATE_FLAG",
  EDIT_SUMMARY = "EDIT_SUMMARY",
  UPDATE_SPRINT_REVIEW_SUMMARY = "UPDATE_SPRINT_REVIEW_SUMMARY",
  SET_SPRINT = "SET_SPRINT",
  GET_SPRINT_CAPACITY = "GET_SPRINT_CAPACITY",
  SPRINT_TIME_OFFS = "SPRINT_TIME_OFFS",
  SPRINT_ANALYTICS = "SPRINT_ANALYTICS",
  SPRINT_ANALYTICS_PERFORMANCE = "SPRINT_ANALYTICS_PERFORMANCE",
  SPRINT_ANALYTICS_QUALITY = "SPRINT_ANALYTICS_QUALITY",
  SPRINT_ANALYTICS_TEAM = "SPRINT_ANALYTICS_TEAM"
}

export const defaultSprintMetric: SprintMetrics = {
  capacityHours: 0,
  items: 0,
  notEstimated: 0,
  plannedEffort: 0,
  totalStoryPoints: 0,
  completedStoryPoints: 0,
  timeLeftHours: 0,
  remainingHours: 0,
  unassigned: 0,
  velocity: 0,
  remaining: 0,
  completed: 0,
  currentRiskPercent: 0,
  itemMetricsLog: {},
  inProgress: 0,
  notStarted: 0,
  avgCycleTime: 0,
  avgLeadTime: 0,
  avgWip: 0,
}

@Module
class SprintModule extends BaseModule {
  sprints: Sprint[] = []
  sprint: Sprint = new Sprint()
  previousSprint: Sprint
  notCompletedSprints: SprintInfo[] = []
  sprintMetric: { [key: string]: SprintMetrics }
  sprintMetricUpdateItemsFlag = true
  sprintsWithStatsResponse: ServerResponse<SprintWithStats>
  sprintsWithStats: SprintWithStats[] = []
  sprintWithStats: SprintWithStats
  currentSprintId: string
  newSprintNumber = 0
  canStartSprint = false
  existsContinuousSprint = false
  sprintReview = new SprintReview()
  sprintQuote: Quote
  activeSprints: SprintWithStats[]
  sprintCapacity: SprintCapacity = new SprintCapacity()
  sprintAnalytics: SprintAnalytics = new SprintAnalytics()
  sprintAnalyticsPerformance: SprintAnalyticsPerformance = new SprintAnalyticsPerformance()
  sprintAnalyticsQuality: SprintAnalyticsQuality = new SprintAnalyticsQuality()
  sprintAnalyticsTeam: SprintAnalyticsTeam = new SprintAnalyticsTeam()

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${SprintActions.GET_SPRINTS}`]() {
    return this.sprints
  }

  get [`get/${SprintActions.GET_SPRINT}`]() {
    return this.sprint
  }


  get [`get/${SprintActions.CAN_START_SPRINT}`]() {
    return this.canStartSprint
  }

  get [`get/${SprintActions.NOT_COMPLETED_SPRINTS}`]() {
    return this.notCompletedSprints
  }

  get [`get/${SprintActions.GET_CURRENT_SPRINT_ID}`]() {
    return this.currentSprintId
  }

  get [`get/${SprintActions.GET_SPRINT_NUMBER}`]() {
    return this.newSprintNumber
  }

  get [`get/${SprintActions.SPRINTS_WITH_STATS}`]() {
    return this.sprintsWithStats
  }

  get [`get/${SprintActions.SPRINTS_WITH_STATS_RESPONSE}`]() {
    return this.sprintsWithStatsResponse
  }

  get [`get/${SprintActions.SPRINT_METRICS}`]() {
    return this.sprintMetric
  }

  get [`get/${SprintActions.SPRINT_WITH_STATS}`]() {
    return this.sprintWithStats
  }

  get [`get/${SprintActions.GET_SPRINT_REVIEW}`]() {
    return this.sprintReview
  }

  get [`get/${SprintActions.SPRINT_QUOTE}`]() {
    return this.sprintQuote
  }

  get [`get/${SprintActions.ACTIVE_SPRINTS}`]() {
    return this.activeSprints
  }

  get [`get/${SprintActions.SPRINT_EXISTS_CONTINUOUS}`]() {
    return this.existsContinuousSprint
  }

  get [`get/${SprintActions.GET_SPRINT_BY_SEQUENCE_NUMBER}`]() {
    return this.previousSprint
  }

  get [`get/${SprintActions.SPRINT_ANALYTICS}`]() {
    return this.sprintAnalytics
  }

  get [`get/${SprintActions.SPRINT_ANALYTICS_PERFORMANCE}`]() {
    return this.sprintAnalyticsPerformance
  }

  get [`get/${SprintActions.SPRINT_ANALYTICS_QUALITY}`]() {
    return this.sprintAnalyticsQuality
  }

  get [`get/${SprintActions.SPRINT_ANALYTICS_TEAM}`]() {
    return this.sprintAnalyticsTeam
  }

  @Mutation
  setSprintsResponse(sprintsWithStatsResponse: ServerResponse<SprintWithStats>) {
    this.sprintsWithStatsResponse = sprintsWithStatsResponse
  }

  @Mutation
  setSprints(sprints: Sprint[]) {
    this.sprints = sprints
  }

  @Mutation
  setSprint(sprint: Sprint) {
    this.sprint = sprint
  }

  @Mutation
  setSprintInSprintReview(sprint: Sprint) {
    this.sprintReview.sprint = sprint
  }

  @Mutation
  setSprintAnalytics(sprintAnalytics: SprintAnalytics) {
    this.sprintAnalytics = sprintAnalytics
  }

  @Mutation
  setSprintAnalyticsPerformance(sprintAnalytics: SprintAnalyticsPerformance) {
    this.sprintAnalyticsPerformance = sprintAnalytics
  }

  @Mutation
  setSprintAnalyticsQuality(sprintAnalytics: SprintAnalyticsQuality) {
    this.sprintAnalyticsQuality = sprintAnalytics
  }

  @Mutation
  setSprintAnalyticsTeam(sprintAnalytics: SprintAnalyticsTeam) {
    this.sprintAnalyticsTeam = sprintAnalytics
  }

  @Mutation
  setSprintMetric(metric: { [key: string]: SprintMetrics }) {
    const sprintId = Object.keys(metric)[0]
    const items = this.sprintMetric && this.sprintMetric[sprintId]?.items || 0
    const updatedMetric = Object.assign(this.sprintMetric ? this.sprintMetric : {}, metric)

    if (!this.sprintMetricUpdateItemsFlag) {
      updatedMetric[sprintId].items = items
    }

    this.sprintMetric = updatedMetric
  }

  @Mutation
  setsprintMetricUpdateItemsFlag(newVal) {
    this.sprintMetricUpdateItemsFlag = newVal
  }

  @Mutation
  setCurrentSprints(notCompletedSprints: SprintInfo[]) {
    this.notCompletedSprints = notCompletedSprints
  }

  @Mutation
  setSprintsWithStats(sprintsWithStats: SprintWithStats[]) {
    this.sprintsWithStats = sprintsWithStats
  }

  @Mutation
  addSprintsWithStats(sprintsWithStats: SprintWithStats[]) {
    this.sprintsWithStats = [...this.sprintsWithStats, ...sprintsWithStats]
  }

  @Mutation
  setSprintWithStats(sprintWithStats: SprintWithStats) {
    this.sprintWithStats = sprintWithStats
  }

  @Mutation
  setSprintValue(payload: UpdateObjectValue<Sprint>) {
    Object.assign(this.sprint, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setCurrentSprintId(sprintId: string) {
    this.currentSprintId = sprintId
  }

  @Mutation
  setSprintReview(payload: SprintReview) {
    this.sprintReview = payload
  }

  @Mutation
  setSprintDuration({ startDate, endDate }:{ startDate: Date, endDate: Date }) {
    this.sprint.startDate = startDate.toISOString()
    this.sprint.endDate = endDate.toISOString()
  }

  @Mutation
  setNewSprintNumber(sequenceNumber: number) {
    this.newSprintNumber = sequenceNumber
  }

  @Mutation
  setCanCreateSprint(payload: boolean) {
    this.canStartSprint = payload
  }

  @Mutation
  updateItemInSprintReview({ itemId, fieldId, value }: {itemId: string, fieldId: string, value: any}) {
    this.sprintReview.review.items = this.sprintReview.review.items.map((item) => {
      if (item.id === itemId) {
        item[fieldId] = value
      }
      return item
    })
  }

  @Mutation
  updateSprintWithStatus({ sprintId, status }:{sprintId: string, status: SprintStatus}) {
    if (this.sprint?.id === sprintId) {
      this.sprint.status = status
    }
    if (this.notCompletedSprints?.length) {
      this.notCompletedSprints = this.notCompletedSprints.map((el) => {
        if (el.id === sprintId) el.status = status
        return el
      })
    }
  }

  @Mutation
  setSprintQuote(sprintQuote: Quote) {
    this.sprintQuote = sprintQuote
  }

  @Mutation
  setExistsContinuousSprint(existsContinuousSprint: boolean) {
    this.existsContinuousSprint = existsContinuousSprint
  }

  @Mutation
  setPreviousSprint(sprint: Sprint) {
    this.previousSprint = sprint
  }

  @Mutation
  setActiveSprints(activeSprints: SprintWithStats[]) {
    this.activeSprints = activeSprints
  }

  @Mutation
  setSprintReviewSummary(summary: string) {
    this.sprintReview.sprint.summary = summary
  }

  @Mutation
  setSprintCapacity(sprintCapacity: SprintCapacity) {
    this.sprintCapacity = sprintCapacity
  }

  @Mutation
  updateSprintMetricsByFilter({ sprintId, metric }: { metric: BacklogMetrics, sprintId: string }) {
    this.sprintMetric?.[sprintId] ? Object.assign(this.sprintMetric[sprintId], {
      items: metric.totalItemCount,
    }) : false
    this.sprintMetricUpdateItemsFlag = false
  }

  @Mutation
  setCommentsInItems(comments: Comment[]) {
    this.sprintReview.review.items = this.sprintReview.review.items.map((item) => {
      const filteredComments: Comment[] = comments.filter(comment => comment.targetResourceId?.id === item.originalId)
      const comment = filteredComments.at(-1)
      if (comment) {
        item.sprintReviewComment = comment
      }
      return item
    })
  }

  @Action
  async [SprintActions.GET_SPRINT_CAPACITY]({ params, onlyGet } : { params: SprintCapacityFilter, onlyGet?: boolean }) {
    const data = await apiService.post<SprintCapacity>(Endpoint.SPRINT_CAPACITY(this.projectId), params)
    return this.handleResponse<SprintCapacity>(data, (data) => {
      const capacity = new SprintCapacity(data)
      if (!onlyGet) {
        this.setSprintCapacity(capacity)
      }
      return capacity
    })
  }

  @Action
  async [SprintActions.GET_SPRINTS](params) {
    const data = await apiService.get<ServerResponse<Sprint>>(Endpoint.SPRINTS(this.projectId), params.getJsonObj())
    return this.handleResponse<ServerResponse<Sprint>>(data, (data) => {
      const content: Sprint[] = data.content.map(s => Sprint.create(s))
      this.setSprints(content)
      return content
    })
  }

  @Action
  async [SprintActions.GET_SPRINT_REVIEW](sprintId: string) {
    const data = await apiService.get<SprintReview>(Endpoint.SPRINT_REVIEW(this.projectId, sprintId))
    return this.handleResponse<SprintReview>(data, (data) => {
      this.setSprintReview(SprintReview.create(data))
      return SprintReview.create(data)
    }, true)
  }

  @Action
  async [SprintActions.GET_SPRINT_NUMBER](teamId: string) {
    const data = await apiService.get<number>(Endpoint.GET_SPRINT_NUMBER(this.projectId, teamId))
    return this.handleResponse<number>(data, (data) => {
      this.setNewSprintNumber(data)
    }, true)
  }

  @Action
  async [SprintActions.RESET_SPRINT_NUMBER]() {
    this.setNewSprintNumber(0)
  }

  @Action
  async [SprintActions.CREATE_SPRINT](sprint: Sprint) {
    const data = await apiService.post<Sprint>(Endpoint.SPRINTS(this.projectId), sprint.getJsonObj())
    return this.handleResponse<Sprint>(data, (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Sprint ${$t("alert.created")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setSprint(Sprint.create(data))
    })
  }

  @Action
  async [SprintActions.GET_SPRINT]({ sprintId, onlyGet } : {sprintId: string, onlyGet?: boolean}) {
    const data = await apiService.get<Sprint>(Endpoint.SPRINT(this.projectId, sprintId))
    return this.handleResponse<Sprint>(data, (data) => {
      const sprint = Sprint.create(data)
      if (!onlyGet) this.setSprint(sprint)
      return sprint
    })
  }

  @Action
  async [SprintActions.CAN_START_SPRINT](sprintId: string) {
    const data = await apiService.get<boolean>(Endpoint.CAN_START_SPRINT(this.projectId, sprintId))
    return this.handleResponse<boolean>(data, (data) => {
      this.setCanCreateSprint(data)
    })
  }

  @Action
  async [SprintActions.UPDATE_SPRINT](sprint: Sprint) {
    const data = await apiService.put<Sprint>(Endpoint.SPRINT(this.projectId, sprint.id), sprint.getJsonObj())
    return this.handleResponse<Sprint>(data, (data) => {
      const updatedSprint = Sprint.create(data)
      let alertContent: AlertContentItem[] = [{
        text: "Sprint updated",
        type: "regular",
      }]
      if (updatedSprint.nextSprintUpdated) {
        alertContent = [{
          text: "Sprint updated. Future Sprint start and end dates automatically adjusted.",
          type: "regular",
        }]
      }
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setSprint(updatedSprint)
    })
  }

  @Action
  async [SprintActions.NOT_COMPLETED_SPRINTS]({ teamId, setMetrics } : { teamId: string, setMetrics?: boolean } = {
    setMetrics: true,
    teamId: "",
  }) {
    const data = await apiService.get<Sprint[]>(Endpoint.SPRINT_NOT_COMPLETED(this.projectId, teamId))
    return this.handleResponse<Sprint[]>(data, (data) => {
      const sprints = data.map(e => Sprint.create(e))
      this.setCurrentSprints(sprints)
      if (setMetrics) {
        sprints.forEach(item => this.setSprintMetric({
          [item.id]: defaultSprintMetric,
        }))
      }
      return sprints
    })
  }

  @Action
  async [SprintActions.ADD_SPRINT_ITEM](payload: ItemQueryPriority) {
    const sprintId = payload.sprintId ?? ""
    const data = await apiService.post<void>(Endpoint.SPRINT_ITEMS(this.projectId, sprintId), null, {
      params: {
        itemId: payload.itemId,
      },
    })

    return this.handleResponse<void>(data, undefined, true, (data) => {
      const { response, message } = data

      if (response.status === 400 && message.includes("Can not change completed sprint items list")) {
        const alertContent: AlertContentItem[] = [{
          text: $t("validationSprintMessage.closed"),
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.ERROR,
          theme: "toast",
        })
      } else {
        const alertContent: AlertContentItem[] = [
          { text: `${$t("alert.error", [message])}`, type: "regular" },
        ]
        alertModule[AlertActions.SHOW_ERROR_ALERT]({
          contentTitle: alertContent,
          theme: "global",
        })
      }

      return false
    })
  }

  @Action
  async [SprintActions.REMOVE_SPRINT_ITEM](payload: ItemQueryPriority) {
    const { itemId, sprintId } = payload

    if (sprintId) {
      const data = await apiService.delete<void>(Endpoint.SPRINT_ITEMS(this.projectId, sprintId), null, {
        itemId,
      })
      return this.handleResponse<void>(data)
    }
  }

  @Action
  async [SprintActions.SPRINT_METRICS](sprintId: string) {
    const data = await apiService.get<SprintMetrics>(Endpoint.SPRINT_METRICS(this.projectId, sprintId))
    return this.handleResponse<SprintMetrics>(data, (data) => {
      this.setSprintMetric({ [sprintId]: data })
      return data
    })
  }

  @Action
  async [SprintActions.UPDATE_SPRINT_METRICS_BY_FILTER]({ params, sprintId } : { params: FilterBacklogMetrics, sprintId: string }) {
    const data = await apiService.get<BacklogMetrics>(Endpoint.ITEMS_METRICS(this.projectId), params.getJsonObj())
    return this.handleResponse<BacklogMetrics>(data, (data) => {
      this.updateSprintMetricsByFilter({ metric: data, sprintId })
    })
  }

  @Action
  async [SprintActions.UPDATE_SPRINT_METRIC_UPDATE_FLAG](newVal: boolean) {
    return this.setsprintMetricUpdateItemsFlag(newVal)
  }

  @Action
  async [SprintActions.ACTIVE_SPRINTS]({ filterParams, onlyGet } : { filterParams: FilterSprint, onlyGet?: boolean }) {
    const filter = FilterSprint.create(filterParams)
    filter.includedStatuses = ["STARTED"]
    filter.addSort("sequenceNumber", true)
    filter.allSprintsPage = true
    const content = await this[SprintActions.SPRINTS_WITH_STATS](filter)
    if (!onlyGet) {
      this.setActiveSprints(content)
    }
    return content
  }

  @Action
  async [SprintActions.SPRINTS_WITH_STATS](params: FilterSprint) {
    const filter = FilterSprint.create(params)
    const projectId = filter.projectId ?? this.projectId
    const data = await apiService.get<ServerResponse<SprintWithStats>>(Endpoint.SPRINTS_WITH_STATS(projectId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<SprintWithStats>>(data, (data) => {
      const content: SprintWithStats[] = data.content.map(el => ({
        sprint: Sprint.create(el.sprint),
        stats: el.stats,
      }))
      if (params.allSprintsPage) {
        return content
      }
      this.setSprintsResponse(data)
      if (!filter || params?.page === 1) {
        this.setSprintsWithStats(content)
      } else {
        this.addSprintsWithStats(content)
      }
      return { ...data, content }
    })
  }

  @Action
  async [SprintActions.SPRINT_WITH_STATS](sprintId: string) {
    const data = await apiService.get<SprintWithStats>(Endpoint.SPRINT_WITH_STATS(this.projectId, sprintId))
    return this.handleResponse<SprintWithStats>(data, (data) => {
      this.setSprintWithStats(Object.assign(data, {
        sprint: Sprint.create(data.sprint),
      }))
      this.setSprint(Sprint.create(data.sprint))
      this.setSprintMetric({ [sprintId]: data.stats })
      return data
    })
  }

  @Action
  async [SprintActions.SPRINT_ANALYTICS](sprintId: string) {
    const data = await apiService.get<SprintAnalytics>(Endpoint.SPRINT_ANALYTICS(this.projectId, sprintId))
    return this.handleResponse<SprintAnalytics>(data, (data) => {
      const sprintAnalytics = new SprintAnalytics(data)
      this.setSprintAnalytics(sprintAnalytics)
      return sprintAnalytics
    })
  }

  @Action
  async [SprintActions.SPRINT_ANALYTICS_TEAM](sprintId: string) {
    const data = await apiService.get<SprintAnalyticsTeam>(Endpoint.SPRINT_ANALYTICS_TEAM(this.projectId, sprintId))
    return this.handleResponse<SprintAnalyticsTeam>(data, (data) => {
      const sprintAnalyticsTeam = new SprintAnalyticsTeam(data)
      this.setSprintAnalyticsTeam(sprintAnalyticsTeam)
      return sprintAnalyticsTeam
    })
  }

  @Action
  async [SprintActions.SPRINT_ANALYTICS_QUALITY](sprintId: string) {
    const data = await apiService.get<SprintAnalyticsQuality>(Endpoint.SPRINT_ANALYTICS_QUALITY(this.projectId, sprintId))
    return this.handleResponse<SprintAnalyticsQuality>(data, (data) => {
      const setSprintAnalytics = new SprintAnalyticsQuality(data)
      this.setSprintAnalyticsQuality(setSprintAnalytics)
      return setSprintAnalytics
    })
  }

  @Action
  async [SprintActions.SPRINT_ANALYTICS_PERFORMANCE](sprintId: string) {
    const data = await apiService.get<SprintAnalyticsPerformance>(Endpoint.SPRINT_ANALYTICS_PERFORMANCE(this.projectId, sprintId))
    return this.handleResponse<SprintAnalyticsPerformance>(data, (data) => {
      const sprintAnalyticsPerformance = new SprintAnalyticsPerformance(data)
      this.setSprintAnalyticsPerformance(sprintAnalyticsPerformance)
      return sprintAnalyticsPerformance
    })
  }

  @Action
  async [SprintActions.RESOLVE_AND_SET_SPRINT_ID](payload: { sequenceNumber: number, teamId: string, onlyGet?: boolean }) {
    const data = await apiService.get<string>(Endpoint.RESOLVE_SPRINT_ID(this.projectId, payload.teamId, payload.sequenceNumber))
    return this.handleResponse<string>(
      data,
      (data) => {
        if (!payload.onlyGet) this.setCurrentSprintId(data)
        return data
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      false, () => {}, true)
  }

  @Action
  async [SprintActions.START_SPRINT](sprintId: string) {
    const data = await apiService.post<void>(Endpoint.SPRINT_START(this.projectId, sprintId))
    return this.handleResponse<void>(data, () => {
      this.updateSprintWithStatus({ sprintId, status: "STARTED" })
      const alertContent: AlertContentItem[] = [{
        text: `Sprint ${$t("alert.started")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [SprintActions.COMPLETE_SPRINT]({ sprintId, sequenceNumber, globalAlert } : { sprintId: string, sequenceNumber: number, globalAlert: boolean } = {
    sprintId: "",
    sequenceNumber: 0,
    globalAlert: false,
  }) {
    const data = await apiService.post<void>(Endpoint.SPRINT_COMPLETE(this.projectId, sprintId))
    return this.handleResponse<void>(data, () => {
      this.updateSprintWithStatus({ sprintId, status: "COMPLETED" })
      if (globalAlert) {
        const alertContentTitle: AlertContentItem[] = [{
          text: `Sprint #${sequenceNumber} was completed`,
          type: "bold",
        }]
        const alertContent: AlertContentItem[] = [{
          text: `All uncompleted Sprint Backlog items have been moved to Sprint #${sequenceNumber + 1}`,
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          contentTitle: alertContentTitle,
          type: AlertType.SUCCESS,
          theme: "global",
        })
      } else {
        const alertContent: AlertContentItem[] = [{
          text: `Sprint ${$t("alert.completed")}`,
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }
    })
  }

  @Action
  async [SprintActions.SPRINT_START_REVIEW](sprint: Sprint) {
    const data = await apiService.post<Sprint>(Endpoint.SPRINT_START_REVIEW(this.projectId, sprint.id), sprint.getJsonObj())
    return this.handleResponse<Sprint>(data, (data) => {
      const sprint = Sprint.create(data)
      this.setSprint(sprint)
      this.setSprintInSprintReview(sprint)
    })
  }

  @Action
  async [SprintActions.SPRINT_COMPLETE_REVIEW]({ sprintId, summary }) {
    const data = await apiService.post<Sprint>(Endpoint.SPRINT_COMPLETE_REVIEW(this.projectId, sprintId), summary, {
      headers: {
        "Content-Type": "application/text",
      },
    })
    return this.handleResponse<Sprint>(data, (data) => {
      this.setSprint(Sprint.create(data))
      const alertContent: AlertContentItem[] = [{
        text: `Review ${$t("alert.completed")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [SprintActions.HALT_SPRINT]({ sprintId, haltReason }) {
    const data = await apiService.post<void>(Endpoint.SPRINT_HALT(this.projectId, sprintId), {
      haltReason,
    })
    return this.handleResponse<void>(data, () => {
      if (this.sprint) {
        this.sprint.status = "HALTED"
      }
      const alertContent: AlertContentItem[] = [{
        text: `Sprint ${$t("alert.halted")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [SprintActions.SPRINT_TIME_OFFS]({ sprintId, timeOffs } : { sprintId: string, timeOffs: TimeOff[] }) {
    const data = await apiService.put<TimeOff[]>(Endpoint.SPRINT_TIME_OFFS(this.projectId, sprintId), {
      timeOffs,
    })
    return this.handleResponse<TimeOff[]>(data, () => {
      this.setSprintValue({
        id: "timeOffs",
        value: data,
      } as any)
      const alertContent: AlertContentItem[] = [{
        text: `Time Off ${$t("alert.saved")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      return data
    })
  }


  @Action
  async [SprintActions.SPRINT_QUOTE]() {
    const data = await apiService.get<Quote>(Endpoint.SPRINT_QUOTE(this.projectId))
    return this.handleResponse<Quote>(data, (data) => {
      this.setSprintQuote(data)
    })
  }

  @Action
  async [SprintActions.SPRINT_EXISTS_CONTINUOUS](teamId: string) {
    const data = await apiService.get<boolean>(Endpoint.SPRINT_EXISTS_CONTINUOUS(this.projectId, teamId))
    return this.handleResponse<boolean>(data, (data) => {
      this.setExistsContinuousSprint(data)
    })
  }

  @Action
  async [SprintActions.EDIT_SUMMARY]({ sprintId, summary } : {sprintId: string, summary: string}) {
    const data = await apiService.patch<Sprint>(Endpoint.EDIT_SUMMARY(this.projectId, sprintId), {
      summary,
    })
    return this.handleResponse<Sprint>(data, (data) => {
      const sprint = Sprint.create(data)
      this.setSprint(sprint)
      this.setSprintInSprintReview(sprint)
      const alertContent: AlertContentItem[] = [
        {
          text: "Team Feedback",
          type: "bold",
        },
        {
          text: " saved",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [SprintActions.GET_SPRINT_BY_SEQUENCE_NUMBER]({ sequenceNumber, teamId } : { sequenceNumber: number, teamId: string }) {
    const payload = {
      sequenceNumber,
      teamId,
      onlyGet: true,
    }
    const previousSprintId = await this[SprintActions.RESOLVE_AND_SET_SPRINT_ID](payload)
    const previousSprint = Sprint.create(await this[SprintActions.GET_SPRINT]({
      sprintId: previousSprintId,
      onlyGet: true,
    }))
    this.setPreviousSprint(previousSprint)
    return previousSprint
  }

  @Action
  [SprintActions.SET_DEFAULT_SPRINT_METRICS]() {
    this.sprints.forEach(sprint => this.setSprintMetric({
      [sprint.id]: defaultSprintMetric,
    }))
  }

  @Action
  [SprintActions.UPDATE_SPRINT_VALUE](payload: UpdateObjectValue<Sprint>) {
    this.setSprintValue(payload)
  }

  @Action
  [SprintActions.RESET_NOT_COMPLETED_SPRINTS]() {
    this.setCurrentSprints([])
  }

  @Action
  [SprintActions.RESET_SPRINT]() {
    this.setSprint(new Sprint())
    this[SprintActions.RESET_SPRINT_NUMBER]()
  }

  @Action
  [SprintActions.SET_SPRINT](sprint: Sprint) {
    this.setSprint(sprint)
  }

  @Action
  [SprintActions.SET_CURRENT_SPRINT_ID](id: string) {
    this.setCurrentSprintId(id)
  }

  @Action
  [SprintActions.UPDATE_ITEM_IN_SPRINT_REVIEW]({ itemId, fieldId, value }: {itemId: string, fieldId: string, value: any}) {
    this.updateItemInSprintReview({ itemId, fieldId, value })
  }

  @Action
  [SprintActions.UPDATE_SPRINT_DURATION]({ startDate, endDate }:{startDate: Date, endDate: Date}) {
    this.setSprintDuration({ startDate, endDate })
  }

  @Action
  [SprintActions.SET_ITEM_COMMENTS_IN_SPRINT_REVIEW](comments: Comment[]) {
    this.setCommentsInItems(comments)
  }

  @Action
  [SprintActions.UPDATE_SPRINT_REVIEW_SUMMARY](summary: string) {
    this.setSprintReviewSummary(summary)
  }
}

export const sprintModule = new SprintModule({ store, name: Modules.SPRINT })
