<template>
  <div class="progress-bar-multi-color">
    <DropdownTooltip
      theme="info-tooltip"
      :triggers="tooltipTriggers"
      :distance="tooltipDistance"
      placement="top"
      popperClass="progress-bar-multi-color-popper"
      class="progress-bar-multi-color-tooltip"
    >
      <template #tooltip-label>
        <div class="progress-bar-multi-color__body">
          <span
            class="progress-bar-multi-color__line progress-bar-multi-color__first"
            :style="{ width: getLineWidth('firstLineCount') + '%'}"
          />
          <span
            class="progress-bar-multi-color__line progress-bar-multi-color__second"
            :style="{ width: getLineWidth('secondLineCount') + 2 + '%'}"
          />
        </div>
      </template>

      <template #tooltip-content>
        <slot name="tooltip-content" />
      </template>
    </DropdownTooltip>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
export interface MultiColorConfig {
  firstLineCount: number
  secondLineCount: number
  total: number
}

const components = {
  DropdownTooltip,
}

@Options({
  name: "ProgressBarMultiColor",
  components,
})
export default class ProgressBar extends Vue {
  @Prop(Object) readonly options: MultiColorConfig
  @Prop({ default: [] }) readonly tooltipTriggers: string[]
  @Prop({ default: 0 }) readonly tooltipDistance: number

  getLineWidth(line: string) {
    if (!line || !this.options[line] || !this.options.total) return 0

    const calculateValue = (+this.options[line] * 100) / +this.options.total

    return calculateValue >= 100 ? 100 : calculateValue
  }
}
</script>
