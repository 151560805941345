<template>
  <div class="alert">
    <div class="alert__content">
      <div
        class="alert__card"
        :class="[
          `alert__card-${alert.type.toLowerCase()}`,
          alert.timeout === 0 && `alert__card--hidden`,
          alert.timeout === -1 && `alert__card--hidden alert__message--removed`
        ]"
      >
        <div class="alert__card__header">
          <div class="alert__title">
            <icon
              v-if="alert.type === 'SUCCESS'"
              data="@icon/check.svg"
              :fill="false"
              width="16px"
              height="16px"
              color="#4CAB26"
            />
            <icon
              v-if="alert.type === 'ERROR'"
              data="@icon/attention.svg"
              :fill="true"
              width="16px"
              height="16px"
              color="#D32F2F"
            />
            <icon
              v-if="alert.type === 'WARNING'"
              data="@icon/warningIcon.svg"
              :fill="true"
              width="16px"
              height="16px"
              color="#C6A700"
            />
            <AlertText
              v-if="alert.contentTitle?.length"
              :content="alert.contentTitle"
            />
          </div>
          <button
            class="alert__close"
            @click="$emit('closeAlert')"
          >
            <icon
              data="@icon/close.svg"
              :fill="false"
              width="1em"
              height="1em"
              color="#000000"
            />
          </button>
        </div>
        <div class="alert__card__content">
          <AlertText
            v-if="alert.content?.length"
            :content="alert.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

import { Alert } from "@/models"

import AlertText from "./AlertText.vue"

const components = {
  AlertText
}

@Options({
  name: "GLobalAlert",
  components
})
export default class extends Vue {
  @Prop() alert: Alert
}
</script>
