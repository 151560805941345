<template>
  <ReferenceComponentParentList
    :searchList="list"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    @onClick="onItemSelected"
  >
    <template #header>
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
      >
        Backlog
      </BaseSubHeading>
    </template>

    <template #item="{ option }">
      <div :class="['reference-item', `is-${option.itemTypeParsed?.id.toLowerCase()}`]">
        <div class="reference-item__header">
          <BaseSubHeading
            weight="500"
            size="medium"
            color="monochrome-06"
          >
            #{{ option.sequenceNumber }}
          </BaseSubHeading>

          <span :class="['reference-item__status', `is_${option.statusParsed?.id.toLowerCase()}`]">{{ option.statusParsed?.label }}</span>
        </div>
        <BaseCaption
          size="large"
          color="monochrome-05"
          className="reference-item__body"
        >
          {{ option.title }}
        </BaseCaption>
      </div>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import { SearchItemResult } from "@/models"
import { Getter, Action } from "s-vuex-class"
import { Getters, Actions } from "@/store"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"

const components = {
  ReferenceComponentParentList,
}

@Options({
  name: "ItemList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.SEARCH_RESOURCE) declare result: SearchItemResult[]

  @Action(Actions.SEARCH_ITEM) findItems: (params) => Promise<void>


  componentType = ReferenceType.ITEM
  waitTitle = "reference.search.item"
  filter: {
    sequenceNumber: string,
    projectIds: string[]
  } = {
      sequenceNumber: "",
      projectIds: [],
    }

  get list() {
    return this.result?.length > 0 ? this.result : []
  }

  async created() {
    this.filter.projectIds = [this.projectId]
    this.searchAction = this.findItems
  }

  queryUpdated(q) {
    this.filter.sequenceNumber = q ?? ""
    this.search()
  }

  onItemSelected(payload) {
    this.selectItem({
      item: payload,
      payload: {},
    })
  }
}
</script>
