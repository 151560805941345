import { BaseModule } from "@/models/BaseModule"
import { LogError } from "@/models/Error/LogError"
import { LogMetric } from "@/models/LogMetric"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { Action, Module } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"

export enum LogActions {
  LOG_ERROR = "LOG_ERROR",
  LOG_PAGE_LOAD = "LOG_PAGE_LOAD",
}

@Module
class LogModule extends BaseModule {
  @Action
  async [LogActions.LOG_PAGE_LOAD](logMetric: LogMetric) {
    const data = await apiService.post<void>(Endpoint.LOG_METRIC_PAGELOAD(), logMetric.getJsonObj())
    return this.handleResponse<void>(data, undefined, true)
  }

  @Action
  async [LogActions.LOG_ERROR](logError: LogError) {
    const data = await apiService.post<void>(Endpoint.LOG_ERROR(), logError.getJsonObj())
    return this.handleResponse<void>(data, undefined, true)
  }
}

export const logModule = new LogModule({ store, name: Modules.LOG })
