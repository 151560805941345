import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/backlog-icon.svg'


const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "notification__item-content__title" }
const _hoisted_3 = { class: "task-number" }
const _hoisted_4 = { class: "task-name" }
const _hoisted_5 = { class: "task-type" }
const _hoisted_6 = { class: "task-estimation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["label-pic", _ctx.picColor])
      }, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px"
        })
      ], 2)
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400",
        className: "notification__active-sprint-add-item notification__item-content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "link is-medium-500",
            target: "_blank",
            href: _ctx.notification.itemUrl
          }, _toDisplayString(_ctx.notification.itemSequenceNumber), 9, _hoisted_1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.notification.getItemValue('title')), 1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notification.tasks, (task, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "notification__item-content-task-line"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(task.order), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.notification.taskNames[index]), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getTaskTypeLabel(task.type)), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(task.estimatedHours) + " hr", 1)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["notification"]))
}