<template>
  <ReferenceComponentParentList
    :searchList="content"
    :componentType="componentType"
    :class="[{'reference-list_sm': !showTips}]"
  >
    <template
      v-if="showTips"
      #header
    >
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
        className="reference-list__header"
      >
        Easy to use Hashtag conventions:
      </BaseSubHeading>
    </template>

    <template
      v-if="showTips"
      #item="{ option }"
    >
      <div class="reference-list__separate">
        <BaseSubHeading
          weight="400"
          size="medium"
          color="monochrome-07"
          className="reference-list__separate-left"
        >
          {{ $t(`reference.${option}.key`) }}
        </BaseSubHeading>
        <BaseSubHeading
          weight="400"
          size="medium"
          color="monochrome-05"
          className="reference-list__separate-right"
        >
          {{ $t(`reference.${option}.desc`) }}
        </BaseSubHeading>
      </div>
    </template>

    <template #footer>
      <span v-if="showTips">&nbsp;</span>
      <button
        class="reference-list__link"
        @click.prevent="onToggleTips(!showTips)"
      >
        {{ tipButtonText }} Tips

        <icon
          v-if="!showTips"
          class="reference-list__icon"
          data="@icon/info.svg"
          :fill="false"
          color="#7D92A7"
        />
      </button>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import { UserSettings } from "@/models/User"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"

const components = {
  ReferenceComponentParentList,
}

@Options({
  name: "TipList",
  components,
})
export default class extends Vue {
  @Getter(Getters.GET_USER_CURRENT_PROFILE_SETTINGS) readonly profileSettings: UserSettings

  @Action(Actions.UPDATE_PROFILE_SETTINGS) updateProfileSettings: ({ settings, immediate } : { settings: UserSettings, immediate?: boolean }) => void

  componentType = ReferenceType.TIP

  get content() {
    return [
      "item",
      "task",
      "sprint",
      "epic",
      "project",
      "milestone",
      "team",
      "file",
    ]
  }

  get tipButtonText() {
    return this.showTips ? "Hide" : "Show"
  }

  get showTips() {
    return this.profileSettings.showTips ?? true
  }

  set showTips(val) {
    this.updateProfileSettings({
      settings: {
        showTips: val,
      },
      immediate: true,
    })
  }

  onToggleTips(value: boolean) {
    this.showTips = value
  }
}
</script>

