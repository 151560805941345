import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "custom-modal__body" }
const _hoisted_2 = { class: "custom-modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, null, {
    default: _withCtx(({ meta, validate }) => [
      _createVNode(_component_Modal, {
        ref: "modal",
        name: _ctx.id,
        classes: _ctx.modalClasses,
        width: _ctx.width,
        height: _ctx.height,
        reset: true,
        scrollable: _ctx.scrollable,
        clickToClose: _ctx.clickToClose,
        onOpened: _ctx.onOpened,
        onBeforeOpen: _ctx.onBeforeOpen,
        onClosed: _ctx.onClosed,
        onBeforeClose: _ctx.onBeforeClose
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["custom-modal__header", [_ctx.headerClass]])
          }, [
            _renderSlot(_ctx.$slots, "header")
          ], 2),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "body", { validators: _ctx.validators })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer", {
              hide: _ctx.hide,
              actionDisable: !meta.valid,
              validate: validate
            })
          ])
        ]),
        _: 2
      }, 1032, ["name", "classes", "width", "height", "scrollable", "clickToClose", "onOpened", "onBeforeOpen", "onClosed", "onBeforeClose"])
    ]),
    _: 3
  }))
}