import { UniqActions } from "@/models/UniqActions"
import { getGettersMap } from "@/utils/helpers"
import { Modules } from "./modules"
import {
  AccountActions,
  AlertActions,
  PaymentActions,
  ProjectActions,
  UserActions,
  CommentActions,
  ItemActions,
  DefinitionOfDoneActions,
  ItemStatusActions,
  ItemTypeActions,
  TeamActions,
  InvitationActions,
  PictureActions,
  FileActions,
  TaskActions,
  BacklogActions,
  EpicActions,
  SprintActions,
  PokerActions,
  BacklogMetricsActions,
  ToDoActions,
  LoginActions,
  MilestoneActions,
  RetrospectiveActions,
  SubscriptionActions,
  PostMortemActions,
  EmailTemplateActions,
  UserManagementActions,
  SearchActions,
  LogActions,
  SessionActions,
  TabsActions,
  KeyboardNavigationActions,
  NotificationActions,
  GoalActions,
  FeatureActions,
  EmailVerificationActions,
  HealthCheckActions,
  JiraImportActions,
} from "./modules/"

export type TypeGetters = {
  [key in UniqActions]: string;
}

export const Getters = {
  ...getGettersMap(Modules.PROJECT, ProjectActions as TypeGetters),
  ...getGettersMap(Modules.USER, UserActions as TypeGetters),
  ...getGettersMap(Modules.USER_MANAGEMENT, UserManagementActions as TypeGetters),
  ...getGettersMap(Modules.ACCOUNT, AccountActions as TypeGetters),
  ...getGettersMap(Modules.PAYMENT, PaymentActions as TypeGetters),
  ...getGettersMap(Modules.ALERT, AlertActions as TypeGetters),
  ...getGettersMap(Modules.COMMENT, CommentActions as TypeGetters),
  ...getGettersMap(Modules.ITEM, ItemActions as TypeGetters),
  ...getGettersMap(Modules.MILESTONE, MilestoneActions as TypeGetters),
  ...getGettersMap(Modules.DEFINITION_OF_DONE, DefinitionOfDoneActions as TypeGetters),
  ...getGettersMap(Modules.ITEM_STATUS, ItemStatusActions as TypeGetters),
  ...getGettersMap(Modules.ITEM_TYPE, ItemTypeActions as TypeGetters),
  ...getGettersMap(Modules.FILE, FileActions as TypeGetters),
  ...getGettersMap(Modules.TASK, TaskActions as TypeGetters),
  ...getGettersMap(Modules.TEAM, TeamActions as TypeGetters),
  ...getGettersMap(Modules.INVITATION, InvitationActions as TypeGetters),
  ...getGettersMap(Modules.PICTURE, PictureActions as TypeGetters),
  ...getGettersMap(Modules.BACKLOG, BacklogActions as TypeGetters),
  ...getGettersMap(Modules.EPIC, EpicActions as TypeGetters),
  ...getGettersMap(Modules.SPRINT, SprintActions as TypeGetters),
  ...getGettersMap(Modules.POKER, PokerActions as TypeGetters),
  ...getGettersMap(Modules.TODO, ToDoActions as TypeGetters),
  ...getGettersMap(Modules.BACKLOG_METRICS, BacklogMetricsActions as TypeGetters),
  ...getGettersMap(Modules.LOGIN, LoginActions as TypeGetters),
  ...getGettersMap(Modules.RETROSPECTIVE, RetrospectiveActions as TypeGetters),
  ...getGettersMap(Modules.SUBSCRIPTION, SubscriptionActions as TypeGetters),
  ...getGettersMap(Modules.POSTMORTEM, PostMortemActions as TypeGetters),
  ...getGettersMap(Modules.EMAIL_TEMPLATE, EmailTemplateActions as TypeGetters),
  ...getGettersMap(Modules.SEARCH, SearchActions as TypeGetters),
  ...getGettersMap(Modules.LOG, LogActions as TypeGetters),
  ...getGettersMap(Modules.SESSION, SessionActions as TypeGetters),
  ...getGettersMap(Modules.TABS, TabsActions as TypeGetters),
  ...getGettersMap(Modules.KEYBOARD_NAVIGATION, KeyboardNavigationActions as TypeGetters),
  ...getGettersMap(Modules.NOTIFICATION, NotificationActions as TypeGetters),
  ...getGettersMap(Modules.GOAL, GoalActions as TypeGetters),
  ...getGettersMap(Modules.FEATURE, FeatureActions as TypeGetters),
  ...getGettersMap(Modules.EMAIL_VERIFICATION, EmailVerificationActions as TypeGetters),
  ...getGettersMap(Modules.HEALTH_CHECK, HealthCheckActions as TypeGetters),
  ...getGettersMap(Modules.JIRA_IMPORT, JiraImportActions as TypeGetters),
} as TypeGetters
