import {
  ReferenceConfig,
} from "@/plugins/extensions/Reference"
import UserList from "./User/UserList.vue"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"

export default {
  char: ReferenceConfig[ReferenceType.USER].char,
  items: () => ({
    list: [],
    type: ReferenceType.USER,
    component: UserList,
  }),
}
