import { Endpoint } from "@/services/endpoints"
import {
  SubscriptionActions,
  subscriptionModule,
} from "@/store/modules/subscription.module"
import { useAcl } from "vue-simple-acl"
import Handlers from "@/events/handlers/index"

export default class ServerSendEvent {
  url: string
  evtSource: EventSource
  reconnectFrequencySeconds: number

  constructor() {
    this.url = Endpoint.STREAM_SUBSCRIPTION().url

    this.evtSource
    this.reconnectFrequencySeconds = 1

    this.init()
  }

  init() {
    this.setupEventSource()
  }

  handleReconnect() {
    this.setupEventSource()

    this.reconnectFrequencySeconds *= 2

    if (this.reconnectFrequencySeconds >= 64) {
      this.reconnectFrequencySeconds = 64
    }
  }

  async setupEventSource() {
    if (useAcl().can.not("is-email-verified")) {
      this.onEvtSourceError()

      return false
    }

    const token = await this.getToken()
    if (!token) {
      return
    }
    this.evtSource = new EventSource(`${this.url}?slt=${token}`)

    this.evtSource.onopen = () => {
      this.onEvtSourceOpen()
    }

    this.evtSource.onerror = () => {
      this.onEvtSourceError()
    }

    for (const handler of Handlers) {
      this.evtSource.addEventListener(handler.type, event => handler.callback(event as MessageEvent))
    }
  }

  async getToken() {
    const token = await subscriptionModule[SubscriptionActions.GET_SUBSCRIPTION_TOKEN]()
    return token
  }

  closeEventSourse() {
    this.evtSource?.close()
  }

  onEvtSourceOpen() {
    this.reconnectFrequencySeconds = 1
  }

  onEvtSourceError() {
    this.closeEventSourse()

    this.reconnectFunc()
  }

  reconnectFunc() {
    setTimeout(this.handleReconnect.bind(this), this.reconnectFrequencySeconds * 1000)
  }
}
