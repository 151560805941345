<template>
  <div
    v-if="accountId && currentUser?.emailVerified"
    id="layout"
    class="layout"
  >
    <Header />
    <Alert />
    <div
      class="inner-wrapper"
    >
      <div
        id="mainContent"
        :key="forceUpdateLayout"
        class="inner"
      >
        <router-view />
      </div>
      <div id="toDoWidget" />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import Footer from "@/components/Footer/index.vue"
import Header from "@/components/Header/index.vue"
import Alert from "@/components/Alert/index.vue"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store/getters"
import { Watch } from "vue-property-decorator"
import { User } from "@/models"

@Options({
  components: {
    Header,
    Alert,
    Footer,
  },
  name: "DefaultLayout",
})
export default class App extends Vue {
  @Getter(Getters.GET_CURRENT_ACCOUNT_ID) accountId: string
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User

  forceUpdateLayout = 0

  get triggerUpdate() {
    return this.$route?.meta?.updateComponentByRoute ? decodeURI(this.$route.path).replace(/\s/g, "") : ""
  }

  @Watch("triggerUpdate")
  onTriggerUpdateChange() {
    if (this.$wait.is("disableUpdateComponentByRoute")) return
    this.forceUpdateLayout++
  }
}
</script>
