import { Module, Mutation, Action } from "vuex-class-modules"
import { store } from "../store"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { Modules } from "../modules"
import { User, UserSettingsPayload, UserSettings } from "@/models/User"
import { FilterUser } from "@/models/Filter/FilterUser"
import { ServerResponse } from "@/models/ServerResponse"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "./account.module"
import { $t } from "@/plugins/i18n"
import { AlertType, AlertContentItem } from "@/models/Alert"
import { AlertActions, alertModule } from "./alert.module"
import { parsingServerResponseContent } from "@/utils/helpers"

export enum UserManagementActions {
  GET_ALL_USERS = "GET_ALL_USERS",
  GET_TEAMS_USERS = "GET_TEAMS_USERS",
  USER_BY_ID = "USER_BY_ID",
  USER_ADD_PROJECT = "USER_ADD_PROJECT",
  USER_DELETE_PROJECT = "USER_DELETE_PROJECT",
  USER_DEACTIVATION = "USER_DEACTIVATION",
  USER_PERMISSIONS = "USER_PERMISSIONS",
  GET_USERS_RESPONSE = "GET_USERS_RESPONSE",
  CLEAR_TEAMS_USERS = "CLEAR_TEAMS_USERS",
  USER_POST_PICTURE = "USER_POST_PICTURE",
  USER_DELETE_PICTURE = "USER_DELETE_PICTURE",
  UPDATE_USER = "UPDATE_USER",
  USER_UPDATE_SETTINGS = "USER_UPDATE_SETTINGS",
  REMOVE_USER_FROM_MANAGEMENT_LIST = "REMOVE_USER_FROM_MANAGEMENT_LIST",
}

@Module
class UserManagementModule extends BaseModule {
  teamUsers: User[] = []
  users: User[] = []
  user: User
  serverResponse: ServerResponse<User>

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${UserManagementActions.GET_ALL_USERS}`]() {
    return this.users
  }

  get [`get/${UserManagementActions.GET_TEAMS_USERS}`]() {
    return this.teamUsers
  }

  get [`get/${UserManagementActions.USER_BY_ID}`]() {
    return this.user
  }

  get [`get/${UserManagementActions.GET_USERS_RESPONSE}`]() {
    return this.serverResponse
  }

  @Mutation
  setUsers(serverResponse: ServerResponse<User>) {
    this.users = parsingServerResponseContent({
      originalContent: this.users,
      serverResponseContent: serverResponse.content,
      numberOfPage: serverResponse.pageable.pageNumber,
    })
  }

  @Mutation
  setTeamUsers(serverResponse: ServerResponse<User>) {
    this.teamUsers = parsingServerResponseContent({
      originalContent: this.users,
      serverResponseContent: serverResponse.content,
      numberOfPage: serverResponse.pageable.pageNumber,
    })
  }

  @Mutation
  setUser(user: User) {
    this.user = user
  }

  @Mutation
  setUserSettings(payload: UserSettings) {
    if (!this.user) return
    this.user.settings = payload
  }

  @Mutation
  setUsersResponse(serverResponse: ServerResponse<User>) {
    this.serverResponse = serverResponse
  }

  @Mutation
  clearTeamUsers() {
    this.teamUsers = []
  }

  @Mutation
  removeUserFromList(userId: string) {
    this.users = this.users.filter(e => e.id !== userId)
  }

  @Mutation
  setUserPictureUrl({ userId, pictureUrl }: { userId: string, pictureUrl: string }) {
    if (this.user?.id === userId) {
      this.user.picture = this.user.picture || {}
      this.user.picture.pictureUrl = pictureUrl
      this.user.pictureUrl = pictureUrl
    }
    const user = this.users.find(t => t.id === userId)
    if (user) {
      user.picture = user.picture || {}
      user.picture.pictureUrl = pictureUrl
      user.pictureUrl = pictureUrl
    }
  }

  @Action
  async [UserManagementActions.GET_ALL_USERS](params: FilterUser) {
    const filter = FilterUser.create(params)
    const accountId = params.accountId ? params.accountId : this.accountId
    const endpoint = (params.isSuperadmin) ? Endpoint.GET_ALL_USERS_SUPERADMIN() : Endpoint.GET_ALL_USERS(accountId)

    const data = await apiService.get<ServerResponse<User>>(endpoint, filter.getJsonObj())
    return this.handleResponse<ServerResponse<User>>(data, (data) => {
      const content: User[] = data.content.map(c => User.create(c))
      data = { ...data, content }
      if (filter.forSearch) {
        return data
      }

      this.setUsersResponse(data)
      this.setUsers(data)
      return data
    })
  }

  @Action
  async [UserManagementActions.GET_TEAMS_USERS](params: FilterUser) {
    const filter = FilterUser.create(params).getJsonObj()

    const data = await apiService.get<ServerResponse<User>>(Endpoint.GET_ALL_USERS(this.accountId), filter)
    return this.handleResponse<ServerResponse<User>>(data, (data) => {
      const content: User[] = data.content.map(c => User.create(c))
      this.setUsersResponse(data)
      data = { ...data, content }
      this.setTeamUsers(data)
    })
  }

  @Action
  async [UserManagementActions.CLEAR_TEAMS_USERS]() {
    this.clearTeamUsers()
  }

  @Action
  async [UserManagementActions.USER_BY_ID]({ userId, onlyGet = false }: { userId: string, onlyGet?: boolean }) {
    const data = await apiService.get<User>(Endpoint.USER_BY_ID(this.accountId, userId))
    return this.handleResponse<User>(data, (data) => {
      const user = User.create(data)
      if (!onlyGet) {
        this.setUser(user)
      }
      return user
    })
  }

  @Action
  async [UserManagementActions.USER_ADD_PROJECT]({ userId, projectId }: { userId: string, projectId: string }) {
    const data = await apiService.post<User>(Endpoint.USER_ADD_PROJECT(this.accountId, userId),
      [projectId],
    )
    return this.handleResponse<User>(data, () => {
      alertModule[AlertActions.SHOW_ALERT]({
        content: [{
          text: $t("alert.updated", ["User"]),
          type: "regular",
        }],
        type: AlertType.SUCCESS,
      })
    })
  }

  @Action
  async [UserManagementActions.USER_DELETE_PROJECT]({ userId, projectId }: { userId: string, projectId: string }) {
    const data = await apiService.delete<void>(Endpoint.USER_ADD_PROJECT(this.accountId, userId),
      [projectId],
    )
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User",
          type: "bold",
        },
        {
          text: " removed from Project",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [UserManagementActions.USER_UPDATE_SETTINGS](payload: UserSettingsPayload) {
    const data = await apiService.put<UserSettings>(Endpoint.USER_SETTINGS(this.accountId, payload.userId),
      payload.settings,
    )
    return this.handleResponse<UserSettings>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User Settings ",
          type: "bold",
        },
        {
          text: "updated",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setUserSettings(data)
    })
  }

  @Action
  async [UserManagementActions.USER_POST_PICTURE](payload) {
    const file = new FormData()
    file.append("file", payload.file)

    const data = await apiService.post<string>(Endpoint.USER_PICTURE_UPDATE(this.accountId, payload.userId), file)
    return this.handleResponse<string>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User Picture",
          type: "bold",
        },
        {
          text: " updated",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setUserPictureUrl({
        userId: payload.userId,
        pictureUrl: data,
      })
    })
  }

  @Action
  async [UserManagementActions.USER_DELETE_PICTURE](userId: string) {
    const data = await apiService.delete<string>(Endpoint.USER_PICTURE_UPDATE(this.accountId, userId))
    return this.handleResponse<string>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User Picture",
          type: "bold",
        },
        {
          text: " deleted",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setUserPictureUrl({
        userId: userId,
        pictureUrl: "",
      })
    })
  }

  @Action
  async [UserManagementActions.USER_DEACTIVATION]({ userId, enabled }: { userId: string, enabled: boolean }) {
    const data = await apiService.patch<void>(Endpoint.USER_DEACTIVATION(this.accountId), {
      userId,
      enabled,
    })
    return this.handleResponse<void>(data, () => {
      alertModule[AlertActions.SHOW_ALERT]({
        content: [{
          text: $t("alert.deactivated", ["User"]),
          type: "regular",
        }],
        type: AlertType.SUCCESS,
      })
    })
  }

  @Action
  async [UserManagementActions.USER_PERMISSIONS]({ userId, role }: { userId: string, role: string }) {
    const data = await apiService.post<void>(Endpoint.USER_PERMISSIONS(this.accountId), {
      userId,
      role,
    })
    return this.handleResponse<void>(data, () => {
      alertModule[AlertActions.SHOW_ALERT]({
        content: [{
          text: $t("alert.changePermission", ["User"]),
          type: "regular",
        }],
        type: AlertType.SUCCESS,
      })
    })
  }

  @Action
  async [UserManagementActions.UPDATE_USER](user: User) {
    const data = await apiService.put<User>(Endpoint.ADMIN_USER_BY_ID(this.accountId, user.id), user.getUpdateJsonObj(this.accountId))
    return this.handleResponse<User>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User",
          type: "bold",
        },
        {
          text: " updated",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setUser(User.create(data))
    })
  }
  @Action
  [UserManagementActions.REMOVE_USER_FROM_MANAGEMENT_LIST](userId: string) {
    this.removeUserFromList(userId)
  }
}

export const userManagementModule = new UserManagementModule({
  store,
  name: Modules.USER_MANAGEMENT,
})
