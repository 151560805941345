import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bordered-team-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["bordered-team", {'is-full': _ctx.isFull}])
  }, [
    _createVNode(_component_Avatar, {
      src: _ctx.src,
      type: "teamsGroup",
      size: "sm",
      bordered: ""
    }, null, 8, ["src"]),
    (_ctx.isFull)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.teamName), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "after-name")
  ], 2))
}