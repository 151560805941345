import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/task-icon.svg'


const _hoisted_1 = { class: "label-pic is-purple" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "notification__item-content__title" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "notification__update-time-left__tasks__name" }
const _hoisted_6 = { class: "notification__update-time-left__tasks__type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notification?.items, (item, index) => {
    return (_openBlock(), _createBlock(_component_NotificationTemplate, {
      key: index,
      notification: _ctx.notification
    }, {
      pic: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_icon, {
            data: _imports_0,
            class: "label-pic__icon",
            fill: true,
            width: "24px",
            height: "24px",
            color: "#6A1B9A"
          })
        ])
      ]),
      title: _withCtx(() => [
        _createVNode(_component_BaseSubHeading, {
          size: "large",
          color: "monochrome-06",
          darkColor: "monochrome-03",
          weight: "500"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" The time on these tasks haven't been updated in quite some time ")
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createVNode(_component_BaseSubHeading, {
          size: "medium",
          color: "monochrome-06",
          darkColor: "monochrome-03",
          weight: "500",
          className: "notification__update-time-left__item notification__item-content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              class: "link is-medium-500",
              target: "_blank",
              href: item?.url ?? '#'
            }, " Story " + _toDisplayString(item?.sequenceNumber ?? _ctx.$t("none")), 9, _hoisted_2),
            _createElementVNode("span", _hoisted_3, _toDisplayString(item?.title), 1)
          ]),
          _: 2
        }, 1024),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item?.tasks, (task, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            className: "notification__update-time-left__tasks"
          }, [
            _createVNode(_component_BaseSubHeading, {
              size: "small",
              color: "monochrome-06",
              darkColor: "monochrome-03",
              weight: "500",
              className: "notification__update-time-left__tasks__left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  class: "link is-small-400",
                  target: "_blank",
                  href: item?.url ? item.url + `?task=${task.order}` : '#'
                }, _toDisplayString(task.order), 9, _hoisted_4),
                _createElementVNode("span", _hoisted_5, _toDisplayString(task.name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              color: "monochrome-05",
              darkColor: "monochrome-04",
              weight: "400",
              className: "notification__update-time-left__tasks__right"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(task.type), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["notification__update-time-left__tasks__estimation is-estimation", {'notification__update-time-left__tasks__estimation_alert': task.estimatedHours >= 24}])
                }, _toDisplayString(task.estimatedHours) + " hr ", 3)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _: 2
    }, 1032, ["notification"]))
  }), 128))
}