import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  const sprintId = store.getters[Getters.GET_SESSION_SPRINT_ID]
  const isSuccess = await store.dispatch(Actions.GET_SPRINT_REVIEW, sprintId)

  if (!isSuccess) {
    console.error(`Sprint review was not found: ${sprintId}`)
    return next({
      name: "Error",
      query: {
        code: 404,
      },
    })
  }

  next()
}
