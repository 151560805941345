<template>
  <div class="user">
    <Avatar
      :src="user?.pictureUrl"
      :gap="user?.getInitials()"
      type="avatarGroup"
      class="user__avatar"
      :class="{'avatar-select__gap': !user}"
    />
    <div class="user__content">
      <BaseSubHeading
        size="medium"
        :weight="userNameWeight"
        color="monochrome-06"
      >
        <slot
          name="username"
          :userName="user?.username ?? 'Unassigned'"
        >
          {{ user?.username ?? "Unassigned" }}
        </slot>
      </BaseSubHeading>
      <BaseSubHeading
        v-if="createDate"
        size="extra-small"
        weight="400"
        color="monochrome-05"
      >
        {{ createDate }}
      </BaseSubHeading>
      <div class="user__content_right">
        <slot name="content-right" />
      </div>
      <!-- <div>{{user.teamRolesString}}</div> -->
    </div>
  </div>
</template>

<script lang="ts">
import Avatar from "@/components/Avatar/index.vue"
import { Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { User } from "@/models"


@Options({
  name: "User",
  components: {
    Avatar,
  },
})
export default class extends Vue {
  @Prop({
    required: true,
    default: () => Object,
  }) readonly user: User
  @Prop({ default: true }) readonly showRole: boolean
  @Prop() createDate: string
  @Prop({ default: "500" }) userNameWeight: string

  get getUser() {
    return this.user
  }
}

</script>
