import {
  ReferenceConfig,
} from "@/plugins/extensions/Reference"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { getTypeByText } from "@/plugins/extensions/Reference"

import ItemList from "./Item/ItemList.vue"
import TipList from "./Tip/TipList.vue"
import TaskList from "./Task/TaskList.vue"
import ProjectList from "./Project/ProjectList.vue"
import EpicList from "./Epic/EpicList.vue"
import TeamList from "./Team/TeamList.vue"
import SprintList from "./Sprint/SprintList.vue"
import MilestoneList from "./Milestone/MilestoneList.vue"

export default {
  char: ReferenceConfig[ReferenceType.TIP].char,
  items: async ({ query }) => {
    const type = getTypeByText(`${ReferenceConfig[ReferenceType.TIP].char}${query}`)
    let component
    if (type === ReferenceType.ITEM) {
      component = ItemList
    } else if (type === ReferenceType.TIP) {
      component = TipList
    } else if (type === ReferenceType.TASK) {
      component = TaskList
    } else if (type === ReferenceType.PROJECT) {
      component = ProjectList
    } else if (type === ReferenceType.EPIC) {
      component = EpicList
    } else if (type === ReferenceType.TEAM) {
      component = TeamList
    } else if (type === ReferenceType.SPRINT) {
      component = SprintList
    } else if (type === ReferenceType.MILESTONE) {
      component = MilestoneList
    }

    return {
      query,
      component,
    }
  },
}
