import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["progress-line__bg", [_ctx.bgClass]])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["progress-line", [`is-${_ctx.color}`]]),
      style: _normalizeStyle({ width: _ctx.isMore ? '100%' : _ctx.lineWidth + '%'})
    }, null, 6)
  ], 2))
}