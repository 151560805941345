import { Notification } from "./Notification"
import { $t } from "@/plugins/i18n"
import { getInitials as getInitialsHelper } from "@/utils/helpers"

export class NotificationComment extends Notification {
  constructor(props) {
    super(props)
  }

  get initiator() {
    return this.content?.initiator
  }

  get initiatorAvatarSrc() {
    return this.initiator.picture?.pictureUrl
  }

  get initiatorFullName() {
    return this.initiator.firstName + " " + this.initiator.lastName
  }

  get itemSequenceNumber() {
    return `#${this.content?.itemSequenceNumber ?? $t("none")}`
  }

  get itemUrl() {
    return this.content?.itemUrl
  }

  get comment() {
    return this.content?.content
  }

  get text() {
    return this.content?.text
  }

  getInitials() {
    return getInitialsHelper(this.initiatorFullName)
  }
}

