import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attachment = _resolveComponent("Attachment")!
  const _component_NodeViewWrapper = _resolveComponent("NodeViewWrapper")!

  return (_openBlock(), _createBlock(_component_NodeViewWrapper, {
    as: "div",
    draggable: "false",
    onClick: _ctx.onSelectNode
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Attachment, {
        node: _ctx.node,
        editor: _ctx.editor,
        updateAttributes: _ctx.updateAttributes,
        getPos: _ctx.getPos
      }, null, 8, ["node", "editor", "updateAttributes", "getPos"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}