import { Options, Vue } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import {
  RetroStatus,
} from "@/models"

@Options({
  name: "RetrospectiveButton",
})
export class RetrospectiveButton extends Vue {
  @Getter(Getters.GET_CURRENT_SPRINT_ID) readonly currentSprintId: string
  @Getter(Getters.GET_RETROSPECTIVE_STATUS) retroStatus: RetroStatus
  @Action(Actions.GET_RETROSPECTIVE_STATUS) getRetroStatus: (sprintId: string) => void

  async mounted() {
    this.getRetroStatus(this.currentSprintId)
  }

  get retrospectiveButton() {
    return this.retroStatus === "NOT_STARTED" ? "Start Sprint Retrospective" : "View Sprint Retrospective"
  }

  onRetrospectiveVote(sequenceNumber: number) {
    const routerName = this.retroStatus === "COMPLETED" ? "retrospective" : "retrospective-vote"

    return {
      name: routerName,
      params: {
        sprintId: sequenceNumber,
      },
    }
  }
}
