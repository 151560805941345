<template>
  <BreadcrumbsProjectsBlock
    v-if="displayProjectsBlock && $route.params.projectId"
    @onTeamClick="onTeamClick"
  />
  <TeamSelectionDropdown
    v-if="displayTeamSelectionBlock || breadcrumbSelectedTeam"
    :selectedTeam="breadcrumbSelectedTeam ?? selectedTeam"
    optionLabelSize="md"
    className="team-selection"
    @onSelectTeam="onSelectDropdownTeam"
  >
    <template #singleLabel="{ option }">
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        className="header__breadcrumb"
      >
        /<span>{{ option.name }}</span>
      </BaseSubHeading>
    </template>
  </TeamSelectionDropdown>
  <BreadcrumbsTeamsBlock
    v-else-if="displayTeamNavigationBlock"
    @onMenuItemClick="onSprintSelected"
  />
  <BaseSubHeading
    v-else-if="$route.params.teamId && selectedTeam?.name && !$route?.meta?.hideTeamLabel"
    size="large"
    weight="400"
    color="monochrome-05"
    className="header__breadcrumb"
  >
    /<span>{{ selectedTeam.name }}</span>
  </BaseSubHeading>

  <button
    v-if="sprintLabel"
    type="button"
  >
    <BaseSubHeading
      size="large"
      weight="400"
      color="monochrome-05"
      className="header__breadcrumb"
    >
      /<span>{{ sprintLabel }}</span>
    </BaseSubHeading>
  </button>
  <template
    v-for="(breadcrumbConfig, index) in breadcrumbConfigList"
    :key="index"
  >
    <BaseSubHeading
      v-if="!isBreadcrumbTitleWaiting(breadcrumbConfig) && breadcrumbLink(breadcrumbConfig)"
      size="large"
      weight="400"
      color="monochrome-05"
      className="header__breadcrumb"
    >
      /
      <router-link :to="breadcrumbLink(breadcrumbConfig)?.route">
        {{ breadcrumbLink(breadcrumbConfig)?.title }}
      </router-link>
    </BaseSubHeading>
    <BaseSubHeading
      v-else-if="!isBreadcrumbTitleWaiting(breadcrumbConfig) && breadcrumbTitle(breadcrumbConfig)"
      size="large"
      weight="400"
      color="monochrome-05"
      className="header__breadcrumb"
    >
      /<span>{{ breadcrumbTitle(breadcrumbConfig) }}</span>
    </BaseSubHeading>
    <component :is="breadcrumbComponent(breadcrumbConfig)" />
  </template>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { useAcl } from "vue-simple-acl"
import { Action, Getter } from "s-vuex-class"

import BreadcrumbsProjectsBlock from "@/components/Header/BreadcrumbsProjectsBlock.vue"
import BreadcrumbsTeamsBlock from "@/components/Header/BreadcrumbsTeamsBlock.vue"
import TeamSelectionDropdown from "@/components/Team/TeamSelectionDropdown.vue"

import { Account, Project, Team, User } from "@/models"
import { BreadcrumbConfig, BreadcrumbLink, BreadcrumbSelectedTeam } from "@/models/MetaConfigs"
import { emitterEmit } from "@/plugins/mitt-emitter"
import { Actions, Getters, store } from "@/store"
// BreadcrumbConfig list components imports
import SupportCaseDropdown from "@/views/Create/CreateItem/components/SupportCaseDropdown.vue"

@Options({
  name: "Breadcrumbs",
  components: {
    BreadcrumbsProjectsBlock,
    BreadcrumbsTeamsBlock,
    TeamSelectionDropdown,
    SupportCaseDropdown
  }
})
export default class Breadcrumbs extends Vue {
  @Getter(Getters.GET_SESSION_PROJECT_ID) readonly projectId: string
  @Getter(Getters.GET_SESSION_TEAM) selectedTeam: Team
  @Getter(Getters.GET_PROJECT) selectedProject: Project
  @Getter(Getters.GET_TEAMS) teams: Team[]
  @Getter(Getters.GET_USER_CURRENT_PROFILE) user: User
  @Getter(Getters.GET_ACCOUNT) readonly account: Account

  @Action(Actions.GET_PROJECT) getProject: ({
    projectId,
    onlyGet
  }: {
    projectId: string
    onlyGet?: boolean
  }) => void
  @Action(Actions.SET_SESSION_TEAM) setSelectedTeam: (team: Team) => void
  @Action(Actions.RESET_SESSION_TEAM) resetTeam: () => void
  @Action(Actions.GET_TEAMS_IN_PROJECT) getTeamsInProject: (projectId: string) => Promise<Team[]>

  get displayProjectsBlock() {
    return !this.$route.meta?.hideProjectLabel ?? true
  }

  get displayTeamSelectionBlock() {
    return (
      this.$route.params?.teamId && this.canSelectTeam && this.teams.length > 1 && this.selectedTeam
    )
  }

  get displayTeamNavigationBlock() {
    return this.$route.params?.teamId && this.teams.length > 1 && this.$route.name !== "create-team"
  }

  get meta() {
    return this.$route.meta ?? null
  }

  get breadcrumbConfigList(): BreadcrumbConfig[] {
    return (this.meta?.breadcrumbConfigList as BreadcrumbConfig[]) ?? []
  }

  get sprintLabel() {
    if (!this.$route.params?.sprintId) return ""
    return this.$route.params?.sprintId === "active"
      ? "Active Sprint"
      : `Sprint #${this.$route.params.sprintId}`
  }

  breadcrumbTitle(breadcrumbConfig: BreadcrumbConfig) {
    if (
      !this.isSuperAdmin &&
      this.$route.name === "edit-account" &&
      this.user.accounts.find(account => this.account?.id === account.id)
    ) {
      return "My Account"
    }
    if (typeof breadcrumbConfig.breadcrumbTitle === "function") {
      const action = breadcrumbConfig.breadcrumbTitle
      if (!breadcrumbConfig.bcGetter) {
        return ""
      }
      let data = {
        value: store.getters[Getters[breadcrumbConfig.bcGetter]],
        route: this.$route
      }
      if (this.$route.name === "edit-user" && this.$route.params?.userId === this.user.id) {
        data.value = ""
      }
      const result = action(data)
      return result ? result : ""
    } else {
      return breadcrumbConfig.breadcrumbTitle
    }
  }
  //The configuration is in router.ts
  breadcrumbLink(breadcrumbConfig: BreadcrumbConfig): BreadcrumbLink | undefined {
    if (!breadcrumbConfig.breadcrumbLink) return
    const action = breadcrumbConfig.breadcrumbLink
    let data = {
      //To get the value you need to pass bcGetter in BreadcrumbConfig

      value: breadcrumbConfig.bcGetter ? store.getters[Getters[breadcrumbConfig.bcGetter]] : "",
      route: this.$route
    }
    const result = action(data)
    return result?.title ? result : undefined
  }

  breadcrumbComponent(breadcrumbConfig: BreadcrumbConfig): any {
    if (!breadcrumbConfig.breadcrumbComponent) {
      return
    }
    const action = breadcrumbConfig.breadcrumbComponent
    let data = {
      value: breadcrumbConfig.bcGetter ? store.getters[Getters[breadcrumbConfig.bcGetter]] : "",
      route: this.$route
    }
    const result = action(data)
    return result
  }

  get breadcrumbSelectedTeam(): Team | null {
    const breadcrumbSelectedTeam = this.$route.meta
      ?.breadcrumbSelectedTeam as BreadcrumbSelectedTeam
    if (!breadcrumbSelectedTeam || !breadcrumbSelectedTeam.selectedTeam) {
      return null
    }
    const action = breadcrumbSelectedTeam.selectedTeam
    let data = {
      value: breadcrumbSelectedTeam.bcGetter
        ? store.getters[Getters[breadcrumbSelectedTeam.bcGetter]]
        : "",
      route: this.$route
    }
    const result = action(data)
    return result
  }

  isBreadcrumbTitleWaiting(breadcrumbConfig: BreadcrumbConfig) {
    if (!breadcrumbConfig?.bcWait) return false
    return this.$wait.is(breadcrumbConfig.bcWait as string)
  }

  get isSuperAdmin() {
    return useAcl().can("superAdmin")
  }

  get canSelectTeam() {
    return this.meta?.canSelectTeam
  }

  @Watch("$route.params.projectId")
  async watchProjectId(projectId, prev) {
    if (prev || !projectId) return
    this.$wait.start("teamsAndProject.get")
    await this.getProject({ projectId: this.projectId })
    await this.watchCanSelectTeam()
    this.$wait.end("teamsAndProject.get")
  }

  @Watch("canSelectTeam")
  async watchCanSelectTeam() {
    if (
      !this.projectId ||
      (this.teams.length && this.selectedProject?.urlName === this.$route.params.projectId)
    )
      return
    this.$wait.start("teamsAndProject.get")
    await this.getTeamsInProject(this.projectId)
    this.$wait.end("teamsAndProject.get")
  }

  async onSprintSelected({ sprint, team }) {
    await this.$router.push({
      name: "sprint",
      params: { sprintId: sprint.sequenceNumber, teamId: team.urlName }
    })
  }

  async onSelectDropdownTeam(team: Team) {
    emitterEmit("teamSelected", team)
    if (this.$route.name === "Item" || this.$route.name === "CreateItem" || this.$route.query?.i)
      return
    await this.onSelectTeam(team)
  }

  onTeamClick(team: Team) {
    this.onSelectTeam(team)
    this.$router.push({
      name: "backlog",
      params: {
        projectId: this.selectedProject.urlName,
        teamId: team.urlName
      }
    })
  }

  async onSelectTeam(team: Team) {
    await this.$router.replace({
      params: {
        teamId: team.urlName
      },
      query: this.$route.query
    })
    await this.setSelectedTeam(team)
  }

  @Watch("$route.params.teamId")
  watchTeamId() {
    if (!this.$route.params.teamId) {
      this.resetTeam()
    }
  }

  async onToltipSelectTeam({ team, linkTo }: { team: Team; linkTo: string }) {
    let route = {
      name: linkTo,
      params: {
        projectId: this.selectedProject.urlName,
        teamId: team.urlName
      }
    }

    this.$router.push(route)
  }
}
</script>
