<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="sprintContent.projectName"
  >
    <template #pic>
      <div class="label-pic is-blue">
        <icon
          data="@icon/list-unordered-v2.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
          color="#1565C0"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        You're invited to participate in Sprint #{{ sprintContent.sprint.sequenceNumber }} Retrospective
      </BaseSubHeading>
    </template>
    <template #content>
      <ul>
        <li class="notification__todo">
          <BaseSubHeading
            size="medium"
            color="monochrome-06"
            darkColor="monochrome-03"
            weight="400"
          >
            <a
              class="link is-medium"
              target="_blank"
              :href="sprintContent.retrospectiveUrl"
            >
              {{ sprintContent.todoName }}
            </a>
          </BaseSubHeading>
          <icon
            data="@icon/flag.svg"
            :fill="true"
            width="14"
            height="14"
            class="notification__todo-icon"
            color="#D32F2F"
          />
        </li>
      </ul>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification/Notification"

@Options({
  name: "SPRINT_RETROSPECTIVE_REQUESTED",
  components: {
    NotificationTemplate,
  },
})
export default class SPRINT_RETROSPECTIVE_REQUESTED extends Vue {
  @Prop() readonly notification: Notification

  get sprintContent() {
    return this.notification.content
  }
}
</script>
