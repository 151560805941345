import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/unfold.svg'


const _hoisted_1 = { class: "widget-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "secondary-button-sm btn footer__button",
    tabindex: 0,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onAction && _ctx.onAction(...args)), ["prevent"]))
  }, [
    _createVNode(_component_icon, {
      data: _imports_0,
      fill: true,
      width: "24",
      height: "24",
      color: "#81889B"
    }),
    _createElementVNode("span", _hoisted_1, "ToDo (" + _toDisplayString(_ctx.count > 99 ? '99+' : _ctx.count) + ")", 1)
  ]))
}