import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-chat-gpt__content" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content.length === 1)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.content[0].join(" ")), 1)
        ], 64))
      : (_ctx.content[0].length === 1)
        ? (_openBlock(), _createElementBlock("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item[0]), 1))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("table", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (row, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, _toDisplayString(index), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (column, subindex) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: subindex,
                    width: "100",
                    height: "100"
                  }, _toDisplayString(column), 1))
                }), 128))
              ]))
            }), 128))
          ]))
  ]))
}