import { NotificationClass, classByType } from "./common"
import { Notification } from "./Notification"
import { NotificationTodo } from "./NotificationTodo"
import { NotificationItem } from "./NotificationItem"
import { NotificationTask } from "./NotificationTask"
import { NotificationComment } from "./NotificationComment"
import { NotificationBugWatermark } from "./NotificationBugWatermark"
import { NotificationStoryPoker } from "./NotificationStoryPoker"
import { NotificationRetrospective } from "./NotificationRetrospective"
import { NotificationMilestone } from "./NotificationMilestone"
import { NotificationGoal } from "./NotificationGoal"
import { NotificationProjectAddition } from "./NotificationProjectAddition"
import { NotificationTeamAddition } from "./NotificationTeamAddition"

const itemNotificationChangesMarkers = [
  "ownerChanged",
  "storyPointsChanged",
  "descriptionChanged",
  "tasksChanged",
  "severityLevelChanged",
  "statusChanged",
  "teamChanged",
  "addedOrRemovedFromActiveSprint",
]

export class NotificationInit {
  static generateNotificationType(changedField) {
    return changedField.includes("Changed") ? changedField.replace("Changed", "").toUpperCase() : "ACTIVE_SPRINT_ADDED"
  }

  static parseItemNotification(props) {
    const content = props.content
    const result: Notification[] = []
    const fieldsKeys = Object.keys(content)
      .filter(field => itemNotificationChangesMarkers.includes(field))
      .filter(field => content[field] === true)

    fieldsKeys.forEach((key) => {
      result.push(new NotificationItem({
        ...props,
        type: `ITEM_UPDATE_${NotificationInit.generateNotificationType(key)}`,
      }))
    })
    return result
  }

  static parseItemCreatedNotification(props) {
    const content = props.content
    return (content?.addedOrRemovedFromActiveSprint) ? [
      new NotificationItem({
        ...props,
        type: "ITEM_UPDATE_ACTIVE_SPRINT_ADDED",
      }),
    ] : []
  }

  static parseItemNotificationTasksUpdate(props) {
    const result: Notification[] = []
    props.content.tasks.forEach((task) => {
      props["task"] = task
      result.push(new NotificationTask({
        ...props,
        type: "UPDATE_TASK",
      }))
    })

    return result
  }

  static parseBugWatermarkNotification(props) {
    const result: Notification[] = []
    const levels = ["critical", "high", "medium", "trivial"]

    Object.keys(props.content).forEach((item) => {
      const level = props.content[item]
      if (levels.includes(item) && level.current > level.max) {
        result.push(new NotificationBugWatermark({
          ...props,
          level: item.toUpperCase(),
          type: "BUG_WATERMARK_EXCEEDED_BY_LEVEL",
        }))
      }
    })

    return result
  }

  static create(props) {
    switch (classByType[props.type]) {
      case NotificationClass.NOTIFICATION_TODO:
        return new NotificationTodo(props)
      case NotificationClass.NOTIFICATION_ITEM:
        return new NotificationItem(props)
      case NotificationClass.NOTIFICATION_TASK:
        return new NotificationTask(props)
      case NotificationClass.NOTIFICATION_COMMENTS:
        return new NotificationComment(props)
      case NotificationClass.NOTIFICATION_BUG_WATERMARK:
        return new NotificationBugWatermark(props)
      case NotificationClass.NOTIFICATION_STORY_POKER:
        return new NotificationStoryPoker(props)
      case NotificationClass.NOTIFICATION_SPRINT_RETROSPECTIVE:
        return new NotificationRetrospective(props)
      case NotificationClass.NOTIFICATION_MILESTONE:
        return new NotificationMilestone(props)
      case NotificationClass.NOTIFICATION_GOAL:
        return new NotificationGoal(props)
      case NotificationClass.NOTIFICATION_PROJECT_ADDITION:
        return new NotificationProjectAddition(props)
      case NotificationClass.NOTIFICATION_TEAM_ADDITION:
        return new NotificationTeamAddition(props)
      default:
        return new Notification(props)
    }
  }
}
