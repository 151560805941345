import { User } from "./User"
import { NameIdAndUrl } from "./common"
import { ChartInfo } from "@/models/Project"
import { RetroStatus } from "./Retrospective"
import {
  formatDate,
  differenceInBusinessDays,
} from "@/utils/dateUtil"
import { isAfter } from "date-fns"
import { Base } from "./Base"
import { Task } from "./Task"
import { HealthCheckSummary } from "./HealthCheck"
import { Team } from "./Team"

export type SprintStatus = "NOT_STARTED" | "STARTED" | "HALTED" | "COMPLETED";

export type SprintStatusReview = "IN_REVIEW" | "REVIEW_COMPLETED" | "NOT_STARTED"

export type SelectedChart = "BURNDOWN" | "HISTORY"

export type SprintIntervalsTypes = "CURRENT_SPRINT" | "THREE_SPRINTS" | "SIX_SPRINTS"

export interface Quote {
  message: string;
  author: string;
}

export interface TimeOff {
  description: string;
  userId: string;
  startDate: string;
  endDate: string;
}

export class ScheduleTimeOff {
  startDate: Date = new Date()
  endDate: Date = new Date()
  members: User[] = []
  description = ""

  constructor(props?: Partial<ScheduleTimeOff>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export interface AddItemSprintPayload {
  sprintId: string;
  itemId: string;
}

export interface SprintInfo {
  id: string;
  sequenceNumber: number;
  endDate: string;
  startDate: string;
  status: SprintStatus;
  zeroSprint?: boolean
  continuousSprint?: boolean
  wipLimitExceeded?: boolean
  wip?: number
  goals?: string
}

export interface SprintMetrics {
  capacityHours: number;
  items: number;
  notEstimated: number;
	remainingHours: number;
  plannedEffort: number;
  totalStoryPoints: number;
  timeLeftHours: number;
  completedStoryPoints: number;
  unassigned: number;
  velocity: number;
  remaining: number;
  completed: number;
  chartInfo?: ChartInfo;
  itemMetricsLog: SprintMetricsLog;
  currentRiskPercent: number;
  inProgress: number;
  notStarted: number;
  avgCycleTime: number;
  avgLeadTime: number;
  avgWip: number;
}

export interface SprintMetricsLog {
  [key: string]: SprintMetricsLogData;
}
export interface SprintMetricsLogData {
  notStarted: number;
  inProgress: number;
  completed: number;
}

export interface SprintWithStats {
  sprint: Sprint;
  stats: SprintMetrics;
}

export class Sprint extends Base<Sprint> {
  id = ""
  status: SprintStatus = "NOT_STARTED"
  startDate = ""
  endDate = ""
  continueEndDate = ""
  utilizationRatio = 65
  timeOffs: TimeOff[] = []
  projectId = ""
  team: NameIdAndUrl = { id: "", name: "", urlName: "" }
  sequenceNumber = 1
  storyPoints = 0
  teamId = ""
  previousSprintId = ""
  nextSprintId = ""
  goals = ""
  summary = ""
  haltReason = ""
  retrospectiveStatus: RetroStatus = "NOT_STARTED"
  zeroSprint = false
  continuousSprint = false
  wipLimitExceeded = false
  wip = 1
  continuousCycle = 0
  size = 0
  requiredRetrospective = true
  nextSprintUpdated = false
  reviewStatus: SprintStatusReview = "NOT_STARTED"
  constructor(props?: Partial<Sprint>) {
    super()
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
    if (this.continuousSprint && this.continueEndDate) {
      this.endDate = this.continueEndDate
    }
  }

  getJsonObj() {
    return {
      teamId: this.teamId,
      status: this.status ?? "NOT_STARTED",
      startDate: new Date(this.startDate).toISOString(),
      endDate: new Date(this.endDate).toISOString(),
      continueEndDate: this.continueEndDate ? new Date(this.continueEndDate).toISOString() : "",
      utilizationRatio: this.utilizationRatio,
      timeOffs: this.timeOffs.map(timeOff => ({
        ...timeOff,
        startDate: new Date(timeOff.startDate).toISOString(),
        endDate: new Date(timeOff.endDate).toISOString(),
      })),
      goals: this.goals,
      summary: this.summary,
      haltReason: this.haltReason,
      zeroSprint: this.zeroSprint,
      continuousSprint: this.continuousSprint,
      wip: this.wip > 0 ? this.wip : 1,
      continuousCycle: this.continuousCycle,
      requiredRetrospective: this.requiredRetrospective,
      reviewStatus: this.reviewStatus,
    }
  }

  static create(props?: Partial<Sprint>): Sprint {
    return new Sprint(props)
  }

  get isStarted() {
    return this.status === "STARTED"
  }

  get isCompleted() {
    return this.status === "COMPLETED"
  }

  get isHalted() {
    return this.status === "HALTED"
  }

  get isNotStarted() {
    return this.status === "NOT_STARTED"
  }

  get isReview() {
    return this.reviewStatus === "IN_REVIEW"
  }

  get isReviewCompleted() {
    return this.reviewStatus === "REVIEW_COMPLETED"
  }

  get retroIsCompleted() {
    return this.retrospectiveStatus === "COMPLETED"
  }

  get retroIsNotStarted() {
    return this.retrospectiveStatus === "NOT_STARTED"
  }

  get retroIsRequested() {
    return this.retrospectiveStatus === "REQUESTED"
  }

  get retroIsRevealed() {
    return this.retrospectiveStatus === "REVEALED"
  }

  get retroIsStarted() {
    return this.retrospectiveStatus === "STARTED"
  }

  get formatedDateStart() {
    return this.startDate ? formatDate(new Date(this.startDate), "dd MMM") : ""
  }

  get formatedDateEnd() {
    return this.endDate ? formatDate(new Date(this.endDate), "dd MMM") : ""
  }

  get workDays() {
    return differenceInBusinessDays(new Date(this.endDate), new Date(this.startDate))
  }

  get canCompleteSprint() {
    const yesterdayDate = new Date()
    const endDate = new Date(this.endDate)
    return isAfter(yesterdayDate, endDate)
  }

  get changeByFields() {
    return [
      "startDate",
      "endDate",
      "utilizationRatio",
      "continuousSprint",
      "wip",
      "goals",
      "requiredRetrospective",
      "continuousCycle",
    ] as (keyof Sprint)[]
  }

  onWipLimitsCalculate(numberOfUsers: number) {
    this.wip = numberOfUsers + Math.round(numberOfUsers / 2) + 1
  }

  getComplitionBarConfig(stats: SprintMetrics) {
    const percentCompletion = stats["items"]
      ?
      Math.round(stats["completed"] /
        stats["items"] * 100)
      : 0
    return {
      label: `Completion ${percentCompletion}%`,
      currentValue: percentCompletion,
      maxValue: 100,
    }
  }

  isModified(keys?: (keyof Sprint)[], inverse?: boolean) {
    let isModified = false
    if (!isModified) {
      isModified = super.isModified(keys, inverse)
    }
    return isModified
  }
}

export interface SprintCapacityFilter {
  endDate: Date;
  startDate: Date;
  teamId: string;
  utilizationRatio: number;
  timeOffs: TimeOff[];
}

export class SprintCapacity {
  capacityHours = 0
  developerDays = 0
  workDays = 0

  constructor(props?: Partial<SprintCapacity>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class DataByBacklogType {
  storyAndSpike: number
  bug: number
  techDebt: number
  supportCase: number
  devOps: number
  total: number

  constructor(props?: Partial<DataByBacklogType>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

interface KeyWithNumber {
  [key: string]: number
}

export class DataByItemType {
  story = 0
  spike = 0
  product = 0
  bug = 0
  techDebt = 0
  supportCase = 0
  devOps = 0
  total = 0

  constructor(props?: Partial<DataByItemType>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}


export class DataByTaskType {
  design = 0
  uxDesign = 0
  developer = 0
  qa = 0
  bug = 0
  devops = 0

  constructor(props?: Partial<DataByTaskType>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export interface QaTileLine {
  [key: string]: {
    hours: number,
    tasks: Task[]
  }
}

export class SprintIntervals {
  CURRENT_SPRINT = 0
  THREE_SPRINTS = 0
  SIX_SPRINTS = 0

  constructor(props?: Partial<SprintIntervals>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class SprintAnalytics {
  capacityObservation = 0
  carryOverItems = 0
  carryOverStoryPoints = 0
  completedItems = 0
  itemsPlanned = 0
  percentCompleted = 0
  sprint = new Sprint()
  cycleTimeInDays: { [key: string]: DataByItemType } = {}
  leadTimeInDays: { [key: string]: DataByItemType } = {}
  completedItemsByPeriod: SprintIntervals = new SprintIntervals()
  storiesUnblockedWithin: SprintIntervals = new SprintIntervals()
  velocity: SprintIntervals = new SprintIntervals()

  constructor(props?: Partial<SprintAnalytics>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      if (field === "sprint") {
        this[field] = new Sprint(props[field])
        return
      }
      if (field === "cycleTimeInDays" || field === "leadTimeInDays") {
        const value = { ...props[field] }
        const keys = Object.keys(value)
        keys.forEach((key) => {
          this[field][key] = new DataByItemType(value[key])
        })
        return
      }
      this[field] = props[field]
    })
  }
}

export class SprintAnalyticsPerformance {
  avgAmountOfWork: { [key: string]: DataByTaskType } = {}
  cycleTimeByStatus: {[key: string]: KeyWithNumber} = {}
  sprintBacklogAnalytics: { [key: string]: DataByItemType } = {}
  sprintItemCycleTimeAnalytics: {
    [key: string]: {
      [key: string]: DataByItemType
    }
  } = {}

  constructor(props?: Partial<SprintAnalyticsPerformance>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class SprintAnalyticsQuality {
  bugs: SprintIntervals = new SprintIntervals()
  newTechDebt: SprintIntervals = new SprintIntervals()
  qaEffort = 0
  qaTileLine: QaTileLine
  supportCaseCycleTime: SupportCaseCycleTime = new SupportCaseCycleTime()
  supportCases: SprintIntervals = new SprintIntervals()

  constructor(props?: Partial<SprintAnalyticsQuality>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      if (field === "supportCaseCycleTime") {
        this[field] = new SupportCaseCycleTime(props[field])
        return
      }
      this[field] = props[field]
    })
  }
}

export class SprintAnalyticsTeam {
  discoveredWork: SprintIntervals = new SprintIntervals()
  healthCheckSummary: HealthCheckSummary = new HealthCheckSummary()
  membersByRole: KeyWithNumber = {}
  team: Team = new Team()
  todosAdded: SprintIntervals = new SprintIntervals()
  todosDone: SprintIntervals = new SprintIntervals()
  workInProgress: SprintIntervals = new SprintIntervals()

  constructor(props?: Partial<SprintAnalyticsTeam>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      if (field === "healthCheckSummary") {
        this[field] = new HealthCheckSummary(props[field])
        return
      }
      if (field === "team") {
        this[field] = new Team(props[field])
        return
      }
      this[field] = props[field]
    })
  }
}

export class SupportCaseCycleTimeTypes {
  CRITICAL = 0
  HIGH = 0
  MEDIUM = 0
  TRIVIAL = 0

  constructor(props?: Partial<SupportCaseCycleTime>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class SupportCaseCycleTime {
  CURRENT_SPRINT = new SupportCaseCycleTimeTypes()
  THREE_SPRINTS = new SupportCaseCycleTimeTypes()
  SIX_SPRINTS = new SupportCaseCycleTimeTypes()

  constructor(props?: Partial<SupportCaseCycleTime>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = new SupportCaseCycleTimeTypes(props[field])
    })
  }
}
