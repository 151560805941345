import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification__update-item__initiator" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_ReferencedText = _resolveComponent("ReferencedText")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, { notification: _ctx.notification }, {
    pic: _withCtx(() => [
      _createVNode(_component_Avatar, {
        src: _ctx.notification.initiatorAvatarSrc || null,
        gap: _ctx.notification.getInitials()
      }, null, 8, ["src", "gap"])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" You have been mentioned by "),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.notification.initiatorFullName), 1),
          _createTextVNode(" to "),
          _createElementVNode("a", {
            class: "link is-large",
            target: "_blank",
            href: _ctx.itemUrl
          }, " Story " + _toDisplayString(_ctx.notification.itemSequenceNumber) + ": ", 9, _hoisted_2)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400",
        className: "notification__desc"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ReferencedText, {
            resetStyle: true,
            html: _ctx.notification.text
          }, null, 8, ["html"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["notification"]))
}