<template>
  <div>
    <template
      v-for="(item, index) in filteredItems"
      :key="index"
    >
      <div
        v-if="item.type === 'divider'"
        class="custom-editor__divider"
      />
      <div
        v-else-if="item.type === 'divider_task'"
        class="custom-editor__divider_task"
      />
      <div
        v-else-if="item.type === 'dividerLg'"
        class="custom-editor__divider custom-editor__divider_lg"
      />

      <DropdownSingleLabel
        v-else-if="item.type === 'dropdown'"
        :id="item.title"
        :options="item.options"
        trackBy="label"
        label="label"
        class="custom-editor__button"
        :tabindex="-1"
        :appendDropDownToBody="true"
        @input="onDropdownInput"
      >
        <template #label>
          <icon
            class="heading-level heading-level-3"
            :data="item.icon"
            width="1em"
            height="1em"
          />
        </template>
      </DropdownSingleLabel>

      <EditorColorPicker
        v-else-if="item.type === 'fontColor'"
        v-bind="item"
      />

      <EditorUploadFiles
        v-else-if="item.type === 'addFile'"
        :id="`addFile-${id}`"
        v-bind="item"
      />

      <EditorMenuItem
        v-else
        v-bind="item"
      />
    </template>


    <Teleport to="body">
      <EditorModalLink
        :id="id"
        @onAction="onModalLinkChange"
      />
    </Teleport>
  </div>
</template>

<script lang="ts">
import EditorMenuItem from "@/components/Editor/EditorMenuItem.vue"
import EditorUploadFiles from "@/components/Editor/EditorUploadFiles.vue"
import DropdownSingleLabel from "@/components/Dropdown/DropdownSingleLabel.vue"
import EditorModalLink from "@/components/Editor/EditorModalLink.vue"
import EditorColorPicker from "@/components/Editor/EditorColorPicker.vue"
import { Emit, Prop } from "vue-property-decorator"
import { Editor } from "@tiptap/vue-3"
import { Options, Vue } from "vue-class-component"
import { TypeConfig } from "@/models"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
import { editorNativeColor } from "@/plugins/extensions/Color"

type option = {
  id: string,
  label: string
}

@Options({
  name: "EditorMenuBar",
  components: {
    EditorMenuItem,
    DropdownSingleLabel,
    EditorModalLink,
    EditorColorPicker,
    EditorUploadFiles,
  },
})
export default class EditorMenuBar extends Vue {
  @Prop({
    required: true,
  }) editor!: Editor
  @Prop({
    default: false,
  }) taskEditor!: boolean
  @Prop() targetConfig: TypeConfig | null
  @Prop({
    default: "",
  }) id: string
  url!: string

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  get theme() {
    return this.isDark ? "dark" : "light"
  }

  get items() {
    return [
      {
        icon: require("@icon/bold.svg"),
        title: "Bold",
        action: () => this.editor.chain().focus().toggleBold().run(),
        isActive: () => this.editor.isActive("bold"),
        inTask: true,
      },
      {
        icon: require("@icon/italic.svg"),
        title: "Italic",
        action: () => this.editor.chain().focus().toggleItalic().run(),
        isActive: () => this.editor.isActive("italic"),
        inTask: true,
      },
      {
        icon: require("@icon/strike.svg"),
        title: "Strike",
        action: () => this.editor.chain().focus().toggleStrike().run(),
        isActive: () => this.editor.isActive("strike"),
        inTask: true,
      },
      {
        icon: require("@icon/underline.svg"),
        title: "Underline",
        action: () => this.editor.chain().focus().toggleUnderline().run(),
        isActive: () => this.editor.isActive("underline"),
        inTask: true,
      },
      {
        icon: require("@icon/font-color.svg"),
        title: "Font Color",
        type: "fontColor",
        action: (color: string) => this.editor.chain().focus().setColor(color, this.theme).run(),
        isActive: (color: string) => this.editor.isActive("textStyle", {
          color,
        }),
        colors: [
          "#E54848",
          "#FF792E",
          "rgb(252, 185, 0)",
          "#26D858",
          "rgb(0, 255, 225)",
          "rgb(6, 147, 227)",
          "rgb(153, 0, 239)",
          "rgb(255, 0, 225)",
          editorNativeColor[this.theme],
          "#7D92A7",
        ],
      },
      {
        type: "divider",
        inTask: true,
      },
      {
        icon: require("@icon/align-left.svg"),
        title: "Left",
        action: () => this.editor.chain().focus().setTextAlign("left").run(),
        isActive: () => this.editor.isActive({ textAlign: "left" }),
      },
      {
        icon: require("@icon/align-center.svg"),
        title: "Center",
        action: () => this.editor.chain().focus().setTextAlign("center").run(),
        isActive: () => this.editor.isActive({ textAlign: "center" }),
      },
      {
        icon: require("@icon/align-right.svg"),
        title: "Right",
        action: () => this.editor.chain().focus().setTextAlign("right").run(),
        isActive: () => this.editor.isActive({ textAlign: "right" }),
      },
      {
        icon: require("@icon/align-justify.svg"),
        title: "Justify",
        action: () => this.editor.chain().focus().setTextAlign("justify").run(),
        isActive: () => this.editor.isActive({ textAlign: "justify" }),
      },
      {
        type: "divider",
      },
      {
        icon: require("@icon/h1.svg"),
        title: "Heading 1",
        action: () => this.editor.chain().focus().toggleHeading({
          level: 1,
        }).run(),
        isActive: () => this.editor.isActive("heading", { level: 1 }),
      },
      {
        icon: require("@icon/h2.svg"),
        title: "Heading 2",
        action: () => this.editor.chain().focus().toggleHeading({
          level: 2,
        }).run(),
        isActive: () => this.editor.isActive("heading", { level: 2 }),
      },
      {
        type: "divider",
      },
      {
        icon: require("@icon/list-unordered.svg"),
        title: "Bullet List",
        action: () => this.editor.chain().focus().toggleBulletList().run(),
        isActive: () => this.editor.isActive("bulletList"),
        inTask: true,
      },
      {
        icon: require("@icon/list-ordered.svg"),
        title: "Ordered List",
        action: () => this.editor.chain().focus().toggleOrderedList().run(),
        isActive: () => this.editor.isActive("orderedList"),
        inTask: true,
      },
      {
        type: "divider",
        inTask: true,
      },
      {
        icon: require("@icon/double-quotes.svg"),
        title: "Blockquote",
        action: () => this.editor.chain().focus().toggleBlockquote().run(),
        isActive: () => this.editor.isActive("blockquote"),
      },
      {
        icon: require("@icon/code-box.svg"),
        title: "Code Block",
        action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        isActive: () => this.editor.isActive("codeBlock"),
        inTask: true,
      },

      {
        icon: require("@icon/link.svg"),
        title: "Link",
        action: () => this.setLink(),
        isActive: () => this.editor.isActive("link"),
        inTask: true,
      },
      {
        type: this.taskEditor ? "divider_task" : "divider",
        inTask: true,
      },
      {
        icon: require("@icon/text-wrap.svg"),
        title: "Hard Break",
        action: () => this.editor.chain().focus().setHardBreak().run(),
      },
      {
        icon: require("@icon/table.svg"),
        title: "Table",
        type: "dropdown",
        options: [
          {
            id: "insertTable",
            label: "Insert table",
          },
          {
            id: "addColumnBefore",
            label: "Add column before",
          },
          {
            id: "addColumnAfter",
            label: "Add column after",
          },
          {
            id: "deleteColumn",
            label: "Delete column",
          },
          {
            id: "addRowBefore",
            label: "Add row before",
          },
          {
            id: "addRowAfter",
            label: "Add row after",
          },
          {
            id: "deleteRow",
            label: "Delete row",
          },
          {
            id: "mergeCells",
            label: "Merge cells",
          },
          {
            id: "splitCell",
            label: "Split cell",
          },
          {
            id: "toggleHeaderColumn",
            label: "Toggle header column",
          },
          {
            id: "toggleHeaderRow",
            label: "Toggle header row",
          },
        ],
      },
      {
        icon: require("@icon/separator.svg"),
        title: "Horizontal Rule",
        action: () => this.editor.chain().focus().setHorizontalRule().run(),
      },
      {
        type: "divider",
      },
      {
        icon: require("@icon/format-clear.svg"),
        title: "Clear Format",
        action: () => this.editor.chain()
          .focus()
          .clearNodes()
          .unsetAllMarks()
          .run(),
        inTask: true,
      },
      {
        type: this.taskEditor ? "divider_task" : "divider",
        inTask: true,
      },
      {
        icon: require("@icon/add-file.svg"),
        title: "Add File",
        type: "addFile",
        action: files => this.onAddFiles(files),
        inTask: true,
      },
      {
        type: this.taskEditor ? "divider_task" : "divider",
        inTask: true,
      },

      {
        icon: require("@icon/arrow-go-back.svg"),
        title: "Undo",
        action: () => this.editor.chain().focus().undo().run(),
        inTask: true,
      },
      {
        icon: require("@icon/arrow-go-forward.svg"),
        title: "Redo",
        action: () => this.editor.chain().focus().redo().run(),
        inTask: true,
      },
    ]
  }

  get filteredItems() {
    if (this.taskEditor) return this.items.filter(item => item.inTask)

    return this.items
  }

  setLink() {
    this.$modal.show(`editor-modal-link-${this.id}`)
  }

  onDropdownInput({ value } : { value: option}) {
    this.tableEvents(value.id)
  }

  tableEvents(event:string) {
    switch (event) {
      case "insertTable":
        this.editor.chain().focus().insertTable({
          rows: 3,
          cols: 3,
          withHeaderRow: true,
        }).run()
        break
      case "addColumnBefore":
        this.editor.chain().focus().addColumnBefore().run()
        break
      case "addColumnAfter":
        this.editor.chain().focus().addColumnAfter().run()
        break
      case "deleteColumn":
        this.editor.chain().focus().deleteColumn().run()
        break
      case "addRowBefore":
        this.editor.chain().focus().addRowBefore().run()
        break
      case "addRowAfter":
        this.editor.chain().focus().addRowAfter().run()
        break
      case "deleteRow":
        this.editor.chain().focus().deleteRow().run()
        break
      case "deleteTable":
        this.editor.chain().focus().deleteTable().run()
        break
      case "mergeCells":
        this.editor.chain().focus().mergeCells().run()
        break
      case "splitCell":
        this.editor.chain().focus().splitCell().run()
        break
      case "toggleHeaderColumn":
        this.editor.chain().focus().toggleHeaderColumn().run()
        break
      case "toggleHeaderRow":
        this.editor.chain().focus().toggleHeaderRow().run()
        break

      default:
        break
    }
  }

  @Emit()
  onAddFiles(files) {
    this.editor.chain().focus().setFile(files, this.targetConfig).run()
    return true
  }

  onModalLinkChange(value:string) {
    this.url = value

    this.editor.chain().focus().setLink({ href: this.url }).run()
  }
}
</script>
