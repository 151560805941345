import {
  FilterBacklog,
  ItemQueryFilter,
  ItemQueryPriorityType,
  FilterBacklogMetrics,
} from "./Filter"
import { SprintInfo, SprintStatus } from "@/models/Sprint"
import { Item, ItemStatus, ItemType } from "./Item"
import { Milestone } from "./Milestone"
import { Epic } from "./Epic"
import { isAfter } from "date-fns"

export type StoryAgeType = "bug" | "devOps" | "storyAndSpike" | "supportCase" | "techDebt"

export type StoreMutation = "setBacklog" | "setUnprioritizedBacklog" | "setSprintBacklog" | "setEpicBacklog" | "setMilestones" | "setEpicRoadmap" | "setMyWork"

export interface BacklogAnalyticsPeriod {
  start: number
  end: number
  metrics: AdditionalProp
  startIndex: number
  endIndex: number
}
export interface AdditionalProp {
  bug: number
  devOps: number
  storyAndSpike: number
  supportCase: number
  techDebt: number
  total: number
}

export interface BacklogAnalyticsChart {
  [key: string]: AdditionalProp
}

export class BacklogAnalytics {
  chartInfo: BacklogAnalyticsChart = {}
  periods: BacklogAnalyticsPeriod[] = []
  general: AdditionalProp = {
    bug: 0,
    devOps: 0,
    storyAndSpike: 0,
    supportCase: 0,
    techDebt: 0,
    total: 0,
  }
  constructor(props?: Partial<BacklogAnalytics>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export interface Backlog {
  items: Item[];
  size?: number;
  storyPoints?: number;
  estimatedHours?: number;
  notPrioritizedItemsCount?: number;
  nonEstimatedTaskCount?: number;
  unassignedItemsCount?: number;
}

export interface ChangePriorityParams {
  itemPriority: ItemQueryPriority,
  backlogSetup?: BacklogSetup,
  priorityMessageParams?: ChangePriorityMessageParams
}

export interface UpdateElementInBacklog {
  item: Item | Epic | Milestone,
  storeBacklogId: string,
  sprintId?: string
}

export interface InsertItemInBacklog {
  item: Item;
  index: number | null;
  backlogName: string;
  sprintId?: string;
}

export interface RemoveItemFromBacklog {
  itemId: string;
  nameBacklog: string;
  sprintId?: string;
}

export interface ServerPriorityResponse {
  newPriority: number,
  normalizedAll: boolean
}

export interface EpicMilestonePriority {
  epicId?: string,
  milestoneId?: string,
}

export interface BugWaterline {
  label: string,
  severityKey: string,
  currentValue: number,
  maxValue: number,
}

export interface ItemMoveTo {
  from: ItemQueryPriorityType,
  fromSprintId?: string,
  to: ItemQueryPriorityType,
  toSprintId?: string,
  toSprintStatus?: SprintStatus,
  toSprintSequenceNumber?: number,
  toSprintInfo?: SprintInfo,
  position: "TOP" | "BOTTOM"
  item: Item,
}

export interface ItemQueryPriority {
  previousItemId?: string | null,
  itemId: string,
  nextItemId?: string | null,
  priorityType: ItemQueryPriorityType,
  epicId?: string,
  lowest?: boolean
  highest?: boolean
  sprintId?: string;
  milestoneId?: string;
  item?: Item | Epic;
  backlogSetup?: BacklogSetup;
}

export class ItemQueryPriorityClass {
  previousItemId?: string | null
  itemId: string
  nextItemId?: string | null
  priorityType: ItemQueryPriorityType
  epicId?: string
  lowest?: boolean
  highest?: boolean
  milestoneId?: string
  item?: Item
  backlogSetup?: BacklogSetup
  constructor(props) {
    this.previousItemId = props.previousItemId
    this.itemId = props.itemId
    this.nextItemId = props.nextItemId
    this.priorityType = props.priorityType
    this.epicId = props.epicId
    this.milestoneId = props.milestoneId
    this.lowest = Object.prototype.hasOwnProperty.call(props, "lowest") ? props.lowest : false
    this.item = props.item
    this.backlogSetup = props.backlogSetup
  }

  getJsonObj() {
    return {
      previousItemId: Object.prototype.hasOwnProperty.call(this, "lowest") && this.lowest ? null : this.previousItemId,
      itemId: this.itemId,
      nextItemId: Object.prototype.hasOwnProperty.call(this, "lowest") && this.lowest ? null : this.nextItemId,
      priorityType: this.priorityType,
      epicId: this.epicId && this.epicId.length ? this.epicId : undefined,
      milestoneId: this.milestoneId && this.milestoneId.length ? this.milestoneId : undefined,
      lowest: this.lowest,
      highest: this.highest,
      item: this.item,
      backlogSetup: this.backlogSetup,
    }
  }

  static create(props) {
    return new ItemQueryPriorityClass(props)
  }
}


export type BacklogConfigType = {
  acceptType: ItemType[],
  elementsType: BacklogElementType,
  backlogGetAction: string,
  backlogMetrics?: string,
  storeMutation: StoreMutation,
  storeGetter: string,
  storeContentGetter?: string,
  storeBacklogId: string,
  filter: ItemQueryFilter,
  priorityType: ItemQueryPriorityType,
  priorityAction: string,
  priorityBatchAction?: string,
  addAction?: string,
  removeAction?: string;
  putIntoBacklog?: boolean,
  sortBacklog?: boolean,
  headerComponentName: string,
  backlogSetupId?: string
}

type BacklogType = "backlogProduct" | "backlogUnprioritized" | "backlogBug" | "backlogSprint" | "backlogEpic" | "backlogSupportCases" | "backlogTechDebt" | "backlogDevOps" | "roadmapEpic" | "backlogStoryPoker" | "backlogMilestones" | "backlogMyWork" | "backlogProductAll";
type BacklogConfig = Record<BacklogType, BacklogConfigType>;

type BacklogElementType = "ITEM" | "EPIC"

const backlogConfigs: BacklogConfig = {
  backlogStoryPoker: {
    acceptType: [
      "STORY",
      "TASK",
      "BUG",
      "SPIKE",
      "SUPPORT_CASE",
      "DEV_OPS",
      "TECH_DEBT",
    ],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    storeMutation: "setBacklog",
    storeGetter: "GET_STORY_POKER_BACKLOG",
    storeContentGetter: "GET_STORY_POKER_BACKLOG_CONTENT",
    storeBacklogId: "backlogStoryPoker",
    filter: {
      hasActiveStoryPoker: true,
      includeTeamLessSupportCases: true,
      size: 20,
      hasBacklogPriority: true,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: false,
    headerComponentName: "BacklogStoryPokerHeader",
  },
  backlogProduct: {
    acceptType: ["STORY", "SPIKE"],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "ITEMS_METRICS",
    storeMutation: "setBacklog",
    storeGetter: "GET_PRODUCT_BACKLOG",
    storeContentGetter: "GET_PRODUCT_BACKLOG_CONTENT",
    storeBacklogId: "backlogProduct",
    filter: {
      itemTypes: ["STORY", "SPIKE"],
      hasBacklogPriority: true,
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: true,
    headerComponentName: "BacklogMetricHeader",
  },
  backlogUnprioritized: {
    acceptType: [
      "STORY",
      "TASK",
      "BUG",
      "SPIKE",
      "SUPPORT_CASE",
      "DEV_OPS",
      "TECH_DEBT",
    ],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    storeMutation: "setUnprioritizedBacklog",
    storeGetter: "GET_UNPRIORITIZED_BACKLOG",
    storeContentGetter: "GET_UNPRIORITIZED_BACKLOG_CONTENT",
    storeBacklogId: "backlogUnprioritized",
    filter: {
      itemTypes: [],
      hasBacklogPriority: false,
      size: 150,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      sortKey: "unprioritizedOrder",
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: false,
    sortBacklog: false,
    headerComponentName: "BacklogUnprioritizedHeader",
  },
  backlogBug: {
    acceptType: ["BUG"],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "METRICS_BUG_SEVERITY",
    storeMutation: "setBacklog",
    storeGetter: "GET_BUG_BACKLOG",
    storeContentGetter: "GET_BUG_BACKLOG_CONTENT",
    storeBacklogId: "backlogBug",
    filter: {
      itemTypes: ["BUG"],
      hasBacklogPriority: true,
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: true,
    headerComponentName: "BacklogBugComponent",
  },
  backlogSprint: {
    acceptType: [
      "STORY",
      "TASK",
      "BUG",
      "SPIKE",
      "SUPPORT_CASE",
      "DEV_OPS",
      "TECH_DEBT",
    ],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "SPRINT_METRICS",
    storeMutation: "setSprintBacklog",
    storeGetter: "GET_SPRINT_BACKLOG",
    storeBacklogId: "backlogSprint",
    filter: {
      sortKey: "sprintPriority",
      size: 20,
      inSprint: true,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      hasBacklogPriority: true,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "SPRINT",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    addAction: "ADD_SPRINT_ITEM",
    removeAction: "REMOVE_SPRINT_ITEM",
    putIntoBacklog: true,
    headerComponentName: "BacklogSprintHeader",
  },

  backlogSupportCases: {
    acceptType: ["SUPPORT_CASE"],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "METRICS_SUPPORT_CASE",
    storeMutation: "setBacklog",
    storeGetter: "GET_SUPPORT_CASE_BACKLOG",
    storeContentGetter: "GET_SUPPORT_CASE_BACKLOG_CONTENT",
    storeBacklogId: "backlogSupportCases",
    filter: {
      itemTypes: ["SUPPORT_CASE"],
      hasBacklogPriority: true,
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: true,
    headerComponentName: "BacklogBugComponent",
  },
  backlogTechDebt: {
    acceptType: ["TECH_DEBT"],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "ITEMS_METRICS",
    storeMutation: "setBacklog",
    storeGetter: "GET_TECH_DEBT_BACKLOG",
    storeContentGetter: "GET_TECH_DEBT_BACKLOG_CONTENT",
    storeBacklogId: "backlogTechDebt",
    filter: {
      itemTypes: ["TECH_DEBT"],
      hasBacklogPriority: true,
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: true,
    headerComponentName: "BacklogMetricHeader",
  },
  backlogDevOps: {
    acceptType: ["DEV_OPS"],
    elementsType: "ITEM",
    backlogGetAction: "GET_BACKLOG",
    backlogMetrics: "ITEMS_METRICS",
    storeMutation: "setBacklog",
    storeGetter: "GET_DEV_OPS_BACKLOG",
    storeContentGetter: "GET_DEV_OPS_BACKLOG_CONTENT",
    storeBacklogId: "backlogDevOps",
    filter: {
      itemTypes: ["DEV_OPS"],
      hasBacklogPriority: true,
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      inSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    putIntoBacklog: true,
    headerComponentName: "BacklogMetricHeader",
  },
  backlogEpic: {
    acceptType: [
      "STORY",
      "TASK",
      "BUG",
      "SPIKE",
      "SUPPORT_CASE",
      "DEV_OPS",
      "TECH_DEBT",
    ],
    elementsType: "ITEM",
    backlogGetAction: "GET_EPIC_BACKLOG",
    backlogMetrics: "METRICS_EPIC",
    storeMutation: "setEpicBacklog",
    storeGetter: "GET_EPIC_BACKLOG",
    storeContentGetter: "GET_EPIC_BACKLOG_CONTENT",
    storeBacklogId: "backlogEpic",
    filter: {
      epicIds: [],
      sortKey: "epicPriority",
      excludeStoryStatuses: [],
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "EPIC",
    priorityAction: "CHANGE_BACKLOG_PRIORITY",
    priorityBatchAction: "CHANGE_BACKLOG_PRIORITY_BATCH",
    addAction: "ADD_ITEM_TO_EPIC",
    removeAction: "DELETE_ITEM_FROM_EPIC",
    putIntoBacklog: true,
    headerComponentName: "BacklogEpicHeader",
  },

  backlogMilestones: {
    acceptType: [],
    elementsType: "EPIC",
    backlogGetAction: "GET_BACKLOG_MILESTONES",
    storeMutation: "setMilestones",
    storeGetter: "GET_BACKLOG_MILESTONES",
    storeBacklogId: "milestones",
    filter: {
      size: 50,
      sortKey: "title",
    },
    priorityType: "EPIC",
    addAction: "ADD_EPIC_FROM_MILESTONE",
    priorityAction: "CHANGE_EPIC_PRIORITY_IN_MILESTONE",
    removeAction: "DELETE_EPIC_FROM_MILESTONE",
    putIntoBacklog: true,
    headerComponentName: "",
  },

  roadmapEpic: {
    acceptType: [],
    elementsType: "EPIC",
    backlogGetAction: "GET_EPIC_ROADMAP",
    storeMutation: "setEpicRoadmap",
    storeGetter: "GET_EPIC_ROADMAP",
    storeContentGetter: "GET_EPIC_ROADMAP_CONTENT",
    storeBacklogId: "roadmapEpic",
    filter: {
      sortKey: "roadMapPriority",
      size: 150,
    },
    priorityType: "EPIC",
    addAction: "",
    removeAction: "DELETE_EPIC_FROM_MILESTONE",
    priorityAction: "CHANGE_EPIC_PRIORITY",
    putIntoBacklog: false,
    headerComponentName: "RoadmapEpicHeader",
  },

  backlogMyWork: {
    acceptType: [],
    elementsType: "ITEM",
    backlogGetAction: "GET_MY_WORK",
    storeMutation: "setBacklog",
    storeGetter: "GET_MY_WORK",
    storeContentGetter: "GET_MY_WORK_CONTENT",
    backlogMetrics: "MY_WORK_STATS",
    storeBacklogId: "backlogMyWork",
    filter: {
      teamId: "",
      inActiveSprint: true,
      inNotActiveSprint: false,
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "MY_WORK",
    priorityAction: "MY_WORK_CHANGE_PRIORITY",
    putIntoBacklog: false,
    headerComponentName: "",
  },

  backlogProductAll: {
    acceptType: [],
    elementsType: "ITEM",
    backlogGetAction: "GET_FULL_BACKLOG",
    backlogMetrics: "",
    storeMutation: "setBacklog",
    storeGetter: "GET_PRODUCT_BACKLOG_ALL",
    storeContentGetter: "GET_PRODUCT_BACKLOG_ALL_CONTENT",
    storeBacklogId: "backlogProductAll",
    filter: {
      itemTypes: [],
      size: 20,
      excludeStoryStatuses: ["OBSOLETE", "DONE"],
      withLatestBlockedOrObsoleteDescription: true,
    },
    priorityType: "BACKLOG",
    priorityAction: "",
    headerComponentName: "",
  },
}

export class BacklogSetup {
  acceptType: ItemType[]
  elementsType: BacklogElementType
  backlogGetAction: string
  backlogMetrics?: string
  storeMutation: StoreMutation
  storeGetter: string
  storeContentGetter?: string
  storeBacklogId: string
  filter: FilterBacklog
  priorityType: ItemQueryPriorityType
  priorityAction: string
  priorityBatchAction?: string
  addAction?: string
  removeAction?: string
  putIntoBacklog: boolean | undefined
  sortBacklog?: boolean
  headerComponentName: string
  sprintInfo?: SprintInfo
  channels?: any
  backlogType: BacklogType
  backlogSetupId?: string

  static BacklogProduct = new BacklogSetup("backlogProduct")
  static BacklogUnprioritized = new BacklogSetup("backlogUnprioritized")
  static BacklogBug = new BacklogSetup("backlogBug")
  static BacklogEpic = new BacklogSetup("backlogEpic")
  static BacklogSprint = new BacklogSetup("backlogSprint")
  static BacklogSupportCases = new BacklogSetup("backlogSupportCases")
  static BacklogTechDebt = new BacklogSetup("backlogTechDebt")
  static BacklogDevOps = new BacklogSetup("backlogDevOps")
  static RoadmapEpic = new BacklogSetup("roadmapEpic")
  static BacklogMilestones = new BacklogSetup("backlogMilestones")
  static BacklogStoryPoker = new BacklogSetup("backlogStoryPoker")
  static BacklogMyWork = new BacklogSetup("backlogMyWork")
  static BacklogProductAll = new BacklogSetup("backlogProductAll")

  constructor(backlogType: BacklogType) {
    const config = backlogConfigs[backlogType]
    this.backlogType = backlogType
    this.acceptType = config.acceptType
    this.elementsType = config.elementsType
    this.backlogGetAction = config.backlogGetAction
    this.storeMutation = config.storeMutation
    this.backlogMetrics = config.backlogMetrics
    this.storeGetter = config.storeGetter
    this.storeContentGetter = config.storeContentGetter
    this.storeBacklogId = config.storeBacklogId
    this.filter = FilterBacklog.create(config.filter)
    this.priorityType = config.priorityType
    this.priorityAction = config.priorityAction
    this.priorityBatchAction = config.priorityBatchAction
    this.addAction = config.addAction
    this.removeAction = config.removeAction
    this.putIntoBacklog = config.putIntoBacklog
    this.sortBacklog = config.sortBacklog
    this.headerComponentName = config.headerComponentName
    this.backlogSetupId = this.storeBacklogId
    this.channels = {}
  }

  checkWipLimitExceeded(contentLenth) {
    if (!this.sprintInfo?.wip || this.sprintInfo?.wipLimitExceeded === undefined) return

    if (this.sprintInfo.wip > contentLenth) {
      this.sprintInfo.wipLimitExceeded = false
    } else {
      this.sprintInfo.wipLimitExceeded = true
    }
  }

  set setFilter(val) {
    (val === null)
      ? this.resetFilters()
      : this.filter = Object.assign(this.filter, val)

    this.publish("backlogFilterChanged", this.filter)
  }

  resetFilters() {
    if (this.backlogType.includes("backlogSprint")) {
      const { sprintIds, inSprint } = this.filter

      this.filter = Object.assign(
        FilterBacklog.create(backlogConfigs[this.backlogType].filter),
        {
          sprintIds,
          inSprint,
        },
      )
    } else {
      this.filter = FilterBacklog.create(backlogConfigs[this.backlogType].filter)
    }
  }

  canAddItem(draggedItem: any): boolean {
    switch (this.elementsType) {
      case "ITEM": return this.acceptType.includes(draggedItem.itemTypeParsed.id)
      case "EPIC": return !this.filter?.teamIds?.length
      default: return false
    }
  }

  get isEpicPriority(): boolean {
    return this.priorityType === "EPIC"
  }

  get isItemType(): boolean {
    return this.elementsType === "ITEM"
  }

  get metric() {
    if (!this.backlogMetrics) return false
    if (!this.priorityType.length) return false

    switch (this.priorityType) {
      case "BACKLOG":
        return {
          action: this.backlogMetrics,
          itemType: this.filter.itemTypes,
          type: this.priorityType,
        }
      case "EPIC":
        return {
          action: this.backlogMetrics,
          type: this.priorityType,
        }
      case "SPRINT":
        return {
          action: this.backlogMetrics,
          type: this.priorityType,
        }
      default:
        return {}
    }
  }

  get sprintOverdue() {
    if (!this.sprintInfo || this.sprintInfo.status !== "NOT_STARTED") return false
    const startDate = new Date(this.sprintInfo.startDate)
    const today = new Date()
    const result = isAfter(today, startDate)
    return result
  }

  updateValue({ key, value } : { key: string, value: any }) {
    this[key] = value
  }

  subscribe(channelName, listener) {
    if (!this.channels[channelName]) {
      this.channels[channelName] = []
    }
    this.channels[channelName].push(listener)
  }

  publish(channelName, data) {
    const channel = this.channels[channelName]
    if (!channel || !channel.length) return

    channel.forEach(listener => listener(data))
  }
}

export class BacklogSprintSetup extends BacklogSetup {
  constructor(sprintInfo?: SprintInfo) {
    super("backlogSprint")

    if (!sprintInfo) return

    this.sprintInfo = sprintInfo
    this.filter.sprintIds = [sprintInfo.id]
    this.filter.inSprint = true
    this.backlogSetupId = this.storeBacklogId + `_${this.sprintInfo?.sequenceNumber}`
  }

  canAddItem(draggedItem: Item) {
    if (draggedItem.statusParsed?.id === "DONE") {
      return true
    }

    if (this.sprintInfo?.wipLimitExceeded) {
      return false
    }

    if (this.sprintInfo && this.sprintInfo.status !== "STARTED") {
      return true
    }

    if (this.sprintInfo?.continuousSprint) {
      return draggedItem.isEstimatedWithoutStoryPoints
    }

    return draggedItem.isEstimated
  }

  get isNotStartedSprint(): boolean {
    return this.sprintInfo?.status === "NOT_STARTED"
  }
}

export type BacklogTab = {
  id: string;
  label: string;
  backlogSetup?: BacklogSetup;
  status?: string;
  startDate?: string;
  endDate?: string;
  filterId?: string[] | string;
  metricKey?: string
  countOptions?: BacklogTabCountOptions
}

export interface BacklogTabCountOptions {
  number: string;
  notify?: string;
}

export interface RemoveItemPayload {
  backlogSetup: BacklogSetup;
  item: Item;
}

export interface BacklogFilterOption {
  title: string,
  modalConfig: BacklogFilterModalOption,
  componentConfig: BacklogFilterComponentOption,
}

export interface BacklogFilterModalOption {
  title: string,
  width: string
}

export interface BacklogFilterComponentOption {
  name: string,
  id?: string,
  customId?: string,
  placeholder?: string
}

export interface ChangePriorityMessageParams {
  newIndex: number
  oldIndex?: number
  item: Item | Epic
  toBacklogId: string
  fromBacklogId: string
  toBacklogSprintNumber?: string
  fromBacklogSprintNumber?: string
}


export const paramsForUnprioritizedMetrics = (teamIds: string[]) => {
  const excludeStoryStatuses = ["OBSOLETE", "DONE"] as ItemStatus[]
  const props = {
    teamIds,
    inSprint: false,
    hasBacklogPriority: false,
    excludeStoryStatuses,
    includeTeamLessSupportCases: true,
  }
  return new FilterBacklogMetrics(props)
}
