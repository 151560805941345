import { IdAndName } from "@/models/common"
import { Invitation } from "@/models/Invitation"
import { $t } from "@/plugins/i18n"
import { TeamPreview } from "./Team"
import { ShortAccount } from "./Account"
import { getInitials as getInitialsHelper } from "@/utils/helpers"

export interface Picture {
  pictureStorageKey?: string
  pictureUrl: string | null
}
export interface UserSettings {
  sendEmailNotifications?: boolean
  showTips?: boolean
  showDemo?: boolean
  darkTheme?: boolean
}

export interface UserSettingsPayload {
  userId: string;
  settings: UserSettings;
}

export interface UserWithRole {
  userId: string
  role: string
}

export enum Role {
  CTO = "CTO",
  CIO = "CIO",
  SVP = "SVP",
  VP_ENGINEERING = "VP_ENGINEERING",
  DIRECTOR_ENGINEERING = "DIRECTOR_ENGINEERING",
  HEAD_OF_PRODUCT = "HEAD_OF_PRODUCT",
  PRODUCT_OWNER = "PRODUCT_OWNER",
  PRODUCT_MANAGER = "PRODUCT_MANAGER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  ENGINEER = "ENGINEER",
  SCRUM_MASTER = "SCRUM_MASTER",
  MANAGER_ENGINEERING = "MANAGER_ENGINEERING",
  QA = "QA",
  UI_UX = "UI_UX",
  DEVOPS = "DEVOPS",
}

export enum DeveloperRoles {
  QA = "QA",
  ENGINEER = "ENGINEER",
  UI_UX = "UI_UX",
  DEVOPS = "DEVOPS"
}

export enum ConvertRoleWithAccess {
  MANAGER_ENGINEERING = "ENGINEERING_MANAGERS",
  VP_ENGINEERING = "ENGINEERING_MANAGERS",
  PRODUCT_OWNER = "PRODUCT_OWNERS_AND_PM",
  PRODUCT_MANAGER = "PRODUCT_OWNERS_AND_PM",
  PROJECT_MANAGER = "PRODUCT_OWNERS_AND_PM",
  SCRUM_MASTER = "SCRUM_MASTER",
}

export enum AccountPermission {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface Roles {
  [key: string]: Role;
}

export interface AccountPermissions {
  [key: string]: AccountPermission;
}

export class User {
  id: string
  email: string
  enabled: boolean
  username: string
  firstName: string
  lastName: string
  picture: Picture
  pictureUrl: string
  imported: boolean
  teamRole: string
  accountRole: string
  type: string

  teamRoles: Roles
  accountRoles: Roles
  roles: string[]
  accountPermissions: AccountPermissions
  teams?: TeamPreview[]
  projects?: IdAndName[]
  accounts: ShortAccount[]

  teamRolesString: string
  accountRolesString: string

  settings?: UserSettings

  invited: boolean
  inviteId: string
  emailVerified: boolean
  superAdmin: boolean

  constructor(props?: Partial<User>) {
    this.set(props)
  }

  getJsonObj() {
    return {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: this.email,
      username: `${this.firstName} ${this.lastName}`,
      pictureUrl: null,
      teamRole: this.teamRole,
      accountRole: this.accountRole,
      roles: this.roles,
      type: this.type,
      enabled: this.enabled,
      imported: this.imported,
    }
  }

  getUpdateJsonObj(accountId: string) {
    return {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: this.email,
      accountRole: this.getRole(accountId),
      accountPermission: this.getPermission(accountId),
      enabled: this.enabled,
      imported: this.imported,
    }
  }

  static create(props?: User): User {
    return new User(props)
  }

  set(props?: Partial<User>): void {
    this.setDefaultData()

    if (!props) return

    this.id = props.id ?? ""
    this.email = props.email ?? ""
    this.enabled = props.enabled ?? false
    this.firstName = props.firstName ?? ""
    this.lastName = props.lastName ?? ""
    this.username = props.username ?? this.fullName
    this.pictureUrl = props.picture && props.picture.pictureUrl ? props.picture.pictureUrl + "?" + new Date().getTime() : ""
    this.picture = props.picture ?? { pictureUrl: null }

    this.teamRole = props.teamRole ?? ""
    this.accountRoles = props.accountRoles ?? {}
    this.type = props.type ?? ""

    this.accountPermissions = props.accountPermissions ?? {}
    this.teamRolesString = props.teamRoles ? this.getRolesString(props.teamRoles) : ""
    this.accountRolesString = props.accountRoles ? this.getRolesString(props.accountRoles) : ""

    this.teams = props.teams ?? []
    this.projects = props.projects ?? []
    this.accounts = props.accounts ?? []
    this.settings = props.settings

    this.invited = props.invited ?? false
    this.inviteId = props.inviteId ?? ""
    this.superAdmin = props.superAdmin ?? false
    this.emailVerified = props.emailVerified ?? true

    this.accountRole = props.accountRole ?? ""
    this.roles = props.roles ?? []
  }

  setDefaultData(): void {
    this.id = ""
    this.email = ""
    this.enabled = true
    this.username = ""
    this.firstName = ""
    this.lastName = ""
    this.pictureUrl = ""

    this.teamRole = ""
    this.accountRole = ""
    this.type = "USER"

    this.teamRoles = {}
    this.accountRoles = {}
    this.accountPermissions = {}
    this.teams = []
    this.accounts = []
    this.projects = []

    this.invited = false
    this.inviteId = ""
    this.superAdmin = false
    this.emailVerified = true
  }

  static createDefault(): User {
    return new User()
  }

  static createFromInvitation(props: Invitation) {
    return new User({
      username: props.inviteeName,
      type: props.inviteeAccountRole,
      accountRole: props.inviteeTeamRole,
      teamRole: props.inviteeTeamRole,
      email: props.inviteeEmail,
      invited: true,
      inviteId: props.id,
    })
  }

  getInitials(): string {
    return getInitialsHelper(this.firstName.length && this.lastName.length ? `${this.firstName} ${this.lastName}` : this.username)
  }

  getAccountRoles() {
    return Object.values(this.accountRoles)
  }

  getRolesString(obj: Roles): string {
    if (!obj) {
      return "None"
    }

    const arr = Object.values(obj).filter(a => !!a)
    if (arr.length === 0) {
      return "None"
    }
    return [...new Set(arr.map(a => $t(`role['${a}']`)))].join(", ")
  }

  isAdmin(accountId: string) {
    return this.accountPermissions[accountId] === AccountPermission.ADMIN
  }

  getRole(accountId: string) {
    return this.accountRoles[accountId]
  }

  getPermission(accountId: string) {
    return this.accountPermissions[accountId]
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get accountIds(): string[] {
    return Object.keys(this.accountRoles)
  }

  get accountId(): string | undefined {
    return this.accountIds[0]
  }

  get userTeamRole() {
    return $t(`role.${this.teamRole}`) ?? $t("none")
  }

  get accountName() {
    return this.accounts.find(a => a.id === this.accountId)?.urlName ?? ""
  }

  static get rolesList() {
    return Object.values(Role)
  }
}
