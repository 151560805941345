import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/file.svg'


const _hoisted_1 = { class: "custom-file__pic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_icon, {
      data: _imports_0,
      fill: true,
      width: "1.25em",
      height: "1.5em",
      class: "custom-file__icon",
      color: "var(--monochrome05to04)"
    })
  ]))
}