import isMacintosh from "@/helpers/checkMacintosh"

export enum KeyType {
  ITEM = "ITEM",
  PROJECT = "PROJECT",
  MILESTONE = "MILESTONE",
  EPIC = "EPIC",
  SPRINT = "SPRINT"
}


export const keys = (type: KeyType): string => {
  const firstLetter = [...type][0] ?? "Undefined"

  return isMacintosh()
    ? `⌘+⇧+${firstLetter}`
    : `Ctrl+Shift+${firstLetter}`
}
