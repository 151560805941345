import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { projectModule, ProjectActions } from "./project.module"
import {
  ModalTypesForCratingTask,
  PatchTaskList,
  Task,
  UpdateTaskList,
} from "@/models/Task"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import {
  AlertContentItem,
  AlertType,
  ItemStatus,
  Status,
  TasksWithItemStatus,
  UpdateObjectValue,
} from "@/models"
import { AlertActions, alertModule } from "./alert.module"
import { $t } from "@/plugins/i18n"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"
import { ItemActions, itemModule } from "./item/item.module"
import { sortTask } from "@/utils/helpers"
import { cutTextByNumberOfSymbols } from "@/helpers/cutTextByNumberOfSymbols"

export enum TaskActions {
  GET_TASKS = "GET_TASKS",
  GET_TASK = "GET_TASK",
  UPDATE_TASKS = "UPDATE_TASKS",
  UPDATE_TASKS_WITH_CLONE = "UPDATE_TASKS_WITH_CLONE",
  RESET_TASKS = "RESET_TASKS",
  CREATE_NEW_TASK = "CREATE_NEW_TASK",
  CHANGE_NEW_TASK = "CHANGE_NEW_TASK",
  PUT_TASKS = "PUT_TASKS",
  PATCH_TASKS = "PATCH_TASKS",
  ADD_TASK_IN_LIST = "ADD_TASK_IN_LIST",
  UPDATE_TASK_LIST_CLONE = "UPDATE_TASK_LIST_CLONE",
  GET_CLONE_TASK_LIST = "GET_CLONE_TASK_LIST",
  TASK_LIST_WAS_EDITED = "TASK_LIST_WAS_EDITED",
  PUT_TASKS_WITH_CHANGES_CHECKING = "PUT_TASKS_WITH_CHANGES_CHECKING",
  REMOVE_TASK_FROM_LIST = "REMOVE_TASK_FROM_LIST",
  GET_REMOVED_TASK = "GET_REMOVED_TASK",
  RESET_CHANGES_IN_TASK_LIST = "RESET_CHANGES_IN_TASK_LIST",
  GET_MAX_TASK_NAME_LENGTH = "GET_MAX_TASK_NAME_LENGTH",
  GET_TASKS_WITH_EMPTY_TASK = "GET_TASKS_WITH_EMPTY_TASK",
  SET_MODAL_TYPE_FOR_CREATING_NEW_TASK = "SET_MODAL_TYPE_FOR_CREATING_NEW_TASK",
}

const MAX_TASK_NAME_LENGTH = 74

@Module
class TaskModule extends BaseModule {
  tasks: Task[] = []
  task: Task
  cloneTaskList: Task[] = []
  removedTask: Task | null = null
  modalTypeForCreatingTask: ModalTypesForCratingTask = null
  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${TaskActions.GET_TASKS_WITH_EMPTY_TASK}`]() {
    if (!this.tasks.length || this.tasks.at(-1)?.isNotEmpty) {
      return [
        ...this.tasks,
        Task.createDefault({ order: this.getNewOrder() }),
      ]
    }
    return this.tasks
  }

  get [`get/${TaskActions.GET_TASKS}`]() {
    return this.tasks
  }

  get [`get/${TaskActions.GET_CLONE_TASK_LIST}`]() {
    return this.cloneTaskList
  }

  get [`get/${TaskActions.GET_TASK}`]() {
    return this.task
  }

  get [`get/${TaskActions.GET_MAX_TASK_NAME_LENGTH}`]() {
    return MAX_TASK_NAME_LENGTH
  }

  get [`get/${TaskActions.TASK_LIST_WAS_EDITED}`]() {
    return !this.compareTaskLists(this.cloneTaskList, this.tasks)
  }

  get [`get/${TaskActions.GET_REMOVED_TASK}`]() {
    return this.removedTask
  }

  get [`get/${TaskActions.SET_MODAL_TYPE_FOR_CREATING_NEW_TASK}`]() {
    return this.modalTypeForCreatingTask
  }

  compareTaskLists(firstTaskList, secondTaskList) {
    return isEqual(this.updatedSimpleDiffTasks(firstTaskList), this.updatedSimpleDiffTasks(secondTaskList))
  }

  updatedSimpleDiffTasks(arr) {
    return cloneDeep(arr).map((e) => {
      return e.simpleDiff
    })
  }

  getNewOrder() {
    const lastTask = this.tasks.at(-1)
    const lastTaskOrder = lastTask?.order ?? 0
    return lastTaskOrder + 1
  }

  @Mutation
  clearTasks() {
    this.tasks = []
  }

  @Mutation
  setTasks(tasks: Task[]) {
    this.tasks = cloneDeep(tasks).sort(sortTask)
  }

  @Mutation
  setTaskListClone(tasks: Task[]) {
    this.cloneTaskList = cloneDeep(tasks).sort(sortTask)
  }

  @Mutation
  setTaskInList(task: Task) {
    this.tasks = [
      ...this.tasks,
      task,
    ].sort(sortTask)
  }

  @Mutation
  setTask(task: Task) {
    this.task = task
  }

  @Mutation
  resetRemovedTask() {
    this.removedTask = null
  }

  @Mutation
  modifyTaskOrderAfterSetAllTasks() {
    if (!this.task) return
    Object.assign(this.task, { order: this.getNewOrder() })
  }

  @Mutation
  removeTaskFromList(index: number) {
    const [removedTask] = this.tasks.splice(index, 1)
    this.tasks = [...this.tasks].map((task, index) => {
      task.order = index + 1
      return task
    })
    this.removedTask = removedTask
  }

  @Mutation
  createNewTask() {
    this.task = Task.create({
      name: "",
      type: "DEV",
      order: this.getNewOrder(),
      json: "",
      text: "",
      html: "",
    })
  }

  @Mutation
  changeNewTask(payload: UpdateObjectValue<Task>) {
    Object.assign(this.task, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setModalTypeForCreatingTask(payload: ModalTypesForCratingTask) {
    this.modalTypeForCreatingTask = payload
  }

  @Action
  async [TaskActions.GET_TASKS]({ itemId, projectId, onlyGet } : {itemId: string, projectId?: string, onlyGet?: boolean}) {
    const data = await apiService.get<TasksWithItemStatus>(Endpoint.TASKS_LIST(projectId ?? this.projectId, itemId))
    return this.handleResponse<TasksWithItemStatus>(data, (data) => {
      if (!onlyGet) this.handleTasksResponse(data)
      return data
    })
  }

  @Action
  async [TaskActions.PUT_TASKS](payload: UpdateTaskList) {
    const { tasks, projectId, item, removedTask } = payload
    const itemId = item?.id ?? ""
    const data = await apiService.put<TasksWithItemStatus>(Endpoint.TASKS_LIST(projectId ?? this.projectId, itemId), tasks.filter(task => task.isNotEmpty).map(task => task.getJsonObj()))
    return this.handleResponse<TasksWithItemStatus>(data, (data) => {
      const tasksWithItemStatus = new TasksWithItemStatus(data)
      if (removedTask) {
        const taskTitle = cutTextByNumberOfSymbols({
          text: removedTask.name,
          numberOfSymbols: 16,
        })

        const alertContent: AlertContentItem[] = [
          {
            text: "Task ",
            type: "regular",
          },
          {
            text: `${taskTitle} `,
            type: "bold",
          },
          {
            text: $t("alert.deleted"),
            type: "regular",
          },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      } else {
        let toastMessage = ""
        switch (tasksWithItemStatus.status) {
          case "RE_OPEN":
            toastMessage = "was Re-Opened and moved into Work in Progress."
            break
          default:
            toastMessage = $t("alert.updated")
            break
        }

        const itemTitle = cutTextByNumberOfSymbols({
          text: item.title,
          numberOfSymbols: 16,
        })

        const alertContent: AlertContentItem[] = [
          {
            text: "Item ",
            type: "regular",
          },
          {
            text: `${itemTitle} `,
            type: "bold",
          },
          {
            text: toastMessage,
            type: "regular",
          },
        ]

        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
          uniqueType: "taskUpdates",
        })
      }

      this.handleTasksResponse(tasksWithItemStatus)
      return tasksWithItemStatus
    })
  }

  @Action
  async [TaskActions.PATCH_TASKS](payload: PatchTaskList) {
    const { item, projectId, taskIdsAndEstimation } = payload
    const itemId = item?.id ?? ""
    const data = await apiService.patch<TasksWithItemStatus>(Endpoint.TASKS_ESTIMATION(projectId ?? this.projectId, itemId), taskIdsAndEstimation)
    return this.handleResponse<TasksWithItemStatus>(data, (data) => {
      const tasksWithItemStatus = new TasksWithItemStatus(data)
      const itemTitle = cutTextByNumberOfSymbols({
        text: item.title,
        numberOfSymbols: 16,
      })
      const alertContent: AlertContentItem[] = [
        {
          text: "Item ",
          type: "regular",
        },
        {
          text: `${itemTitle} `,
          type: "bold",
        },
        {
          text: $t("alert.updated"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
        uniqueType: `itemEstimation_${item.sequenceNumber}`,
      })
      itemModule[ItemActions.UPDATE_ITEM_STATUS_IN_LIST]({
        status: tasksWithItemStatus.status,
        itemId: item?.id ?? "",
      })
      return tasksWithItemStatus
    })
  }

  @Action
  async [TaskActions.RESET_TASKS]() {
    return new Promise((resolve) => {
      this.clearTasks()
      this.setTaskListClone(this.tasks)
      resolve(true)
    })
  }

  @Action
  [TaskActions.UPDATE_TASKS](tasks: Task[]) {
    this.setTasks(tasks)
  }

  @Action
  [TaskActions.ADD_TASK_IN_LIST](task: Task) {
    this.setTaskInList(task)
  }

  @Action
  async [TaskActions.CREATE_NEW_TASK]() {
    this.createNewTask()
  }

  @Action
  async [TaskActions.CHANGE_NEW_TASK](payload: UpdateObjectValue<Task>) {
    this.changeNewTask(payload)
  }

  @Action
  async [TaskActions.UPDATE_TASK_LIST_CLONE](tasks: Task[]) {
    this.setTaskListClone(tasks)
  }

  @Action
  async [TaskActions.RESET_CHANGES_IN_TASK_LIST]() {
    this.setTasks(this.cloneTaskList)
  }

  @Action
  async [TaskActions.UPDATE_TASKS_WITH_CLONE](tasks: Task[]) {
    this.setTasks(tasks)
    this.setTaskListClone(tasks)
  }

  @Action
  async [TaskActions.REMOVE_TASK_FROM_LIST](index: number) {
    this.removeTaskFromList(index)
  }

  @Action
  async [TaskActions.SET_MODAL_TYPE_FOR_CREATING_NEW_TASK](payload: ModalTypesForCratingTask) {
    this.setModalTypeForCreatingTask(payload)
  }

  @Action
  async [TaskActions.PUT_TASKS_WITH_CHANGES_CHECKING](payload: UpdateTaskList) {
    if (this[`get/${TaskActions.TASK_LIST_WAS_EDITED}`]) {
      await this[TaskActions.PUT_TASKS](payload)
    }
  }

  handleTasksResponse(data: TasksWithItemStatus) {
    const content: Task[] = data.tasks.map(task => Task.create(task))
    this.setTasks(content)
    this.setTaskListClone(content)
    this.resetRemovedTask()
    this.modifyTaskOrderAfterSetAllTasks()
    const estimation = data.estimation ?? Task.getItemEstimation(content)
    itemModule[ItemActions.UPDATE_ITEM_VALUES]({
      payload: [
        {
          id: "status",
          value: data.status,
        },
        {
          id: "estimation",
          value: estimation,
        },
        {
          id: "statusParsed",
          value: Status.createById({
            id: data.status ?? "NOT_STARTED",
          }),
        },
      ] as any,
      updateClone: true,
    })
  }
}

export const taskModule = new TaskModule({ store, name: Modules.TASK })
