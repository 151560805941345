<template>
  <CustomModal
    id="avatar-upload"
    modalClass="custom-modal_margin-custom"
    @onOpened="opened"
  >
    <template #header>
      <h2>Upload Avatar</h2>
    </template>

    <template #body>
      <div class="custom-cropper">
        <img
          ref="editImage"
          :src="url"
          class="custom-cropper__image"
        >
      </div>
    </template>

    <template #footer>
      <ControlButtons
        @save="onSave"
        @cancel="onCancel"
      />
    </template>
  </CustomModal>
</template>

<script lang="ts">
import { Options } from "vue-class-component"

import { Prop, Mixins } from "vue-property-decorator"
import { CropperInit } from "@/mixins/cropperInit"
import CustomModal from "@/components/Modal/index.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
@Options({
  name: "AvatarUploadModal",
  components: {
    CustomModal,
    ControlButtons,
  },
})

export default class AvatarUploadModal extends Mixins(CropperInit) {
  @Prop(String) readonly url!: string

  opened() {
    this.onOpen()
  }
}
</script>
