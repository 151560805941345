export const debounceWithThrottle = (func, delay) => {
  let timeoutId

  return function (...args) {
    clearTimeout(timeoutId)
    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const result = await func(...args)
          resolve(result)
        } catch (error) {
          reject(error)
        }
      }, delay)
    })
  }
}
