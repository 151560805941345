import { formatDate } from "@/utils/dateUtil"
import { User } from "./User"
import { $t } from "@/plugins/i18n"

export interface ShortAccount {
  id: string,
  name: string,
  urlName: string
}

interface AccountProps {
  id: string;
  name: string;
  picture?: {
    pictureUrl?: string
  }
  owner?: User
  paymentSubscription?: string
  stats?: AccountStats
  createdDate?: Date

  contactInformation?: AccountContactInformation | null
  urlName: string
  enabled: boolean
}

export interface AccountContactInformation {
  billingAddress: string
  phoneNumber: string
  contactEmail: string
}

export class AccountStats {
  projects: number
  teams: number
  users: number

  constructor(props?) {
    this.projects = props?.projects ?? 0
    this.teams = props?.teams ?? 0
    this.users = props?.users ?? 0
  }
}

export interface AccountPictureUpdatePayload {
  accountId: string;
  file: File;
}

export interface AccountContactInformationPayload {
  accountId: string;
  information: AccountContactInformation;
  isSuperAdmin: boolean;
}

export class Account {
  id: string
  name: string
  picture: {
    pictureUrl?: string
  }
  owner?: User
  paymentSubscription?: string
  createdDate?: Date
  enabled: boolean

  stats: AccountStats

  contactInformation: AccountContactInformation | null
  urlName: string
  constructor(props: AccountProps) {
    this.set(props)
  }

  set(props: AccountProps) {
    this.setDefaultData()

    if (!props) return

    this.id = props.id ?? ""
    this.name = props.name ?? ""
    this.picture = props.picture ?? {}
    this.owner = User.create(props.owner) ?? undefined
    this.paymentSubscription = props.paymentSubscription ?? ""
    this.stats = new AccountStats(props?.stats)
    this.contactInformation = props.contactInformation ?? null
    this.createdDate = props.createdDate ?? undefined
    this.urlName = props.urlName ?? ""
    this.enabled = props.enabled ?? true
  }

  setDefaultData() {
    this.id = ""
    this.name = ""
    this.picture = {}
    this.owner = undefined
    this.paymentSubscription = ""
    this.stats = new AccountStats()
    this.contactInformation = null
    this.createdDate = undefined
    this.urlName = ""
    this.enabled = true
  }

  getJsonObj() {
    return {
      id: this.id,
      name: this.name,
      ownerId: this.owner?.id ?? "",
      urlName: this.urlName,
    }
  }

  static create(props: AccountProps) {
    return new Account(props)
  }

  get accountName() {
    return `${this.name} Organization`
  }

  get creationDateString() {
    if (!this.createdDate) return $t("none")
    return formatDate(new Date(this.createdDate), "LLL dd, yyyy")
  }
}
