export default function eventKey(event: KeyboardEvent, checkKey?: string) {
  return {
    withShiftKey: event.shiftKey && event.key === checkKey,
    withCtrlKey: event.ctrlKey && event.key === checkKey,
    withAltKey: event.altKey && event.key === checkKey,
    withMetaKey: event.metaKey && event.key === checkKey,
    shiftTabKey: event.shiftKey && event.key === "Tab",

    tabKey: event.key === "Tab",
    enterKey: event.key === "Enter",
    escKey: event.key === "Escape",
    arrowDownKey: event.key === "ArrowDown",
    arrowUpKey: event.key === "ArrowUp",
    arrowLeftKey: event.key === "ArrowLeft",
    arrowRightKey: event.key === "ArrowRight",

  }
}
