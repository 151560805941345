import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  await store.dispatch(Actions.RESET_SPRINT)
  const currentSprintId = store.getters[Getters.GET_CURRENT_SPRINT_ID]
  if (!currentSprintId) {
    return next()
  }
  const currentSprint = await store.dispatch(Actions.GET_SPRINT, {
    sprintId: currentSprintId,
  })
  // Check if the current sprint is neither started nor not started
  if (currentSprint?.id && !currentSprint.isStarted && !currentSprint.isNotStarted) {
    // Redirect to the SprintReview if the current sprint is completed or halted
    return next({
      name: "SprintReview",
      params: to.params,
    })
  }
  return next()
}

