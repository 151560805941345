import { formatDate, parseDate } from "@/utils/dateUtil"
import { addMonths } from "date-fns"
import { Base } from "./Base"
import { Epic } from "./Epic"

export type MilestoneStatus = "COMPLETED" | "ACTIVE" | "COMING";

export const renderMilestoneName = (letter: string) => `Milestone ${letter}`
export interface MilestoneAddEpic {
  milestoneId: string,
  epicId: string,
  item?: Epic
}

export interface MilestoneProps {
  endDate: Date;
  newMilestone?: boolean
  title?: string
  epics: Epic[]
  startDate: Date
  status?: MilestoneStatus
  goalId?: string
}

export interface MilestoneDTO {
  id?: string
  title: string
  endDate: string
  startDate: string
  epics: Epic[]
  notEstimatedItemCount?: number
  totalStoryPoints?: number
  newMilestone?: boolean
  status: MilestoneStatus
}

export interface MilestonePreview {
  id: string
  title: string
  startDate: string
  endDate: string
  chartInfo: any
}

export class Milestone extends Base<Milestone> implements MilestoneProps {
  id?: string
  title?: string
  _title: string
  endDate: Date
  startDate: Date
  epics: Epic[]
  newMilestone?: boolean
  notEstimatedItemCount?: number
  totalStoryPoints?: number
  status?: MilestoneStatus
  goalId?: string

  constructor(props: MilestoneProps) {
    super()
    this.setDefaultData()
    const {
      endDate,
      title,
      newMilestone,
      epics,
      startDate,
      status,
      goalId,
    } = props
    this.title = title
    this.endDate = endDate
    this.epics = epics
    this.newMilestone = newMilestone
    this.startDate = startDate
    this.status = status ?? "COMING"
    this.goalId = goalId
  }
  static create(milestoneDTO: MilestoneDTO): Milestone {
    const {
      id,
      title,
      epics,
      endDate,
      startDate,
      notEstimatedItemCount,
      totalStoryPoints,
      status,
    } = milestoneDTO
    const milestone = new Milestone({
      title,
      endDate: parseDate(endDate),
      epics: [],
      startDate: parseDate(startDate),
    })
    milestone.id = id
    milestone.title = renderMilestoneName(title)
    milestone._title = title
    milestone.epics = epics ? epics.map(epic => Epic.create(epic)).sort((a, b) => a.milestonePriority - b.milestonePriority) : []
    milestone.newMilestone = false
    milestone.notEstimatedItemCount = notEstimatedItemCount
    milestone.totalStoryPoints = totalStoryPoints
    milestone.startDate = parseDate(startDate)
    milestone.status = status
    return milestone
  }

  setDefaultData() {
    this.title = "",
    this.endDate = new Date()
    this.startDate = new Date()
  }

  getJsonObj(): { [key in keyof Milestone]? } {
    return {
      title: this.title,
      endDate: formatDate(this.endDate, "yyyy-MM-dd"),
      startDate: formatDate(this.startDate, "yyyy-MM-dd"),
      epics: this.epics.map(e => e.id),
      goalId: this.goalId,
    }
  }
  static createDefault(): Milestone {
    return new Milestone({
      title: "Milestone A",
      endDate: addMonths(new Date(), 1),
      startDate: new Date(),
      epics: [],
      newMilestone: true,
      status: "COMING",
    })
  }

  get isCompleted() {
    return this.status === "COMPLETED"
  }

  get isActive() {
    return this.status === "ACTIVE"
  }

  get isComing() {
    return this.status === "COMING"
  }

  get simpleDiff() {
    if (this.id) return ""
    return {
      endDate: this.endDate,
      startDate: this.startDate,
      epics: this.epics,
    }
  }
}

export class ChangeMilestoneDate {
  milestone: Milestone
  startDate: Date = new Date()
  endDate: Date = new Date()
  milestoneId = ""
  constructor(props?: Partial<ChangeMilestoneDate>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  getJsonObj() {
    const data = {
      milestoneId: this.milestone?.id ?? "",
      endDate: formatDate(this.endDate, "yyyy-MM-dd"),
      startDate: formatDate(this.startDate, "yyyy-MM-dd"),
    }
    return data
  }
}

