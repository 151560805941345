import { VUFile } from "@/models"
import Cropper from "cropperjs"
import "cropperjs/dist/cropper.min.css"
import { Options, Vue } from "vue-class-component"
import { Ref, Prop } from "vue-property-decorator"

@Options({
  name: "CropperInit",
})
export class CropperInit extends Vue {
  @Ref("editImage") readonly editImage!: HTMLImageElement
  @Prop(Array) readonly img: VUFile[]
  @Prop(String) readonly mode: string

  cropper!: Cropper

  onOpen() {
    this.cropperInit()
  }

  cropperInit() {
    const cropper = new Cropper(this.editImage, {
      aspectRatio: 1 / 1,
      viewMode: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      center: false,
      guides: false,
      dragMode: "move",
      highlight: false,
      toggleDragModeOnDblclick: false,
      minCropBoxWidth: 100,
      minCropBoxHeight: 100,
      autoCropArea: 1,
    })

    this.cropper = cropper
  }

  onSave() {
    const oldFile = this.img[0]
    const binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type, 0.1).split(",")[1])
    const arr = new Uint8Array(binStr.length)
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    const file = new File([arr], oldFile.name, { type: oldFile.type })

    this.$emit("save", {
      id: oldFile.id,
      file,
    })

    this.destroy()
  }

  onCancel() {
    this.$emit("hide")
    this.destroy()
  }

  destroy() {
    this.cropperDestroy()
  }

  cropperDestroy() {
    if (this.cropper) this.cropper.destroy()
    this.hide()
  }

  hide() {
    if (this.mode === "modal") {
      this.$modal.hide("avatar-upload")
    }
  }
}
