import { ItemStatus } from "@/models"
import { Filter } from "../Pageable"
import { ItemSeverityLevel, ItemType } from "../Item"

export class FilterBacklogMetrics extends Filter {
  text?: string
  sequenceNumberPrefix?: string
  itemTypes?: ItemType[]
  storyStatuses?: ItemStatus[]
  excludeStoryStatuses?: ItemStatus[]
  ownerIds?: string[]
  teamIds?: string[]
  hasStoryPoints?: boolean
  hasEstimation?: boolean
  hasBacklogPriority?: boolean
  sprintIds?: string[]
  inSprint?: boolean
  epicIds?: string[]
  inEpic?: boolean
  startCreatedDateTime?: string
  endCreatedDateTime?: string
  hasActiveStoryPoker?: boolean
  includeTeamLessSupportCases?: boolean
  severityLevel?: ItemSeverityLevel[]
  hasEstimationOrStoryPoints?: boolean
  completed?: boolean
  inActiveSprint?: boolean
  inNotActiveSprint?: boolean
  teamId?: string

  constructor(props?: Partial<FilterBacklogMetrics>) {
    super(props as FilterBacklogMetrics)
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field] ?? this[field]
    })
  }

  getJsonObj() {
    const data = {
      text: this.text,
      sequenceNumberPrefix: this.sequenceNumberPrefix,
      itemTypes: this.itemTypes,
      storyStatuses: this.storyStatuses,
      excludeStoryStatuses: this.excludeStoryStatuses,
      ownerIds: this.ownerIds,
      teamIds: this.teamIds,
      hasStoryPoints: this.hasStoryPoints,
      hasEstimation: this.hasEstimation,
      hasBacklogPriority: this.hasBacklogPriority,
      sprintIds: this.sprintIds,
      inSprint: this.inSprint,
      epicIds: this.epicIds,
      inEpic: this.inEpic,
      startCreatedDateTime: this.startCreatedDateTime,
      endCreatedDateTime: this.endCreatedDateTime,
      hasActiveStoryPoker: this.hasActiveStoryPoker,
      includeTeamLessSupportCases: this.includeTeamLessSupportCases,
      severityLevel: this.severityLevel,
      hasEstimationOrStoryPoints: this.hasEstimationOrStoryPoints,
      completed: this.completed,
      inActiveSprint: this.inActiveSprint,
      inNotActiveSprint: this.inNotActiveSprint,
      teamId: this.teamId,
    }

    return super.getJsonObj({ params: data, withoutPagination: true })
  }

  static createBugMetricsFilterWithTeamId(teamId: string) {
    const props = {
      teamIds: [teamId],
      itemTypes: ["BUG"] as ItemType[],
      excludeStoryStatuses: ["DONE", "OBSOLETE"] as ItemStatus[],
      hasBacklogPriority: true,
    }
    return new FilterBacklogMetrics(props)
  }

  static createBacklogMetricsFilterWithTeamId(teamIds: string[]) {
    const props = {
      teamIds,
      excludeStoryStatuses: ["DONE", "OBSOLETE"] as ItemStatus[],
      hasBacklogPriority: true,
      includeTeamLessSupportCases: true,
      inSprint: false,
    }
    return new FilterBacklogMetrics(props)
  }

  static createBacklogMetricsFilterWithSprint(sprintIds: string[]) {
    const props = {
      excludeStoryStatuses: ["DONE", "OBSOLETE"] as ItemStatus[],
      hasBacklogPriority: true,
      includeTeamLessSupportCases: true,
      inSprint: true,
      sprintIds,
    }
    return new FilterBacklogMetrics(props)
  }
}
