<template>
  <button
    :class="{ 'is-active': isActive ? isActive() : null }"
    :title="title"
    :tabindex="-1"
    class="custom-editor__button heading-level heading-level-3"
    type="button"
    @click.prevent="action"
  >
    <icon
      :data="icon"
      width="1em"
      height="1em"
    />
  </button>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Icon } from "@yzfe/vue3-svgicon"
import { CallbackFunction } from "@/models"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "EditorMenuItem",
})
export default class EditorMenuItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly icon: Icon
  @Prop({
    type: String,
    required: true,
  }) readonly title: string
  @Prop({
    type: Function,
    required: true,
  }) action: CallbackFunction
  @Prop({
    type: Function,
    default: null,
  }) isActive: CallbackFunction
}
</script>
