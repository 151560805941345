import { Sort } from "./Sort"

export interface FilterProps {
  page: number;
  size: number;
  sort: Sort[];
  empty?: boolean;
  defaultSize?: number;
}

export class Filter {
  page: number
  size: number
  sort: Sort[]
  empty?: boolean
  defaultSize: number



  constructor(props: FilterProps) {
    this.defaultSize = 20
    this.size = props?.size ?? this.defaultSize
    this.page = props?.page ?? 1
    this.sort = props?.sort ?? []
    this.empty = props?.empty
  }


  addSort(field: string, isAsc: boolean) {
    this.sort = [new Sort(field, isAsc)]
  }

  removeSort() {
    this.sort = []
  }

  static createDefault(): Filter {
    return new Filter({
      size: 20,
      page: 1,
      sort: [],
    })
  }

  static create(props: FilterProps): Filter {
    return new Filter(props)
  }

  getJsonObj({ params, withoutPagination } : { params?: any, withoutPagination?: boolean } = {}) {
    let data = { ...params }
    if (!withoutPagination) {
      data = {
        ...data,
        size: this.size,
        page: this.page - 1,
        sort: this.sort.map(sort => sort.getString()),
      }
    } else {
      delete data.size
      delete data.page
    }

    const result: URLSearchParams = new URLSearchParams()

    for (const item in data) {
      if (this.empty || data[item] !== "") {
        if (typeof data[item] !== "object") {
          if (item === "sort") {
            for (let i = 0; i < data[item].length; i++) {
              result.append(item, data[item][i])
            }
          } else if (data[item] !== undefined) {
            result.append(item, data[item])
          }
        } else {
          if (data[item] && Object.keys(data[item]).length) {
            for (let i = 0; i < data[item].length; i++) {
              result.append(item, data[item][i])
            }
          }
        }
      }
    }
    return result
  }
}
