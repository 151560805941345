<template>
  <div class="footer">
    <div
      id="footer-start"
      class="footer__content-start"
    >
      <BackButtonComponent
        v-if="!$route.meta?.hideBackButtonInFooter"
        :key="$route.fullPath"
        class="footer__back-button"
      />
    </div>
    <div
      id="footer-center"
      class="footer__content-start"
    />
    <span
      class="footer__content-end"
    >
      <ToDoWidget
        v-if="accountId && !$route.meta?.hideToDoWidget"
        :key="keyByRoute"
      />

      <button
        v-if="!hideGptButton"
        @click="showChatModal"
      >
        <icon
          data="@icon/chat-gpt.svg"
          :fill="true"
          class="footer__chat-gpt-icon"
          color="#81889B"
          width="24px"
          height="24px"
        />
      </button>

      <DropdownTooltip
        iconSize="md"
        class="footer-navigation"
        :class="[{'navigation-rotate':opened}, {'navigation-tramor':!opened}]"
        :distance="16"
        :skidding="-208"
        :shown="showMenu"
        popperClass="footer-navigation_poper"
        placement="top-start"
        @onShowDropdown="toggleNavigation"
        @onHideDropdown="toggleNavigation"
      >
        <template #tooltip-label>
          <icon
            data="@icon/addMark.svg"
            :fill="false"
            color="#FFFFFF"
            width="1em"
            height="1em"
          />
        </template>
        <template #tooltip-content>
          <div
            v-for="(nav, index) in navigationList"
            :key="index"
            v-close-popper
            class="footer-navigation_link"
            :class="[{'navigation_link-remove':!nav.show}]"
          >
            <router-link
              v-if="nav.show"
              class="footer-navigation_link__item"
              :to="nav.to"
            >
              <BaseSubHeading
                color="accent-03"
                darkColor="accent-200"
                size="large"
                weight="400"
                className="footer-navigation_link-label"
              >
                {{ nav.label }}
              </BaseSubHeading>
              <BaseCaption
                color="monochrome-05"
                darkColor="monochrome-03"
                size="large"
              >
                {{ nav.help }}
              </BaseCaption>
            </router-link>
          </div>
        </template>
      </DropdownTooltip>
    </span>
  </div>
  <Teleport to="body">
    <ModalChatGPT />
    <MCreateEpic
      :id="project.name"
      @onCreateEpic="createEpic"
    />
  </Teleport>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import MCreateEpic from "@/components/Modal/MCreateEpic.vue"
import ModalChatGPT from "@/components/Modal/ModalChatGPT/ModalChatGPT.vue"
import { directive as ClickOutside } from "click-outside-vue3"
import { Getter, Action } from "s-vuex-class"
import { Getters, Actions } from "@/store"
import {
  Account,
  Epic,
  Project,
  Team,
  User,
} from "@/models"
import ToDoWidget from "@/components/ToDo/ToDoWidget.vue"
import isMacintosh from "@/helpers/checkMacintosh"
import { keys, KeyType } from "@/helpers/keys"
import BackButtonComponent from "@/components/ControlButtons/BackButtonComponent.vue"
import { hideElementByAccount } from "@/utils/helpers"

@Options({
  name: "Footer",
  components: {
    DropdownTooltip,
    MCreateEpic,
    ToDoWidget,
    BackButtonComponent,
    ModalChatGPT,
  },
  directives: {
    ClickOutside,
  },
})
export default class Footer extends Vue {
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User
  @Getter(Getters.GET_CURRENT_PROJECT_ID) readonly projectId: string
  @Getter(Getters.GET_PROJECT) readonly project: Project
  @Getter(Getters.GET_SESSION_TEAM) selectedTeam: Team
  @Getter(Getters.GET_ACCOUNT) readonly account: Account
  @Action(Actions.SET_PROJECTS) setProjects: (projects: Project[]) => void
  @Action(Actions.POST_EPIC) postEpic: (epic: { name: string }) => Epic
  @Getter(Getters.GET_ACCOUNT_ID) accountId: string

  opened = false
  showMenu = false
  showItemsBasedOnParams = {
    NewProject: ["accountId"],
    NewMilestone: ["projectId"],
    NewEpic: ["projectId"],
    NewSprint: ["projectId"],
    NewItem: ["projectId"],
  }

  get navigationList() {
    const queryForCrateItem = { ...this.$route.query }
    const queryForCreateSprint = this.selectedTeam ? {
      t: this.selectedTeam.urlName,
    } : {}
    delete queryForCrateItem.item
    const newItem = this.$route.meta.createStoryInBacklog ? {
      query: {
        ...this.$route.query,
        i: "create",
      },
    } : {
      name: "CreateItem",
      params: {
        accountId: this.accountUrlId,
        projectId: this.$route.params?.projectId ?? "",
        teamId: this.$route.params?.teamId ?? "",
      },
    }

    return [
      {
        id: "newAccount",
        label: "New Account (TBD)",
        help: "",
        linkTo: "",
        show: false,
        to: {
          name: "",
          params: {},
        },
      },
      {
        id: "newProject",
        label: "New Project",
        help: keys(KeyType.PROJECT),
        linkTo: "NewProject",
        show: false,
        keyCode: 80,
        to: {
          name: "create-project",
          params: {
            accountId: this.accountUrlId,
          },
        },
      },
      {
        id: "newMilestone",
        label: "New Milestone",
        help: keys(KeyType.MILESTONE),
        linkTo: "NewMilestone",
        show: false,
        keyCode: 77,
        to: {
          name: "Milestones",
          params: {
            projectId: this.project.urlName,
            newMilestone: "true",
            accountId: this.accountUrlId,
          },
        },
      },
      {
        id: "newEpic",
        label: "New Epic",
        help: keys(KeyType.EPIC),
        linkTo: "NewEpic",
        show: false,
        keyCode: 69,
        to: {
          name: "epics",
          params: {
            projectId: this.project.urlName,
            accountId: this.accountUrlId,
          },
          query: {
            newEpic: "true",
          },
        },
      },
      {
        id: "newSprint",
        label: "New Sprint",
        help: keys(KeyType.SPRINT),
        linkTo: "NewSprint",
        show: false,
        keyCode: 83,
        to: {
          name: "create-sprint",
          params: {
            accountId: this.accountUrlId,
          },
          query: queryForCreateSprint,
        },
      },
      {
        id: "newItem",
        label: "New Item",
        help: keys(KeyType.ITEM),
        linkTo: "NewItem",
        show: false,
        keyCode: 73,
        to: newItem,
      },
    ]
  }

  get hideGptButton() {
    return hideElementByAccount(this.currentUser)
  }

  get accountUrlId(): string {
    if (this.$route.params?.accountId) {
      return this.$route.params?.accountId as string
    } else {
      return this.account ? this.account.urlName : ""
    }
  }

  get isMacintosh():boolean {
    return isMacintosh()
  }

  get keyByRoute() {
    return this.$route?.meta?.updateComponentByRoute ? decodeURI(this.$route.path).replace(/\s/g, "") : ""
  }

  mounted() {
    document.addEventListener("keydown", this.onKeyDown)
    document.addEventListener("keyup", this.onKeyDown)
  }

  beforeUnmount() {
    document.removeEventListener("keydown", this.onKeyDown)
  }

  onKeyDown(e) {
    this.watchOpened()
    let checkSystemKey = this.isMacintosh ? e.metaKey : e.ctrlKey
    let navTo = this.navigationList.find(i => i.keyCode === e.keyCode)
    if (e.shiftKey && checkSystemKey && navTo?.keyCode) {
      e.preventDefault()

      if (navTo?.show) {
        this.$router.push(navTo.to)
      }
    }

    if (e.shiftKey && checkSystemKey) {
      this.showMenu = true
    } else {
      this.showMenu = false
    }
  }

  toggleNavigation() {
    this.opened = !this.opened
  }

  showChatModal() {
    this.$modal.show("modal-chat-gpt")
  }

  async createEpic(name: string) {
    const epic = await this.postEpic({ name })
    await this.$router.push({
      name: "epic",
      params: {
        epicId: epic.sequenceNumber,
      },
    })
  }

  @Watch("opened")
  watchOpened() {
    const params = Object.keys(this.$route.params)
    const query = Object.keys(this.$route.query)
    this.navigationList.forEach((item) => {
      const paramsCheck = this.showItemsBasedOnParams[item.linkTo]
      if (paramsCheck) {
        item.show = paramsCheck.every(param => params.includes(param) || query.includes(param))
      }
      if (item.id === "newAccount") {
        item.show = this.currentUser.superAdmin
      }
    })
  }
}
</script>
