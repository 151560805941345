<template>
  <ToDoWidgetButton
    id="toDoWidgetButton"
    :count="openedCount"
    @onClick="onToggleWidget"
  />
  <Teleport
    :disabled="!isTeleport"
    to="#toDoWidget"
  >
    <Transition
      @beforeEnter="beforeEnter"
      @beforeLeave="beforeLeave"
    >
      <div
        v-if="isShowWidget"
        ref="toDoWidgetEl"
        class="to-do-widget content is-60-40"
      >
        <div>
          &nbsp;
        </div>
        <Transition
          @beforeEnter="beforeEnterToDo"
        >
          <ToDo
            v-if="showToDo"
            :showCloseButton="true"
            @onHideToDoWidget="onHideToDo"
            @mousedown.stop
          />
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import ToDoWidgetButton from "@/components/ToDo/ToDoWidgetButton.vue"
import ToDo from "@/components/ToDo/index.vue"
import { gsap } from "gsap"
import { FilterToDo } from "@/models/Filter"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { emitterDeleteByKey, emitterOn } from "@/plugins/mitt-emitter"

const components = {
  ToDo,
  ToDoWidgetButton,
}

@Options({
  name: "ToDoWidget",
  components,
})
export default class ToDoWidget extends Vue {
  @Getter(Getters.GET_OPENED_TODO_COUNT) readonly openedCount: number

  @Action(Actions.GET_OPENED_TODO_COUNT) getOpenedToDoCount: () => void
  @Action(Actions.SET_TODO) setToDo: (toDo: ToDo) => void
  @Action(Actions.RESET_TO_DO) resetToDo: () => void

  filterToDoCount = new FilterToDo()
  isShowWidget = false
  isTeleport = false
  showToDo = false
  backGroundAnimation = gsap.timeline({
    paused: true,
  })
  toDoAnimation = gsap.timeline({
    paused: true,
  })
  layoutElement: HTMLElement | null = null
  created() {
    this.getOpenedToDoCount()
    emitterOn("addToDo", this.onAddToDo)
    this.layoutElement = document.getElementById("layout")
  }

  onAddToDo(toDo: ToDo) {
    this.setToDo(toDo)
    this.onToggleWidget()
  }

  onToggleWidget() {
    if (!this.isShowWidget) {
      this.onShowWidgetUpdate(true)
    } else {
      this.onHideToDo()
    }
  }

  onShowWidgetUpdate(value) {
    this.isTeleport = value
    this.$nextTick(() => {
      this.isShowWidget = value
    })
  }

  beforeEnter(toDoWidgetEl) {
    this.backGroundAnimation = gsap.timeline({
      paused: true,
    }).to(toDoWidgetEl, {
      background: "rgba(129, 136, 155, 0)",
      duration: 0,
    }).to(toDoWidgetEl, {
      background: "rgba(129, 136, 155, 0.2)",
      duration: 0.3,
    }).play().eventCallback("onComplete", () => {
      this.showToDo = true
      if (this.layoutElement) {
        this.layoutElement.addEventListener("mousedown", this.onHideToDo)
      }
    })
  }

  beforeEnterToDo(toDoWidgetEl) {
    this.toDoAnimation = gsap.timeline({
      paused: true,
    }).to(toDoWidgetEl, {
      duration: 0,
      translateY: "100%",
      opacity: 0,
    }, 0).to(toDoWidgetEl, {
      opacity: 1,
      duration: 0,
    }).to(toDoWidgetEl, {
      duration: 0.3,
      translateY: 0,
    }).play()
  }

  beforeLeave() {
    this.backGroundAnimation.reverse(0)
  }

  onHideToDo(e?) {
    this.toDoAnimation.reverse(0).eventCallback("onReverseComplete", () => {
      this.onShowWidgetUpdate(false)
      this.showToDo = false
      if (this.layoutElement) {
        this.layoutElement.removeEventListener("mousedown", this.onHideToDo)
      }
      if (e?.type !== "mousedown") {
        this.resetToDo()
      }
    })
  }

  beforeUnmount() {
    emitterDeleteByKey("addToDo")
  }
}
</script>
