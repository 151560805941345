<template>
  <div class="avatar-upload">
    <div class="avatar-upload__img">
      <Avatar
        :src="files.length && !edit ? url : src || null"
        :size="size"
        class="team__avatar"
        type="teamsGroup"
      />

      <div
        v-if="state"
        class="avatar-upload__controls"
      >
        <file-upload
          ref="avatarUpload"
          v-model="files"
          extensions="jpg,jpeg,png,webp"
          accept="image/png,image/jpeg,image/webp"
          name="avatar"
          class="avatar-upload__control avatar-upload__file"
          :drop="true"
          :multiple="false"
          @inputFilter="inputFilter"
          @inputFile="inputFile"
        >
          <icon
            data="@icon/add.svg"
            :fill="false"
            color="#ffffff"
            width="16"
            height="16"
          />
        </file-upload>
      </div>
    </div>
    <div
      v-if="state"
      class="avatar-delete__file"
    >
      <button
        class="avatar-upload__control"
        type="button"
        :disabled="!isUrlExist"
        @click="onClean"
      >
        <icon
          data="@icon/trash.svg"
          :fill="false"
          color="#7D92A7"
          width="18"
          height="18"
        />
      </button>
    </div>

    <component
      :is="component"
      v-if="isModeModal ? isModeModal : edit"
      :img="files"
      :url="url"
      :mode="mode"
      @hide="onHide"
      @save="onSave"
    />

    <span
      v-if="error"
      class="avatar-upload__error"
    >{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { Prop, Watch, Ref } from "vue-property-decorator"

import Avatar from "../index.vue"
import { VUFile } from "@/models"
import { Options, Vue } from "vue-class-component"
import AvatarUploadSelf from "./AvatarUploadSelf.vue"
import AvatarUploadModal from "./AvatarUploadModal.vue"


@Options({
  name: "AvatarUpload",
  components: {
    Avatar,
    AvatarUploadModal,
    AvatarUploadSelf,
  },
})
export default class AvatarUpload extends Vue {
  @Prop({ default: "", type: String }) readonly src!: string | null
  @Prop({ default: "", type: String }) readonly id!: string
  @Prop({
    default: "xl",
    type: String,
  }) readonly size!: string
  @Prop({ default: false, type: Boolean }) readonly state!: boolean
  @Prop({ default: "", type: String }) readonly gap!: string
  @Prop({
    required: true,
    validator(mode: string) {
      return ["modal", "self"].includes(mode)
    },
  }) readonly mode!: string
  @Ref("avatarUpload") readonly upload

  files: VUFile[] = []
  edit = false
  show = false
  url: string | null = null
  maxFileSize = 8
  error = false
  errorMessage: string

  mounted() {
    this.url = this.src
  }

  get isModeModal(): boolean {
    return this.mode === "modal"
  }

  get isUrlExist(): boolean {
    return !!this.url
  }

  private get component() {
    switch (this.mode) {
      case "modal":
        return AvatarUploadModal
      case "self":
        return AvatarUploadSelf
      default:
        return null
    }
  }

  @Watch("state")
  onStateChanged(): void {
    this.show = false
  }

  openModal() {
    this.$modal.show("avatar-upload")
  }

  onHide() {
    this.onClean()
  }

  onSave({ id, file }: { id: string, file: VUFile }) {
    this.editSave({
      id,
      file,
    })
  }

  editSave({ id, file }: {id: string, file: VUFile}) {
    this.upload.update(id, {
      file,
      type: file.type,
      size: file.size,
      active: true,
    })
    this.show = true
    this.edit = false

    this.$emit("avatar", {
      value: this.files[0].file,
      id: this.id,
    })
  }

  inputFile(newFile: VUFile, oldFile: VUFile) {
    this.error = false

    if (newFile && !oldFile) {
      this.$nextTick(() => {
        this.edit = true
        this.show = false

        this.editPhoto()
      })
    }
    if (!newFile && oldFile) {
      this.edit = false
    }
  }

  inputFilter(newFile: VUFile, oldFile: VUFile, prevent: (prevent?: boolean) => boolean) {
    if (newFile && !oldFile) {
      if (!/\.(jpg|jpeg|png|webp)$/i.test(newFile.name)) {
        return prevent()
      }
    }

    if (newFile.size >= 0 && newFile.size > this.maxFileSize * 1024 * 1024) {
      this.showEror("size")

      return prevent()
    }

    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      this.url = ""

      let URL = window.URL || window.webkitURL

      if (URL && URL.createObjectURL) {
        this.url = URL.createObjectURL(newFile.file as unknown as Blob)
      }
    }
  }

  editPhoto() {
    if (this.isModeModal) this.openModal()
  }

  onClean() {
    this.files = []
    this.url = ""
    this.upload.clear()

    this.$emit("avatar", {
      value: "",
      id: this.id,
    })
  }

  showEror(type: string) {
    this.error = true

    switch (type) {
      case "size":
        this.errorMessage = `The maximum file size for the profile picture is ${this.maxFileSize}MB.`
        break

      default:
        this.errorMessage = ""
        break
    }
  }
}
</script>
