import { HttpError } from "@/models/Error"

export default function setError(error: HttpError): Error {
  let e: Error

  if (error.response) {
    e = new HttpError(error)
  } else {
    e = new Error(error.message)
  }

  return e
}
