import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/x-circle.svg'


const _hoisted_1 = { class: "dropdown-basic__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.parent), {
    ref: `multiselect-parent-${_ctx.id}`,
    type: _ctx.isButton ? 'button' : null,
    tabindex: _ctx.tabindex,
    class: "dropdown-basic",
    onClick: _withModifiers(_ctx.onEnter, ["prevent","stop"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Multiselect, {
        id: _ctx.id,
        ref: `multiselect-${_ctx.id}`,
        class: _normalizeClass([[`is-position-${_ctx.optionPosition}`], ""]),
        modelValue: _ctx.value,
        options: _ctx.unselectedOptions,
        showLabels: false,
        closeOnSelect: _ctx.closeOnSelect,
        placeholder: _ctx.placeholder,
        searchable: _ctx.searchable,
        maxHeight: _ctx.maxHeight,
        trackBy: _ctx.trackBy,
        label: _ctx.label,
        tabindex: -1,
        hideSelected: _ctx.hideSelected,
        allowEmpty: _ctx.allowEmpty,
        multiple: _ctx.multiple,
        onOpen: _ctx.onOpen,
        onClose: _ctx.onClose,
        "onUpdate:modelValue": _ctx.onSelect,
        onSearchChange: _ctx.dropdownInput,
        onRemove: _ctx.removeOption,
        onKeydown: [
          _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.onSelectHighlightedByTab($event)), ["exact","stop"]), ["tab"])),
          _withKeys(_withModifiers(_ctx.onSelectHighlightedByEnter, ["stop"]), ["enter"])
        ]
      }, {
        noResult: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.noResult), 1)
        ]),
        noOptions: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.noOptions), 1)
        ]),
        singleLabel: _withCtx((props) => [
          _renderSlot(_ctx.$slots, "singleLabel", {
            option: props.option
          })
        ]),
        option: _withCtx((props) => [
          _renderSlot(_ctx.$slots, "option", {
            option: props.option
          })
        ]),
        placeholder: _withCtx(() => [
          _renderSlot(_ctx.$slots, "placeholder")
        ]),
        team: _withCtx((props) => [
          _renderSlot(_ctx.$slots, "team", {
            team: props.team
          })
        ]),
        _: 3
      }, 8, ["id", "class", "modelValue", "options", "closeOnSelect", "placeholder", "searchable", "maxHeight", "trackBy", "label", "hideSelected", "allowEmpty", "multiple", "onOpen", "onClose", "onUpdate:modelValue", "onSearchChange", "onRemove", "onKeydown"]),
      (_ctx.remove && _ctx.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "dropdown-basic__close",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove')))
          }, [
            _createVNode(_component_icon, {
              data: _imports_0,
              fill: false,
              width: "1em",
              height: "1em",
              color: "#7D92A7",
              class: ""
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["type", "tabindex", "onClick"]))
}