<template>
  <div
    class="progress-line__bg"
    :class="[bgClass]"
  >
    <span
      class="progress-line"
      :style="{ width: isMore ? '100%' : lineWidth + '%'}"
      :class="[`is-${color}`]"
    />
  </div>
</template>

<script lang="ts">
import { ProgressBarConfig } from "@/models"
import {
  ProgressBarBackgroundColor,
  ProgressBarColor,
} from "@/models/ProgressBar"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "ProgressLine",
})

export default class ProgressLine extends Vue {
  @Prop(Object) readonly desc: ProgressBarConfig
  @Prop({ default: "green" }) readonly color: ProgressBarColor
  @Prop({ default: "" }) readonly backgroundColor: ProgressBarBackgroundColor

  get bgClass() {
    if (!this.backgroundColor) return ""
    return `is-${this.backgroundColor}`
  }

  get lineWidth() {
    if (this.desc) {
      const { currentValue, maxValue } = this.desc
      const calculateValue = (+currentValue * 100) / +maxValue

      return calculateValue
    }
    return 0
  }

  get isMore() {
    const { currentValue, maxValue } = this.desc
    return currentValue > maxValue
  }
}
</script>
