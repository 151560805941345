import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "custom-editor__divider"
}
const _hoisted_2 = {
  key: 1,
  class: "custom-editor__divider_task"
}
const _hoisted_3 = {
  key: 2,
  class: "custom-editor__divider custom-editor__divider_lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_DropdownSingleLabel = _resolveComponent("DropdownSingleLabel")!
  const _component_EditorColorPicker = _resolveComponent("EditorColorPicker")!
  const _component_EditorUploadFiles = _resolveComponent("EditorUploadFiles")!
  const _component_EditorMenuItem = _resolveComponent("EditorMenuItem")!
  const _component_EditorModalLink = _resolveComponent("EditorModalLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (item.type === 'divider')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : (item.type === 'divider_task')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : (item.type === 'dividerLg')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : (item.type === 'dropdown')
                ? (_openBlock(), _createBlock(_component_DropdownSingleLabel, {
                    key: 3,
                    id: item.title,
                    options: item.options,
                    trackBy: "label",
                    label: "label",
                    class: "custom-editor__button",
                    tabindex: -1,
                    appendDropDownToBody: true,
                    onInput: _ctx.onDropdownInput
                  }, {
                    label: _withCtx(() => [
                      _createVNode(_component_icon, {
                        class: "heading-level heading-level-3",
                        data: item.icon,
                        width: "1em",
                        height: "1em"
                      }, null, 8, ["data"])
                    ]),
                    _: 2
                  }, 1032, ["id", "options", "onInput"]))
                : (item.type === 'fontColor')
                  ? (_openBlock(), _createBlock(_component_EditorColorPicker, _mergeProps({
                      key: 4,
                      ref_for: true
                    }, item), null, 16))
                  : (item.type === 'addFile')
                    ? (_openBlock(), _createBlock(_component_EditorUploadFiles, _mergeProps({
                        key: 5,
                        id: `addFile-${_ctx.id}`,
                        ref_for: true
                      }, item), null, 16, ["id"]))
                    : (_openBlock(), _createBlock(_component_EditorMenuItem, _mergeProps({
                        key: 6,
                        ref_for: true
                      }, item), null, 16))
      ], 64))
    }), 128)),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_EditorModalLink, {
        id: _ctx.id,
        onOnAction: _ctx.onModalLinkChange
      }, null, 8, ["id", "onOnAction"])
    ]))
  ]))
}