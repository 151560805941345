<template>
  <div
    v-click-outside="hide"
    class="notifications"
  >
    <button
      class="body-text body-text-large-400"
      :class="[{'clicked__button' : opened}]"
      @click="toggle"
    >
      <icon
        data="@icon/bell.svg"
        :fill="true"
        width="1.5em"
        height="1.5em"
      />
      <DotNotification
        v-if="isHasNewNotification"
        class="notifications__indicator"
      />
    </button>

    <div
      v-if="opened || importantOpened"
      class="notifications__box"
    >
      <BaseHeading
        level="3"
        color="monochrome-07"
        className="notifications__box_title"
      >
        Notifications
        <template v-if="isHasNewNotification && !importantOpened">
          ({{ count }})
        </template>
      </BaseHeading>
      <div class="notifications__box_content">
        <div class="notifications__list custom-scroll">
          <template v-if="importantOpened ? isImportantNotificationExist : isNotificationExist">
            <Observer
              :items="importantOpened ? importantNotification : notifications"
              :last="last"
              elementClass="notifications__item"
              class="notifications__content"
              @intersect="scroll"
            >
              <template #default="{ item }">
                <Component
                  :is="item.type"
                  :key="item.id"
                  :notification="item"
                />
              </template>
            </Observer>
          </template>
          <p v-else>
            No Notifications Found
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { directive as ClickOutside } from "click-outside-vue3"
import { Options, Vue } from "vue-class-component"

import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import { ServerResponse } from "@/models/ServerResponse"
import { FilterNotification } from "@/models/Filter"
import Observer from "@/components/Observer/index.vue"
import { ServerSendEventNotification, ResourceEvent } from "@/models"
import { Watch } from "vue-property-decorator"
import { Notification, NotificationType } from "@/models/Notification"
import DotNotification from "@/components/Dot/DotNotification.vue"

import TODO_ASSIGNMENT from "@/components/Notification/NotificationByType/TODO_ASSIGNMENT.vue"
import TODO_REMINDER from "@/components/Notification/NotificationByType/TODO_REMINDER.vue"
import UNPRIORITIZED_BACKLOG_ADD_ITEM from "@/components/Notification/NotificationByType/UNPRIORITIZED_BACKLOG_ADD_ITEM.vue"
import UPDATE_STORY_TIME_LEFT_REMINDER from "@/components/Notification/NotificationByType/UPDATE_STORY_TIME_LEFT_REMINDER.vue"
import SPRINT_RISK_ALERT from "@/components/Notification/NotificationByType/SPRINT_RISK_ALERT.vue"
import ITEM_UPDATE_STATUS from "@/components/Notification/NotificationByType/ITEM_UPDATE_STATUS.vue"
import ITEM_UPDATE_STORYPOINTS from "@/components/Notification/NotificationByType/ITEM_UPDATE_STORYPOINTS.vue"
import ITEM_UPDATE_SEVERITYLEVEL from "@/components/Notification/NotificationByType/ITEM_UPDATE_SEVERITYLEVEL.vue"
import ITEM_UPDATE_OWNER from "@/components/Notification/NotificationByType/ITEM_UPDATE_OWNER.vue"
import ITEM_UPDATE_DESCRIPTION from "@/components/Notification/NotificationByType/ITEM_UPDATE_DESCRIPTION.vue"
import ITEM_UPDATE_ACTIVE_SPRINT_ADDED from "@/components/Notification/NotificationByType/ITEM_UPDATE_ACTIVE_SPRINT_ADDED.vue"
import ITEM_UPDATE_TEAM from "@/components/Notification/NotificationByType/ITEM_UPDATE_TEAM.vue"

import UPDATE_TASK from "@/components/Notification/NotificationByType/UPDATE_TASK.vue"
import COMMENTS from "@/components/Notification/NotificationByType/COMMENTS.vue"
import BUG_WATERMARK_EXCEEDED_BY_LEVEL from "@/components/Notification/NotificationByType/BUG_WATERMARK_EXCEEDED_BY_LEVEL.vue"
import HALT_SPRINT_NOTIFICATION from "@/components/Notification/NotificationByType/HALT_SPRINT_NOTIFICATION.vue"
import SPRINT_REVIEW_COMPLETED from "@/components/Notification/NotificationByType/SPRINT_REVIEW_COMPLETED.vue"
import SUPPORT_CASE_CREATED from "@/components/Notification/NotificationByType/SUPPORT_CASE_CREATED.vue"
import USER_MENTION from "@/components/Notification/NotificationByType/USER_MENTION.vue"
import SPRINT_RETROSPECTIVE from "@/components/Notification/NotificationByType/SPRINT_RETROSPECTIVE.vue"
import STORY_POKER_CREATED from "@/components/Notification/NotificationByType/STORY_POKER_CREATED.vue"
import MILESTONE_COMPLETED from "@/components/Notification/NotificationByType/MILESTONE_COMPLETED.vue"
import GOAL_REACHED from "@/components/Notification/NotificationByType/GOAL_REACHED.vue"
import WELCOME from "@/components/Notification/NotificationByType/WELCOME.vue"
import PROJECT_ADDITION from "@/components/Notification/NotificationByType/PROJECT_ADDITION.vue"
import TEAM_ADDITION from "@/components/Notification/NotificationByType/TEAM_ADDITION.vue"

import TASK_OWNER_ASSIGMENT_NOTIFICATION from "@/components/Notification/NotificationByType/TASK_OWNER_ASSIGMENT_NOTIFICATION.vue"
import TASK_READINESS_NOTIFICATION from "@/components/Notification/NotificationByType/TASK_READINESS_NOTIFICATION.vue"
import JIRA_BACKLOG_IMPORT_COMPLETED
  from "@/components/Notification/NotificationByType/JIRA_BACKLOG_IMPORT_COMPLETED.vue"
@Options({
  name: "NotificationList",
  components: {
    Observer,
    DotNotification,
    TODO_ASSIGNMENT,
    TODO_REMINDER,
    UNPRIORITIZED_BACKLOG_ADD_ITEM,
    UPDATE_STORY_TIME_LEFT_REMINDER,
    SPRINT_RISK_ALERT,
    ITEM_UPDATE_STATUS,
    ITEM_UPDATE_STORYPOINTS,
    ITEM_UPDATE_SEVERITYLEVEL,
    ITEM_UPDATE_OWNER,
    ITEM_UPDATE_DESCRIPTION,
    ITEM_UPDATE_ACTIVE_SPRINT_ADDED,
    ITEM_UPDATE_TEAM,
    UPDATE_TASK,
    COMMENTS,
    BUG_WATERMARK_EXCEEDED_BY_LEVEL,
    HALT_SPRINT_NOTIFICATION,
    SPRINT_REVIEW_COMPLETED,
    SUPPORT_CASE_CREATED,
    USER_MENTION,
    SPRINT_RETROSPECTIVE,
    STORY_POKER_CREATED,
    MILESTONE_COMPLETED,
    GOAL_REACHED,
    WELCOME,
    PROJECT_ADDITION,
    TEAM_ADDITION,
    TASK_OWNER_ASSIGMENT_NOTIFICATION,
    TASK_READINESS_NOTIFICATION,
    JIRA_BACKLOG_IMPORT_COMPLETED,
  },
  directives: {
    ClickOutside,
  },
})
export default class NotificationList extends Vue {
  @Getter(Getters.GET_NOTIFICATIONS) readonly notifications: Notification[]
  @Getter(Getters.GET_NEW_NOTIFICATIONS) readonly newNotifications: Notification[]
  @Getter(Getters.GET_NOTIFICATIONS_RESPONSE) readonly serverResponse: ServerResponse<Notification>
  @Getter(Getters.GET_NOTIFICATIONS_COUNT) readonly count: number
  @Getter(Getters.SUBSCRIPTION_MESSAGE_NOTIFICATION) readonly serverSendUpdated: (status: ResourceEvent) => ServerSendEventNotification

  @Action(Actions.GET_NOTIFICATIONS_COUNT) readonly getNotificationCount: () => Promise<number>
  @Action(Actions.GET_NOTIFICATIONS_RESPONSE) readonly getNotifications: (filter: FilterNotification) => Promise<Notification[]>
  @Action(Actions.READ_NOTIFICATIONS) readonly readNotifications: () => Promise<any>
  @Action(Actions.SET_NOTIFICATIONS_COUNT) readonly setNotificationsCount: (count: number) => Promise<any[]>
  @Action(Actions.SET_NOTIFICATION_READ) readonly setNotificationsRead: (notificationsIds: string[]) => Promise<any[]>

  opened = false
  importantOpened = false

  filter: FilterNotification = FilterNotification.createDefault()
  pageNumber = 1

  @Watch("importantOpened")
  setImportantToRead() {
    if (this.importantOpened) {
      this.setNotificationsRead(this.importantNotificationsIds)
    }
  }

  @Watch("notificationUpdatedMessage")
  onListChanged() {
    const { newNumberOfUnreadNotifications, hasImportant } = this.notificationUpdatedMessage
    this.setNotificationsCount(newNumberOfUnreadNotifications)

    if (this.opened || hasImportant) {
      this.fetchNotifications(0)
    }
  }

  get importantNotification() {
    return this.notifications.filter(notification => !notification.read && notification.type === NotificationType.JIRA_BACKLOG_IMPORT_COMPLETED)
  }

  get importantNotificationsIds() {
    return this.importantNotification.map(notification => notification.id)
  }

  get isImportantNotificationExist() {
    return this.importantNotification.length > 0
  }

  get isNotificationExist() {
    return this.notifications.length > 0
  }

  get isHasNewNotification() {
    return this.count > 0
  }

  get last() {
    return this.serverResponse?.last ?? true
  }

  get notificationUpdatedMessage() {
    return this.serverSendUpdated("NOTIFICATION_UPDATE")
  }

  created() {
    this.filter.addSort("createdDate", false)
  }

  mounted() {
    this.getNotificationCount()
    this.fetchNotifications(0)
  }

  scroll() {
    this.pageNumber++
    this.fetchNotifications(this.pageNumber)
  }

  async fetchNotifications(page: number) {
    this.filter.page = page
    await this.getNotifications(this.filter)
    if (this.isImportantNotificationExist && !this.opened) {
      this.importantOpened = true
    }
  }

  async toggle() {
    if (this.importantOpened) {
      this.importantOpened = false
      return
    }
    this.opened = !this.opened

    await this.$nextTick(() => {
      if (this.opened) {
        this.onShow()
      }
    })
  }

  async onShow() {
    await this.fetchNotifications(0)
    if (this.newNotifications.length) {
      const success = await this.readNotifications()

      if (!success) return false

      this.setNotificationsCount(0)
    }
  }

  hide() {
    this.importantOpened = false
    this.opened = false
  }
}
</script>
