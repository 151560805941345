import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "AppendToBody",
})
export class AppendToBody extends Vue {
  // @Ref([`multiselect-${this.id}`]) readonly button!: HTMLDivEleme
  appendDropDownToBody: boolean
  id: string
  optionPosition: "left" | "right" | "center"
  declare $refs: {
    checkboxElement: HTMLDivElement
  }
  @Prop() scrollableContainer: string

  get parentRef():HTMLElement | null {
    return document.getElementById(this.scrollableContainer)
  }

  mounted() {
    if (this.appendDropDownToBody) {
      window.addEventListener("scroll", this.hideDropDown)
      window.addEventListener("resize", this.hideDropDown)
      if (this.scrollableContainer) {
        this.parentRef?.addEventListener("scroll", this.hideDropDown)
      }
    }
  }

  repositionDropDown() {
    if (this.appendDropDownToBody) {
      this.calculatePositionDropDown()
    }
  }

  calculatePositionDropDown() {
    if (!this.$el) return
    const { top, height, right } = this.$el.getBoundingClientRect()
    const ref = this.$refs[`multiselect-${this.id}`].$refs.list

    if (!ref) return
    const style = ref.style
    style.width = `${this.$el.clientWidth}px`
    style.position = "fixed"
    style.bottom = "auto"
    style.top = `${top + height}px`
    style.left = "auto"

    if (this.optionPosition === "right") {
      this.$nextTick(function () {
        const optionWidth = ref.clientWidth
        style.left = `${right - optionWidth}px`
        style.right = "auto"
      })
    }
    if (this.optionPosition === "center") {
      this.$nextTick(function () {
        const optionWidth = ref.getBoundingClientRect().width
        style.left = `${right - (optionWidth + this.$el.clientWidth) / 2}px`
        style.right = "auto"
      })
    }
  }

  hideDropDown() {
    if (this.$refs[`multiselect-${this.id}`]?.isOpen) {
      this.$refs[`multiselect-${this.id}`].deactivate()
    }
  }

  beforeUnmount() {
    if (this.appendDropDownToBody) {
      window.removeEventListener("scroll", this.hideDropDown)
      window.removeEventListener("resize", this.hideDropDown)
      if (this.scrollableContainer) {
        this.parentRef?.removeEventListener("scroll", this.hideDropDown)
      }
    }
  }
}
