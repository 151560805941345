import { RetroNote } from "./RetroNote"

// export type ResourceType = "ACCOUNT" | "PROJECT" | "SPRINT" | "ITEM" | "TODO_ITEM" | "COMMENT" | "USER" | "TEAM" | "INVITATION" | "STORY_POKER" | "EPIC" | "FILE_METADATA" | "JIRA_BACKLOG_IMPORT_COMPLETED";
export const resourceTypes = [
  "ACCOUNT",
  "PROJECT",
  "SPRINT",
  "ITEM",
  "TODO_ITEM",
  "TODO_ITEM_CHANGE",
  "COMMENT",
  "USER",
  "TEAM",
  "INVITATION",
  "STORY_POKER_ESTIMATION_UPDATE",
  "EPIC",
  "FILE_METADATA",
  "RETROSPECTIVE_NOTE_CHANGED",
  "SPRINT_REVIEW",
  "ITEM_SNAPSHOT",
  "SUBSCRIPTION",
  "RETROSPECTIVE",
  "STORY_POKER",
  "JIRA_BACKLOG_IMPORT_COMPLETED",
  "ITEM_CREATED",
] as const

export const resourceEvents = [
  "NOTIFICATION_UPDATE",
] as const

export const commentSourceTypes = [
  "SPRINT_REVIEW",
  "ITEM_STATUS_CHANGE",
  "AUDIT",
  "ITEM",
] as const

export type ResourceType = typeof resourceTypes[number]
export type ResourceEvent = typeof resourceEvents[number]
export type CommentSourceType = typeof commentSourceTypes[number]

export type UpdateObjectValue<T> = <K extends keyof T>(params: { id: keyof T; value: T[K] }) => void

export type CallbackFunction = () => void;

export type BlockTab = {
  id: string;
  label: string;
  isLast?: boolean;
  countOptions?: {number: string; notify: string;}
}

export type IdAndName = {
  id: string;
  name: string;
  accountId?: string;
}

export interface NameIdAndUrl {
  id: string;
  name: string;
  urlName: string;
  accountId?: string;
}

export type ProgressBarConfig = {
  currentValue: number;
  maxValue: number;
  label?: string;
}

export interface ServerSendEventMessage {
  type: ResourceType;
}

export interface ServerSendRetroUpdate {
  retrospectiveId: string,
  type: ResourceType,
  newNotes: RetroNote[]
}

export interface ServerSendStoryPokerUpdate {
  storyPokerId: string,
  type: ResourceType,
  userIds: string[]
}

export interface ServerSendEventNotification {
  newNumberOfUnreadNotifications: number;
  hasImportant?: boolean;
  notificationType: string
  type: ResourceEvent;
}
