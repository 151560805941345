import {
  ItemTypeActions,
  itemTypeModule,
} from "@/store/modules/item/itemType.module"
import { ItemType } from "./Item"

export class Type {
  readonly id: ItemType
  label?: string

  constructor({ label, id } : { label: string, id: ItemType }) {
    this.id = id
    this.label = label
  }
}

export const typeCreate = ({ label, id } : { label: string, id: ItemType }): Type => new Type({
  id,
  label,
})

export const typeCreateById = ({ id } : { id: string }): Type => {
  const type = itemTypeModule[`get/${ItemTypeActions.GET_TYPE_BY_ID}`](id)

  return new Type(type)
}
