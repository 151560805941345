<template>
  <Form v-slot="{ meta, validate }">
    <Modal
      ref="modal"
      :name="id"
      :classes="modalClasses"
      :width="width"
      :height="height"
      :reset="true"
      :scrollable="scrollable"
      :clickToClose="clickToClose"
      @opened="onOpened"
      @beforeOpen="onBeforeOpen"
      @closed="onClosed"
      @beforeClose="onBeforeClose"
    >
      <div
        class="custom-modal__header"
        :class="[headerClass]"
      >
        <slot name="header" />
      </div>
      <div class="custom-modal__body">
        <slot
          name="body"
          :validators="validators"
        />
      </div>
      <div class="custom-modal__footer">
        <slot
          name="footer"
          :hide="hide"
          :actionDisable="!meta.valid"
          :validate="validate"
        />
      </div>
    </Modal>
  </Form>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop, Ref } from "vue-property-decorator"
import { Form } from "vee-validate"

import validators from "@/utils/validators"

@Options({
  name: "CustomModal",
  components: {
    Form
  }
})
export default class CustomModal extends Vue {
  @Prop(String) readonly id!: string
  @Prop({ default: "" }) readonly modalClass: string
  @Prop({ default: "" }) readonly width: string
  @Prop({ default: "" }) readonly height: string
  @Prop({ default: false }) readonly scrollable: boolean
  @Prop({ default: "" }) readonly headerClass: string
  @Prop({ default: true }) readonly clickToClose: boolean
  @Prop({ default: Infinity }) readonly maxHeight: number
  @Ref("modal") readonly modal!: any

  validators = validators

  get modalClasses(): string[] {
    const classes = ["custom-modal"]
    if (this.modalClass) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return [...classes, ...this.modalClass?.split(" ")]
    }
    return classes
  }

  @Emit("onOpened")
  onOpened() {
    this.initializeSize()
  }

  @Emit("onBeforeOpen")
  onBeforeOpen() {
    document.body.classList.add("open-modal")
  }

  @Emit("onClosed")
  onClosed() {}

  @Emit("before-close")
  onBeforeClose() {
    document.body.classList.remove("open-modal")
  }

  hide() {
    this.$modal.hide(this.id)
  }

  initializeSize() {
    this.modal.setInitialSize()
  }

  unmounted() {
    document.body.classList.remove("open-modal")
  }
}
</script>
