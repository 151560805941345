import { Mark } from '@tiptap/core'
import '@tiptap/extension-text-style'


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    color: {
      /**
       * Comments will be added to the autocomplete.
       */
      setColor: (color: string, isDark: string) => ReturnType,
      unsetColor: () => ReturnType
    }
  }
}

export const editorNativeColor = {
  dark: "#FFFFFF",
  light: "#2A2B36"
}

export const Color = Mark.create({
  name: 'color',

  addOptions() {
    return {
      ...this.parent?.(),
      types: ['textStyle'],
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: attributes => {
              if (!attributes.color) {
                return {}
              }

              return {
                style: `color: ${attributes.color}`,
              }
            },
            // parseHTML: element => ({
            //   color: element.style.color.replace(/['"]+/g, ''),
            // }),
            parseHTML: element => element.style.color.replace(/['"]+/g, ''),
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setColor: (color, theme) => ({ chain }) => {

        let elementColor: string | null = color
        if(color === editorNativeColor[theme]){
          elementColor = null
        }

        return chain()
          .setMark('textStyle', { color: elementColor })
          .run()
      },
      unsetColor: () => ({ chain }) => {
        return chain()
          .setMark('textStyle', { color: null })
          .removeEmptyTextStyle()
          .run()
      }
    }
  }
})
