<template>
  <div class="avatar-group">
    <div
      v-for="(item, index) in croppedAvatarsByLength"
      :key="index"
      :style="`z-index: ${index}`"
      class="avatar-group__item"
    >
      <Avatar
        v-if="team"
        :src="item?.picture?.pictureUrl"
        :type="type"
        bordered
      />

      <Avatar
        v-if="user"
        v-tooltip.bottom="{ content: item?.username, theme: 'info-tooltip-sm' }"
        :userEnabled="item ? item.enabled : true"
        :src="item?.pictureUrl ?? null"
        :gap="item?.getInitials()"
        :type="type"
        class="avatar-group__item"
        :class="[
          {'avatar-select__gap avatar is-md': !item},
          {'is-scale-by-hover': scaleByHover}
        ]"
        bordered
      />
    </div>

    <div
      v-if="lengthOfHiddenAvatars"
      :style="`z-index: ${numberOfVisibleUsers + 1}`"
      class="avatar is-md is-bordered avatar-group__item avatar__length"
    >
      +{{ lengthOfHiddenAvatars }}
    </div>
    <div v-if="!croppedAvatarsByLength.length">
      <Avatar
        :src="null"
        :type="type"
        bordered
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import Avatar from "@/components/Avatar/index.vue"
import { Options, Vue } from "vue-class-component"
import { Team, User } from "@/models"

@Options({
  name: "AvatarGroupTask",
  components: {
    Avatar,
  },
})

export default class AvatarGroupTasks extends Vue {
  @Prop({ default: 3 }) readonly numberOfVisibleUsers: number
  @Prop({ required: true }) readonly type: string
  @Prop({ default: [] }) readonly avatars!: User[] | Team[]

  @Prop({ default: false }) readonly team!: boolean

  @Prop({ default: false }) readonly user!: boolean
  @Prop({ default: true }) readonly scaleByHover: boolean

  get lengthOfHiddenAvatars() {
    return this.avatars.length - this.croppedAvatarsByLength.length
  }

  get croppedAvatarsByLength() {
    if (this.avatars.length <= this.numberOfVisibleUsers) {
      return this.avatars
    }
    return this.avatars.slice(0, this.numberOfVisibleUsers - 1)
  }
}
</script>
