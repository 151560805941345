<template>
  <div class="avatar-group">
    <Avatar
      v-for="(item, index) in croppedAvatarsByLength"
      v-tooltip.bottom="{ content: item?.username, theme: 'info-tooltip-sm' }"
      :key="index"
      :src="item.picture?.pictureUrl"
      :gap="item.getInitials()"
      :style="`z-index: ${index}`"
      class="avatar-group__item is-scale-by-hover"
      bordered
    >
    </Avatar>
    <div
      v-if="lengthOfHiddenAvatars"
      :style="`z-index: ${numberOfVisibleUsers + 1}`"
      class="avatar is-md is-bordered avatar-group__item avatar__length"
    >
      +{{ lengthOfHiddenAvatars }}
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import Avatar from "@/components/Avatar/index.vue"
import { Options, Vue } from "vue-class-component"
import { User } from "@/models"

@Options({
  name: "AvatarGroup",
  components: {
    Avatar,
  },
})

export default class AvatarGroup extends Vue {
  @Prop({ default: [] }) readonly avatars: User[]

  @Prop({ default: 3 }) readonly numberOfVisibleUsers: number

  get lengthOfHiddenAvatars() {
    return this.avatars.length - this.croppedAvatarsByLength.length
  }

  get croppedAvatarsByLength() {
    if (this.avatars.length <= this.numberOfVisibleUsers) {
      return this.avatars
    }
    return this.avatars.slice(0, this.numberOfVisibleUsers - 1)
  }
}
</script>
