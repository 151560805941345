<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__update-item"
  >
    <template #pic>
      <div class="label-pic is-teal">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}
        </a>
        {{ notification.getItemValue('title') }}
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="small"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        Owner has been changed
      </BaseSubHeading>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
        className="notification__update-item__content"
      >
        <span class="link is-medium-500 is-opacity">
          @{{ oldOwnerFullName }}
        </span>
        <icon
          data="@icon/arrow-to.svg"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
        <span class="link is-medium-500">
          @{{ actualOwnerFullName }}
        </span>
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "ITEM_UPDATE_OWNER",
  components: {
    NotificationTemplate,
  },
})
export default class ITEM_UPDATE_OWNER extends Vue {
  @Prop() readonly notification: NotificationItem

  get oldOwnerFullName() {
    return this.notification.oldItem?.owner ? this.notification.oldItem?.owner?.firstName + " " + this.notification.oldItem?.owner?.lastName : "Unassigned"
  }

  get actualOwnerFullName() {
    return this.notification.item?.owner ? this.notification.item?.owner?.firstName + " " + this.notification.item?.owner?.lastName : "Unassigned"
  }
}
</script>
