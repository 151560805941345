
export enum EmailTemplateName {
  WELCOME = "WELCOME",
  ACCOUNT_INVITATION = "ACCOUNT_INVITATION",
  PROJECT_INVITATION = "PROJECT_INVITATION",
  PROJECT_ADDITION = "PROJECT_ADDITION",
  TEAM_INVITATION = "TEAM_INVITATION",
  TEAM_ADDITION = "TEAM_ADDITION",
  PASSWORD_RESET = "PASSWORD_RESET",
  USER_PASSWORD_CHANGED = "USER_PASSWORD_CHANGED",
  STORY_ASSIGNMENT = "STORY_ASSIGNMENT",
  TODO_ASSIGNMENT = "TODO_ASSIGNMENT",
  TODO_REMINDER = "TODO_REMINDER",
  UPDATE_STORY_TIME_LEFT_REMINDER = "UPDATE_STORY_TIME_LEFT_REMINDER",
  COMMENTS = "COMMENTS",
  UNPRIORITIZED_BACKLOG_ADD_ITEM = "UNPRIORITIZED_BACKLOG_ADD_ITEM",
  BLOCKED_STORY_NOTIFICATION = "BLOCKED_STORY_NOTIFICATION",
  OBSOLETE_STORY_NOTIFICATION = "OBSOLETE_STORY_NOTIFICATION",
  STORY_DESCRIPTION_UPDATE = "STORY_DESCRIPTION_UPDATE",
  ITEM_SUB_TASK_ADDED_UPDATE = "ITEM_SUB_TASK_ADDED_UPDATE",
  ITEM_SUB_TASK_TIME_LEFT_UPDATED_TO_ZERO = "ITEM_SUB_TASK_TIME_LEFT_UPDATED_TO_ZERO",
  SPRINT_REVIEW_COMPLETED = "SPRINT_REVIEW_COMPLETED",
  SUPPORT_CASE_CREATED = "SUPPORT_CASE_CREATED",
  BUG_WATERMARK_EXCEEDED = "BUG_WATERMARK_EXCEEDED",
  SPRINT_RISK_ALERT = "SPRINT_RISK_ALERT",
  HALT_SPRINT_NOTIFICATION = "HALT_SPRINT_NOTIFICATION",
  ITEM_CREATED = "ITEM_CREATED",
  ITEM_UPDATED = "ITEM_UPDATED",
  STORY_POKER_CREATED = "STORY_POKER_CREATED",
  SPRINT_RETROSPECTIVE = "SPRINT_RETROSPECTIVE",
  USER_MENTION = "USER_MENTION",
  MILESTONE_COMPLETED = "MILESTONE_COMPLETED",
  MILESTONE_AT_RISK = "MILESTONE_AT_RISK",
  GOAL_REACHED = "GOAL_REACHED",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
}

export class EmailTemplate {
  html: string
  type: string
  subjectTemplate: string
  model: Record<string, unknown>
}

export interface EmailTemplateRender {
  html: string;
  subjectTemplate: string;
  paramsJson: string;
}

export interface EmailTemplateSend {
  html: string;
  paramsJson: string;
  subjectTemplate: string;
  emails: string[];
}
