<template>
  <div class="sprint-header">

    <BaseHeading
      level="2"
      color="monochrome-07"
      className="sprint-header__title"
    >
      <icon
        v-if="sprint.continuousSprint"
        data="@icon/continuous-sprint.svg"
        class="continuous-sprint__icon"
        :class="[`is-${sprint.status.toLowerCase()}`]"
        :fill="true"
        width="1.2em"
        height="1.2em"
      />
      <icon
        v-else-if="sprint.zeroSprint"
        data="@icon/zero-sprint.svg"
        class="zero-sprint__icon"
        :class="[`is-${sprint.status.toLowerCase()}`]"
        :fill="true"
        width="1.2em"
        height="1.2em"
      />
      <div
        v-else
        class="dot sprint-header__dot"
        :class="[`is-${sprint.status.toLowerCase()}`]"
      />
      <slot
        name="title"
        :title="title"
      >

        {{ title }}
      </slot>
    </BaseHeading>

    <BaseSubHeading
      v-if="showDate"
      size="large"
      :isDark="isDark"
      :style="{ color: isDark ? 'var(--monochrome-04)' : 'var(--monochrome-05)' }"
      className="sprint-header__sub-title"
      weight="500"
    >

      <slot
        name="date"
        :startDate="sprint.formatedDateStart"
        :endDate="sprint.formatedDateEnd"
      >
        {{ sprint.formatedDateStart }} - {{ sprint.formatedDateEnd }}
      </slot>


      <icon
        v-if="sprint.isHalted && $route.path.includes('/review')"
        v-tooltip.bottom="{content: sprint.haltReason, theme: 'info-tooltip-alert' }"
        data="@icon/alert-tooltip.svg"
        class="icon-alert-tooltip"
        :fill="true"
        width="1.2em"
        height="1.2em"
        color="var(--serviceAlert-02)"
      />
    </BaseSubHeading>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Sprint } from "@/models"
import { $t } from "@/plugins/i18n"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
const components = {}

@Options({
  name: "SprintHeading",
  components,
})

export default class extends Vue {
  @Prop({ required: true }) sprint: Sprint
  @Prop({ default: true }) showDate: boolean
  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean
  get title() {
    return `${$t("backlog.titles.backlogSprint")} #${this.sprint.sequenceNumber}`
  }
}
</script>
