<template>
  <div
    v-if="accountId"
    id="layout"
    class="layout"
  >
    <Header />
    <Alert />
    <router-view />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import Alert from "@/components/Alert/index.vue"
import Header from "@/components/Header/index.vue"
import { Getters } from "@/store/getters"
import { Getter } from "s-vuex-class"

@Options({
  components: {
    Alert,
    Header,
  },
  name: "EmptyLayoutWithHeader",
})
export default class EmptyLayoutWithHeader extends Vue {
  @Getter(Getters.GET_CURRENT_ACCOUNT_ID) accountId: string
}
</script>
