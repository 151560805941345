import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "type"]
const _hoisted_2 = { class: "custom-editor__content" }
const _hoisted_3 = { class: "custom-editor__content-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorMenuBar = _resolveComponent("EditorMenuBar")!
  const _component_EditorContent = _resolveComponent("EditorContent")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.id,
        class: _normalizeClass(["custom-editor", {'custom-editor_read': _ctx.typeRead }]),
        type: _ctx.type
      }, [
        (!_ctx.typeRead && !_ctx.taskInput)
          ? (_openBlock(), _createBlock(_component_EditorMenuBar, {
              key: 0,
              id: _ctx.id,
              editor: _ctx.editor,
              taskEditor: _ctx.taskEditor,
              targetConfig: _ctx.targetConfig,
              class: "custom-editor__header",
              onFocus: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, null, 8, ["id", "editor", "taskEditor", "targetConfig"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_EditorContent, {
              editor: _ctx.editor,
              class: "custom-editor__in"
            }, null, 8, ["editor"])
          ])
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}