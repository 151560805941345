import { Filter } from "@/models/Pageable"

export class FilterAccount extends Filter {
  q: string
  disabled: boolean

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.disabled = props.disabled ?? false
  }

  getJsonObj() {
    const data = {
      q: this.q,
      enabled: !this.disabled,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterAccount(props)
  }

  static createDefault() {
    const props = {
      q: "",
      disabled: false,
    }

    return new FilterAccount(props)
  }
}
