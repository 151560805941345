<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div
        class="label-pic is-grey"
      >
        <icon
          data="@icon/sprint-alert.svg"
          class="label-pic__icon"
          :fill="true"
          color="#616161"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        <NotificationSprintHeader
          :sprint="sprintContent"
        >
          Halted
        </NotificationSprintHeader>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__desc"
      >
        {{ sprintContent?.haltReasonSummary }}
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification"
import NotificationSprintHeader from "../components/NotificationSprintHeader.vue"

@Options({
  name: "HALT_SPRINT_NOTIFICATION",
  components: {
    NotificationTemplate,
    NotificationSprintHeader,
  },
})
export default class HALT_SPRINT_NOTIFICATION extends Vue {
  @Prop() readonly notification: Notification

  get sprintContent() {
    return this.notification.content
  }
}
</script>
