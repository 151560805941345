<template>
  <div
    class="attachment-component"
    :class="[`attachment-component_${attachmentType}`]"
  >
    <div class="attachment-component__content">
      <div
        v-if="isImage"
        class="attachment-component__content-in"
        :style="{width: width+'px', height: height+'px'}"
      >
        <div
          class="attachment-component__drag-handle"
          contenteditable="false"
          draggable="true"
          data-drag-handle
        >
          <img
            :src="require('@/assets/images/icons/drag.svg')"
            width="10"
            height="10"
          >
        </div>
        <Skeletor
          v-if="node?.attrs?.isSkeletor"
          :width="`${width}px`"
          :height="`${height}px`"
        />
        <VueDraggableResizable
          v-else-if="width && height"
          :ref="'resizable_'+attachmentId"
          :w="width"
          :h="height"
          :draggable="false"
          :handles="['br']"
          :parent="false"
          :lockAspectRatio="true"
          :maxWidth="maxWidth"
          :maxHeight="maxHeight"
          :resizable="resizable"
          :minWidth="100"
          :minHeight="100"
          @resizing="onResize"
        >
          <div
            v-if="blobUrl"
            class="attachment-component__link"
            :tabindex="-1"
            :data-fancybox="`editor_fancybox_${projectId}`"
            :href="blobUrl"
          >
            <img
              class="attachment-component__img"
              :src="blobUrl"
              :alt="attachmentName"
              style="user-select: none;"
            >
          </div>
        </VueDraggableResizable>
      </div>
      <VideoAttachmentItem
        v-else-if="isVideo"
        :file="initFileInstance"
        :videoPath="currentVideoUrl"
        :fancyboxData="`editor_fancybox_${projectId}`"
      />
      <FileItem
        v-else-if="isFile"
        :key="attachmentId"
        :file="initFileInstance"
        fileMarkup="line"
        class="custom-file_reference"
        :removeButton="true"
      />
      <div
        v-else
        class="attachment-component__link"
        :tabindex="-1"
        @click.prevent="onDownload"
      >
        {{ attachmentName }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import { Getters } from "@/store/getters"
import { Actions } from "@/store/actions"
import VueDraggableResizable from "vue-draggable-resizable/src/components/vue-draggable-resizable.vue"
import "vue-draggable-resizable/src/components/vue-draggable-resizable.css"
import { getImageSize } from "@/utils/helpers"
import FileItem from "@/components/File/FileItemSimplified.vue"
import { FileMetaData, Project } from "@/models"
import { Editor } from "@tiptap/core"
import { Node as ProseMirrorNode } from "@tiptap/pm/model"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { Skeletor } from "vue-skeletor"
import {
  emitterDeleteByKey,
  emitterEmit,
  emitterOn,
} from "@/plugins/mitt-emitter"
import VideoAttachmentItem from "@/components/VideoPlayer/VideoAttachmentItem.vue"
import { errorHandlerService } from "@/services/error-handler.service"

@Options({
  name: "Attachment",
  components: {
    VideoAttachmentItem,
    VueDraggableResizable,
    FileItem,
    Skeletor,
  },
})

export default class Attachment extends Vue {
  @Prop() node: ProseMirrorNode
  @Prop() editor: Editor
  @Prop() updateAttributes: (attributes: Record<string, any>) => void
  @Prop() getPos: () => number

  @Getter(Getters.GET_PROJECT) readonly project: Project
  @Action(Actions.ATTACHMENT_VIEW) loadFile: ({ fileMetadataId, fileName } : { fileMetadataId: string, fileName?: string }) => Promise<Blob>
  @Action(Actions.ATTACHMENT_DOWNLOAD) downloadFile: ({ fileMetadataId, fileName } : { fileMetadataId: string, fileName: string }) => void
  @Action(Actions.ATTACHMENT_VIDEO_URL) videoUrl: ({ projectId, attachmentId } : { projectId: string, attachmentId: string }) => Promise<string>

  maxWidth = 1000
  maxHeight = 1000
  blobUrl = ""
  width = 0
  height = 0
  pos: number | undefined = undefined
  currentVideoUrl = ""

  get projectId() {
    return this.project.id
  }

  get isImage() {
    return this.initFileInstance.isImage || this.node.attrs.type === "img"
  }

  get isVideo() {
    return this.initFileInstance.isVideo || this.node.attrs.type === "img"
  }

  get isFile() {
    return this.node.attrs.configType === ReferenceType.FILE
  }

  get attachmentType() {
    if (this.isImage) return "img"
    return "a"
  }

  get attachmentId() {
    return this.initFileInstance.id || this.node.attrs.id
  }

  get attachmentName() {
    return this.initFileInstance.name
  }

  get resizable() {
    return this.node.attrs.isResizable ?? true
  }

  get info() {
    try {
      return JSON.parse(this.node.attrs.info)
    } catch {
      return false
    }
  }

  get initFileInstance() {
    return FileMetaData.create(this.info)
  }

  revokeObjectURL() {
    if (this.blobUrl) URL.revokeObjectURL(this.blobUrl)
  }

  onResize(_x, _y, width, height) {
    this.width = this.roundToEven(width)
    this.height = this.roundToEven(height)

    this.updateAttributes({
      width: this.width,
      height: this.height,
      customResize: true,
    })

    this.editor.commands.selectNodeBackward()
  }

  onDownload() {
    this.downloadFile({
      fileMetadataId: this.attachmentId,
      fileName: this.attachmentName,
    })
  }

  async created() {
    emitterOn("itemDeleteFile", this.onCheckAndDeleteFile)
    this.currentVideoUrl = await this.videoUrl({
      projectId: this.projectId,
      attachmentId: this.attachmentId,
    })
  }

  async mounted() {
    if (!this.getPos) {
      errorHandlerService.customLogError(`File position method: ${this.getPos}`)
    }
    if (!this.node) {
      errorHandlerService.customLogError(`Editor node: ${this.node}`)
    }
    if (!this.editor) {
      errorHandlerService.customLogError(`Editor data: ${this.editor}`)
    }
    if (!this.isImage) {
      this.$wait.end("get.attachment")
      return
    }

    if (this.node?.attrs?.isSkeletor) {
      this.width = 300
      this.height = 100
      this.updateAttributes({ width: this.width, height: this.height })
      this.$wait.start("get.attachment")
    } else {
      this.$wait.start("get.attachment")

      if (!this.attachmentId) {
        this.$wait.end("get.attachment")
        return
      }

      const blob: Blob = await this.loadFile({
        fileMetadataId: this.attachmentId,
      })

      if (!blob) {
        this.$wait.end("get.attachment")
        return
      }

      this.blobUrl = URL.createObjectURL(blob)

      if (!this.blobUrl) {
        this.$wait.end("get.attachment")
        return
      }

      if (!this.$wait.is("get.attachment")) {
        this.width = this.node.attrs.width
        this.height = this.node.attrs.height
        this.$wait.end("get.attachment")
        return
      }
      if (!this.isImage) {
        this.$wait.end("get.attachment")
        return
      }
      const { width, height } = await getImageSize(this.blobUrl)
      this.pos = this.getPos()

      if (width > height) {
        this.width = this.node.attrs.width
        this.height = this.roundToEven(this.width * height / width)
        this.maxWidth = width
      } else {
        this.height = this.node.attrs.height
        this.width = this.roundToEven(this.height * width / height)
        this.maxHeight = height
      }

      if (this.getPos()) {
        this.updateAttributes({ width: this.width, height: this.height })
      }
      this.$wait.end("get.attachment")
    }
  }

  roundToEven(number) {
    return Math.round(number / 2) * 2
  }

  resolvePosition(pos) {
    return this.editor.view.state.doc.resolve(pos)
  }

  onDeleteNode() {
    // make a prosemirror transaction
    // which available on editor node
    let tr = this.editor.view.state.tr
    const { pos } = this.resolvePosition(this.getPos() || this.pos)

    tr.delete(pos, pos + this.node.nodeSize)
    this.editor.view.dispatch(tr)
    emitterEmit("itemSave")
  }

  onCheckAndDeleteFile(id) {
    if (id !== this.attachmentId) return

    this.onDeleteNode()
  }

  beforeUnmount() {
    this.revokeObjectURL()
    emitterDeleteByKey("itemDeleteFile")
  }
}
</script>

