import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content__control-buttons"
}
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: !_ctx.hideButtons ? 'collapse-and-hide' : 'fade'
  }, {
    default: _withCtx(() => [
      (!_ctx.hideButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.hideCancel)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  tabindex: 0,
                  class: _normalizeClass(["secondary-button btn content__control-buttons__cancel", [{'secondary-button_small':_ctx.small}]]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('cancel')), ["stop"]))
                }, [
                  _renderSlot(_ctx.$slots, "cancel", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (!_ctx.hideSave)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  tabindex: 0,
                  class: _normalizeClass(["primary-button btn content__control-buttons__save", [{'primary-button_small':_ctx.small}]]),
                  disabled: _ctx.actionDisabled,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('save')), ["stop"]))
                }, [
                  _renderSlot(_ctx.$slots, "save", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                  ])
                ], 10, _hoisted_2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name"]))
}