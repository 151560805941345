import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "avatar-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.croppedAvatarsByLength, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_Avatar, {
        key: index,
        src: item.picture?.pictureUrl,
        gap: item.getInitials(),
        style: _normalizeStyle(`z-index: ${index}`),
        class: "avatar-group__item is-scale-by-hover",
        bordered: ""
      }, null, 8, ["src", "gap", "style"])), [
        [
          _directive_tooltip,
          { content: item?.username, theme: 'info-tooltip-sm' },
          void 0,
          { bottom: true }
        ]
      ])
    }), 128)),
    (_ctx.lengthOfHiddenAvatars)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(`z-index: ${_ctx.numberOfVisibleUsers + 1}`),
          class: "avatar is-md is-bordered avatar-group__item avatar__length"
        }, " +" + _toDisplayString(_ctx.lengthOfHiddenAvatars), 5))
      : _createCommentVNode("", true)
  ]))
}