<template>
  <div
    :key="value.id"
    class="avatar-select__item to-do-assign__item"
    :class="[{ 'is-selected': isAssigned }]"
    @click.stop="onSelect"
  >
    <div
      v-if="selectedTeamList"
      class="to-do-assign__item__content"
    >
      <Avatar
        :src="value.picture?.pictureUrl || null"
        type="teamsGroup"
      />
      <BaseSubHeading
        size="large"
        weight="400"
        class="is-ellipsis to-do-assign__item__name"
        color="monochrome-06"
      >
        {{ value.name }}
      </BaseSubHeading>
    </div>
    <div
      v-else
      class="to-do-assign__item__content"
    >
      <Avatar
        :src="value?.pictureUrl"
        :gap="value?.getInitials()"
        type="avatarGroup"
        class="user__avatar"
      />
      <BaseSubHeading
        size="large"
        weight="400"
        class="is-ellipsis to-do-assign__item__name"
        color="monochrome-06"
      >
        {{ value?.username }}
      </BaseSubHeading>
    </div>
    <icon
      v-if="isAssigned"
      data="@icon/check.svg"
      :fill="true"
      color="#33691E"
      width="24px"
      height="24px"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import Avatar from "@/components/Avatar/index.vue"

const components = {
  Avatar,
}

@Options({
  name: "ToDoAssignItem",
  components,
})
export default class ToDoAssignItem extends Vue {
  @Prop({ required: true }) readonly value: any
  @Prop({ default: false }) readonly isAssigned: boolean
  @Prop({ default: false }) readonly selectedTeamList: boolean

  onSelect() {
    this.selectedTeamList ? this.$emit("onSelectTeam", this.value) : this.$emit("onSelectUser", this.value)
  }
}
</script>
