import { LogMetric } from "@/models/LogMetric"
import { RequestBody } from "./api.service"
import { debounceTime, Subject } from "rxjs"

class TrackService {
  static logMetric: (error: LogMetric) => void

  endTimeout = 1000
  events: {
    [key: string]: {
      subject: Subject<void>,
      metric: LogMetric
    } | undefined
  } = {}

  init(logMetric: (error: LogMetric) => void) {
    TrackService.logMetric = logMetric
  }

  customEvent(name: string, value: number, tags: { [key:string]: string }) {
    if (!TrackService.logMetric) {
      return
    }

    const logMetric = new LogMetric(name, value, tags)
    TrackService.logMetric(logMetric)
    return logMetric
  }

  createPageLoadEvent(url?: string) {
    if (!TrackService.logMetric) {
      return
    }
    const uri = url || this.getBrowserUrl()
    if (!this.events[uri]) {
      this.events[uri] = {
        subject: new Subject<void>(),
        metric: new LogMetric("pageLoad", (new Date()).getTime(), {
          uri,
          uriPattern: uri,
        }),
      }
      this.events[uri]?.subject
        .pipe(debounceTime(this.endTimeout))
        .subscribe(() => this.sendEvent())
    }
  }

  setPageLoadEventData(method: string, url: string, body?: RequestBody) {
    let logText = `${method}###${url}`
    if (body) {
      if (body instanceof Object) {
        if (body.params) {
          if (body.params instanceof URLSearchParams) {
            logText += `###${body.params.toString()}`
          } else {
            logText += "###null"
          }
          logText += `###${JSON.stringify(body.params)}`
        }
      }
    }

    if (!TrackService.logMetric) {
      return
    }
    const event = this.events[this.getBrowserUrl()]?.metric
    if (event) {
      for (let i = 0; i < Infinity; i++) {
        if (!event.tags[i]) {
          event.tags[i] = logText
          break
        }
      }
    }
  }

  endPageLoadEvent() {
    if (!TrackService.logMetric) {
      return
    }
    this.events[this.getBrowserUrl()]?.subject.next()
  }

  private sendEvent() {
    if (!TrackService.logMetric) {
      return
    }
    const metric = this.events[this.getBrowserUrl()]?.metric
    const subject = this.events[this.getBrowserUrl()]?.subject

    if (metric && subject) {
      metric.value = (new Date()).getTime() - metric.value
      TrackService.logMetric(metric)
      subject.unsubscribe()
      this.events[this.getBrowserUrl()] = undefined
    }
  }

  private getBrowserUrl() {
    return `${window.location.origin}${window.location.pathname}`
  }
}

export const trackService = new TrackService()
