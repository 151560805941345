import { Filter } from "@/models/Pageable"

export class FilterSearch extends Filter {
  q?: string

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
  }

  getJsonObj() {
    const res = super.getJsonObj()
    res.append("q", this.q ?? "")
    return res
  }

  static create(props) {
    return new FilterSearch(props)
  }

  static createDefault() {
    const props = {
      q: "",
    }

    return new FilterSearch(props)
  }
}
