import { Plugin, PluginKey } from "prosemirror-state"
import { TypeConfig, UploadAttachmentFn } from "../File"
import { replaceTextDecorationTags, uploadAttachments } from "@/utils/helpers"
import fetchImageAndConvertToBlob from "@/helpers/fetchImageAndConvertToBlob"

export const dropAttachmentPlugin = (upload: UploadAttachmentFn, config: TypeConfig | null, editor) => new Plugin({
  key: new PluginKey("dropAttachmentPlugin"),
  props: {
    transformPastedHTML(html: string) {
      return replaceTextDecorationTags(html)
    },
    handlePaste(view, event, slice) {
      const items = Array.from(event.clipboardData?.items || [])
      const hasText = items.some(item => item.type === "text/plain")
      const hasHtml = items.some(item => item.type === "text/html")
      const { state, dispatch } = view

      const tr = state.tr.replaceSelection(slice)
      dispatch(tr)

      // Prevent the default paste action
      event.preventDefault()


      const handleImageUpload = async ({ imageUrl, position, type }: { imageUrl, position?: number, type?: string}) => {
        try {
          const blob = await fetchImageAndConvertToBlob(imageUrl)
          const file = new File([blob], "image.jpg")
          uploadAttachments({
            upload,
            files: [file],
            view,
            config,
            position,
            type,
            editor,
          })
        } catch (error) {
          console.error("Error fetching image:", error)
        }
      }

      if (hasText && hasHtml) {
        view.state.doc.content.descendants((fragment, position) => {
          fragment.content.forEach((node) => {
            if (node.type.name === "attachment") {
              handleImageUpload({
                imageUrl: node.attrs.src,
                position: position + node.nodeSize,
                type: "PASTE",
              })
            }
          })
          return false
        })
      } else if (items.length) {
        for (const item of items) {
          const file = item.getAsFile()!
          if (file) {
            uploadAttachments({
              upload: upload,
              files: [file],
              view,
              config,
              editor,
            })
          }
        }
      }

      return true
      // return true
    },

    handleDrop(view, event) {
      const files = Array.from(event.dataTransfer?.files || [])
      if (files.length === 0) return false
      const coordinates = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      })
      if (!coordinates) return false
      event.preventDefault()
      uploadAttachments({
        upload,
        files,
        view,
        config,
        position: coordinates.pos,
        editor,
      })
      return true
    },
  },
})
