<template>
  <span
    class="custom-file__pic"
  >
    <icon
      data="@icon/file.svg"
      :fill="true"
      width="1.25em"
      height="1.5em"
      class="custom-file__icon"
      color="var(--monochrome05to04)"
    />
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({
  name: "FilePic",
})

export default class FilePic extends Vue {}
</script>
