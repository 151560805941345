<template>
  <div
    class="avatar-select"
    :class="[`is-${size}`, `is-${dropdownPosition}`]"
  >
    <DropdownTooltip
      :placement="undefined"
      :shown="opened"
      :triggers="[]"
      :tabIndex="-1"
      popperClass="avatar-select__dropdown"
      iconSize="md"
      @onHideDropdown="hideDropDown"
      @onShowDropdown="onShowDropdown"
      >
      <template #tooltip-label>
        <button
          ref="avatarPlaceholder"
          class="avatar-select__placeholder add-custom-outline"
          type="button"
          :tabindex="tabindex"
          @click.prevent="toggle"
        >
          <BaseSubHeading
            v-if="editItem"
            tabindex="-1"
            size="large"
            weight="400"
            class="avatar-select_label"
          >
            <span
              v-if="selectedUser"
              :class="{'avatar-select_label-disabled': !selectedUser.enabled}"
            >
              {{ selectedUser.username }}
            </span>

            <span
              v-else
              class="link"
            >Assign Owner</span>
          </BaseSubHeading>
          <div
            v-if="avatarGroup"
            :tabindex="tabindex"
            @keyup.enter="toggle()"
          >
            <AvatarGroupWithMenu
              v-if="showUserMenu"
              :avatars="avatars"
              type="avatarGroup"
              size="md"
              @onToggle="onToggleAvatarMenu"
            />
            <AvatarGroupTask
              v-else
              :avatars="avatars"
              :user="true"
              type="avatarGroup"
              size="md"
            />
          </div>
          <div
            v-else
            :tabindex="tabindex"
            @keyup.enter="toggle()"
          >
            <Avatar
              :src="selectedUser?.pictureUrl ?? null"
              :userEnabled="selectedUser ? selectedUser.enabled : true"
              :gap="selectedUser?.getInitials()"
              :size="size"
              type="avatarGroup"
              :class="{'avatar-select__gap' : !selectedUser}"
            />
          </div>
        </button>
      </template>
      <template #tooltip-content>
        <div
          v-if="opened"
          @keydown.esc="hideDropDown"
          @keydown.enter="onSelectHighlighted"
          @keydown.tab.exact.stop="onSelectHighlighted"
        >
          <BorderedInput
            id="search"
            className="avatar-select__input custom-outline"
            :modelValue="search"
            type="text"
            placeholder="Search by Name"
            :tooltip="false"
            :autocomplete="false"
            :tabindex="-1"
            :onWrapEvent="true"
            :triggerFocus="triggerFocus"
            @onFocus="onInputFocus"
            @update:modelValue="handleInput"
          />

          <ul
            ref="container"
            class="avatar-select__list"
          >
            <li
              v-if="selectedUser"
              :ref="`user-${id}-first`"
              class="avatar-select__item custom-outline"
              :class="[{
                'is-selected': selectedUser === null,
                'active-item': currentItem === -1
              }]"
            >
              <button
                type="button"
                class="avatar-select__button"
                :tabindex="-1"
                @click.prevent="onUserSelect(null)"
              >
                <UserComponent :user="null" />
              </button>
            </li>
            <li
              v-for="(item, index) in users"
              :key="item.id"
              :ref="`user-${id}-${index}`"
              class="avatar-select__item custom-outline"
              :class="[{
                'is-selected': selectedUser && selectedUser.id === item.id,
                'active-item': currentItem === index
              }]"
            >
              <button
                type="button"
                class="avatar-select__button"
                :tabindex="-1"
                @click.prevent="onUserSelect(item)"
              >
                <UserComponent :user="item" />
              </button>
            </li>
          </ul>
        </div>
      </template>
    </DropdownTooltip>
  </div>
</template>

<script lang="ts">
import {
  Prop,
  Emit,
  PropSync,
  Ref,
  Watch,
} from "vue-property-decorator"
import BorderedInput from "@/components/Form/BorderedInput.vue"
import { default as UserComponent } from "@/components/User/index.vue"
import Avatar from "@/components/Avatar/index.vue"
import { Actions, Getters } from "@/store"
import {
  User,
  FilterUser,
  Project,
  ServerResponse,
} from "@/models"
import { mixins, Options } from "vue-class-component"
import { Action, Getter } from "s-vuex-class"
import AvatarGroupWithMenu from "@/components/Avatar/AvatarGroupWithMenu.vue"
import AvatarGroupTask from "@/components/Avatar/AvatarGroupTasks.vue"
import { hideDropdownToScroll } from "@/mixins/hideDropdownToScroll"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import Debounce from "@/decorators/Debounce"
import { hideAllPoppers } from "floating-vue"
@Options({
  name: "AvatarSelect",
  components: {
    BorderedInput,
    UserComponent,
    Avatar,
    AvatarGroupWithMenu,
    DropdownTooltip,
    AvatarGroupTask,
  },
})
export default class AvatarSelectDropdown extends mixins(hideDropdownToScroll) {
  @Prop({
    type: String,
    default: "md",
    validator(tag: string) {
      return ["md", "lg"].includes(tag)
    },
  }) readonly size: string
  @Prop({
    type: String,
    default: "right",
    validator(tag: string) {
      return ["left", "right", "center"].includes(tag)
    },
  }) readonly dropdownPosition: string
  @Prop({ type: String }) readonly id: string
  @PropSync("user", {
    required: true,
    default: null,
  }) selectedUser: User | null

  @Prop({
    default: () => [""],
    type: Array,
  }) readonly avatars: User[]
  @Prop({ default: false }) readonly avatarGroup: boolean
  @Prop({ default: false }) readonly editItem: boolean
  @Prop({ default: false }) readonly showUserMenu: boolean
  @Prop({ default: false }) readonly modifyTabindex: boolean
  @Prop({ default: true }) readonly focusable: boolean
  @Prop({ default: "" }) readonly teamId: string
  @Prop({ default: 0 }) readonly tabIndex: number

  @Ref("avatarDropdown") readonly avatarDropdown!: HTMLElement

  @Getter(Getters.GET_OPENED_AVATAR_DROPDOWN_ID) readonly openedAvatarDropdownId: string | null
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User
  @Getter(Getters.GET_PROJECT) readonly project: Project

  @Action(Actions.SET_OPENED_AVATAR_DROPDOWN_ID) updateAvatarDropdownId: (id: string | null) => void
  @Action(Actions.GET_USERS) findUsers: (params: FilterUser) => Promise<ServerResponse<User>>

  opened = false
  focused = false
  search = ""
  users: User[] = []
  filter: FilterUser = FilterUser.createDefault()
  removeButton = false
  currentItem = 0
  triggerFocus = false

  get tabindex() {
    if (!this.focusable) return -1
    return this.opened && this.modifyTabindex ? -1 : undefined
  }

  @Emit()
  onToggleAvatarMenu(value: boolean) {
    return value
  }

  created() {
    this.filter.page = 1
    this.filter.size = 100
    this.filter.addSort("firstName_keyword", true)
    this.filter.forSearch = true
    this.filter.includeDemo = this.project?.demo
    this.filter.includeEnabledOnly = true
    this.updateTeamId()
  }

  onShowDropdown() {
    this.triggerFocus = !this.triggerFocus
  }

  mounted() {
    document.addEventListener("keyup", this.nextItem)
  }

  onSelectHighlighted() {
    if (this.currentItem === -1) {
      this.onUserSelect(null)
    } else {
      const selectedUser = this.users[this.currentItem]
      if (!selectedUser) return
      this.onUserSelect(selectedUser)
    }
  }

  nextItem(event) {
    if (event.keyCode === 38 && this.currentItem > -1) {
      this.currentItem--
    } else if (event.keyCode === 40 && this.currentItem < this.users.length - 1) {
      this.currentItem++
    }

    if (this.opened && this.$refs[`user-${this.id}-${this.currentItem}`]) {
      const activeEl = this.$refs[`user-${this.id}-${this.currentItem}`] as any
      const firstEl = this.$refs[`user-${this.id}-first`] as any
      const scrollEl = this.currentItem === -1 ? firstEl : activeEl[0]

      scrollEl?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }

  toggle() {
    this.opened = true
    this.updateAvatarDropdownId(this.id)
    this.openAvatarSelect()
    this.searchUsers("")
    this.selectedUsername()
  }

  onInputFocus() {
    this.focused = true
  }

  @Debounce(300)
  async searchUsers(search?) {
    this.filter.text = search ?? this.search
    if (this.opened) {
      const serverResponseUser = await this.findUsers(this.filter)
      this.users = serverResponseUser.content
    } else {
      this.users = []
    }
    if (this.users.length && this.users.find(user => user.id === this.currentUser.id)) {
      this.users = this.users.filter(user => user.id !== this.currentUser.id)
      this.users.unshift(this.currentUser)
    }
  }

  hideDropDown() {
    if (this.opened) {
      this.opened = false
      this.focused = false
      this.search = ""
      this.users = []
      this.currentItem = 0
      this.openAvatarSelect()
      hideAllPoppers()
    }
  }

  handleInput({ value } : { value: string }) {
    this.search = value
    this.searchUsers()
  }

  selectedUsername() {
    if (this.selectedUser === null) return

    this.search = this.selectedUser.fullName
  }

  removeUser() {
    this.selectedUser = null
    this.search = ""
    this.users = []
  }


  @Watch("teamId")
  updateTeamId() {
    this.filter.teamId = this.teamId
  }

  @Emit("selected")
  onUserSelect(user: User | null) {
    this.selectedUser = this.selectedUser && user && this.selectedUser.username === user.username ? null : user
    this.hideDropDown()
    return {
      id: this.id,
      value: user,
      selectedUser: this.selectedUser,
    }
  }
  @Emit("openAvatarSelect")
  openAvatarSelect() {
    return {
      open: this.opened,
      type: "itemOwner",
    }
  }

  beforeUnmount() {
    document.removeEventListener("keyup", this.nextItem)
  }
}
</script>
