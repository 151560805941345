import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@icon/bell.svg'


const _hoisted_1 = { class: "notifications" }
const _hoisted_2 = {
  key: 0,
  class: "notifications__box"
}
const _hoisted_3 = { class: "notifications__box_content" }
const _hoisted_4 = { class: "notifications__list custom-scroll" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_DotNotification = _resolveComponent("DotNotification")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_Observer = _resolveComponent("Observer")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["body-text body-text-large-400", [{'clicked__button' : _ctx.opened}]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createVNode(_component_icon, {
        data: _imports_0,
        fill: true,
        width: "1.5em",
        height: "1.5em"
      }),
      (_ctx.isHasNewNotification)
        ? (_openBlock(), _createBlock(_component_DotNotification, {
            key: 0,
            class: "notifications__indicator"
          }))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.opened || _ctx.importantOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseHeading, {
            level: "3",
            color: "monochrome-07",
            className: "notifications__box_title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Notifications "),
              (_ctx.isHasNewNotification && !_ctx.importantOpened)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" (" + _toDisplayString(_ctx.count) + ") ", 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.importantOpened ? _ctx.isImportantNotificationExist : _ctx.isNotificationExist)
                ? (_openBlock(), _createBlock(_component_Observer, {
                    key: 0,
                    items: _ctx.importantOpened ? _ctx.importantNotification : _ctx.notifications,
                    last: _ctx.last,
                    elementClass: "notifications__item",
                    class: "notifications__content",
                    onIntersect: _ctx.scroll
                  }, {
                    default: _withCtx(({ item }) => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(item.type), {
                        key: item.id,
                        notification: item
                      }, null, 8, ["notification"]))
                    ]),
                    _: 1
                  }, 8, ["items", "last", "onIntersect"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, " No Notifications Found "))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.hide]
  ])
}