import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-bar-multi-color" }
const _hoisted_2 = { class: "progress-bar-multi-color__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownTooltip, {
      theme: "info-tooltip",
      triggers: _ctx.tooltipTriggers,
      distance: _ctx.tooltipDistance,
      placement: "top",
      popperClass: "progress-bar-multi-color-popper",
      class: "progress-bar-multi-color-tooltip"
    }, {
      "tooltip-label": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "progress-bar-multi-color__line progress-bar-multi-color__first",
            style: _normalizeStyle({ width: _ctx.getLineWidth('firstLineCount') + '%'})
          }, null, 4),
          _createElementVNode("span", {
            class: "progress-bar-multi-color__line progress-bar-multi-color__second",
            style: _normalizeStyle({ width: _ctx.getLineWidth('secondLineCount') + 2 + '%'})
          }, null, 4)
        ])
      ]),
      "tooltip-content": _withCtx(() => [
        _renderSlot(_ctx.$slots, "tooltip-content")
      ]),
      _: 3
    }, 8, ["triggers", "distance"])
  ]))
}