<template>
  <span
    class="bordered-team"
    :class="{'is-full': isFull}"
  >
    <Avatar
      :src="src"
      type="teamsGroup"
      size="sm"
      bordered
    />
    <span
      v-if="isFull"
      class="bordered-team-name"
    >
      {{ teamName }}
    </span>
    <slot name="after-name" />
  </span>
</template>

<script lang="ts">
import Avatar from "@/components/Avatar/index.vue"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

const components = {
  Avatar,
}

@Options({
  name: "BorderedTeam",
  components,
})
export default class extends Vue {
  @Prop({ default: null }) readonly src: string | null
  @Prop({ default: true }) readonly isFull: boolean
  @Prop({ default: "" }) readonly teamName: string
}
</script>
