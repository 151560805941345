import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "is-flex" }
const _hoisted_2 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_3 = { class: "reference-component__tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_component_DropdownTooltip, {
    theme: "info-tooltip",
    distance: 8,
    triggers: ['click'],
    popperShowTriggers: ['click'],
    placement: "bottom-center"
  }, {
    "tooltip-label": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["reference-component__link", [{'is-disabled': _ctx.user.enabled === false }]])
        }, _toDisplayString(_ctx.text), 3),
        (_ctx.isShowDetails)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.user.email) + ")", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    "tooltip-content": _withCtx(({ hide }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
        _createVNode(_component_Avatar, {
          src: _ctx.user?.pictureUrl,
          size: "sm",
          class: "reference-component__tooltip-avatar"
        }, null, 8, ["src"]),
        _createElementVNode("span", null, [
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            class: "reference-component__tooltip-username"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.username), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            color: "monochrome-05",
            class: "reference-component__tooltip-username"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.teamRolesString), 1)
            ]),
            _: 1
          })
        ])
      ])), [
        [_directive_click_outside, hide.bind()]
      ])
    ]),
    _: 1
  }))
}