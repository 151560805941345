import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "avatar-upload__area custom-cropper custom-cropper_gray custom-cropper_team" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "avatar-upload__area-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButtons = _resolveComponent("ControlButtons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        ref: "editImage",
        style: {"display":"none"},
        src: _ctx.url,
        class: "custom-cropper__image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ControlButtons, {
        onSave: _ctx.onSave,
        onCancel: _ctx.onCancel
      }, {
        save: _withCtx(() => [
          _createTextVNode(" Upload ")
        ]),
        _: 1
      }, 8, ["onSave", "onCancel"])
    ])
  ], 64))
}