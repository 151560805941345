export enum ReferenceType {
  USER = "USER",
  PROJECT = "PROJECT",
  TIP = "TIP",
  MILESTONE = "MILESTONE",
  EPIC = "EPIC",
  TEAM = "TEAM",
  SPRINT = "SPRINT",
  ITEM = "ITEM",
  TASK = "TASK",
  FILE = "FILE",
}