import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"

import { Endpoint } from "@/services/endpoints"
import { UpdateObjectValue } from "@/models/common"
import {
  Account,
  AccountContactInformation,
  AccountPictureUpdatePayload,
  AccountContactInformationPayload,
} from "@/models/Account"
import { SearchResult } from "@/models/Search"
import { FilterAccount } from "@/models/Filter/FilterAccount"

import { ServerResponse } from "@/models/ServerResponse"
import { Role, User } from "@/models/User"
import { BaseModule } from "@/models/BaseModule"
import { apiService } from "@/services/api.service"
import { AlertType, AlertContentItem } from "@/models/Alert"
import { $t } from "@/plugins/i18n"
import { AlertActions, alertModule } from "./alert.module"
import { forceUpdateToken } from "@/plugins/keycloak"

export enum AccountActions {
  GET_ACCOUNTS = "GET_ACCOUNTS",
  GET_ACCOUNTS_RESPONSE = "GET_ACCOUNTS_RESPONSE",
  GET_ACCOUNT = "GET_ACCOUNT",
  GET_ACCOUNT_ID = "GET_ACCOUNT_ID",
  SET_ACCOUNT_CREATING_STATUS = "SET_ACCOUNT_CREATING_STATUS",
  SET_ACCOUNT_ID = "SET_ACCOUNT_ID",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
  POST_ACCOUNT = "POST_ACCOUNT",
  GET_CURRENT_ACCOUNT_ID = "GET_CURRENT_ACCOUNT_ID",
  GET_USER_CURRENT_ACCOUNTS = "GET_USER_CURRENT_ACCOUNTS",
  SET_ACCOUNT_OVERVIEW = "SET_ACCOUNT_OVERVIEW",
  GET_ACCOUNT_SUPERADMIN = "GET_ACCOUNT_SUPERADMIN",
  ACCOUNT_PICTURE_UPDATE = "ACCOUNT_PICTURE_UPDATE",
  ACCOUNT_PICTURE_DELETE = "ACCOUNT_PICTURE_DELETE",
  UPDATE_ACCOUNT_CONTACT_INFO = "UPDATE_ACCOUNT_CONTACT_INFO",
  GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO",
  UPDATE_ACCOUNT_VALUE = "UPDATE_ACCOUNT_VALUE",
  CHECK_ACCOUNT_NAME = "CHECK_ACCOUNT_NAME",
  GET_ACCOUNT_ADMIN = "GET_ACCOUNT_ADMIN",
  UPDATE_ACCOUNT_SUPERADMIN = "UPDATE_ACCOUNT_SUPERADMIN",
  UPDATE_ACCOUNT_AVAILABILITY = "UPDATE_ACCOUNT_AVAILABILITY",
}

@Module
class AccountModule extends BaseModule {
  accounts: Account[] = []
  serverResponse: ServerResponse<Account>
  account: Account | null = null
  accountId = ""
  accountUuids: string[]
  accountOverview: User
  accountCreating = false

  get [`get/${AccountActions.GET_CURRENT_ACCOUNT_ID}`]() {
    return this.getCurrentAccountId()
  }

  get [`get/${AccountActions.GET_ACCOUNT_ID}`]() {
    return this.accountId
  }

  get [`get/${AccountActions.GET_ACCOUNT}`]() {
    return this.account
  }

  get [`get/${AccountActions.GET_ACCOUNTS}`]() {
    return this.accounts
  }

  get [`get/${AccountActions.GET_ACCOUNTS_RESPONSE}`]() {
    return this.serverResponse
  }

  get [`get/${AccountActions.GET_USER_CURRENT_ACCOUNTS}`]() {
    return this.accountUuids
  }

  @Mutation
  setAccountId(id: string) {
    this.accountId = id
  }

  @Mutation
  setAccountCreating(val) {
    this.accountCreating = val
  }

  @Mutation
  setAccounts(accounts: Account[]) {
    this.accounts = accounts
  }

  @Mutation
  addAccounts(accounts: Account[]) {
    this.accounts = [...this.accounts, ...accounts]
  }

  @Mutation
  setAccountsResponse(response: ServerResponse<Account>) {
    this.serverResponse = response
  }

  @Mutation
  setAccount(account: Account) {
    this.account = account
    this.accountId = account.id
  }

  @Mutation
  setAccountValue(payload) {
    if (!this.account) return
    Object.assign(this.account, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setAccountOverview(accountOverview: User) {
    this.accountOverview = accountOverview
  }

  @Mutation
  setUserCurrentAccounts(uuids: string[]) {
    this.accountUuids = uuids
  }

  @Mutation
  setAccountPictureUrl({ accountId, pictureUrl }: { accountId: string, pictureUrl: string }) {
    if (this.account && this.account.id === accountId) {
      this.account.picture = this.account.picture || {}
      this.account.picture.pictureUrl = pictureUrl
    }
    const account = this.accounts.find(t => t.id === accountId)
    if (account) {
      account.picture = account.picture || {}
      account.picture.pictureUrl = pictureUrl
    }
  }

  @Action
  async [AccountActions.GET_ACCOUNTS](params: FilterAccount) {
    const filter = FilterAccount.create(params)
    const data = await apiService.get<ServerResponse<Account>>(Endpoint.GET_ACCOUNTS(), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Account>>(data, (data) => {
      this.setAccountsResponse(data)
      const accounts = data.content.map((account: Account) => Account.create(account))
      if (filter.page === 1) {
        this.setAccounts(accounts)
      } else {
        this.addAccounts(accounts)
      }
    })
  }

  @Action
  async [AccountActions.SET_ACCOUNT_CREATING_STATUS](val) {
    this.setAccountCreating(val)
  }

  @Action
  async [AccountActions.GET_USER_CURRENT_ACCOUNTS]() {
    const data = await apiService.get<string[]>(Endpoint.USER_CURRENT_ACCOUNTS())
    return this.handleResponse<string[]>(data, (data) => {
      this.setUserCurrentAccounts(data)
      return data ?? []
    })
  }

  @Action
  async [AccountActions.GET_ACCOUNT](id: string) {
    const data = await apiService.get<Account>(Endpoint.GET_ACCOUNT(id))
    return this.handleResponse<Account>(data, (data) => {
      const acc = Account.create(data)
      this.setAccount(acc)
      return acc
    })
  }

  @Action
  async [AccountActions.GET_ACCOUNT_ADMIN](id: string) {
    const data = await apiService.get<Account>(Endpoint.GET_ACCOUNT_ADMIN(id))
    return this.handleResponse<Account>(data, (data) => {
      this.setAccount(Account.create(data))
    })
  }

  @Action
  async [AccountActions.GET_ACCOUNT_SUPERADMIN](id: string) {
    const data = await apiService.get<Account>(Endpoint.GET_ACCOUNT_SUPERADMIN(id))
    return this.handleResponse<Account>(data, (data) => {
      this.setAccount(Account.create(data))
    })
  }

  @Action
  async [AccountActions.UPDATE_ACCOUNT_SUPERADMIN](account: Account) {
    const data = await apiService.put<Account>(Endpoint.GET_ACCOUNT_SUPERADMIN(account.id), account.getJsonObj())
    return this.handleResponse<Account>(data, (data) => {
      this.setAccount(Account.create(data))
      const alertContent: AlertContentItem[] = [
        {
          text: "Account Settings ",
          type: "bold",
        },
        {
          text: "Saved",
          type: "regular",
        },
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [AccountActions.UPDATE_ACCOUNT](account: Account) {
    const data = await apiService.patch<Account>(Endpoint.GET_ACCOUNT(account.id), account.getJsonObj())
    return this.handleResponse<Account>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Account Settings ",
          type: "bold",
        },
        {
          text: "Saved",
          type: "regular",
        },
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [AccountActions.POST_ACCOUNT](payload: { name: string, ownerTeamRole: Role, contactInformation: AccountContactInformation }) {
    this.setError(null)

    const data = await apiService.post<Account>(Endpoint.POST_ACCOUNT(), payload)
    return this.handleResponse<Account>(data, async (data) => {
      await forceUpdateToken()
      const account = Account.create(data)
      this.setAccount(account)

      return account
    })
  }

  @Action
  async [AccountActions.ACCOUNT_PICTURE_UPDATE](payload: AccountPictureUpdatePayload) {
    const file = new FormData()
    file.append("file", payload.file)
    const data = await apiService.post<string>(Endpoint.ACCOUNT_PICTURE(payload.accountId), file)
    return this.handleResponse<string>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Account Picture",
          type: "bold",
        },
        {
          text: " updated",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setAccountPictureUrl({
        accountId: payload.accountId,
        pictureUrl: data,
      })
    })
  }

  @Action
  async [AccountActions.ACCOUNT_PICTURE_DELETE](accountId: string) {
    const data = await apiService.delete<string>(Endpoint.ACCOUNT_PICTURE(accountId))
    return this.handleResponse<string>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Account Picture",
          type: "bold",
        },
        {
          text: " deleted",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setAccountPictureUrl({
        accountId: accountId,
        pictureUrl: "",
      })
    })
  }

  @Action
  async [AccountActions.UPDATE_ACCOUNT_CONTACT_INFO](payload: AccountContactInformationPayload) {
    const endpointName = payload.isSuperAdmin ? "ACCOUNT_CONTACT_INFO_SUPER_ADMIN" : "ACCOUNT_CONTACT_INFO"
    const data = await apiService.put<Account>(Endpoint[endpointName](payload.accountId), payload.information)
    return this.handleResponse<Account>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Account Settings",
          type: "bold",
        },
        {
          text: " saved",
          type: "regular",
        },
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  [AccountActions.SET_ACCOUNT_ID](id: string) {
    this.setAccountId(id)
  }

  @Action
  [AccountActions.UPDATE_ACCOUNT_VALUE](payload: UpdateObjectValue<Account>) {
    this.setAccountValue(payload)
  }

  @Action
  async [AccountActions.CHECK_ACCOUNT_NAME](name: string) {
    const data = await apiService.get<boolean>(Endpoint.ACCOUNT_CHECK(), {
      name,
    })
    return this.handleResponse<boolean>(data, () => data ? [{
      id: "",
      resourceType: "ACCOUNT",
      textPreview: name,
      type: "TIP",
    }] as SearchResult[] : [])
  }

  @Action
  async [AccountActions.UPDATE_ACCOUNT_AVAILABILITY](payload: { accountId: string, name: string, enabled: boolean }) {
    const data = await apiService.patch<boolean>(Endpoint.ACCOUNT_ENABLE(payload.accountId), null, {
      params: { enabled: payload.enabled },
    })

    return this.handleResponse<boolean>(data, () => {
      this.setAccountValue({ id: "enabled", value: payload.enabled })

      const alertContent: AlertContentItem[] = [
        {
          text: "Account",
          type: "regular",
        },
        {
          text: ` ${payload.name} `,
          type: "bold",
        },
        {
          text: data ? "was enabled" : "was disabled",
          type: "regular",
        },
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  getCurrentAccountId() {
    return this.accountOverview?.accounts?.[0]?.id ?? this.accountId
  }
}

export const accountModule = new AccountModule({
  store,
  name: Modules.ACCOUNT,
})
