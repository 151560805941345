import { Status } from "./Status"
import { User } from "./User"
import { Task } from "./Task"
import { DefinitionOfDone } from "./DefinitionOfDone"
import { Type, typeCreateById } from "./Type"
import { FileMetaData, IFile } from "./File"
import { NameIdAndUrl, ResourceType } from "./common"
import { Base } from "./Base"
import { Sprint } from "./Sprint"
import { Comment } from "./Comment"
import { ReferencedResourcePreviews } from "./Search"
import { replaceHtmlTagsForEditor } from "@/utils/helpers"
import { BacklogSetup } from "./Backlog"
import { $t } from "@/plugins/i18n"
import { ItemQueryPriorityType } from "./Filter/FilterBacklog"
import { Team } from "./Team"
export type ItemSeverityLevel = "CRITICAL" | "HIGH" | "MEDIUM" | "TRIVIAL" | "NONE";
export type ItemSize = "EXTRA_LARGE" | "LARGE" | "MEDIUM" | "SMALL";
export type ItemStatus = "NOT_STARTED" | "IN_UI_UX" | "UI_UX_DONE" | "IN_DESIGN" | "DESIGN_COMPLETED" | "IN_DEVELOPMENT" | "DEVELOPMENT_DONE" | "IN_QA" | "TESTING_COMPLETE" | "SCHEDULED_FOR_DEPLOYMENT" | "DEPLOYED_TO_STAGING" | "DEPLOYED_TO_PRODUCTION" | "DONE" | "BLOCKED" | "OBSOLETE" | "RE_OPEN" | "ALL";
export type ItemType = "STORY" | "TASK" | "BUG" | "SPIKE" | "SUPPORT_CASE" | "DEV_OPS" | "TECH_DEBT";
import {
  differenceInBusinessDays,
} from "@/utils/dateUtil"
import { Epic } from "./Epic"
import { PokerEstimation } from "./Poker"
import {
  updateReferencesByResourcePreviews,
} from "@/utils/updateReferencesByResourcePreviews"

export interface ServerSendCreatedItem {
  item: Item;
  type: ResourceType;
}

export interface ItemFile {
  itemId: string;
  file: File;
}

export interface ChangeTeamForItem {
  team: Team | null
  item: Item
}

export interface ChangeTeamForItems {
  team: Team
  items: Item[]
  moveTo?: string
}

export interface SeverityLevelExtend {
  id: ItemSeverityLevel;
  label: string;
}

export class TasksWithItemStatus {
  tasks: Task[] = []
  status: ItemStatus = "NOT_STARTED"
  estimation = 0

  constructor(props: TasksWithItemStatus) {
    this.tasks = props.tasks.map(t => Task.create(t))
    this.status = props.status
    this.estimation = props.estimation
  }
}

export interface DefinitionWithItemStatus {
  definitionsOfDone: DefinitionOfDone[];
  status: ItemStatus;
  readyToComplete: boolean;
  estimation: number;
}

export interface ChangeItemStatus {
  projectId?: string;
  itemId: string;
  status: string;
  oldStatus: ItemStatus;
  message?: StructuredDescription;
  itemTitle?: string;
}

export interface ItemPreviewDto {
  id: string;
  itemType: ItemType;
  projectId: string;
  sequenceNumber: number;
  status: ItemStatus;
  title: string;
}

export interface ChangeItemOwner {
  itemId: string;
  ownerId: string;
}

export interface ItemDefinitionPyload {
  itemId: string;
  definitionOfDoneId: string;
}

export interface AddItemEpicPayload {
  itemId: string[];
  epicId: string;
}

export interface EmptyStory {
  title: string;
  epic: Epic | null;
  team: NameIdAndUrl | null;
  type: ItemType;
  priority: ItemPriority | null;
  sprintId: string;
  sprintIsActive: boolean;
  sprintSequenceNumber: number | null;
  addToBacklog?: string;
  moveFrom?: string;
  sprintIsContinuous?: boolean;
  sprint?: Sprint;
}

export interface ItemProgress {
  projectId?: string;
  itemId: string;
  tasks: Task[];
  definitionsOfDone?: DefinitionOfDone[];
}

export type ItemOptions = {
  id: string;
  label: string;
}

export type ChangeItemByUser = {
  id: string;
  firstName: string;
  lastName: string;
}

interface StructuredDescription {
  html: string;
  json: string;
  text: string;
}

export interface ItemPriority {
  priorityType: string;
  previousItemId: string | null;
  nextItemId: string | null;
  index?: number;
}

export interface IItem {
  title: string;
  itemType: ItemType;
  status?: ItemStatus;
  priority?: ItemPriority | null;
  severityLevel?: ItemSeverityLevel;
  description?: string;
  structuredDescription: StructuredDescription;
  size?: ItemSize;
  storyPoints?: number;
  id?: string;
  definitionsOfDone?: DefinitionOfDone[];
  tasks?: Task[];
  taskOwners?: User[];
  owner?: User | null;
  fileMetadata?: IFile[];
  attachmentIds?: string[];
  epic?: Epic | null;
  team?: NameIdAndUrl;
  createdDate: string;
  modifiedDate?: string;
  modifiedByUser?: ChangeItemByUser | null;
  createdByUser?: ChangeItemByUser | null;
  backlogPriority?: number | null;
  unprioritizedOrder?: number;
  sequenceNumber: number;
  estimation?: number;
  originalEstimation?: number;
  storyPokerId? : string;
  sprint?: Sprint;
  sprintId: string;
  sprintSequenceNumber: number | null;
  referencedResourcePreviews?: ReferencedResourcePreviews;
  addToBacklog?: string;
  moveFrom?: string;
  hasPostMortem: boolean;
  label: string;
}

export class Item extends Base<Item> {
  title: string
  itemType: ItemType
  itemTypeParsed: Type
  status: ItemStatus
  statusParsed?: Status
  priority?: ItemPriority | null
  severityLevel?: ItemSeverityLevel
  severityLevelParsed?: {id: ItemSeverityLevel, label: string}
  description?: string
  structuredDescription: StructuredDescription
  size?: ItemSize
  storyPoints?: number | null
  id?: string
  definitionsOfDone: DefinitionOfDone[]
  tasks: Task[]
  taskOwners?: User[]
  owner?: User | null
  fileMetadata?: FileMetaData[]
  attachmentIds?: string[]
  epic?: Epic | null
  team: NameIdAndUrl | null
  projectId?: string
  createdDate: string
  modifiedDate?: string
  modifiedByUser?: ChangeItemByUser | null
  createdByUser?: ChangeItemByUser | null
  backlogPriority?: number | null
  sprintPriority?: number | null
  unprioritizedOrder?: number
  sequenceNumber: number
  estimation?: number
  originalEstimation?: number
  milestoneId?: string
  changedFieldsOriginValues: {[key: string] : any}
  storyPokerId? : string
  sprint?: Sprint
  sprintId?: string
  sprintIsActive?: boolean
  sprintIsContinuous?: boolean
  referencedResourcePreviews?: ReferencedResourcePreviews
  sprintSequenceNumber: number | null
  addToBacklog?: string
  moveFrom?: string
  activeStoryPoker: boolean
  hasPostMortem: boolean
  label: string
  backlogSetup?: BacklogSetup
  isInline?: boolean
  ready: boolean
  lastStatusChangeInSprint?: Date
  noteId?: string
  pokerEstimation?: PokerEstimation
  hasNotEstimatedTask: boolean
  statusChangeDescription: StructuredDescription

  constructor(props?: Item) {
    super()
    this.setDefaultData()

    if (!props) return

    this.id = props.id ?? ""
    this.title = props.title ?? ""
    this.description = props.description ?? ""
    this.activeStoryPoker = props.activeStoryPoker ?? false
    if (props.structuredDescription) {
      this.structuredDescription = {
        html: props.structuredDescription.html,
        text: props.structuredDescription.text,
        json: props.structuredDescription.json,
      }
    } else {
      this.structuredDescription = { html: "", text: "", json: "" }
    }

    if (props.referencedResourcePreviews) {
      this.structuredDescription.html = updateReferencesByResourcePreviews(props.referencedResourcePreviews, this.structuredDescription.html)
    }

    this.itemType = props.itemType === "TASK" ? "STORY" : props.itemType ?? "STORY"
    this.itemTypeParsed = typeCreateById({ id: this.itemType ?? "STORY" })

    this.storyPoints = props.storyPoints ?? null
    this.status = props.status ?? "NOT_STARTED"
    this.statusParsed = Status.createById({
      id: props.status ?? "NOT_STARTED",
    })

    this.priority = props.priority ?? null
    this.severityLevel = props.severityLevel ?? "HIGH"

    this.severityLevelParsed = this.getParsedSeverityLevel(this.severityLevel)

    this.size = props.size ?? "EXTRA_LARGE"

    this.tasks = props.tasks?.length
      ? props.tasks.map(t => Task.create(t))
      : []
    this.taskOwners = props.taskOwners?.length
      ? props.taskOwners.filter(t => t !== null).map(t => User.create(t))
      : []
    this.definitionsOfDone = props.definitionsOfDone
      ? props.definitionsOfDone.map(d => DefinitionOfDone.create(d))
      : []
    this.fileMetadata = props.fileMetadata
      ? props.fileMetadata.map(d => FileMetaData.create(d))
      : []
    this.attachmentIds = props.fileMetadata?.length
      ? props.fileMetadata.map(f => f.id)
      : []
    this.owner = props.owner ? User.create(props?.owner) : null
    this.epic = props.epic ?? null
    this.team = props.team ?? null
    this.createdDate = props.createdDate ?? ""
    this.modifiedDate = props.modifiedDate ?? ""
    this.modifiedByUser = props.modifiedByUser ?? null
    this.createdByUser = props.createdByUser ?? null
    this.backlogPriority = props.backlogPriority ?? null
    this.sprintPriority = props.sprintPriority ?? null
    this.unprioritizedOrder = props.unprioritizedOrder ?? 0
    this.sequenceNumber = props.sequenceNumber ?? 1
    this.originalEstimation = props.originalEstimation ?? 0
    this.projectId = props.projectId ?? ""
    this.storyPokerId = props.storyPokerId ?? ""
    this.sprint = props.sprint ? Sprint.create(props.sprint) : undefined
    this.sprintId = props.sprintId ?? this.sprint?.id ?? ""
    this.sprintIsActive = props.sprintIsActive ?? false
    this.sprintIsContinuous = props.sprintIsContinuous ?? false
    this.sprintSequenceNumber = props.sprintSequenceNumber ?? null
    this.addToBacklog = props.addToBacklog ?? undefined
    this.moveFrom = props.moveFrom ?? undefined
    this.hasPostMortem = props.hasPostMortem ?? false
    this.label = props.label ?? ""
    this.backlogSetup = props.backlogSetup ?? undefined
    this.isInline = props.isInline ?? false
    this.ready = props.ready ?? true
    this.estimation = props.estimation ?? undefined
    this.noteId = props.noteId ?? undefined
    this.hasNotEstimatedTask = props.hasNotEstimatedTask ?? false

    this.lastStatusChangeInSprint = props.lastStatusChangeInSprint
      ? new Date(props.lastStatusChangeInSprint) :
      undefined
    this.pokerEstimation = props.pokerEstimation ?? undefined
    this.statusChangeDescription = props.statusChangeDescription ?? {
      html: "",
      json: "",
      text: "",
    }
  }

  setDefaultData() {
    this.id = ""
    this.title = ""
    this.description = ""
    this.structuredDescription = { html: "", text: "", json: "" }
    this.itemType = "STORY"
    this.itemTypeParsed = typeCreateById({ id: "STORY" })

    this.storyPoints = null
    this.status = "NOT_STARTED"
    this.statusParsed = Status.createById({ id: "NOT_STARTED" })

    this.priority = null
    this.severityLevel = "HIGH"
    this.severityLevelParsed = this.getParsedSeverityLevel("HIGH")
    this.size = "EXTRA_LARGE"

    this.tasks = []
    this.team = null
    this.taskOwners = []
    this.definitionsOfDone = []
    this.fileMetadata = []
    this.attachmentIds = []
    this.createdDate = ""
    this.sequenceNumber = 1
    this.storyPokerId = ""
    this.changedFieldsOriginValues = {}
    this.sprint = undefined
    this.sprintId = ""
    this.sprintIsActive = false
    this.sprintIsContinuous = false
    this.sprintSequenceNumber = null
    this.activeStoryPoker = false
    this.hasPostMortem = false
    this.label = ""
    this.ready = true
    this.noteId = ""
    this.statusChangeDescription = {
      html: "",
      json: "",
      text: "",
    }
  }

  hasChangedProp(prop) {
    return Object.keys(this.changedFieldsOriginValues).includes(prop)
  }

  oldValueForProp(prop) {
    return this.changedFieldsOriginValues[prop]
  }

  onValueUpdate(payload) {
    if (payload["id"] === "fileMetadata") return

    if (!this.hasChangedProp(payload["id"])) {
      this.changedFieldsOriginValues[payload["id"]] = this[payload["id"]]
    }
    if (payload["id"] === "team") this.cleanOwners(payload["value"])
  }

  cleanOwners(team) {
    if (!team?.users) {
      return
    }
    const acceptableIds = team.users.map(u => u.id)

    if (this.owner && !acceptableIds.includes(this.owner.id)) {
      this.owner = null
    }

    this.tasks.forEach((task) => {
      if (task.owner && !acceptableIds.includes(task.owner.id)) {
        task.owner = undefined
      }
    })
  }

  get isEstimated() {
    const estimation = this.estimation
    if (estimation === undefined) return false
    return (this.isSupportCase || this.storyPoints !== null) && estimation > 0
  }

  get isEstimatedWithoutStoryPoints() {
    return this.estimation !== undefined
  }

  get isNotStarted(): boolean {
    return this.status === "NOT_STARTED"
  }

  get allUsersInItem() {
    const users: (User | undefined | null)[] = []

    users.push(this.owner)

    this.taskOwners?.forEach((item) => {
      const result = users.find(u => u?.id === item.id)
      if (!result) {
        users.push(item)
      }
    })
    return users
  }

  static createDefault(): Item {
    return new Item()
  }

  getJsonObj() {
    return {
      title: this.title,
      structuredDescription: {
        html: this.structuredDescription.html ?? "",
        json: this.structuredDescription.json ?? "",
        text: this.structuredDescription.text ?? "",
      },
      itemType: this.itemTypeParsed ? this.itemTypeParsed.id : "",
      storyPoints: this.storyPoints,
      status: this.statusParsed ? this.statusParsed.id : "",
      priority: this.priority,
      severityLevel: this.showSeverity && this.severityLevelParsed ? this.severityLevelParsed.id : "NONE",
      size: this.size,
      owner: this.owner,
      ownerId: this.owner && this.owner?.id ? this.owner?.id : "",
      definitionsOfDone: this.definitionsOfDone?.map(d => DefinitionOfDone.create(d).getJsonObj()),
      tasks: this.tasks?.filter(task => task.isNotEmpty).map(t => t.getJsonObj()),
      attachmentIds: this.attachmentIds?.filter(id => this.tasks.every(task => task.fileMetadataIds?.every(fileId => fileId !== id))),
      epicId: this.epic?.id ?? null,
      teamId: this.team?.id ?? null,
      createdDate: this.createdDate,
      sequenceNumber: this.sequenceNumber,
      projectId: this.projectId,
      sprint: this.sprint,
      sprintId: this.sprintId,
      label: this.label,
      noteId: this.noteId,
    }
  }

  static create(props: Item): Item {
    return new Item(props)
  }

  static createEmptyStory(payload: EmptyStory): Item {
    const item = new Item()
    item.title = payload.title
    item.epic = payload.epic
    item.team = payload.team
    item.itemTypeParsed = typeCreateById({ id: payload.type ?? "STORY" })
    item.itemType = payload.type
    item.severityLevel = "NONE"
    item.definitionsOfDone = []
    item.priority = payload.priority
    item.sprintId = payload.sprintId
    item.sprintIsActive = payload.sprintIsActive
    item.sprintIsContinuous = payload.sprintIsContinuous
    item.sprintSequenceNumber = payload.sprintSequenceNumber
    item.addToBacklog = payload.addToBacklog
    item.moveFrom = payload.moveFrom
    item.sprint = payload.sprint
    return item
  }

  static get severityLevelsParsed() {
    return severityLevelsParsed
  }

  get showSeverity() {
    return this.itemTypeParsed.id === "BUG" || this.itemTypeParsed.id === "SUPPORT_CASE"
  }

  get notDone() {
    return this.status !== "DONE" && this.status !== "OBSOLETE"
  }

  get isDone() {
    return this.statusParsed?.id === "DONE" || this.status === "DONE"
  }

  get isObsolete() {
    return this.statusParsed?.id === "OBSOLETE" || this.status === "OBSOLETE"
  }

  get isBlocked() {
    return this.statusParsed?.id === "BLOCKED" || this.status === "BLOCKED"
  }

  get unsaveFields() {
    return [
      "title",
      "structuredDescription",
      "pokerEstimation",
    ] as (keyof Item)[]
  }

  itemTypeWasChanged({ oldType, newType }) {
    const ignoreTypes = ["STORY", "SPIKE"]
    if (ignoreTypes.includes(oldType) && ignoreTypes.includes(newType)) return false
    return oldType !== newType
  }

  getParsedSeverityLevel(type) {
    const level = Item.severityLevelsParsed.find(item => item.id === type)

    return level ? level : Item.severityLevelsParsed[0]
  }

  getCheckUnsaveField(key: (keyof Item)) {
    return this.unsaveFields.includes(key)
  }

  getAutoSaveFields(paramId) {
    const idsList = [
      "storyPoints",
      "severityLevelParsed",
      "epic",
      "label",
      "itemTypeParsed",
    ]
    return idsList.includes(paramId)
  }

  getUnfinishedTasksCount(type) {
    return this.tasks?.filter(e => e.isNotEmpty).filter(e => e.typeParsed.id === type)?.filter(e => e.estimatedHours! > 0)?.length
  }

  isRemovable(): boolean {
    return this.itemType === "STORY" &&
        this.status === "NOT_STARTED" &&
        !this.structuredDescription.html &&
        !this.storyPoints &&
        !this.owner &&
        !this.tasks?.length &&
        !this.fileMetadata?.length
  }

  getTasksByUserId(userId) {
    return this.tasks?.filter(el => el.owner?.id === userId) ?? []
  }

  checkAlertTheTaskForwardedToUser(userId, taskId) {
    if (!this.tasks?.length) return false
    const taskIndex = this.tasks.findIndex(el => el.id === taskId)
    if (taskIndex <= 0) return false
    const previousTask = this.tasks[taskIndex - 1]
    if (previousTask.estimatedHours === 0 && previousTask.owner?.id !== userId) return true
  }

  isModified(keys?: (keyof Item)[], inverse?: boolean) {
    let isModified = false
    if (keys?.includes("structuredDescription") && this.initialState) {
      const initial = JSON.parse(this.initialState)
      if (initial.structuredDescription) {
        isModified = replaceHtmlTagsForEditor(this.structuredDescription?.html) !== replaceHtmlTagsForEditor(initial.structuredDescription?.html)
      }
      keys = keys.filter(key => key !== "structuredDescription")
    }
    if (!isModified) {
      isModified = super.isModified(keys, inverse)
    }
    return isModified
  }

  updateItemEstimationByTasks(tasks: Task[]) {
    return Task.getItemEstimation(tasks)
  }

  get estimationView() {
    if (!this.isEstimatedWithoutStoryPoints) return "-"
    if (this.estimation && this.estimation > 99) {
      return "99+"
    }
    return this.estimation
  }

  get daysSinceLastStatusChange() {
    if (!this.lastStatusChangeInSprint) return 0

    const differenceDays = differenceInBusinessDays(new Date(), this.lastStatusChangeInSprint)

    return differenceDays >= 0 ? differenceDays : 0
  }

  get isSupportCase() {
    return this.itemTypeParsed.id === "SUPPORT_CASE"
  }

  get inTheUnprioritized() {
    return this.backlogPriority === null
  }
  get inTheBacklog() {
    return this.backlogPriority !== null
  }
  get inTheSprint() {
    return this.sprintPriority && this.sprintPriority !== null && this.sprint
  }

  get getPriorityType(): ItemQueryPriorityType {
    if (this.inTheSprint) return "SPRINT"
    if (this.inTheUnprioritized) return "UNPRIORITIZED"
    if (this.inTheBacklog) return "BACKLOG"

    return "BACKLOG"
  }

  get simpleDiff() {
    if (!this.id) return this
    return {
      title: this.title.trim(),
      structuredDescriptionHtml: replaceHtmlTagsForEditor(this.structuredDescription?.html),
    }
  }

  static backlogLabelByItemType(type: ItemType) {
    switch (type) {
      case "STORY":
      case "SPIKE":
        return "Team"
      case "BUG":
        return "Bug"
      case "SUPPORT_CASE":
        return "Support Case"
      case "DEV_OPS":
        return "DevOps"
      case "TECH_DEBT":
        return "Tech Debt"
      default:
        return ""
    }
  }

  static getBacklogInstanceNameByItem(item: Item) {
    if (!item) return ""
    if (item?.backlogPriority === null && !item?.sprint) return "BacklogUnprioritized"
    if (item?.sprint) return `BacklogSprint#${item.sprint.sequenceNumber}`
    return $t(`backlog.backlogIdByItemType['${item.itemTypeParsed.id.toLowerCase()}']`)
  }

  static getBacklogSetupIdByItem(item: Item) {
    if (!item) return ""
    if (item?.backlogPriority === null && !item?.sprint) return "backlogUnprioritized"
    if (item?.sprint) return `backlogSprint_${item.sprint.sequenceNumber}`
    return $t(`backlog.backlogSetupIdByItemType['${item.itemTypeParsed.id.toLowerCase()}']`)
  }

  static getBacklogNameByItem(item: Item) {
    if (!item) return ""
    if (item?.backlogPriority === null && !item?.sprint) return "Unprioritized"
    return item?.sprint ? `Sprint #${item.sprint?.sequenceNumber}` : $t(`backlog.titlesByItemType['${item.itemTypeParsed.id.toLowerCase()}']`)
  }

  static getBacklogQueryByItem(item: Item) {
    const query = { b: "" }
    const b = item?.sprint ?
      `${$t("backlog.query.BacklogSprint")}#${item.sprint?.sequenceNumber}` :
      $t(`backlog.backlogQueryByItemType['${item.itemTypeParsed.id.toLowerCase()}']`)

    Object.assign(query, { b })
    // if item is unprioritized
    if (item?.backlogPriority === null) {
      Object.assign(query, {
        u: $t(`backlog.itemTypeToQueryParam['${item.itemTypeParsed.id}']`),
      })
    }

    return query
  }
}


const severityLevelsParsed: {id: ItemSeverityLevel, label: string }[] = [
  {
    id: "CRITICAL",
    label: "Critical",
  },
  {
    id: "HIGH",
    label: "High",
  },
  {
    id: "MEDIUM",
    label: "Medium",
  },
  {
    id: "TRIVIAL",
    label: "Trivial",
  },
]


export class ItemInSprintReview extends Item {
  statusChangeComment: Comment | null = null
  sprintPriority: number | null = null
  sprintReviewComment: Comment | null = null
  users: User[] = []
  originalId = ""
  constructor(props) {
    super(props)

    this.sprintPriority = props.sprintPriority
    this.statusChangeComment = props.statusChangeComment ? Comment.create(props.statusChangeComment) : null
    this.sprintReviewComment = this.statusChangeComment ?
      this.statusChangeComment :
      props.sprintReviewComment ? Comment.create(props.statusChangeComment) : null
    this.users = props.users.map(u => User.create(u))
    this.originalId = props.originalId
  }

  static create(props: Item): ItemInSprintReview {
    return new ItemInSprintReview(props)
  }
}

export class ItemInfo {
  id = ""
  sequenceNumber = 1
  title = ""
  itemType: ItemType = "STORY"
  itemTypeParsed: Type = typeCreateById({ id: "STORY" })
  status: ItemStatus = "NOT_STARTED"
  statusParsed: Status = Status.createById({ id: "NOT_STARTED" })

  constructor(props?: Partial<ItemInfo>) {
    this.id = props?.id ?? ""
    this.sequenceNumber = props?.sequenceNumber ?? 1
    this.title = props?.title ?? ""
    this.itemType = props?.itemType ?? "STORY"
    this.itemTypeParsed = typeCreateById({ id: this.itemType })
    this.status = props?.status ?? "NOT_STARTED"
    this.statusParsed = Status.createById({ id: this.status })
  }
}

export class MyWorkStats {
  hours = 0
  tasks = 0
  total = 0
  constructor(props?: Partial<MyWorkStats>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class ResponseToChangingItemTeam {
  id = ""
  title = ""
  itemType: ItemType = "STORY"
  itemTypeParsed = typeCreateById({ id: "STORY" })
  projectId = ""
  sequenceNumber = 1
  status: ItemStatus = "NOT_STARTED"
  statusParsed = Status.createById({ id: "NOT_STARTED" })
  team: Team | null = null

  constructor(props?: Partial<ResponseToChangingItemTeam>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
    this.itemTypeParsed = typeCreateById({ id: this.itemType })
    this.statusParsed = Status.createById({ id: this.status })
    this.team = this.team ? Team.create(this.team) : null
  }
}
