<template>
  <div
    class="reference-list"
    :class="[`reference-list_${componentType.toLowerCase()}`]"
  >
    <div
      v-if="!hideHeader"
      class="reference-list__header"
    >
      <slot name="header" />
    </div>
    <div
      v-if="showList"
      class="reference-list__in"
    >
      <ul class="reference-list__list">
        <li
          v-for="(item, index) in searchList"
          :key="index"
          :class="{ 'is-selected': index === selectedIndex }"
          class="reference-list__item "
        >
          <button
            type="button"
            class="reference-list__button"
            @click.stop="selectItem(item)"
          >
            <slot
              name="item"
              :option="item"
            />
          </button>
        </li>
      </ul>
    </div>
    <div
      v-if="$wait.is('referenceSearch') || $wait.is('referenceSprintSearch')"
      class="reference-loading"
    >
      ...loading
    </div>
    <div
      v-else-if="showNotFound"
      class="reference-loading"
    >
      No results found.
    </div>
    <div
      v-if="!hideFooter"
      class="reference-list__footer"
    >
      <slot name="footer" />
    </div>
    <div class="reference-list__second-screen">
      <slot name="screen" />
    </div>
  </div>
</template>

<script lang="ts">
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { Options, Vue } from "vue-class-component"
import { Prop, Emit } from "vue-property-decorator"

const components = {

}

@Options({
  name: "ReferenceComponentParentList",
  components,
})
export default class extends Vue {
  @Prop() searchList: any[] = []
  @Prop() componentType: ReferenceType
  @Prop() selectedIndex = 0
  @Prop({ default: false }) hideFooter: boolean
  @Prop({ default: false }) hideHeader: boolean
  @Prop({ default: false }) hideContent: boolean

  @Emit("onClick")
  selectItem(item) {
    return item
  }

  get showList() {
    return this.searchList?.length !== 0 && !this.hideContent && !this.$wait.is("referenceSearch")
  }

  get showNotFound() {
    return this.searchList?.length === 0 && !this.hideContent && !this.$wait.is("referenceSearch")
  }
}

</script>
