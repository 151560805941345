import { Filter } from "@/models/Pageable"
import { ItemStatus } from "@/models"
import { FilterBacklogUI } from "./FilterBacklogUI"
import { ItemSeverityLevel, ItemType } from "../Item"

export type ItemQueryPriorityType = "BACKLOG" | "SPRINT" | "EPIC" | "UNPRIORITIZED" | "MY_WORK" | ""
export type SortPriority = "backlogPriority" | "sprintPriority" | "epicPriority" | "roadMapPriority" | "title" | "unprioritizedOrder"

export interface ItemQueryFilter {
  text?: string,
  itemTypes?: ItemType[],
  storyStatuses?: ItemStatus[],
  ownerIds?: string[],
  taskOwners?: string[],
  hasStoryPoints?: boolean,
  hasEstimation?: boolean,
  hasBacklogPriority?: boolean,
  sprintIds?: string[],
  inSprint?: boolean,
  epicIds?: string[],
  inEpic?: boolean,
  teamId?: string,
  startCreatedDateTime?: string,
  endCreatedDateTime?: string,
  sortKey?: SortPriority;
  size?: number;
  hasActiveStoryPoker?: boolean;
  excludeStoryStatuses?: string[]
  includeTeamLessSupportCases?: boolean
  severityLevel?: ItemSeverityLevel[]
  sequenceNumberPrefix?: string
  hasEstimationOrStoryPoints?: boolean
  teamIds?: string[]
  inActiveSprint?: boolean
  inNotActiveSprint?: boolean
  completed?: boolean
  withLatestBlockedOrObsoleteDescription?: boolean,
  hasTeam?: boolean
}

export class FilterBacklog extends Filter {
  text = ""
  itemTypes: ItemType[] = []
  storyStatuses: ItemStatus[] = []
  ownerIds: string[] = []
  taskOwners: string[] = []
  withLatestBlockedOrObsoleteDescription = false
  hasStoryPoints?: boolean = undefined
  hasEstimation?: boolean = undefined
  hasBacklogPriority?: boolean = undefined
  sprintIds: string[] = []
  inSprint?: boolean = undefined
  epicIds: string[] = []
  inEpic?: boolean = undefined
  teamId?: string = undefined
  teamIds: string[] = []
  startCreatedDateTime = ""
  endCreatedDateTime = ""
  inMilestone?: boolean = undefined
  archived?: boolean = undefined
  sortKey = "backlogPriority"
  label?: string
  status?: string
  tShirtSize?: string
  hasActiveStoryPoker?: boolean
  excludeStoryStatuses?: ItemStatus[]
  hasTShirt: boolean
  includeTeamLessSupportCases?: boolean
  severityLevel?: ItemSeverityLevel[]
  sequenceNumberPrefix?: string
  hasEstimationOrStoryPoints?: boolean
  inActiveSprint?: boolean
  inNotActiveSprint?: boolean
  completed?: boolean
  itemIds?: string[] = []
  onlyGet?: boolean
  withoutPagination = false
  hasTeam?: boolean = undefined

  constructor(props) {
    super(props)

    this.text = props.text
    this.itemTypes = props.itemTypes
    this.storyStatuses = props.storyStatuses
    this.ownerIds = props.ownerIds
    this.taskOwners = props.taskOwners
    this.hasStoryPoints = props.hasStoryPoints
    this.hasEstimation = props.hasEstimation
    this.hasBacklogPriority = props.hasBacklogPriority
    this.sprintIds = props.sprintIds
    this.inSprint = props.inSprint
    this.epicIds = props.epicIds
    this.inEpic = props.inEpic
    this.teamId = props.teamId
    this.teamIds = props.teamIds
    this.startCreatedDateTime = props.startCreatedDateTime
    this.endCreatedDateTime = props.endCreatedDateTime
    this.size = props.size
    this.sortKey = props.sortKey || this.sortKey
    this.archived = props.archived
    this.label = props.label
    this.status = props.status
    this.tShirtSize = props.tShirtSize
    this.hasActiveStoryPoker = props.hasActiveStoryPoker
    this.excludeStoryStatuses = props.excludeStoryStatuses
    this.addSort(this.sortKey as string, true)
    this.hasTShirt = props.hasTShirt
    this.includeTeamLessSupportCases = props.includeTeamLessSupportCases
    this.severityLevel = props.severityLevel
    this.sequenceNumberPrefix = props.sequenceNumberPrefix
    this.hasEstimationOrStoryPoints = props.hasEstimationOrStoryPoints
    this.inActiveSprint = props.inActiveSprint
    this.inNotActiveSprint = props.inNotActiveSprint
    this.completed = props.completed
    this.withLatestBlockedOrObsoleteDescription = props.withLatestBlockedOrObsoleteDescription
    this.itemIds = props.itemIds
    this.onlyGet = props.onlyGet
    this.withoutPagination = props.withoutPagination
    this.hasTeam = props.hasTeam
  }

  getJsonObj() {
    const data = {
      text: this.text,
      itemTypes: this.itemTypes,
      ownerIds: this.ownerIds,
      taskOwners: this.taskOwners,
      hasStoryPoints: this.hasStoryPoints,
      hasEstimation: this.hasEstimation,
      hasBacklogPriority: this.hasBacklogPriority,
      sprintIds: this.sprintIds,
      inSprint: this.inSprint,
      epicIds: this.epicIds,
      inEpic: this.inEpic,
      teamId: this.teamId,
      teamIds: this.teamIds,
      startCreatedDateTime: this.startCreatedDateTime,
      endCreatedDateTime: this.endCreatedDateTime,
      size: this.size ?? this.defaultSize,
      inMilestone: this.inMilestone,
      archived: this.archived,
      label: this.label,
      status: this.status,
      tShirtSize: this.tShirtSize,
      hasActiveStoryPoker: this.hasActiveStoryPoker,
      excludeStoryStatuses: this.getExcludeStoryStatuses(this.excludeStoryStatuses),
      storyStatuses: this.storyStatuses,
      hasTShirt: this.hasTShirt ?? "",
      includeTeamLessSupportCases: this.includeTeamLessSupportCases ?? "",
      severityLevel: this.severityLevel ?? [],
      sequenceNumberPrefix: this.sequenceNumberPrefix ?? "",
      hasEstimationOrStoryPoints: this.hasEstimationOrStoryPoints,
      inActiveSprint: this.inActiveSprint,
      inNotActiveSprint: this.inNotActiveSprint,
      completed: this.completed,
      withLatestBlockedOrObsoleteDescription: this.withLatestBlockedOrObsoleteDescription,
      itemIds: this.itemIds,
      hasTeam: this.hasTeam,
    }

    return super.getJsonObj({
      params: data,
      withoutPagination: this.withoutPagination,
    })
  }

  getExcludeStoryStatuses(excludeStoryStatuses) {
    if (this.storyStatuses && this.storyStatuses[0] === "ALL") {
      this.storyStatuses = []
      return []
    }

    if (!excludeStoryStatuses) return []

    if (!this.storyStatuses?.length) return excludeStoryStatuses

    return excludeStoryStatuses.filter(status => !this.storyStatuses?.includes(status))
  }


  setFilterNotEstimated(value?: boolean) {
    this.hasEstimation = value ? !value : undefined
  }

  setFilterEstimated(value?: boolean) {
    this.hasEstimation = value ? value : undefined
  }

  setStoryPointsEstimated(value?: boolean) {
    this.hasStoryPoints = value ? value : undefined
  }

  setFilterStatus(status?: ItemStatus) {
    this.storyStatuses = status ? [status] : []
  }

  setFilterEpic(epicId: string) {
    if (epicId) {
      this.inEpic = true
      this.epicIds = [epicId]
      this.archived = false
    }
  }

  setFilterNotInEpic() {
    this.inEpic = false
  }

  setArchivedEpic(archived) {
    this.archived = archived
  }

  setSize(size) {
    this.size = size
  }

  setEpicLabel(label) {
    this.label = label
  }

  setEpicStatus(status) {
    this.status = status
  }

  setItemTypes(itemTypes: ItemType[]) {
    this.itemTypes = itemTypes
  }

  setTShortSize(size) {
    this.tShirtSize = size
  }

  setHasTShirt(hasTShirt: boolean) {
    this.hasTShirt = hasTShirt
  }

  setFilterTeam(teamId?: string) {
    this.teamId = teamId ? teamId : undefined
  }

  setInMilestone(inMilestone) {
    this.inMilestone = inMilestone
  }

  setFilterTeams(teamIds?: string[]) {
    this.teamIds = this.checkSupportCaseFilter ? [] : teamIds ?? []
  }

  setFilter(filter) {
    Object.keys(filter).forEach((field) => {
      this[field] = filter[field]
    })
  }

  updateFilterByField({ id, value }) {
    this[id] = value
  }

  updateFilterSizeByElements(numberOfElements: number) {
    this.size = this.defaultSize
    const numberOfItemsInFilter = this.page * this.size
    if (numberOfItemsInFilter < numberOfElements) {
      // calculate how many items are left to be displayed
      const differenceOfFilter = numberOfElements - numberOfItemsInFilter
      // calculate how many items can be displayed on the current page
      const remainder = differenceOfFilter % this.size
      // calculate how many pages are needed to display the remaining items
      this.page += Math.floor(differenceOfFilter / this.size)
      this.size -= remainder
    }
  }

  get hasSelectedFilters() {
    return !!this.selectedFiltersCount
  }

  get checkSupportCaseFilter(): boolean {
    return !!this.itemTypes?.includes("SUPPORT_CASE")
  }

  get selectedFiltersCount() {
    let count = 0
    let changedFilterUI = [
      "text",
      "storyStatuses",
      "ownerIds",
      "epicIds",
      "startCreatedDateTime",
      "hasEstimation",
      "hasStoryPoints",
      "severityLevel",
      "sequenceNumberPrefix",
    ]

    if (this.sortKey === "epicPriority") {
      changedFilterUI = changedFilterUI.filter(e => e !== "epicIds")
      changedFilterUI.push("teamIds")
    }

    changedFilterUI.forEach((filterKey) => {
      if (this.hasSelectedFilterByKey(filterKey)) {
        count++
      }
    })

    // check item types in filter only for sprint backlogs
    if (this.sprintIds?.length && this.hasSelectedFilterByKey("itemTypes")) {
      count++
    }

    return count
  }

  hasSelectedFilterByKey(key) {
    if (!key || !this[key]) return false

    return this[key].length > 0
  }

  static create(props) {
    return new FilterBacklog(props)
  }

  static createFilterFromUI(filter, props) {
    const newFilter = new FilterBacklog(filter)
    const payload = FilterBacklogUI.create(props).getJsonObj()

    Object.keys(payload)
      .forEach(key => newFilter[key] = payload[key])
    return newFilter
  }
}

export class FilterBacklogAnalytics extends FilterBacklog {

}
