
export type RiskLevel = "GREEN" | "YELLOW" | "RED" | "GREY"

export const creationProgressTypes = [
  "ADD_TEAM",
  "SELECT_TEAM",
  "TEAM_SETUP",
  "TEAM_SETTINGS",
  "CREATED",
  "CREATE_TEAM",
  "START_JIRA_IMPORT_TEAM_SETUP",
  "SETUP_JIRA_IMPORT_ON_TEAM_SETUP",
  "REVIEW_JIRA_IMPORT_TEAM",
  "JIRA_IMPORT_BACKLOG",
]

export enum EstimationMethodology {
  ALL_AVAILABLE = "ALL_AVAILABLE",
  VELOCITY_AND_CAPACITY = "VELOCITY_AND_CAPACITY",
  VELOCITY_BASED_ONLY = "VELOCITY_BASED_ONLY",
  CAPACITY_BASED_ONLY = "CAPACITY_BASED_ONLY",
  NO_ESTIMATES = "NO_ESTIMATES",
}

export type CreationProgress = typeof creationProgressTypes[number]

export interface SprintCycleParsed {
  label: string
  length: number
}

export class Project {
  id = ""
  name = ""
  demo = false
  utilizationRatio = 65
  sprintCycle = 2
  sprintCycleParsed: SprintCycleParsed = this.sprintCycleParsedByLength(this.sprintCycle)
  maxCritical = 5
  maxHigh = 10
  maxMedium = 20
  maxTrivial = 30
  urlName: null | string = null
  createdDate = ""
  fiveWhysThresholdSeverityLevel = "HIGH"
  users = 0
  teams = 0
  items = 0
  teamIds?: string[]
  timesheets = true
  prefix = ""
  creationProgress: CreationProgress | null = null
  estimationMethodology: EstimationMethodology = EstimationMethodology.ALL_AVAILABLE

  constructor(props?: Partial<Project>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
    this.sprintCycleParsed = this.sprintCycleParsedByLength(this.sprintCycle)
  }

  static create(props: Project): Project {
    return new Project(props)
  }

  static createDefault(): Project {
    return new Project()
  }

  getJsonObj() {
    const data = {
      id: this.id,
      name: this.name,
      utilizationRatio: this.utilizationRatio,
      sprintCycle: this.sprintCycle,
      maxCritical: this.maxCritical,
      maxHigh: this.maxHigh,
      maxMedium: this.maxMedium,
      maxTrivial: this.maxTrivial,
      fiveWhysThresholdSeverityLevel: this.fiveWhysThresholdSeverityLevel,
      urlName: this.urlName,
      teamIds: this.teamIds,
      demo: this.demo,
      createdDate: this.createdDate,
      timesheets: this.timesheets,
      prefix: this.prefix,
      estimationMethodology: this.estimationMethodology,
    }

    return data
  }

  sprintCycleParsedByLength(index: number) {
    return { label: `${index} weeks`, length: index }
  }

  get creationInProgress() {
    return this.creationProgress && this.creationProgress !== "CREATED"
  }
}

export interface ProjectPreview {
  id: string
  name: string
  accountId: string
  urlName: null | string
  demo: boolean
}

export interface ExistsByNameOrUrlName {
  existsByName: boolean
  existsByUrlName: boolean
}

export interface ChartInfo {
  [key: string]: ChartInfoExtendedData;
}

export interface ChartInfoExtendedData {
  value: number
  riskLevel: RiskLevel
}

export interface ChartInfoItem {
  label: string;
  date: Date;
  dataset: number | null;
  riskLevel?: RiskLevel;
}

export interface ProjectMilestone {
  title: string,
  endDate: string,
  startDate?: string
  id: string,
  chartInfo: ChartInfo
  capacityHours?: number
}

export interface ProjectMetrics {
  [key: string]: ProjectStats
}

export interface ProjectStats {
  bugsClosed: number;
  bugsOpened: number;
  storiesCompleted: number;
  storiesRemaining: number;
  supportCaseFiled: number;
  supportCaseResolved: number;
  techDebtAdded: number;
  techDebtDone: number;
}

export interface ProjectOverview {
  project: Project;
  milestone: ProjectMilestone;
  stats: {
    items: number;
    teams: number;
    users: number;
    metricsLog: ProjectMetrics;
  };
}

export interface ProjectInfoStats {
  items: number;
  teams: number;
  users: number;
  metricsLog: ProjectMetrics;
}

export class ProjectInfo {
  name = ""
  urlName = ""
  projectId = ""
  demo = false
  estimatedDelay = 0
  createdDate = ""
  milestone: ProjectMilestone = {
    title: "",
    endDate: "",
    startDate: "",
    id: "",
    chartInfo: {},
  }
  stats: ProjectInfoStats = {
    items: 0,
    teams: 0,
    users: 0,
    metricsLog: {},
  }
  creationProgress: CreationProgress | null = null

  constructor(props?: Partial<ProjectInfo>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  get creationInProgress() {
    return this.creationProgress && this.creationProgress !== "CREATED"
  }
}

export interface ProjectWithStats {
  project: Project;
  stats: {
    users: number,
    teams: number,
    items: number,
  }
}
