<template>
  <Field
    v-slot="{ errors, field, meta }"
    ref="borderedInput"
    :as="validationTag"
    :modelValue="content"
    :name="name ?? id"
    :rules="rules"
    :validateOnBlur="validateOnBlur"
    :validateOnModelUpdate="validateOnModelUpdate"
    :validateOnInput="validateOnInput"
    :validateOnChange="false"
  >
    <div
      class="bordered-input"
      :class="[
        {'bordered-input_sm': sm },
        {'bordered-input_icon': icon },
        {'bordered-input_icon-left': searchIcon },
        {'bordered-input_relative-label': relative },
        {'is-error': errors[0] },
        className,
      ]"
      @click="onWrapperClick(onWrapEvent)"
    >
      <icon
        v-if="searchIcon"
        data="@icon/loupe.svg"
        :fill="false"
        color="#81889B"
        width="18"
        height="18"
        class="bordered-input__icon-left"
      />

      <label
        v-if="!sm && label"
        :for="id"
      >
        <slot name="label small">
          {{ label }}

          <Tooltip
            v-if="tooltip"
            :text="tooltipText"
          />
        </slot>
      </label>
      <input
        v-bind="field"
        v-maska="maska"
        :id="id"
        ref="search"
        :type="type"
        :class="inputClass"
        :placeholder="placeholder"
        :autocomplete="getAutocomplete"
        :maxlength="maxLength"
        :tabindex="tabindex"
        v-on="inputEvents"
        @mousedown="$event.stopPropagation()"
        @keydown.enter.prevent="$emit('enter')"
        @focus="$emit('onFocus')"
        @blur="$emit('onBlur')"
        @input="onChange($event.target.value)"
      >

      <button
        v-if="showClearButton && !loading"
        class="bordered-input__remove"
        @click.prevent="onClearButtonClicked"
      >
        <icon
          data="@icon/close.svg"
          :fill="false"
          width="1em"
          height="1em"
          color="#7D92A7"
        />
      </button>

      <div class="bordered-input__icon">
        <slot name="icon" />
        <icon
          v-if="meta.pending || loading"
          data="@icon/spinner.svg"
          original
          color="#85A7FF"
          width="18"
          height="18"
          class="spin-animation"
        />
      </div>

      <span
        v-if="errors[0]"
        class="error-text bordered-input__error"
        :class="{ 'error-text-small': isSmallErrorText }"
      >
        {{ errors[0] }}
      </span>
    </div>
  </Field>
</template>

<script lang="ts">
import Tooltip from "@/components/Tooltip/index.vue"
import { maska } from "maska"
import { Field } from "vee-validate"
import { Options, Vue } from "vue-class-component"
import {
  Emit,
  Prop,
  Ref,
  Watch,
} from "vue-property-decorator"

@Options({
  name: "BorderedInput",
  components: {
    Tooltip,
    Field,
  },
  directives: { maska },
})
export default class BorderedInput extends Vue {
  @Prop() readonly modelValue: string | number
  @Prop() readonly label?: string
  @Prop() readonly name?: string
  @Prop() readonly id: string
  @Prop() readonly placeholder: string
  @Prop() readonly tooltip?: boolean
  @Prop() readonly tooltipText?: string
  @Prop() readonly sm?: boolean
  @Prop() readonly icon: boolean
  @Prop() readonly loading: boolean
  @Prop() readonly inputClass: string
  @Prop({ default: "" }) readonly rules: () => boolean | string
  @Prop({ default: false }) readonly autocomplete: boolean | string
  @Prop({ default: "text" }) readonly type: string
  @Prop({ default: "" }) readonly className: string
  @Prop() readonly focus: boolean
  @Prop({ default: false }) cancelButton: boolean
  @Prop({ default: false }) searchIcon: boolean
  @Prop({ default: null }) maska: string | string[]
  @Prop({ default: "" }) validationTag: string
  @Prop({ default: true }) validateOnBlur: boolean
  @Prop({ default: false }) onWrapEvent: boolean
  @Prop({ default: -1 }) maxLength: number
  @Prop({ default: 0 }) tabindex: number
  @Prop({ default: false }) relative: boolean
  @Prop({ default: true }) validateOnChange: boolean
  @Prop({ default: false }) triggerFocus: boolean
  @Prop({ default: true }) isSmallErrorText: boolean
  @Prop({ default: true }) showRemoveButton: boolean
  @Prop({ default: false }) validateOnModelUpdate: boolean
  @Prop({ default: false }) validateOnInput: boolean
  @Prop() inputEvents: any

  @Ref("search") readonly search!: HTMLInputElement
  @Ref("borderedInput") readonly borderedInput!: any

  get getAutocomplete() {
    if (!this.autocomplete) return "off"
    if (this.autocomplete === true) return "on"
    return this.autocomplete
  }

  get content() {
    return this.modelValue
  }

  set content(value) {
    this.$emit("update:modelValue", {
      value,
      id: this.id,
    })
  }

  get showClearButton() {
    if (this.type === "search" && this.showRemoveButton && this.content) return true

    return this.cancelButton
  }

  onWrapperClick(payload) {
    if (payload) {
      this.onFocus()
    }
  }

  mounted() {
    if (this.focus) {
      this.onFocus()
    }
  }

  onClearButtonClicked() {
    if (this.cancelButton) {
      this.cancel()
    } else {
      this.remove()
    }
  }

  async onChange(value) {
    if (value === undefined) return
    this.content = value
    const validate = this.borderedInput.validate
    const { valid } = this.validateOnChange ? await validate() : true
    this.$emit("change",
      {
        value,
        id: this.id,
        valid,
      })
  }

  @Emit("remove")
  remove() {
    this.content = ""
  }

  @Emit("cancel")
  cancel() {}

  @Watch("triggerFocus")
  onFocus() {
    this.$nextTick(() =>
      this.search.select(),
    )
  }
}
</script>
