import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { filePreviewForEditor } from "@/utils/helpers"
import { Plugin, PluginKey } from "prosemirror-state"

export const dropFilePlugin = (props) => {
  const { editor } = props

  const plugin: Plugin<any> = new Plugin({
    key: new PluginKey("dropFilePlugin"),
    props: {
      handleDrop(view, event) {
        const getDataId = ReferenceType.FILE
        const dataTransferById = event.dataTransfer?.getData(getDataId)

        const hasFiles =
            event.dataTransfer &&
            dataTransferById &&
            dataTransferById.length

        if (!hasFiles) return false

        const file = JSON.parse(dataTransferById)

        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })
        filePreviewForEditor({
          file,
          editor,
          position: coordinates?.pos,
          view,
        })
      },
    },
  })

  return plugin
}
