<template>
  <div
    class="avatar"
    :class="[
      'is-'+size,
      {'is-bordered': bordered || isDisableUserDefaultAvatarShow},
      {'is-gap': !hasSrc && !isDisableUserDefaultAvatarShow},
    ]"
  >
    <icon
      v-if="isDisableUserDefaultAvatarShow"
      class="avatar__disabledUser-picture avatar__default-picture"
      :data="defaultPictureList.disabledUser"
      :fill="defaultPicture.fill"
      :color="defaultAvatarColor"
      :type="type"
    />
    <span
      v-else-if="hasSrc || gap"
      class="avatar__img-wrapper"
    >
      <ImageComponent
        v-if="hasSrc"
        :src="src"
        alt="avatar"
        class="avatar__img"
        @error="onErrorHandler"
      />
      <span
        v-else
        class="avatar__gap"
      >{{ gap }}</span>
    </span>
    <icon
      v-else
      class="avatar__default-picture"
      :data="defaultPicture.data"
      :fill="defaultPicture.fill"
      :color="defaultAvatarColor"
      :type="type"
    />
    <button
      v-if="showRemoveButton"
      type="button"
      class="avatar__remove"
      @click.stop="removeAvatar"
    >
      <icon
        data="@icon/close_with_stroke.svg"
        original
        :fill="false"
        width="1em"
        height="1em"
      />
    </button>
  </div>
</template>

<script lang="ts">
import ImageComponent from "@/components/Avatar/ImageComponent.vue"
import { Emit, Prop } from "vue-property-decorator"
import { Options } from "vue-class-component"
import { Vue } from "vue-class-component"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
const components = {
  ImageComponent,
}
@Options({
  name: "Avatar",
  components,
})
export default class Avatar extends Vue {
  defaultPictureList = {
    avatarGroup: require("@icon/user-add.svg"),
    disabledUser: require("@icon/disable-user.svg"),
    addTeamsGroup: require("@icon/addUserInTeam.svg"),
    teamsGroup: require("@icon/team-noAvatar.svg"),
  }
  @Prop({ default: false }) showRemoveButton: boolean
  @Prop({
    default: 0,
    type: Number,
  }) readonly index!: number
  @Prop({
    required: true,
    default: null,
  }) readonly src: string | null
  @Prop() readonly type: string
  @Prop({
    default: "md",
    type: String,
  }) readonly size!: string
  @Prop({
    default: "",
    type: String,
  }) readonly gap!: string
  @Prop({
    default: false,
    type: Boolean,
  }) readonly bordered: boolean
  @Prop({
    default: true,
    type: Boolean,
  }) readonly userEnabled: boolean
  error = false

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  get defaultAvatarColor(): string {
    return this.isDark ? "#363945" : "#fff"
  }

  get hasSrc(): boolean {
    return !!this.src && !this.error
  }

  get isDisableUserDefaultAvatarShow() {
    return !this.userEnabled && this.type === "avatarGroup"
  }

  onErrorHandler() {
    this.error = true
  }
  get defaultPicture() {
    switch (this.type) {
      case "avatarGroup":
        return {
          data: this.defaultPictureList.avatarGroup,
          fill: true,
        }
      case "addTeamsGroup":
        return {
          data: this.defaultPictureList.addTeamsGroup,
          fill: true,
        }
      case "teamsGroup":
        return {
          data: this.defaultPictureList.teamsGroup,
          fill: true,
        }
      default:
        return {
          data: this.defaultPictureList.avatarGroup,
          fill: true,
        }
    }
  }

  @Emit()
  removeAvatar() {
    return this.index
  }
}
</script>
