import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/favorite-folder.svg'


const _hoisted_1 = { class: "label-pic is-green" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    projectName: _ctx.notification.projectName,
    class: "notification__item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" There's a New Project for you ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" You’ve been added to join "),
          _createElementVNode("a", {
            href: _ctx.notification.acceptanceUrl,
            class: "link .is-medium-500"
          }, "Project " + _toDisplayString(_ctx.notification.projectName), 9, _hoisted_2),
          _createTextVNode(" at " + _toDisplayString(_ctx.notification.companyName) + ". ", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["notification", "projectName"]))
}