import { Notification } from "./Notification"

export class NotificationTodo extends Notification {
  constructor(props) {
    super(props)
  }

  get toDos() {
    return this.content?.newTodo ?? []
  }

  get projectName() {
    return this.toDos[0].project?.name
  }

  getProjectId(todo) {
    return { ...todo, projectId: todo.project?.id }
  }
}
