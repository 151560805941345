import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/download.svg'


const _hoisted_1 = ["id"]
const _hoisted_2 = {
  type: "button",
  class: "custom-file-simplified__content"
}
const _hoisted_3 = { class: "custom-file-simplified__size__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePic = _resolveComponent("FilePic")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_ProgressLoadingLine = _resolveComponent("ProgressLoadingLine")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.file.id,
    class: _normalizeClass(["custom-file-simplified", [`custom-file-simplified_${_ctx.fileMarkup}`]])
  }, [
    _createElementVNode("div", {
      class: "custom-file-simplified__in",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.showImage()), ["prevent"]))
    }, [
      _createVNode(_component_FilePic, { class: "custom-file-simplified__pic" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseSubHeading, {
          weight: "400",
          size: "medium",
          className: "custom-file-simplified__cut-name",
          color: "monochrome-07"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.file.name), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.progress === 0)
            ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                key: 0,
                weight: "500",
                size: "extra-small",
                className: "custom-file-simplified__size",
                color: "monochrome-05",
                darkColor: "monochrome-04"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.fileSize), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ProgressLoadingLine, {
                key: 1,
                class: "custom-file-simplified__progress",
                progress: _ctx.progress
              }, null, 8, ["progress"]))
        ])
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "custom-file-simplified__download",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFileDownload && _ctx.onFileDownload(...args)), ["prevent","stop"]))
      }, [
        _createVNode(_component_icon, {
          data: _imports_0,
          fill: true,
          color: "var(--monochrome05to04)",
          width: "24",
          height: "24"
        })
      ])
    ])
  ], 10, _hoisted_1))
}