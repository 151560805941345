import { LogError } from "@/models/Error/LogError"

class ErrorHandlerService {
  static logError: (error: LogError) => void

  init(logError: (error: LogError) => void) {
    ErrorHandlerService.logError = logError
    window.onerror = this.onWindowError
  }

  onWindowError(message, source, lineno, colno, error) {
    console.error("window error", message, source, lineno, colno, error)
    if (!ErrorHandlerService.logError) {
      return
    }

    const tags = {
      pageUrl: window.location.href,
    } as { [key: string]: string }

    if (source) {
      tags.source = source
    }
    if (lineno) {
      tags.lineno = lineno.toString()
    }
    if (colno) {
      tags.colno = colno.toString()
    }
    if (error) {
      tags.error = error.toString()
    }

    const logError = new LogError(message, { type: "window", ...tags })
    ErrorHandlerService.logError(logError)
  }

  onAppError(err, info) {
    console.error(`app error: ${err?.stack ? err.stack : ""}`, { err, info })
    if (!ErrorHandlerService.logError) {
      return
    }

    const tags = {
      pageUrl: window.location.href,
    } as { [key: string]: string }
    let message = err

    if (info) {
      tags.info = info
    }

    if (err?.stack) {
      message = err.stack
    }

    const logError = new LogError(message, { type: "app", ...tags })
    ErrorHandlerService.logError(logError)
  }

  onApiError(error) {
    if (!ErrorHandlerService.logError) {
      return
    }

    const tags = {
      pageUrl: window.location.href,
    } as { [key: string]: string }

    let message = error

    if (error?.request?.responseURL) {
      tags.url = error.request.responseURL
    }

    if (error?.message) {
      message = error.message
    }

    if (error?.response?.data?.message) {
      tags.message = error.response.data.message
    }

    if (error?.response?.data?.timestamp) {
      tags.timestamp = error.response.data.timestamp
    }

    if (error?.response?.status) {
      tags.status = error.response.status
    }

    if (error?.response?.statusText) {
      tags.statusText = error.response.statusText
    }

    const logError = new LogError(message, { type: "api", ...tags })
    ErrorHandlerService.logError(logError)
  }

  customLogError(message) {
    const logError = new LogError(message, {
      type: "customError",
      pageUrl: window.location.href,
    })
    ErrorHandlerService.logError(logError)
  }
}

export const errorHandlerService = new ErrorHandlerService()
