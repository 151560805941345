<template>
  <DropdownTooltip
    v-if="projectName"
    placement="bottom-start"
    placementSub="right-start"
    :menu="options"
    popperClassSub="sprint-selection header__breadcrumb-menu"
    optionMenuClass="header__breadcrumb-menu-item"
    popperClass="project-selection header__breadcrumb-menu"
    subMenuClass="header__breadcrumb-menu-item"
    submenuWaitTitle="projectTeams"
    @dropdownOptionMenu="onTeamClick"
  >
    <template #tooltip-label>
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        className="header__breadcrumb"
      >
        /<span>{{ projectName }}</span>
      </BaseSubHeading>
    </template>
    <template #tooltip-item="{ item }">
      <router-link
        v-if="item?.to"
        class="header__breadcrumb-menu-item_backlogs router-link-wrapper"
        :to="item.to"
      >
        <span class="multiselect__option_team-name">{{ item.label }}</span>
        <icon
          v-if="!item.hideOptions"
          width="6px"
          height="8px"
          data="@icon/triangle_right.svg"
          fill="false"
          :color="iconColor"
          class="team-selection__arrow"
        />
      </router-link>
      <div
        v-else
        class="header__breadcrumb-menu-item_backlogs"
      >
        <span class="multiselect__option_team-name">{{ item.label }}</span>
        <icon
          v-if="!item.hideOptions"
          width="6px"
          height="8px"
          data="@icon/triangle_right.svg"
          fill="false"
          :color="iconColor"
          class="team-selection__arrow"
        />
      </div>
    </template>
    <template #tooltip-submenu-item="{ option }">
      <router-link
        class="sprint-label router-link-wrapper"
        :to="{
          name: 'backlog',
          params: {
            projectId: selectedProject.urlName,
            teamId: option.urlName,
          },
        }"
      >
        <span class="multiselect__option__avatar">
          <Avatar
            :src="option.picture?.pictureUrl"
            :size="optionLabelSize"
            type="teamsGroup"
            class="user__avatar"
          />
        </span>
        <span class="multiselect__option__name">{{ option.name }}</span>
      </router-link>
    </template>
  </DropdownTooltip>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import { Project, Team } from "@/models"
import Avatar from "../Avatar/index.vue"

interface OptionMenu {
  label: string,
  id: string,
  options?: any[],
  hideOptions?: boolean,
  to?: any
}

@Options({
  name: "BreadcrumbsProjectsBlock",
  components: {
    DropdownTooltip,
    Avatar,
  },
})
export default class BreadcrumbsProjectsBlock extends Vue {
  @Prop({ default: "sm" }) optionLabelSize: string

  @Getter(Getters.GET_SESSION_PROJECT_ID) sessionProjectId: string
  @Getter(Getters.GET_PROJECT) selectedProject: Project
  @Getter(Getters.GET_TEAMS) projectTeams: Team[]
  @Getter(Getters.THEME_IS_DARK) isDark: boolean
  @Action(Actions.GET_CLEAR_PROJECT_TEAMS) clearTeams: () => void


  get iconColor() {
    return this.isDark ? "#AFB4C0" : "#363945"
  }

  get options(): OptionMenu[] {
    const toBacklog = this.projectTeams.length === 1 ? {
      name: "backlog",
      params: {
        projectId: this.selectedProject.urlName,
        teamId: this.projectTeams[0].urlName,
      },
    } : false
    let allOptions: OptionMenu[] = [
      {
        label: "Project",
        id: "project",
        hideOptions: true,
        to: {
          name: "project",
          params: {
            projectId: this.selectedProject.urlName,
          },
          query: {
            back: "true",
          },
        },
      },
      {
        label: "Milestones",
        id: "milestones",
        hideOptions: true,
        to: { name: "Milestones" },
      },
      {
        label: "Product Backlog",
        id: "productBacklog",
        hideOptions: true,
        to: { name: "product-backlog" },
      },
      {
        label: "Epics",
        id: "epics",
        hideOptions: true,
        to: { name: "epics" },
      },
      {
        label: "Sprints",
        id: "sprints",
        hideOptions: true,
        to: {
          name: "sprints",
          params: {
            projectId: this.selectedProject.urlName,
          },
        },
      },
    ]
    if (this.projectTeams.length > 0) {
      allOptions.push({
        label: "Backlogs",
        id: "backlogs",
        options: this.projectTeams,
        hideOptions: this.projectTeams.length === 1,
        to: toBacklog,
      })
    }
    return allOptions
  }


  get projectName() {
    return this.selectedProject?.name ?? ""
  }

  async created() {
    this.clearTeams()
  }


  @Emit()
  onTeamClick({ value, hide }) {
    hide()
    return value
  }
}
</script>
