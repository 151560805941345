import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/task-icon.svg'


const _hoisted_1 = { class: "label-pic is-purple" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "notification-task__content" }
const _hoisted_4 = { class: "notification-task__name" }
const _hoisted_5 = { class: "notification-task__type" }
const _hoisted_6 = { class: "notification-task__estimation is-estimation" }
const _hoisted_7 = { class: "is-opacity notification-task__estimation__old" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__update-item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px",
          color: "#6A1B9A"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "link is-large",
            target: "_blank",
            href: _ctx.notification.itemUrl
          }, " Story " + _toDisplayString(_ctx.notification.itemSequenceNumber), 9, _hoisted_2),
          _createTextVNode(" Task List has been updated ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseSubHeading, {
          size: "medium",
          color: "monochrome-07",
          weight: "400",
          class: "notification-task__content__left"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["notification-task__status", [`is_${_ctx.notification.taskStatus.id.toLowerCase()}`]])
            }, _toDisplayString(_ctx.notification.taskStatus.label), 3),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.notification.taskName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseSubHeading, {
          size: "medium",
          color: "monochrome-06",
          weight: "500",
          class: "notification-task__content__right"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`task.types.${_ctx.notification.taskType.toLowerCase()}`)), 1),
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.notification.estimationOld), 1),
              (_ctx.notification.estimationOld)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " > "))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.notification.estimation) + " hr ", 1)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["notification"]))
}