<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__update-item"
  >
    <template #pic>
      <div class="label-pic is-teal">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}
        </a>
        Severity has been updated by <span class="notification__update-item__initiator">@{{ notification.initiatorFullName }}</span>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
        className="notification__update-item__content"
      >
        <span class="notification__update-item__severityLevel is-opacity">
          <SeverityLevelIcon :level="notification.getOldItemValue('severityLevel')" />
          {{ notification.getOldItemValue('severityLevel').toLowerCase() }}
        </span>
        <icon
          data="@icon/arrow-to.svg"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
        <span class="notification__update-item__severityLevel">
          {{ notification.getItemValue('severityLevel').toLowerCase() }}
          <SeverityLevelIcon :level="notification.getItemValue('severityLevel')" />
        </span>
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import SeverityLevelIcon from "@/components/SeverityLevel/SeverityLevelIcon.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "ITEM_UPDATE_SEVERITYLEVEL",
  components: {
    NotificationTemplate,
    SeverityLevelIcon,
  },
})
export default class ITEM_UPDATE_SEVERITYLEVEL extends Vue {
  @Prop() readonly notification: NotificationItem
}
</script>
