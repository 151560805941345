
import { Task } from "../Task"
import { Notification } from "./Notification"
import { $t } from "@/plugins/i18n"
import { replaceHtmlTags } from "@/utils/helpers"

export class NotificationTask extends Notification {
  task: Task

  constructor(props) {
    super(props)
    this.task = props.task
  }

  get taskStatus() {
    if (this.task?.oldEstimation && this.task.estimatedHours) {
      return {
        id: "IN_PROGRESS",
        label: "In Progress",
      }
    } else if (this.task.estimatedHours) {
      return {
        id: "NEW",
        label: "New",
      }
    } else {
      return {
        id: "COMPLETED",
        label: "Completed",
      }
    }
  }

  get taskName() {
    return replaceHtmlTags(this.task?.name)
  }

  get estimationOld() {
    return this.task?.oldEstimation ? this.task?.oldEstimation : null
  }

  get estimation() {
    return this.task?.estimatedHours ?? null
  }

  get taskType() {
    return this.task?.type ?? ""
  }

  get item() {
    return this.content?.item
  }

  get itemSequenceNumber() {
    return `#${this.item?.sequenceNumber ?? $t("none")}`
  }

  get itemUrl() {
    return this.item?.url
  }
}
