import { plugin as unleashPlugin } from "@unleash/proxy-client-vue"
import { config as appConfig } from "@/app.config" 

const { VUE_APP_UNLEASH_FE_API_TOKEN, VUE_APP_FILES_LOCATION } = appConfig
const UNLEASH_APP_NAME = "fe-project-simple"
const UNLEASH_URL = `${VUE_APP_FILES_LOCATION}/unleash/api/frontend`
const UNLEASH_REFRESH_INTERVAL = 15

export const config = {
  url: UNLEASH_URL,
  clientKey: VUE_APP_UNLEASH_FE_API_TOKEN,
  refreshInterval: UNLEASH_REFRESH_INTERVAL,
  appName: UNLEASH_APP_NAME
}

export const unleash = {
  unleashPlugin,
  config: VUE_APP_UNLEASH_FE_API_TOKEN ? config : undefined
}