<template>
  <button
    type="button"
    class="secondary-button-sm btn footer__button"
    :tabindex="0"
    @click.prevent="onAction"
  >
    <icon
      data="@icon/unfold.svg"
      :fill="true"
      width="24"
      height="24"
      color="#81889B"
    />

    <span class="widget-text">ToDo ({{ count > 99 ? '99+' : count }})</span>
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"

const components = {}

@Options({
  name: "ToDoWidgetButton",
  components,
})
export default class ToDoWidget extends Vue {
  @Prop({ default: 0 }) readonly count: number

  @Emit("onClick")
  onAction() {}
}
</script>
