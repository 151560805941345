<template>
  <ToDoContent
    :class="className"
    class="to-do-view"
  >
    <template #header>
      <BaseHeading
        level="3"
        color="monochrome-06"
        darkColor="monochrome-07"
      >
        ToDo List
      </BaseHeading>
    </template>
    <template #content>
      <div class="to-do__menu">
        <BaseSubHeading
          v-for="(item) in toDoMenu"
          :key="item.id"
          size="large"
          weight="400"
          class="to-do__menu__item"
          :class="{ 'is-active': selectedMenuItem === item.id }"
          @click="onSelectMenuItem(item.id)"
        >
          {{ item.label }}
        </BaseSubHeading>
      </div>
      <Observer
        :key="selectedMenuItem"
        v-scroll="handleScroll"
        class="to-do__item__list custom-scroll"
        :items="toDos"
        :last="isLast"
        :options="observerOptions"
        @intersect="scroll"
      >
        <template #default="{ item, index }">
          <ToDoItem
            :key="item.id"
            :item="item"
            :index="index"
            :sm="true"
            :isSent="selectedMenuItem === 'sent'"
            @onCheckboxClick="onCheckboxClick"
            @onEdit="onEditToDo"
          />
        </template>
      </Observer>
    </template>
    <template #footer>
      <button
        class="link is-large to-do__add-button"
        @click.prevent="$emit('addToDo')"
      >
        <icon
          data="@icon/plus.svg"
          :fill="true"
          width="24"
          height="24"
        />
        Add ToDo
      </button>
      <Transition
        @beforeLeave="onCloseToDoAnimation"
        @beforeEnter="onOpenToDoAnimation"
      >
        <ToDoAdd
          v-if="expandToDo"
          className="to-do__edit"
          @onCancel="onCloseToDo"
        />
      </Transition>
    </template>
  </ToDoContent>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ToDoContent from "@/components/ToDo/ToDoContent.vue"
import { handleScrollOnElement } from "@/mixins/handleScrollOnElement"
import Observer from "@/components/Observer/index.vue"
import ToDoItem from "@/components/ToDo/ToDoItem.vue"
import { Prop } from "vue-property-decorator"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { ToDo, ToDoStatus } from "@/models/ToDo"
import { FilterToDo, ServerResponse, User } from "@/models"
import ToDoAdd from "@/components/ToDo/ToDoAdd.vue"
import { scaleAnimation } from "@/utils/gsapAnimation"

const components = {
  ToDoContent,
  Observer,
  ToDoItem,
  ToDoAdd,
}

@Options({
  name: "ToDoView",
  components,
})
export default class ToDoView extends mixins(handleScrollOnElement) {
  @Prop({ default: "" }) className: string

  @Getter(Getters.GET_TODOS) readonly toDos: ToDo[]
  @Getter(Getters.GET_TODOS_SERVER_RESPONSE) readonly toDosServerResponse: ServerResponse<ToDo>
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User
  @Getter(Getters.GET_OPENED_TODO_COUNT) readonly openedCount: number

  @Action(Actions.GET_TODOS) getToDos: (filterToDo : FilterToDo) => void
  @Action(Actions.GET_OPENED_TODO_COUNT) getOpenedToDoCount: () => void
  @Action(Actions.TODO_ITEM_RESOLVE) toDoResolve: (todoItemId : string) => Promise<ToDo>
  @Action(Actions.TODO_ITEM_UNRESOLVE) toDoUnresolve: (todoItemId : string) => Promise<ToDo>
  @Action(Actions.REMOVE_TODO_FROM_LIST) removeToDoFromList: (todoItemId : string) => void
  @Action(Actions.SET_TODO) setToDo: (todo: ToDo) => void
  @Action(Actions.SET_TODO_IN_LIST) setToDoInList: (todo: ToDo) => void
  @Action(Actions.RESET_TO_DOS) resetToDos: () => void

  filterToDo = new FilterToDo()
  selectedMenuItem = "assignedToMe"
  expandToDo = false
  observerOptions = {
    root: null,
    scrollMargin: "0px 0px 160px 0px",
    threshold: 0.5,
  }
  get toDoMenu() {
    return [
      {
        id: "assignedToMe",
        label: `Assigned to Me (${this.openedCount})`,
      },
      {
        id: "completed",
        label: "Completed",
      },
      {
        id: "sent",
        label: "Sent to Others",
      },
    ]
  }

  get isLast() {
    return this.toDosServerResponse?.last ?? true
  }

  created() {
    this.onUpdateFilterToDo()
    this.getOpenedToDoCount()
  }

  async onUpdateFilterToDo() {
    this.filterToDo = new FilterToDo()
    this.filterToDo.addSort("createdDate", false)
    switch (this.selectedMenuItem) {
      case "assignedToMe":
        this.filterToDo.status = "ACTIVE"
        this.filterToDo.completedByMe = false
        break
      case "sent":
        this.filterToDo.status = undefined
        this.filterToDo.createdByMe = true
        break
      case "completed":
        this.filterToDo.status = undefined
        this.filterToDo.completedByMe = true
        break
    }
    this.resetToDos()
    await this.getToDos(this.filterToDo)
  }


  onSelectMenuItem(id) {
    this.selectedMenuItem = id
    this.onUpdateFilterToDo()
  }

  async onCheckboxClick({ status, toDoItemId } : { status: ToDoStatus, toDoItemId: string }) {
    let updatedToDo: ToDo
    switch (status) {
      case "ACTIVE":
        updatedToDo = await this.toDoUnresolve(toDoItemId)
        break
      case "COMPLETED":
        updatedToDo = await this.toDoResolve(toDoItemId)
        break
      default:
        return
    }

    if (!updatedToDo) return

    if (!this.filterToDo.status && !this.filterToDo.completedByMe) {
      this.setToDoInList(updatedToDo)
      return
    }
    this.removeToDoFromList(toDoItemId)
    this.getOpenedToDoCount()
  }

  scroll() {
    this.filterToDo.page++
    this.getToDos(this.filterToDo)
  }

  onEditToDo(toDo: ToDo) {
    this.setToDo(toDo)
    this.expandToDo = true
  }

  onCloseToDo() {
    this.expandToDo = false
  }

  onCloseToDoAnimation(element) {
    scaleAnimation(element, 0.3).reverse(0)
  }

  onOpenToDoAnimation(element) {
    scaleAnimation(element, 0.3).play()
  }
}
</script>
