import axios from "axios"
import { updateToken } from "@/plugins/keycloak"
import { router } from "@/router"
import { BASE_URL } from "@/services/endpoints"

const AUTHORIZATION_HEADER = "Authorization"

export const applyInterceptors = async () => {
  await axios.interceptors.request.use(async config => {
    const token = await updateToken()
    if (config.headers) {
      config.headers.common[AUTHORIZATION_HEADER] = `Bearer ${token}`
      config.baseURL = BASE_URL
    }
    return config
  })

  await axios.interceptors.response.use((response) => {
    return response
  }, error => {
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}
