import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "body-text body-text-medium-400",
  tabindex: -1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_file_upload = _resolveComponent("file-upload")!

  return (_openBlock(), _createBlock(_component_file_upload, {
    ref: "upload",
    name: _ctx.id,
    class: "custom-editor__button",
    multiple: true,
    drop: true,
    dropDirectory: true,
    tabindex: -1,
    onInput: _ctx.onInput
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _ctx.icon,
          fill: true,
          color: "#363945",
          width: "1.25em",
          height: "1.25em"
        }, null, 8, ["data"])
      ])
    ]),
    _: 1
  }, 8, ["name", "onInput"]))
}