<template>
  <div class="profile-menu">
    <button
      v-click-outside="hide"
      @click="toggle"
    >
      <Avatar
        :src="user ? user.pictureUrl : ''"
        :gap="user ? user.getInitials() : ''"
        size="lg"
      />
    </button>
    <ul
      v-if="opened"
      class="profile-menu__list"
    >
      <template
        v-for="(item, index) in menu"
      >
        <li
          v-if="item.visible"
          :key="index"
          class="profile-menu__item"
        >
          <button
            v-if="item.action"
            class="profile-menu__button"
            @click.prevent="onClick(item.action)"
          >
            <icon
              :data="item.icon"
              :fill="item.fill"
              class="profile-menu__icon"
              :color="item.iconColor"
              width="1em"
              height="1em"
            />

            {{ item.label }}
          </button>
          <router-link
            v-else
            class="profile-menu__button"
            :to="item.to"
          >
            <icon
              :data="item.icon"
              :fill="item.fill"
              class="profile-menu__icon"
              :color="item.iconColor"
              width="1em"
              height="1em"
            />

            {{ item.label }}
          </router-link>
        </li>
      </template>
    </ul>
    <ModalTheme />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Getter } from "s-vuex-class"
import { directive as ClickOutside } from "click-outside-vue3"
import { useAcl } from "vue-simple-acl"

import Avatar from "@/components/Avatar/index.vue"
import { Account, User } from "@/models"
import { Getters } from "@/store"
import { logout as keycloakLogout } from "@/plugins/keycloak"

import user from "@icon/user.svg"
import logout from "@icon/logout.svg"
import mail from "@icon/mail.svg"
import accounts from "@icon/accounts.svg"
import theme from "@icon/theme.svg"
import ModalTheme from "../Modal/ModalTheme.vue"

interface IMenu {
  id: string
  icon: any;
  iconColor?: string;
  label?: string;
  to?: any;
  fill?: boolean;
  visible?: boolean;
  action?: string;
}

@Options({
  name: "HeaderProfileMenu",
  components: {
    Avatar,
    ModalTheme,
  },
  directives: {
    ClickOutside,
  },
})
export default class HeaderProfileMenu extends Vue {
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly currentUser: User
  @Getter(Getters.GET_USER_CURRENT_PROFILE) user: User
  @Getter(Getters.GET_CURRENT_ACCOUNT_ID) accountId: string
  @Getter(Getters.GET_ACCOUNT) readonly account: Account
  opened = false
  menu: IMenu[] = []

  get isSuperAdmin() {
    return useAcl().can("superAdmin")
  }

  get isAdmin() {
    return useAcl().can.any(["admin", "superAdmin"])
  }

  mounted() {
    const routeToAccount = this.isSuperAdmin ? {
      name: "accounts",
    } : {
      name: "edit-account",
      query: {
        accountId: this.user.accountId,
      },
    }

    this.menu = [
      {
        id: "profile",
        icon: user,
        iconColor: "#2A2B36",
        label: "Profile",
        fill: false,
        visible: true,
        to: {
          name: "edit-user",
          params: {
            accountId: this.user.accountName,
            userId: this.user.id,
          },
        },
      },
      {
        id: "templates",
        icon: mail,
        iconColor: "#2A2B36",
        label: "Templates",
        fill: false,
        visible: this.isSuperAdmin,
        to: {
          name: "emails",
        },
      },
      {
        id: "accounts",
        icon: accounts,
        iconColor: "#2A2B36",
        label: this.isSuperAdmin ? "Accounts" : "My Account",
        to: routeToAccount,
        fill: true,
        visible: true,
      },
      {
        id: "theme",
        icon: theme,
        iconColor: "#2A2B36",
        label: "Theme",
        fill: true,
        visible: true,
        action: "theme",
      },
      {
        id: "logout",
        icon: logout,
        iconColor: "#7D92A7",
        label: "Log out",
        to: "logOut",
        fill: false,
        visible: true,
        action: "logOut",
      },
    ]
  }

  toggle() {
    this.opened = !this.opened
    if (this.opened) {
      this.menu = this.menu.map((e) => {
        if (e.id === "accounts") {
          e.visible = this.isAdmin
        }
        return e
      })
    }
  }

  hide() {
    this.opened = false
  }

  async toUserProfile() {
    if (!this.user.id || !this.user.accountName) return

    this.$router.push({
      name: "edit-user",
      params: {
        accountId: this.user.accountName,
        userId: this.user.id,
      },
    })
  }

  toTemplates() {
    this.$router.push({
      name: "emails",
    })
  }

  toAccounts() {
    if (this.isSuperAdmin) {
      this.$router.push({
        name: "accounts",
      })
    } else if (this.user.accountId) {
      this.$router.push({
        name: "edit-account",
        query: {
          accountId: this.user.accountId,
        },
      })
    }
  }

  onClick(action) {
    switch (action) {
      case "logOut": this.logout(); break
      case "theme": this.openThemeModal(); break
      default:
        break
    }
  }

  openThemeModal() {
    this.$modal.show("modal-theme")
  }

  logout() {
    keycloakLogout()
  }
}
</script>
