<template>
  <Transition>
    <div
      v-if="loading"
      class="sprinner"
      :class="className"
      :style="{
        right: `${right}px`,
      }"
    >
      <span class="spin-animation">
        <slot>
          <icon
            data="@icon/spinner.svg"
            original
            :width="size"
            :height="size"
            class="spin-animation"
          />
        </slot>
      </span>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
const components = {}

@Options({
  components,
})
export default class Spinner extends Vue {
  @Prop({ default: false }) readonly loading: boolean
  @Prop({ default: 18 }) readonly right: number
  @Prop({ default: 18 }) readonly size: number
  @Prop({ default: "" }) readonly className: string
}

</script>
