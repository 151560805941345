import { BaseModule } from "@/models/BaseModule"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { Action, Module } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"
import { AccountActions, accountModule } from "./account.module"
import { ChatGPTResponse } from "@/models"

export enum ChatGPTActions {
  CHAT_GPT_ASK = "CHAT_GPT_ASK",
  CHAT_GPT_RESET = "CHAT_GPT_RESET",
}

@Module
class ChatGPTModule extends BaseModule {
  get accountId() {
    return accountModule.accountId
  }

  @Action
  async [ChatGPTActions.CHAT_GPT_ASK](prompt: string) {
    const data = await apiService.post<ChatGPTResponse>(Endpoint.CHAT_GPT_ASK(this.accountId), prompt)
    return this.handleResponse<ChatGPTResponse>(data, data => data, true)
  }

  @Action
  async [ChatGPTActions.CHAT_GPT_RESET]() {
    const data = await apiService.post<void>(Endpoint.CHAT_GPT_RESET(this.accountId))
    return this.handleResponse<void>(data, undefined, true)
  }
}

export const chatGPTModule = new ChatGPTModule({
  store,
  name: Modules.CHAT_GPT,
})
