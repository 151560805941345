import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '@icon/loupe.svg'
import _imports_1 from '@icon/close2.svg'


const _hoisted_1 = ["id", "placeholder", "value"]
const _hoisted_2 = {
  key: 2,
  class: "error-text bordered-input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    ref: "basicInput",
    name: _ctx.id,
    rules: _ctx.rules,
    modelValue: _ctx.content
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["basic-input", [_ctx.computedClass, {'basic-input_results' : _ctx.showSearchResult, 'is-error': errors[0]}]])
      }, [
        (_ctx.searchIcon)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _imports_0,
              fill: false,
              color: _ctx.isDark ? 'var(--monochrome-03)' : 'var(--monochrome-05)',
              width: "1.5em",
              height: "1.5em",
              class: "basic-input__search-icon"
            }, null, 8, ["color"]))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          id: _ctx.id,
          ref: _ctx.elRef,
          type: "text",
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInput($event.target.value))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onChange', $event)))
        }, null, 40, _hoisted_1),
        (_ctx.modelValue.length && _ctx.showSearchResult)
          ? _renderSlot(_ctx.$slots, "searchResult", { key: 1 })
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: _normalizeClass(["basic-input__remove", {'is-show': _ctx.showRemoveButtonAlways || _ctx.modelValue.length && _ctx.showRemoveButton}]),
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('remove')), ["prevent"]))
        }, [
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: true,
            width: "1em",
            height: "1em"
          })
        ], 2),
        (errors[0])
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(errors[0]), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["name", "rules", "modelValue"]))
}