import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "custom-scroll dropdown-menu__list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ObserverComponent = _resolveComponent("ObserverComponent")!
  const _component_VDropdown = _resolveComponent("VDropdown")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    id: _ctx.id,
    ref: `dropdownMenuRef_${_ctx.id}`,
    distance: _ctx.distance,
    skidding: _ctx.skidding,
    placement: _ctx.placement,
    triggers: _ctx.triggers,
    autoHide: _ctx.autoHide,
    class: _normalizeClass([_ctx.className, "dropdown-menu"]),
    popperClass: _ctx.popperClass,
    shown: _ctx.shown,
    delay: _ctx.delay,
    theme: "info-dropdown",
    onApplyShow: _ctx.onApplyShow,
    onApplyHide: _ctx.onApplyHide,
    onShow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onShow')))
  }, {
    popper: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-list", { hideDropdown: _ctx.hideDropdown }),
      _renderSlot(_ctx.$slots, "list", { hideDropdown: _ctx.hideDropdown }, () => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              ref_for: true,
              ref: `listOfElementRefs_${_ctx.id}`,
              class: _normalizeClass([[{'is-selected' : index === _ctx.selectedElementIndex}, value?.class], "dropdown-menu__item"]),
              onClick: ($event: any) => (_ctx.onSelect({value, index}))
            }, [
              _renderSlot(_ctx.$slots, "item", {
                value: value,
                index: index,
                hideDropdown: _ctx.hideDropdown
              })
            ], 10, _hoisted_2))
          }), 128)),
          _createVNode(_component_ObserverComponent, {
            last: _ctx.isLast,
            onIntersect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onScroll')))
          }, null, 8, ["last"])
        ])
      ]),
      _renderSlot(_ctx.$slots, "after-list", { hideDropdown: _ctx.hideDropdown })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "label", { hideDropdown: _ctx.hideDropdown })
    ]),
    _: 3
  }, 8, ["id", "distance", "skidding", "placement", "triggers", "autoHide", "class", "popperClass", "shown", "delay", "onApplyShow", "onApplyHide"]))
}