import { Role } from "."

export interface InviteErrorConfig {
  email: string,
  accountName: string,
  type: InvitatonStatus,
  role?: Role,
  canAddMember?: boolean
}

export enum InvitatonStatus {
  SENT = "SENT",
  FAILED = "FAILED",
  RESENT = "RESENT",
  ACCEPTED = "ACCEPTED",
  ALREADY_CREATED_IN_OTHER_ACCOUNT = "ALREADY_CREATED_IN_OTHER_ACCOUNT",
  ALREADY_CREATED_IN_CURRENT_ACCOUNT = "ALREADY_CREATED_IN_CURRENT_ACCOUNT",
  ALREADY_INVITED_IN_OTHER_ACCOUNT = "ALREADY_INVITED_IN_OTHER_ACCOUNT",
  ALREADY_INVITED_IN_CURRENT_ACCOUNT = "ALREADY_INVITED_IN_CURRENT_ACCOUNT",
  NOT_CREATED = "NOT_CREATED",
}

export class InvitationPayload {
  inviteeTeamRole = ""
  inviteeAccountRole = ""
  accountId = ""
  projectId = ""
  teamId = ""
  inviteeName = ""
  firstName = ""
  lastName = ""
  inviteeEmail = ""
  externalResourceId? = {
    id: "",
    importId: "",
  }

  constructor(props?: Partial<InvitationPayload>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  getJsonObj() {
    const data = {
      inviteeTeamRole: this.inviteeTeamRole,
      inviteeAccountRole: this.inviteeAccountRole,
      accountId: this.accountId,
      projectId: this.projectId,
      teamId: this.teamId,
      inviteeName: this.inviteeName,
      firstName: this.firstName,
      lastName: this.lastName,
      inviteeEmail: this.inviteeEmail.trim(),
      externalResourceId: this.externalResourceId ? {
        ...this.externalResourceId,
      } : undefined,
    }
    return data
  }
}

export interface Invitation {
  inviteeEmail: string;
  inviteeName: string;
  inviteeTeamRole: Role;
  inviteeAccountRole: Role;
  firstName?: string;
  lastName?: string;
  expiresOn?: string;
  id?: string;
  teamId?: string;
  projectId?: string;
  statusUpdates?: {
    [key: string]: InvitatonStatus;
  }
}
