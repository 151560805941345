import { Task } from "@/models"
import { Vue, Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import isMacintosh from "@/helpers/checkMacintosh"
import eventKey from "@/helpers/eventKeyHelper"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
const components = {

}
@Options({
  components,
  directives: {
    focus: {
      updated(el, binding) {
        el.tabIndex = -1
        if (binding.value) {
          el.tabIndex = 0
          el.focus()
        }
        if (!binding.oldValue) {
          el.tabIndex = 0
        }
      },
    },
  },
})
export default class KeyboardNavigation extends Vue {
  @Getter(Getters.KEYBOARD_NAVIGATION_ID) activeElementId: string

  @Action(Actions.KEYBOARD_NAVIGATION_ID) activateFocus: (id: string) => void

  @Prop({
    default: [],
  }) tasks: Task[]
  declare idList
  // id = "name_0"
  unFocusElement = false

  mounted() {
    document.addEventListener("keydown", this.onKeyDown)
    document.addEventListener("mousedown", this.onClick)
  }

  beforeUnmount() {
    document.addEventListener("keydown", this.onKeyDown)
    document.addEventListener("mousedown", this.onClick)
  }

  get isActive() {
    return elId => elId === this.activeElementId
  }

  get isMacintosh():boolean {
    return isMacintosh()
  }

  onKeyDown(event) {
    if (!this.activeElementId) return false
    if (event.target.id !== this.activeElementId) return false
    const checkSystemKey = this.isMacintosh ? event.metaKey : event.ctrlKey
    // eslint-disable-next-line prefer-const
    let [id, index] = event.target.id.split("_")
    index = Number(index)

    this.unFocusElement = isNaN(index)

    if (eventKey(event).arrowUpKey) {
      this.focusUpItem(id, index)
      return false
    }
    if (eventKey(event).arrowDownKey) {
      this.focusDownItem(id, index, event)
      return false
    }
    if (eventKey(event).shiftTabKey) {
      event.preventDefault()
      this.focusLeftItem(id, index)
      return false
    }
    if (eventKey(event).tabKey) {
      event.preventDefault()
      this.focusRightItem(id, index)
      return false
    }
    if (eventKey(event).enterKey && !checkSystemKey) {
      this.focusDownItem(id, index, event)
      return false
    }
  }

  onClick(event) {
    // eslint-disable-next-line prefer-const
    let [id, index] = event.target.id.split("_")
    index = Number(index)
    this.activateFocus(`${id}_${index}`)
    this.unFocusElement = Boolean(isNaN(index) && this.activeElementId)
  }

  focusUpItem(id:string, index:number) {
    if (index === 0) {
      this.focusUpPrevItem()
      return false
    }
    this.activateFocus(`${id}_${index - 1}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  focusUpPrevItem() {}

  focusDownItem(id:string, index:number, event) {
    const elementIndex = this.getElementIndexById(id)
    if (elementIndex === -1) return false
    else if (this.idList[elementIndex]?.dropdown) {
      this.focusOnDropdown(index)
      event.preventDefault()
      this.activateFocus("")
    } else if (index < this.tasks.length - 1) {
      this.activateFocus(`${id}_${index + 1}`)
    } else {
      this.focusDownLastItem()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  focusDownLastItem() {}

  focusRightItem(id:string, index:number) {
    let elementIndex = this.getElementIndexById(id)
    let nextId = ""
    let nextIndex = index

    if (this.idListLength > elementIndex) {
      elementIndex++
    } else {
      elementIndex = 0
      nextIndex = index + 1
    }
    nextId = this.idList[elementIndex].id
    this.activateFocus(`${nextId}_${nextIndex}`)
  }

  focusLeftItem(id:string, index:number) {
    let elementIndex = this.getElementIndexById(id)
    let prevId = ""
    let prevIndex = index
    if (0 < elementIndex) {
      elementIndex--
    } else {
      elementIndex = this.idListLength
      prevIndex = index - 1
    }
    prevId = this.idList[elementIndex].id

    this.activateFocus(`${prevId}_${prevIndex}`)
  }

  // activateFocus(focusId) {
  //   this.id = focusId
  // }

  getElementIndexById(id:string) {
    if (id) {
      return this.idList.findIndex(element => element.id === id)
    }
  }

  get idListLength() {
    return this.idList.length - 1
  }

  focusOnDropdown(index:number) {
    document.getElementById(`typeParsed_${index}`)!.focus()
  }

  checkOpenDropdown({ openDropdown, index }:{openDropdown:boolean, index:number}) {
    if (!openDropdown) {
      this.activateFocus(`type_${index}`)
    }
  }
}
