export class Sort {
  field: string
  isAsc: boolean

  constructor(field: string, isAsc: boolean) {
    this.field = field
    this.isAsc = isAsc
  }

  static create(field: string, isAsc: boolean): Sort {
    return new Sort(field, isAsc)
  }

  getString(): string {
    return this.field + "," + (this.isAsc ? "ASC" : "DESC")
  }
}
