<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__item"
  >
    <template #pic>
      <div
        class="label-pic"
        :class="picColor"
      >
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        {{ title }}
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__active-sprint-add-item notification__item-content"
      >
        <a
          class="link is-medium-500"
          target="_blank"
          :href="notification.itemUrl"
        >
          {{ notification.itemSequenceNumber }}
        </a>
        <span class="notification__item-content__title">
          {{ notification.getItemValue('title') }}
        </span>
      </BaseSubHeading>

      <div
        v-for="(task, index) in notification.tasks"
        :key="index"
        class="notification__item-content-task-line"
      >
        <span class="task-number">{{ task.order }}</span>
        <span class="task-name">{{ notification.taskNames[index] }}</span>
        <span class="task-type">{{ getTaskTypeLabel(task.type) }}</span>
        <span class="task-estimation">{{ task.estimatedHours }}&nbsp;hr</span>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification"
import { Task } from "@/models"

@Options({
  name: "ITEM_UPDATE_ACTIVE_SPRINT_ADDED",
  components: {
    NotificationTemplate,
  },
})
export default class ITEM_UPDATE_ACTIVE_SPRINT_ADDED extends Vue {
  @Prop() readonly notification: NotificationItem

  get title() {
    return this.notification.item?.sprintSequenceNumber ? `New ${this.itemTypeLabel} has been added to the Active Sprint` : `${this.itemTypeLabel} removed from Active Sprint`
  }

  get itemTypeLabel() {
    switch (this.notification.item?.itemType) {
      case "SUPPORT_CASE":
        return "Support Case"
      case "BUG":
        return "Bug"
      default:
        return "Item"
    }
  }

  get picColor() {
    switch (this.notification.item?.itemType) {
      case "SUPPORT_CASE":
        return "is-orange"
      case "BUG":
        return "is-red"
      default:
        return "is-teal"
    }
  }

  getTaskTypeLabel(taskType) {
    return Task.types.find(x => x.id === taskType)?.label ?? ""
  }
}
</script>
