import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "@/store/modules"
import { store } from "@/store/store"
import { AlertActions, alertModule } from "./alert.module"
import { Endpoint } from "@/services/endpoints"
import {
  Invitation,
  InvitationPayload,
  InvitatonStatus,
} from "@/models/Invitation"
import { ServerResponse } from "@/models/ServerResponse"
import { AlertType, AlertContentItem } from "@/models/Alert"
import { User } from "@/models/User"
import { BaseModule } from "@/models/BaseModule"
import { accountModule } from "./account.module"
import { apiService } from "@/services/api.service"
import { FilterInvitation } from "@/models/Filter"

export enum InvitationActions {
  GET_INVITATIONS = "GET_INVITATIONS",
  SEND_INVITATION = "SEND_INVITATION",
  SEND_INVITATION_WITH_FEEDBACK = "SEND_INVITATION_WITH_FEEDBACK",
  RESEND_INVITATION_BY_ID = "RESEND_INVITATION_BY_ID",
  RESEND_INVITATION_BY_EMAIL = "RESEND_INVITATION_BY_EMAIL",
  ACCEPT_INVITATION = "ACCEPT_INVITATION",
  CANCEL_INVITATION = "CANCEL_INVITATION",
  CHECK_INVITATION = "CHECK_INVITATION",
  CLEAR_INVITATIONS_LIST = "CLEAR_INVITATIONS_LIST",
  REMOVE_INVITATION_FROM_LIST = "REMOVE_INVITATION_FROM_LIST",
  INVITE_TO_TEAM_LINK = "INVITE_TO_TEAM_LINK",
}

@Module
class InvitationModule extends BaseModule {
  invitations: Invitation[] = []

  get accountId() {
    return accountModule.accountId
  }

  get accountCreating() {
    return accountModule.accountCreating
  }

  get [`get/${InvitationActions.GET_INVITATIONS}`]() {
    return this.invitations
  }

  @Mutation
  setInvitations(invitations: Invitation[]) {
    this.invitations = invitations
  }

  @Mutation
  removeInvitationFromList(userId: string) {
    this.invitations = this.invitations.filter(invitation => invitation.id !== userId)
  }

  @Action
  async [InvitationActions.GET_INVITATIONS](params: FilterInvitation) {
    const filter = FilterInvitation.create(params)
    const data = await apiService.get<ServerResponse<Invitation>>(Endpoint.INVITATION(filter.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Invitation>>(data, (data) => {
      this.setInvitations(data.content)
    })
  }

  @Action
  async [InvitationActions.CHECK_INVITATION](email: string) {
    const data = await apiService.get<InvitatonStatus>(Endpoint.INVITATION_CHECK(this.accountId), {
      email,
    })
    return this.handleResponse<InvitatonStatus>(data, data => data)
  }

  @Action
  async [InvitationActions.SEND_INVITATION](payload: InvitationPayload[]) {
    const invites = payload.map(invite => invite.getJsonObj())
    const data = await apiService.post<ServerResponse<Invitation>>(Endpoint.INVITATION(this.accountId), {
      invites,
    })
    return this.handleResponse<ServerResponse<Invitation>>(data, () => {
      invites.forEach((p) => {
        const alertContent: AlertContentItem[] = [
          {
            text: "Invitation sent to ",
            type: "regular",
          },
          {
            text: p.inviteeEmail,
            type: "bold",
          },
        ]
        if (!this.accountCreating) {
          alertModule[AlertActions.SHOW_ALERT]({
            type: AlertType.SUCCESS,
            theme: "toast",
            content: alertContent,
          })
        }
      })
    })
  }

  @Action
  async [InvitationActions.SEND_INVITATION_WITH_FEEDBACK](payload: InvitationPayload[]) {
    const invites = payload.map(invite => invite.getJsonObj())
    const data = await apiService.post<ServerResponse<Invitation>>(Endpoint.INVITATION_WITH_FEEDBACK(this.accountId), {
      invites,
    })
    return this.handleResponse<ServerResponse<Invitation>>(data, data => data)
  }

  @Action
  async [InvitationActions.INVITE_TO_TEAM_LINK]({ teamId } : { teamId: string }) {
    const data = await apiService.post<string>(Endpoint.INVITE_TO_TEAM_LINK(this.accountId), {
      teamId,
    })
    return this.handleResponse<string>(data, data => data)
  }

  @Action
  async [InvitationActions.CANCEL_INVITATION](user: User) {
    const data = await apiService.post<string>(Endpoint.INVITATION_CANCEL(this.accountId, user.inviteId))

    return this.handleResponse<string>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Invitation for ",
          type: "regular",
        },
        {
          text: user.email,
          type: "bold",
        },
        {
          text: " canceled",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [InvitationActions.RESEND_INVITATION_BY_ID](user: User) {
    const data = await apiService.post<ServerResponse<Invitation>>(Endpoint.INVITATION_RESEND_BY_ID(this.accountId, user.inviteId))
    return this.handleResponse<ServerResponse<Invitation>>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Invitation resent to ",
          type: "regular",
        },
        {
          text: user.email,
          type: "bold",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }


  @Action
  async [InvitationActions.RESEND_INVITATION_BY_EMAIL](email: string) {
    const data = await apiService.post<ServerResponse<Invitation>>(Endpoint.INVITATION_RESEND_BY_EMAIL(this.accountId), null, {
      params: { email },
    })

    return this.handleResponse<ServerResponse<Invitation>>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Invitation resent to ",
          type: "regular",
        },
        {
          text: email,
          type: "bold",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [InvitationActions.ACCEPT_INVITATION](id: string) {
    const data = await apiService.get<void>(Endpoint.INVITATION_ACCEPT(id))
    this.handleResponse<void>(data, undefined, true)
  }

  @Action
  async [InvitationActions.CLEAR_INVITATIONS_LIST]() {
    this.setInvitations([])
  }

  @Action
  async [InvitationActions.REMOVE_INVITATION_FROM_LIST](userId: string) {
    this.removeInvitationFromList(userId)
  }
}

export const invitationModule = new InvitationModule({
  store,
  name: Modules.INVITATION,
})
