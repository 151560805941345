<template>
  <CustomModal
    :id="`m-create-epic_${id}`"
    modalClass="custom-modal_margin-custom custom-modal_delete-modal"
    @onBeforeOpen="clearInput"
  >
    <template #header>
      <div class="custom-modal__space-between">
        <div class="custom-modal__space-between">
          <h2>Create New Epic</h2>
        </div>
      </div>
    </template>

    <template #body="{ validators }">
      <form @submit.prevent>
        <BorderedInput
          id="newEpicName"
          :modelValue="newEpicName"
          placeholder="Epic Name"
          :validateOnChange="false"
          :rules="validators.isRequiredAndValid({ resourceTypes: 'EPIC', projectId }, 'Epic name')"
          @update:modelValue="handleInput"
          @enter="onCreateEpic"
        />
      </form>
    </template>

    <template #footer="{ hide, validate }">
      <ControlButtons
        @save="onCreateEpic(validate)"
        @cancel="hide"
      >
        <template #save>
          Create Epic
        </template>
      </ControlButtons>
    </template>
  </CustomModal>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import CustomModal from "@/components/Modal/index.vue"
import BorderedInput from "@/components/Form/BorderedInput.vue"
import DropdownBordered from "@/components/Dropdown/DropdownBordered.vue"
import { Options, Vue } from "vue-class-component"
import ControlButtons from "@/components/ControlButtons/index.vue"

import { Getters } from "@/store"
import { Getter } from "s-vuex-class"

@Options({
  name: "ModalCreateEpic",
  components: {
    CustomModal,
    BorderedInput,
    DropdownBordered,
    ControlButtons,
  },
})

export default class ModalCreateEpic extends Vue {
  @Prop({ default: "" }) readonly id: string

  @Getter(Getters.GET_SESSION_PROJECT_ID) projectId: string

  newEpicName = ""

  clearInput() {
    this.newEpicName = ""
  }

  handleInput(payload) {
    this.newEpicName = payload["value"]
  }

  hide() {
    this.$modal.hide(`m-create-epic_${this.id}`)
  }

  async onCreateEpic(validate) {
    const result = await validate()
    if (!result.valid) {
      return
    }
    this.$emit("onCreateEpic", this.newEpicName)
    this.hide()
  }
}
</script>
