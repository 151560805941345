import { Notification } from "./Notification"
import { ItemSeverityLevel } from "../Item"

export class NotificationBugWatermark extends Notification {
  level: ItemSeverityLevel

  constructor(props) {
    super(props)

    this.level = props.level
  }

  get breadcrumbs() {
    return `${this.content.projectName} / ${this.content.teamName}`
  }
}
