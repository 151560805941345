<template>
  <div
    :id="file.id"
    class="custom-file"
    :class="[`custom-file_${fileMarkup}`]"
  >
    <div class="custom-file__in">
      <template v-if="isElType">
        <FilePic @click.prevent="onFileDownload" />
        <span class="custom-file__desc">
          <button
            type="button"
            class="custom-file__name"
            @click.prevent="showImage()"
          >
            {{ file.name }}
          </button>

          <span
            class="custom-file__meta"
            :class="{ 'is-error': isOverSizeLimit }"
          >
            {{ fileMaxSize }}
          </span>
          <ProgressLoadingLine
            class="custom-file__progress"
            :class="[{ 'is-hidden': progress === 0 }]"
            :progress="progress"
          />
          <button
            v-if="removeButton"
            class="custom-file__remove"
            @click.stop="$emit('remove', file.id)"
          />
        </span>
      </template>

      <template v-if="isLineType">
        <button
          type="button"
          @click.prevent="showImage()"
        >
          <FilePic />
        </button>
        <div class="custom-file__content">
          <div class="custom-file__row">
            <button
              type="button"
              class="custom-file__row-item custom-file__row-left"
              @click.prevent="showImage()"
            >
              <BaseSubHeading
                weight="400"
                size="medium"
                className="custom-file__cut-name"
                color="monochrome-07"
              >
                {{ file.name }}
              </BaseSubHeading>

              <BaseSubHeading
                weight="500"
                size="extra-small"
                className="custom-file__size"
                color="monochrome-05"
                darkColor="monochrome-04"
              >
                {{ fileSize }}
              </BaseSubHeading>
            </button>
            <div class="custom-file__row-item custom-file__row-right">
              <button
                type="button"
                class="custom-file__download"
                @click.prevent.stop="onFileDownload"
              >
                <icon
                  data="@icon/download.svg"
                  :fill="true"
                  color="#7D92A7"
                  width="24"
                  height="24"
                />
              </button>

              <span
                v-tooltip.bottom="{ content: fileCreateDate, theme: 'info-tooltip' }"
                class="desc_label desc_normal custom-file_time-ago"
              >
                <BaseSubHeading
                  weight="400"
                  size="large"
                  color="monochrome-05"
                  darkColor="monochrome-04"
                >
                  {{ timeAgo }}
                </BaseSubHeading>
              </span>
            </div>
          </div>
          <div class="custom-file__sub-row">
            <ProgressLoadingLine
              class="custom-file__progress"
              :class="[{ 'is-hidden': progress === 0 }]"
              :progress="progress"
            />
          </div>
        </div>

        <Avatar
          :src="fileOwner.pictureUrl"
          :gap="fileOwner.getInitials()"
        />

        <DropdownTooltip
          :skidding="8"
          iconSize="md"
          placement="bottom-end"
          class="custom-file__menu"
          @click.stop
        >
          <template #tooltip-content="{ hide }">
            <ul>
              <li
                v-for="(option, index) in moreOptions"
                :key="index"
              >
                <button
                  :id="option.id"
                  class="dropdownTooltip_remove dropdownTooltip"
                  @click.prevent="deleteFile(hide)"
                >
                  <icon
                    data="@icon/trash.svg"
                    width="1.5em"
                    height="1.5em"
                    :fill="true"
                    color="#7D92A7"
                  />
                  {{ option.label }}
                </button>
              </li>
            </ul>
          </template>
        </DropdownTooltip>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import formatBytes from "@/helpers/formatBytes"
import { Prop, Emit } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { FileMetaData } from "@/models"
import FilePic from "@/components/File/FilePic.vue"
import Avatar from "@/components/Avatar/index.vue"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { formatDateAgo } from "@/utils/dateUtil"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import ProgressLoadingLine from "@/components/ProgressBar/ProgressLoadingLine.vue"
import format from "date-fns/format"
import { config } from "@/app.config"

@Options({
  name: "FileItem",
  components: {
    FilePic,
    Avatar,
    DropdownTooltip,
    ProgressLoadingLine,
  },
})
export default class FileItem extends Vue {
  @Prop({
    required: true,
  })
    file: FileMetaData
  @Prop({
    default: [],
  })
    files: FileMetaData[]
  @Prop({
    default: false,
  })
    removeButton
  @Prop({
    default: 0,
  })
    progress: number
  @Prop({
    default: false,
  })
    preventShow: boolean
  @Prop({
    default: "",
  })
    fancyboxGroup: string
  @Prop({
    default: "element",
    validator: (type: string) => ["element", "line"].includes(type),
  })
    fileMarkup: string

  @Getter(Getters.GET_CURRENT_PROJECT_ID) projectId: string

  @Action(Actions.ATTACHMENT_DOWNLOAD) downloadFile: ({
    fileMetadataId,
    fileName,
  }: {
    fileMetadataId: string
    fileName: string
  }) => void
  @Action(Actions.ATTACHMENT_VIEW) attachmentView: (payload: { fileMetadataId: string }) => Blob
  @Action(Actions.ATTACHMENT_VIDEO_URL) videoUrl: ({ projectId, attachmentId } : { projectId: string, attachmentId: string }) => Promise<string>

  moreOptions = [
    {
      id: "delete",
      label: "Delete",
      icon: require("@icon/trash.svg"),
      iconOption: {
        width: "1.5em",
        height: "1.5em",
      },
    },
  ]
  get fileMaxSize() {
    return this.getFileSize(this.file.size)
  }

  get fileSize() {
    return this.getFileSize(this.file.size)
  }


  get fileOwner() {
    return this.file.owner
  }

  get isLineType() {
    return this.fileMarkup === "line"
  }

  get isElType() {
    return this.fileMarkup === "element"
  }

  get timeAgo() {
    return formatDateAgo({
      date: new Date(),
      dateFormat: this.file.createdDateParsed,
      short: true,
    })
  }

  get fileCreateDate() {
    return format(this.file.createdDateParsed, "MM/dd/yy h:mm bb")
  }

  get maxUploadSizeKb() {
    return Number(config.VUE_APP_UPLOAD_MAX_FILE_SIZE_KB)
  }

  get isOverSizeLimit() {
    return this.file.size / 1024 > this.maxUploadSizeKb
  }

  get filteredFiles() {
    return this.files.filter(file => file.isImage || file.isVideo)
  }

  get jumpToIndex() {
    const index = this.filteredFiles.findIndex(file => file.id === this.file.id)

    return index < 0 ? 0 : index
  }

  getFileSize(size) {
    return size === 0
      ? "0 Bytes"
      : this.isOverSizeLimit
        ? `Maximum size for file is ${this.maxUploadSizeKb / 1024} Mb`
        : this.formatBytes(size.toString())
  }
  formatBytes(value: string) {
    return formatBytes(value)
  }
  onFileDownload() {
    const { id, name }: { id: string; name: string } = this.file
    if (this.file.custom) return

    this.downloadFile({
      fileMetadataId: id,
      fileName: name,
    })
  }

  @Emit("deleteFile")
  deleteFile(hide) {
    hide()
    return this.file.id
  }

  async showImage() {
    if (this.preventShow) return
    if (!this.file.isImage && !this.file.isVideo) return

    if (!this.fancyboxGroup.length) {
      const item = {
        blob: this.file.isImage ? this.attachmentView : null,
        fileMetadataId: this.file.id,
        type: this.file.isImage ? "image" : "html5video",
        src: this.file.isVideo ? await this.videoUrl({
          projectId: this.projectId,
          attachmentId: this.file.id,
        }) : "",
      }

      this.$fancybox.show([item])
      return
    }

    this.$fancybox
      .show(
        await Promise.all(
          this.filteredFiles.map(async file => ({
            blob: file.isImage ? this.attachmentView : null,
            type: file.isImage ? "image" : "html5video",
            src: file.isVideo ? await this.videoUrl({
              projectId: this.projectId,
              attachmentId: file.id,
            }) : "",
            fileMetadataId: file.id,
          })),
        ),
      )
      .jumpTo(this.jumpToIndex)
  }

  beforeUnmount() {
    this.$fancybox.unbind()
  }
}
</script>
