import { Notification, NotificationMilestoneContent } from "./Notification"

export class NotificationGoal extends Notification {
  milestone: NotificationMilestoneContent
  goal: string
  plannedTimeline: string
  timeLeft: string
  url: string
  constructor(props) {
    super(props)

    this.goal = props.goal ?? ""
    this.plannedTimeline = props.plannedTimeline ?? ""
    this.timeLeft = props.timeLeft ?? ""
  }
}
