import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert__wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "toasts__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalAlert = _resolveComponent("GlobalAlert")!
  const _component_ToastAlert = _resolveComponent("ToastAlert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.visibleGlobalAlerts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalAlerts, (alert, index) => {
            return (_openBlock(), _createBlock(_component_GlobalAlert, {
              key: index,
              alert: alert,
              onCloseAlert: _ctx.closeAlert
            }, null, 8, ["alert", "onCloseAlert"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.visibleToastAlerts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastAlerts, (alert, index) => {
            return (_openBlock(), _createBlock(_component_ToastAlert, {
              key: index,
              alert: alert,
              onCloseAlert: _ctx.closeAlert
            }, null, 8, ["alert", "onCloseAlert"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}