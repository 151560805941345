<template>
  <Component
    :is="nodeViewWrapper"
    :class="[
      'reference-component-wrap',
      {'reference-component-wrap_custom': type === referenceTypes.FILE }
    ]"
  >
    <span :class="['reference-component', `reference-component_${type.toLowerCase()}`]">
      <template v-if="!isShowLoading">
        <button
          v-if="type === referenceTypes.USER"
          type="button"
          @click.prevent="onClick(type)"
        >
          <UserReferenceTooltip
            :text="text"
            :user="info"
            :isShowDetails="isShowDetails"
          />
        </button>
        <button
          v-if="type === referenceTypes.ITEM"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span class="reference-component__link">{{ text }}</span>
          <span
            v-if="info?.itemTypeParsed?.label"
            class="reference-component__desc"
          >
            ({{ info.itemTypeParsed.label }}, {{ info?.statusParsed?.label }})
          </span>
        </button>
        <button
          v-else-if="type === referenceTypes.TASK"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span
            class="reference-component__link"
            v-html="text"
          />
          <span
            v-if="info?.typeParsed?.label"
            class="reference-component__desc"
          >
            ({{ info.typeParsed.label }}, {{ info?.estimatedHours }}hr)</span>
        </button>
        <button
          v-else-if="type === referenceTypes.MILESTONE"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span class="reference-component__link">{{ text }}</span>
          <span
            v-if="info?.date"
            class="reference-component__desc"
          >
            {{ info.date }}
          </span>
        </button>
        <button
          v-else-if="type === referenceTypes.EPIC"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span class="reference-component__link">{{ text }}</span>
          <span
            v-if="info?.percentComplete"
            class="reference-component__desc"
          >
            {{ info.percentComplete }}% Done
          </span>
        </button>
        <button
          v-else-if="type === referenceTypes.PROJECT"
          @click.prevent="onClick(type)"
        >
          <span class="reference-component__link">{{ text }}</span>
        </button>
        <button
          v-else-if="type === referenceTypes.SPRINT"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span
            class="dot dot_sm reference-component__meta"
            :class="[`is-${info.status.toLowerCase()}`]"
          />
          <span class="reference-component__link">{{ text }}</span>
          <span
            v-if="info?.startDate"
            class="reference-component__desc"
          >
            {{ info.startDate }} - {{ info.endDate }}
          </span>
          <span
            v-if="info?.team?.name"
            class="reference-component__desc"
          >
            (Team {{ info.team.name }})
          </span>
        </button>
        <button
          v-else-if="type === referenceTypes.TEAM"
          class="is-flex"
          type="button"
          @click.prevent="onClick(type)"
        >
          <span class="reference-component__link">{{ text }}</span>
          <span
            v-if="info?.size"
            class="reference-component__desc"
          >
            ({{ info.size }})
          </span>
        </button>
        <div
          v-else-if="type === referenceTypes.FILE"
        >
          <Attachment
            :node="node"
            :editor="editor"
            :updateAttributes="updateAttributes"
            :getPos="getPos"
          />

        </div>
      </template>
      <span v-else>...Loading...</span>
    </span>
  </Component>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentCommon from "@/components/Reference/helpers/ReferenceComponentCommon"
import UserReferenceTooltip from "./UserReferenceTooltip.vue"
import ParseReference from "./ParseReference"
import Attachment from "@/components/Attachment/index.vue"
import { FileMetaData } from "@/models"

const components = {
  UserReferenceTooltip,
  Attachment,
}

@Options({
  name: "InitComponent",
  components,
})
export default class extends mixins(ReferenceComponentCommon, ParseReference) {
  onClick(type) {
    if (type !== this.referenceTypes.USER && this.href) {
      window.open(this.href, "_blank")
    }
  }

  get initFileInstance() {
    return FileMetaData.create(this.info)
  }
}
</script>
