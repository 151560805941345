<template>
  <div
    :id="id"
    class="content"
    :class="['is-'+size]"
  >
    <div
      v-if="size === 'fullsize'"
      class="content__full-screen"
    >
      <slot />
    </div>

    <template v-else>
      <div class="content__block content__left">
        <div class="content__top content__space-between">
          <slot name="top" />
        </div>
        <slot name="before-left" />
        <slot name="left" />
      </div>
      <div
        :id="idRight"
        class="content__right"
        :class="[{'content__block': !withTabs}]"
      >
        <slot name="before-right" />
        <slot name="right" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
@Options({
  name: "Layout",
  components: {},
})
export default class Layout extends Vue {
  @Prop({ default: "fullsize" }) readonly size: string
  @Prop({ default: "" }) readonly id: string
  @Prop({ default: "" }) readonly idRight: string
  @Prop({ default: true }) readonly withTabs: boolean
  @Prop({ default: false }) readonly footer: boolean
  @Prop({ default: false }) readonly actionDisabled: boolean
  @Prop({ default: false }) readonly footerButtons: boolean
}
</script>
