import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"

import { RetroNote } from "@/models/RetroNote"
import { ProjectActions, projectModule } from "./project.module"
import {
  RetroChangePriority,
  RetroPriority,
  Retrospective,
  RetroStack,
  RetroStackNoteDto,
  RetroStatus,
} from "@/models/Retrospective"
import { ActionItems } from "@/models/ActionItems"
import { RetroStackItemsList } from "@/models/RetroStackItemsList"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType, ServerResponse } from "@/models"
import { FilterRetrospective } from "@/models/Filter/FilterRetrospective"

export enum RetrospectiveActions {
  RETROSPECTIVE_PUT_NOTE = "RETROSPECTIVE_PUT_NOTE",
  RETROSPECTIVE_REVIEWED = "RETROSPECTIVE_REVIEWED",
  RETROSPECTIVE_POST_NEW_NOTE = "RETROSPECTIVE_POST_NEW_NOTE",
  RETROSPECTIVE_COMPLETE = "RETROSPECTIVE_COMPLETE",
  RETROSPECTIVE_START = "RETROSPECTIVE_START",
  GET_RETROSPECTIVE = "GET_RETROSPECTIVE",
  GET_LIST_OF_RETROSPECTIVE = "GET_LIST_OF_RETROSPECTIVE",
  GET_RETROSPECTIVES_RESPONSE = "GET_RETROSPECTIVES_RESPONSE",
  RETROSPECTIVE_POST = "RETROSPECTIVE_POST",
  RETROSPECTIVE_CHANGE_PRIORITY = "RETROSPECTIVE_CHANGE_PRIORITY",
  GET_RETROSPECTIVE_STATUS = "GET_RETROSPECTIVE_STATUS",
  RETROSPECTIVE_PUT_STACK = "RETROSPECTIVE_PUT_STACK",
  RETROSPECTIVE_POST_NOTES = "RETROSPECTIVE_POST_NOTES",
  RETROSPECTIVE_DELETE_NOTE = "RETROSPECTIVE_DELETE_NOTE",
  RETROSPECTIVE_POST_STACK = "RETROSPECTIVE_POST_STACK",
  RETROSPECTIVE_POST_STACK_NOTE = "RETROSPECTIVE_POST_STACK_NOTE",
  GET_RETROSPECTIVE_BY_SPRINT_ID = "GET_RETROSPECTIVE_BY_SPRINT_ID",
  RETROSPECTIVE_NOTES_LIST = "RETROSPECTIVE_NOTES_LIST",
  RETROSPECTIVE_UPDATE_DRAGGABLE_LIST = "RETROSPECTIVE_UPDATE_DRAGGABLE_LIST",
  RETROSPECTIVE_UPDATE_SUB_DRAGGABLE_LIST = "RETROSPECTIVE_UPDATE_SUB_DRAGGABLE_LIST",
  RETROSPECTIVE_UPDATE_STACK_ELEMENTS = "RETROSPECTIVE_UPDATE_STACK_ELEMENTS",
  RETROSPECTIVE_REMOVE_NOTE_FROM_STACK = "RETROSPECTIVE_REMOVE_NOTE_FROM_STACK",
  UPDATE_STACK_NAME = "UPDATE_STACK_NAME",
  PUSH_INTO_NOTES_LIST = "PUSH_INTO_NOTES_LIST",
  REMOVE_NOTE_FROM_LIST = "REMOVE_NOTE_FROM_LIST",
  REMOVE_NOTE_FROM_LIST_BY_ID = "REMOVE_NOTE_FROM_LIST_BY_ID",
  UPDATE_DESCRIPRION_NOTE_IN_LIST = "UPDATE_DESCRIPRION_NOTE_IN_LIST",
  RETROSPECTIVE_NOTE_UPDATE = "RETROSPECTIVE_NOTE_UPDATE",
}
@Module
class RetrospectiveModule extends BaseModule {
  retrospective: Retrospective = Retrospective.createDefault()
  retrospectiveStatus: RetroStatus
  retroPriority: RetroPriority
  retrospectives: Retrospective[] = []
  retrospectivesServerResponse: ServerResponse<Retrospective>
  get projectId() {
    return projectModule.currentProjectId
  }

  get retrospectiveId() {
    return this.retrospective?.id ?? ""
  }

  get [`get/${RetrospectiveActions.GET_RETROSPECTIVE}`]() {
    return this.retrospective
  }

  get [`get/${RetrospectiveActions.GET_RETROSPECTIVE_STATUS}`]() {
    return this.retrospectiveStatus
  }

  get [`get/${RetrospectiveActions.RETROSPECTIVE_NOTES_LIST}`]() {
    return this.retrospective.notes
  }

  get [`get/${RetrospectiveActions.GET_RETROSPECTIVES_RESPONSE}`]() {
    return this.retrospectivesServerResponse
  }

  get [`get/${RetrospectiveActions.GET_LIST_OF_RETROSPECTIVE}`]() {
    return this.retrospectives
  }

  @Mutation
  setRetrospective(retrospective: Retrospective) {
    this.retrospective = retrospective
  }

  @Mutation
  setRetrospectiveStatus(retrospectiveStatus: RetroStatus) {
    this.retrospectiveStatus = retrospectiveStatus
  }

  @Mutation
  setRetrospectivePriority(retroPriority: RetroPriority) {
    this.retroPriority = retroPriority
  }

  @Mutation
  replaceNoteInList({ note, listKey, index }) {
    this.retrospective[listKey][index] = RetroStackItemsList.create(note)
  }

  @Mutation
  onUpdateStackName({ listKey, stackId, value } : { listKey: string, stackId: string, value: string }) {
    this.retrospective[listKey] = this.retrospective[listKey].map((e) => {
      if (e.id === stackId) {
        e.description = value
        return e
      } else {
        return e
      }
    })
  }

  @Mutation
  setRetrospectiveStackId({ stack, listKey }) {
    this.retrospective.stacks.push(stack)

    this.retrospective[listKey] = this.retrospective[listKey].map((e) => {
      if (e.description === stack.title) {
        e.id = stack.id
        return e
      } else {
        return e
      }
    })
  }

  @Mutation
  setRetrospectivesServerResponse(serverResponse: ServerResponse<Retrospective>) {
    this.retrospectivesServerResponse = serverResponse
  }

  @Mutation
  onRemoveNoteFromList({ index, listKey }) {
    this.retrospective?.[listKey].splice(index, 1)
  }

  @Mutation
  onRemoveNoteFromListById({ elementId, listKey }) {
    if (!this.retrospective?.[listKey]) return
    this.retrospective[listKey] = this.retrospective[listKey].filter(e => e.id !== elementId)
  }

  @Mutation
  onUpdateDescriptionNoteInList({ index, listKey, value }) {
    if (!this.retrospective?.[listKey]?.[index]) return
    this.retrospective[listKey][index].description = value
  }

  @Mutation
  setRetrospectivesContent(retrospectives: Retrospective[]) {
    this.retrospectives = retrospectives
  }

  @Mutation
  addRetrospectivesContent(retrospectives: Retrospective[]) {
    this.retrospectives = [...this.retrospectives, ...retrospectives]
  }

  @Mutation
  onUpdateDraggableList({ draggableList, key } : { draggableList: RetroStackItemsList[], key: string }) {
    this.retrospective[key] = draggableList
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_PUT_NOTE]({ retrospectiveNoteBody, retrospectiveId }: { retrospectiveNoteBody: RetroNote, retrospectiveId: string }) {
    const noteId = retrospectiveNoteBody?.id ?? ""
    const data = await apiService.put<Retrospective>(Endpoint.RETROSPECTIVE_PUT_NOTE(this.projectId, retrospectiveId, noteId), retrospectiveNoteBody)
    return this.handleResponse<Retrospective>(data)
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_REVIEWED]({ reviewedRetrospectiveCards, retrospectiveId }: { reviewedRetrospectiveCards: string[], retrospectiveId: string }) {
    await apiService.patch<any>(Endpoint.RETROSPECTIVE_REVIEWED(this.projectId, retrospectiveId), reviewedRetrospectiveCards)
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_NOTE_UPDATE]({ retrospectiveNote, retrospectiveId }: { retrospectiveNote: RetroStackItemsList | RetroNote, retrospectiveId: string }) {
    const noteId = retrospectiveNote?.id ?? ""
    const data = await apiService.put<RetroNote>(Endpoint.RETROSPECTIVE_PUT_NOTE(this.projectId, retrospectiveId, noteId), retrospectiveNote)
    return this.handleResponse<RetroNote>(data, (data) => {
      const note = RetroStackItemsList.create(data)

      const title = note.wentWell ? "What Went Well" : "What To Improve"
      const alertContent: AlertContentItem[] = [
        { text: title, type: "bold" },
        { text: " note updated.", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return note
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_POST_NOTES]({ retrospectiveId, retrospectiveNotes }: { retrospectiveId: string, retrospectiveNotes: RetroNote[] }) {
    const notes = retrospectiveNotes.map(e => e.getJsonObj())
    const data = await apiService.post<RetroNote[]>(Endpoint.RETROSPECTIVE_NOTES(this.projectId, retrospectiveId), notes)
    return this.handleResponse<RetroNote[]>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Thank you, your retrospective notes have been submitted.",
          type: "regular",
        },
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return data.map(e => RetroNote.create(e))
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_POST_NEW_NOTE]({ retrospectiveId, retrospectiveNote, index, listKey }: { retrospectiveId: string, retrospectiveNote: RetroStackItemsList | RetroNote, index: number, listKey: string }) {
    const data = await apiService.post<RetroNote[]>(Endpoint.RETROSPECTIVE_NOTES(this.projectId, retrospectiveId), [
      retrospectiveNote.getJsonObj(),
    ])
    return this.handleResponse<RetroNote[]>(data, (data) => {
      const note = data.map(e => RetroStackItemsList.create(e))[0]
      this.replaceNoteInList({ note, listKey, index })
      const title = retrospectiveNote.wentWell ? "What Went Well" : "What To Improve"
      const alertContent: AlertContentItem[] = [
        { text: "New ", type: "regular" },
        { text: title, type: "bold" },
        { text: " note added.", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return note
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_COMPLETE]({ retrospectiveId, actions }: { retrospectiveId: string, actions: ActionItems[] }) {
    const actionsNotes = actions.map(e => e.getJsonObj())
    const data = await apiService.post<Retrospective>(Endpoint.RETROSPECTIVE_COMPLETE(this.projectId, retrospectiveId), actionsNotes)
    return this.handleResponse<Retrospective>(data, (data) => {
      this.setRetrospective(Retrospective.create(data))
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_START]({ sprintId, demo } : { sprintId: string, demo: boolean }) {
    const data = await apiService.post<Retrospective>(Endpoint.RETROSPECTIVE_START(this.projectId, sprintId),
      null,
      { params: { demo } },
    )
    return this.handleResponse<Retrospective>(data, (data) => {
      this.setRetrospective(Retrospective.create(data))
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_POST]({ sprintId, demo } : { sprintId: string, demo: boolean }) {
    const data = await apiService.post<Retrospective>(
      Endpoint.RETROSPECTIVE_POST(this.projectId, sprintId),
      null,
      { params: { demo } },
    )
    return this.handleResponse<Retrospective>(data, (data) => {
      this.setRetrospective(Retrospective.create(data))
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_CHANGE_PRIORITY](retroChangePriority: RetroChangePriority) {
    const data = await apiService.patch<RetroPriority>(Endpoint.RETROSPECTIVE_CHANGE_PRIORITY(this.projectId), retroChangePriority)
    return this.handleResponse<RetroPriority>(data, (data) => {
      this.setRetrospectivePriority(data)
    })
  }

  @Action
  async [RetrospectiveActions.GET_RETROSPECTIVE](retrospectiveId: string) {
    const data = await apiService.get<Retrospective>(Endpoint.GET_RETROSPECTIVE(this.projectId, retrospectiveId))
    return this.handleResponse<Retrospective>(data, (data) => {
      if (data) {
        this.setRetrospective(Retrospective.create(data))
        // this.updateNotesLists()
      } else {
        this.setRetrospective(Retrospective.createDefault())
      }
    })
  }

  @Action
  async [RetrospectiveActions.GET_LIST_OF_RETROSPECTIVE]({ projectId, filter } : { projectId?: string, filter: FilterRetrospective }) {
    const data = await apiService.get<ServerResponse<Retrospective>>(Endpoint.GET_LIST_OF_RETROSPECTIVE(projectId ?? this.projectId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Retrospective>>(data, (data) => {
      const content = data.content.map(e => Retrospective.create(e))
      const response = { ...data, content }
      this.setRetrospectivesServerResponse(response)
      if (filter.page === 1) {
        this.setRetrospectivesContent(content)
      } else {
        this.addRetrospectivesContent(content)
      }
      return response
    })
  }

  @Action
  async [RetrospectiveActions.GET_RETROSPECTIVE_STATUS](sprintId: string) {
    const data = await apiService.get<RetroStatus>(Endpoint.GET_RETROSPECTIVE_STATUS(this.projectId, sprintId))
    return this.handleResponse<RetroStatus>(data, (data) => {
      this.setRetrospectiveStatus(data)
    })
  }

  @Action
  async [RetrospectiveActions.GET_RETROSPECTIVE_BY_SPRINT_ID](sprintId: string) {
    const data = await apiService.get<any>(Endpoint.GET_RETROSPECTIVE_BY_SPRINT_ID(this.projectId, sprintId))
    return this.handleResponse<any>(data, (data) => {
      if (data) {
        this.setRetrospective(Retrospective.create(data))
        // this.updateNotesLists()
        return Retrospective.create(data)
      } else {
        this.setRetrospective(Retrospective.createDefault())
        return Retrospective.createDefault()
      }
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_DELETE_NOTE]({ retrospectiveId, noteIds } : { retrospectiveId : string, noteIds: string[] }) {
    const data = await apiService.delete<Retrospective>(Endpoint.RETROSPECTIVE_NOTES(this.projectId, retrospectiveId), noteIds)
    return this.handleResponse<Retrospective>(data)
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_PUT_STACK]({ stack, title } : { stack: RetroStackItemsList, title: string }) {
    const stackId = stack.getJsonObj().id
    const data = await apiService.put<Retrospective>(Endpoint.RETROSPECTIVE_PUT_STACK(this.projectId, this.retrospectiveId, stackId), {
      title,
    })
    return this.handleResponse<Retrospective>(data)
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_POST_STACK]({ retroStack, listKey } : {retroStack: RetroStack, listKey: string}) {
    const data = await apiService.post<Retrospective>(Endpoint.RETROSPECTIVE_POST_STACK(this.projectId, this.retrospectiveId), retroStack)
    return this.handleResponse<Retrospective>(data, (data) => {
      this.setRetrospectiveStackId({ stack: data, listKey })
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_POST_STACK_NOTE](retroStackNote: RetroStackNoteDto) {
    const data = await apiService.post<Retrospective>(Endpoint.RETROSPECTIVE_POST_STACK_NOTE(this.projectId, this.retrospectiveId), retroStackNote)
    return this.handleResponse<Retrospective>(data)
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_REMOVE_NOTE_FROM_STACK](retroStackNote: RetroStackNoteDto) {
    const data = await apiService.delete<Retrospective>(Endpoint.RETROSPECTIVE_POST_STACK_NOTE(this.projectId, this.retrospectiveId), retroStackNote)
    return this.handleResponse<Retrospective>(data, () => {
      const alertContent: AlertContentItem[] = [
        { text: "Sticker moved", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_UPDATE_DRAGGABLE_LIST]({ draggableList, key } : { draggableList: RetroStackItemsList[], key: string }) {
    this.onUpdateDraggableList({ draggableList, key })
  }

  @Action
  async [RetrospectiveActions.PUSH_INTO_NOTES_LIST]({ note, key } : {note: RetroStackItemsList, key: string}) {
    this.retrospective[key].push(note)
  }

  @Action
  async [RetrospectiveActions.UPDATE_STACK_NAME]({ listKey, stackId, value } : { listKey: string, stackId: string, value: string }) {
    this.onUpdateStackName({ listKey, stackId, value })
  }

  @Action
  async [RetrospectiveActions.REMOVE_NOTE_FROM_LIST]({ index, listKey }) {
    this.onRemoveNoteFromList({ index, listKey })
  }

  @Action
  async [RetrospectiveActions.REMOVE_NOTE_FROM_LIST_BY_ID]({ elementId, listKey }) {
    this.onRemoveNoteFromListById({ elementId, listKey })
  }

  @Action
  async [RetrospectiveActions.UPDATE_DESCRIPRION_NOTE_IN_LIST]({ index, listKey, value }) {
    this.onUpdateDescriptionNoteInList({ index, listKey, value })
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_UPDATE_STACK_ELEMENTS]({ draggableList, key } : { draggableList: RetroStackItemsList[], key: string }) {
    const [listKey, stackId] = key.split("_")
    if (draggableList.length) {
      this.retrospective[listKey] = this.retrospective[listKey].map((e) => {
        if (e.id === stackId) {
          e.elements = draggableList
          return e
        } else {
          return e
        }
      })
    } else {
      this.retrospective[listKey] = this.retrospective[listKey].filter(e => e.id !== stackId)
    }
  }

  @Action
  async [RetrospectiveActions.RETROSPECTIVE_UPDATE_SUB_DRAGGABLE_LIST]({ draggableList, key, index } : { draggableList: RetroNote[], key: string, index: number }) {
    const parentKey = key.replace("sub_", "")
    if (!this.retrospective[parentKey][index]?.elements?.length) {
      this[RetrospectiveActions.RETROSPECTIVE_POST_STACK](this.retrospective.createStack({
        draggableList,
        parentKey,
        index,
      }))
    } else {
      this[RetrospectiveActions.RETROSPECTIVE_POST_STACK_NOTE](this.retrospective.addNoteInStack({
        draggableList,
        parentKey,
        index,
      }))
    }
  }
}

export const retrospectiveModule = new RetrospectiveModule({
  store,
  name: Modules.RETROSPECTIVE,
})
