import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "user-search" }
const _hoisted_2 = {
  key: 0,
  class: "user-search__list"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderedInput = _resolveComponent("BorderedInput")!
  const _component_User = _resolveComponent("User")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BorderedInput, {
      id: "name",
      className: "user-search__input",
      modelValue: _ctx.searchText,
      onWrapEvent: true,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _ctx.onSearchUser
    }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]),
    (_ctx.users.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "user-search__item"
            }, [
              _createElementVNode("button", {
                type: "button",
                class: "user-search__button",
                onClick: _withModifiers(($event: any) => (_ctx.onUserSelect(item)), ["prevent"])
              }, [
                _renderSlot(_ctx.$slots, "default", { item: item }, () => [
                  _createVNode(_component_User, { user: item }, null, 8, ["user"])
                ])
              ], 8, _hoisted_3)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.hide]
  ])
}