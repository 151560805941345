import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"
import { Retrospective } from "@/models/Retrospective"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  const sprintId = store.getters[Getters.GET_SESSION_SPRINT_ID]
  const retrospective: Retrospective = await store.dispatch(Actions.GET_RETROSPECTIVE_BY_SPRINT_ID, sprintId)

  if (retrospective.isCompleted || retrospective.isRevealed) {
    next({
      name: "retrospective",
      params: to.params,
    })
  }

  next()
}
