<script lang="ts">
import { h } from "vue"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"

type CaptionSize = "large" | "small"

@Options({
  name: "BaseCaption",
})
export default class extends Vue {
  @Prop() readonly size: CaptionSize = "large"
  @Prop() readonly className: string = ""
  @Prop() readonly color: string = ""
  @Prop() readonly darkColor: string = ""

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  get styleObject() {
    if (!this.color) return {}

    let color = this.color

    if (this.isDark && this.darkColor) {
      color = this.darkColor
    }

    return {
      color: `var(--${color})`,
    }
  }

  render() {
    return h("div",
      {
        class: `caption-text caption-text-${this.size} ${this.className ?? ""}`,
        style: this.styleObject,
      },
      this.$slots.default ? this.$slots.default() : "")
  }
}
</script>
