import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@icon/continuous-sprint.svg'
import _imports_1 from '@icon/zero-sprint.svg'
import _imports_2 from '@icon/alert-tooltip.svg'


const _hoisted_1 = { class: "sprint-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeading, {
      level: "2",
      color: "monochrome-07",
      className: "sprint-header__title"
    }, {
      default: _withCtx(() => [
        (_ctx.sprint.continuousSprint)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _imports_0,
              class: _normalizeClass(["continuous-sprint__icon", [`is-${_ctx.sprint.status.toLowerCase()}`]]),
              fill: true,
              width: "1.2em",
              height: "1.2em"
            }, null, 8, ["class"]))
          : (_ctx.sprint.zeroSprint)
            ? (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                data: _imports_1,
                class: _normalizeClass(["zero-sprint__icon", [`is-${_ctx.sprint.status.toLowerCase()}`]]),
                fill: true,
                width: "1.2em",
                height: "1.2em"
              }, null, 8, ["class"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["dot sprint-header__dot", [`is-${_ctx.sprint.status.toLowerCase()}`]])
              }, null, 2)),
        _renderSlot(_ctx.$slots, "title", { title: _ctx.title }, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ])
      ]),
      _: 3
    }),
    (_ctx.showDate)
      ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
          key: 0,
          size: "large",
          isDark: _ctx.isDark,
          style: _normalizeStyle({ color: _ctx.isDark ? 'var(--monochrome-04)' : 'var(--monochrome-05)' }),
          className: "sprint-header__sub-title",
          weight: "500"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "date", {
              startDate: _ctx.sprint.formatedDateStart,
              endDate: _ctx.sprint.formatedDateEnd
            }, () => [
              _createTextVNode(_toDisplayString(_ctx.sprint.formatedDateStart) + " - " + _toDisplayString(_ctx.sprint.formatedDateEnd), 1)
            ]),
            (_ctx.sprint.isHalted && _ctx.$route.path.includes('/review'))
              ? _withDirectives((_openBlock(), _createBlock(_component_icon, {
                  key: 0,
                  data: _imports_2,
                  class: "icon-alert-tooltip",
                  fill: true,
                  width: "1.2em",
                  height: "1.2em",
                  color: "var(--serviceAlert-02)"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    {content: _ctx.sprint.haltReason, theme: 'info-tooltip-alert' },
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["isDark", "style"]))
      : _createCommentVNode("", true)
  ]))
}