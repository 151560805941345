import { Options, Vue } from "vue-class-component"

@Options({
  name: "OpenItemByRouteQuery",
})

export class OpenItemByRouteQuery extends Vue {
  async onOpenItemByRouteQuery() {
    const itemId = this.$route.query?.i as string
    if (!itemId || itemId === "create") return false
    const query = { ...this.$route.query }
    delete query.i
    await this.$router.replace({ query })
    await this.$router.push({
      name: "Item",
      params: {
        itemAnimation: "true",
        itemId,
      },
    })
    return true
  }
}
