import { config } from '@/app.config';
import Keycloak from 'keycloak-js'
const channel = new BroadcastChannel('TOKEN_EXCHANGE')

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $keycloak: Keycloak;
  }
}

const initOptions = {
  url: config.VUE_APP_KEYCLOAK_URL,
  realm: 'project-simple',
  clientId: 'login-app',
  checkLoginIframe: false
}

const TOKEN_MIN_VALIDITY_SECONDS = 70

export async function updateToken (sec?: number) {
  await $keycloak.updateToken(sec || TOKEN_MIN_VALIDITY_SECONDS)
  const token = $keycloak.token
  channel.postMessage(token)
  return token
}

export function getToken () {
  return $keycloak.token
}

export function logout () {
  $keycloak.logout({ redirectUri: window.location.origin });
}

export async function forceUpdateToken () {
  return await updateToken(-1);
}

export const $keycloak = new Keycloak(initOptions)

$keycloak.onAuthRefreshError = () => logout()