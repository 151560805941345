<template>
  <ReferenceComponentParentList
    :searchList="list"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    @onClick="onFileSelected"
  >
    <template #header>
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
      >
        Files
      </BaseSubHeading>
    </template>

    <template #item="{ option }">
      <FileItem
        :key="option.id"
        :file="option"
        :preventShow="true"
        fileMarkup="element"
        :removeButton="false"
      />
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import {
  ServerResponse,
  FileMetaData,
  FilterSearch,
  Item,
} from "@/models"
import FileItem from "@/components/File/FileItem.vue"

const components = {
  ReferenceComponentParentList,
  FileItem,
}

@Options({
  name: "FileList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.GET_ITEM) readonly item: Item

  @Action(Actions.GET_ITEM_FILES) getItemFiles: ({ itemId, filterFiles }: { itemId: string, filterFiles }) => Promise<ServerResponse<FileMetaData>>

  componentType = ReferenceType.FILE
  waitTitle = "reference.search.file"

  filter: FilterSearch = FilterSearch.createDefault()
  result: FileMetaData[] = []

  get list() {
    return this.result?.length > 0 ? this.result : []
  }

  created() {
    this.filter.addSort("createdDate", false)
  }

  queryUpdated(q: string) {
    this.filter.q = q.substring(1) ?? ""
    this.search()
  }

  async search() {
    if (!this.item.id) return
    this.result = []

    const filesResponse = await this.getItemFiles({
      itemId: this.item.id,
      filterFiles: this.filter,
    })

    this.result = filesResponse.content
  }

  onEnterHandler() {
    this.onFileSelected(this.result[this.selectedIndex])
  }

  onFileSelected(payload) {
    this.selectItem({
      item: payload,
      payload: {},
    })
  }
}
</script>
