<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="notification.projectName"
    class="notification__item"
  >
    <template #pic>
      <div class="label-pic is-orange">
        <icon
          data="@icon/milestoneIcon.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        {{ notification.milestoneName }} Completed
      </BaseSubHeading>
    </template>
    <template #content>
      <div className="notification__update-item__content">
        <a
          class="link is-large"
          target="_blank"
          :href="notification.url"
        >
          {{ notification.milestoneName }}
        </a>
        <BaseSubHeading
          size="medium"
          color="monochrome-07"
          weight="400"
        >
          {{ notification.milestoneDate }}
        </BaseSubHeading>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationMilestone } from "@/models/Notification"

@Options({
  name: "MILESTONE_COMPLETED",
  components: {
    NotificationTemplate,
  },
})
export default class MILESTONE_COMPLETED extends Vue {
  @Prop() readonly notification: NotificationMilestone
}
</script>
