import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@icon/trash.svg'


const _hoisted_1 = { class: "team__content" }
const _hoisted_2 = { class: "team__left" }
const _hoisted_3 = { class: "team__name team__desc" }
const _hoisted_4 = { class: "team__right" }
const _hoisted_5 = { class: "team__desc" }
const _hoisted_6 = { class: "team__desc team_length" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'user-page__team-list' : _ctx.teamDel}])
  }, [
    _createElementVNode("div", {
      class: "team card",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickOnTeam && _ctx.clickOnTeam(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.team.name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(" Members: "),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.team.size ?? 0), 1)
          ]),
          _createVNode(_component_Avatar, {
            src: _ctx.team.picture?.pictureUrl || null,
            class: "team__avatar",
            type: "teamsGroup"
          }, null, 8, ["src"])
        ])
      ])
    ]),
    (_ctx.teamDel)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "team__delete",
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.teamDelete && _ctx.teamDelete(...args)))
        }, [
          _createVNode(_component_icon, {
            data: _imports_0,
            fill: true,
            color: "#7D92A7"
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}