import { Module, Mutation, Action } from "vuex-class-modules"

import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { projectModule } from "./project.module"
import { ServerResponse } from "@/models/ServerResponse"
import {
  Item,
  ItemQueryPriority,
  Epic,
  BacklogSetup,
  ServerPriorityResponse,
  Team,
  FilterBacklog,
  MilestoneAddEpic,
  UpdateObjectValue,
  AlertContentItem,
  AlertType,
  ChangePriorityMessageParams,
  BugWaterline,
  InsertItemInBacklog,
  RemoveItemFromBacklog,
  UpdateElementInBacklog,
  BacklogAnalytics,
} from "@/models"
import { store } from "../store"
import { Modules } from "../modules"
import { SprintActions, sprintModule } from "./sprint.module"
import { EpicActions, epicModule } from "@/store/modules/epic.module"
import { BaseModule } from "@/models/BaseModule"
import { MilestoneActions, milestoneModule } from "./milestone.module"
import { AlertActions, alertModule } from "./alert.module"
import { $t } from "@/plugins/i18n"
import { parsingServerResponseContent } from "@/utils/helpers"
import { accountModule } from "./account.module"
import cloneDeep from "lodash/cloneDeep"


export enum BacklogActions {
  GET_BACKLOG = "GET_BACKLOG",
  GET_FULL_BACKLOG = "GET_FULL_BACKLOG",
  GET_PRODUCT_BACKLOG = "GET_PRODUCT_BACKLOG",
  GET_PRODUCT_BACKLOG_CONTENT = "GET_PRODUCT_BACKLOG_CONTENT",
  GET_BUG_BACKLOG = "GET_BUG_BACKLOG",
  GET_BUG_BACKLOG_CONTENT = "GET_BUG_BACKLOG_CONTENT",
  GET_UNPRIORITIZED_BACKLOG = "GET_UNPRIORITIZED_BACKLOG",
  GET_UNPRIORITIZED_BACKLOG_CONTENT = "GET_UNPRIORITIZED_BACKLOG_CONTENT",
  GET_SPRINT_BACKLOG = "GET_SPRINT_BACKLOG",
  GET_TECH_DEBT_BACKLOG = "GET_TECH_DEBT_BACKLOG",
  GET_TECH_DEBT_BACKLOG_CONTENT = "GET_TECH_DEBT_BACKLOG_CONTENT",
  GET_DEV_OPS_BACKLOG = "GET_DEV_OPS_BACKLOG",
  GET_DEV_OPS_BACKLOG_CONTENT = "GET_DEV_OPS_BACKLOG_CONTENT",
  GET_SUPPORT_CASE_BACKLOG = "GET_SUPPORT_CASE_BACKLOG",
  GET_SUPPORT_CASE_BACKLOG_CONTENT = "GET_SUPPORT_CASE_BACKLOG_CONTENT",
  GET_EPIC_BACKLOG = "GET_EPIC_BACKLOG",
  GET_EPIC_BACKLOG_CONTENT = "GET_EPIC_BACKLOG_CONTENT",
  GET_BACKLOG_LENGTH = "GET_BACKLOG_LENGTH",
  CHANGE_BACKLOG_PRIORITY = "CHANGE_BACKLOG_PRIORITY",
  CHANGE_BACKLOG_PRIORITY_BATCH = "CHANGE_BACKLOG_PRIORITY_BATCH",
  UPDATE_BACKLOG_ITEMS = "UPDATE_BACKLOG_ITEMS",
  UPDATE_BACKLOG_ITEM = "UPDATE_BACKLOG_ITEM",
  CLEAR_EPICS_LIST_CONTENT = "CLEAR_EPICS_LIST_CONTENT",

  SELECT_STATUS = "SELECT_STATUS",
  SELECT_EPIC = "SELECT_EPIC",
  SELECT_FILTER_VALUE = "SELECT_FILTER_VALUE",
  FILTER_VALUE = "FILTER_VALUE",

  GET_EPIC_ROADMAP = "GET_EPIC_ROADMAP",
  GET_EPIC_ROADMAP_CONTENT = "GET_EPIC_ROADMAP_CONTENT",
  CHANGE_EPIC_PRIORITY = "CHANGE_EPIC_PRIORITY",
  CHANGE_SPRINT_PRIORITY = "CHANGE_SPRINT_PRIORITY",
  BACKLOG_SHOW_GOALS = "BACKLOG_SHOW_GOALS",
  BACKLOG_SHOW_ANALYTICS = "BACKLOG_SHOW_ANALYTICS",

  CHANGE_UNPRIORITIZED_FILTER = "CHANGE_UNPRIORITIZED_FILTER",
  GET_UNPRIORITIZED_FILTER = "GET_UNPRIORITIZED_FILTER",

  GET_BACKLOG_MILESTONES = "GET_BACKLOG_MILESTONES",
  CHANGE_EPIC_PRIORITY_IN_MILESTONE = "CHANGE_EPIC_PRIORITY_IN_MILESTONE",
  DELETE_EPIC_FROM_MILESTONE = "DELETE_EPIC_FROM_MILESTONE",
  ADD_EPIC_FROM_MILESTONE = "ADD_EPIC_FROM_MILESTONE",

  SET_BACKLOG_ITEM = "SET_BACKLOG_ITEM",
  GET_ITEMS_IN_EPIC = "GET_ITEMS_IN_EPIC",
  CLEAR_BACKLOG_CONTENT = "CLEAR_BACKLOG_CONTENT",

  INSERT_BACKLOG_ITEM = "INSERT_BACKLOG_ITEM",
  REMOVE_ITEM_FROM_BACKLOG = "REMOVE_ITEM_FROM_BACKLOG",
  REMOVE_ITEM_FROM_BACKLOG_AND_SET_TO_PREVIOUS_BACKLOG = "REMOVE_ITEM_FROM_BACKLOG_AND_SET_TO_PREVIOUS_BACKLOG",
  SHOW_CHANGE_PRIORITY_MESSAGE = "SHOW_CHANGE_PRIORITY_MESSAGE",
  CLEAR_BACKLOG_CONTENT_BY_NAME = "CLEAR_BACKLOG_CONTENT_BY_NAME",
  GET_STORY_POKER_BACKLOG = "GET_STORY_POKER_BACKLOG",
  GET_STORY_POKER_BACKLOG_CONTENT = "GET_STORY_POKER_BACKLOG_CONTENT",

  GET_MAIN_BACKLOG_SETUP = "GET_MAIN_BACKLOG_SETUP",
  SET_MAIN_BACKLOG_SETUP = "SET_MAIN_BACKLOG_SETUP",
  GET_MY_WORK = "GET_MY_WORK",
  GET_MY_WORK_CONTENT = "GET_MY_WORK_CONTENT",
  MY_WORK_CHANGE_PRIORITY = "MY_WORK_CHANGE_PRIORITY",
  GET_PRODUCT_BACKLOG_ALL_CONTENT = "GET_PRODUCT_BACKLOG_ALL_CONTENT",
  GET_PRODUCT_BACKLOG_ALL = "GET_PRODUCT_BACKLOG_ALL",
  BACKLOG_ANALYTICS = "BACKLOG_ANALYTICS",
}

interface BacklogPayload {
  response: ServerResponse<Item>;
  backlogSetup: BacklogSetup;
}

export const defaultBagWaterline: BugWaterline[] = [
  {
    label: "Critical",
    severityKey: "CRITICAL",
    currentValue: 0,
    maxValue: 30,
  },
  {
    label: "High",
    severityKey: "HIGH",
    currentValue: 0,
    maxValue: 30,
  },
  {
    label: "Medium",
    severityKey: "MEDIUM",
    currentValue: 0,
    maxValue: 30,
  },
  {
    label: "Trivial",
    severityKey: "TRIVIAL",
    currentValue: 0,
    maxValue: 30,
  },
]

@Module
class BacklogModule extends BaseModule {
  backlogProduct: ServerResponse<Item> = new ServerResponse<Item>()
  backlogProductAll: ServerResponse<Item> = new ServerResponse<Item>()
  backlogStoryPoker: ServerResponse<Item> = new ServerResponse<Item>()
  backlogUnprioritized: ServerResponse<Item> = new ServerResponse<Item>()
  backlogBug: ServerResponse<Item> = new ServerResponse<Item>()
  backlogEpic: ServerResponse<Item> = new ServerResponse<Item>()
  backlogSprint: { [key: string]: ServerResponse<Item> } = {}
  backlogSupportCases: ServerResponse<Item> = new ServerResponse<Item>()
  backlogTechDebt: ServerResponse<Item> = new ServerResponse<Item>()
  backlogDevOps: ServerResponse<Item> = new ServerResponse<Item>()
  roadmapEpic: ServerResponse<Epic> = new ServerResponse<Epic>()
  backlogMyWork: ServerResponse<Item> = new ServerResponse<Item>()

  status: { id: string, value: string } | null = null
  team: Team | null = null
  hasStoryPoints: boolean | null = false
  hasEstimation: boolean | null = false
  hasNotEstimation: boolean | null = false

  resultCount: number | null = null
  backlogShowGoals = false
  backlogShowAnalytics = false

  unprioritizedFilter = "STORY"
  moveTo: BacklogSetup | null = null
  itemsInEpic: { [key: string]: Item[] } = {}
  mainBacklogSetup: BacklogSetup | null = null
  backlogAnalytics: BacklogAnalytics = new BacklogAnalytics()

  get [`get/${BacklogActions.GET_UNPRIORITIZED_FILTER}`]() {
    return this.unprioritizedFilter
  }

  get projectId() {
    return projectModule.currentProjectId
  }

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${BacklogActions.GET_PRODUCT_BACKLOG}`]() {
    return this.backlogProduct
  }

  get [`get/${BacklogActions.BACKLOG_SHOW_GOALS}`]() {
    return this.backlogShowGoals
  }

  get [`get/${BacklogActions.GET_PRODUCT_BACKLOG_CONTENT}`]() {
    return this.backlogProduct.content
  }

  get [`get/${BacklogActions.GET_STORY_POKER_BACKLOG}`]() {
    return this.backlogStoryPoker
  }

  get [`get/${BacklogActions.GET_STORY_POKER_BACKLOG_CONTENT}`]() {
    return this.backlogStoryPoker.content
  }

  get [`get/${BacklogActions.GET_BACKLOG_MILESTONES}`]() {
    return milestoneModule.getAllMilestones()
  }

  get [`get/${BacklogActions.GET_UNPRIORITIZED_BACKLOG}`]() {
    return this.backlogUnprioritized
  }

  get [`get/${BacklogActions.GET_UNPRIORITIZED_BACKLOG_CONTENT}`]() {
    return this.backlogUnprioritized.content
  }

  get [`get/${BacklogActions.GET_BUG_BACKLOG}`]() {
    return this.backlogBug
  }

  get [`get/${BacklogActions.GET_BUG_BACKLOG_CONTENT}`]() {
    return this.backlogBug.content
  }

  get [`get/${BacklogActions.GET_EPIC_ROADMAP}`]() {
    return this.roadmapEpic
  }

  get [`get/${BacklogActions.GET_EPIC_ROADMAP_CONTENT}`]() {
    return this.roadmapEpic.content
  }

  get [`get/${BacklogActions.GET_SPRINT_BACKLOG}`]() {
    return this.backlogSprint
  }

  get [`get/${BacklogActions.GET_EPIC_BACKLOG}`]() {
    return this.backlogEpic
  }

  get [`get/${BacklogActions.GET_EPIC_BACKLOG_CONTENT}`]() {
    return this.backlogEpic.content
  }

  get [`get/${BacklogActions.GET_BACKLOG_LENGTH}`]() {
    return (id: string) => this[id]?.content.length ?? 0
  }

  get [`get/${BacklogActions.GET_SUPPORT_CASE_BACKLOG}`]() {
    return this.backlogSupportCases
  }

  get [`get/${BacklogActions.GET_SUPPORT_CASE_BACKLOG_CONTENT}`]() {
    return this.backlogSupportCases.content
  }

  get [`get/${BacklogActions.GET_TECH_DEBT_BACKLOG}`]() {
    return this.backlogTechDebt
  }

  get [`get/${BacklogActions.GET_TECH_DEBT_BACKLOG_CONTENT}`]() {
    return this.backlogTechDebt.content
  }

  get [`get/${BacklogActions.GET_DEV_OPS_BACKLOG}`]() {
    return this.backlogDevOps
  }

  get [`get/${BacklogActions.GET_DEV_OPS_BACKLOG_CONTENT}`]() {
    return this.backlogDevOps.content
  }

  get [`get/${BacklogActions.SELECT_FILTER_VALUE}`]() {
    return getter => this[getter]
  }

  get [`get/${BacklogActions.SELECT_STATUS}`]() {
    return this.status
  }

  get [`get/${BacklogActions.SELECT_EPIC}`]() {
    return epicModule.epic
  }

  get [`get/${BacklogActions.GET_ITEMS_IN_EPIC}`]() {
    return (epicId: string) => this.itemsInEpic[epicId] ?? []
  }

  get [`get/${BacklogActions.GET_MAIN_BACKLOG_SETUP}`]() {
    return this.mainBacklogSetup
  }

  get [`get/${BacklogActions.GET_MY_WORK}`]() {
    return this.backlogMyWork
  }

  get [`get/${BacklogActions.GET_MY_WORK_CONTENT}`]() {
    return this.backlogMyWork.content
  }

  get [`get/${BacklogActions.GET_PRODUCT_BACKLOG_ALL}`]() {
    return this.backlogProductAll
  }

  get [`get/${BacklogActions.GET_PRODUCT_BACKLOG_ALL_CONTENT}`]() {
    return this.backlogProductAll.content
  }

  get [`get/${BacklogActions.BACKLOG_ANALYTICS}`]() {
    return this.backlogAnalytics
  }

  get [`get/${BacklogActions.BACKLOG_SHOW_ANALYTICS}`]() {
    return this.backlogShowAnalytics
  }

  setBacklogContent({ items, backlogName, sprintId } : {items: Item[], backlogName: string, sprintId?: string}) {
    if (backlogName !== "backlogSprint") {
      this[backlogName] = { ...this[backlogName], content: [...items] }
      return
    }
    if (sprintId) {
      this.backlogSprint[sprintId] = {
        ...this.backlogSprint[sprintId],
        content: [...items],
      }
    }
  }

  createBacklog(payload: BacklogPayload) {
    const backlogName = payload.backlogSetup.storeBacklogId
    const sprintId = payload.backlogSetup?.sprintInfo ? payload.backlogSetup.sprintInfo.id : ""
    const originalContent = sprintId ? this.backlogSprint[sprintId]?.content : this[backlogName].content
    const originalNumberOfPage = sprintId ? this.backlogSprint[sprintId]?.number : this[backlogName].number
    const items = parsingServerResponseContent({
      originalContent,
      serverResponseContent: payload.response.content,
      numberOfPage: payload.response.number,
      originalNumberOfPage,
    })
    if (backlogName !== "backlogSprint") {
      this[backlogName] = {
        ...payload.response,
        content: items,
      }
      return
    }
    if (sprintId) {
      this[backlogName][sprintId] = {
        ...payload.response,
        content: items,
      }
    }
  }

  @Mutation
  setShowGoals(value: boolean) {
    this.backlogShowGoals = value
  }

  @Mutation
  setShowAnalytics(value: boolean) {
    this.backlogShowAnalytics = value
  }

  @Mutation
  setBacklog(payload: BacklogPayload) {
    this.createBacklog(payload)
  }

  @Mutation
  setUnprioritizedBacklog(payload: BacklogPayload) {
    const backlogName = payload.backlogSetup.storeBacklogId
    const items = payload.response.content
    this.backlogUnprioritized = payload.response
    this.setBacklogContent({ items, backlogName })
  }


  @Mutation
  setEpicBacklog(payload: BacklogPayload) {
    const content = parsingServerResponseContent({
      originalContent: this.backlogEpic.content,
      serverResponseContent: payload.response.content,
      numberOfPage: payload.response.number,
      originalNumberOfPage: this.backlogEpic?.number,
    })
    this.backlogEpic = {
      ...payload.response,
      content,
    }
  }

  @Mutation
  setSprintBacklog(payload: BacklogPayload) {
    if (!payload?.backlogSetup?.sprintInfo) return
    this.createBacklog(payload)
  }

  @Mutation
  setEpicRoadmap(response: ServerResponse<Epic>) {
    const content: Epic[] = parsingServerResponseContent({
      originalContent: this.roadmapEpic.content,
      serverResponseContent: response.content,
      numberOfPage: response.number,
      originalNumberOfPage: this.roadmapEpic?.number,
    })
    this.roadmapEpic = { ...response, content }
  }

  @Mutation
  updateBacklogItems({ id, value, sprintId }) {
    if (sprintId && this[id][sprintId]) {
      this.setBacklogContent({ items: value, backlogName: id, sprintId })
    }
    if (id === "roadmapEpic") {
      this.roadmapEpic.content = value
    } else {
      this.setBacklogContent({ items: value, backlogName: id })
    }
  }

  @Mutation
  updateFilterValue(payload) {
    this[payload.id] = payload.value
  }

  @Mutation
  clearBacklogContentByName({ backlogName, sprintId } : {backlogName: string, sprintId?: string}) {
    this.setBacklogContent({ items: [], backlogName, sprintId })
  }

  @Mutation
  setBacklogItem(payload: UpdateElementInBacklog) {
    const { item, storeBacklogId, sprintId } = payload
    if (storeBacklogId === "backlogSprint") {
      if (!sprintId) return
      this.backlogSprint[sprintId].content = this.backlogSprint[sprintId].content.map(el => el.id === item.id ? item : el) as Item[]
    } else {
      this[storeBacklogId].content = this[storeBacklogId].content.map(el => el.id === item.id ? item : el)
    }
  }

  @Mutation
  updateStatus(payload) {
    this.status = payload
  }

  @Mutation
  updateResultCount(count: number) {
    this.resultCount = count
  }

  @Mutation
  updateBacklogItem({ params, itemId, storeBacklogId, sprintId }:{params:UpdateObjectValue<Item>, itemId:string, storeBacklogId:string, sprintId:string}) {
    if (storeBacklogId === "backlogSprint") {
      if (sprintId) {
        this.backlogSprint[sprintId].content = this.backlogSprint[sprintId].content.map((el) => {
          if (el.id === itemId) el[params?.["id"]] = params?.["value"]
          return el
        })
      } else {
        return
      }
    } else {
      this[storeBacklogId].content = this[storeBacklogId].content.map((el) => {
        if (el.id === itemId) el[params?.["id"]] = params?.["value"]
        return el
      })
    }
  }

  @Mutation
  updateUnprioritizedFilter(filter: string) {
    this.unprioritizedFilter = filter
  }

  @Mutation
  setEpicItems({ epicId, items } : { epicId: string, items: Item[] }) {
    this.itemsInEpic[epicId] = items
  }

  @Mutation
  insertItemToBacklog(payload: any) {
    const { item, index, backlogName, sprintId } = payload
    let backlogContent = sprintId && backlogName === "backlogSprint" ?
      cloneDeep(this.backlogSprint[sprintId].content) :
      cloneDeep(this[backlogName].content)
    if (!backlogContent) return
    const elementRepeats = backlogContent.find(el => el.id === item.id)
    if (elementRepeats) {
      backlogContent = backlogContent.map(el => el.id === item.id ? item : el)
      return this.setBacklogContent({
        backlogName,
        sprintId,
        items: backlogContent,
      })
    }
    if (index === null) {
      backlogContent.push(item)
    } else {
      backlogContent.splice(index, 0, item)
    }
    // Update totalElements
    if (sprintId && this.backlogSprint[sprintId]) {
      this.backlogSprint[sprintId].totalElements = this.backlogSprint[sprintId].content.length < backlogContent.length ?
        this.backlogSprint[sprintId].totalElements + 1 :
        this.backlogSprint[sprintId].totalElements
    } else if (this[backlogName]) {
      this[backlogName].totalElements = this[backlogName].content.length < backlogContent.length ?
        this[backlogName].totalElements + 1 :
        this[backlogName].totalElements
    }
    // Update content
    this.setBacklogContent({ backlogName, sprintId, items: backlogContent })
  }

  @Mutation
  removeItemFromBacklog(payload: RemoveItemFromBacklog) {
    const { itemId, nameBacklog, sprintId } = payload
    let contentArray = sprintId && nameBacklog === "backlogSprint"
      ? this.backlogSprint[sprintId].content
      : this[nameBacklog].content

    if (!contentArray || !Array.isArray(contentArray)) {
      return
    }
    contentArray = contentArray.filter(el => el.id !== itemId)
    this.setBacklogContent({
      items: contentArray,
      backlogName: nameBacklog,
      sprintId,
    })
  }

  @Mutation
  setRoadmapEpicContent(epics: Epic[]) {
    this.roadmapEpic.content = epics
  }

  @Mutation
  setBacklogAnalytics(value: BacklogAnalytics) {
    this.backlogAnalytics = value
  }

  @Mutation
  addRoadmapEpicContent(epics: Epic[]) {
    this.roadmapEpic.content = [...this.roadmapEpic.content, ...epics]
  }

  @Mutation
  setMainBacklogSetup(backlogSetup: BacklogSetup) {
    this.mainBacklogSetup = backlogSetup
  }

  @Action
  async [BacklogActions.GET_BACKLOG](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.BACKLOG(this.projectId), query)
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      const content: Item[] = data.content.map(i => Item.create(i))
      const response = { ...data, content }
      if (!backlogSetup.filter.onlyGet) {
        this[backlogSetup.storeMutation]({
          response,
          backlogSetup,
        })
      }
      return response
    })
  }

  @Action
  async [BacklogActions.GET_FULL_BACKLOG](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.PRODUCT_BACKLOG(this.projectId), query)
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      const content: Item[] = data.content.map(i => Item.create(i))
      const response = { ...data, content }
      if (!backlogSetup.filter.onlyGet) {
        this[backlogSetup.storeMutation]({
          response,
          backlogSetup,
        })
      }
      return response
    })
  }

  @Action
  async [BacklogActions.GET_ITEMS_IN_EPIC](filter: FilterBacklog) {
    if (!filter.epicIds?.length) return false

    const query = filter.getJsonObj()

    const data = await apiService.get<ServerResponse<Item>>(Endpoint.BACKLOG(this.projectId), query)
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      const content: Item[] = data.content.map(i => Item.create(i))
      if (filter.epicIds?.length) {
        this.setEpicItems({
          epicId: filter.epicIds[0],
          items: content,
        })
      }
    })
  }

  @Action
  async [BacklogActions.GET_EPIC_BACKLOG](backlogSetup: BacklogSetup) {
    if (backlogSetup.filter.epicIds) {
      return await this[BacklogActions.GET_BACKLOG](backlogSetup)
    }
  }

  @Action
  async [BacklogActions.GET_BACKLOG_MILESTONES](backlogSetup: BacklogSetup) {
    await milestoneModule[MilestoneActions.GET_MILESTONES_ALL](backlogSetup)
  }


  @Action
  async [BacklogActions.GET_EPIC_ROADMAP](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<Epic>>(Endpoint.GET_EPICS(this.projectId), query)
    return this.handleResponse<ServerResponse<Epic>>(data, (data) => {
      const content: Epic[] = data.content.map(e => Epic.create(e))
      this.setEpicRoadmap(Object.assign(data, { content }))
    })
  }

  @Action
  async [BacklogActions.CHANGE_BACKLOG_PRIORITY](payload: ItemQueryPriority) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.CHANGE_ITEM_PRIORITY(this.projectId), payload)
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) {
        // TODO - reload backlog
      }
    })
  }

  @Action
  async [BacklogActions.CHANGE_BACKLOG_PRIORITY_BATCH](payload: ItemQueryPriority[]) {
    const data = await apiService.patch<ServerPriorityResponse[]>(Endpoint.CHANGE_ITEM_PRIORITY_BATCH(this.projectId), payload)
    return this.handleResponse<ServerPriorityResponse[]>(data)
  }

  @Action
  async [BacklogActions.CHANGE_EPIC_PRIORITY](payload: ItemQueryPriority) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.CHANGE_EPIC_PRIORITY(this.projectId), payload)
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) {
        // TODO - reload backlog
      }
    })
  }

  @Action
  async [BacklogActions.CHANGE_SPRINT_PRIORITY](payload: ItemQueryPriority) {
    if (payload.sprintId) {
      await sprintModule[SprintActions.ADD_SPRINT_ITEM](payload)
    }
    await this[BacklogActions.CHANGE_BACKLOG_PRIORITY](payload)
  }

  @Action
  async [BacklogActions.CHANGE_EPIC_PRIORITY_IN_MILESTONE](payload: ItemQueryPriority) {
    if (payload.milestoneId) {
      await epicModule[EpicActions.MOVE_EPIC_PRIORITY_IN_MILESTONE](payload)
    }
  }

  @Action
  async [BacklogActions.ADD_EPIC_FROM_MILESTONE](payload: ItemQueryPriority) {
    if (payload.milestoneId) {
      const success = await epicModule[EpicActions.MOVE_EPIC_TO_MILESTONE](payload)
      const epic = payload.item
      if (success && epic) {
        const alertContent: AlertContentItem[] = [
          {
            text: `Epic E-${epic.sequenceNumber} `,
            type: "bold",
          },
          { text: "added to Milestone", type: "regular" },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertContent,
        })
      }
    }
  }

  @Action
  async [BacklogActions.DELETE_EPIC_FROM_MILESTONE](payload: MilestoneAddEpic) {
    if (payload.milestoneId) {
      const success = await milestoneModule[MilestoneActions.DELETE_MILESTONE_EPIC](payload)
      const epic = payload.item
      if (success && epic) {
        const alertContent: AlertContentItem[] = [
          {
            text: `Epic E-${epic.sequenceNumber} `,
            type: "bold",
          },
          { text: "removed from Milestone", type: "regular" },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertContent,
        })
      }
    }
  }

  @Action
  async [BacklogActions.GET_MY_WORK](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.MY_WORK_PRIORITIZED(this.accountId), query)
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      const content: Item[] = data.content.map(i => Item.create(i))
      const response = { ...data, content }
      if (!backlogSetup.filter.onlyGet) {
        this[backlogSetup.storeMutation]({
          response,
          backlogSetup,
        })
      }
      return response
    })
  }

  @Action
  async [BacklogActions.BACKLOG_ANALYTICS]({ filterBacklog } : { filterBacklog: FilterBacklog }) {
    let query = filterBacklog.getJsonObj()

    // Ensure the query is not empty or only contains 'withLatestBlockedOrObsoleteDescription'
    // by setting the query to include the accountId if necessary. This is because the backend
    // does not accept an empty query.
    if (query instanceof URLSearchParams) {
      const queryObj = Object.fromEntries(query.entries())

      if ((Object.keys(queryObj).length === 1 && "withLatestBlockedOrObsoleteDescription" in queryObj) || Object.keys(queryObj).length === 0) {
        query = new URLSearchParams({ accountId: this.accountId })
      }
    }

    const data = await apiService.get<BacklogAnalytics>(Endpoint.BACKLOG_ANALYTICS(this.projectId), query)
    return this.handleResponse<BacklogAnalytics>(data, (data) => {
      const backlogAnalytics = new BacklogAnalytics(data)
      this.setBacklogAnalytics(backlogAnalytics)
      return backlogAnalytics
    })
  }

  @Action
  async [BacklogActions.MY_WORK_CHANGE_PRIORITY](payload: ItemQueryPriority) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.MY_WORK_CHANGE_PRIORITY(this.accountId), payload)
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) {
        // TODO - reload backlog
      }
    })
  }

  @Action
  [BacklogActions.UPDATE_BACKLOG_ITEMS](payload) {
    this.updateBacklogItems(payload)
  }

  @Action
  async [BacklogActions.UPDATE_BACKLOG_ITEM]({ params, itemId, storeBacklogId, sprintId }:{params:UpdateObjectValue<Item>, itemId:string, storeBacklogId:string, sprintId:string}) {
    this.updateBacklogItem({ params, itemId, storeBacklogId, sprintId })
  }

  @Action
  [BacklogActions.SELECT_FILTER_VALUE](payload) {
    this.updateFilterValue(payload)
  }

  @Action
  [BacklogActions.SELECT_STATUS](payload) {
    this.updateStatus(payload)
  }

  @Action
  [BacklogActions.SELECT_EPIC](payload) {
    epicModule.setEpic(payload)
  }


  @Action
  [BacklogActions.CHANGE_UNPRIORITIZED_FILTER](filter) {
    this.updateUnprioritizedFilter(filter)
  }

  @Action
  [BacklogActions.SET_BACKLOG_ITEM](payload: UpdateElementInBacklog) {
    this.setBacklogItem(payload)
  }

  @Action
  [BacklogActions.INSERT_BACKLOG_ITEM](payload: InsertItemInBacklog) {
    this.insertItemToBacklog(payload)
  }

  @Action
  [BacklogActions.REMOVE_ITEM_FROM_BACKLOG](payload) {
    this.removeItemFromBacklog(payload)
  }

  @Action
  [BacklogActions.REMOVE_ITEM_FROM_BACKLOG_AND_SET_TO_PREVIOUS_BACKLOG]({ from, to }: { from: RemoveItemFromBacklog, to: InsertItemInBacklog }) {
    const { itemId, nameBacklog: fromNameBacklog, sprintId: fromSprintId } = from
    const { item, index, backlogName: toNameBacklog, sprintId: toSprintId } = to

    this.removeItemFromBacklog({
      itemId,
      nameBacklog: fromNameBacklog,
      sprintId: fromSprintId,
    })

    this.insertItemToBacklog({
      item,
      index,
      backlogName: toNameBacklog,
      sprintId: toSprintId,
    })
  }

  @Action
  [BacklogActions.SET_MAIN_BACKLOG_SETUP](backlogSetup: BacklogSetup) {
    this.setMainBacklogSetup(backlogSetup)
  }

  @Action
  [BacklogActions.CLEAR_EPICS_LIST_CONTENT]() {
    this.setRoadmapEpicContent([])
  }

  @Action
  [BacklogActions.CLEAR_BACKLOG_CONTENT_BY_NAME]({ backlogName, sprintId } : {backlogName: string, sprintId?: string}) {
    this.clearBacklogContentByName({ backlogName, sprintId })
  }

  @Action
  [BacklogActions.BACKLOG_SHOW_ANALYTICS](value) {
    this.setShowAnalytics(value)
  }

  @Action
  [BacklogActions.SHOW_CHANGE_PRIORITY_MESSAGE](changePriorityMessage: ChangePriorityMessageParams) {
    const { toBacklogSprintNumber, fromBacklogSprintNumber, fromBacklogId, toBacklogId, newIndex, item } = changePriorityMessage
    const toSprintNumber = toBacklogSprintNumber ? "#" + toBacklogSprintNumber : ""
    const isFromSprintNumber = fromBacklogSprintNumber ? "#" + fromBacklogSprintNumber : ""
    if (!(item instanceof Item)) return
    const alertContent: AlertContentItem[] = [
      { text: `Item #${item.sequenceNumber} `, type: "bold" },
      { text: "moved from ", type: "regular" },
      {
        text: `${$t(`backlog.titles['${fromBacklogId}']`) + isFromSprintNumber} `,
        type: "bold",
      },
      { text: "Backlog into ", type: "regular" },
      {
        text: `${$t(`backlog.titles['${toBacklogId}']`) + toSprintNumber} `,
        type: "bold",
      },
      { text: "Backlog Priority ", type: "regular" },
      { text: `order ${newIndex + 1}`, type: "bold" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
  }

  @Action
  [BacklogActions.BACKLOG_SHOW_GOALS](value: boolean) {
    this.setShowGoals(value)
  }
}

export const backlogModule = new BacklogModule({
  store,
  name: Modules.BACKLOG,
})
