import { User } from "./User"

export interface RetroNoteDTO {
  description: string;
  wentWell: boolean;
  reviewed: boolean;
  user?: User | null;
  stackId?: string;
  hasActionItem?: boolean;
  priority: number;
  id?: string
}

export interface RetroNoteProps {
  description: string;
  reviewed: boolean;
  wentWell: boolean;
  user?: User | null;
  stackId?: string;
  hasActionItem?: boolean;
  priority: number;
  id?: string
  isNewNote?: boolean
}

export class RetroNote {
  description: string
  reviewed: boolean
  wentWell: boolean
  user?: User | null
  stackId?: string
  hasActionItem?: boolean
  priority: number
  id?: string
  isNewNote?: boolean
  constructor(props?: RetroNoteProps) {
    this.setDefaultData()
    if (!props) return

    const {
      description,
      reviewed,
      wentWell,
      user,
      stackId,
      hasActionItem,
      priority,
      id,
      isNewNote,
    } = props

    this.description = description
    this.reviewed = reviewed
    this.wentWell = wentWell
    this.user = user
    this.stackId = stackId
    this.hasActionItem = hasActionItem
    this.priority = priority
    this.id = id
    this.isNewNote = isNewNote
  }

  static create(retroNoteDTO?: RetroNoteDTO): RetroNote {
    if (retroNoteDTO) {
      const {
        description,
        reviewed,
        wentWell,
        user,
        stackId,
        hasActionItem,
        priority,
        id,
      } = retroNoteDTO

      const retroNote = new RetroNote({
        description: description,
        reviewed: reviewed,
        wentWell: wentWell,
        user: user,
        stackId: stackId,
        hasActionItem: hasActionItem,
        priority: priority,
        id: id,
      })
      return retroNote
    }
    return new RetroNote()
  }

  setDefaultData() {
    this.description = ""
    this.reviewed = false
    this.wentWell = false
    this.user = null
    this.stackId = ""
    this.hasActionItem = false
    this.priority = 0
    this.id = ""
  }

  static createWell(): RetroNote {
    return new RetroNote({
      reviewed: false,
      wentWell: true,
      description: "",
      priority: 0,
    })
  }

  static createImporove(): RetroNote {
    return new RetroNote({
      reviewed: false,
      wentWell: false,
      description: "",
      priority: 0,
    })
  }

  static createDefault(): RetroNote {
    return new RetroNote()
  }

  getJsonObj() {
    return {
      description: this.description,
      reviewed: this.reviewed,
      wentWell: this.wentWell,
    }
  }

  get fullName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}`
  }
}
