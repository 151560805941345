export class ServerResponseSort {
  sorted = false
  unsorted = false
  empty = false

  constructor(props?: Partial<ServerResponseSort>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class ServerResponsePagable {
  offset = 0
  pageNumber = 0
  pageSize = 0
  paged = false
  sort: ServerResponseSort = new ServerResponseSort()
  unpaged = false

  constructor(props?: Partial<ServerResponsePagable>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
    this.sort = new ServerResponseSort(props.sort)
  }
}

export class ServerResponse<T> {
  content: T[] = []
  empty = false
  first = true
  last = true
  number = 0
  numberOfElements = 0
  pageable: ServerResponsePagable = new ServerResponsePagable()
  size = 0
  sort: ServerResponseSort = new ServerResponseSort()
  totalElements = 0
  totalPages = 0

  constructor(props?: Partial<ServerResponse<T>>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
    this.sort = new ServerResponseSort(props.sort)
    this.pageable = new ServerResponsePagable(props.pageable)
  }
}
