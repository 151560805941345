import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { projectModule, ProjectActions } from "./project.module"
import { AlertActions, alertModule } from "./alert.module"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { $t } from "@/plugins/i18n"
import { ServerResponse } from "@/models/ServerResponse"
import {
  ChangeMilestoneDate,
  Milestone,
  MilestoneAddEpic,
  MilestoneDTO,
  MilestonePreview,
} from "@/models/Milestone"
import { BacklogSetup } from "@/models/Backlog"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { formatDate, nextMonths } from "@/utils/dateUtil"
import addDays from "date-fns/esm/addDays"
import { Filter } from "@/models"

export enum MilestoneActions {
  GET_MILESTONE = "GET_MILESTONE",
  POST_MILESTONE = "POST_MILESTONE",
  POST_MILESTONE_EPIC = "POST_MILESTONE_EPIC",
  DELETE_MILESTONE_EPIC = "DELETE_MILESTONE_EPIC",
  ADD_NEW_MILESTONE = "ADD_NEW_MILESTONE",
  CANCEL_MILESTONE = "CANCEL_MILESTONE",
  CHANGE_MILESTONE_DATE = "CHANGE_MILESTONE_DATE",
  CHANGE_MILESTONE = "CHANGE_MILESTONE",
  GET_MILESTONES = "GET_MILESTONES",
  GET_MILESTONES_REVIEW = "GET_MILESTONES_REVIEW",
  GET_MILESTONES_ALL = "GET_MILESTONES_ALL",
  UPDATE_MILESTONE_IN_LIST = "UPDATE_MILESTONE_IN_LIST",
  GET_MILESTONES_ALL_CONTENT = "GET_MILESTONES_ALL_CONTENT",
  CREATE_NEW_MILESTONE = "CREATE_NEW_MILESTONE",
  SET_LAST_MILESTONE = "SET_LAST_MILESTONE",
  GET_MILESTONES_PERIOD = "GET_MILESTONES_PERIOD",
  GET_LAST_MILESTONE = "GET_LAST_MILESTONE",
  LIST_OF_MILESTONE_CHARTS = "LIST_OF_MILESTONE_CHARTS",
}
@Module
class MilestoneModule extends BaseModule {
  milestone: Milestone
  milestones: ServerResponse<Milestone>
  reviewedMilestones: ServerResponse<Milestone>
  milestoneCharts: ServerResponse<MilestonePreview>

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${MilestoneActions.GET_MILESTONES}`]() {
    return this.milestones
  }

  get [`get/${MilestoneActions.GET_MILESTONES_REVIEW}`]() {
    return this.reviewedMilestones
  }

  get [`get/${MilestoneActions.GET_MILESTONE}`]() {
    return this.milestone
  }

  get [`get/${MilestoneActions.LIST_OF_MILESTONE_CHARTS}`]() {
    return this.milestoneCharts
  }

  get [`get/${MilestoneActions.GET_LAST_MILESTONE}`]() {
    const index = this.milestones.content.length - 1
    return this.milestones.content[index]
  }

  get [`get/${MilestoneActions.GET_MILESTONES_ALL}`]() {
    return this.getAllMilestones()
  }

  get [`get/${MilestoneActions.GET_MILESTONES_ALL_CONTENT}`]() {
    const reviewContent = this.reviewedMilestones?.content ?? []
    const milestoneContent = this.milestones?.content ?? []
    return [...reviewContent, ...milestoneContent]
  }

  @Mutation
  setMilestones(milestone: ServerResponse<Milestone>) {
    this.milestones = milestone
  }

  @Mutation
  setMilestoneInList(milestone: Milestone) {
    const milestoneIndex = this.milestones.content.findIndex(e => e.id === milestone.id)
    if (milestoneIndex === -1) {
      this.milestones.content.push(milestone)
      return
    }
    this.milestones.content[milestoneIndex] = milestone
  }

  @Mutation
  setMilestoneReview(milestone: ServerResponse<Milestone>) {
    this.reviewedMilestones = milestone
  }

  @Mutation
  setMilestone(milestone: Milestone) {
    this.milestone = milestone
  }

  @Mutation
  newMilestone() {
    const milestones = this.milestones.content
    const firstWord = 65
    const previousMilestone = milestones[milestones.length - 1] || this.reviewedMilestones.content[this.reviewedMilestones.content.length - 1]
    let newMilestoneTitle = ""
    const checkFirstWord = previousMilestone.title?.slice(-2).toUpperCase().charCodeAt(0) ?? 0
    const checkLastWord = previousMilestone.title?.slice(-1).toUpperCase().charCodeAt(0) ?? 0

    if (checkLastWord === 90 && checkFirstWord === 32) {
      newMilestoneTitle = `Milestone ${String.fromCharCode(firstWord, firstWord)}`
    } else if (checkLastWord === 90) {
      newMilestoneTitle = `Milestone ${String.fromCharCode(checkFirstWord + 1, firstWord)}`
    } else {
      newMilestoneTitle = `Milestone ${String.fromCharCode(checkFirstWord, checkLastWord + 1)}`
    }
    const newStartDay = previousMilestone.endDate
    const addNewMilestone = new Milestone({
      title: newMilestoneTitle,
      startDate: addDays(newStartDay, 1),
      endDate: nextMonths(newStartDay, 3),
      epics: [],
      newMilestone: true,
      status: "COMING",
    })
    milestones.push(addNewMilestone)
  }

  @Mutation
  setLastMilestone(milestone: Milestone) {
    const index = this.milestones.content.length ? this.milestones.content.length - 1 : 0
    this.milestones.content[index] = milestone
  }

  @Mutation
  cancelMilestone() {
    const milestones = this.milestones.content
    milestones.splice(-1)
  }

  @Mutation
  setMilestoneCharts(milestoneCharts: ServerResponse<MilestonePreview>) {
    this.milestoneCharts = milestoneCharts
  }

  @Mutation
  updateMilestoneInList() {
    this.milestones.content = this.milestones.content.map((e) => {
      if (e.id === this.milestone.id) {
        e = this.milestone
      }
      return e
    })
  }

  @Mutation
  updateMilestone({ field, value, milestoneId } : {field: string, value: any, milestoneId: string}) {
    if (milestoneId.length) {
      this.milestones.content = this.milestones.content.map((e) => {
        if (e.id === milestoneId) e[field] = value
        return e
      })
    } else {
      const lastIndex = this.milestones.content.length - 1
      this.milestones.content[lastIndex][field] = value
    }
  }

  @Action
  async [MilestoneActions.GET_MILESTONE](milestoneId: string) {
    const data = await apiService.get<MilestoneDTO>(Endpoint.GET_MILESTONE(this.projectId, milestoneId))
    return this.handleResponse<MilestoneDTO>(data, (data) => {
      const milestone = Milestone.create(data)
      this.setMilestone(milestone)
    })
  }

  @Action
  async [MilestoneActions.POST_MILESTONE_EPIC](payload: MilestoneAddEpic) {
    // TODO - Perhaps this endpoint will not be used to add new epic in milestone
    const data = await apiService.post<Milestone>(Endpoint.POST_MILESTONE_EPIC(this.projectId, payload.milestoneId, payload.epicId))
    return this.handleResponse<Milestone>(data)
  }

  @Action
  async [MilestoneActions.DELETE_MILESTONE_EPIC](payload: MilestoneAddEpic) {
    const data = await apiService.delete<Milestone>(Endpoint.POST_MILESTONE_EPIC(this.projectId, payload.milestoneId, payload.epicId))
    return this.handleResponse<Milestone>(data)
  }

  @Action
  async [MilestoneActions.POST_MILESTONE]({ milestone, projectId }: { milestone: Milestone, projectId?: string }) {
    const data = await apiService.post<MilestoneDTO>(Endpoint.POST_MILESTONE(projectId ?? this.projectId), milestone.getJsonObj())
    return this.handleResponse<MilestoneDTO>(data, (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Milestone ${$t("alert.created")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      const milestone = Milestone.create(data)
      this.setMilestone(milestone)
      this.setLastMilestone(milestone)
      return milestone
    })
  }

  @Action
  async [MilestoneActions.GET_MILESTONES](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<MilestoneDTO>>(Endpoint.GET_MILESTONES(this.projectId), query)
    return this.handleResponse<ServerResponse<MilestoneDTO>>(data, (data) => {
      const content: Milestone[] = data.content.map(e => Milestone.create(e))
      this.setMilestones(Object.assign(data, { content }))
    })
  }

  @Action
  async [MilestoneActions.GET_MILESTONES_REVIEW](backlogSetup: BacklogSetup) {
    const query = backlogSetup.filter.getJsonObj()
    const data = await apiService.get<ServerResponse<MilestoneDTO>>(Endpoint.GET_MILESTONES_REVIEW(this.projectId), query)
    return this.handleResponse<ServerResponse<MilestoneDTO>>(data, (data) => {
      const content: Milestone[] = data.content.map((e) => {
        e.status = "COMPLETED"
        return Milestone.create(e)
      })
      this.setMilestoneReview(Object.assign(data, { content }))
    })
  }

  @Action
  async [MilestoneActions.GET_MILESTONES_PERIOD]({ body, projectId }: { body: ChangeMilestoneDate, projectId: string }) {
    const data = await apiService.get<ServerResponse<MilestoneDTO>>(Endpoint.MILESTONES_PERIOD(projectId), body.getJsonObj())
    return this.handleResponse<ServerResponse<MilestoneDTO>>(data, (data) => {
      const content: Milestone[] = data.content.map(e => Milestone.create(e))
      this.setMilestones(Object.assign(data, { content }))
    })
  }

  @Action
  async [MilestoneActions.LIST_OF_MILESTONE_CHARTS]({ projectId, onlyGet, filter }: { projectId: string, onlyGet?: boolean, filter: Filter }) {
    const data = await apiService.get<ServerResponse<MilestonePreview>>(Endpoint.LIST_OF_MILESTONE_CHARTS(projectId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<MilestonePreview>>(data, (data) => {
      if (!onlyGet) {
        this.setMilestoneCharts(data)
      }
      return data
    })
  }

  @Action
  async [MilestoneActions.GET_MILESTONES_ALL](backlogSetup: BacklogSetup) {
    await Promise.all([
      this[MilestoneActions.GET_MILESTONES_REVIEW](backlogSetup),
      this[MilestoneActions.GET_MILESTONES](backlogSetup),
    ])
  }

  @Action
  async [MilestoneActions.ADD_NEW_MILESTONE]() {
    this.newMilestone()
  }

  @Action
  async [MilestoneActions.CANCEL_MILESTONE]() {
    this.cancelMilestone()
  }

  @Action
  async [MilestoneActions.CHANGE_MILESTONE]({ field, value, milestoneId } : {field: string, value: any, milestoneId: string}) {
    this.updateMilestone({ field, value, milestoneId })
  }

  @Action
  async [MilestoneActions.CHANGE_MILESTONE_DATE]({ params, projectId }: { params: ChangeMilestoneDate, projectId?: string }) {
    const data = await apiService.patch<MilestoneDTO>(Endpoint.CHANGE_MILESTONE_DURATION(projectId ?? this.projectId, params.milestone?.id ?? ""), params.getJsonObj())
    return this.handleResponse<MilestoneDTO>(data, (data) => {
      const startDate = formatDate(params.startDate, "M/dd/Y")
      const endDate = formatDate(params.endDate, "M/dd/Y")
      const alertContent: AlertContentItem[] = [
        { text: `${params.milestone.title} `, type: "bold" },
        { text: "dates changed to ", type: "regular" },
        { text: `${startDate} `, type: "bold" },
        { text: "and ", type: "regular" },
        { text: `${endDate} `, type: "bold" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      const milestone = Milestone.create(data)
      this.setMilestoneInList(milestone)
      return milestone
    })
  }

  @Action
  async [MilestoneActions.UPDATE_MILESTONE_IN_LIST]() {
    this.updateMilestoneInList()
  }

  @Action
  async [MilestoneActions.SET_LAST_MILESTONE](milestone) {
    this.setLastMilestone(milestone)
  }

  getAllMilestones() {
    const milestoneResponse = { ...this.milestones }
    const reviewContent = this.reviewedMilestones?.content ?? []
    const milestoneContent = this.milestones?.content ?? []
    const content = [...reviewContent, ...milestoneContent]
    milestoneResponse.content = content
    return milestoneResponse
  }
}

export const milestoneModule = new MilestoneModule({
  store,
  name: Modules.MILESTONE,
})
