
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { NodeViewWrapper } from "@tiptap/vue-3"
import { Account, Project, Team } from "@/models"
import { Getters } from "@/store/getters"
import { Action, Getter } from "s-vuex-class"
import { Actions } from "@/store"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { router } from "@/router"
import { Editor } from "@tiptap/core"
import { Node as ProseMirrorNode } from "@tiptap/pm/model"

const components = {
  NodeViewWrapper,
}

export const getHrefByType = (type: ReferenceType, info: any, accountName: string, projectName: string) => {
  let link
  switch (type) {
    case ReferenceType.USER:
      link = `mailto:${info.email.toLowerCase()}`
      break
    case ReferenceType.PROJECT:
      link = `/${accountName}/projects/${info.urlName}`
      break
    case ReferenceType.EPIC:
      link = `/${accountName}/${projectName}/epic/${info.sequenceNumber}`
      break
    case ReferenceType.MILESTONE:
      link = `/${accountName}/${projectName}/milestones/${info.title?.toLowerCase() || "a"}`
      break
    case ReferenceType.SPRINT:
      link = `/${accountName}/${projectName}/${info.team.urlName}/sprint/${info.sequenceNumber}`
      break
    case ReferenceType.ITEM:
      link = `/${accountName}/${projectName}-${info.sequenceNumber}`
      break
    case ReferenceType.TASK:
      link = `/${accountName}/${projectName}-${info.parentSequenceNumber}?task=${info.order - 1}`
      break
    case ReferenceType.TEAM:
      link = `/${accountName}/team/${info.urlName}`
      break
  }

  return link
}

@Options({
  name: "ReferenceComponentCommon",
  components,
})
export default class extends Vue {
  @Getter(Getters.GET_SESSION_ACCOUNT) account: Account

  @Getter(Getters.GET_SESSION_PROJECT_ID) readonly projectId: string
  @Getter(Getters.GET_SESSION_TEAM) team: Team

  @Action(Actions.GET_PROJECT) getProject: ({ projectId, onlyGet } : {projectId: string, onlyGet?: boolean}) => Promise<Project>

  @Prop() node: ProseMirrorNode
  @Prop() editor: Editor
  @Prop() updateAttributes: (attributes: Record<string, any>) => void
  @Prop() getPos: () => number

  nodeViewWrapper: any = "span"

  get text() {
    return this.node.attrs.linkText
  }

  get originUrl() {
    return this.node.attrs.originUrl
  }

  get href() {
    return this.node.attrs.linkHref
  }

  get isShowLoading() {
    return this.node.attrs.loading
  }

  get isShowDetails() {
    return this.node.attrs.showDetails
  }

  get info() {
    try {
      return JSON.parse(this.node.attrs.info)
    } catch {
      return false
    }
  }

  get type() {
    return this.node.attrs.configType
  }

  get referenceTypes() {
    return ReferenceType
  }

  get route() {
    return router.resolve(this.originUrl.replace(location.origin.toString(), ""))
  }

  created() {
    if (this.editor) {
      this.nodeViewWrapper = NodeViewWrapper
    }
  }

  async getHrefByType(type, info?) {
    const accountName = this.account.urlName
    const projectId = info?.projectId || this.info.projectId || this.projectId
    const projectName = (await this.getProject({
      projectId,
      onlyGet: true,
    })).urlName
    if (projectName) {
      return getHrefByType(type, info || this.info, accountName, projectName)
    }
    return ""
  }
}
