<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__bug-watermark"
  >
    <template #pic>
      <div
        class="label-pic"
        :class="[labelColor]"
      >
        <SeverityLevelIcon
          :level="notification.level"
          width="16px"
          height="16px"
          class="label-pic__icon"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Bug Watermark Exceeded
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        {{ notification.breadcrumbs }}
      </BaseSubHeading>

      <div
        v-if="notification.level"
        class="notification__bug-watermark-line"
      >
        <BaseSubHeading
          size="medium"
          color="monochrome-07"
          darkColor="monochrome-03"
          weight="500"
          className="notification__bug-watermark-left"
        >
          {{ notification.level.toLowerCase() }}
        </BaseSubHeading>
        <div class="notification__bug-watermark-right">
          <BaseSubHeading
            size="medium"
            color="serviceAlert-02"
            weight="600"
            className=""
          >
            {{ levelValue.current }}
          </BaseSubHeading>
          <BaseSubHeading
            size="medium"
            color="monochrome-07"
            weight="400"
            className=""
          >
            &nbsp;of&nbsp;
          </BaseSubHeading>
          <BaseSubHeading
            size="medium"
            color="serviceAlert-02"
            weight="600"
            className=""
          >
            {{ levelValue.max }}
          </BaseSubHeading>
        </div>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import {
  NotificationBugWatermark,
} from "@/models/Notification/NotificationBugWatermark"
import SeverityLevelIcon from "@/components/SeverityLevel/SeverityLevelIcon.vue"


@Options({
  name: "BUG_WATERMARK_EXCEEDED_BY_LEVEL",
  components: {
    NotificationTemplate,
    SeverityLevelIcon,
  },
})
export default class BUG_WATERMARK_EXCEEDED_BY_LEVEL extends Vue {
  @Prop() readonly notification: NotificationBugWatermark

  get labelColor() {
    switch (this.notification.level) {
      case "CRITICAL":
        return "is-red"
      case "HIGH":
        return "is-orange"
      case "MEDIUM":
        return "is-yellow"
      case "TRIVIAL":
      default:
        return "is-blue"
    }
  }

  get levelValue() {
    return this.notification.content[this.notification.level.toLowerCase()]
  }
}
</script>
