<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__update-item"
  >
    <template #pic>
      <div class="label-pic is-teal">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}
        </a>
        Story Points changed by <span class="notification__update-item__initiator">@{{ notification.initiatorFullName }}</span>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__update-item__content"
      >
        <StoryPoint
          :value="notification.getOldItemValue('storyPoints')"
          class="is-opacity"
        />
        <icon
          data="@icon/arrow-to.svg"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
        <StoryPoint :value="notification.getItemValue('storyPoints')" />
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import StoryPoint from "@/components/StoryPoint/index.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "ITEM_UPDATE_STORYPOINTS",
  components: {
    NotificationTemplate,
    StoryPoint,
  },
})
export default class ITEM_UPDATE_STORYPOINTS extends Vue {
  @Prop() readonly notification: NotificationItem
}
</script>
