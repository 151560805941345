import { createApp, App } from "vue"

import "@/assets/scss/app.scss"
import "@/assets/scss/app.dark.scss"

import "@/models"
import AppComponent from "./App.vue"
import { router, applyMiddleware } from "@/router"
import { store } from "@/store"
import acl from "@/plugins/acl"

import { $keycloak, updateToken } from "@/plugins/keycloak"
import { $t, $tc, applyLocalization } from "@/plugins/i18n"
import { applyInterceptors } from "./plugins/axios"
import BaseComponents from "@/components/Base"
import { configureServerSentEvent } from "@/events/index"
import { errorHandlerService } from "./services/error-handler.service"
import { LogActions, logModule } from "./store/modules/log.module"
import { decoratorService } from "./services/decorator.service"
import { trackService } from "./services/track.service"
import { $fancybox } from "./plugins/fancybox"
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"
import { pathSignUp } from "./router/routers"
import { isLocalHost } from "./app.config"

const isSignUp = window.location.pathname.includes(pathSignUp)

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    // eslint-disable-next-line no-undef
    $context: AppContext;
    $fancybox: Fancybox;
  }
}

export class Main {
  app: App<Element>

  constructor() {
    this.bootstrap()
    if (!isLocalHost()) {
      errorHandlerService.init(logModule[LogActions.LOG_ERROR])
      trackService.init(logModule[LogActions.LOG_PAGE_LOAD])
    }
  }

  private async keyclockInit(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      $keycloak
        .init({
          onLoad: isSignUp ? undefined : "login-required",
        })
        .then(auth => resolve(auth))
    })
  }

  private async bootstrap(): Promise<void> {
    const auth = await this.keyclockInit()
    const sse = () => configureServerSentEvent()

    this.app = createApp(AppComponent)
    this.app.use(store)
    this.app.use(router)
    this.app.use(acl)

    if (isSignUp) return

    applyLocalization(this)

    this.registerBaseComponents()

    await applyMiddleware()
    await applyInterceptors()

    if (!auth) {
      window.location.reload()
    } else {
      window.onfocus = () => {
        updateToken()
      }
    }

    await this.postInit()

    this.setInjects()


    this.app.mount("#app")
    sse()


  }

  setInjects() {
  //   this.app.provide('updateToken', updateToken.bind(null, $keycloak));
  }

  applyConfig() {
    // this.app.config.productionTip = false;
    this.app.config.globalProperties.$t = $t
    this.app.config.globalProperties.$tc = $tc
    this.app.config.globalProperties.$keycloak = $keycloak
    this.app.config.globalProperties.$context = this.app._context
    this.app.config.globalProperties.$fancybox = $fancybox
    this.app.config.errorHandler = function (err: any, vm, info) {
      errorHandlerService.onAppError(err, info)
    }
  }

  registerBaseComponents() {
    BaseComponents.register(this.app)
  }

  async postInit() {
    this.applyConfig()
    decoratorService.setContext(this.app._context)
    decoratorService.setStore(store)
    await import("@/plugins")
  }
}

export const main = new Main()
