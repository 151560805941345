import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import { Plugins } from "@fancyapps/ui/src/Fancybox/plugins/index";
import "@fancyapps/ui/dist/fancybox.css";

Fancybox.bind(`[data-fancybox]`, {
  closeButton: "outside",
  type: "image",
  click: "close",
});

const blobUrls = {};

async function setBlobContent(slide) {
  if (slide.type === 'html5video') {
    slide.loaded = true;
    return slide;
  }

  if (blobUrls[slide.fileMetadataId]) {
    slide.src = blobUrls[slide.fileMetadataId];
    slide.loaded = true;
    return slide;
  }

  if (slide.$el) {
    slide.$el.classList.add('fancybox-loading');
  }

  const blob = await slide.blob({
    fileMetadataId: slide.fileMetadataId,
  });

  const blobUrl = URL.createObjectURL(blob);
  blobUrls[slide.fileMetadataId] = blobUrl;
  slide.src = blobUrl;
  slide.loaded = true;

  if (slide.$el) {
    slide.$el.classList.remove('fancybox-loading');
  }

  return slide;
}

class CustomImage extends Plugins.Image {
  async setContent(slide) {
    if (slide?.blob && slide.type === 'image') {
      if (slide.loaded) {
        super.setContent(slide);
        return;
      }

      slide = await setBlobContent(slide);
    }
    super.setContent(slide);
  }
}

class CustomHtml extends Plugins.Html {
  async setContent(slide) {
    if (slide?.blob && slide.type !== 'image') {
      if (slide.loaded) {
        super.setContent(slide);
        return;
      }

      slide = await setBlobContent(slide);
    }
    super.setContent(slide);
  }
}

Fancybox.Plugins = { ...Plugins, Image: CustomImage, Html: CustomHtml };

export const $fancybox = Fancybox;
