import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@icon/check.svg'


const _hoisted_1 = {
  key: 0,
  class: "to-do-assign__item__content"
}
const _hoisted_2 = {
  key: 1,
  class: "to-do-assign__item__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    key: _ctx.value.id,
    class: _normalizeClass(["avatar-select__item to-do-assign__item", [{ 'is-selected': _ctx.isAssigned }]]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)), ["stop"]))
  }, [
    (_ctx.selectedTeamList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Avatar, {
            src: _ctx.value.picture?.pictureUrl || null,
            type: "teamsGroup"
          }, null, 8, ["src"]),
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            class: "is-ellipsis to-do-assign__item__name",
            color: "monochrome-06"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.value.name), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            src: _ctx.value?.pictureUrl,
            gap: _ctx.value?.getInitials(),
            type: "avatarGroup",
            class: "user__avatar"
          }, null, 8, ["src", "gap"]),
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            class: "is-ellipsis to-do-assign__item__name",
            color: "monochrome-06"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.value?.username), 1)
            ]),
            _: 1
          })
        ])),
    (_ctx.isAssigned)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 2,
          data: _imports_0,
          fill: true,
          color: "#33691E",
          width: "24px",
          height: "24px"
        }))
      : _createCommentVNode("", true)
  ], 2))
}