<template>
  <header
    id="pageHeader"
    class="header"
  >
    <div class="header__left header__item">
      <router-link
        to="/"
        class="logo header__logo"
      >
        <img
          :src="require(`@/assets/images/${isStagingFlag ? 'staging-logo' : 'logo'}${isDark ? '-dark' : ''}.svg`)"
          alt=""
        >
      </router-link>
      <BaseHeading
        level="2"
        className="header__title"
      >
        {{ mainHeaderTitle }}
      </BaseHeading>

      <Breadcrumbs />
    </div>
    <div class="header__right header__item">
      <NotificationList />

      <router-link
        v-if="account?.urlName"
        :to="{
          name: 'myWork',
          params: { accountId: account.urlName },
        }"
        tabindex="-1"
      >
        <span
          class="header__news body-text body-text-large-400"
        >
          <icon
            data="@icon/my-work-checkbox.svg"
            :fill="true"
            width="1.3em"
            height="1.3em"
            color="var(--monochrome-05)"
          />
        </span>
      </router-link>

      <HeaderMenu
        :key="$route.path"
      />
      <HeaderProfileMenu />
    </div>
  </header>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import HeaderProfileMenu from "@/components/Header/HeaderProfileMenu.vue"
import HeaderMenu from "@/components/Header/HeaderMenu.vue"
import NotificationList from "@/components/Notification/NotificationList.vue"
import Breadcrumbs from "./Breadcrumbs.vue"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { User, Account } from "@/models"
import { isStaging } from "@/app.config"
import { useAcl } from "vue-simple-acl"

@Options({
  name: "Header",
  components: {
    HeaderProfileMenu,
    HeaderMenu,
    NotificationList,
    Breadcrumbs,
  },
})
export default class Header extends Vue {
  @Getter(Getters.GET_USER_CURRENT_PROFILE) user: User
  @Getter(Getters.GET_ACCOUNT) readonly account: Account
  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean



  get accountName() {
    return this.account?.name ?? ""
  }

  get mainHeaderTitle() {
    if (this.$route.name === "edit-user" && !this.$route.query.myAccount) {
      if (this.$route.params?.userId === this.user.id) {
        return this.user.fullName
      } else {
        return "User Profile"
      }
    }
    return this.$route.meta?.adminPage && this.isSuperAdmin ? "Admin Panel" : this.accountName
  }

  get isStagingFlag() {
    return isStaging()
  }

  get isSuperAdmin() {
    return useAcl().can("superAdmin")
  }
}
</script>
