import { Filter } from "@/models/Pageable"

export class FilterSprint extends Filter {
  q?: string
  includedStatuses?: string[]
  excludedStatuses?: string[]
  teamId?: string
  allSprintsPage?: boolean
  teamName?: string
  hasRetrospective?: boolean
  projectId?: string

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.includedStatuses = props.includedStatuses ?? []
    this.excludedStatuses = props.excludedStatuses ?? []
    this.teamId = props.teamId ?? ""
    this.allSprintsPage = props.allSprintsPage ?? false
    this.teamName = props.teamName ?? ""
    this.hasRetrospective = props.hasRetrospective ?? undefined
    this.projectId = props.projectId ?? undefined
  }

  getJsonObj() {
    const data = {
      q: this.q ?? "",
      includedStatuses: this.includedStatuses ?? [],
      excludedStatuses: this.excludedStatuses ?? [],
      teamId: this.teamId ?? "",
      hasRetrospective: this.hasRetrospective ?? undefined,
      projectId: this.projectId ?? undefined,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterSprint(props)
  }

  static createDefault() {
    const props = {
      q: "",
      includedStatuses: [],
      excludedStatuses: [],
      teamId: "",
      allSprintsPage: false,
    }

    const filter = new FilterSprint(props)
    return filter
  }
}
