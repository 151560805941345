<template>
  <ToDoContent
    :class="className"
  >
    <template #header>
      <BaseHeading
        level="3"
        color="monochrome-06"
      >
        Add ToDo Item
      </BaseHeading>
    </template>
    <template #content>
      <ToDoSelectUser
        :toDoUsers="toDo.owners"
        :toDoTeams="toDo.selectedTeams"
        :rules="validators.isRequired('Owner')"
        :canSelectTeam="!toDo.isStoryPoker"
        @onSelectedUsers="onSelectedUsers"
        @onSelectedTeams="onSelectedTeams"
      />
      <TodoWidgetDatePicker
        :today="today"
        :tomorrow="tomorrow"
        :date="toDo.estimatedDeadline"
        :rules="validators.isRequired('Date')"
        @onCheckboxTomorrow="onCheckboxTomorrow"
        @onCheckboxToday="onCheckboxToday"
        @updateDate="updateDate"
      />
      <BasicCheckbox
        :modelValue="toDo.urgent"
        class="to-do__mark-as-urgent"
        label="Mark as Urgent"
        value="urgent"
        :bordered="false"
        :lineThrough="false"
        checkType="check"
        @update:modelValue="updateUrgent"
      />
      <Field
        v-model="toDo.name"
        as="div"
        :validateOnBlur="false"
        name="toDoDescription"
        class="to-do__description__wrapper"
        :rules="validators.isRequired('Description')"
      >
        <Editor
          id="toDoDescription"
          :value="toDo.structuredDescription.html"
          :extensions="extensions"
          :triggerHandleInputOnMount="false"
          :taskEditor="true"
          class="to-do__description"
          @handleInput="handleInputEditor"
          @input.stop
        />
        <ErrorMessage
          class="error-text error-text-small"
          name="toDoDescription"
        />
      </Field>
    </template>
    <template #footer="{ validate, handleReset }">
      <ControlButtons
        :small="true"
        @save="onValidateAndSave(validate, handleReset)"
        @cancel="onCancel"
      />
    </template>
  </ToDoContent>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ToDoContent from "@/components/ToDo/ToDoContent.vue"
import ToDoSelectUser from "@/components/ToDo/ToDoSelectUser.vue"
import TodoWidgetDatePicker from "@/components/ToDo/TodoWidgetDatePicker.vue"
import ToDoCreateAndUpdate from "@/components/ToDo/ToDoCreateAndUpdate"
import validators from "@/utils/validators"
import BasicCheckbox from "@/components/Form/BasicCheckbox.vue"
import { ReferenceAt } from "@/plugins/extensions/reference/referenceAt"
import {
  ReferenceHash,
  ReferenceHashFile,
} from "@/plugins/extensions/reference/referenceHash"
import { ReferenceLink } from "@/plugins/extensions/reference/referenceLink"
import Editor from "@/components/Editor/index.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
import { Field, ErrorMessage } from "vee-validate"
import { Prop } from "vue-property-decorator"

const components = {
  ToDoContent,
  ToDoSelectUser,
  TodoWidgetDatePicker,
  BasicCheckbox,
  Editor,
  ControlButtons,
  Field,
  ErrorMessage,
}

@Options({
  name: "ToDoAdd",
  components,
})
export default class ToDoAdd extends mixins(ToDoCreateAndUpdate) {
  @Prop({ default: "" }) className: string

  validators = validators
  addRules = false

  get extensions() {
    return [ReferenceAt, ReferenceHash, ReferenceLink, ReferenceHashFile]
  }

  onCheckValid(validate, reset) {
    this.addRules = true
    this.$nextTick(() => {
      this.onValidateAndSave(validate, reset)
    })
  }

  async onValidateAndSave(validate, reset) {
    const { valid } = await validate()
    if (!valid) {
      return
    }
    this.addRules = false
    this.onSave()
    reset()
  }
}
</script>
