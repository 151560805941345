import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "./account.module"

import { Goal, GoalDTO, GoalStatus } from "@/models/Goal"
import { Feature, FeatureDTO } from "@/models/Feature"
import { ServerResponse } from "@/models/ServerResponse"
import { UpdateObjectValue } from "@/models/common"
import { FilterGoal, ServerPriorityResponse } from "@/models"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { parsingServerResponseContent } from "@/utils/helpers"

enum GoalStatusMutation {
  "CURRENT" = "currentGoals",
  "NEXT_IN_LINE" = "futureGoals",
  "CLOSE" = "closeGoals",
}

export enum GoalActions {
  GET_GOALS = "GET_GOALS",
  GET_RESPONSE_GOAL = "GET_RESPONSE_GOAL",
  GET_CURRENT_GOAL_RESPONSE = "GET_CURRENT_GOAL_RESPONSE",
  GET_CURRENT_GOAL = "GET_CURRENT_GOAL",
  SET_FEATURE_BY_GOAL = "SET_FEATURE_BY_GOAL",
  GET_FUTURE_GOAL = "GET_FUTURE_GOAL",
  GET_GOAL = "GET_GOAL",
  SET_GOAL = "SET_GOAL",
  GET_GOALS_SEARCH = "GET_GOALS_SEARCH",
  CREATE_GOAL = "CREATE_GOAL",
  UPDATE_GOAL = "UPDATE_GOAL",
  GET_FEATURE_BY_GOAL = "GET_FEATURE_BY_GOAL",
  UPDATE_GOAL_VALUE = "UPDATE_GOAL_VALUE",
  RESET_GOAL = "RESET_GOAL",
  UPDATE_GOALS_DRAGGABLE_LIST = "UPDATE_GOALS_DRAGGABLE_LIST",
  UPDATE_FEATURE_DRAGGABLE_LIST_IN_GOAL = "UPDATE_FEATURE_DRAGGABLE_LIST_IN_GOAL",
  UPDATE_GOAL_STATUS = "UPDATE_GOAL_STATUS",
  CHANGE_GOAL_PRIORITY = "CHANGE_GOAL_PRIORITY",
  UPDATE_FEATURE_IN_GOAL = "UPDATE_FEATURE_IN_GOAL",
  UPDATE_GOAL_FEATURE_VALUE = "UPDATE_GOAL_FEATURE_VALUE",
  UPDATE_GOAL_IN_LIST = "UPDATE_GOAL_IN_LIST",
  ADD_SPECIFIC_FEATURE = "ADD_SPECIFIC_FEATURE",
  DELETE_FEATURE_BY_GOAL = "DELETE_FEATURE_BY_GOAL",
}

@Module
class GoalModule extends BaseModule {
  goalResponse: ServerResponse<Goal> | null
  goalContent: Goal[] = []

  currentGoals: Goal[] = []
  currentGoalsResponse: ServerResponse<Goal>
  futureGoals: Goal[] = []
  futureGoalsResponse: ServerResponse<Goal>
  closeGoals: Goal[] = []
  closeGoalsResponse: ServerResponse<Goal>


  goal: Goal = Goal.create()
  featureInGoal: { [key: string]: Feature[] } = {}

  get accountId() {
    return accountModule.getCurrentAccountId()
  }

  get [`get/${GoalActions.GET_FEATURE_BY_GOAL}`]() {
    return (goalId: string) => this.featureInGoal[goalId] ?? []
  }

  get [`get/${GoalActions.GET_RESPONSE_GOAL}`]() {
    return this.goalResponse
  }

  get [`get/${GoalActions.GET_CURRENT_GOAL_RESPONSE}`]() {
    return this.currentGoalsResponse
  }

  get [`get/${GoalActions.GET_CURRENT_GOAL}`]() {
    return this.currentGoalsResponse?.content?.[0]
  }

  get [`get/${GoalActions.GET_FUTURE_GOAL}`]() {
    return this.futureGoalsResponse
  }

  get [`get/${GoalActions.GET_GOAL}`]() {
    return this.goal
  }

  get [`get/${GoalActions.GET_GOALS}`]() {
    return this.goalContent
  }

  @Mutation
  setGoalFeatures({ goalId, features }: { goalId: string, features: Feature[] }) {
    this.featureInGoal[goalId] = features
  }

  @Mutation
  updateFeatureInGoal({ goalId, feature }: { goalId: string, feature: Feature }) {
    this.featureInGoal[goalId] = this.featureInGoal[goalId].map(f => (f.id !== feature.id ? f : feature))
  }

  @Mutation
  deleteFeatureFromGoal({ goalId, feature }: { goalId: string, feature: Feature }) {
    this.featureInGoal[goalId] = this.featureInGoal[goalId].filter(f => f.id !== feature.id)
  }

  @Mutation
  setGoalsResponse(serverResponse: ServerResponse<Goal> | null) {
    this.goalResponse = serverResponse
  }

  @Mutation
  addGoals(content: Goal[]) {
    this.goalContent = [...this.goalContent, ...content]
  }

  @Mutation
  setGoals(content: Goal[]) {
    this.goalContent = content
  }

  @Mutation
  setGoalsResponseByStatus({ response, status }) {
    this[status + "Response"] = response
  }

  @Mutation
  setGoalsByStatus({
    serverResponse,
    status,
  }:{serverResponse: ServerResponse<Goal>, status: string}) {
    const updatedContent = parsingServerResponseContent({
      serverResponseContent: serverResponse.content,
      numberOfPage: serverResponse.number,
      originalContent: this[status],
      originalNumberOfPage: this[status + "Response"]?.number,
    })
    this[status] = updatedContent

    this[status + "Response"] = {
      ...serverResponse,
      content: this[status],
    }
  }
  @Mutation
  addGoalsByStatus({ content, status }) {
    this[status] = [...this[status], ...content]
  }

  @Mutation
  setGoalsByType({ content, status }) {
    Object.assign(this[status + "Response"], {
      content,
      totalElements: content.length,
    })
  }

  @Mutation
  setGoalValue(payload: UpdateObjectValue<Goal>) {
    Object.assign(this.goal, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  updateFeatureValue({ payload, featureId, goalId }: { payload: UpdateObjectValue<Feature>, featureId: string, goalId: string }) {
    // TODO - Replace with update method
    this.featureInGoal[goalId] = this.featureInGoal[goalId].map((el) => {
      if (el.id === featureId) el[payload?.["id"]] = payload?.["value"]
      return el
    })
  }

  @Mutation
  setGoal(goal: Goal) {
    this.goal = goal
  }

  @Mutation
  resetGoal() {
    this.goal = Goal.create()
  }

  @Action
  async [GoalActions.GET_GOALS](filterGoal: FilterGoal) {
    const filter = FilterGoal.create(filterGoal).getJsonObj()

    this.setGoalsResponse(null)

    const data = await apiService.get<ServerResponse<GoalDTO>>(Endpoint.GET_GOAL(this.accountId), filter)
    return this.handleResponse<ServerResponse<GoalDTO>>(data, (data) => {
      const content: Goal[] = data.content.map(goal => Goal.create(goal))
      this.setGoalsResponse(Object.assign(data, { content }))

      if (filterGoal.page === 1) {
        this.setGoals(content)
      } else {
        this.addGoals(content)
      }
    })
  }

  @Action
  async [GoalActions.GET_GOALS_SEARCH](filterGoal: FilterGoal) {


    const filter = filterGoal.getJsonObj()

    const data = await apiService.get<ServerResponse<GoalDTO>>(Endpoint.SEARCH_GOAL(this.accountId), filter)
    return this.handleResponse<ServerResponse<GoalDTO>>(data, (data) => {
      const content: Goal[] = data.content.map(goal => Goal.create(goal))
      const serverResponse = {
        ...data,
        content,
      } as ServerResponse<Goal>
      if (!filterGoal.statuses?.length) {
        this.setGoalsResponse(Object.assign(data, { content }))
        filterGoal.page === 1 ? this.setGoals(content) : this.addGoals(content)
      } else {
        const status = GoalStatusMutation[filterGoal.statuses.join()]
          this.setGoalsByStatus({
            status,
          serverResponse,
        })
      }
    })
  }

  @Action
  async [GoalActions.CREATE_GOAL](goal: Goal) {
    const data = await apiService.post<GoalDTO>(Endpoint.POST_GOAL(goal.projectId), goal.getJsonObj())

    return this.handleResponse<GoalDTO>(data, (data) => {
      const goal = Goal.create(data)
      this.setGoal(goal)
      const alertContent: AlertContentItem[] = [{
        text: "Goal successfully created",
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return goal
    })
  }

  @Action
  async [GoalActions.UPDATE_GOAL](goal: Goal) {
    const data = await apiService.put<GoalDTO>(Endpoint.UPDATE_GOAL(goal.projectId, goal.id), goal.getJsonObj())
    return this.handleResponse<GoalDTO>(data, (data) => {
      const goal = Goal.create(data)
      this.setGoal(goal)
      if (goal.status !== "CLOSE") {
        const alertContent: AlertContentItem[] = [{
          text: "Goal updated",
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertContent,
        })
      }
      return goal
    })
  }

  @Action
  async [GoalActions.UPDATE_GOAL_STATUS]({ goal, status }: { goal: Goal, status: GoalStatus }) {
    const data = await this[GoalActions.UPDATE_GOAL](Object.assign(goal, {
      status,
    }))
    if (data && status === "CLOSE") {
      const alertContent: AlertContentItem[] = [{
        text: "Goal marked as Completed",
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    }
  }

  @Action
  async [GoalActions.UPDATE_FEATURE_IN_GOAL](payload: { goalId: string, feature: Feature }) {
    this.updateFeatureInGoal(payload)
  }

  @Action
  async [GoalActions.UPDATE_GOAL_IN_LIST]({ content, status }: { content: Goal[], status: GoalStatus }) {
    this.setGoalsByType({
      status: GoalStatusMutation[status],
      content,
    })
  }

  @Action
  async [GoalActions.GET_FEATURE_BY_GOAL]({ filter, goal }: { filter, goal: Goal }) {
    const { projectId, id } = goal
    const data = await apiService.get<ServerResponse<FeatureDTO>>(Endpoint.FEATURE_GOAL(projectId, id), filter.getJsonObj())
    return this.handleResponse<ServerResponse<FeatureDTO>>(data, (data) => {
      const feature = data.content.map(feature => Feature.create(feature))
      this.setGoalFeatures({
        goalId: id,
        features: feature,
      })
    })
  }

  @Action
  async [GoalActions.SET_FEATURE_BY_GOAL]({ goal, features, alertMessage }: { goal: Goal, features: Feature[], alertMessage?: AlertContentItem[] }) {
    const { id, projectId } = goal
    const data = await apiService.put<GoalDTO>(Endpoint.FEATURE_GOAL(projectId, id), features.length ? features.map(item => item.id) : [])
    return this.handleResponse<GoalDTO>(data, (data) => {
      const newGoal = Goal.create(data)

      if (alertMessage) {
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertMessage,
        })
      }

      return newGoal
    })
  }

  @Action
  async [GoalActions.CHANGE_GOAL_PRIORITY]({ element, nextElement, previousElement }: { element: Feature, nextElement: Feature, previousElement: Feature }) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.CHANGE_GOAL_PRIORITY(element.projectId), {
      previousItemId: previousElement?.id || null,
      nextItemId: nextElement?.id || null,
      itemId: element.id,
    })
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) return "reload"
    })
  }

  @Action
  async [GoalActions.ADD_SPECIFIC_FEATURE]({ goal, feature, alertMessage }: { goal: Goal, feature: Feature, alertMessage?: AlertContentItem[] }) {
    const data = await apiService.post<GoalDTO>(Endpoint.ADD_SPECIFIC_FEATURE(goal.projectId, goal.id, feature.id))
    return this.handleResponse<GoalDTO>(data, (data) => {
      if (alertMessage) {
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertMessage,
        })
      }
      return Goal.create(data)
    })
  }

  @Action
  [GoalActions.UPDATE_GOAL_VALUE](payload: UpdateObjectValue<Goal>) {
    this.setGoalValue(payload)
  }

  @Action
  [GoalActions.RESET_GOAL]() {
    this.resetGoal()
  }

  @Action
  [GoalActions.SET_GOAL](goal: Goal) {
    this.setGoal(goal)
  }

  @Action
  async [GoalActions.UPDATE_FEATURE_DRAGGABLE_LIST_IN_GOAL]({ draggableList, goalId }: { draggableList: Feature[], goalId: string }) {
    this.setGoalFeatures({
      goalId,
      features: draggableList,
    })
  }

  @Action
  async [GoalActions.DELETE_FEATURE_BY_GOAL]({ feature, goalId }: { feature: Feature, goalId: string }) {
    this.deleteFeatureFromGoal({
      goalId,
      feature,
    })
  }

  @Action
  async [GoalActions.UPDATE_GOALS_DRAGGABLE_LIST]({ draggableList, status }: { draggableList: Feature[], status: GoalStatus }) {
    this.setGoalsByType({
      status: GoalStatusMutation[status],
      content: draggableList,
    })
  }

  @Action
  async [GoalActions.UPDATE_GOAL_FEATURE_VALUE]({ payload, featureId, goalId }: { payload: UpdateObjectValue<Feature>, featureId: string, goalId: string }) {
    this.updateFeatureValue({ payload, featureId, goalId })
  }
}

export const goalModule = new GoalModule({
  store,
  name: Modules.GOAL,
})
