<template>
  <div
    v-if="visibleGlobalAlerts.length > 0"
    class="alert__wrapper"
  >
    <GlobalAlert
      v-for="(alert, index) in globalAlerts"
      :key="index"
      :alert="alert"
      @closeAlert="closeAlert"
    />
  </div>
  <div
    v-if="visibleToastAlerts.length > 0"
    class="toasts__wrapper"
  >
    <ToastAlert
      v-for="(alert, index) in toastAlerts"
      :key="index"
      :alert="alert"
      @closeAlert="closeAlert"
    />
  </div>
</template>

<script lang="ts">
import { Alert } from "@/models"
import { Getters, Actions } from "@/store"
import { Options, Vue } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { Getter, Action } from "s-vuex-class"
import ToastAlert from "../Alert/ToastAlert.vue"
import GlobalAlert from "../Alert/GlobalAlert.vue"

@Options({
  name: "Alert",
  components: {
    GlobalAlert,
    ToastAlert,
  },
})
export default class extends Vue {
  @Getter(Getters.GET_ALERTS) alerts: Alert[]
  @Action(Actions.CLEAR_REMOVED_ALERTS) clearAlerts: () => void


  @Watch("alerts")
  onStateChanged(newChanges: Alert[], alerts: Alert[]): void {
    if (alerts.length < newChanges.length) {
      this.runTimeout()
    }
    if (newChanges.length > 3) {
      newChanges.splice(0, 1)
    }
  }

  get globalAlerts() {
    return this.alerts.filter(alert => alert.theme === "global")
  }

  get visibleGlobalAlerts() {
    return this.globalAlerts.filter(alert => alert.timeout && alert.timeout > 0)
  }

  get toastAlerts() {
    return this.alerts.filter(alert => alert.theme === "toast")
  }

  get visibleToastAlerts() {
    return this.toastAlerts.filter(alert => alert.timeout && alert.timeout > 0)
  }

  created() {
    setInterval(this.clearAlerts, 60000)
  }

  private runTimeout() {
    for (let i = 0; i < this.alerts.length; i++) {
      const alert = this.alerts[i]
      if (alert && !alert.timeoutStarted && alert.timeout && alert.timeout > 0) {
        alert.timeoutStarted = true
        alert.setupTimeout()
      }
    }
  }
  closeAlert() {
    for (let i = 0; i < this.alerts.length; i++) {
      const alert = this.alerts[i]
      alert.timeout = -1
    }
  }
}
</script>
