<template>
  <file-upload
    ref="upload"
    :name="id"
    class="custom-editor__button"
    :multiple="true"
    :drop="true"
    :dropDirectory="true"
    :tabindex="-1"
    @input="onInput"
  >
    <button
      type="button"
      class="body-text body-text-medium-400"
      :tabindex="-1"
    >
      <icon
        :data="icon"
        :fill="true"
        color="#363945"
        width="1.25em"
        height="1.25em"
      />
    </button>
  </file-upload>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Icon } from "@yzfe/vue3-svgicon"
import { CallbackFunction } from "@/models"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "EditorMenuItem",
})
export default class EditorMenuItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly icon: Icon
  @Prop({
    type: String,
    required: true,
  }) readonly title: string
  @Prop({
    type: Function,
    required: true,
  }) action: (files: File[]) => void
  @Prop({
    type: Function,
    default: null,
  }) isActive: CallbackFunction
  @Prop({
    default: "",
  }) id: string

  mounted() {
    const upload = this.$refs.upload as any
    const input = upload?.$refs.input

    input?.setAttribute("tabindex", -1)
  }

  onInput(e) {
    this.action(e.target.files)
  }
}
</script>
