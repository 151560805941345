import { VuexModule, Module } from "vuex-class-modules"
import { store } from "../../store"
import { Modules } from "../../modules"
import { Status } from "@/models"

export enum ItemStatusActions {
  GET_STATUSES = "GET_STATUSES",
  GET_STATUSES_FOR_FILTER = "GET_STATUSES_FOR_FILTER",
  GET_STATUSES_FOR_PRODUCT_BACKLOG_FILTER = "GET_STATUSES_FOR_PRODUCT_BACKLOG_FILTER",
  GET_INITIAL_STATUSES_FOR_PRODUCT_BACKLOG_FILTER = "GET_INITIAL_STATUSES_FOR_PRODUCT_BACKLOG_FILTER",
  GET_STATUS_BY_ID = "GET_STATUS_BY_ID",
  GET_STATUS_BY_IDS = "GET_STATUS_BY_IDS",
}

@Module
class ItemStatusModule extends VuexModule {
  statuses = [
    {
      id: "NOT_STARTED",
      label: "Not Started",
      statusPriority: 15,
    },
    {
      id: "IN_UI_UX",
      label: "In UI/UX",
      statusPriority: 14,
    },
    {
      id: "UI_UX_DONE",
      label: "UI/UX Done",
      statusPriority: 13,
    },
    {
      id: "IN_DESIGN",
      label: "In Design",
      statusPriority: 12,
    },
    {
      id: "DESIGN_COMPLETED",
      label: "Design Done",
      statusPriority: 11,
    },
    {
      id: "IN_DEVELOPMENT",
      label: "In Development",
      statusPriority: 10,
    },
    {
      id: "DEVELOPMENT_DONE",
      label: "Development Done",
      statusPriority: 9,
    },
    {
      id: "IN_QA",
      label: "In QA",
      statusPriority: 8,
    },
    {
      id: "TESTING_COMPLETE",
      label: "QA Done",
      statusPriority: 7,
    },
    {
      id: "SCHEDULED_FOR_DEPLOYMENT",
      label: "Scheduled for Deployment",
      statusPriority: 6,
    },
    {
      id: "DEPLOYED_TO_STAGING",
      label: "Deployed to Staging",
      statusPriority: 5,
    },
    {
      id: "DEPLOYED_TO_PRODUCTION",
      label: "Deployed to Production",
      statusPriority: 4,
    },
    {
      id: "DONE",
      label: "Done",
      statusPriority: 3,
    },
    {
      id: "IN_PROGRESS",
      label: "In Progress",
      statusPriority: 19,
    },
    {
      id: "BLOCKED",
      label: "Blocked",
      statusPriority: 2,
    },
    {
      id: "OBSOLETE",
      label: "Obsolete",
      statusPriority: 1,
    },
    {
      id: "UNBLOCKED",
      label: "Unblocked",
      statusPriority: 16,
    },
    {
      id: "RESTORE",
      label: "Restore",
      statusPriority: 17,
    },
    {
      id: "RE_OPEN",
      label: "Re-open",
      statusPriority: 18,
    },
  ] as Status[]

  get [`get/${ItemStatusActions.GET_STATUSES}`]() {
    return this.statuses.filter(a => a.id !== "UNBLOCKED" && a.id !== "RESTORE")
  }

  get [`get/${ItemStatusActions.GET_STATUSES_FOR_FILTER}`]() {
    return [
      {
        id: "NOT_STARTED",
        label: "Not Started",
        statusPriority: 1,
      },
      {
        id: "BLOCKED",
        label: "Blocked",
        statusPriority: 3,
      },
      {
        id: "OBSOLETE",
        label: "Obsolete",
        statusPriority: 4,
      },
      {
        id: "RE_OPEN",
        label: "Re-Open",
        statusPriority: 5,
      },
      {
        id: "DONE",
        label: "Done",
        statusPriority: 6,
      },
    ]
  }

  get [`get/${ItemStatusActions.GET_STATUSES_FOR_PRODUCT_BACKLOG_FILTER}`]() {
    return [
      {
        id: "ALL",
        label: "All",
        statusPriority: 0,
      },
      {
        id: "NOT_STARTED",
        label: "Not Started",
        statusPriority: 1,
      },
      {
        id: "BLOCKED",
        label: "Blocked",
        statusPriority: 3,
      },
      {
        id: "OBSOLETE",
        label: "Obsolete",
        statusPriority: 4,
      },
      {
        id: "RE_OPEN",
        label: "Re-Open",
        statusPriority: 5,
      },
      {
        id: "DONE",
        label: "Done",
        statusPriority: 6,
      },
    ]
  }

  get [`get/${ItemStatusActions.GET_INITIAL_STATUSES_FOR_PRODUCT_BACKLOG_FILTER}`]() {
    return [
      {
        id: "NOT_STARTED",
        label: "Not Started",
        statusPriority: 1,
      },
      {
        id: "BLOCKED",
        label: "Blocked",
        statusPriority: 3,
      },
      {
        id: "RE_OPEN",
        label: "Re-Open",
        statusPriority: 5,
      },
    ]
  }

  get [`get/${ItemStatusActions.GET_STATUS_BY_ID}`]() {
    return (id: string) => {
      const item = this.statuses.find((status: Status) => status.id === id)
      return item ? item : { id: "", label: "" }
    }
  }

  get [`get/${ItemStatusActions.GET_STATUS_BY_IDS}`]() {
    return (ids: string[]) => this.statuses.filter(status => ids.includes(status.id))
  }
}

export const itemStatusModule = new ItemStatusModule({
  store,
  name: Modules.ITEM_STATUS,
})
