<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__update-item"
  >
    <template #pic>
      <div class="label-pic is-purple">
        <icon
          data="@icon/task-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
          color="#6A1B9A"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}
        </a>
        Task List has been updated
      </BaseSubHeading>
    </template>
    <template #content>
      <div class="notification-task__content">
        <BaseSubHeading
          size="medium"
          color="monochrome-07"
          weight="400"
          class="notification-task__content__left"
        >
          <span
            class="notification-task__status"
            :class="[`is_${notification.taskStatus.id.toLowerCase()}`]"
          >
            {{ notification.taskStatus.label }}
          </span>

          <span class="notification-task__name">
            {{ notification.taskName }}
          </span>
        </BaseSubHeading>

        <BaseSubHeading
          size="medium"
          color="monochrome-06"
          weight="500"
          class="notification-task__content__right"
        >
          <span class="notification-task__type">
            {{ $t(`task.types.${notification.taskType.toLowerCase()}`) }}
          </span>
          <span class="notification-task__estimation is-estimation">
            <span class="is-opacity notification-task__estimation__old">{{ notification.estimationOld }}</span>
            <span v-if="notification.estimationOld">
              >
            </span>
            {{ notification.estimation }} hr
          </span>
        </BaseSubHeading>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationTask } from "@/models/Notification/NotificationTask"
@Options({
  name: "UPDATE_TASK",
  components: {
    NotificationTemplate,
  },
})
export default class UPDATE_TASK extends Vue {
  @Prop() readonly notification: NotificationTask
}
</script>
