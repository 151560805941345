import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/calendar.svg'


const _hoisted_1 = { class: "to-do__datepicker" }
const _hoisted_2 = { class: "to-do__datepicker-labels" }
const _hoisted_3 = { class: "to-do__datepicker-pressets" }
const _hoisted_4 = { class: "to-do__datepicker-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_BasicCheckbox = _resolveComponent("BasicCheckbox")!
  const _component_icon = _resolveComponent("icon")!
  const _component_BorderedInput = _resolveComponent("BorderedInput")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        weight: "400",
        color: "monochrome-05",
        class: "to-do__datepicker-title",
        darkColor: "monochrome-03"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Due ")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BasicCheckbox, {
          modelValue: _ctx.today,
          label: "Today",
          value: "todayDate",
          bordered: true,
          checkType: "filter",
          "onUpdate:modelValue": _ctx.onCheckboxToday
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_BasicCheckbox, {
          modelValue: _ctx.tomorrow,
          label: "Tomorrow",
          value: "tomorrowDate",
          bordered: true,
          checkType: "filter",
          "onUpdate:modelValue": _ctx.onCheckboxTomorrow
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_date_picker, {
        modelValue: _ctx.date,
        mode: "date",
        color: "blue",
        locale: "en",
        minDate: new Date(),
        popover: _ctx.popoverConfig,
        class: "input_to-do",
        "onUpdate:modelValue": _ctx.updateDate,
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => ($event.stopPropagation()))
      }, {
        default: _withCtx(({ inputEvents, togglePopover }) => [
          _createVNode(_component_BorderedInput, {
            id: "selectToDoDate",
            placeholder: "MM/DD/YYYY",
            rules: _ctx.rules,
            modelValue: _ctx.parseDate,
            inputEvents: inputEvents,
            validateOnBlur: false,
            validateOnModelUpdate: true,
            validateOnChange: false,
            className: "input_to-do",
            label: "Select Another Day:",
            maska: "##/##/####",
            validationTag: "div",
            icon: "",
            onClick: togglePopover
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_icon, {
                data: _imports_0,
                fill: false,
                color: "#7D92A7",
                width: "16",
                height: "16"
              })
            ]),
            _: 2
          }, 1032, ["rules", "modelValue", "inputEvents", "onClick"])
        ]),
        _: 1
      }, 8, ["modelValue", "minDate", "popover", "onUpdate:modelValue"])
    ])
  ]))
}