import { Module, Mutation, Action } from "vuex-class-modules"

import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { ProjectActions, projectModule } from "./project.module"
import {
  EpicMetrics,
  BacklogMetrics,
  BugMetrics,
  SeverityMetrics,
  ItemType,
  TeamBacklogsMetrics,
  BacklogMetricsById,
  MetricsByType,
  MyWorkStats,
} from "@/models"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { TeamActions, teamModule } from "@/store/modules/team.module"
import { SessionActions, sessionModule } from "./session.module"
import { FilterBacklogMetrics } from "@/models/Filter/FilterBacklogMetrics"
import { accountModule } from "./account.module"

export enum BacklogMetricsActions {
  METRICS_EPIC = "METRICS_EPIC",
  METRICS_BACKLOG = "METRICS_BACKLOG",
  METRICS_BUG = "METRICS_BUG",
  METRICS_SUPPORT_CASE = "METRICS_SUPPORT_CASE",
  UNPRIORITIZED_METRICS = "UNPRIORITIZED_METRICS",
  METRICS_BACKLOG_BY_TYPE = "METRICS_BACKLOG_BY_TYPE",
  METRICS_TEAM_BACKLOGS = "METRICS_TEAM_BACKLOGS",
  ITEMS_METRICS = "ITEMS_METRICS",
  BACKLOG_METRICS_BY_TYPE = "BACKLOG_METRICS_BY_TYPE",
  SPRINT_METRICS_BY_TYPE = "SPRINT_METRICS_BY_TYPE",
  METRICS_BUG_SEVERITY = "METRICS_BUG_SEVERITY",
  MY_WORK_STATS = "MY_WORK_STATS"
}

@Module
class BacklogMetricsModule extends BaseModule {
  backlogMetricsById: BacklogMetricsById = {
    backlogProduct: BacklogMetrics.create(),
    backlogUnprioritized: BacklogMetrics.create(),
    backlogSupportCases: BacklogMetrics.create(),
    backlogTechDebt: BacklogMetrics.create(),
    backlogDevOps: BacklogMetrics.create(),
    backlogEpic: BacklogMetrics.create(),
    backlogBug: BacklogMetrics.create(),
  }
  teamBacklogsMetrics: TeamBacklogsMetrics
  epicMetrics: EpicMetrics
  backlogMetrics: Partial<{
    [key in ItemType]: BacklogMetrics
  }> = {
      "STORY": BacklogMetrics.create(),
      "TASK": BacklogMetrics.create(),
      "SPIKE": BacklogMetrics.create(),
      "SUPPORT_CASE": BacklogMetrics.create(),
      "DEV_OPS": BacklogMetrics.create(),
      "TECH_DEBT": BacklogMetrics.create(),
    }
  bugMetrics: BugMetrics = new BugMetrics()
  supportCaseMetrics: SeverityMetrics = {
    distributionBySeverity: {},
    totalCount: 0,
  }
  bugSeverityMetrics: SeverityMetrics = {
    distributionBySeverity: {},
    totalCount: 0,
  }

  unprioritizedMetrics: MetricsByType
  backlogMetricsByType: MetricsByType
  sprintBacklogMetricsByType: MetricsByType
  myWorkStats: MyWorkStats = new MyWorkStats()

  get projectId() {
    return projectModule.currentProjectId
  }

  get teamId() {
    return teamModule.currentTeamId
  }

  get sessionTeamId() {
    return sessionModule.teamId
  }

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${BacklogMetricsActions.MY_WORK_STATS}`]() {
    return this.myWorkStats
  }

  get [`get/${BacklogMetricsActions.METRICS_EPIC}`]() {
    return this.epicMetrics
  }

  get [`get/${BacklogMetricsActions.METRICS_BACKLOG}`]() {
    return this.backlogMetrics
  }

  get [`get/${BacklogMetricsActions.ITEMS_METRICS}`]() {
    return (backlogId: string) => this.backlogMetricsById[backlogId]
  }

  get [`get/${BacklogMetricsActions.METRICS_BACKLOG_BY_TYPE}`]() {
    return (types: ItemType[]) => {
      const totalMetrics = BacklogMetrics.create()
      if (!types || types.length == 0) return totalMetrics

      types.forEach((type) => {
        const typeMetrics = this.backlogMetrics[type]
        totalMetrics.sumWith(typeMetrics)
      })

      return totalMetrics
    }
  }

  get [`get/${BacklogMetricsActions.METRICS_BUG}`]() {
    return this.bugMetrics
  }

  get [`get/${BacklogMetricsActions.METRICS_BUG_SEVERITY}`]() {
    return this.bugSeverityMetrics
  }

  get [`get/${BacklogMetricsActions.METRICS_SUPPORT_CASE}`]() {
    return this.supportCaseMetrics
  }

  get [`get/${BacklogMetricsActions.UNPRIORITIZED_METRICS}`]() {
    return this.unprioritizedMetrics
  }

  get [`get/${BacklogMetricsActions.BACKLOG_METRICS_BY_TYPE}`]() {
    return this.backlogMetricsByType
  }

  get [`get/${BacklogMetricsActions.SPRINT_METRICS_BY_TYPE}`]() {
    return this.sprintBacklogMetricsByType
  }

  get [`get/${BacklogMetricsActions.METRICS_TEAM_BACKLOGS}`]() {
    return this.teamBacklogsMetrics
  }

  @Mutation
  setEpicMetrics(payload: EpicMetrics) {
    this.epicMetrics = payload
  }

  @Mutation
  setBugSeverity(payload: SeverityMetrics) {
    this.bugSeverityMetrics = payload
  }

  @Mutation
  setMetricsBacklog(payload: Partial<{
    [key in ItemType]: BacklogMetrics
  }>) {
    this.backlogMetrics = Object.assign(this.backlogMetrics ? this.backlogMetrics : {}, payload)
  }

  @Mutation
  setItemsMetrics({ data, backlogKey } : {backlogKey: string, data: BacklogMetrics}) {
    Object.assign(this.backlogMetricsById, { [backlogKey]: data })
  }

  @Mutation
  setMetricsBug(payload: BugMetrics) {
    this.bugMetrics = new BugMetrics(payload)
  }

  @Mutation
  setMetricsSupportCase(payload: SeverityMetrics) {
    this.supportCaseMetrics = payload
  }

  @Mutation
  setMetricsTeamBacklogs(payload: TeamBacklogsMetrics) {
    this.teamBacklogsMetrics = payload
  }

  @Mutation
  setUnprioritizedMetrics(payload) {
    this.unprioritizedMetrics = payload
  }

  @Mutation
  setBacklogMetrics(payload) {
    this.backlogMetricsByType = payload
  }

  @Mutation
  setSprintMetrics(payload) {
    this.sprintBacklogMetricsByType = payload
  }

  @Mutation
  setMyWorkStats(payload: MyWorkStats) {
    this.myWorkStats = payload
  }

  @Action
  async [BacklogMetricsActions.METRICS_EPIC]({ params } : { params: FilterBacklogMetrics }) {
    const data = await apiService.get<EpicMetrics>(Endpoint.METRICS_EPIC(this.projectId), params.getJsonObj())
    return this.handleResponse<EpicMetrics>(data, (data) => {
      this.setEpicMetrics(data)
      return data
    })
  }

  @Action
  async [BacklogMetricsActions.METRICS_BACKLOG](itemType: ItemType) {
    const params = new URLSearchParams()
    params.append("types", itemType)
    params.append("teamId", this.teamId ?? "")

    const data = await apiService.get<BacklogMetrics>(Endpoint.METRICS_BACKLOG(this.projectId), params)
    return this.handleResponse<BacklogMetrics>(data, (data) => {
      this.setMetricsBacklog({ [itemType]: data })
    })
  }

  @Action
  async [BacklogMetricsActions.METRICS_BUG_SEVERITY]({ params } : {params: FilterBacklogMetrics}) {
    const data = await apiService.get<SeverityMetrics>(Endpoint.METRICS_SEVERITY(this.projectId), params.getJsonObj())
    return this.handleResponse<SeverityMetrics>(data, (data) => {
      this.setBugSeverity(data)
    })
  }

  @Action
  async [BacklogMetricsActions.METRICS_BUG]({ params } : {params: FilterBacklogMetrics}) {
    const data = await apiService.get<BugMetrics>(Endpoint.METRICS_BUG(this.projectId), params.getJsonObj())
    return this.handleResponse<BugMetrics>(data, (data) => {
      this.setMetricsBug(data)
      return new BugMetrics(data)
    })
  }

  @Action
  async [BacklogMetricsActions.METRICS_SUPPORT_CASE]({ params } : {params: FilterBacklogMetrics}) {
    const data = await apiService.get<SeverityMetrics>(Endpoint.METRICS_SEVERITY(this.projectId), params.getJsonObj())
    return this.handleResponse<SeverityMetrics>(data, (data) => {
      this.setMetricsSupportCase(data)
    })
  }

  @Action
  async [BacklogMetricsActions.ITEMS_METRICS]({ params, backlogKey } : { params: FilterBacklogMetrics, backlogKey?: string }) {
    const data = await apiService.get<BacklogMetrics>(Endpoint.ITEMS_METRICS(this.projectId), params.getJsonObj())
    return this.handleResponse<BacklogMetrics>(data, (data) => {
      const metric = BacklogMetrics.create(data)
      if (backlogKey) {
        this.setItemsMetrics({ data: metric, backlogKey })
      }
      return metric
    })
  }

  @Action
  async [BacklogMetricsActions.UNPRIORITIZED_METRICS]({ params } : { params: FilterBacklogMetrics }) {
    const data = await apiService.get<MetricsByType>(Endpoint.METRICS_BY_TYPE(this.projectId), params.getJsonObj())
    return this.handleResponse<MetricsByType>(data, (data) => {
      this.setUnprioritizedMetrics(data)
      return data
    })
  }

  @Action
  async [BacklogMetricsActions.BACKLOG_METRICS_BY_TYPE]({ params } : { params: FilterBacklogMetrics }) {
    const data = await apiService.get<MetricsByType>(Endpoint.METRICS_BY_TYPE(this.projectId), params.getJsonObj())
    return this.handleResponse<MetricsByType>(data, (data) => {
      this.setBacklogMetrics(data)
      return data
    })
  }

  @Action
  async [BacklogMetricsActions.SPRINT_METRICS_BY_TYPE]({ params } : { params: FilterBacklogMetrics }) {
    const data = await apiService.get<MetricsByType>(Endpoint.METRICS_BY_TYPE(this.projectId), params.getJsonObj())
    return this.handleResponse<MetricsByType>(data, (data) => {
      this.setSprintMetrics(data)
      return data
    })
  }

  @Action
  async [BacklogMetricsActions.METRICS_TEAM_BACKLOGS]({ params, onlyGet } : { params: FilterBacklogMetrics, onlyGet: boolean }) {
    const data = await apiService.get<TeamBacklogsMetrics>(Endpoint.METRICS_TEAM_BACKLOGS(this.projectId), params.getJsonObj())
    return this.handleResponse<TeamBacklogsMetrics>(data, (data) => {
      if (!onlyGet) {
        this.setMetricsTeamBacklogs(data)
      }
      return data
    })
  }

  @Action
  async [BacklogMetricsActions.MY_WORK_STATS]({ params } : { params: FilterBacklogMetrics }) {
    const data = await apiService.get<MyWorkStats>(Endpoint.MY_WORK_STATS(this.accountId), params.getJsonObj())
    return this.handleResponse<MyWorkStats>(data, (data) => {
      const myWorkStats = new MyWorkStats(data)
      this.setMyWorkStats(myWorkStats)
      return myWorkStats
    })
  }
}

export const backlogMetricsModule = new BacklogMetricsModule({
  store,
  name: Modules.BACKLOG_METRICS,
})
