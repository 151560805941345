<template>
  <div
    :key="notification.id"
    class="notification__item"
  >
    <div class="notification__header">
      <div class="notification__avatar">
        <slot name="pic" />
      </div>
      <div class="notification__title">
        <slot name="title" />
      </div>
      <div
        v-if="!notification.read"
        class="notification__mark"
      />
    </div>
    <div class="notification__container">
      <div
        v-if="showSubTitle && (computedProjectName || computedTeamName)"
        class="notification__sub-title"
      >
        <BaseSubHeading
          v-if="computedTeamName"
          size="medium"
          color="monochrome-05"
          darkColor="monochrome-04"
          weight="500"
        >
          {{ computedTeamName }}
        </BaseSubHeading>
        <span v-else>&nbsp;</span>
        <BaseSubHeading
          v-if="computedProjectName"
          size="medium"
          color="monochrome-05"
          darkColor="monochrome-04"
          weight="500"
        >
          {{ computedProjectName }}
        </BaseSubHeading>
      </div>

      <div class="notification__content">
        <slot name="content" />
      </div>

      <div class="notification__footer">
        <BaseSubHeading
          size="extra-small"
          color="monochrome-05"
          darkColor="monochrome-04"
          className="notification__footer-right"
          weight="400"
        >
          {{ notification.timeAgo }}
        </BaseSubHeading>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification"

@Options({
  name: "NotificationTemplate",
  components: {
  },
})
export default class NotificationTemplate extends Vue {
  @Prop() readonly notification: Notification
  @Prop() readonly projectName: string
  @Prop() readonly teamName: string
  @Prop({ default: true }) readonly showSubTitle: boolean

  get computedProjectName() {
    return this.projectName ? this.projectName : this.notification.content.projectName
  }

  get computedTeamName() {
    return this.teamName ? this.teamName : this.notification.content.teamName
  }
}
</script>
