import { Options, mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import {
  ReferenceConfig,
} from "@/plugins/extensions/Reference"
import ReferenceComponentCommon from "./ReferenceComponentCommon"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
import { Project } from "@/models"

@Options({
  name: "ReferenceComponentListCommon",
})
export default class ReferenceComponentListCommon extends mixins(ReferenceComponentCommon) {
  @Prop() items: { list: any[], type: string } = {
    list: [],
    type: "",
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() command: Function

  @Getter(Getters.GET_PROJECT) readonly project: Project

  componentType: ReferenceType
  selectedIndex = 0
  declare result: any[] | null

  get payloadList() {
    return this.result ?? []
  }

  get type() {
    return ReferenceConfig[this.items?.type].label
  }

  onKeyDown({ event }) {
    if (event.key === "ArrowUp") {
      this.upHandler()
      return true
    }

    if (event.key === "ArrowDown") {
      this.downHandler()
      return true
    }

    if (event.key === "Enter" || event.key === "Tab") {
      event.stopPropagation()
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler() {
    this.selectedIndex = ((this.selectedIndex + this.payloadList.length) - 1) % this.payloadList.length
  }

  downHandler() {
    this.selectedIndex = (this.selectedIndex + 1) % this.payloadList.length
  }

  enterHandler() {
    const payload = { item: this.payloadList[this.selectedIndex], payload: {} }

    if (typeof this["onEnterHandler"] === "function") {
      this["onEnterHandler"](payload)

      return false
    }

    this.selectItem(payload)
  }

  created() {
    //
  }

  waitTitle = "reference.search"
  filter: any = {}
  searchAction = async (_filter) => {
    //
  }

  async search() {
    if (this.project?.demo && typeof this.filter !== "string") {
      this.filter.includeDemo = this.project.demo
    }
    if (typeof this.filter !== "string") {
      this.filter.projectName = this.project.urlName
      this.filter.projectId = this.project.id
    }
    this.$wait.start("referenceSearch")
    await this.searchAction(this.filter)
    this.selectedIndex = 0
    this.$wait.end("referenceSearch")
    window.dispatchEvent(new Event("resize"))
  }

  async selectItem({ item, payload }) {
    if (item) {
      const type = this.componentType
      const config = ReferenceConfig[type]

      if (payload.parent?.sequenceNumber) {
        item.parentSequenceNumber = payload.parent.sequenceNumber
      }

      this.command({
        info: JSON.stringify(item),
        linkText: config.renderTitle ? config.renderTitle(item, payload.parent) : "",
        linkHref: await this.getHrefByType(type, item),
        configType: type,
        type: config.type,
        ...payload,
      })
    }
  }
}
