import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    referrerpolicy: "no-referrer",
    src: _ctx.filterUrl,
    alt: _ctx.alt
  }, null, 8, _hoisted_1))
}