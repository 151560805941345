import { Module, Mutation, Action } from "vuex-class-modules"
import { store } from "../store"
import { Endpoint } from "@/services/endpoints"
import { Modules } from "../modules"
import {
  EmailTemplate,
  EmailTemplateName,
  EmailTemplateRender,
  EmailTemplateSend,
} from "@/models/EmailTemplate"
import { BaseModule } from "@/models/BaseModule"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { $t } from "@/plugins/i18n"
import { apiService } from "@/services/api.service"
import { splitAndCapitalize } from "@/utils/helpers"

export enum EmailTemplateActions {
  GET_EMAIL_TEMPLATES_MARKETING = "GET_EMAIL_TEMPLATES_MARKETING",
  GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES",
  GET_EMAIL_TEMPLATE_TYPE = "GET_EMAIL_TEMPLATE_TYPE",
  EMAIL_RENDER = "EMAIL_RENDER",
  EMAIL_SEND = "EMAIL_SEND",
  PUT_EMAIL_TEMPLATE_TYPE = "PUT_EMAIL_TEMPLATE_TYPE",
  CLEAR_EMAIL_TEMPLATE_TYPE = "CLEAR_EMAIL_TEMPLATE_TYPE",
  CLEAR_EMAIL_RENDER = "CLEAR_EMAIL_RENDER",
  UPDATE_EMAIL_VALUE = "UPDATE_EMAIL_VALUE",
}

@Module
class EmailTemplateModule extends BaseModule {
  marketingTemplates: EmailTemplateName[]
  templates: EmailTemplateName[]
  template: EmailTemplate = {
    html: "",
    type: "",
    subjectTemplate: "",
    model: {},
  }
  templateRender = ""

  get [`get/${EmailTemplateActions.GET_EMAIL_TEMPLATES_MARKETING}`]() {
    return this.marketingTemplates
  }

  get [`get/${EmailTemplateActions.GET_EMAIL_TEMPLATES}`]() {
    return this.templates
  }

  get [`get/${EmailTemplateActions.GET_EMAIL_TEMPLATE_TYPE}`]() {
    return this.template
  }

  get [`get/${EmailTemplateActions.EMAIL_RENDER}`]() {
    return this.templateRender
  }

  @Mutation
  setTemplates({ templates, marketing } : {templates: EmailTemplateName[], marketing: boolean}) {
    if (marketing) {
      this.marketingTemplates = templates
    } else {
      this.templates = templates
    }
  }

  @Mutation
  setTemplate(template: EmailTemplate) {
    this.template = template
  }

  @Mutation
  setTemplateRender(templateRender: string) {
    this.templateRender = templateRender
  }

  @Mutation
  setTemplateValue(payload) {
    Object.assign(this.template, { [payload["id"]]: payload["value"] })
  }

  @Action
  async [EmailTemplateActions.GET_EMAIL_TEMPLATES](marketing: boolean) {
    const data = await apiService.get<EmailTemplateName[]>(Endpoint.GET_EMAIL_TEMPLATES(), {
      marketing,
    })
    return this.handleResponse<EmailTemplateName[]>(data, (data) => {
      this.setTemplates({ templates: data, marketing })
    })
  }

  @Action
  async [EmailTemplateActions.GET_EMAIL_TEMPLATE_TYPE](type: EmailTemplateName) {
    const data = await apiService.get<EmailTemplate>(Endpoint.GET_EMAIL_TEMPLATES_TYPE(type))
    return this.handleResponse<EmailTemplate>(data, (data) => {
      this.setTemplate(data)
    }, true)
  }
  @Action
  async [EmailTemplateActions.CLEAR_EMAIL_TEMPLATE_TYPE]() {
    this.setTemplate({
      html: "",
      type: "",
      subjectTemplate: "",
      model: {},
    })
  }

  @Action
  async [EmailTemplateActions.PUT_EMAIL_TEMPLATE_TYPE]({ type, payload }: { type: EmailTemplateName, payload: { html: string, subjectTemplate: string }}) {
    const data = await apiService.put<EmailTemplate>(Endpoint.GET_EMAIL_TEMPLATES_TYPE(type), payload)
    return this.handleResponse<EmailTemplate>(data, (data) => {
      this.setTemplate(data)
      const alertContent: AlertContentItem[] = [
        {
          text: "Email ",
          type: "regular",
        },
        {
          text: `${splitAndCapitalize(type)} `,
          type: "bold",
        },
        {
          text: $t("alert.updated"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [EmailTemplateActions.EMAIL_RENDER]({ type, payload }: { type: EmailTemplateName, payload: EmailTemplateRender}) {
    const data = await apiService.post<string>(Endpoint.POST_EMAIL_TEMPLATE_RENDER(type), payload)
    return this.handleResponse<string>(data, (data) => {
      this.setTemplateRender(data)
    })
  }

  @Action
  async [EmailTemplateActions.EMAIL_SEND]({ type, payload }: { type: EmailTemplateName, payload: EmailTemplateSend}) {
    const data = await apiService.post<void>(Endpoint.EMAIL_SEND(type), payload)
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Email ",
          type: "regular",
        },
        {
          text: `${splitAndCapitalize(type)} `,
          type: "bold",
        },
        {
          text: $t("alert.send"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  [EmailTemplateActions.CLEAR_EMAIL_RENDER]() {
    this.setTemplateRender("")
  }

  @Action
  [EmailTemplateActions.UPDATE_EMAIL_VALUE](payload) {
    this.setTemplateValue(payload)
  }
}

export const emailTemplateModule = new EmailTemplateModule({
  store,
  name: Modules.EMAIL_TEMPLATE,
})
