import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reference-list__item-in reference-sprint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.result,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    class: "reference-list_md",
    onOnClick: _ctx.onMilestoneSelected
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        weight: "600",
        size: "medium",
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Milestones ")
        ]),
        _: 1
      })
    ]),
    item: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, [
          _createVNode(_component_BaseSubHeading, {
            weight: "500",
            size: "medium",
            color: "monochrome-07"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.title), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_BaseSubHeading, {
            weight: "400",
            size: "medium",
            color: "monochrome-05"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.endDate), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ])
    ]),
    _: 1
  }, 8, ["searchList", "componentType", "selectedIndex", "onOnClick"]))
}