import {
  Status,
  Epic,
  User,
  Type,
  SeverityLevelExtend,
} from "@/models"
import { Sort } from "@/models/Pageable"
interface CreateDate {
  start?: "",
  end?: ""
}

export class FilterBacklogUI {
  text?: string
  sequenceNumberPrefix?: string
  storyStatuses?: Status[]
  epicIds?: Epic[]
  ownerIds?: User[]
  createDate?: CreateDate
  itemTypes?: Type[]
  severityLevel?: SeverityLevelExtend[]
  hasEstimation?: boolean
  sort?: Sort[]

  constructor(props) {
    this.text = props.text ?? ""
    this.sequenceNumberPrefix = props.sequenceNumberPrefix ?? ""
    this.storyStatuses = props.storyStatuses ?? []
    this.epicIds = props.epicIds ?? []
    this.ownerIds = props.ownerIds ?? []
    this.createDate = props.createDate ?? {}
    this.itemTypes = props.itemTypes ?? []
    this.severityLevel = props.severityLevel ?? []
    this.hasEstimation = props.hasEstimation ?? undefined
    this.sort = props.sort ?? []
  }

  getJsonObj() {
    return {
      text: this.text,
      sequenceNumberPrefix: this.sequenceNumberPrefix,
      storyStatuses: this.storyStatuses ? this.storyStatuses.map(status => status.id) : [],
      itemTypes: this.itemTypes ? this.itemTypes.map(type => type.id) : [],
      severityLevel: this.severityLevel ? this.severityLevel.map(level => level.id) : [],
      epicIds: this.epicIds ? this.epicIds.map(epic => epic.id) : [],
      // inEpic: this.epicIds && !!this.epicIds.length,
      inEpic: this.epicIds && this.epicIds.length ? true : "",
      ownerIds: this.ownerIds ? this.ownerIds.map(owner => owner.id) : [],
      startCreatedDateTime: this.createDate?.start ? this.formatToISOString(this.createDate.start) : "",
      endCreatedDateTime: this.createDate?.end ? this.formatToISOString(this.createDate.end) : "",
      hasEstimation: this.hasEstimation,
      sort: this.sort ?? [],
    }
  }

  addSort(field: string, isAsc: boolean) {
    this.sort = [new Sort(field, isAsc)]
  }

  removeSort(field) {
    if (!this.sort) return
    this.sort = this.sort.filter(sort => sort.field !== field)
  }

  formatToISOString(date: string): string {
    return new Date(date).toISOString()
  }

  static createDefault() {
    const props = {
      text: "",
      sequenceNumberPrefix: "",
      storyStatuses: [],
      severityLevel: [],
      epicIds: [],
      ownerIds: [],
      itemTypes: [],
      createDate: {
        start: "",
        end: "",
      },
    }

    return new FilterBacklogUI(props)
  }

  static create(props) {
    return new FilterBacklogUI(props)
  }
}
