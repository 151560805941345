<template>
  <div
    class="required-alert"
  >
    <BaseSubHeading
      :size="sizeText"
      weight="400"
      color="monochrome-07"
      class="required-alert__content"
    >
      <icon
        data="@icon/attention.svg"
        :fill="true"
        color="#D32F2F"
        width="1.5em"
        height="1.5em"
      />
      <span class="required-alert__text">{{ alertText }}</span>
      <button
        class="required-alert__close"
        @click="$emit('closeAlert')"
      >
        <icon
          data="@icon/close.svg"
          :fill="false"
          width="1.25em"
          height="1.25em"
          color="#81889B"
        />
      </button>
    </BaseSubHeading>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"


@Options({
  name: "RequireAlert",
})
export default class extends Vue {
  @Prop() readonly alertText: string
  @Prop({ default: "medium" }) readonly sizeText: string
}
</script>
