import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, toHandlers as _toHandlers, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"
import _imports_0 from '@icon/loupe.svg'
import _imports_1 from '@icon/close.svg'
import _imports_2 from '@icon/spinner.svg'


const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "placeholder", "autocomplete", "maxlength", "tabindex"]
const _hoisted_3 = { class: "bordered-input__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Field = _resolveComponent("Field")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_Field, {
    ref: "borderedInput",
    as: _ctx.validationTag,
    modelValue: _ctx.content,
    name: _ctx.name ?? _ctx.id,
    rules: _ctx.rules,
    validateOnBlur: _ctx.validateOnBlur,
    validateOnModelUpdate: _ctx.validateOnModelUpdate,
    validateOnInput: _ctx.validateOnInput,
    validateOnChange: false
  }, {
    default: _withCtx(({ errors, field, meta }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["bordered-input", [
        {'bordered-input_sm': _ctx.sm },
        {'bordered-input_icon': _ctx.icon },
        {'bordered-input_icon-left': _ctx.searchIcon },
        {'bordered-input_relative-label': _ctx.relative },
        {'is-error': errors[0] },
        _ctx.className,
      ]]),
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onWrapperClick(_ctx.onWrapEvent)))
      }, [
        (_ctx.searchIcon)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              data: _imports_0,
              fill: false,
              color: "#81889B",
              width: "18",
              height: "18",
              class: "bordered-input__icon-left"
            }))
          : _createCommentVNode("", true),
        (!_ctx.sm && _ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 1,
              for: _ctx.id
            }, [
              _renderSlot(_ctx.$slots, "label small", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                (_ctx.tooltip)
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 0,
                      text: _ctx.tooltipText
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", _mergeProps(field, {
          id: _ctx.id,
          ref: "search",
          type: _ctx.type,
          class: _ctx.inputClass,
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.getAutocomplete,
          maxlength: _ctx.maxLength,
          tabindex: _ctx.tabindex
        }, _toHandlers(_ctx.inputEvents, true), {
          onMousedown: _cache[0] || (_cache[0] = ($event: any) => ($event.stopPropagation())),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('enter')), ["prevent"]), ["enter"])),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onFocus'))),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onBlur'))),
          onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChange($event.target.value)))
        }), null, 16, _hoisted_2), [
          [_directive_maska, _ctx.maska]
        ]),
        (_ctx.showClearButton && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "bordered-input__remove",
              onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClearButtonClicked && _ctx.onClearButtonClicked(...args)), ["prevent"]))
            }, [
              _createVNode(_component_icon, {
                data: _imports_1,
                fill: false,
                width: "1em",
                height: "1em",
                color: "#7D92A7"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon"),
          (meta.pending || _ctx.loading)
            ? (_openBlock(), _createBlock(_component_icon, {
                key: 0,
                data: _imports_2,
                original: "",
                color: "#85A7FF",
                width: "18",
                height: "18",
                class: "spin-animation"
              }))
            : _createCommentVNode("", true)
        ]),
        (errors[0])
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: _normalizeClass(["error-text bordered-input__error", { 'error-text-small': _ctx.isSmallErrorText }])
            }, _toDisplayString(errors[0]), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["as", "modelValue", "name", "rules", "validateOnBlur", "validateOnModelUpdate", "validateOnInput"]))
}