import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reference-team" }
const _hoisted_2 = { class: "reference-team__left" }
const _hoisted_3 = { class: "reference-team__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.result,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    onOnClick: _ctx.onTeamSelected
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        weight: "600",
        size: "medium",
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Teams ")
        ]),
        _: 1
      })
    ]),
    item: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            src: option.picture?.pictureUrl,
            size: "md"
          }, null, 8, ["src"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseSubHeading, {
            weight: "400",
            size: "medium",
            color: "monochrome-07"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.name) + " (" + _toDisplayString(option.size) + ") ", 1)
            ]),
            _: 2
          }, 1024)
        ])
      ])
    ]),
    _: 1
  }, 8, ["searchList", "componentType", "selectedIndex", "onOnClick"]))
}