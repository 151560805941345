<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div
        class="label-pic"
        :class="[labelColor]"
      >
        <icon
          data="@icon/sprint-alert.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        Sprint Risk Alert
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-07"
        weight="500"
        className="notification__sprint-alert"
      >
        <NotificationSprintHeader
          :sprint="sprintAlert"
        >
          at Risk
        </NotificationSprintHeader>
      </BaseSubHeading>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__sprint-alert"
      >
        It looks like the time left for the work in <b>Sprint #{{ sprintAlert?.sprintSequenceNumber }}</b>
        exceeds the time available by <span
          :class="[labelColor]"
          class="notification__sprint-alert__riskLevel"
        >over {{ sprintAlert?.riskLevel }}%</span><br>
        Please take this opportunity to review what's left.
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification"
import NotificationSprintHeader from "../components/NotificationSprintHeader.vue"

@Options({
  name: "SPRINT_RISK_ALERT",
  components: {
    NotificationTemplate,
    NotificationSprintHeader,
  },
})
export default class SPRINT_RISK_ALERT extends Vue {
  @Prop() readonly notification: Notification

  get sprintAlert() {
    return this.notification.content
  }

  get labelColor() {
    if (this.sprintAlert?.riskLevel >= 20) {
      return "is-red"
    } else {
      return "is-orange"
    }
  }
}
</script>
