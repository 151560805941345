export const enum Modules {
  ACCOUNT = "ACCOUNT",
  USER = "USER",
  PROJECT = "PROJECT",
  PAYMENT = "PAYMENT",
  ALERT = "ALERT",
  COMMENT = "COMMENT",
  ITEM = "ITEM",
  ITEM_STATUS = "ITEM_STATUS",
  ITEM_TYPE = "ITEM_TYPE",
  DEFINITION_OF_DONE = "DEFINITION_OF_DONE",
  FILE = "FILE",
  TASK = "TASK",
  TEAM = "TEAM",
  INVITATION = "INVITATION",
  PICTURE = "PICTURE",
  BACKLOG = "BACKLOG",
  EPIC = "EPIC",
  SPRINT = "SPRINT",
  POKER = "POKER",
  TODO = "TODO",
  BACKLOG_METRICS = "BACKLOG_METRICS",
  LOGIN = "LOGIN",
  RETROSPECTIVE = "RETROSPECTIVE",
  MILESTONE = "MILESTONE",
  SUBSCRIPTION = "SUBSCRIPTION",
  EMAIL_TEMPLATE = "EMAIL_TEMPLATE",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  POSTMORTEM = "POSTMORTEM",
  SEARCH = "SEARCH",
  LOG = "LOG",
  SESSION = "SESSION",
  TABS = "TABS",
  KEYBOARD_NAVIGATION = "KEYBOARD_NAVIGATION",
  NOTIFICATION = "NOTIFICATION",
  GOAL = "GOAL",
  FEATURE = "FEATURE",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  CHAT_GPT = "CHAT_GPT",
  HEALTH_CHECK = "HEALTH_CHECK",
  JIRA_IMPORT = "JIRA_IMPORT",
}
