import { store, Getters, Actions } from "@/store"
import { RouteLocationNormalized } from "vue-router"
import { $keycloak } from "@/plugins/keycloak"

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: (...args) => void): Promise<void> => {
  const auth = $keycloak.authenticated

  if (!auth) {
    next()
    return
  }


  const { params } = to


  if (!store.getters[Getters.GET_USER_CURRENT_PROFILE]) {
    const hasSuccess = await store.dispatch(Actions.GET_USER_CURRENT_PROFILE)
    if (!hasSuccess) {
      next(false)
    }
  }

  if (!store.getters[Getters.GET_USER_CURRENT_PROFILE]?.emailVerified && to.name !== "VerificationEmail") {
    next("/email-verification")
    return
  }

  if (to.meta?.withoutAccount || to.meta?.adminPage) {
    return next()
  }

  if (!params.accountId || params.accountId === "undefined") {
    const currentAccountId = store.getters[Getters.GET_CURRENT_ACCOUNT_ID]
    if (!currentAccountId) return next("/company/create/")
    const accountUrlName = findNameById(currentAccountId)
    return next(accountUrlName ? `/${accountUrlName}` : false)
  }
  next()

}

function findNameById(id: string) {
  return store.getters[Getters.GET_USER_CURRENT_PROFILE].accounts.find(a => a.id === id).urlName
}
