import { StructuredDescription } from "./EditorData"
import { Type, typeCreateById } from "./Type"
export interface MoveToInfo {
  id: string,
  label: string
}
export interface MoveToProp{
  title: string
  itemTypeParsed: Type
  itemType?: string
  status?: string
  severityLevel?: string
  structuredDescription?: StructuredDescription
  size?: string
  storyPoints?: number
  estimation?: number
  taskId: string
  epicId?: string
  sprintId?: string
  moveToInfo?: MoveToInfo
  teamId: string
}
export class MoveTo {
  title: string
  itemTypeParsed: Type
  itemType?: string
  status?: string
  severityLevel?: string
  structuredDescription?: StructuredDescription
  size?: string
  storyPoints?: number
  estimation?: number
  taskId: string
  epicId?: string
  sprintId?: string
  moveToInfo?: MoveToInfo
  teamId: string
  constructor(props: MoveToProp) {
    this.setDefaultData()

    if (!props) return

    this.set(props)
  }

  set(props: MoveToProp): void {
    this.title = props.title ?? ""
    this.itemTypeParsed = props.itemTypeParsed
    this.itemType = props.itemType ?? ""
    this.status = props.status ?? ""
    this.severityLevel = props.severityLevel ?? ""
    this.structuredDescription = props.structuredDescription
    this.size = props.size ?? ""
    this.storyPoints = props.storyPoints ?? 0
    this.estimation = props.estimation ?? 0
    this.taskId = props.taskId ?? ""
    this.epicId = props.epicId ?? ""
    this.sprintId = props.sprintId ?? ""
    this.moveToInfo = props.moveToInfo
    this.teamId = props.teamId ?? ""
  }

  setDefaultData(): void {
    this.title = ""
    this.itemTypeParsed = typeCreateById({ id: "STORY" })
    this.itemType = "STORY"
    this.status = ""
    this.severityLevel = ""
    this.structuredDescription = {
      html: "",
      json: "",
      text: "",
    }
    this.size = ""
    this.storyPoints = 0
    this.estimation = 0
    this.taskId = ""
    this.epicId = ""
    this.sprintId = ""
    this.moveToInfo = {
      id: "",
      label: "",
    }
    this.teamId = ""
  }
  getJsonObj() {
    return {
      title: this.title,
      itemType: this.itemTypeParsed.id,
      status: this.status,
      severityLevel: this.severityLevel,
      structuredDescription: this.structuredDescription,
      size: this.size,
      storyPoints: this.storyPoints,
      estimation: this.estimation,
      taskId: this.taskId,
      epicId: this.epicId,
      sprintId: this.sprintId,
      teamId: this.teamId,
    }
  }

  static create(props:MoveToProp): MoveTo {
    return new MoveTo(props)
  }

  get moveToLabel() {
    return this.moveToInfo?.id === "epic" ? "Epic" : `${this.moveToInfo?.label.replace("Backlog", "").trim()} Backlog`
  }
}

