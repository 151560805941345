import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"
import _imports_0 from '@icon/pen.svg'
import _imports_1 from '@icon/completed.svg'


const _hoisted_1 = { class: "definition" }
const _hoisted_2 = { class: "definition__title" }
const _hoisted_3 = {
  key: 0,
  class: "definition__content custom-scroll"
}
const _hoisted_4 = {
  key: 1,
  class: "definition__content custom-scroll"
}
const _hoisted_5 = {
  key: 0,
  class: "item-post-mortem"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  class: "definition__buttons-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!
  const _component_BasicCheckbox = _resolveComponent("BasicCheckbox")!
  const _component_AddBasicInput = _resolveComponent("AddBasicInput")!
  const _component_ControlButtons = _resolveComponent("ControlButtons")!
  const _component_ModalDefinitionDone = _resolveComponent("ModalDefinitionDone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.change)
          ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
              key: 0,
              className: "definition__title_item",
              size: "medium",
              weight: "400"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Identify the steps that need to be completed for this effort to be marked as done. ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.change)
          ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
              key: 1,
              className: "definition__title_item",
              size: "medium",
              weight: "400"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Select \"Definition of Done\" criteria(s) that are appropriate to confirm the doneness of the effort. ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.change)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "definition__title_add",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
            }, [
              _createVNode(_component_icon, {
                data: _imports_0,
                color: "#336DFF",
                width: "1.5em",
                height: "1.5em"
              }),
              _createTextVNode(" Edit ")
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_Transition, { name: "transitionCheck" }, {
        default: _withCtx(() => [
          (!_ctx.change && _ctx.itemId)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enabledDefinitions, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    style: _normalizeStyle(_ctx.getDefinitionStyle(index))
                  }, [
                    _createVNode(_component_BasicCheckbox, {
                      label: item.definition,
                      value: item.definition,
                      modelValue: item.done,
                      class: _normalizeClass(["definition__checkbox", {'definition__checkbox_check':item.done}]),
                      checkType: "check",
                      "onUpdate:modelValue": _ctx.onCheckboxUpdate
                    }, null, 8, ["label", "value", "modelValue", "class", "onUpdate:modelValue"])
                  ], 4))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allDefinitionsList, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createVNode(_component_BasicCheckbox, {
                      label: item.definition,
                      value: item.definition,
                      modelValue: item.enabled,
                      isDisabled: _ctx.isMandatory(item),
                      class: "definition__item_switch",
                      checkType: "switch",
                      "onUpdate:modelValue": _ctx.onSwitchUpdate
                    }, null, 8, ["label", "value", "modelValue", "isDisabled", "onUpdate:modelValue"])
                  ]))
                }), 128))
              ]))
        ]),
        _: 1
      }),
      (_ctx.change)
        ? (_openBlock(), _createBlock(_component_AddBasicInput, {
            key: 0,
            onEnter: _ctx.onAddCustomCheckbox
          }, null, 8, ["onEnter"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showCompleteButtons)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showFiveWhysButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_BaseSubHeading, {
                  size: "medium",
                  weight: "400",
                  className: "item-post-mortem__description",
                  color: "monochrome-05"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" To mark Support Case completed, please fill out the 5 Why's ")
                  ]),
                  _: 1
                }),
                _createElementVNode("button", {
                  class: "item-post-mortem__button secondary-button definition__gradient-button",
                  disabled: !_ctx.definitionsReadyToComplete,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('fillPostMortem')), ["prevent"]))
                }, [
                  _createTextVNode(" Fill out 5 Why's "),
                  _createVNode(_component_icon, {
                    data: _imports_1,
                    fill: true,
                    color: "#4CAB26",
                    width: "1.25em",
                    height: "1.25em"
                  })
                ], 8, _hoisted_6)
              ]))
            : (_openBlock(), _createBlock(_component_BaseSubHeading, {
                key: 1,
                size: "medium",
                weight: "400",
                className: "item-completed"
              }, {
                default: _withCtx(() => [
                  (!_ctx.inActiveSprint && _ctx.enableMarkAsCompleted)
                    ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                        key: 0,
                        size: "medium",
                        weight: "400",
                        className: "item-completed__description",
                        color: "monochrome-05"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Item must be in an Active Sprint to be marked as \"Completed\" ")
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "   ")),
                  _createElementVNode("button", {
                    class: "item-completed__button secondary-button definition__gradient-button",
                    disabled: !_ctx.enableMarkAsCompleted || !_ctx.inActiveSprint,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('itemCompleted')))
                  }, [
                    _createTextVNode(" Mark as Completed "),
                    _createVNode(_component_icon, {
                      data: _imports_1,
                      fill: true,
                      color: "#4CAB26",
                      width: "1.25em",
                      height: "1.25em"
                    })
                  ], 8, _hoisted_8)
                ]),
                _: 1
              }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.change && _ctx.itemId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_ControlButtons, {
            small: true,
            onSave: _ctx.save,
            onCancel: _ctx.cancel
          }, null, 8, ["onSave", "onCancel"])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ModalDefinitionDone, {
        config: _ctx.taskModalData,
        onConfirm: _ctx.onDefinitionModalConfirm
      }, null, 8, ["config", "onConfirm"])
    ]))
  ], 64))
}