<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <Avatar
        :src="notification.initiatorAvatarSrc || null"
        :gap="notification.getInitials()"
      />
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <span class="notification__update-item__initiator">{{ notification.initiatorFullName }}</span> added a comment to
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}:
        </a>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__update-item__description"
      >
        <ReferencedText
          :resetStyle="true"
          :html="notification.comment"
        />
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationComment } from "@/models/Notification/NotificationComment"
import Avatar from "@/components/Avatar/index.vue"
import { ReferencedText } from "../../Reference"

@Options({
  name: "COMMENTS",
  components: {
    NotificationTemplate,
    Avatar,
    ReferencedText,
  },
})
export default class COMMENTS extends Vue {
  @Prop() readonly notification: NotificationComment
}
</script>
