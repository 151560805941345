export interface HandleMouseParams {
  event: any,
  container: HTMLElement,
  threshold: number,
  maxScrollSpeed: number
}


export function handleMouseScroll({ event, container, threshold, maxScrollSpeed } :HandleMouseParams) {
  if (!container) return
  const { top, bottom } = container.getBoundingClientRect()
  const clientY = event.clientY

  if (clientY - top < threshold) {
    const scrollStep = -maxScrollSpeed * ((threshold - (clientY - top)) / threshold)
    container.scrollTop += scrollStep
    return
  }

  if (bottom - clientY < threshold) {
    const scrollStep = maxScrollSpeed * ((threshold - (bottom - clientY)) / threshold)
    container.scrollTop += scrollStep
  }
}
