import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/list-unordered-v2.svg'
import _imports_1 from '@icon/flag.svg'


const _hoisted_1 = { class: "label-pic is-blue" }
const _hoisted_2 = { class: "notification__todo" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    projectName: _ctx.sprintContent.projectName
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          width: "24px",
          height: "24px",
          color: "#1565C0"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Sprint is ending in a few days. Please complete your Sprint Retrospective. ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("ul", null, [
        _createElementVNode("li", _hoisted_2, [
          _createVNode(_component_BaseSubHeading, {
            size: "medium",
            color: "monochrome-06",
            darkColor: "monochrome-03",
            weight: "400"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: "link is-medium",
                target: "_blank",
                href: _ctx.sprintContent.retrospectiveUrl
              }, _toDisplayString(_ctx.sprintContent.todoName), 9, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: true,
            width: "14",
            height: "14",
            class: "notification__todo-icon",
            color: "#D32F2F"
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["notification", "projectName"]))
}