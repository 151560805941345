<template>
  <CustomModal
    id="definition-of-done-modal"
    modalClass="custom-modal_margin-custom custom-modal_delete-modal definition-of-done-modal"
  >
    <template #header>
      <BaseHeading
        level="2"
        weight="500"
      >
        Time Left
      </BaseHeading>
    </template>

    <template #body>
      <BaseSubHeading
        size="medium"
        weight="400"
        class="definition-of-done-modal__body"
      >
        {{ messageBody }}
        <br>
        <span>
          {{ messageFooter }}
        </span>
      </BaseSubHeading>
    </template>

    <template #footer="{ hide }">
      <div>
        <ControlButtons
          @save="confirm"
          @cancel="hide"
        >
          <template #save>
            Confirm
          </template>
        </ControlButtons>
      </div>
    </template>
  </CustomModal>
</template>

<script lang="ts">
import CustomModal from "@/components/Modal/index.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { Task } from "@/models"
import { $t, $tc } from "@/plugins/i18n"


@Options({
  name: "ModalDefinitionDone",
  components: {
    CustomModal,
    ControlButtons,
  },
})

export default class extends Vue {
  @Prop() config

  get taskType() {
    const typeParsed = Task.types.find(type => type.id === this.config.taskType)
    return typeParsed?.label ?? this.config.taskType
  }

  get definitionLabel() {
    return this.config.definitionLabel
  }

  get isAre() {
    return $tc("isAre", this.config.tasksCount)
  }

  get taskLabel() {
    return $tc("taskLabel", this.config.tasksCount)
  }

  get messageBody() {
    const { tasksCount, taskHours, tasksCountBugs, taskHoursBugs, taskTypeBugs } = this.config

    if (this.config.tasksCount && this.config.tasksCountBugs && this.taskType === "Dev") {
      let taskBugsAllInfo = $t("taskBugs.allInfo", {
        tasksCountBugs: tasksCountBugs,
        taskTypeBugs: taskTypeBugs,
        taskHoursBugs: taskHoursBugs,
      })
      let taskBugsType =
        $t("taskBugs.type", {
          taskTypeBugs: taskTypeBugs,
        })
      return $t("validationDefinitionMessage.messageBody", {
        isAre: this.isAre,
        taskLabel: this.taskLabel,
        tasksCount: tasksCount,
        taskType: this.taskType,
        taskHours: taskHours,
        definitionLabel: this.definitionLabel,
        taskBugsAllInfo: taskBugsAllInfo,
        taskBugsType: taskBugsType,
      })
    }

    if (this.config.tasksCountBugs) {
      return $t("validationDefinitionMessage.messageBody", {
        isAre: this.isAre,
        taskLabel: this.taskLabel,
        tasksCount: tasksCountBugs,
        taskType: taskTypeBugs,
        taskHours: taskHoursBugs,
        definitionLabel: this.definitionLabel,
      })
    } else {
      return $t("validationDefinitionMessage.messageBody", {
        isAre: this.isAre,
        taskLabel: this.taskLabel,
        tasksCount: tasksCount,
        taskType: this.taskType,
        taskHours: taskHours,
        definitionLabel: this.definitionLabel,
      })
    }
  }

  get messageFooter() {
    const { taskTypeBugs } = this.config
    if (this.config.tasksCount && this.config.tasksCountBugs) {
      let taskBugsType =
      $t("taskBugs.type", {
        taskTypeBugs: taskTypeBugs,
      })
      return $t("validationDefinitionMessage.messageFooter", {
        taskLabel: this.taskLabel,
        taskType: this.taskType,
        taskBugsType: taskBugsType,
      })
    }
    if (this.config.tasksCountBugs) {
      return $t("validationDefinitionMessage.messageFooter", {
        taskLabel: this.taskLabel,
        taskType: taskTypeBugs,
      })
    } else {
      return $t("validationDefinitionMessage.messageFooter", {
        taskLabel: this.taskLabel,
        taskType: this.taskType,
      })
    }
  }

  @Emit("confirm")
  confirm() {
    return this.config
  }
}
</script>
