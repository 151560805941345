<template>
  <ReferenceComponentParentList
    :searchList="result"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    :command="command"
    @onClick="onClick"
  >
    <template #item="{ option }">
      <div class="reference-user">
        <div class="reference-user__left">
          <Avatar
            :src="option.pictureUrl"
            :gap="option.getInitials()"
            size="sm"
          />
        </div>
        <div class="reference-user__right">
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-06"
            className="reference-user__title"
          >
            {{ option.fullName }}
          </BaseSubHeading>
          <BaseCaption
            v-if="hasDuplicateName(option.fullName)"
            size="large"
            color="monochrome-05"
            className="reference-user__desc"
          >
            ({{ option.email }})
          </BaseCaption>
        </div>
      </div>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import { User } from "@/models/User"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import Avatar from "@/components/Avatar/index.vue"
import { Getter, Action } from "s-vuex-class"
import { Getters, Actions } from "@/store"
import { FilterUser } from "@/models/Filter/FilterUser"
import { ServerResponse } from "@/models"

const components = {
  Avatar,
  ReferenceComponentParentList,
}

@Options({
  name: "UserList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  componentType = ReferenceType.USER

  @Getter(Getters.GET_SESSION_TEAM_ID) readonly teamId: string
  @Getter(Getters.GET_USER_CURRENT_PROFILE) readonly curentUser: User

  @Action(Actions.GET_USERS) findUsers: (params: FilterUser) => Promise<ServerResponse<User>>

  filter: FilterUser = FilterUser.createDefault()
  result: User[] = []

  async created() {
    this.filter.teamId = this.teamId
    this.filter.text = ""
    this.filter.forSearch = true
    this.searchAction = this.onSearchUsers
    this.search()
  }

  hasDuplicateName(fullName: string): boolean {
    const duplicate = this.result.filter(user => user.fullName === fullName)

    return duplicate.length > 1
  }

  queryUpdated(q) {
    this.filter.text = q ?? ""
    this.filter.teamId = this.teamId
    this.search()
  }

  onEnterHandler() {
    this.onClick(this.result[this.selectedIndex])
  }

  onClick(user: User) {
    this.selectItem({
      item: user,
      payload: {
        showDetails: this.hasDuplicateName(user.fullName),
      },
    })
  }

  async onSearchUsers() {
    this.filter.includeDemo = this.project?.demo ?? false
    const serverResponseUser = await this.findUsers(this.filter)
    this.result = serverResponseUser.content.filter(({ id }) => id !== this.curentUser?.id)
  }
}
</script>
