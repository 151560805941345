import {
  format,
  parseISO,
  formatISO,
  differenceInHours,
  differenceInDays,
  differenceInCalendarDays,
  differenceInBusinessDays as dnfsDifferenceInBusinessDays,
  subDays,
  addDays,
  startOfDay,
  formatDistanceStrict,
  subWeeks,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  isFirstDayOfMonth,
  addMonths,
  intervalToDuration,
} from "date-fns"
import locale from "date-fns/locale/en-US"
import formatDistance from "./dateLocale/formatDistance"

export interface FormatDateAgo{
  date: Date,
  dateFormat: Date,
  short?: boolean,
  sufixAgo?: boolean
}

export const enum DateFormats {

}

export const formatDate = (date: Date, dateFormat: string) => format(date, dateFormat)

export const dateNow = () => new Date()

export const getUTCDate = (date = new Date()) => new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

export const parseDate = (date?: string, isUTC?: boolean): Date => {
  if (!date) return new Date()
  if (isUTC) {
    const offSet = new Date().getTimezoneOffset() * 60 * 1000
    return new Date(parseISO(date).getTime() - offSet)
  }
  return parseISO(date)
}

export const nextMonths = (date: Date, monthsAgo:number) => {
  if (isFirstDayOfMonth(date)) {
    return addMonths(date, monthsAgo)
  } else {
    return addMonths(startOfMonth(date), monthsAgo + 1)
  }
}

export const formatDateAgo = (params: FormatDateAgo) => {
  const { date, dateFormat, short, sufixAgo } = params
  const options = short ? {
    addSuffix: false,
    locale: {
      ...locale,
      formatDistance,
    },
  } : undefined
  const timeAgo = formatDistanceStrict(date, dateFormat, options)
  return sufixAgo && timeAgo !== "now" ? timeAgo + " ago" : timeAgo
}

export const getListDatesFromList = (startDate: Date, stopDate: Date) => {
  const dateArray: Date[] = []
  let currentDate = startDate
  while (currentDate <= stopDate) {
    dateArray.push(currentDate)
    currentDate = addDays(currentDate, 1)
  }
  return dateArray
}

export const lastWeek = () => {
  const lastWeekDay = subWeeks(dateNow(), 1)

  return {
    start: startOfWeek(lastWeekDay, { weekStartsOn: 1 }),
    end: endOfWeek(lastWeekDay, { weekStartsOn: 1 }),
  }
}

export const thisWeek = () => ({
  start: startOfWeek(dateNow(), { weekStartsOn: 1 }),
  end: endOfWeek(dateNow(), { weekStartsOn: 1 }),
})

export const last30Days = () => {
  const lastSubDays = subDays(dateNow(), 29)

  return {
    start: dateNow(),
    end: lastSubDays,
  }
}

export const differenceInBusinessDays = (end, start) => {
  const newEndDate = new Date(end)
  newEndDate.setDate(newEndDate.getDate() + 1)
  return dnfsDifferenceInBusinessDays(newEndDate, start)
}

export {
  differenceInHours,
  differenceInDays,
  subDays,
  addDays,
  formatISO,
  startOfDay,
  differenceInCalendarDays,
  intervalToDuration,
}
