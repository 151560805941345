
import { Notification } from "./Notification"

export class NotificationRetrospective extends Notification {
  reminder: boolean

  constructor(props) {
    super(props)
    this.reminder = props.reminder
  }
}
