<template>
  <div
    v-tooltip="{
      content: text,
      theme: 'info-tooltip',
      placement: placement,
      popperClass: className
    }"
    class="custom-tooltip"
  >
    <icon
      data="@icon/info.svg"
      :fill="false"
      :width="size"
      :height="size"
      color="#7D92A7"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "Tooltip",
  components: {
  },
})
export default class Tooltip extends Vue {
  @Prop({ default: "" }) readonly text: string
  @Prop({ default: "" }) readonly size: string
  @Prop({ default: "" }) readonly className: string
  @Prop({ default: "top" }) readonly placement: string
}
</script>
