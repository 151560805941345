<template>
  <FlagProvider
    :config="unleashConfig"
  >
    <component
      :is="layout"
      v-if="layout"
    />
  </FlagProvider>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { forceUpdateToken, updateToken } from "./plugins/keycloak"
import DefaultLayout from "@/layouts/DefaultLayout.vue"
import EmptyLayout from "@/layouts/EmptyLayout.vue"
import EmptyLayoutWithHeader from "@/layouts/EmptyLayoutWithHeader.vue"
import ErrorLayout from "@/layouts/ErrorLayout.vue"
import { config, isStaging } from "@/app.config"
import { store } from "./store/store"
import { Getters } from "./store/getters"
import { BrowserTabConfig } from "./models/MetaConfigs"
import { FlagProvider } from "@unleash/proxy-client-vue"
import { unleash } from "@/plugins/unleash"

@Options({
  computed: {
    config() {
      return config
    },
  },
  methods: {
    isStaging,
  },
  components: {
    DefaultLayout,
    EmptyLayout,
    ErrorLayout,
    FlagProvider,
    EmptyLayoutWithHeader,
  },
  name: "Project Simple",
})
export default class App extends Vue {
  defaultLayout = "DefaultLayout"
  triggerBrowserTabUpdate = false

  get unleashConfig() {
    const { config } = unleash
    return config
  }

  async mounted() {
    await forceUpdateToken()
  }

  async created() {
    this.setBrowserTabName()
  }

  get layout() {
    return (this.$route.meta.layout || this.defaultLayout)
  }

  @Watch("$route")
  routeUpdated() {
    updateToken()
    this.setBrowserTabName()
  }

  get browserTabConfig(): BrowserTabConfig {
    return this.$route.meta?.browserTabConfig as BrowserTabConfig
  }

  get browserTabGetter() {
    return this.browserTabConfig?.getter ? store.getters[Getters[this.browserTabConfig.getter]] : ""
  }

  get browserTabSecondaryGetter() {
    return this.browserTabConfig?.secondaryGetter ? store.getters[Getters[this.browserTabConfig.secondaryGetter]] : ""
  }

  get browserTabWait() {
    return this.browserTabConfig?.wait ? this.$wait.is(this.browserTabConfig.wait) : false
  }

  @Watch("browserTabWait")
  @Watch("browserTabGetter")
  @Watch("browserTabSecondaryGetter")
  setBrowserTabName() {
    const defaultTabName = "Project Simple"
    if (!this.browserTabConfig || this.browserTabWait) {
      document.title = defaultTabName
      return
    }

    if (typeof this.browserTabConfig.title === "function") {
      let data = {
        value: this.browserTabGetter,
        secondaryValue: this.browserTabSecondaryGetter,
        route: this.$route,
      }
      const result = this.browserTabConfig.title(data)

      document.title = result ? result : defaultTabName
    } else {
      document.title = this.browserTabConfig.title
    }
  }
}
</script>
