<template>
  <NotificationTemplate
    :notification="notification"
    class="notification__update-item"
  >
    <template #pic>
      <div class="label-pic is-teal">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}
        </a>
        Status has been updated by <span class="notification__update-item__initiator">@{{ notification.initiatorFullName }}</span>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
        className="notification__update-item__content"
      >
        <span
          class="notification__update-item__status is-opacity"
          :class="[oldStatus.stringId]"
        >
          {{ oldStatus.label }}
        </span>
        <icon
          data="@icon/arrow-to.svg"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
        <span
          class="notification__update-item__status"
          :class="[actualStatus.stringId]"
        >
          {{ actualStatus.label }}
        </span>
      </BaseSubHeading>
      <BaseSubHeading
        v-if="notification.changeStatusReason"
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__update-item__description"
      >
        {{ notification.changeStatusReason }}
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "ITEM_UPDATE_STATUS",
  components: {
    NotificationTemplate,
  },
})
export default class ITEM_UPDATE_STATUS extends Vue {
  @Prop() readonly notification: NotificationItem

  get oldStatus() {
    return this.notification.getStatus(this.notification.getOldItemValue("status"))
  }

  get actualStatus() {
    return this.notification.getStatus(this.notification.getItemValue("status"))
  }
}
</script>
