import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"
import _imports_0 from '@icon/loupe.svg'


const _hoisted_1 = { class: "dropdown-bordered__multiselect" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown-bordered__start-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.parent), {
    type: _ctx.isButton ? 'button' : null,
    tabindex: "0",
    class: _normalizeClass(["dropdown-bordered", [
      {'dropdown-bordered_startIcon': _ctx.beforeIcon},
      {'is-disable-dropdown': _ctx.disabled}
    ]]),
    onKeydown: _withKeys(_ctx.onEnter, ["enter"]),
    onClick: _ctx.toggleDropdown
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        className: "dropdown-bordered__label",
        size: "large",
        weight: "400",
        color: "monochrome-05",
        darkColor: "monochrome-03"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.tooltip)
              ? (_openBlock(), _createBlock(_component_Tooltip, {
                  key: 0,
                  text: _ctx.tooltipText
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.beforeIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "start-icon", {}, () => [
                _createVNode(_component_icon, {
                  data: _imports_0,
                  fill: false,
                  width: "18px",
                  height: "18px",
                  color: "#7D92A7"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Multiselect, {
          id: _ctx.id,
          ref: `multiselect-${_ctx.id}`,
          modelValue: _ctx.value,
          trackBy: _ctx.track,
          label: _ctx.labelName,
          options: _ctx.options,
          showLabels: false,
          placeholder: _ctx.placeholder,
          hideSelected: _ctx.hideSelected,
          closeOnSelect: _ctx.closeOnSelect,
          tabindex: -1,
          preserveSearch: _ctx.preserveSearch,
          multiple: _ctx.multiple,
          taggable: _ctx.taggable,
          searchable: _ctx.searchable,
          allowEmpty: _ctx.allowEmpty,
          clearOnSelect: _ctx.clearOnSelect,
          preselectFirst: _ctx.preselectFirst,
          disabled: _ctx.disabledMultiselect,
          class: _normalizeClass(["dropdown-bordered__item", [{'is-multiple': _ctx.multiple}]]),
          optionHeight: _ctx.optionHeight,
          onOpen: _ctx.onOpen,
          onClose: _ctx.onClose,
          onSearchChange: _ctx.onSearchChange,
          onRemove: _ctx.onRemove,
          "onUpdate:modelValue": _ctx.onInput,
          onKeydown: _withKeys(_withModifiers(_ctx.onSelectHighlighted, ["exact","stop"]), ["tab"]),
          onKeyup: _withKeys(_ctx.onSelectHighlightedByEnter, ["enter"])
        }, {
          singleLabel: _withCtx((props) => [
            _renderSlot(_ctx.$slots, "singleLabel", {
              option: props.option
            })
          ]),
          tag: _withCtx((props) => [
            _renderSlot(_ctx.$slots, "tag", {
              option: props.option
            })
          ]),
          beforeList: _withCtx(() => [
            _renderSlot(_ctx.$slots, "beforeList")
          ]),
          option: _withCtx((props) => [
            _renderSlot(_ctx.$slots, "option", {
              option: props.option
            })
          ]),
          afterList: _withCtx(() => [
            _renderSlot(_ctx.$slots, "afterList")
          ]),
          _: 3
        }, 8, ["id", "modelValue", "trackBy", "label", "options", "placeholder", "hideSelected", "closeOnSelect", "preserveSearch", "multiple", "taggable", "searchable", "allowEmpty", "clearOnSelect", "preselectFirst", "disabled", "class", "optionHeight", "onOpen", "onClose", "onSearchChange", "onRemove", "onUpdate:modelValue", "onKeydown", "onKeyup"])
      ])
    ]),
    _: 3
  }, 40, ["type", "class", "onKeydown", "onClick"]))
}