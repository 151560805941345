import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"
import { FilterSprint } from "@/models/Filter/FilterSprint"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  const filterSprints = FilterSprint.createDefault()
  const teamId = store.getters[Getters.GET_CURRENT_TEAM_ID]
  let currentSprintId = store.getters[Getters.GET_CURRENT_SPRINT_ID]
  if (!currentSprintId && to.params.sprintId) {
    currentSprintId = await store.dispatch(Actions.RESOLVE_SESSION_SPRINT_ID, {
      sequenceNumber: to.params.sprintId,
      teamId,
    })
  }
  filterSprints.teamId = teamId
  filterSprints.includedStatuses = ["STARTED"]
  filterSprints.projectId = store.getters[Getters.GET_CURRENT_PROJECT_ID]
  const activeSprintsResponse = await store.dispatch(Actions.SEARCH_SPRINT, {
    filter: filterSprints,
    onlyGet: true,
  })
  const activeSprint = activeSprintsResponse.content[0]
  const isActiveSprint = to.params.sprintId === "active" || currentSprintId === activeSprint?.id
  if (!activeSprint && isActiveSprint) {
    return next({
      name: "Error",
      query: {
        code: 404,
      },
    })
  }
  if (isActiveSprint) {
    store.dispatch(Actions.SET_SESSION_SPRINT_ID, activeSprint.id)
  }
  if (to.params.sprintId !== "active" && isActiveSprint) {
    return next({
      name: to.name,
      params: { ...to.params, sprintId: "active" },
      query: to.query,
    })
  }
  return next()
}
