import { Action, Module, Mutation } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { AlertActions, alertModule } from "./alert.module"
import { ServerResponse } from "@/models/ServerResponse"
import { ProjectActions, projectModule } from "@/store/modules/project.module"
import { store } from "../store"
import { Modules } from "../modules"
import {
  AlertContentItem,
  AlertType,
  Epic,
  FilterBacklog,
  Item,
  FilterEpic,
  Status,
  Team,
  UpdateObjectValue,
  ItemQueryPriority,
  ServerPriorityResponse,
  tShort,
  CreateEpic,
} from "@/models"
import { $t } from "@/plugins/i18n"
import { BaseModule } from "@/models/BaseModule"
import { ItemActions, itemModule } from "./item"
import { cutTextByNumberOfSymbols } from "@/helpers/cutTextByNumberOfSymbols"

export enum EpicActions {
  GET_EPICS = "GET_EPICS",
  GET_EPICS_RESPONSE = "GET_EPICS_RESPONSE",
  GET_EPIC = "GET_EPIC",
  GET_CURRENT_EPIC = "GET_CURRENT_EPIC",
  GET_CURRENT_EPIC_ID = "GET_CURRENT_EPIC_ID",
  POST_EPIC = "POST_EPIC",
  UPDATE_EPIC = "UPDATE_EPIC",
  UPDATE_EPIC_VALUE = "UPDATE_EPIC_VALUE",
  GET_EPIC_STATUSES = "GET_EPIC_STATUSES",
  GET_EPIC_STORY = "GET_EPIC_STORY",
  RESOLVE_EPIC_ID = "RESOLVE_EPIC_ID",
  UNSELECT_TEAM = "UNSELECT_TEAM",
  IMPORT_ITEM_LIST = "IMPORT_ITEM_LIST",
  IMPORT_ITEM_TO_EPIC = "IMPORT_ITEM_TO_EPIC",
  CLEAR_IMPORT_ITEM_LIST = "CLEAR_IMPORT_ITEM_LIST",
  CHANGE_ITEM_LIST = "CHANGE_ITEM_LIST",
  CHANGE_TEAM_ITEM_IN_EPIC = "CHANGE_TEAM_ITEM_IN_EPIC",
  CLEAR_CHANGE_ITEM_LIST = "CLEAR_CHANGE_ITEM_LIST",
  REMOVE_ITEM_FROM_EPIC = "REMOVE_ITEM_FROM_EPIC",
  DELETE_ITEM_FROM_EPIC = "DELETE_ITEM_FROM_EPIC",
  MOVE_EPIC_PRIORITY_IN_MILESTONE = "MOVE_EPIC_PRIORITY_IN_MILESTONE",
  MOVE_EPIC_TO_MILESTONE = "MOVE_EPIC_TO_MILESTONE",
  ARCHIVE_EPIC = "ARCHIVE_EPIC",
  DELETE_EPIC = "DELETE_EPIC",
  MERGE_EPIC = "MERGE_EPIC",
  RESTORE_EPIC = "RESTORE_EPIC",
  GET_TSHORT_LIST = "GET_TSHORT_LIST",
  GET_TSHORT_BY_ID = "GET_TSHORT_BY_ID",
}

@Module
class EpicModule extends BaseModule {
  epic: Epic
  epics: Epic[] = []
  epicResponse: ServerResponse<Epic> | null = null
  statuses: Status[] = Epic.statuses
  currentEpicId: string
  items: Item[]
  itemsImport: Item[] = []
  itemsChangeTeam: Item[] = []
  tShorts: tShort[] = [
    {
      id: "NONE",
      shortLabel: "--",
      label: "None",
    },
    {
      id: "XS",
      shortLabel: "XS",
      label: "Extra Small",
    },
    {
      id: "S",
      shortLabel: "S",
      label: "Small",
    },
    {
      id: "M",
      shortLabel: "M",
      label: "Medium",
    },
    {
      id: "L",
      shortLabel: "L",
      label: "Large",
    },
    {
      id: "XL",
      shortLabel: "XL",
      label: "Extra Large",
    },
  ]

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${EpicActions.GET_EPIC}`]() {
    return this.epic
  }

  get [`get/${EpicActions.GET_EPICS_RESPONSE}`]() {
    return this.epicResponse
  }

  get [`get/${EpicActions.GET_TSHORT_LIST}`]() {
    return this.tShorts
  }

  get [`get/${EpicActions.GET_TSHORT_BY_ID}`]() {
    return (id: string) => {
      if (!id) return null

      const item = this.tShorts.find(item => item.id === id.toUpperCase())

      return item ? item : null
    }
  }

  get [`get/${EpicActions.GET_EPICS}`]() {
    return this.epics
  }

  get [`get/${EpicActions.GET_EPIC_STORY}`]() {
    return this.items
  }

  get [`get/${EpicActions.GET_EPIC_STATUSES}`]() {
    return this.statuses
  }

  get [`get/${EpicActions.GET_CURRENT_EPIC_ID}`]() {
    return this.currentEpicId
  }

  get [`get/${EpicActions.IMPORT_ITEM_LIST}`]() {
    return this.itemsImport
  }

  get [`get/${EpicActions.CHANGE_ITEM_LIST}`]() {
    return this.itemsChangeTeam
  }

  @Mutation
  setEpic(epic: Epic) {
    this.epic = epic
    this.currentEpicId = epic?.id
  }

  @Mutation
  setEpics(epics: Epic[]) {
    this.epics = epics
  }

  @Mutation
  addEpics(epics: Epic[]) {
    this.epics = [...this.epics, ...epics]
  }

  @Mutation
  setEpicStory(items: Item[]) {
    this.items = items
  }

  @Mutation
  addEpic(epic: Epic) {
    this.epic = epic
    this.currentEpicId = epic.id
    this.epics.push(epic)
  }

  @Mutation
  setEpicValue(payload: UpdateObjectValue<Epic>) {
    Object.assign(this.epic, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setEpicResponse(serverResponse: ServerResponse<Epic> | null) {
    this.epicResponse = serverResponse
  }

  @Mutation
  clearImportItemList() {
    this.itemsImport = []
  }

  @Mutation
  clearchangeTeamForItem() {
    this.itemsChangeTeam = []
  }

  @Mutation
  importItemList(item: Item) {
    if (this.itemsImport.find(e => e.id === item.id)) {
      this.itemsImport = this.itemsImport.filter(e => e.id !== item.id)
    } else {
      this.itemsImport.push(item)
    }
  }

  @Mutation
  changeTeamForItem(item: Item) {
    const itemsIds = [...this.itemsChangeTeam.map(item => item.id)]
    if (itemsIds.includes(item.id)) {
      this.itemsChangeTeam = this.itemsChangeTeam.filter(e => e.id !== item.id)
    } else {
      this.itemsChangeTeam.push(item)
    }
  }

  @Mutation
  setCurrentEpicId(currentEpicId: string) {
    this.currentEpicId = currentEpicId
  }

  @Action
  async [EpicActions.GET_EPICS]({ filter, projectId, immediate = false }: { filter: FilterEpic, projectId: string, immediate: boolean }) {
    const query = filter ? filter.getJsonObj() : FilterEpic.createDefault().getJsonObj()
    const data = await apiService.get<ServerResponse<Epic>>(Endpoint.GET_EPICS(projectId ?? this.projectId), query)
    return this.handleResponse<ServerResponse<Epic>>(data, (data) => {
      const epics = data.content.map(e => Epic.create(e))
      const epicsServerSesponse = { ...data, content: epics }

      if (immediate) return epicsServerSesponse


      this.setEpicResponse(epicsServerSesponse)

      if (filter.page === 1) {
        this.setEpics(epics)
      } else {
        this.addEpics(epics)
      }
    })
  }

  @Action
  async [EpicActions.GET_CURRENT_EPIC]() {
    return await this[EpicActions.GET_EPIC](this.currentEpicId)
  }

  @Action
  async [EpicActions.CHANGE_TEAM_ITEM_IN_EPIC]({ team, item, moveTo }: { team: Team, item?: Item, moveTo: string }) {
    const items = item ? [item] : this.itemsChangeTeam
    const updatedItems = await itemModule[ItemActions.CHANGE_TEAM_FOR_ITEMS_BATCH]({
      items: items,
      team,
      moveTo,
    })
    return updatedItems
  }

  @Action
  async [EpicActions.GET_EPIC](epicId: string) {
    const data = await apiService.get<Epic>(Endpoint.GET_EPIC(this.projectId, epicId))
    return this.handleResponse<Epic>(data, (data) => {
      const epic = Epic.create(data)
      this.setEpic(epic)
      return epic
    })
  }

  @Action
  async [EpicActions.POST_EPIC](epic: CreateEpic) {
    const data = await apiService.post<Epic>(Endpoint.POST_EPIC(epic.projectId ?? this.projectId), epic)
    return this.handleResponse<Epic>(data, (data) => {
      const epic = Epic.create(data)
      this.addEpic(epic)
      const alertContent: AlertContentItem[] = [
        {
          text: `Epic "E-${epic.sequenceNumber} ${cutTextByNumberOfSymbols({
            text: epic.name,
            numberOfSymbols: 16,
          })}" `,
          type: "bold",
        },
        { text: "was created", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return epic
    })
  }

  @Action
  async [EpicActions.MOVE_EPIC_PRIORITY_IN_MILESTONE](payload: ItemQueryPriority) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.CHANGE_EPIC_PRIORITY_IN_MILESTONE(this.projectId), payload)
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) {
        // TODO - reload backlog
      }
    })
  }

  @Action
  async [EpicActions.MOVE_EPIC_TO_MILESTONE](payload: ItemQueryPriority) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.MOVE_EPIC_TO_MILESTONE(this.projectId), payload)
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) {
        // TODO - reload backlog
      }
    })
  }

  @Action
  async [EpicActions.UPDATE_EPIC]({ epic, projectId } : { epic: Epic, projectId?: string }) {
    const data = await apiService.put<Epic>(Endpoint.UPDATE_EPIC(projectId ?? this.projectId, epic.id ?? ""), epic.getJsonObj())
    return this.handleResponse<Epic>(data, (data) => {
      const epic = Epic.create(data)
      this.addEpic(epic)
      const alertContent: AlertContentItem[] = [{
        text: `Epic ${$t("alert.updated")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      return epic
    })
  }

  @Action
  async [EpicActions.DELETE_EPIC](epic: Epic) {
    const data = await apiService.delete<Epic>(Endpoint.UPDATE_EPIC(this.projectId, epic.id ?? ""))
    const alertContent: AlertContentItem[] = [
      {
        text: `Epic E-${epic.sequenceNumber} `,
        type: "bold",
      },
      { text: "was deleted", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
    return this.handleResponse<Epic>(data)
  }
  @Action
  async [EpicActions.RESTORE_EPIC](epic: Epic) {
    const data = await apiService.post<Epic>(Endpoint.RESTORE_EPIC(this.projectId, epic.id ?? ""))
    const alertContent: AlertContentItem[] = [
      {
        text: `Epic E-${epic.sequenceNumber} `,
        type: "bold",
      },
      { text: "was restored", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
    return this.handleResponse<Epic>(data)
  }

  @Action
  async [EpicActions.MERGE_EPIC]({ epic, targetEpic }:{epic: Epic, targetEpic: Epic}) {
    const data = await apiService.post<Epic>(Endpoint.MERGE_EPIC(this.projectId, epic.id ?? "", targetEpic.id))
    const alertContent: AlertContentItem[] = [
      {
        text: `Epic E-${epic.sequenceNumber} `,
        type: "bold",
      },
      { text: "and  ", type: "regular" },
      {
        text: `Epic E-${targetEpic.sequenceNumber}  `,
        type: "bold",
      },
      { text: "were merged", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
    return this.handleResponse<Epic>(data)
  }

  @Action
  async [EpicActions.IMPORT_ITEM_TO_EPIC]({ epicId, selectedTeam }:{epicId: string, selectedTeam?: Team}) {
    const data = await apiService.post<Epic>(Endpoint.IMPORT_ITEM_TO_EPIC(this.projectId, epicId ?? ""), this.itemsImport.map(e => e.id))
    const alertContent: AlertContentItem[] = [
      {
        text: `${this.itemsImport.length} Items `,
        type: "bold",
      },
      { text: "from ", type: "regular" },
      { text: `${selectedTeam?.name} `, type: "bold" },
      { text: "Team added to Epic ", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
    return this.handleResponse<Epic>(data)
  }

  @Action
  async [EpicActions.ARCHIVE_EPIC](epic: Epic) {
    const data = await apiService.post<Epic>(Endpoint.ARCHIVE_EPIC(this.projectId, epic.id))
    const alertContent: AlertContentItem[] = [
      {
        text: `Epic E-${epic.sequenceNumber} `,
        type: "bold",
      },
      { text: "was archived", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })
    return this.handleResponse<Epic>(data)
  }

  @Action
  async [EpicActions.GET_EPIC_STORY](filterEpicStory: FilterBacklog) {
    const query = filterEpicStory.getJsonObj()
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.BACKLOG(this.projectId), query)
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      const content: Item[] = data.content.map(i => Item.create(i))
      this.setEpicStory(content)
    })
  }

  @Action
  async [EpicActions.RESOLVE_EPIC_ID](sequenceNumber: number) {
    const data = await apiService.get<string>(Endpoint.RESOLVE_EPIC_ID(this.projectId, sequenceNumber))
    return this.handleResponse<string>(data, (epicId) => {
      this.setCurrentEpicId(epicId)
      return this.currentEpicId
    },
    true, () => {
      throw Error(`Unknown epic number: ${sequenceNumber}`)
    })
  }

  @Action
  async [EpicActions.DELETE_ITEM_FROM_EPIC]({ epicId, items } : { epicId: string, items: Item[] }) {
    const itemIds = items.map(item => item.id)
    const data = await apiService.delete<void>(Endpoint.DELETE_ITEM_FROM_EPIC(this.projectId, epicId), itemIds)
    return this.handleResponse<void>(data, () => {
      let alertContent: AlertContentItem[] = []
      if (items.length > 1) {
        alertContent = [
          { text: "Items ", type: "bold" },
          { text: "was removed from Epic", type: "regular" },
        ]
      } else {
        alertContent = [
          { text: `Item #${items[0].sequenceNumber} `, type: "bold" },
          { text: "was removed from Epic", type: "regular" },
        ]
      }

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }


  @Action
  [EpicActions.UPDATE_EPIC_VALUE](payload: UpdateObjectValue<Epic>) {
    this.setEpicValue(payload)
  }

  @Action
  [EpicActions.UNSELECT_TEAM](teamId: string) {
    this.epic.teams = this.epic.teams?.filter(e => e.id !== teamId)
  }

  @Action
  [EpicActions.IMPORT_ITEM_LIST](item: Item) {
    this.importItemList(item)
  }

  @Action
  [EpicActions.CHANGE_ITEM_LIST](item: Item) {
    this.changeTeamForItem(item)
  }

  @Action
  [EpicActions.CLEAR_IMPORT_ITEM_LIST]() {
    this.clearImportItemList()
  }

  @Action
  [EpicActions.CLEAR_CHANGE_ITEM_LIST]() {
    this.clearchangeTeamForItem()
  }
}

export const epicModule = new EpicModule({ store, name: Modules.EPIC })
