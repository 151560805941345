/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "@/store/modules"
import { store } from "@/store/store"
import { AlertActions, alertModule } from "../alert.module"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { $t } from "@/plugins/i18n"
import { projectModule } from "../project.module"
import { FileMetaData } from "@/models/File"
import { UpdateObjectValue } from "@/models/common"
import { ServerResponse } from "@/models/ServerResponse"
import { AlertType, AlertContentItem } from "@/models/Alert"
import {
  AddItemEpicPayload,
  MoveTo,
  TaskType,
  ChangeItemOwner,
  ChangeItemStatus,
  Item,
  ItemDefinitionPyload,
  DefinitionOfDone,
  Status,
  DefinitionWithItemStatus,
  ItemStatus,
  ChangeTeamForItem,
  Team,
  ResponseToChangingItemTeam,
} from "@/models"
import { BaseModule } from "@/models/BaseModule"
import { accountModule } from "../account.module"
import { parsingServerResponseContent } from "@/utils/helpers"
import { cutTextByNumberOfSymbols } from "@/helpers/cutTextByNumberOfSymbols"

export enum ItemActions {
  GET_ITEMS = "GET_ITEMS",
  GET_ITEM_SERVER_RESPONSE = "GET_ITEM_SERVER_RESPONSE",
  GET_ITEM = "GET_ITEM",
  POST_ITEM = "POST_ITEM",
  CHANGE_STATUS_ITEM = "CHANGE_STATUS_ITEM",
  RESET_ITEM = "RESET_ITEM",
  PUT_ITEM = "PUT_ITEM",
  UPDATE_ITEM_VALUE = "UPDATE_ITEM_VALUE",
  UPDATE_ITEM_VALUES = "UPDATE_ITEM_VALUES",
  GET_ITEM_FILES = "GET_ITEM_FILES",
  UPDATE_DEFINITION_OF_DONE = "UPDATE_DEFINITION_OF_DONE",
  ADD_ITEM_TO_EPIC = "ADD_ITEM_TO_EPIC",
  DELETE_ITEM = "DELETE_ITEM",
  DELETE_ITEM_FILE = "DELETE_ITEM_FILE",
  CHANGE_OWNER_ITEM = "CHANGE_OWNER_ITEM",
  MOVE_TO_ITEM = "MOVE_TO_ITEM",
  GET_ITEM_CLONE = "GET_ITEM_CLONE",
  UPDATE_ITEM_CLONE = "UPDATE_ITEM_CLONE",
  REMOVE_DEFINITION = "REMOVE_DEFINITION",
  GET_ITEMS_SEARCH = "GET_ITEMS_SEARCH",
  SET_OPENED_AVATAR_DROPDOWN_ID = "SET_OPENED_AVATAR_DROPDOWN_ID",
  GET_OPENED_AVATAR_DROPDOWN_ID = "GET_OPENED_AVATAR_DROPDOWN_ID",
  UPDATE_ITEM_DETAILS = "UPDATE_ITEM_DETAILS",
  UPDATE_TEAM = "UPDATE_TEAM",
  CHANGE_TEAM_FOR_ITEM = "CHANGE_TEAM_FOR_ITEM",
  UPDATE_ITEM_STATUS = "UPDATE_ITEM_STATUS",
  UPDATE_ITEM_STATUS_IN_LIST = "UPDATE_ITEM_STATUS_IN_LIST",
  UPDATE_ITEM_IN_LIST = "UPDATE_ITEM_IN_LIST",
  GET_BACKLOG_CREATED_ITEM = "GET_BACKLOG_CREATED_ITEM",
  UPDATE_VALUE_FOR_BACKLOG_CREATED_ITEM = "UPDATE_VALUE_FOR_BACKLOG_CREATED_ITEM",
  RESET_CLONE = "RESET_CLONE",
  REMOVE_FILE_FROM_ITEM = "REMOVE_FILE_FROM_ITEM",
  UPDATE_ITEM_READINESS = "UPDATE_ITEM_READINESS",
  CHANGE_TEAM_FOR_ITEMS_BATCH = "CHANGE_TEAM_FOR_ITEMS_BATCH",
  SET_ITEM = "SET_ITEM"
}

@Module
class ItemModule extends BaseModule {
  items: Item[] = []
  itemServerResponse: ServerResponse<Item>
  item: Item = new Item()
  itemClone: Item = new Item()
  openedAvatarDropdownId: string | null = null
  backlogCreatedItem: Item | null = null

  get [`get/${ItemActions.GET_OPENED_AVATAR_DROPDOWN_ID}`]() {
    return this.openedAvatarDropdownId
  }

  get accountId() {
    return accountModule.accountId
  }

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${ItemActions.GET_ITEMS}`]() {
    return this.items
  }

  get [`get/${ItemActions.GET_ITEM}`]() {
    return this.item
  }

  get [`get/${ItemActions.GET_ITEM_CLONE}`]() {
    return this.itemClone
  }

  get [`get/${ItemActions.GET_BACKLOG_CREATED_ITEM}`]() {
    return this.backlogCreatedItem
  }

  get [`get/${ItemActions.GET_ITEM_SERVER_RESPONSE}`]() {
    return this.itemServerResponse
  }

  @Mutation
  setOpenedAvatarDropdownId(id: string | null) {
    this.openedAvatarDropdownId = id
  }

  @Mutation
  setItems(itemServerResponse: ServerResponse<Item>) {
    this.items = parsingServerResponseContent({
      originalContent: this.items,
      serverResponseContent: itemServerResponse.content,
      numberOfPage: itemServerResponse.number,
    })
    this.itemServerResponse = { ...itemServerResponse, content: this.items }
  }

  @Mutation
  replaceItem(item: Item) {
    this.items = this.items.map(i => item.id === i.id ? item : i)
  }

  @Mutation
  setItemClone(item: Item) {
    this.itemClone = Item.create(item)
  }

  @Mutation
  setItem(item: Item) {
    this.item = item
  }

  @Mutation
  // update progress fields in Item
  setProgress(item: Item) {
    const listItem = this.items.find(el => el.id === item.id)
    const updateFields = [
      "tasks",
      "status",
      "statusParsed",
      "definitionsOfDone",
    ]
    const updateItem = Item.create(item)
    updateFields.forEach((updateField) => {
      if (listItem) {
        listItem[updateField] = updateItem[updateField]
      }
      this.item[updateField] = updateItem[updateField]
      this.itemClone[updateField] = updateItem[updateField]
    })
  }

  @Mutation
  setItemValue(payload: UpdateObjectValue<Item>) {
    this.item.onValueUpdate(payload)
    Object.assign(this.item, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setItemValues({ payload, updateClone } : { payload: UpdateObjectValue<Item>[], updateClone?: boolean }) {
    payload.forEach((e) => {
      this.item.onValueUpdate(e)
      Object.assign(this.item, { [e["id"]]: e["value"] })
      if (updateClone) {
        Object.assign(this.itemClone, { [e["id"]]: e["value"] })
      }
    })
  }

  @Mutation
  updateItemClone(itemField: string) {
    this.itemClone[itemField] = this.item[itemField]
  }

  @Mutation
  removeFileFromItem(fileMetadataId: string) {
    this.item.fileMetadata = this.item.fileMetadata?.filter(file => file.id !== fileMetadataId)
  }

  @Mutation
  setStatusToItemList({ status, itemId } : {status: ItemStatus, itemId: string}) {
    const statusParsed = Status.createById({
      id: status ?? "NOT_STARTED",
    })
    this.items = this.items.map((item) => {
      if (item.id === itemId) Object.assign(item, { status, statusParsed })
      return item
    })
  }

  @Mutation
  setStatusAndDefinition(payload: DefinitionWithItemStatus) {
    const definitionsOfDone = payload.definitionsOfDone.map(definition => DefinitionOfDone.create(definition))
    const status = payload.status
    const statusParsed = Status.createById({
      id: status ?? "NOT_STARTED",
    })
    const estimation = payload.estimation
    Object.assign(this.item, {
      status,
      statusParsed,
      definitionsOfDone,
      estimation,
    })
    Object.assign(this.itemClone, {
      status,
      statusParsed,
      definitionsOfDone,
      estimation,
    })
  }

  @Mutation
  setStatusToItem(status: ItemStatus) {
    const statusParsed = Status.createById({
      id: status ?? "NOT_STARTED",
    })
    Object.assign(this.item, { status, statusParsed })
    Object.assign(this.itemClone, { status, statusParsed })
  }

  @Mutation
  resetItem() {
    this.item = new Item()
    this.itemClone = new Item()
  }

  @Mutation
  removeDefinition(taskType: TaskType) {
    this.item.definitionsOfDone = this.item.definitionsOfDone.filter(def => def.type !== taskType)
  }

  @Mutation
  setItemInList({ payload, itemId }: { payload: UpdateObjectValue<Item>, itemId: string}) {
    this.items = this.items.map((el) => {
      if (el.id === itemId) el[payload?.["id"]] = payload?.["value"]
      return el
    })
  }

  @Mutation
  setbacklogCreatedItem(backlogCreatedItem: Item | null) {
    this.backlogCreatedItem = backlogCreatedItem ? Item.create(backlogCreatedItem) : null
  }

  @Mutation
  resetItemClone() {
    this.itemClone = Item.create(this.item)
  }

  @Mutation
  setValueForBacklogCreatedItem(params: UpdateObjectValue<Item>) {
    if (!this.backlogCreatedItem) return
    Object.assign(this.backlogCreatedItem, { [params["id"]]: params["value"] })
  }

  private getChangeTeamAlert(payload: { items: Item[], team: Team | null, moveTo?: string }) {
    const item = payload.items[0]
    const team = payload.team

    if (!item) {
      return []
    }

    let alertContent: AlertContentItem[] = []

    if (!payload.team) {
      // Item X - was unassigned from Team X and moved to a Product Backlog.

      alertContent = [
        {
          text: `${item.itemTypeParsed?.label ?? ""} `,
          type: "bold",
        },
        { text: `#${item.sequenceNumber}`, type: "bold" },
        { text: " was unassigned from ", type: "regular" },
        {
          text: `${item.team?.name ?? ""} `,
          type: "bold",
        },
        { text: " and moved to  ", type: "regular" },
        {
          text: `${Item.getBacklogNameByItem(item)}`,
          type: "regular",
        },
      ]
    }
    if (payload.moveTo === "unprioritizedBacklog") {
      alertContent = [
        {
          text: `${item.itemTypeParsed?.label ?? ""} `,
          type: "bold",
        },
        { text: `#${item.sequenceNumber}`, type: "bold" },
        { text: " was moved to ", type: "regular" },
        {
          text: `${item.team?.name ?? ""}`,
          type: "bold",
        },
        {
          text: " Team Unprioritized Backlog",
          type: "regular",
        },
      ]
    }
    if (payload.moveTo === "team") {
      if (payload.items.length > 1) {
        alertContent = [
          { text: "Items ", type: "bold" },
          { text: " were assigned to ", type: "regular" },
        ]
      } else {
        alertContent = [
          {
            text: `${item.itemTypeParsed?.label ?? ""} `,
            type: "bold",
          },
          {
            text: `#${item.sequenceNumber}`,
            type: "bold",
          },
          { text: " was assigned to ", type: "regular" },
        ]
      }
      alertContent.push(
        { text: `${team?.name ?? ""} `, type: "bold" },
        { text: "Team", type: "regular" },
      )
    }
    return alertContent
  }

  private getItemUpdateAlertContent(item: Item) {
    let alertContent: AlertContentItem[] = []

    if (item.hasChangedProp("itemTypeParsed")) {
      // "[Original ITEM TYPE] #123 was changed to a [New Item Type]" // for story and spike
      // "[Original ITEM TYPE] #123 was updated to [New Item Type] and moved to Unprioritized Backlog" // type
      // "[Original ITEM TYPE] #123 was updated to [New Item Type] and moved to [TEAM NAME] Team Unprioritized Backlog" // type and team

      const oldType = item.oldValueForProp("itemTypeParsed")?.id
      const newType = item.itemTypeParsed?.id
      const inEpic = !!item?.epic && !item.team
      const hasItemChangedFromStoryToSpike = [
        oldType,
        newType,
      ].every(type => [
        "STORY",
        "SPIKE",
      ].includes(type))
      const inSprint = !!item?.sprint
      // if item type was changed from story to spike or back
      if (hasItemChangedFromStoryToSpike || inSprint || inEpic) {
        alertContent = [
          {
            text: `${item.oldValueForProp("itemTypeParsed")?.label ?? ""}`,
            type: "bold",
          },
          {
            text: ` #${item.sequenceNumber}`,
            type: "bold",
          },
          { text: " was changed to ", type: "regular" },
          {
            text: `${item.itemTypeParsed?.label ?? ""}`,
            type: "bold",
          },
        ]
      } else {
        alertContent = [
          {
            text: `${item.oldValueForProp("itemTypeParsed")?.label ?? ""}`,
            type: "bold",
          },
          {
            text: ` #${item.sequenceNumber}`,
            type: "bold",
          },
          { text: " was updated to ", type: "regular" },
          {
            text: `${item.itemTypeParsed?.label ?? ""}`,
            type: "bold",
          },
          { text: " and moved to ", type: "regular" },
          {
            text: `${item.hasChangedProp("team") ? item.team?.name ?? "" : ""}`,
            type: "bold",
          },
          {
            text: `${item.hasChangedProp("team") ? " Team " : ""}Unprioritized Backlog`,
            type: "regular",
          },
        ]
      }
    } else if (item.hasChangedProp("team")) {
      // "[ITEM TYPE] #123 was updated and moved to [TEAM NAME] Team Unprioritized Backlog" // team and changes
      // "[ITEM TYPE] #123 was moved to [TEAM NAME] Team Unprioritized Backlog" // team
      alertContent = [
        {
          text: `${item.itemTypeParsed?.label ?? ""} `,
          type: "bold",
        },
        { text: `#${item.sequenceNumber}`, type: "bold" },
        {
          text: ` was ${(Object.keys(item.changedFieldsOriginValues).length > 1) ? "updated and " : " "}moved to `,
          type: "regular",
        },
        { text: `${item.team?.name ?? ""}`, type: "bold" },
        {
          text: " Team Unprioritized Backlog",
          type: "regular",
        },
      ]
    } else {
      // "[ITEM TYPE] #123 updated" // any other updates
      alertContent = [
        {
          text: `${item.itemTypeParsed?.label ?? ""} `,
          type: "bold",
        },
        { text: `#${item.sequenceNumber}`, type: "bold" },
        { text: " updated", type: "regular" },
      ]
    }

    return alertContent
  }


  @Action
  [ItemActions.SET_OPENED_AVATAR_DROPDOWN_ID](id: string | null) {
    this.setOpenedAvatarDropdownId(id)
  }

  @Action
  async [ItemActions.GET_ITEMS]() {
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.GET_ITEMS(this.projectId))
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      data.content = data.content.map(i => Item.create(i))
      this.setItems(data)
      return data
    })
  }

  @Action
  async [ItemActions.GET_ITEMS_SEARCH](filter) {
    const data = await apiService.get<ServerResponse<Item>>(Endpoint.BACKLOG(this.projectId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Item>>(data, (data) => {
      data.content = data.content.map(i => Item.create(i))
      this.setItems(data)
      return data
    })
  }

  @Action
  async [ItemActions.GET_ITEM]({ itemId, onlyGet } : { itemId: string, onlyGet?: boolean }) {
    const data = await apiService.get<Item>(Endpoint.GET_ITEM(this.projectId, itemId))
    return this.handleResponse<Item>(data, (data) => {
      const item = Item.create(data)
      if (!onlyGet) {
        this.setItemClone(item)
        this.setItem(item)
      }
      return item
    })
  }

  @Action
  async [ItemActions.POST_ITEM]({ item, onlySend, alertMessage } : { item: Item, onlySend?: boolean, alertMessage?: AlertContentItem[] }) {
    const data = await apiService.post<Item>(Endpoint.POST_ITEM(this.projectId), item.getJsonObj())
    return this.handleResponse<Item>(data, (data) => {
      const newItem = Item.create(data)

      const alertContent = [
        {
          text: `${item.itemTypeParsed?.label ?? ""}`,
          type: "bold",
        } as AlertContentItem,
        {
          text: " was created and added to ",
          type: "regular",
        } as AlertContentItem,
        {
          text: `${item.team?.name ?? ""} `,
          type: "bold",
        } as AlertContentItem,
        {
          text: `${Item.getBacklogNameByItem(newItem)} Backlog`,
          type: "regular",
        } as AlertContentItem,
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertMessage ?? alertContent,
      })
      if (!onlySend) {
        this.setItem(newItem)
      }
      return newItem
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.CHANGE_TEAM_FOR_ITEMS_BATCH](params: { items: Item[], team: Team | null, moveTo?: string }) {
    const payload = {
      itemIds: params.items.map(i => i.id),
      teamId: params.team?.id ?? "",
    }

    const data = await apiService.post<ResponseToChangingItemTeam[]>(Endpoint.CHANGE_TEAM_FOR_ITEMS_BATCH(this.projectId), payload)

    return this.handleResponse<ResponseToChangingItemTeam[]>(data, (data) => {
      const itemsResponse = data.map(e => new ResponseToChangingItemTeam(e))
      const alertContent = this.getChangeTeamAlert(params)

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })

      return itemsResponse
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.CHANGE_TEAM_FOR_ITEM](payload: ChangeTeamForItem) {
    const { item, team } = payload
    const data = await apiService.post<Item>(Endpoint.CHANGE_TEAM_FOR_ITEM(this.projectId, item.id), null, {
      params: {
        teamId: team?.id,
      },
    })
    return this.handleResponse<Item>(data, (data) => {
      const newItem = Item.create(data)
      const updateValues = [
        { id: "team", value: newItem.team },
        { id: "owner", value: newItem.owner },
        { id: "tasks", value: newItem.tasks },
        { id: "storyPoints", value: newItem.storyPoints },
      ] as any[]
      this.setItemValues({ payload: updateValues, updateClone: true })

      const alertContent = this.getChangeTeamAlert({
        items: [item],
        team,
        moveTo: "team",
      })
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return newItem
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }


  @Action
  async [ItemActions.CHANGE_STATUS_ITEM]({ changeStatus } : { changeStatus: ChangeItemStatus }) {
    const projectId = changeStatus.projectId ? changeStatus.projectId : this.projectId
    const data = await apiService.post<ItemStatus>(Endpoint.CHANGE_STATUS_ITEM(projectId, changeStatus.itemId), {
      message: changeStatus.message,
      status: changeStatus.status,
    })
    return this.handleResponse<ItemStatus>(data, (data) => {
      let toastMessage = ""

      switch (changeStatus.status) {
        case "RE_OPEN":
          toastMessage = "was Re-Opened and moved into Work in Progress."
          break
        case "OBSOLETE":
          toastMessage = "was marked as Obsolete. Use Filter to locate it."
          break
        case "DONE":
          toastMessage = "was marked as Done. Use Filter to locate it."
          break
        default:
          toastMessage = (changeStatus.oldStatus === "OBSOLETE") ? "was removed from Obsolete and moved into Work in Progress." : $t("alert.changeStatus")
          break
      }
      const itemTitle = cutTextByNumberOfSymbols({
        text: changeStatus.itemTitle ?? "",
        numberOfSymbols: 16,
      })
      const alertContent: AlertContentItem[] = [
        {
          text: "Item ",
          type: "regular",
        },
        {
          text: `${itemTitle} `,
          type: "bold",
        },
        {
          text: `${toastMessage}`,
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        theme: "toast",
        type: AlertType.SUCCESS,
      })
      this.setStatusToItem(data)
      return data
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.CHANGE_OWNER_ITEM](changeOwner:ChangeItemOwner) {
    const data = await apiService.post<Item>(Endpoint.CHANGE_OWNER_ITEM(this.projectId, changeOwner.itemId), {
      ownerId: changeOwner.ownerId,
    })
    return this.handleResponse<Item>(data, (data) => {
      const itemTitle = cutTextByNumberOfSymbols({
        text: this.item.title ?? "",
        numberOfSymbols: 16,
      })
      const alertContent: AlertContentItem[] = [
        {
          text: "Item ",
          type: "regular",
        },
        {
          text: `${itemTitle} `,
          type: "bold",
        },
        {
          text: $t("alert.changeOwner"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.UPDATE_ITEM_DETAILS]({ item, notSetItem } : {item: Item, notSetItem?: boolean}) {
    const data = await apiService.put<Item>(Endpoint.UPDATE_ITEM_DETAILS(this.projectId, item.id ?? ""), item.getJsonObj())
    return this.handleResponse<Item>(data, (data) => {
      const alertContent = this.getItemUpdateAlertContent(item)

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      const itemCreate = Item.create(data)
      if (!notSetItem) {
        this.setItem(itemCreate)
      }
      return itemCreate
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.UPDATE_TEAM]({ itemId, teamId } : {itemId: string, teamId: string}) {
    const data = await apiService.patch<Item>(Endpoint.UPDATE_TEAM(this.projectId, itemId), {
      teamId: teamId,
    })
    return this.handleResponse<Item>(data, data => data)
  }

  @Action
  async [ItemActions.GET_ITEM_FILES]({ itemId, filterFiles }: { itemId: string, filterFiles }) {
    const filter = filterFiles?.getJsonObj()
    const data = await apiService.get<ServerResponse<FileMetaData>>(Endpoint.GET_ITEM_FILES(this.projectId, itemId), filter)
    return this.handleResponse<ServerResponse<FileMetaData>>(data, (data) => {
      const content = data.content.map((file: FileMetaData) => FileMetaData.create(file))
      return {...data, content}
    })
  }

  @Action
  async [ItemActions.UPDATE_DEFINITION_OF_DONE](payload: ItemDefinitionPyload) {
    const data = await apiService.patch<void>(Endpoint.ITEM_DEFINITION_OF_DONE(this.projectId, payload.itemId, payload.definitionOfDoneId))
    return this.handleResponse<void>(data)
  }

  @Action
  async [ItemActions.ADD_ITEM_TO_EPIC](payload: AddItemEpicPayload) {
    const { itemId, epicId } = payload
    let itemIds: string[] = []

    itemIds = typeof itemId === "string" ? [itemId] : itemId

    const data = await apiService.post<void>(Endpoint.ASSIGN_EPIC(this.projectId, epicId), itemIds)
    return this.handleResponse<void>(data)
  }

  @Action
  async [ItemActions.DELETE_ITEM](itemId: string) {
    const data = await apiService.delete<Item>(Endpoint.GET_ITEM(this.projectId, itemId))
    return this.handleResponse<Item>(data, () => {
      const itemTitle = cutTextByNumberOfSymbols({
        text: this.item.title ?? "",
        numberOfSymbols: 16,
      })
      const alertContent: AlertContentItem[] = [
        {
          text: "Item ",
          type: "regular",
        },
        {
          text: `${itemTitle} `,
          type: "bold",
        },
        {
          text: $t("alert.deleted"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.DELETE_ITEM_FILE]({ itemId, fileMetadataId }) {
    const data = await apiService.delete<Item>(itemId ?
        Endpoint.DELETE_ITEM_FILE(this.projectId, itemId, fileMetadataId) :
        Endpoint.DELETE_FILE(this.projectId, fileMetadataId)
    )
    return this.handleResponse<Item>(data, () => {
      this.removeFileFromItem(fileMetadataId)
      const alertContent: AlertContentItem[] = [{
        text: `File ${$t("alert.deleted")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [ItemActions.MOVE_TO_ITEM]({ itemId, moveTo }:{itemId: string, moveTo: MoveTo}) {
    const data = await apiService.post<MoveTo>(Endpoint.MOVE_TO_ITEM(this.projectId, itemId), moveTo.getJsonObj())
    return this.handleResponse<MoveTo>(data, () => {
      const moveToLabel = moveTo.moveToLabel
      const alertContent: AlertContentItem[] = [
        {
          text: "Task ",
          type: "regular",
        },
        {
          text: `"${moveTo.title}" `,
          type: "bold",
        } as AlertContentItem,
        {
          text: "moved to ",
          type: "regular",
        } as AlertContentItem,
        {
          text: `${moveToLabel} `,
          type: "bold",
        } as AlertContentItem,
        {
          text: "as a new ",
          type: "regular",
        } as AlertContentItem,
        {
          text: `${moveTo.itemTypeParsed.label} `,
          type: "bold",
        } as AlertContentItem,
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    }, true,
    (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Error: ${data.message.split(":")[1]}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        content: alertContent,
        theme: "toast",
      })
    })
  }

  @Action
  [ItemActions.UPDATE_ITEM_VALUE](payload: UpdateObjectValue<Item>) {
    this.setItemValue(payload)
  }

  @Action
  [ItemActions.UPDATE_ITEM_VALUES]({ payload, updateClone } : { payload: UpdateObjectValue<Item>[], updateClone?: boolean }) {
    this.setItemValues({ payload, updateClone })
  }

  @Action
  [ItemActions.SET_ITEM](item: Item) {
    this.setItem(item)
  }

  @Action
  [ItemActions.RESET_ITEM]() {
    this.resetItem()
  }

  @Action
  [ItemActions.PUT_ITEM](item: Item) {
    this.setItem(Item.create(item))
    this.setItemClone(Item.create(item))

    if (this.items.map(i => i.id).includes(item?.id)) {
      this.replaceItem(Item.create(item))
    }
  }

  @Action
  [ItemActions.UPDATE_ITEM_CLONE](itemField: string) {
    this.updateItemClone(itemField)
  }

  @Action
  [ItemActions.REMOVE_DEFINITION](taskType: TaskType) {
    this.removeDefinition(taskType)
  }

  @Action
  [ItemActions.UPDATE_ITEM_STATUS](status: ItemStatus) {
    this.setStatusToItem(status)
  }

  @Action
  [ItemActions.UPDATE_ITEM_STATUS_IN_LIST]({ status, itemId } : {status: ItemStatus, itemId: string}) {
    this.setStatusToItemList({ status, itemId })
  }

  @Action
  async [ItemActions.RESET_CLONE]() {
    this.resetItemClone()
  }

  @Action
  async [ItemActions.UPDATE_ITEM_IN_LIST]({ payload, itemId } : { payload: UpdateObjectValue<Item>, itemId: string}) {
    this.setItemInList({ payload, itemId })
  }

  @Action
  async [ItemActions.GET_BACKLOG_CREATED_ITEM](item: Item | null) {
    this.setbacklogCreatedItem(item)
  }

  @Action
  async [ItemActions.UPDATE_VALUE_FOR_BACKLOG_CREATED_ITEM](params: UpdateObjectValue<Item>) {
    this.setValueForBacklogCreatedItem(params)
  }

  @Action
  async [ItemActions.REMOVE_FILE_FROM_ITEM](attachmentId: string) {
    this.removeFileFromItem(attachmentId)
  }

  @Action
  async [ItemActions.UPDATE_ITEM_READINESS](payload) {
    const data = await apiService.patch<boolean>(Endpoint.UPDATE_ITEM_READINESS(this.projectId, payload.item.id), undefined, {
      params: { ready: payload.ready },
    })
    return this.handleResponse<boolean>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: `${payload.item.itemTypeParsed?.label ?? ""}`,
          type: "bold",
        } as AlertContentItem,
        {
          text: ` #${payload.item.sequenceNumber}`,
          type: "bold",
        },
        {
          text: `  is marked as${data ? " " : " not "}Ready`,
          type: "regular",
        } as AlertContentItem,
      ]

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }
}

export const itemModule = new ItemModule({ store, name: Modules.ITEM })
