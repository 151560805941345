<template>
  <BaseSubHeading
    size="extra-small"
    weight="600"
    className="story-point"
  >
    {{ storyPoint }}
  </BaseSubHeading>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

const components = {}

@Options({
  name: "StoryPoint",
  components,
})

export default class StoryPoint extends Vue {
  @Prop({ default: null }) readonly value: number | null

  get storyPoint() {
    return this.value !== null ? this.value : "-"
  }
}
</script>
