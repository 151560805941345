import { Module, Mutation, Action } from "vuex-class-modules"

import { store } from "../store"
import { Endpoint } from "@/services/endpoints"
import { apiService } from "@/services/api.service"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { Account } from "@/models/Account"
import { User } from "@/models/User"
import { Team } from "@/models/Team"
import { Project } from "@/models/Project"
import { CustomError } from "@/models"

import { AccountActions, accountModule } from "./account.module"
import { TeamActions, teamModule } from "./team.module"
import { ProjectActions, projectModule } from "./project.module"
import { SprintActions, sprintModule } from "./sprint.module"


export enum SessionActions {
  GET_SESSION_ACCOUNT_ID = "GET_SESSION_ACCOUNT_ID",
  GET_SESSION_ACCOUNT = "GET_SESSION_ACCOUNT",
  RESET_SESSION_ACCOUNT = "RESET_SESSION_ACCOUNT",
  RESOLVE_SESSION_ACCOUNT = "RESOLVE_SESSION_ACCOUNT",
  GET_ACCOUNT_OVERVIEW = "GET_ACCOUNT_OVERVIEW",
  GET_SESSION_TEAM = "GET_SESSION_TEAM",
  SET_SESSION_TEAM = "SET_SESSION_TEAM",
  GET_SESSION_TEAM_ID = "GET_SESSION_TEAM_ID",
  RESOLVE_SESSION_TEAM_ID = "RESOLVE_SESSION_TEAM_ID",
  GET_SESSION_PROJECT_ID = "GET_SESSION_PROJECT_ID",
  RESOLVE_SESSION_PROJECT_ID = "RESOLVE_SESSION_PROJECT_ID",
  RESET_SESSION_TEAM = "RESET_SESSION_TEAM",
  RESET_SESSION_PROJECT = "RESET_SESSION_PROJECT",
  GET_SESSION_ITEM_ID = "GET_SESSION_ITEM_ID",
  RESET_SESSION_ITEM_ID = "RESET_SESSION_ITEM_ID",
  RESOLVE_ITEM_ID = "RESOLVE_ITEM_ID",
  RESOLVE_SESSION_SPRINT_ID = "RESOLVE_SESSION_SPRINT_ID",
  GET_SESSION_SPRINT_ID = "GET_SESSION_SPRINT_ID",
  RESET_SESSION_SPRINT_ID = "RESET_SESSION_SPRINT_ID",
  SET_SESSION_SPRINT_ID = "SET_SESSION_SPRINT_ID",
}

@Module
class SessionModule extends BaseModule {
  account: Account | null = null
  accountId: string

  accountOverview: User

  team: Team | null = null
  teamId: string
  projectId: string

  itemId: string

  sprintId: string

  // Current Account
  get [`get/${SessionActions.GET_SESSION_ACCOUNT_ID}`]() {
    return this.accountId
  }

  get [`get/${SessionActions.GET_SESSION_ACCOUNT}`]() {
    return this.account
  }

  @Mutation
  setAccountId(accountId: string) {
    this.accountId = accountId
  }

  @Mutation
  setAccount(account: Account | null) {
    this.account = account
  }

  @Action
  async [SessionActions.GET_SESSION_ACCOUNT](id: string) {
    const data = await accountModule[AccountActions.GET_ACCOUNT](id)
    const account = Account.create(data)
    this.setAccount(account)
    return account
  }

  @Action
  async [SessionActions.RESOLVE_SESSION_ACCOUNT](name: string) {
    const data = await apiService.get<string>(Endpoint.ACCOUNT_RESOLVE(), {
      name,
    })
    if (!data) {
      throw new CustomError({
        message: `Unknown account name: ${name}`,
        code: 404,
      })
    }
    return this.handleResponse<string>(data, (data) => {
      this.setAccountId(data)
      accountModule[AccountActions.SET_ACCOUNT_ID](data)
      this[SessionActions.GET_SESSION_ACCOUNT](data)
      return this.accountId
    },
    true, () => {
      throw new CustomError({
        message: `Unknown account name: ${name}`,
        code: 404,
      })
    })
  }

  // Current Account Overview
  get [`get/${SessionActions.GET_ACCOUNT_OVERVIEW}`]() {
    return this.accountOverview
  }

  @Mutation
  setAccountOverview(accountOverview: User) {
    this.accountOverview = accountOverview
  }

  // Current Team
  get [`get/${SessionActions.GET_SESSION_TEAM}`]() {
    return this.team
  }
  get [`get/${SessionActions.GET_SESSION_TEAM_ID}`]() {
    return this.teamId
  }

  @Mutation
  setTeamId(teamId: string) {
    this.teamId = teamId
  }

  @Mutation
  setTeam(team: Team | null) {
    this.team = team
  }

  @Action
  async [SessionActions.SET_SESSION_TEAM](team: Team) {
    this.setTeam(team)
    this.setTeamId(team.id)
  }

  @Action
  async [SessionActions.RESET_SESSION_ACCOUNT]() {
    this.setAccount(null)
  }

  @Action
  async [SessionActions.RESOLVE_SESSION_TEAM_ID]({ name, onlyGet } : { name: string, onlyGet?: boolean }) {
    const data = await apiService.get<string>(Endpoint.TEAM_RESOLVE(this.accountId), {
      name,
    })
    if (!data) {
      throw new CustomError({
        message: `Unknown team name: ${name}`,
        code: 404,
      })
    }

    // review this async await (maybe not needed)
    return this.handleResponse<string>(data, async (data) => {
      if (!onlyGet) {
        this.setTeamId(data)
        teamModule[TeamActions.SET_CURRENT_TEAM_ID](data) // backwards compatibility
        await this[SessionActions.GET_SESSION_TEAM](data)
      }
      return data
    })
  }

  @Action
  async [SessionActions.GET_SESSION_TEAM](id: string) {
    const data = await teamModule[TeamActions.TEAM_WITH_EXTENDED_USERS]({
      teamId: id,
    })
    this.setTeam(data)
  }

  @Action
  [SessionActions.RESET_SESSION_TEAM]() {
    if (this.team?.id) {
      this.setTeam(null)
      this.setTeamId("")
    }
  }


  // Current Project
  get [`get/${SessionActions.GET_SESSION_PROJECT_ID}`]() {
    return this.projectId
  }

  @Mutation
  setProjectId(projectId: string) {
    this.projectId = projectId
  }

  @Action
  async [SessionActions.RESOLVE_SESSION_PROJECT_ID]({ name, onlyGet }:{ name: string, onlyGet?: boolean }) {
    const data = await apiService.get<string>(Endpoint.PROJECT_RESOLVE(this.accountId), {
      name,
    })
    if (!data) {
      throw new CustomError({
        message: `Unknown project name: ${name}`,
        code: 404,
      })
    }
    return this.handleResponse<string>(data, (data) => {
      if (!onlyGet) {
        this.setProjectId(data)
        projectModule[ProjectActions.SET_CURRENT_PROJECT_ID](data) // backwards compatibility
      }
      return data
    })
  }

  @Action
  [SessionActions.RESET_SESSION_PROJECT]() {
    this.setProjectId("")
    projectModule[ProjectActions.CREATE_INITIAL_PROJECT]
  }

  // Current Item
  get [`get/${SessionActions.GET_SESSION_ITEM_ID}`]() {
    return this.itemId
  }

  @Mutation
  setItemId(itemId: string) {
    this.itemId = itemId
  }

  @Action
  async [SessionActions.RESOLVE_ITEM_ID]({ sequenceNumber, onlyGet }:{ sequenceNumber: number, onlyGet?: boolean }) {
    const data = await apiService.get<string>(Endpoint.RESOLVE_ITEM_ID(this.projectId, sequenceNumber))

    return this.handleResponse<string>(data, (itemId) => {
      if (!itemId) {
        throw new CustomError({
          message: `Unknown item number: ${sequenceNumber}`,
          code: 404,
        })
      }
      if (!onlyGet) {
        this.setItemId(itemId)
      }
      return itemId
    },
    true, (data) => {
      const code = data.response.status
      let message = ""
      switch (code) {
        case 403:
          message = "Access denied to the requested resource"
          break
        default:
          `Unknown item number: ${sequenceNumber}`
          break
      }
      throw new CustomError({
        code,
        message,
      })
    })
  }

  // Sprint Id
  get [`get/${SessionActions.GET_SESSION_SPRINT_ID}`]() {
    return this.sprintId
  }

  @Mutation
  setSprintId(sprintId: string) {
    this.sprintId = sprintId
  }


  @Action
  [SessionActions.RESET_SESSION_ITEM_ID]() {
    this.setItemId("")
  }

  @Action
  [SessionActions.RESET_SESSION_SPRINT_ID]() {
    this.setSprintId("")
    sprintModule[SprintActions.SET_CURRENT_SPRINT_ID]("")
  }

  @Action
  [SessionActions.SET_SESSION_SPRINT_ID](sprintId: string) {
    this.setSprintId(sprintId)
    sprintModule[SprintActions.SET_CURRENT_SPRINT_ID](sprintId)
  }

  @Action
  async [SessionActions.RESOLVE_SESSION_SPRINT_ID](payload: { sequenceNumber: number, teamId: string }) {
    if (!Number(payload.sequenceNumber)) return
    const data = await apiService.get<string>(Endpoint.RESOLVE_SPRINT_ID(this.projectId, payload.teamId, payload.sequenceNumber))

    if (!data) {
      throw new CustomError({
        message: `Unknown sprint number: ${payload.sequenceNumber}`,
        code: 404,
      })
    }

    return this.handleResponse<string>(data, (data) => {
      this.setSprintId(data)
      sprintModule[SprintActions.SET_CURRENT_SPRINT_ID](data) // backwards compatibility
      return this.sprintId
    })
  }
}

export const sessionModule = new SessionModule({
  store,
  name: Modules.SESSION,
})
