import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@icon/close_with_stroke.svg'


const _hoisted_1 = {
  key: 1,
  class: "avatar__img-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "avatar__gap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_ImageComponent = _resolveComponent("ImageComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", [
      'is-'+_ctx.size,
      {'is-bordered': _ctx.bordered || _ctx.isDisableUserDefaultAvatarShow},
      {'is-gap': !_ctx.hasSrc && !_ctx.isDisableUserDefaultAvatarShow},
    ]])
  }, [
    (_ctx.isDisableUserDefaultAvatarShow)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          class: "avatar__disabledUser-picture avatar__default-picture",
          data: _ctx.defaultPictureList.disabledUser,
          fill: _ctx.defaultPicture.fill,
          color: _ctx.defaultAvatarColor,
          type: _ctx.type
        }, null, 8, ["data", "fill", "color", "type"]))
      : (_ctx.hasSrc || _ctx.gap)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (_ctx.hasSrc)
              ? (_openBlock(), _createBlock(_component_ImageComponent, {
                  key: 0,
                  src: _ctx.src,
                  alt: "avatar",
                  class: "avatar__img",
                  onError: _ctx.onErrorHandler
                }, null, 8, ["src", "onError"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.gap), 1))
          ]))
        : (_openBlock(), _createBlock(_component_icon, {
            key: 2,
            class: "avatar__default-picture",
            data: _ctx.defaultPicture.data,
            fill: _ctx.defaultPicture.fill,
            color: _ctx.defaultAvatarColor,
            type: _ctx.type
          }, null, 8, ["data", "fill", "color", "type"])),
    (_ctx.showRemoveButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          type: "button",
          class: "avatar__remove",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeAvatar && _ctx.removeAvatar(...args)), ["stop"]))
        }, [
          _createVNode(_component_icon, {
            data: _imports_0,
            original: "",
            fill: false,
            width: "1em",
            height: "1em"
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}