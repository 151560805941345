import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "avatar-group" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.croppedAvatarsByLength, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: _normalizeStyle(`z-index: ${index}`),
        class: "avatar-group__item"
      }, [
        (_ctx.team)
          ? (_openBlock(), _createBlock(_component_Avatar, {
              key: 0,
              src: item?.picture?.pictureUrl,
              type: _ctx.type,
              bordered: ""
            }, null, 8, ["src", "type"]))
          : _createCommentVNode("", true),
        (_ctx.user)
          ? _withDirectives((_openBlock(), _createBlock(_component_Avatar, {
              key: 1,
              userEnabled: item ? item.enabled : true,
              src: item?.pictureUrl ?? null,
              gap: item?.getInitials(),
              type: _ctx.type,
              class: _normalizeClass(["avatar-group__item", [
          {'avatar-select__gap avatar is-md': !item},
          {'is-scale-by-hover': _ctx.scaleByHover}
        ]]),
              bordered: ""
            }, null, 8, ["userEnabled", "src", "gap", "type", "class"])), [
              [
                _directive_tooltip,
                { content: item?.username, theme: 'info-tooltip-sm' },
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true)
      ], 4))
    }), 128)),
    (_ctx.lengthOfHiddenAvatars)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(`z-index: ${_ctx.numberOfVisibleUsers + 1}`),
          class: "avatar is-md is-bordered avatar-group__item avatar__length"
        }, " +" + _toDisplayString(_ctx.lengthOfHiddenAvatars), 5))
      : _createCommentVNode("", true),
    (!_ctx.croppedAvatarsByLength.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            src: null,
            type: _ctx.type,
            bordered: ""
          }, null, 8, ["type"])
        ]))
      : _createCommentVNode("", true)
  ]))
}