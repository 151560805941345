import { Notification } from "./Notification"
import { $t } from "@/plugins/i18n"
import { Status } from "../Status"
import { replaceHtmlTagsV2 } from "@/utils/helpers"

export class NotificationItem extends Notification {
  constructor(props) {
    super(props)
  }

  get item() {
    return this.content?.item
  }

  get items() {
    return this.content?.items
  }

  get tasks() {
    return this.content?.tasks ?? []
  }

  get taskNames() {
    return this.tasks.map(x => x.name.replace(/<\/?[a-zA-Z]+>/gi, ""))
  }

  get oldItem() {
    return this.content?.oldItem
  }

  get initiatorFullName() {
    return this.content?.initiator.firstName + " " + this.content?.initiator.lastName
  }

  get itemSequenceNumber() {
    return `#${this.item?.sequenceNumber ?? $t("none")}`
  }

  get itemUrl() {
    return this.item?.url
  }

  get changeStatusReason() {
    const rawContent = this.content?.changeStatusReason ?? ""
    return replaceHtmlTagsV2(rawContent)
  }

  getStatus(id) {
    if (!id) return $t("none")

    return Status.createById({
      id,
    })
  }

  getOldItemValue(value) {
    return this.oldItem?.[value] ?? $t("none")
  }

  getItemValue(value) {
    return this.item?.[value] ?? $t("none")
  }
}
