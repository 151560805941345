<template>
  <div
    ref="observer"
    style="display: block; height: 1px; margin-top: -1px;"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"

@Options({
  name: "ObserverComponent",
})

export default class ObserverComponent extends Vue {
  @Prop() options: IntersectionObserver
   @Prop({
     default: true,
   }) last: boolean

  @Ref("observer") readonly observerEl!: HTMLElement

  observer: IntersectionObserver

  mounted() {
    this.init()
  }

  beforeUnmount() {
    this.observer.disconnect()
  }

  init() {
    const options = this.options || {}
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        if (!this.last) {
          this.$emit("intersect")
        }
      }
    }, options)
    if (!this.observerEl) return
    this.$nextTick(() => {
      this.observer.observe(this.observerEl)
    })
  }
}
</script>
