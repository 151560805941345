import { Node, mergeAttributes, textInputRule } from '@tiptap/core';
import { PasteRule, VueNodeViewRenderer } from '@tiptap/vue-3';
import Suggestion from '@tiptap/suggestion'
import { v4 as uuidv4 } from "uuid"
import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import { Project, User, Milestone, Epic, Team, Sprint, Item, Task } from '@/models';
import { debounce } from "debounce";
import { PluginKey } from 'prosemirror-state';
import { parsingProjectIdAndItemNumber } from '@/utils/helpers';
import { router } from '@/router';
import { ReferenceType } from './ReferenceType';
import { dropFilePlugin } from '@/models'
import { nextTick } from 'vue';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    reference: {
      setReference: (id: string, attrs: object) => ReturnType,
      getReferenceType: () => string,
    }
  }
}

export const ReferenceConfig = {
  [ReferenceType.USER]: {
    char: "@",
    label: "fullName",
    type: "referenceUser",
    extensionName: "referenceAt",
    renderTitle(user: User): string {
      return `${this.char}${user[this.label]}`
    }
  },
  [ReferenceType.PROJECT]: {
    char: "#P",
    label: "name",
    type: "referenceProject",
    extensionName: "referenceHash",
    renderTitle: function(project: Project): string {
      return `${this.char} - ${project[this.label]}`
    }
  },
  [ReferenceType.MILESTONE]: {
    char: "#M",
    label: "title",
    type: "referenceMilestone",
    extensionName: "referenceHash",
    renderTitle: function(milestone: Milestone): string {
      return `${this.char} - Milestone ${milestone[this.label]}`
    }
  },
  [ReferenceType.EPIC]: {
    char: "#E",
    label: "name",
    type: "referenceEpic",
    extensionName: "referenceHash",
    renderTitle: function(epic: Epic): string {
      return `${this.char} - ${epic.sequenceNumber} ${epic[this.label]}`
    }
  },
  [ReferenceType.TEAM]: {
    char: "#T",
    label: "name",
    type: "referenceTeam",
    extensionName: "referenceHash",
    renderTitle: function(team: Team): string {
      return `${this.char} - ${team[this.label]}`
    }
  },
  [ReferenceType.SPRINT]: {
    char: "#S",
    label: "sequenceNumber",
    type: "referenceSprint",
    extensionName: "referenceHash",
    renderTitle: function(sprint: Sprint): string {
      return `Sprint #${sprint[this.label]}`
    }
  },
  [ReferenceType.ITEM]: {
    char: "#",
    label: "sequenceNumber",
    type: "referenceItem",
    extensionName: "referenceHash",
    renderTitle: function(item: Item): string {
      return `#${item[this.label]} - ${item.title}`
    }
  },
  [ReferenceType.TASK]: {
    char: "#",
    label: "sequenceNumber",
    type: "referenceTask",
    extensionName: "referenceHash",
    renderTitle: function(task: Task, item: Item): string {
      const { sequenceNumber} = item

      return `#${sequenceNumber}.${task.order} - ${task.name}`
    }
  },
  [ReferenceType.FILE]: {
    char: "#",
    label: "",
    type: "",
    extensionName: "referenceHashFile",
    renderTitle: function(): string {
      return ""
    }
  },
  [ReferenceType.TIP]: {
    char: "#",
    label: "",
    type: "",
    extensionName: "referenceHash",
    renderTitle: function(): string {
      return ``
    }
  },
}

export const getTypeByText = (search) => {
  if (/@/.test(search)) {
    return ReferenceType.USER
  }

  if (/#[0-9]+($| )/.test(search)) {
    return ReferenceType.ITEM
  } else if (/#[0-9]+\./.test(search) || /#[0-9]+\.[0-9]+/.test(search)) {
    return ReferenceType.TASK
  } else if (/#P/i.test(search)) {
    return ReferenceType.PROJECT
  } else if (/#M/i.test(search)) {
    return ReferenceType.MILESTONE
  } else if (/#E/i.test(search)) {
    return ReferenceType.EPIC
  } else if (/#T/i.test(search)) {
    return ReferenceType.TEAM
  } else if (/#S/i.test(search)) {
    return ReferenceType.SPRINT
  } else if (/#F/i.test(search)) {
    return ReferenceType.FILE
  } else {
    return ReferenceType.TIP
  }
}

export const getTypeByUrl = (url: URL) => {
  const route = router.resolve(url.pathname + url.search)

  let {
    projectId: projectName, teamId: teamName, itemId: itemNumber,
    epicId: epicNumber, index: milestone, sprintId: sequenceNumber
  } = route.params

  let { task: taskNumber } = route.query

  if(route.name === 'Item') {
    const result = parsingProjectIdAndItemNumber(projectName + "-" + itemNumber)
    projectName = result.projectId
    itemNumber = result.itemId;
  }
  switch (route.name) {
    case "project":
      return {
        type: ReferenceType.PROJECT,
        name: projectName
      }
    case "create-team":
      return {
        type: ReferenceType.TEAM,
        name: teamName
      }
    case "Milestones":
      return {
        type: ReferenceType.MILESTONE,
        number: milestone
      }
    case "epic":
      return {
        type: ReferenceType.EPIC,
        number: epicNumber
      }
    case "sprint":
      return {
        type: ReferenceType.SPRINT,
        number: sequenceNumber
      }
    case "Item": {
      return taskNumber ?
      {
        type: ReferenceType.TASK,
        number: taskNumber
      } :
      {
        type: ReferenceType.ITEM,
        number: itemNumber
      }
    }
  }

  if(route.name === 'backlog' && route.query.i) {
    return {
      type: ReferenceType.ITEM,
      number: route.query.i
    }
  }
}

const isExtensionExist = (editor: any, type: ReferenceType) => {
  return editor.options.extensions.find(extension => extension.name === ReferenceConfig[type].extensionName)
}


export const ReferenceExtension = Node.create({
  name: 'reference',
  inline: true,
  group: 'inline',
  atom: true,
  draggable: true,

  addOptions() {
    return {
      suggestion: {
        pluginKey: new PluginKey(this.name),
        allowSpaces: false,
        render: () => {
          let component
          let popup
          let type

          return {
            onStart: props => {
              const { items, editor, clientRect } = props
              if(editor && (editor.isActive('codeBlock') || editor.isActive('link'))) return
              
              type = getTypeByText(props.text) ?? ReferenceType.TIP

              if (!isExtensionExist(editor, type)) return

              if (items?.component) {
                component = new VueRenderer(items.component, {
                  props: Object.assign(props, { componentType: type }),
                  editor
                })

                popup = tippy('body', {
                  getReferenceClientRect: clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  maxWidth: 380,
                  placement: 'bottom-start',
                })

                if (popup && popup[0] && !popup[0].state.isShown) {
                  popup[0].show()
                }
              }
            },

            onUpdate: debounce((props) => {
                if(props?.editor && (props.editor.isActive('codeBlock') || props.editor.isActive('link')) || !props.clientRect()) return
                if (props?.editor && !isExtensionExist(props.editor, getTypeByText(props.text))) return

                if (type !== getTypeByText(props.text)) {
                  type = getTypeByText(props.text)
                  if (popup && popup[0]) {
                    popup[0].destroy()
                  }
                  if (props?.items?.component) {
                    component = new VueRenderer(props.items.component, {
                      props: Object.assign(props, {componentType: type}),
                      editor: props.editor
                    })

                    popup = tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      maxWidth: 380,
                      placement: 'bottom-start',
                    })
                  }
                }


                if (popup && popup[0]) {
                  if (!popup[0].state.isShown) {
                    popup[0].show()
                  }
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  })

                  nextTick(() => {
                    if (component?.ref?.queryUpdated) {
                      component.ref.queryUpdated(props.query);
                    }
                  })
                }
            }, 500),

            onKeyDown(props) {
              if (props?.editor && (props.editor.isActive('codeBlock') || props.editor.isActive('link'))) return
              if (props?.editor && !isExtensionExist(props.editor, getTypeByText(props.text))) return

              if (props.event.key === 'Escape') {
                popup[0].hide()
                return true
              }

              if (component?.ref && typeof component?.ref?.onKeyDown === "function") {
                return component?.ref?.onKeyDown(props) ?? false
              }
            },

            onExit() {
              if (popup && popup[0]) {
                popup[0].destroy()
              }
              if (component) {
                component.destroy()
              }
            },
          }
        },
        command: ({ editor, props }) => {
          if(!props?.configType || !editor.view.state) return

          const extensionName = ReferenceConfig[props.configType]?.extensionName

          if(!extensionName) return

          const stateKeys = Object.keys(editor.view.state)
          const fieldName = stateKeys.find(key => key.includes(extensionName))

          if(!fieldName) return

          const extension = editor.view.state[fieldName]
          
          if(!extension) return

          const deleteFrom = extension.range?.from ?? 0
          const deleteTo = extension.range?.to ?? 0

          if(!deleteTo) return
          editor
            .chain()
            .deleteRange({ from: deleteFrom, to: deleteTo })
            .insertContent([{ type: this.name, attrs: Object.assign(props, { id: uuidv4() }) }, { type: 'text', text: ' ' }])
            .run()
          editor.view.focus()
        },
      },
    }
  },

  addProseMirrorPlugins() {
    const plugins = [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]

    if (this.name === "referenceHashFile") {
      plugins.push(dropFilePlugin({
        editor: this.editor
      }))
    }

    return plugins
  },

  addCommands() {
    return {
      setReference: (title: string) => ({ commands }) => commands.setNode(this.name, { title }),
      getReferenceType: () => this.name,
    };
  },

  addAttributes() {
    return {
      id: { default: "" },
      loading: { default: false },
      showDetails: { default: false },
      type: { default: "" },
      configType: { default: "" },
      info: { default: "" },
      linkText: { default: null },
      linkHref: { default: "" },
      originUrl: { default: "" },
      title: { default: null },
      ext: { default: 'img' },
      width: {
        default: 300,
      },
      height: {
        default: 300
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'reference',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    HTMLAttributes.type = ReferenceConfig[HTMLAttributes.configType].type;
    const data = window.btoa(encodeURI(JSON.stringify(HTMLAttributes)));

    return ['reference', mergeAttributes(HTMLAttributes, { data })]
  },

  addNodeView() {
    return VueNodeViewRenderer(this.options.component)
  },

  renderText({ node }) {
    const { linkText } = node.attrs;
    return linkText;
  },

  addInputRules() {
    return [
      textInputRule({ find: /-___-$/, replace: '😑 ' }),
    ]
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: new RegExp(this.options.pasteRules, "g"),
        handler: ({ state, range, match }) => {
          const { tr } = state

          if (match[0]) {
            if (this.options.suggestion.pluginKey?.key?.includes("referenceLink")) {
              const paramsFromUrl = window.location.pathname.split('/');
              const url = new URL(match[0]);
              const params = url.pathname.split('/');

              if (params[1] === paramsFromUrl[1]) {
                const config = getTypeByUrl(url);
                if (config?.type) {
                  const type = ReferenceConfig[config.type].type
                  let linkText = url.pathname + (config.type === ReferenceType.TASK ? url.search : "")
                  if (config.type === ReferenceType.ITEM && url.pathname.includes("backlog")) {
                    linkText = `${url.pathname.split('/').slice(0, -1).join('/')}/items/${config.number}`
                  }
                  const node = this.type.create({
                    loading: true,
                    configType: config.type,
                    type,
                    linkText,
                    originUrl: url.origin + url.pathname + url.search
                  });

                  tr.replaceWith(
                    range.from,
                    range.to,
                    node
                  )
                }
              }
            } else {
              const configType = getTypeByText(match[0]);
              const type = ReferenceConfig[configType].type;
              const node = this.type.create({
                loading: true,
                configType,
                type,
                linkText: match[0].trim()
              });

              tr.replaceWith(
                range.from,
                range.to,
                node
              )
            }
          }
        },
      }),
    ]
  },
});


