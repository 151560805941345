import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gradient-bar__bg" }
const _hoisted_2 = { class: "gradient-bar-tooltip__content" }
const _hoisted_3 = { class: "body-text-large-400 gradient-bar-tooltip__left-text" }
const _hoisted_4 = { class: "body-text-large-400" }
const _hoisted_5 = {
  key: 1,
  class: "gradient-bar-tooltip"
}
const _hoisted_6 = { class: "gradient-bar__bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!

  return (_ctx.showTooltip && _ctx.status!=='NOT_STARTED')
    ? (_openBlock(), _createBlock(_component_DropdownTooltip, {
        key: 0,
        theme: "info-tooltip",
        triggers: ['hover'],
        hideTriggers: ['hover'],
        popperHideTriggers: ['hover'],
        popperShowTriggers: ['hover'],
        distance: 0,
        placement: "top",
        popperClass: "gradient-bar-popper",
        class: "gradient-bar-tooltip"
      }, {
        "tooltip-label": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["gradient-bar", {'all-sprints__sprint-health_not-started': _ctx.status==='NOT_STARTED'}])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("dt", {
                style: _normalizeStyle({ left: _ctx.percent + '%' })
              }, null, 4)
            ])
          ], 2)
        ]),
        "tooltip-content": _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.remaining) + " Days remaining ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.timeLeft) + " hr Time left ", 1)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["gradient-bar", {'all-sprints__sprint-health_not-started': _ctx.status==='NOT_STARTED'}])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("dt", {
              style: _normalizeStyle({ left: _ctx.percent + '%' })
            }, null, 4)
          ])
        ], 2)
      ]))
}