import { Status } from "@/models/Status"
import { Team } from "./Team"

export type EpicStatus = "ALL" | "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED" | "ARCHIVED"

const epicStatuses = [
  {
    id: "ALL",
    label: "All",
  },
  {
    id: "NOT_STARTED",
    label: "Not Started",
  },
  {
    id: "IN_PROGRESS",
    label: "In Progress",
  },
  {
    id: "COMPLETED",
    label: "Completed",
  },
  {
    id: "ARCHIVED",
    label: "Aarchived",
  },
] as Status[]

export interface AddEpicStory {
  inEpic: boolean,
  epicIds: string[]
}
export interface tShort {
  id: string,
  shortLabel?: string,
  label?: string
}

export interface CreateEpicInMilestone {
  previousItemId?: string
  nextItemId?: string
  lowest?: boolean
  milestoneId: string
}

export interface CreateEpic {
  name: string
  size?: string
  label?: string
  projectId?: string
  createEpicPriorityInMilestoneDto?: CreateEpicInMilestone
  teamId?: string
}

export class Epic {
  id: string
  name: string
  label?: string | null
  roadMapPriority: number
  milestonePriority: number
  status: EpicStatus
  parsedStatus: Status
  sequenceNumber: number
  teams: Team[]
  totalStoryPoints: number
  itemsCount: number
  percentComplete: number
  size?: string | null
  createEpicPriorityInMilestoneDto?: CreateEpicInMilestone | null
  teamId?: string
  doneItemsCount: number
  constructor(props?: Epic) {
    this.setDefaultData()
    if (!props) return

    this.id = props.id ?? ""
    this.name = props.name ?? ""
    this.roadMapPriority = props.roadMapPriority ?? 0
    this.milestonePriority = props.milestonePriority ?? 0
    this.status = props.status ?? "NOT_STARTED"
    this.parsedStatus = this.getParsedStatus(props.status ?? "NOT_STARTED")
    this.sequenceNumber = props.sequenceNumber ?? 1
    this.teams = props.teams ?? []
    this.totalStoryPoints = props.totalStoryPoints ?? 0
    this.itemsCount = props.itemsCount ?? 0
    this.percentComplete = props.percentComplete ?? 0
    this.size = props.size ?? null
    this.label = props.label ?? null
    this.createEpicPriorityInMilestoneDto = props.createEpicPriorityInMilestoneDto ?? null
    this.teamId = props.teamId ?? ""
    this.doneItemsCount = props.doneItemsCount ?? 0
  }

  setDefaultData() {
    this.id = ""
    this.name = ""
    this.roadMapPriority = 0
    this.milestonePriority = 0
    this.status = "NOT_STARTED"
    this.parsedStatus = this.getParsedStatus("NOT_STARTED")
    this.sequenceNumber = 1
    this.totalStoryPoints = 0
    this.itemsCount = 0
    this.percentComplete = 0
    this.label = null
    this.doneItemsCount = 0
    this.teamId = ""
  }

  getJsonObj() {
    return {
      name: this.name,
      size: this.size,
      label: this.label,
      createEpicPriorityInMilestoneDto: this.createEpicPriorityInMilestoneDto,
      teamId: this.teamId,
    }
  }

  static create(props: Epic): Epic {
    return new Epic(props)
  }

  static createDefault(): Epic {
    return new Epic()
  }

  static get statuses(): Status[] {
    return epicStatuses
  }

  get isCompleted() {
    return this.parsedStatus.id === "COMPLETED"
  }

  getParsedStatus(id: string) {
    const epic = Epic.statuses.find((status: Status) => status.id === id)
    return epic ? epic : { id: "", label: "" } as Status
  }
}
