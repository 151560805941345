import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"
import { Team } from "@/models/Team"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  await store.dispatch(Actions.RESET_ITEM)
  const sessionItemId = store.getters[Getters.GET_SESSION_ITEM_ID]
  if (!sessionItemId) {
    return next()
  }
  const sessionItem = await store.dispatch(Actions["GET_ITEM"], {
    itemId: sessionItemId,
  })

  if (to.query.showStoryPoker && sessionItem.team) {
    const currentProfile = store.getters[Getters.GET_USER_CURRENT_PROFILE]
    const currentTeam: Team = await store.dispatch(Actions.TEAM_WITH_EXTENDED_USERS, {
      teamId: sessionItem.team.id,
      onlyGet: true,
    })
    const checkUserInTeam = currentTeam.users.find(user => user.id === currentProfile.id)
    if (!checkUserInTeam) {
      return next({
        name: "Error",
        query: {
          code: 404,
        },
      })
    }
  }

  next()
}
