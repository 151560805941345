<template>
  <div class="modal-chat-gpt__content">
    <template v-if="content.length === 1">
      {{ content[0].join(" ") }}
    </template>
    <template v-else-if="content[0].length === 1">
      <ol>
        <li
          v-for="(item, index) in content"
          :key="index"
        >
          {{ item[0] }}
        </li>
      </ol>
    </template>
    <template v-else>
      <table>
        <tr
          v-for="(row, index) in content"
          :key="index"
        >
          <td>{{ index }}</td>
          <td
            v-for="(column, subindex) in row"
            :key="subindex"
            width="100"
            height="100"
          >
            {{ column }}
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "ChatGPTResponseContent",
  components: {
  },
})
export default class ChatGPTResponseContent extends Vue {
  @Prop({ required: true }) content: string[][]
}
</script>
