import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"
import _imports_0 from '@icon/plus.svg'


const _hoisted_1 = { class: "to-do__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_ToDoItem = _resolveComponent("ToDoItem")!
  const _component_Observer = _resolveComponent("Observer")!
  const _component_icon = _resolveComponent("icon")!
  const _component_ToDoAdd = _resolveComponent("ToDoAdd")!
  const _component_ToDoContent = _resolveComponent("ToDoContent")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createBlock(_component_ToDoContent, {
    class: _normalizeClass([_ctx.className, "to-do-view"])
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        level: "3",
        color: "monochrome-06",
        darkColor: "monochrome-07"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" ToDo List ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toDoMenu, (item) => {
          return (_openBlock(), _createBlock(_component_BaseSubHeading, {
            key: item.id,
            size: "large",
            weight: "400",
            class: _normalizeClass(["to-do__menu__item", { 'is-active': _ctx.selectedMenuItem === item.id }]),
            onClick: ($event: any) => (_ctx.onSelectMenuItem(item.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_Observer, {
        key: _ctx.selectedMenuItem,
        class: "to-do__item__list custom-scroll",
        items: _ctx.toDos,
        last: _ctx.isLast,
        options: _ctx.observerOptions,
        onIntersect: _ctx.scroll
      }, {
        default: _withCtx(({ item, index }) => [
          (_openBlock(), _createBlock(_component_ToDoItem, {
            key: item.id,
            item: item,
            index: index,
            sm: true,
            isSent: _ctx.selectedMenuItem === 'sent',
            onOnCheckboxClick: _ctx.onCheckboxClick,
            onOnEdit: _ctx.onEditToDo
          }, null, 8, ["item", "index", "isSent", "onOnCheckboxClick", "onOnEdit"]))
        ]),
        _: 1
      }, 8, ["items", "last", "options", "onIntersect"])), [
        [_directive_scroll, _ctx.handleScroll]
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: "link is-large to-do__add-button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('addToDo')), ["prevent"]))
      }, [
        _createVNode(_component_icon, {
          data: _imports_0,
          fill: true,
          width: "24",
          height: "24"
        }),
        _createTextVNode(" Add ToDo ")
      ]),
      _createVNode(_Transition, {
        onBeforeLeave: _ctx.onCloseToDoAnimation,
        onBeforeEnter: _ctx.onOpenToDoAnimation
      }, {
        default: _withCtx(() => [
          (_ctx.expandToDo)
            ? (_openBlock(), _createBlock(_component_ToDoAdd, {
                key: 0,
                className: "to-do__edit",
                onOnCancel: _ctx.onCloseToDo
              }, null, 8, ["onOnCancel"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onBeforeLeave", "onBeforeEnter"])
    ]),
    _: 1
  }, 8, ["class"]))
}