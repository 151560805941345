import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "progress-bar__header"
}
const _hoisted_2 = { class: "progress-bar__body" }
const _hoisted_3 = { class: "progress-bar__line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["progress-bar", [
      {'is-active': _ctx.isMore},
      `is-${_ctx.color}`
    ]])
  }, [
    (_ctx.showLabels)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              weight: "400",
              color: "monochrome-05",
              darkColor: "monochrome-03",
              className: "progress-bar__label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.desc.label), 1)
              ]),
              _: 1
            })
          ]),
          (_ctx.showInfo)
            ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                key: 0,
                size: "medium",
                weight: "400",
                color: "monochrome-07",
                className: "progress-bar__meta"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.desc.currentValue), 1),
                  _createTextVNode(" of "),
                  _createElementVNode("b", null, _toDisplayString(_ctx.desc.maxValue), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: "progress-bar__bg",
          style: _normalizeStyle({ width: _ctx.isMore ? '100%' : _ctx.lineWidth + '%'})
        }, null, 4)
      ])
    ])
  ], 2))
}