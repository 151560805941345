import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification__bug-watermark-line"
}
const _hoisted_2 = { class: "notification__bug-watermark-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeverityLevelIcon = _resolveComponent("SeverityLevelIcon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__bug-watermark"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["label-pic", [_ctx.labelColor]])
      }, [
        _createVNode(_component_SeverityLevelIcon, {
          level: _ctx.notification.level,
          width: "16px",
          height: "16px",
          class: "label-pic__icon"
        }, null, 8, ["level"])
      ], 2)
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Bug Watermark Exceeded ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.notification.breadcrumbs), 1)
        ]),
        _: 1
      }),
      (_ctx.notification.level)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BaseSubHeading, {
              size: "medium",
              color: "monochrome-07",
              darkColor: "monochrome-03",
              weight: "500",
              className: "notification__bug-watermark-left"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.notification.level.toLowerCase()), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BaseSubHeading, {
                size: "medium",
                color: "serviceAlert-02",
                weight: "600",
                className: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.levelValue.current), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_BaseSubHeading, {
                size: "medium",
                color: "monochrome-07",
                weight: "400",
                className: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("  of  ")
                ]),
                _: 1
              }),
              _createVNode(_component_BaseSubHeading, {
                size: "medium",
                color: "serviceAlert-02",
                weight: "600",
                className: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.levelValue.max), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["notification"]))
}