<script lang="ts">
import { h } from "vue"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"

type SubHeadingSize = "extra-large" | "large" | "medium" | "small" | "extra-small"
type SubHeadingWeight = "400" | "500" | "600"

@Options({
  name: "BaseSubHeading",
})
export default class extends Vue {
  @Prop() readonly size: SubHeadingSize = "medium"
  @Prop() readonly weight: SubHeadingWeight = "400"
  @Prop() readonly className: string = ""
  @Prop() readonly color: string = ""
  @Prop() readonly darkColor: string = ""

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  get styleObject() {
    if (!this.color) return {}

    let color = this.color

    if (this.isDark && this.darkColor) {
      color = this.darkColor
    }

    return {
      color: `var(--${color})`,
    }
  }

  render() {
    return h("div",
      {
        class: `body-text body-text-${this.size}-${this.weight} ${this.className ?? ""}`,
        style: this.styleObject,
      },
      this.$slots.default ? this.$slots.default() : "")
  }
}
</script>
