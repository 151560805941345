export class LogMetric {
  name: string
  value: number
  tags: { [key:string]: string }

  constructor(name, value, tags: { [key:string]: string }) {
    this.name = name
    this.value = value
    this.tags = tags
  }

  getJsonObj() {
    return {
      name: this.name,
      value: this.value,
      tags: this.tags,
    }
  }
}
