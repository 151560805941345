<template>
  <div class="avatar-group">
    <div v-if="!listOfVisibleUsers.length">
      <Avatar
        :src="null"
        :type="type"
        bordered
      />
    </div>
    <template v-else>
      <Avatar
        v-for="(item, index) in listOfVisibleUsers"
        :key="index"
        v-tooltip.bottom="{ content: item?.username, theme: 'info-tooltip-sm' }"
        :userEnabled="item ? item.enabled : true"
        :src="item?.picture?.pictureUrl"
        :gap="item?.getInitials()"
        :type="type"
        :style="`z-index: ${index}`"
        :class="[{'avatar-select__gap avatar is-md':!item}]"
        class="avatar-group__item is-scale-by-hover"
        bordered
      />

      <VDropdown
        v-if="listOfHideUsers.length && lengthOfHiddenAvatars > 0"
        v-click-outside="onHide"
        :showTriggers="['click']"
        :hideTriggers="[]"
        :popperTriggers="[]"
        :autoHide="false"
        :shown="isShowUserpicMenu"
        :style="`z-index: ${numberOfVisibleUsers + 1}`"
        class="avatar-group__item"
        popperClass="avatar-group__carousel-wrapper"
        placement="right"
        @applyShow="onToggle(true)"
        @hide="onHide"
      >
        <div
          ref="hiddenAvatarCountRef"
          class="avatar is-md is-bordered avatar-group__item avatar__length"
          :class="{ 'is-scale-by-hover' : !isShowUserpicMenu }"
          @click.stop="onHide"
        >
          <template v-if="!isShowUserpicMenu">
            +{{ lengthOfHiddenAvatars }}
          </template>
          <icon
            v-else
            data="@icon/chevron-blue.svg"
            class="avatar-group__arrow-pointer"
            width="1em"
            height="1em"
            dir="left"
            :fill="true"
          />
        </div>
        <template #popper>
          <div
            class="avatar-group__carousel"
          >
            <button
              ref="prevButtonRef"
              class="avatar-group__carousel__item"
              :class="{'is-disabled-button': !stepOfMovingUserPicList}"
              @click.stop="onPrevious"
            >
              <span class="avatar-group__carousel__arrow-icon">
                <icon
                  data="@icon/chevron-blue.svg"
                  width="1em"
                  height="1em"
                  dir="down"
                  :style="{ rotate: `${calculatedStyle[0]?.angle}deg` }"
                  :fill="true"
                />
              </span>
            </button>
            <div
              v-for="(item, index) in usersPicList"
              :key="index"
              ref="hiddenAvatarRefs"
              class="avatar-group__carousel__item is-avatar"
            >
              <Avatar
                v-tooltip.right="{ content: item?.username, theme: 'info-tooltip-sm' }"
                :userEnabled="item ? item.enabled : true"
                :src="item?.pictureUrl ?? null"
                :gap="item?.getInitials()"
                :type="type"
                bordered
              />
            </div>
            <button
              ref="nextButtonRef"
              :class="{'is-disabled-button': isLast}"
              class="avatar-group__carousel__item"
              @click.stop="onNext"
            >
              <span class="avatar-group__carousel__arrow-icon">
                <icon
                  data="@icon/chevron-blue.svg"
                  width="1em"
                  height="1em"
                  :fill="true"
                  :style="{ rotate: `${calculatedStyle.at(-1)?.angle}deg` }"
                />
              </span>
            </button>
          </div>
        </template>
      </VDropdown>
    </template>
  </div>
</template>

<script lang="ts">
import Avatar from "@/components/Avatar/index.vue"
import { Emit, Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { User } from "@/models"
import { ref } from "vue"
import { translateTransformAnimation } from "@/utils/gsapAnimation"
import { directive as ClickOutside } from "click-outside-vue3"
import { hideAllPoppers } from "floating-vue"

@Options({
  name: "AvatarGroupWithMenu",
  components: {
    Avatar,
  },
  directives: {
    ClickOutside,
  },
})

export default class AvatarGroupWithMenu extends Vue {
  @Prop({ required: true }) readonly type: string
  @Prop({ default: [] }) readonly avatars: User[]
  @Prop({ default: 3 }) readonly numberOfVisibleUsers: number
  hiddenAvatarRefs: any = ref([])
  hiddenAvatarCountRef: any = ref(null)
  prevButtonRef: any = ref()
  nextButtonRef: any = ref()
  maxCorner = 0
  avatarsGap = 10
  arcRadius = 155
  centerOffset = 64
  numberOfArrows = 2
  stepOfMovingUserPicList = 0
  listOfHideUsers: User[] = []
  isShowUserpicMenu = false
  get listOfVisibleUsers() {
    if (this.avatars.length <= this.numberOfVisibleUsers) return this.avatars
    this.listOfHideUsers = this.avatars.slice((this.numberOfVisibleUsers - 1))
    return this.avatars.slice(0, this.numberOfVisibleUsers - 1)
  }

  get lengthOfHiddenAvatars() {
    return this.avatars.length - this.listOfVisibleUsers.length
  }

  get usersPicList() {
    return this.listOfHideUsers.slice(this.stepOfMovingUserPicList, this.stepOfMovingUserPicList + 5)
  }

  get isLast() {
    return this.listOfHideUsers.at(-1)?.id === this.usersPicList.at(-1)?.id
  }

  get singleArcAngle() {
    const avatarSize = this.hiddenAvatarCountRef?.offsetWidth ?? 0
    const singleArcLength = avatarSize + this.avatarsGap
    return singleArcLength / this.arcRadius
  }

  get totalArcAngle() {
    const gapsCount = this.usersPicList.length + this.numberOfArrows - 1
    return gapsCount * this.singleArcAngle
  }

  onHide() {
    if (!this.isShowUserpicMenu) return
    this.stepOfMovingUserPicList = 0
    this.onToggle(false)
  }

  onNext() {
    if (this.isLast) return
    this.stepOfMovingUserPicList += 1
  }

  onPrevious() {
    if (!this.stepOfMovingUserPicList) return
    this.stepOfMovingUserPicList -= 1
  }

  get listOfrefElements() {
    let listOfrefElements = [...this.hiddenAvatarRefs]
    listOfrefElements.unshift(this.prevButtonRef)
    listOfrefElements.push(this.nextButtonRef)
    return listOfrefElements
  }

  get calculatedStyle() {
    return this.listOfrefElements.map((_, index) => {
      const angle = -this.totalArcAngle / 2 + index * this.singleArcAngle
      return {
        angle: angle * 180 / Math.PI,
        x: 32 - this.centerOffset + this.arcRadius * Math.cos(angle),
        y: this.arcRadius * Math.sin(angle),
      }
    })
  }

  @Emit()
  onToggle(value: boolean) {
    if (value) {
      hideAllPoppers()
    }
    this.listOfrefElements.forEach((element, index) => {
      const animation = translateTransformAnimation({
        element,
        x: this.calculatedStyle[index].x,
        y: this.calculatedStyle[index].y,
      })
      if (value) {
        this.isShowUserpicMenu = value
        animation.play()
      } else {
        animation.reverse(0).delay(0.1).eventCallback("onReverseComplete", () => {
          this.isShowUserpicMenu = value
        })
      }
    })
    return value
  }
}
</script>
