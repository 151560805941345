<template>
  <div
    class="to-do-assign__wrapper"
  >
    <Field
      as=""
      name="toDoOwner"
      :rules="rules"
      :modelValue="!!selectedUsers?.length"
      :validateOnBlur="false"
    >
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        darkColor="monochrome-03"
      >
        Assign to:
      </BaseSubHeading>
      <VDropdownWithKeyboardNavigation
        :triggers="['click']"
        :values="selectedTeamList ? searchedTeams : users"
        :isLast="isLast"
        popperClass="to-do-assign__dropdown"
        class="to-do-assign"
        iconSize="md"
        @onScroll="onScroll"
      >
        <template #label>
          <AvatarGroupToDo
            :avatars="selectedUsers"
            :numberOfVisibleUsers="20"
            :showRemoveButton="true"
            :scaleByHover="true"
            type="avatarGroup"
            size="md"
            @onRemoveAvatar="onRemoveUser"
          />
          <button
            v-show="selectedUsers?.length"
            class="to-do__plus"
          >
            <icon
              data="@icon/plus-16.svg"
              :fill="true"
              width="24"
              height="24"
              color="var(--monochrome05to04)"
            />
          </button>
        </template>
        <template #before-list>
          <BorderedInput
            v-if="!selectedTeamList"
            id="searchTeamUsers"
            :modelValue="filterUsers.text"
            type="search"
            placeholder="Search by Name or Email"
            :tooltip="false"
            :searchIcon="true"
            :autocomplete="false"
            :focus="true"
            :showRemoveButton="false"
            className="to-do-assign__search"
            @update:modelValue="onSearchUsers"
          />
        </template>
        <template #item="{ value }">
          <ToDoAssignItem
            :value="value"
            :isAssigned="getSelectedItemIndex(value) !== -1"
            :selectedTeamList="selectedTeamList"
            @onSelectTeam="onSelectTeam"
            @onSelectUser="onUpdateSelectedList"
          />
        </template>
        <template
          v-if="canSelectTeam"
          #after-list
        >
          <button
            type="button"
            class="to-do-assign__change-list"
            @click="toggleList"
          >
            <span class="to-do-assign__change-list__text">
              or Select a {{ selectedTeamList ? "User" : "Team" }}
            </span>
          </button>
        </template>
      </VDropdownWithKeyboardNavigation>
      <ErrorMessage
        class="error-text error-text-small"
        name="toDoOwner"
      />
    </Field>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from "vue-class-component"
import { hideDropdownToScroll } from "@/mixins/hideDropdownToScroll"
import VDropdownWithKeyboardNavigation from "@/components/Dropdown/VDropdownWithKeyboardNavigation.vue"
import { User } from "@/models/User"
import { Prop } from "vue-property-decorator/lib/decorators/Prop"
import { Action } from "s-vuex-class"
import { Actions } from "@/store"
import {
  FilterTeam,
  FilterUser,
  ServerResponse,
  Team,
} from "@/models"
import Debounce from "@/decorators/Debounce"
import BorderedInput from "@/components/Form/BorderedInput.vue"
import { User as UserComponent } from "@/components/User"
import AvatarGroupToDo from "@/components/Avatar/AvatarGroupToDo.vue"
import TeamListItem from "@/components/Team/TeamListItem.vue"
import ToDoAssignItem from "@/components/ToDo/ToDoAssignItem.vue"
import { parsingServerResponseContent } from "@/utils"
import { Field, ErrorMessage } from "vee-validate"

@Options({
  name: "ToDoSelectUser",
  components: {
    VDropdownWithKeyboardNavigation,
    BorderedInput,
    UserComponent,
    AvatarGroupToDo,
    TeamListItem,
    ToDoAssignItem,
    Field,
    ErrorMessage,
  },
})

export default class ToDoSelectUser extends mixins(hideDropdownToScroll) {
  @Action(Actions.GET_USERS) getUsers: (filter: FilterUser) => Promise<ServerResponse<User>>
  @Action(Actions.TEAMS_WITH_USERS) searchTeam: (params: FilterTeam) => Promise<ServerResponse<Team>>

  @Prop({ default: [] }) readonly toDoUsers: User[]
  @Prop({ default: [] }) readonly toDoTeams: Team[]
  @Prop({ default: "" }) readonly rules: () => boolean | string
  @Prop({ default: true }) readonly canSelectTeam: boolean

  filterUsers = FilterUser.createDefault()
  filterTeam = FilterTeam.createDefault()
  selectedTeamList = false
  searchedTeams: Team[] = []
  users: User[] = []
  isLastForTeams = true
  isLastForUsers = true

  get selectedUsers() {
    return this.toDoUsers
  }

  set selectedUsers(val) {
    this.$emit("onSelectedUsers", val)
  }

  get selectedTeams() {
    return this.toDoTeams
  }

  set selectedTeams(val) {
    this.$emit("onSelectedTeams", val)
  }

  get isLast() {
    return this.selectedTeamList ? this.isLastForTeams : this.isLastForUsers
  }

  async mounted() {
    this.filterUsers.page = 1
    this.filterUsers.size = 100
    this.filterUsers.addSort("firstName_keyword", true)
    this.filterTeam.forSearch = true
    this.filterUsers.forSearch = true
    if (!this.canSelectTeam) {
      await this.onGetUsers()
      return
    }
    await Promise.all([this.onGetUsers(), this.onGetTeams()])
    this.selectedTeams.forEach((team) => {
      const { users } = team
      this.selectedUsers = [...this.selectedUsers, ...users]
    })
  }

  getSelectedItemIndex(value: Team | User) {
    const listOfItems = value instanceof Team ? this.selectedTeams : this.selectedUsers
    return listOfItems.findIndex(item => item.id === value.id)
  }

  onUpdateSelectedList(value: User) {
    const index = this.getSelectedItemIndex(value)
    if (index === -1) {
      this.selectedUsers.push(value)
    } else {
      this.selectedUsers.splice(index, 1)
    }
  }

  checkSelectedTeam() {
    this.selectedTeams = this.selectedTeams.filter((team) => {
      const { users } = team
      return users.some(user => this.selectedUsers.find(selectedUser => selectedUser.id === user.id))
    })
  }

  onSelectTeam(team: Team) {
    const { users } = team
    // This is a necessary to avoid the issue with the same users in the list
    let updatedListOfUsers: User[] = [...this.selectedUsers]
    if (users.length) {
      updatedListOfUsers = updatedListOfUsers.filter(item => !users.find(user => user.id === item.id))
    }
    if (this.getSelectedItemIndex(team) === -1) {
      updatedListOfUsers = [...updatedListOfUsers, ...users]
    }
    this.selectedUsers = updatedListOfUsers
    const index = this.getSelectedItemIndex(team)
    if (index === -1) {
      this.selectedTeams.push(team)
    } else {
      this.selectedTeams.splice(index, 1)
    }
  }

  toggleList() {
    this.selectedTeamList = !this.selectedTeamList
    this.$nextTick(() => {
      this.checkSelectedTeam()
    })
  }

  onScroll() {
    if (this.selectedTeamList) {
      this.filterTeam.page++
      this.onGetTeams()
    } else {
      this.filterUsers.page++
      this.onGetUsers()
    }
  }

  async onGetTeams() {
    const serverResponseTeam = await this.searchTeam(this.filterTeam)
    this.isLastForTeams = serverResponseTeam.last
    this.searchedTeams = parsingServerResponseContent({
      originalContent: this.searchedTeams,
      serverResponseContent: serverResponseTeam.content.filter(team => team.users.length),
      numberOfPage: serverResponseTeam.pageable.pageNumber,
    })
  }

  async onGetUsers() {
    const serverResponseUser = await this.getUsers(this.filterUsers)
    this.isLastForUsers = serverResponseUser.last
    this.users = parsingServerResponseContent({
      originalContent: this.users,
      serverResponseContent: serverResponseUser.content,
      numberOfPage: serverResponseUser.pageable.pageNumber,
    })
  }

  onRemoveUser(user: User) {
    this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id)
  }

  @Debounce(500)
  async onSearchUsers({ value }: { value: string }) {
    this.filterUsers.text = value
    this.filterUsers.page = 1
    await this.onGetUsers()
  }
}
</script>
