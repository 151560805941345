import { Notification } from "./Notification"

export class NotificationStoryPoker extends Notification {
  itemTitle: string
  itemSequenceNumber: number
  itemUrl: string
  constructor(props) {
    super(props)
    this.itemTitle = props.itemTitle
    this.itemSequenceNumber = props.itemSequenceNumber
    this.itemUrl = props.itemUrl
  }

  get title() {
    return this.content.itemTitle ?? ""
  }

  get sequenceNumber() {
    return this.content.itemSequenceNumber ?? ""
  }

  get url() {
    return this.content.itemUrl ?? ""
  }
}
