import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user" }
const _hoisted_2 = { class: "user__content" }
const _hoisted_3 = { class: "user__content_right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      src: _ctx.user?.pictureUrl,
      gap: _ctx.user?.getInitials(),
      type: "avatarGroup",
      class: _normalizeClass(["user__avatar", {'avatar-select__gap': !_ctx.user}])
    }, null, 8, ["src", "gap", "class"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        weight: _ctx.userNameWeight,
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "username", {
            userName: _ctx.user?.username ?? 'Unassigned'
          }, () => [
            _createTextVNode(_toDisplayString(_ctx.user?.username ?? "Unassigned"), 1)
          ])
        ]),
        _: 3
      }, 8, ["weight"]),
      (_ctx.createDate)
        ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
            key: 0,
            size: "extra-small",
            weight: "400",
            color: "monochrome-05"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.createDate), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content-right")
      ])
    ])
  ]))
}