import { v4 as uuidv4 } from "uuid"
import { TaskType } from "@/models/Task"
import { ItemStatus } from "@/models/Item"


export interface DefinitionOfDoneProps {
  id?: string
  definition: string
  done?: boolean
  enabled?: boolean
  type?: TaskType
  types?: TaskType[]
  status?: ItemStatus
}


export class DefinitionOfDone {
  id?: string
  definition: string
  done?: boolean
  type?: TaskType
  types?: TaskType[]
  status?: ItemStatus
  enabled?: boolean
  constructor(props?: DefinitionOfDoneProps) {
    this.setDefaultData()

    if (!props) return

    this.set(props)
  }

  set(props: DefinitionOfDoneProps) {
    this.id = props.id ?? ""
    this.definition = props.definition ?? ""
    this.done = props.done ?? false
    this.type = props.type
    this.types = props.types
    this.status = props.status
    this.enabled = props.enabled ?? false
  }

  setDefaultData() {
    this.id = uuidv4()
    this.definition = ""
    this.done = false
  }

  getJsonObj() {
    return {
      id: this.id,
      definition: this.definition,
      done: this.done,
      type: this.type,
      types: this.types,
      status: this.status,
    }
  }

  static create(props?: DefinitionOfDoneProps): DefinitionOfDone {
    return new DefinitionOfDone(props)
  }

  static createByName(definition: string) {
    return new DefinitionOfDone({ definition })
  }
}
