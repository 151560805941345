import { RetroNote } from "./RetroNote"
import { User } from "./User"


export interface RetroStackItemsListProps {
  description?: string;
  title?: string
  reviewed?: boolean;
  wentWell?: boolean;
  user?: User | null;
  stackId?: string | null;
  hasActionItem?: boolean;
  priority?: number;
  id?: string
  elements?: RetroNote[]
  noteIds?: string[]
  isNewStack?: boolean
  isNewNote?: boolean
}

export class RetroStackItemsList {
  description: string
  reviewed: boolean
  wentWell: boolean
  user: User | null
  stackId: string | null
  hasActionItem: boolean
  priority: number
  id: string
  elements: RetroNote[]
  isNewStack: boolean
  isNewNote: boolean
  constructor(props?: RetroStackItemsListProps) {
    this.setDefaultData()

    if (!props) return

    const {
      description,
      reviewed,
      wentWell,
      user,
      stackId,
      hasActionItem,
      priority,
      id,
      elements,
      title,
      isNewStack,
      isNewNote,
    } = props

    this.description = description ?? title ?? ""
    this.reviewed = reviewed ?? true
    this.wentWell = wentWell ?? false
    this.user = user ?? null
    this.stackId = stackId ?? null
    this.hasActionItem = hasActionItem ?? false
    this.priority = priority ?? 0
    this.id = id ?? ""
    this.elements = elements ?? []
    this.isNewStack = isNewStack ?? false
    this.isNewNote = isNewNote ?? false
  }

  static create(props: RetroStackItemsListProps): RetroStackItemsList {
    return new RetroStackItemsList(props)
  }

  setDefaultData() {
    this.description = ""
    this.reviewed = false
    this.wentWell = false
    this.user = null
    this.stackId = null
    this.hasActionItem = false
    this.priority = 0
    this.id = ""
    this.elements = []
    this.isNewStack = false
    this.isNewNote = false
  }

  getJsonObj() {
    return {
      title: this.description,
      id: this.id,
      reviewed: this.reviewed,
      wentWell: this.wentWell,
      elements: this.elements,
      description: this.description,
    }
  }

  isStack() {
    return !!this.elements?.length
  }

  get fullName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}`
  }
}
