import { VuexModule, Module } from "vuex-class-modules"
import { store } from "../../store"
import { Modules } from "../../modules"

import { Type } from "@/models"

export enum ItemTypeActions {
  GET_TYPES = "GET_TYPES",
  GET_TYPE_BY_ID = "GET_TYPE_BY_ID"
}

@Module
class ItemTypeModule extends VuexModule {
  types: Type[] = [
    {
      id: "STORY",
      label: "Story",
    },
    {
      id: "SPIKE",
      label: "Spike",
    },
    {
      id: "BUG",
      label: "Bug",
    },
    {
      id: "SUPPORT_CASE",
      label: "Support Case",
    },
    {
      id: "DEV_OPS",
      label: "DevOps",
    },
    {
      id: "TECH_DEBT",
      label: "Tech Debt",
    },
  ]

  get [`get/${ItemTypeActions.GET_TYPES}`]() {
    return this.types
  }

  get [`get/${ItemTypeActions.GET_TYPE_BY_ID}`]() {
    return (id: string) => {
      const item = this.types.find((status: Type) => status.id === id)
      return item ? item : { id: "", label: "" }
    }
  }
}

export const itemTypeModule = new ItemTypeModule({
  store,
  name: Modules.ITEM_TYPE,
})
