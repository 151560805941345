export class PostMortemCreate {
  itemId: string
  whys: string[]
  resolution: string
}


export class PostMortem {
  itemId: string
  resolution: string
  whys: string[]

  constructor(props: Partial<PostMortemCreate>) {
    const { itemId, resolution, whys } = props

    this.itemId = itemId ?? ""
    this.resolution = resolution ?? ""

    this.whys = whys ?? []
  }

  static create(props: Partial<PostMortemCreate>): PostMortem {
    return new PostMortem(props)
  }

  getJsonObj() {
    const data = {
      itemId: this.itemId,
      resolution: this.resolution,
      whys: this.whys,
    }

    return data
  }
}
