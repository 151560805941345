import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/plus.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("form", {
    class: "add-input",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["prevent"]))
  }, [
    _createVNode(_component_BasicInput, {
      id: "addCustomInput",
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      placeholder: "Add Custom...",
      class: "add-input__item"
    }, null, 8, ["modelValue"]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)))
    }, [
      _createVNode(_component_icon, {
        data: _imports_0,
        color: "#336DFF",
        width: "1.5em",
        height: "1.5em"
      })
    ])
  ], 32))
}