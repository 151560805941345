import { Filter } from "@/models/Pageable"

export class FilterTeam extends Filter {
  q: string
  accountId: string
  userId: string
  isSuperadmin: boolean
  forSearch: boolean
  demo: boolean
  projectId: string
  projectName?: string
  teamName?: string
  excludeUserId?: string
  constructor(props) {
    super(props)

    this.q = props?.q ?? ""
    this.accountId = props?.accountId ?? ""
    this.userId = props?.userId ?? ""
    this.isSuperadmin = props?.isSuperadmin ?? false
    this.forSearch = props?.forSearch ?? false
    this.demo = props?.demo ?? false
    this.projectId = props?.projectId ?? ""
    this.projectName = props?.projectName ?? ""
    this.teamName = props?.teamName ?? ""
    this.excludeUserId = props?.excludeUserId ?? ""
  }

  getJsonObj() {
    const data = {
      q: this.q,
      accountId: this.accountId,
      userId: this.userId,
      demo: this.demo,
      projectId: this.projectId,
      excludeUserId: this.excludeUserId,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterTeam(props)
  }

  static createDefault() {
    const props = {
      q: "",
      accountId: "",
      userId: "",
      isSuperadmin: false,
      forSearch: false,
      demo: false,
      projectId: "",
    }

    return new FilterTeam(props)
  }
}
