import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "reference-team reference-team_heading"
}
const _hoisted_2 = { class: "reference-team__left" }
const _hoisted_3 = { class: "reference-team__right" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "reference-list__item-in reference-sprint" }
const _hoisted_6 = { class: "reference-list__list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "reference-team" }
const _hoisted_9 = { class: "reference-team__left" }
const _hoisted_10 = { class: "reference-team__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_SprintHeading = _resolveComponent("SprintHeading")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.result,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    hideFooter: _ctx.showTeams,
    hideHeader: !_ctx.currentTeam,
    class: "reference-list_md",
    onOnClick: _ctx.onSprintSelected
  }, _createSlots({
    header: _withCtx(() => [
      (_ctx.currentTeam)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Avatar, {
                src: _ctx.currentTeam.picture?.pictureUrl || null,
                size: "md"
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseSubHeading, {
                weight: "400",
                size: "medium",
                color: "monochrome-07"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentTeam.name) + " (" + _toDisplayString(_ctx.currentTeam.size) + ") ", 1)
                ]),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BaseSubHeading, {
              weight: "600",
              size: "medium",
              color: "monochrome-06"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Sprints ")
              ]),
              _: 1
            })
          ]))
    ]),
    item: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SprintHeading, {
          sprint: option,
          class: "reference-sprint__title"
        }, null, 8, ["sprint"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: "reference-list__link",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSelectAnotherTeam && _ctx.onSelectAnotherTeam(...args)), ["prevent"]))
      }, " Select Another Team ")
    ]),
    _: 2
  }, [
    (_ctx.showTeams)
      ? {
          name: "screen",
          fn: _withCtx(() => [
            _createElementVNode("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "reference-list__item"
                }, [
                  _createElementVNode("button", {
                    class: "reference-list__button",
                    onClick: _withModifiers(($event: any) => (_ctx.onTeamSelect(item)), ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_Avatar, {
                          src: item.picture?.pictureUrl || null,
                          size: "md"
                        }, null, 8, ["src"])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_BaseSubHeading, {
                          weight: "400",
                          size: "medium",
                          color: "monochrome-07"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name) + " (" + _toDisplayString(item.size) + ") ", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ], 8, _hoisted_7)
                ]))
              }), 128))
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["searchList", "componentType", "selectedIndex", "hideFooter", "hideHeader", "onOnClick"]))
}