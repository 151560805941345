import { TranslateResult } from "vue-i18n"

export const enum AlertType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING"
}

export interface ShowAlertConfig {
  type?: AlertType
  timeout?: number
  theme?: string
  content?: AlertContentItem[]
  contentTitle?: AlertContentItem[]
  uniqueType?: string
}

export interface AlertContentItem {
  text: string
  type: "bold" | "regular"
}

export interface CutAlertContent {
  field: string
  numberOfSymbols?: number
}

export class Alert {
  id: string
  content: AlertContentItem[]
  contentTitle: AlertContentItem[]
  theme?: "global" | "toast"
  type: AlertType
  timeout?: number
  timeoutStarted: boolean

  uniqueType?: string
  timeoutCallbackId: ReturnType<typeof setTimeout>

  constructor(props) {
    this.id = props.id ?? ""
    this.content = props.content ?? []
    this.contentTitle = props.contentTitle ?? []
    this.theme = props.theme ?? null
    this.type = props.type ?? ""
    this.timeout = props.timeout ?? null
    this.timeoutStarted = props.timeoutStarted ?? false
    this.uniqueType = props.uniqueType ?? null
    this.timeoutCallbackId = props.timeoutCallbackId
  }

  setupTimeout() {
    const timeoutDuration = this.timeout ?? 5000
    this.startTimer(timeoutDuration)
  }

  cancelTimeout() {
    clearTimeout(this.timeoutCallbackId)
  }

  restartTimeout() {
    this.startTimer(3000)
  }

  private startTimer(duration: number) {
    this.cancelTimeout()
    this.timeoutCallbackId = setTimeout(() => {
      this.hideToast()
    }, duration)
  }

  hideToast() {
    this.timeout = -1
  }
}
