import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/spinner.svg'


const _hoisted_1 = { class: "spin-animation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["sprinner", _ctx.className]),
            style: _normalizeStyle({
        right: `${_ctx.right}px`,
      })
          }, [
            _createElementVNode("span", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createVNode(_component_icon, {
                  data: _imports_0,
                  original: "",
                  width: _ctx.size,
                  height: _ctx.size,
                  class: "spin-animation"
                }, null, 8, ["width", "height"])
              ])
            ])
          ], 6))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}