<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="notification.projectName"
    class="notification__item"
  >
    <template #pic>
      <div class="label-pic is-green">
        <icon
          data="@icon/favorite-folder.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        There's a New Project for you
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        You’ve been added to join <a
          :href="notification.acceptanceUrl"
          class="link .is-medium-500"
        >Project {{ notification.projectName }}</a>
        at {{ notification.companyName }}.
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationProjectAddition } from "@/models/Notification"

@Options({
  name: "PROJECT_ADDITION",
  components: {
    NotificationTemplate,
  },
})
export default class PROJECT_ADDITION extends Vue {
  @Prop() readonly notification: NotificationProjectAddition
}
</script>
