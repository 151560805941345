import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from "vuex-class-modules"

import { Modules } from "@/store/modules"
import { store } from "@/store/store"
import {
  AlertContentItem,
  AlertType,
  DefinitionOfDone,
  DefinitionOfDoneProps,
  DefinitionWithItemStatus,
  Item,
  Status,
  Task,
} from "@/models"
import { TaskActions, taskModule } from "./task.module"
import { Endpoint } from "@/services/endpoints"
import { apiService } from "@/services/api.service"
import { ProjectActions, projectModule } from "./project.module"
import { BaseModule } from "@/models/BaseModule"
import { ItemActions, itemModule } from "./item/item.module"
import { AlertActions, alertModule } from "./alert.module"
import { $t } from "@/plugins/i18n"
import { cutTextByNumberOfSymbols } from "@/helpers/cutTextByNumberOfSymbols"
export enum DefinitionOfDoneActions {
  GET_DEFINITIONS_DEFAULT = "GET_DEFINITIONS_DEFAULT",
  GET_DEFINITIONS_LIST = "GET_DEFINITIONS_LIST",
  GET_DEFINITION_OF_DONE = "GET_DEFINITION_OF_DONE",
  PUT_DEFINITION_OF_DONE = "PUT_DEFINITION_OF_DONE",
  UPDATE_DEFINITION_OF_DONE_LIST = "UPDATE_DEFINITION_OF_DONE_LIST",
  CLEAR_DEFINITION_LIST = "CLEAR_DEFINITION_LIST",
}

@Module
class DefinitionOfDoneModule extends BaseModule {
  definitionOfDoneDefault: DefinitionOfDoneProps[] = [
    {
      definition: "Documentation",
      done: false,
    },
    {
      definition: "UI/UX Done",
      done: false,
      type: "UI_UX",
      types: ["UI_UX"],
      status: "UI_UX_DONE",
    },
    {
      definition: "UI/UX Review",
      done: false,
      status: "UI_UX_DONE",
    },
    {
      definition: "Design Review",
      done: false,
      type: "DESIGN",
      types: ["DESIGN"],
      status: "DESIGN_COMPLETED",
    },
    {
      definition: "Development Done",
      done: false,
      type: "DEV",
      types: ["DEV", "BUG"],
      status: "DEVELOPMENT_DONE",
    },
    {
      definition: "Code Review",
      done: false,
      status: "DEVELOPMENT_DONE",
    },
    {
      definition: "QA Review",
      done: false,
      type: "QA",
      types: ["QA"],
      status: "TESTING_COMPLETE",
    },
    {
      definition: "PM Review",
      done: false,
    },
    {
      definition: "Acceptance Testing",
      done: false,
      status: "TESTING_COMPLETE",
    },
    {
      definition: "Regression Testing",
      done: false,
      status: "TESTING_COMPLETE",
    },
    {
      definition: "Integration Tests",
      done: false,
      status: "TESTING_COMPLETE",
    },
    {
      definition: "Demo Recorded",
      done: false,
    },
    {
      definition: "Deployed to Staging",
      done: false,
      status: "DEPLOYED_TO_STAGING",
    },
    {
      definition: "Deployed to Production",
      done: false,
      status: "DEPLOYED_TO_PRODUCTION",
    },
    {
      definition: "DevOps Tasks Done",
      done: false,
      type: "DEVOPS",
      types: ["DEVOPS"],
    },
  ]

  definitionsList: DefinitionOfDone[] = []

  get taskTypesList() {
    return Task.getTaskTypes(taskModule.tasks)
  }

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${DefinitionOfDoneActions.GET_DEFINITIONS_DEFAULT}`]() {
    return this.definitionOfDoneDefault.map(def => DefinitionOfDone.create(def))
  }

  get [`get/${DefinitionOfDoneActions.GET_DEFINITIONS_LIST}`]() {
    return this.definitionsList
  }

  @Mutation
  setDefinitionList() {
    this.definitionsList = this.definitionOfDoneDefault.map(def => DefinitionOfDone.create({
      ...def,
      enabled: def.type ? this.taskTypesList?.has(def.type) : false,
    }))
  }

  @Mutation
  setDefinitionOfDone(definition: DefinitionOfDone[]) {
    this.definitionsList = definition
  }

  @Action
  async [DefinitionOfDoneActions.GET_DEFINITIONS_LIST]() {
    this.setDefinitionList()
  }

  @Action
  async [DefinitionOfDoneActions.CLEAR_DEFINITION_LIST]() {
    this.setDefinitionOfDone([])
  }

  @Action
  async [DefinitionOfDoneActions.UPDATE_DEFINITION_OF_DONE_LIST](definition: DefinitionOfDone[]) {
    this.setDefinitionOfDone(definition)
  }

  @Action
  async [DefinitionOfDoneActions.GET_DEFINITION_OF_DONE]({ projectId, itemId } : {projectId?: string, itemId: string}) {
    const data = await apiService.get<DefinitionWithItemStatus>(Endpoint.DEFINITION_OF_DONE(projectId ?? this.projectId, itemId))
    return this.handleResponse<DefinitionWithItemStatus>(data, (data) => {
      this.handleDefinitionsResponse(data)
    })
  }

  @Action
  async [DefinitionOfDoneActions.PUT_DEFINITION_OF_DONE]({ projectId, item, definitionsOfDone } : {projectId?: string, item: Item, definitionsOfDone: DefinitionOfDone[]}) {
    const itemId = item?.id ?? ""
    const data = await apiService.put<DefinitionWithItemStatus>(Endpoint.DEFINITION_OF_DONE(projectId ?? this.projectId, itemId), definitionsOfDone.map(def => def.getJsonObj()))
    return this.handleResponse<DefinitionWithItemStatus>(data, (data) => {
      const itemTitle = cutTextByNumberOfSymbols({
        text: item.title,
        numberOfSymbols: 16,
      })
      const alertContent: AlertContentItem[] = [
        {
          text: "Item ",
          type: "regular",
        },
        {
          text: `${itemTitle} `,
          type: "bold",
        },
        {
          text: $t("alert.updated"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
        uniqueType: "itemEstimation",
      })
      this.handleDefinitionsResponse(data)
    })
  }

  handleDefinitionsResponse(data) {
    const definitionOfDone = data.definitionsOfDone.map(definition => DefinitionOfDone.create(definition))
    this.setDefinitionOfDone(definitionOfDone)
    itemModule[ItemActions.UPDATE_ITEM_VALUES]({
      payload: [
        {
          id: "status",
          value: data.status,
        },
        {
          id: "estimation",
          value: data.estimation,
        },
        {
          id: "statusParsed",
          value: Status.createById({
            id: data.status ?? "NOT_STARTED",
          }),
        },
      ] as any,
      updateClone: true,
    })
    return data
  }
}

export const definitionOfDoneModule = new DefinitionOfDoneModule({
  store,
  name: Modules.DEFINITION_OF_DONE,
})
