<template>
  <Form
    v-slot="{ validate, handleReset }"
    as="div"
    class="to-do"
  >
    <div class="to-do__header">
      <slot
        name="header"
        :validate="validate"
        :handleReset="handleReset"
      />
    </div>
    <div class="to-do__content">
      <slot
        name="content"
        :validate="validate"
        :handleReset="handleReset"
      />
    </div>
    <div class="to-do__footer">
      <slot
        name="footer"
        :validate="validate"
        :handleReset="handleReset"
      />
    </div>
  </Form>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Form } from "vee-validate"

const components = {
  Form,
}

@Options({
  name: "ToDoContent",
  components,
})
export default class ToDoContent extends Vue {}
</script>
