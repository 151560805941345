<template>
  <icon
    data="@icon/severityLevel.svg"
    :fill="true"
    :color="severityLevelOptions[colorLabel]"
    :width="width"
    :height="height"
    :dir="severityLevelOptions.dir"
  />
</template>

<script lang="ts">
import { ItemSeverityLevel } from "@/models"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"

const components = {
}

@Options({
  name: "SeverityLevelIcon",
  components,
})
export default class extends Vue {
  @Prop() readonly level: ItemSeverityLevel
  @Prop({ default: "0.5em" }) readonly width: string
  @Prop({ default: "1em" }) readonly height: string
  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean

  get colorLabel() {
    return this.isDark ? "darkColor" : "color"
  }


  get severityLevelOptions() {
    switch (this.level) {
      case "CRITICAL":
        return {
          color: "#E53935",
          darkColor: "#ED7472",
          dir: "up",
        }
      case "HIGH":
        return {
          color: "#F57C00",
          darkColor: "#F9B066",
          dir: "up",
        }
      case "MEDIUM":
        return {
          color: "#FFCA28",
          darkColor: "#FFDA69",
          dir: "down",
        }
      case "TRIVIAL":
      default:
        return {
          color: "#1565C0",
          darkColor: "#89C4F4",
          dir: "down",
        }
    }
  }
}
</script>
