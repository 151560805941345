<template>
  <ul>
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="elementClass"
    >
      <slot
        :item="item"
        :index="index"
      />
    </li>
    <li>
      <ObserverComponent
        :options="options"
        :last="last"
        @intersect="$emit('intersect')"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import ObserverComponent from "@/components/Observer/ObserverComponent.vue"
const components = {
  ObserverComponent,
}
@Options({
  name: "Observer",
  components,
})

export default class Observer<T> extends Vue {
  @Prop() options: IntersectionObserver
  @Prop() elementClass: string
  @Prop() items: T[]
  @Prop({
    default: true,
  }) last: boolean
}
</script>
