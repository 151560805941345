<template>
  <div :class="[{'user-page__team-list' : teamDel}]">
    <div
      class="team card"
      @click="clickOnTeam"
    >
      <div class="team__content">
        <div class="team__left">
          <div class="team__name team__desc">
            <span>{{ team.name }}</span>
          </div>
        </div>
        <div class="team__right">
          <div class="team__desc">
            Members:
            <span class="team__desc team_length"> {{ team.size ?? 0 }} </span>
          </div>

          <Avatar
            :src="team.picture?.pictureUrl || null"
            class="team__avatar "
            type="teamsGroup"
          />
        </div>
      </div>
    </div>
    <button
      v-if="teamDel"
      class="team__delete"
      type="button"
      @click="teamDelete"
    >
      <icon
        data="@icon/trash.svg"
        :fill="true"
        color="#7D92A7"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { Emit, Prop } from "vue-property-decorator"

import Avatar from "@/components/Avatar/index.vue"
import { TeamPreview } from "@/models"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "TeamListItem",
  components: {
    Avatar,
  },
})
export default class TeamListItem extends Vue {
  @Prop() readonly team: TeamPreview
  @Prop({ default: "add" }) readonly type: string
  @Prop({ default: false }) readonly teamDel: boolean

  @Emit("clickOnTeam")
  clickOnTeam() {
    return this.team.id
  }

  @Emit("teamDelete")
  teamDelete() {
    return this.team.id
  }
}
</script>
