import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@icon/add.svg'
import _imports_1 from '@icon/trash.svg'


const _hoisted_1 = { class: "avatar-upload" }
const _hoisted_2 = { class: "avatar-upload__img" }
const _hoisted_3 = {
  key: 0,
  class: "avatar-upload__controls"
}
const _hoisted_4 = {
  key: 0,
  class: "avatar-delete__file"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 2,
  class: "avatar-upload__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_icon = _resolveComponent("icon")!
  const _component_file_upload = _resolveComponent("file-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Avatar, {
        src: _ctx.files.length && !_ctx.edit ? _ctx.url : _ctx.src || null,
        size: _ctx.size,
        class: "team__avatar",
        type: "teamsGroup"
      }, null, 8, ["src", "size"]),
      (_ctx.state)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_file_upload, {
              ref: "avatarUpload",
              modelValue: _ctx.files,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
              extensions: "jpg,jpeg,png,webp",
              accept: "image/png,image/jpeg,image/webp",
              name: "avatar",
              class: "avatar-upload__control avatar-upload__file",
              drop: true,
              multiple: false,
              onInputFilter: _ctx.inputFilter,
              onInputFile: _ctx.inputFile
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon, {
                  data: _imports_0,
                  fill: false,
                  color: "#ffffff",
                  width: "16",
                  height: "16"
                })
              ]),
              _: 1
            }, 8, ["modelValue", "onInputFilter", "onInputFile"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.state)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "avatar-upload__control",
            type: "button",
            disabled: !_ctx.isUrlExist,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClean && _ctx.onClean(...args)))
          }, [
            _createVNode(_component_icon, {
              data: _imports_1,
              fill: false,
              color: "#7D92A7",
              width: "18",
              height: "18"
            })
          ], 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isModeModal ? _ctx.isModeModal : _ctx.edit)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
          key: 1,
          img: _ctx.files,
          url: _ctx.url,
          mode: _ctx.mode,
          onHide: _ctx.onHide,
          onSave: _ctx.onSave
        }, null, 40, ["img", "url", "mode", "onHide", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}