import { User } from "./User"
import { formatDate } from "@/utils/dateUtil"
import { StructuredDescription } from "./EditorData"
import { replaceHtmlTags } from "@/utils/helpers"
import { Team } from "./Team"
import { Base } from "./Base"
import { ReferencedResourcePreviews } from "./Search"
import {
  updateReferencesByResourcePreviews,
} from "@/utils/updateReferencesByResourcePreviews"

export type ToDoType = "OPENED" | "SENT" | "CLOSED"

export interface ToDoCount {
  count: number;
}
export interface ToDoRelatedResourceId {
  id: string
  type: string
}

export type ToDoByType = {
  [key in ToDoType]: ToDo[];
};

export type ToDoStatus = "ACTIVE" | "COMPLETED" | "OBSOLETE";

export interface OwnersCompletion {
  [key: string]: boolean
}
export class ToDo extends Base<ToDo> {
  completedOn = new Date()
  createdAutomatically = false
  createdDate = new Date()
  estimatedDeadline?: Date
  id = ""
  name = ""
  owner = new User()
  owners: User[] = []
  ownersCompletionStatuses: OwnersCompletion = {}
  projectId = ""
  relatedResourceId: ToDoRelatedResourceId = {
    id: "",
    type: "",
  }
  relatedUrl = ""
  status: ToDoStatus = "ACTIVE"
  structuredDescription = new StructuredDescription()
  referencedResourcePreviews?: ReferencedResourcePreviews
  urgent = false
  removePrevious = false
  addMode = false
  selectedTeams: Team[] = []
  isStoryPoker = false
  itemId = ""
  completedByMe = false
  constructor(props?: Partial<ToDo>) {
    super()
    if (!props) return
    Object.keys(props).forEach((field) => {
      if (field === "completedOn" || field === "createdDate") {
        this[field] = new Date(props[field] ?? "")
        return
      }
      if (field === "owners") {
        const users = props[field] ?? []
        this[field] = users.map(owner => new User(owner))
        return
      }
      this[field] = props[field]
    })
    if (props.referencedResourcePreviews) {
      this.structuredDescription.html = updateReferencesByResourcePreviews(props.referencedResourcePreviews, this.structuredDescription.html)
    }
  }

  getJsonObj() {
    return {
      name: this.getToDoName(),
      ownerIds: this.owners.map(owner => owner.id),
      projectId: this.projectId,
      relatedResourceId: this.relatedResourceId,
      removePrevious: this.removePrevious,
      structuredDescription: this.structuredDescription,
      urgent: this.urgent,
      estimatedDeadline: this.estimatedDeadline ? formatDate(new Date(this.estimatedDeadline), "yyyy-MM-dd") : null,
    }
  }

  getToDoName() {
    let name = this.name
    if (name.length > 128) {
      name = `${this.name.slice(0, 125)}...`
    }
    return name
  }

  get changeByFields() {
    return [
      "estimatedDeadline",
      "structuredDescription",
      "urgent",
      "owners",
    ] as (keyof ToDo)[]
  }

  isModified(keys?: (keyof ToDo)[], inverse?: boolean) {
    let isModified = false
    if (keys?.includes("structuredDescription") && this.initialState) {
      const initial = JSON.parse(this.initialState)
      if (initial.structuredDescription) {
        isModified = replaceHtmlTags(this.structuredDescription?.html) !== replaceHtmlTags(initial.structuredDescription?.html)
      }
      keys = keys.filter(key => key !== "structuredDescription")
    }
    if (!isModified) {
      isModified = super.isModified(keys, inverse)
    }
    return isModified
  }

  get isDone() {
    return this.status === "COMPLETED"
  }
}
