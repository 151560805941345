export class LogError {
  message: string
  tags: { [key:string]: string } = {}

  constructor(message, tags: { [key:string]: string }) {
    this.message = message
    this.tags = tags
  }

  getJsonObj() {
    return {
      message: this.message,
      tags: this.tags,
    }
  }
}
