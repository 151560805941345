<template>
  <form
    class="add-input"
    @submit.prevent="onEnter"
  >
    <BasicInput
      id="addCustomInput"
      v-model="value"
      placeholder="Add Custom..."
      class="add-input__item"
    />
    <button @click="onEnter">
      <icon
        data="@icon/plus.svg"
        color="#336DFF"
        width="1.5em"
        height="1.5em"
      />
    </button>
  </form>
</template>

<script lang="ts">
import { Emit } from "vue-property-decorator"
import BasicInput from "@/components/Form/BasicInput.vue"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "AddBasicInput",
  components: {
    BasicInput,
  },
})

export default class AddBasicCheckbox extends Vue {
  value = ""

  // onInput({ value } : { value: string }) {
  //   this.value = value;
  // }

  onEnter() {
    this.toParent()
    this.clean()
  }

  @Emit("enter")
  toParent() {
    return this.value
  }

  clean() {
    this.value = ""
  }
}
</script>
