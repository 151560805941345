import { Options, Vue } from "vue-class-component"
import {
  InjectReactive,
  Prop,
  Emit,
  Watch,
} from "vue-property-decorator"

import {
  FilterBacklogUI,
  BacklogSetup,
  FilterBacklog,
  Type,
  typeCreateById,
} from "@/models"

@Options({
  name: "backlogFilter",
})
export default class backlogFilter extends Vue {
  @Prop() readonly backlogConfig: BacklogSetup
  @Prop({ default: [] }) readonly defaultItemTypes: Type[]
  @InjectReactive() filterUILocal!: FilterBacklogUI

  hasFilters = false

  get numberOfElementsDefault() {
    return this.defaultItemTypes.length
  }

  updateLocalFilter(payload) {
    this.filterUILocal[payload.id] = payload.value
  }

  updateAndSetFilter(value) {
    this.updateLocalFilter(value)
    this.onSetFilter()
  }

  onSetFilter() {
    this.backlogConfig.setFilter = FilterBacklog.createFilterFromUI(
      this.backlogConfig.filter,
      this.filterUILocal,
    )
    this.checkFilters()
  }

  resetFilterByFields(fieldsKey: string[]) {
    this.backlogConfig.resetFilters()
    fieldsKey.forEach((key) => {
      this.filterUILocal[key] = FilterBacklogUI.createDefault()?.[key]
    })
    this.sendItemTypesToFilterUILocal()
    this.onSetFilter()
  }

  checkFilters() {
    this.hasFilters = !!this.backlogConfig?.filter?.hasSelectedFilters
    this.isFiltered(this.hasFilters)
  }

  @Emit()
  isFiltered(value) {
    return value
  }

  onBacklogChanged() {
    if (!this.backlogConfig) return
    this.resetLocalFilter(false)
    this.checkFilters()
    this.filterUILocal = FilterBacklogUI.createDefault()
  }

  resetLocalFilter(deep = false) {
    this.filterUILocal = deep
      ? FilterBacklogUI.createDefault()
      : FilterBacklogUI.create(this.filterUILocal)
  }

  @Watch("numberOfElementsDefault")
  sendItemTypesToFilterUILocal() {
    this.filterUILocal.itemTypes = [...this.defaultItemTypes]
  }
}
