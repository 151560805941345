import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/severityLevel.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_component_icon, {
    data: _imports_0,
    fill: true,
    color: _ctx.severityLevelOptions[_ctx.colorLabel],
    width: _ctx.width,
    height: _ctx.height,
    dir: _ctx.severityLevelOptions.dir
  }, null, 8, ["color", "width", "height", "dir"]))
}