import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/close.svg'


const _hoisted_1 = { class: "to-do__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToDoView = _resolveComponent("ToDoView")!
  const _component_ToDoAdd = _resolveComponent("ToDoAdd")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      onBeforeLeave: _ctx.slideFadeLeave,
      onBeforeEnter: _ctx.slideFadeEnter
    }, {
      default: _withCtx(() => [
        (!_ctx.addToDo)
          ? (_openBlock(), _createBlock(_component_ToDoView, {
              key: 0,
              onAddToDo: _ctx.onShowAddToDo
            }, null, 8, ["onAddToDo"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeLeave", "onBeforeEnter"]),
    _createVNode(_Transition, {
      onBeforeLeave: _ctx.slideFadeLeave,
      onBeforeEnter: _ctx.slideFadeEnter
    }, {
      default: _withCtx(() => [
        (_ctx.addToDo)
          ? (_openBlock(), _createBlock(_component_ToDoAdd, {
              key: 0,
              onOnCancel: _ctx.onCloseAddAddToDo
            }, null, 8, ["onOnCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeLeave", "onBeforeEnter"]),
    (_ctx.showCloseButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "to-do-widget__close",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onHideToDoWidget && _ctx.onHideToDoWidget(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon, {
            data: _imports_0,
            fill: false,
            width: "24px",
            height: "24px"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}