<template>
  <div
    :id="file.id"
    class="custom-file-simplified"
    :class="[`custom-file-simplified_${fileMarkup}`]"
  >
    <div
      class="custom-file-simplified__in"
      @click.prevent="showImage()"
    >
      <FilePic class="custom-file-simplified__pic" />
      <div
        type="button"
        class="custom-file-simplified__content"
      >
        <BaseSubHeading
          weight="400"
          size="medium"
          className="custom-file-simplified__cut-name"
          color="monochrome-07"
        >
          {{ file.name }}
        </BaseSubHeading>
        <div class="custom-file-simplified__size__wrapper">
          <BaseSubHeading
            v-if="progress === 0"
            weight="500"
            size="extra-small"
            className="custom-file-simplified__size"
            color="monochrome-05"
            darkColor="monochrome-04"
          >
            {{ fileSize }}
          </BaseSubHeading>
          <ProgressLoadingLine
            v-else
            class="custom-file-simplified__progress"
            :progress="progress"
          />
        </div>
      </div>
      <button
        type="button"
        class="custom-file-simplified__download"
        @click.prevent.stop="onFileDownload"
      >
        <icon
          data="@icon/download.svg"
          :fill="true"
          color="var(--monochrome05to04)"
          width="24"
          height="24"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import formatBytes from "@/helpers/formatBytes"
import { Prop, Emit } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { FileMetaData } from "@/models"
import FilePic from "@/components/File/FilePic.vue"
import Avatar from "@/components/Avatar/index.vue"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { formatDateAgo } from "@/utils/dateUtil"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import ProgressLoadingLine from "@/components/ProgressBar/ProgressLoadingLine.vue"
import format from "date-fns/format"
import { config } from "@/app.config"

@Options({
  name: "FileItemSimplified",
  components: {
    FilePic,
    Avatar,
    DropdownTooltip,
    ProgressLoadingLine,
  },
})
export default class FileItemSimplified extends Vue {
  @Prop({
    required: true,
  })
    file: FileMetaData
  @Prop({
    default: [],
  })
    files: FileMetaData[]
  @Prop({
    default: false,
  })
    removeButton
  @Prop({
    default: 0,
  })
    progress: number
  @Prop({
    default: false,
  })
    preventShow: boolean
  @Prop({
    default: "",
  })
    fancyboxGroup: string
  @Prop({
    default: "element",
    validator: (type: string) => ["element", "line"].includes(type),
  })
    fileMarkup: string

  @Action(Actions.ATTACHMENT_DOWNLOAD) downloadFile: ({
    fileMetadataId,
    fileName,
  }: {
    fileMetadataId: string
    fileName: string
  }) => void
  @Action(Actions.ATTACHMENT_VIEW) attachmentView: (payload: { fileMetadataId: string }) => Blob
  @Getter(Getters.GET_CURRENT_PROJECT_ID) projectId: string

  moreOptions = [
    {
      id: "delete",
      label: "Delete",
      icon: require("@icon/trash.svg"),
      iconOption: {
        width: "1.5em",
        height: "1.5em",
      },
    },
  ]

  get fileOwner() {
    return this.file.owner
  }

  get isLineType() {
    return this.fileMarkup === "line"
  }

  get isElType() {
    return this.fileMarkup === "element"
  }

  get timeAgo() {
    return formatDateAgo({
      date: new Date(),
      dateFormat: this.file.createdDateParsed,
      short: true,
    })
  }

  get fileCreateDate() {
    return format(this.file.createdDateParsed, "MM/dd/yy h:mm bb")
  }

  get maxUploadSizeKb() {
    return Number(config.VUE_APP_UPLOAD_MAX_FILE_SIZE_KB)
  }

  get isOverSizeLimit() {
    return this.file.size / 1024 > this.maxUploadSizeKb
  }

  get filteredFiles() {
    return this.files.filter(file => file.isImage)
  }

  get jumpToIndex() {
    const index = this.filteredFiles.findIndex(file => file.id === this.file.id)

    return index < 0 ? 0 : index
  }
  get fileSize() {
    return this.file.size === 0 ? "0 Bytes" : this.formatBytes(this.file.size.toString())
  }

  formatBytes(value: string) {
    return formatBytes(value)
  }

  onFileDownload() {
    const { id, name }: { id: string; name: string } = this.file
    if (this.file.custom) return

    this.downloadFile({
      fileMetadataId: id,
      fileName: name,
    })
  }

  @Emit("deleteFile")
  deleteFile(hide) {
    hide()
    return this.file.id
  }

  showImage() {
    if (this.preventShow) return
    if (!this.file.isImage) return

    if (!this.fancyboxGroup.length) {
      this.$fancybox.show([
        {
          blob: this.attachmentView,
          fileMetadataId: this.file.id,
        },
      ])
      return
    }

    this.$fancybox
      .show(
        this.filteredFiles.map(file => ({
          blob: this.attachmentView,
          fileMetadataId: file.id,
        })),
      )
      .jumpTo(this.jumpToIndex)
  }

  beforeUnmount() {
    this.$fancybox.unbind()
  }
}
</script>
