import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_TeamSelectionDropdown = _resolveComponent("TeamSelectionDropdown")!

  return (_ctx.teams.length <= 1)
    ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
        key: 0,
        size: "large",
        weight: "400",
        color: "monochrome-05",
        className: "header__breadcrumb"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" / "),
          _createVNode(_component_router_link, { to: _ctx.routeLink }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_TeamSelectionDropdown, {
        key: 1,
        optionLabelSize: "md",
        className: "team-selection",
        onOnSelectTeam: _ctx.onSelectDropdownTeam
      }, {
        placeholder: _withCtx(() => [
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            color: "monochrome-05",
            className: "header__breadcrumb"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" /"),
              _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onOnSelectTeam"]))
}