import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList } from "vue"
import _imports_0 from '@icon/download.svg'
import _imports_1 from '@icon/trash.svg'


const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "custom-file__in" }
const _hoisted_3 = { class: "custom-file__desc" }
const _hoisted_4 = { class: "custom-file__content" }
const _hoisted_5 = { class: "custom-file__row" }
const _hoisted_6 = { class: "custom-file__row-item custom-file__row-right" }
const _hoisted_7 = { class: "desc_label desc_normal custom-file_time-ago" }
const _hoisted_8 = { class: "custom-file__sub-row" }
const _hoisted_9 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePic = _resolveComponent("FilePic")!
  const _component_ProgressLoadingLine = _resolveComponent("ProgressLoadingLine")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.file.id,
    class: _normalizeClass(["custom-file", [`custom-file_${_ctx.fileMarkup}`]])
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isElType)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_FilePic, {
              onClick: _withModifiers(_ctx.onFileDownload, ["prevent"])
            }, null, 8, ["onClick"]),
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                class: "custom-file__name",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showImage()), ["prevent"]))
              }, _toDisplayString(_ctx.file.name), 1),
              _createElementVNode("span", {
                class: _normalizeClass(["custom-file__meta", { 'is-error': _ctx.isOverSizeLimit }])
              }, _toDisplayString(_ctx.fileMaxSize), 3),
              _createVNode(_component_ProgressLoadingLine, {
                class: _normalizeClass(["custom-file__progress", [{ 'is-hidden': _ctx.progress === 0 }]]),
                progress: _ctx.progress
              }, null, 8, ["class", "progress"]),
              (_ctx.removeButton)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "custom-file__remove",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('remove', _ctx.file.id)), ["stop"]))
                  }))
                : _createCommentVNode("", true)
            ])
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.isLineType)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.showImage()), ["prevent"]))
            }, [
              _createVNode(_component_FilePic)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  type: "button",
                  class: "custom-file__row-item custom-file__row-left",
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.showImage()), ["prevent"]))
                }, [
                  _createVNode(_component_BaseSubHeading, {
                    weight: "400",
                    size: "medium",
                    className: "custom-file__cut-name",
                    color: "monochrome-07"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.file.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BaseSubHeading, {
                    weight: "500",
                    size: "extra-small",
                    className: "custom-file__size",
                    color: "monochrome-05",
                    darkColor: "monochrome-04"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.fileSize), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "custom-file__download",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFileDownload && _ctx.onFileDownload(...args)), ["prevent","stop"]))
                  }, [
                    _createVNode(_component_icon, {
                      data: _imports_0,
                      fill: true,
                      color: "#7D92A7",
                      width: "24",
                      height: "24"
                    })
                  ]),
                  _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createVNode(_component_BaseSubHeading, {
                      weight: "400",
                      size: "large",
                      color: "monochrome-05",
                      darkColor: "monochrome-04"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.timeAgo), 1)
                      ]),
                      _: 1
                    })
                  ])), [
                    [
                      _directive_tooltip,
                      { content: _ctx.fileCreateDate, theme: 'info-tooltip' },
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_ProgressLoadingLine, {
                  class: _normalizeClass(["custom-file__progress", [{ 'is-hidden': _ctx.progress === 0 }]]),
                  progress: _ctx.progress
                }, null, 8, ["class", "progress"])
              ])
            ]),
            _createVNode(_component_Avatar, {
              src: _ctx.fileOwner.pictureUrl,
              gap: _ctx.fileOwner.getInitials()
            }, null, 8, ["src", "gap"]),
            _createVNode(_component_DropdownTooltip, {
              skidding: 8,
              iconSize: "md",
              placement: "bottom-end",
              class: "custom-file__menu",
              onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
            }, {
              "tooltip-content": _withCtx(({ hide }) => [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreOptions, (option, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createElementVNode("button", {
                        id: option.id,
                        class: "dropdownTooltip_remove dropdownTooltip",
                        onClick: _withModifiers(($event: any) => (_ctx.deleteFile(hide)), ["prevent"])
                      }, [
                        _createVNode(_component_icon, {
                          data: _imports_1,
                          width: "1.5em",
                          height: "1.5em",
                          fill: true,
                          color: "#7D92A7"
                        }),
                        _createTextVNode(" " + _toDisplayString(option.label), 1)
                      ], 8, _hoisted_9)
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}