import { Filter } from "@/models/Pageable"

export class FilterNotification extends Filter {
  constructor(props) {
    super(props)

    this.size = 20
  }

  getJsonObj() {
    const data = {
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterNotification(props)
  }

  static createDefault() {
    const props = {
    }

    return new FilterNotification(props)
  }
}
