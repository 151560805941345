import { $t } from "@/plugins/i18n"
import setError from "@/store/helpers/error"
import { AlertActions, alertModule } from "@/store/modules/alert.module"
import { Mutation, VuexModule } from "vuex-class-modules"
import { HttpError } from "./Error"
import { AlertContentItem } from "./Alert"

export class BaseModule extends VuexModule {
  error: Error | null

  @Mutation
  setError(error: Error | null) {
    this.error = error
  }

  protected handleResponse<T>(data: T | HttpError, success?: (data: T) => void | any, noErrorAlert?: boolean | ((data: HttpError) => boolean), reject?: (data: HttpError) => any | void, responseRequired?: boolean): boolean | any {
    if (!(data instanceof HttpError)) {
      if (success && typeof success === "function") {
        const response = success(data)
        if (responseRequired) return response
        return response || true
      }
      return true
    } else {
      this.reject(data, noErrorAlert)
      if (reject && typeof reject === "function") {
        return reject(data)
      }
      return false
    }
  }

  protected reject(data: HttpError, noAlert?: boolean | ((data: HttpError) => boolean)) {
    const error = setError(data)
    this.setError(error)
    if (!noAlert || (typeof noAlert === "function" && !noAlert(data))) {
      const alertContent: AlertContentItem[] = [
        { text: `${$t("alert.error", [error.message])}`, type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ERROR_ALERT]({
        contentTitle: alertContent,
        theme: "global",
      })
    }
  }
}
