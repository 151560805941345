<template>
  <NotificationTemplate
    v-for="(item, index) in notification?.items"
    :key="index"
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-purple">
        <icon
          data="@icon/task-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
          color="#6A1B9A"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        The time on these tasks haven't been updated in quite some time
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
        className="notification__update-time-left__item notification__item-content"
      >
        <a
          class="link is-medium-500"
          target="_blank"
          :href="item?.url ?? '#'"
        >
          Story {{ item?.sequenceNumber ?? $t("none") }}
        </a>
        <span class="notification__item-content__title">
          {{ item?.title }}
        </span>
      </BaseSubHeading>
      <div
        v-for="(task, key) in item?.tasks"
        :key="key"
        className="notification__update-time-left__tasks"
      >
        <BaseSubHeading
          size="small"
          color="monochrome-06"
          darkColor="monochrome-03"
          weight="500"
          className="notification__update-time-left__tasks__left"
        >
          <a
            class="link is-small-400"
            target="_blank"
            :href="item?.url ? item.url + `?task=${task.order}` : '#'"
          >
            {{ task.order }}
          </a>
          <span class="notification__update-time-left__tasks__name">
            {{ task.name }}
          </span>
        </BaseSubHeading>

        <BaseSubHeading
          size="medium"
          color="monochrome-05"
          darkColor="monochrome-04"
          weight="400"
          className="notification__update-time-left__tasks__right"
        >
          <span class="notification__update-time-left__tasks__type">
            {{ task.type }}
          </span>
          <span
            class="notification__update-time-left__tasks__estimation is-estimation"
            :class="{'notification__update-time-left__tasks__estimation_alert': task.estimatedHours >= 24}"
          >
            {{ task.estimatedHours }} hr
          </span>
        </BaseSubHeading>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "UPDATE_STORY_TIME_LEFT_REMINDER",
  components: {
    NotificationTemplate,
  },
})
export default class UPDATE_STORY_TIME_LEFT_REMINDER extends Vue {
  @Prop() readonly notification: NotificationItem
}
</script>
