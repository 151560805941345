<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-purple">
        <icon
          data="@icon/task-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
          color="#6A1B9A"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="button-level button-level-large notification__simple-link"
          target="_blank"
          :href="notification.content?.itemUrl ?? '#'"
        >
          Item #{{ notification.content?.sequenceNumber ?? $t("none") }}
        </a> <span
          v-if="initiatorFullName"
          class="notification__update-item__initiator"
        >@{{ initiatorFullName }}</span> assigned you the Owner of the following Tasks
      </BaseSubHeading>
    </template>
    <template #content>
      <div
        v-for="(task, key) in notification.content?.tasks"
        :key="key"
        className="notification__update-time-left__tasks"
      >
        <div class="notification__update-time-left__tasks__left">
          <BaseSubHeading
            size="medium"
            color="markingOrange-02"
            weight="500"
          >
            New
          </BaseSubHeading>
          <BaseSubHeading
            size="medium"
            color="monochrome-07"
            weight="400"
            className="notification__update-time-left__tasks__name"
          >
            {{ task.name }}
          </BaseSubHeading>
        </div>

        <div class="notification__update-time-left__tasks__right">
          <BaseSubHeading
            size="medium"
            color="monochrome-06"
            darkColor="monochrome-03"
            weight="500"
            className="notification__update-time-left__tasks__type"
          >
            {{ task.type }}
          </BaseSubHeading>

          <BaseSubHeading
            size="small"
            color="monochrome-07"
            weight="500"
            className="notification__update-time-left__tasks__estimation-gap"
          >
            -
          </BaseSubHeading>
        </div>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "TASK_OWNER_ASSIGMENT_NOTIFICATION",
  components: {
    NotificationTemplate,
  },
})
export default class TASK_OWNER_ASSIGMENT_NOTIFICATION extends Vue {
  @Prop() readonly notification: NotificationItem

  get initiatorFullName() {
    if (!this.notification.content.initiators) return ""

    const { firstName, lastName } = this.notification.content.initiators[0]
    return `${firstName} ${lastName}`
  }
}
</script>
