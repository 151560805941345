import {
  createTextVNode,
  h,
  render,
  VNode,
} from "vue"
import { RouterLink } from "vue-router"
import { UserActions, userModule } from "@/store/modules/user.module"

class TicketDirective {
  created(el, binding) {
    if (el?.textContent) {
      const projectId = binding.value?.todo.projectId

      const project = userModule[`get/${UserActions.GET_USER_CURRENT_PROFILE}`].projects.find(item => item.id === projectId)

      if (!projectId || !project) return el


      let text = el.textContent
      const query = binding.value?.query
      const links = text.match(/#\d+/g)
      const params = { ...binding.value?.params, projectId: project.urlName }
      const name = binding.value?.routerName
      let nodes: VNode[] = []

      if (links?.length > 0) {
        if (params && name) {
          const node = h(RouterLink, {
            target: "_blank",
            to: {
              name: name,
              params: params,
            },
            class: "link",
          }, text)
          node.appContext = { ...binding.instance.$context }
          nodes = [node]
        } else if (binding.value?.wholeLink) {
          const node = h(RouterLink, {
            target: "_blank",
            to: {
              name: "Item",
              params: {
                itemId: links[0].substring(1),
                projectId: project.urlName,
              },
              query: query,
            },
            class: "link",
          }, text)
          node.appContext = { ...binding.instance.$context }
          nodes = [node]
        } else {
          links.forEach((link, i) => {
            const arr = text.split(link)

            nodes.push(createTextVNode(arr[0]))
            text = text.substring(arr[0].length)

            const node = h(RouterLink, {
              target: "_blank",
              to: {
                name: "Item",
                params: {
                  itemId: link.substring(1),
                  projectId: project.urlName,
                },
                query: query,
              },
              class: "link",
            }, link)
            node.appContext = { ...binding.instance.$context }
            nodes.push(node)
            text = text.substring(link.length)

            if (i === links.length - 1) {
              nodes.push(createTextVNode(arr[1]))
            }
          })
        }

        el.innerHTML = ""
        const node = h("span", null, nodes)
        render(node, el)
      }
    }
  }
}

export default new TicketDirective()
