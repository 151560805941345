import { Module, Mutation, Action } from "vuex-class-modules"
import { projectModule, ProjectActions } from "./project.module"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "./account.module"
import { apiService } from "@/services/api.service"
import { Poker, PokerCreateRequest, PokerEstimation } from "@/models/Poker"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { AlertActions, alertModule } from "./alert.module"

export enum PokerActions {
  GET_POKER = "GET_POKER",
  CREATE_POKER = "CREATE_POKER",
  ADD_STORY_POKER_ESTIMATION = "ADD_STORY_POKER_ESTIMATION",
  COMPLETE_STORY_POKER = "COMPLETE_STORY_POKER",
  GET_STORY_POKER_URL = "GET_STORY_POKER_URL"
}

@Module
class PokerModule extends BaseModule {
  poker: Poker
  storyPokerUrl = ""

  get projectId() {
    return projectModule.currentProjectId
  }

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${PokerActions.GET_POKER}`]() {
    return this.poker
  }

  get [`get/${PokerActions.GET_STORY_POKER_URL}`]() {
    return this.storyPokerUrl
  }

  @Mutation
  setPoker(poker: Poker) {
    this.poker = poker
  }

  @Mutation
  setStoryPokerUrl(url: string) {
    this.storyPokerUrl = url
  }

  @Action
  async [PokerActions.GET_POKER](itemId: string) {
    const data = await apiService.get<Poker>(Endpoint.GET_STORY_POKER(this.projectId, itemId))
    return this.handleResponse<Poker>(data, (data) => {
      this.setPoker(data)
    })
  }

  @Action
  async [PokerActions.ADD_STORY_POKER_ESTIMATION]({ storyPokerId, pokerEstimation } : { storyPokerId : string, pokerEstimation : PokerEstimation }) {
    const data = await apiService.patch<Poker>(Endpoint.ADD_STORY_POKER_ESTIMATION(this.projectId, storyPokerId), pokerEstimation)
    return this.handleResponse<Poker>(data, data => (data))
  }

  @Action
  async [PokerActions.GET_STORY_POKER_URL]({ itemId, onlyGet } : { itemId: string, onlyGet?: boolean }) {
    const data = await apiService.get<string>(Endpoint.GET_STORY_POKER_URL(this.projectId, itemId))
    return this.handleResponse<string>(data, (data) => {
      if (!onlyGet) {
        this.setStoryPokerUrl(data)
      }
      return data
    })
  }

  @Action
  async [PokerActions.CREATE_POKER](payload: PokerCreateRequest) {
    const data = await apiService.post<Poker>(Endpoint.CREATE_STORY_POKER(this.projectId), payload.getJsonObj(), {
      params: { demo: payload.demo },
    })
    return this.handleResponse<Poker>(data, (data) => {
      this.setPoker(data)
      const alertContent: AlertContentItem[] = [
        { text: "ToDo ", type: "regular" },
        { text: "Story Poker ", type: "bold" },
        { text: "Created", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [PokerActions.COMPLETE_STORY_POKER]({ storyPokerId, storyNumber, estimation } : {storyPokerId: string, storyNumber: number, estimation: number}) {
    const data = await apiService.post<Poker>(Endpoint.COMPLETE_STORY_POKER(this.projectId, storyPokerId), estimation, {
      headers: {
        "Content-Type": "application/json",
      },
    })

    return this.handleResponse<Poker>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        { text: "Estimstion for ", type: "regular" },
        { text: `Story #${storyNumber} `, type: "bold" },
        { text: "completed", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return data
    })
  }
}

export const pokerModule = new PokerModule({ store, name: Modules.POKER })
