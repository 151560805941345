import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/my-work-checkbox.svg'


const _hoisted_1 = {
  id: "pageHeader",
  class: "header"
}
const _hoisted_2 = { class: "header__left header__item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "header__right header__item" }
const _hoisted_5 = { class: "header__news body-text body-text-large-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_NotificationList = _resolveComponent("NotificationList")!
  const _component_icon = _resolveComponent("icon")!
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_HeaderProfileMenu = _resolveComponent("HeaderProfileMenu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "logo header__logo"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require(`@/assets/images/${_ctx.isStagingFlag ? 'staging-logo' : 'logo'}${_ctx.isDark ? '-dark' : ''}.svg`),
            alt: ""
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseHeading, {
        level: "2",
        className: "header__title"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.mainHeaderTitle), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Breadcrumbs)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NotificationList),
      (_ctx.account?.urlName)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: {
          name: 'myWork',
          params: { accountId: _ctx.account.urlName },
        },
            tabindex: "-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_icon, {
                  data: _imports_0,
                  fill: true,
                  width: "1.3em",
                  height: "1.3em",
                  color: "var(--monochrome-05)"
                })
              ])
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_HeaderMenu, {
        key: _ctx.$route.path
      })),
      _createVNode(_component_HeaderProfileMenu)
    ])
  ]))
}