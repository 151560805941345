import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "checked", "value", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["basic-checkbox", [
      {'is-bordered': _ctx.bordered },
      {'is-checked' : _ctx.isChecked},
      {'is-danger' : _ctx.danger},
      {'is-disabled' : _ctx.isDisabled},
      {'basic-checkbox_lineThrough' : _ctx.lineThrough},
      `basic-checkbox_${_ctx.checkType}`,
      `basic-checkbox_${_ctx.checkSize}`
    ]])
  }, [
    (_openBlock(), _createElementBlock("input", {
      id: _ctx.value,
      key: _ctx.value,
      type: "checkbox",
      class: "basic-checkbox__input",
      checked: _ctx.isChecked,
      value: _ctx.value,
      disabled: _ctx.isDisabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args))),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input')))
    }, null, 40, _hoisted_1)),
    _createElementVNode("label", {
      for: _ctx.value,
      class: _normalizeClass(["body-text body-text-medium-400", [
        {'basic-checkbox_switch__label': _ctx.addSwitch},
        {'basic-checkbox__label': !_ctx.addSwitch}]])
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
      _renderSlot(_ctx.$slots, "default")
    ], 10, _hoisted_2)
  ], 2))
}