import { Notification } from "./Notification"

export class NotificationProjectAddition extends Notification {
  constructor(props) {
    super(props)
  }

  get projectName() {
    return this.content?.projectName
  }

  get companyName() {
    return this.content?.companyName
  }

  get acceptanceUrl() {
    return this.content?.acceptanceUrl
  }
}
