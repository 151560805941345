<template>
  <CustomModal
    :id="`editor-modal-link-${id}`"
    modalClass="custom-modal_margin-md"
  >
    <template #header>
      <h2>Add Url</h2>
    </template>
    <template #body>
      <form
        class="form"
        @submit.prevent="onActionEvent"
      >
        <div class="form__line">
          <BorderedInput
            id="url"
            :modelValue="url"
            label="Url"
            @update:modelValue="handleInput"
          />
        </div>
      </form>
    </template>
    <template #footer="{ hide }">
      <ControlButtons
        @save="onActionEvent(hide)"
        @cancel="hide"
      >
        <template #save>
          Continue
        </template>

        <template #cancel>
          Close
        </template>
      </ControlButtons>
    </template>
  </CustomModal>
</template>

<script lang="ts">
import BorderedInput from "@/components/Form/BorderedInput.vue"
import CustomModal from "@/components/Modal/index.vue"
import { Emit, Prop } from "vue-property-decorator"
import { CallbackFunction } from "@/models"
import { Options, Vue } from "vue-class-component"
import ControlButtons from "@/components/ControlButtons/index.vue"
@Options({
  name: "EditorModalLink",
  components: {
    BorderedInput,
    CustomModal,
    ControlButtons,
  },
})
export default class EditorModalLink extends Vue {
  @Prop({
    default: "",
  }) id: string
  url = ""

  handleInput({ value } : { value: string }) {
    this.url = value
  }

  onActionEvent(callback: CallbackFunction) {
    this.toParent()
    callback()
    this.url = ""
  }

  @Emit("onAction")
  toParent(): string {
    return this.url
  }
}
</script>
