<template>
  <button
    v-if="haveBrowserHistory"
    class="back-button"
    @click.prevent.stop="goBack"
  >
    <slot name="label">
      Back
    </slot>
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { stepBackInBrowserHistory } from "@/utils/helpers"

@Options({
  name: "BackButtonComponent",
  components: {},
})

export default class BackButtonComponent extends Vue {
  @Prop({ default: true }) readonly canGoBack: boolean

  haveBrowserHistory = false
  mounted() {
    if (this.$route.params.accountId) {
      this.haveBrowserHistory = stepBackInBrowserHistory(this.$route)
    }
  }

  async goBack() {
    if (!this.canGoBack || this.$wait.is("goToPreviousPage")) {
      return
    }
    this.$wait.start("goToPreviousPage")
    await this.$router.go(-1)
    this.$wait.end("goToPreviousPage")
  }
}
</script>
