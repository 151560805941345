import {
  PROJECT_PREFIX_MAX_LENGTH,
  TEAM_TITLE_MAX_LENGTH,
  TITLE_MAX_LENGTH
} from "@/helpers/validationHelper"
import { ResourceType } from "@/models"
import { $t } from "@/plugins/i18n"
import { Actions, store } from "@/store"

import { debounceWithThrottle } from "./debounceWithThrottle"
import { replaceDashToSpace, toLowerCaseWithTrim } from "./helpers"

export type ValidationResultField = "existsByUrlName" | "existsByName"

interface ValidationPayload {
  resourceType: ResourceType
  projectId?: string
  notUnique?: boolean
}

interface ValidationParams {
  value: string
  resourceTypes: ResourceType
  checkByField?: string
  checkResultField?: ValidationResultField
  projectId?: string
  includeDemo?: boolean
}

export const isRequired = (value, data?) => {
  const text = value
  const validate = (value, text?) => {
    const stringValue = String(value)
    const isNumber = typeof value === "number"
    if ((value || isNumber) && stringValue && stringValue.trim()) {
      return true
    }
    return $t("validatorsMessage.required", [text]) || $t("This is required")
  }
  return data?.form ? validate(value, data.field) : value => validate(value, text)
}

const isTooLong = (resourceTypes, value) =>
  (resourceTypes === "TEAM" && value.length >= TEAM_TITLE_MAX_LENGTH) ||
  value.length > TITLE_MAX_LENGTH

const validation = async (payload, value, text?, required?) => {
  const { resourceTypes, projectId, includeDemo, checkByField, checkResultField } = payload

  if (value && value.trim()) {
    if (isTooLong(resourceTypes, value)) {
      return $t("validatorsMessage.tooLong", [text]) || $t("Too long")
    }
    if (payload.notUnique) {
      return true
    }
    const lowerCaseValue = toLowerCaseWithTrim(value ?? "")
    const res = (await makeSearchCall({
      value,
      resourceTypes,
      projectId,
      includeDemo,
      checkByField
    })) as any
    const alreadyExistMessage =
      (resourceTypes === "TEAM" && `${value} already used`) ||
      $t("validatorsMessage.exists", [text]) ||
      "Already exists"
    if (
      (res &&
        res.length &&
        res.find(
          e =>
            replaceDashToSpace(toLowerCaseWithTrim(e.textPreview ?? "")) ===
            replaceDashToSpace(lowerCaseValue)
        )) ||
      res[checkResultField]
    ) {
      return alreadyExistMessage
    }
    return true
  }
  if (required) {
    return $t("validatorsMessage.required", [text]) || "This is required"
  }
}

export const personNameValidate = fieldName => {
  return value => {
    if (!value) {
      return `${fieldName} is required`
    }

    const prohibitedCharacters = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/
    if (prohibitedCharacters.test(value)) {
      return `${fieldName} contains prohibited characters`
    }

    return true
  }
}

export const prohibitedCharacters = fieldName => {
  return value => {
    const prohibitedCharacters = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/
    if (prohibitedCharacters.test(value)) {
      return `${fieldName} contains prohibited characters`
    }

    return true
  }
}

export const isRequiredAndValid = (
  payload: ValidationPayload,
  value: string,
  data?,
  skipValid?: boolean
) => {
  if (skipValid) {
    return isRequired(value, data)
  }
  const text = value
  return data?.form
    ? validation(payload, value, true)
    : value => validation(payload, value, text, true)
}

export const isValid = (resourceType: ResourceType, value: string, data?) => {
  const text = value
  return data?.form
    ? validation(resourceType, value)
    : value => validation(resourceType, value, text)
}

const makeSearchCall = debounceWithThrottle(
  async (params: ValidationParams) =>
    new Promise(resolve => {
      const { resourceTypes, value } = params
      switch (resourceTypes) {
        case "PROJECT":
          resolve(
            store.dispatch(Actions.CHECK_EXIST_PROJECT_BY_URL_OR_NAME, {
              name: value.toLowerCase().trim(),
              checkByField: params.checkByField
            })
          )
          break
        case "ACCOUNT":
          resolve(store.dispatch(Actions.CHECK_ACCOUNT_NAME, value.toLowerCase()))
          break
        case "TEAM":
          resolve(store.dispatch(Actions.CHECK_TEAM_NAME, value.toLowerCase()))
          break
        default:
          resolve(
            store.dispatch(Actions.SEARCH_RESOURCE, {
              text: value.toLowerCase().trim(),
              resourceTypes,
              projectId: params.projectId,
              includeDemo: params.includeDemo
            })
          )
          break
      }
    }),
  150
)

const makeTestApiCall = async value =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(value === "a@a.a" ? true : false)
    }, 500)
  })

export const validateEmail = async value => {
  const res = await makeTestApiCall(value)
  return res ? true : "This email is already taken"
}

export const validateUtilization = value => {
  if (value && value.trim()) {
    if (value.replace("%", "") > 0) {
      return true
    }
    return "Utilization should be > 0"
  }
  return "Utilization is required"
}

export const validateProjectPrefix = value => {
  if (value && value.trim()) {
    if (value.length > 0 && value.length <= PROJECT_PREFIX_MAX_LENGTH) {
      return true
    }
    return $t("Prefix too long")
  }
  return "Prefix is required"
}

export const isEmail = (value, data?) => {
  const text = value

  const validate = (value, _text?) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (emailRegex.test(String(value).toLowerCase())) {
      return true
    }
    return "Email required" || "This is not a valid email"
  }

  return data?.form ? validate(value, data.field) : value => validate(value, text)
}

export const isEmailAndDuplicate = (value, { existingEmails }) => {
  if (!value) {
    return "Required"
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailRegex.test(String(value).toLowerCase())) {
    return "Invalid"
  }

  const emailCount = existingEmails.filter(
    email => email.toLowerCase() === value.toLowerCase()
  ).length
  if (emailCount > 1) {
    return "Duplicated"
  }

  return true
}

export default {
  isRequired,
  isRequiredAndValid,
  validateEmail,
  validateUtilization,
  isValid,
  isEmail,
  isEmailAndDuplicate,
  validateProjectPrefix,
  personNameValidate,
  prohibitedCharacters
}
