<template>
  <div class="avatar-upload__area custom-cropper custom-cropper_gray custom-cropper_team">
    <img
      ref="editImage"
      style="display: none;"
      :src="url"
      class="custom-cropper__image"
    >
  </div>
  <div class="avatar-upload__area-button">
    <ControlButtons
      @save="onSave"
      @cancel="onCancel"
    >
      <template #save>
        Upload
      </template>
    </ControlButtons>
  </div>
</template>

<script lang="ts">
import { Prop, Mixins } from "vue-property-decorator"
import { CropperInit } from "@/mixins/cropperInit"
import { Options } from "vue-class-component"
import ControlButtons from "@/components/ControlButtons/index.vue"
const components = {
  ControlButtons,
}
@Options({
  name: "AvatarUploadSelf",
  components,
})

export default class AvatarUploadSelf extends Mixins(CropperInit) {
  @Prop(String) readonly url!: string

  mounted() {
    this.onOpen()
  }
}
</script>
