import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 4,
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsProjectsBlock = _resolveComponent("BreadcrumbsProjectsBlock")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_TeamSelectionDropdown = _resolveComponent("TeamSelectionDropdown")!
  const _component_BreadcrumbsTeamsBlock = _resolveComponent("BreadcrumbsTeamsBlock")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.displayProjectsBlock && _ctx.$route.params.projectId)
      ? (_openBlock(), _createBlock(_component_BreadcrumbsProjectsBlock, {
          key: 0,
          onOnTeamClick: _ctx.onTeamClick
        }, null, 8, ["onOnTeamClick"]))
      : _createCommentVNode("", true),
    (_ctx.displayTeamSelectionBlock || _ctx.breadcrumbSelectedTeam)
      ? (_openBlock(), _createBlock(_component_TeamSelectionDropdown, {
          key: 1,
          selectedTeam: _ctx.breadcrumbSelectedTeam ?? _ctx.selectedTeam,
          optionLabelSize: "md",
          className: "team-selection",
          onOnSelectTeam: _ctx.onSelectDropdownTeam
        }, {
          singleLabel: _withCtx(({ option }) => [
            _createVNode(_component_BaseSubHeading, {
              size: "large",
              weight: "400",
              color: "monochrome-05",
              className: "header__breadcrumb"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" /"),
                _createElementVNode("span", null, _toDisplayString(option.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["selectedTeam", "onOnSelectTeam"]))
      : (_ctx.displayTeamNavigationBlock)
        ? (_openBlock(), _createBlock(_component_BreadcrumbsTeamsBlock, {
            key: 2,
            onOnMenuItemClick: _ctx.onSprintSelected
          }, null, 8, ["onOnMenuItemClick"]))
        : (_ctx.$route.params.teamId && _ctx.selectedTeam?.name && !_ctx.$route?.meta?.hideTeamLabel)
          ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
              key: 3,
              size: "large",
              weight: "400",
              color: "monochrome-05",
              className: "header__breadcrumb"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" /"),
                _createElementVNode("span", null, _toDisplayString(_ctx.selectedTeam.name), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
    (_ctx.sprintLabel)
      ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            color: "monochrome-05",
            className: "header__breadcrumb"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" /"),
              _createElementVNode("span", null, _toDisplayString(_ctx.sprintLabel), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbConfigList, (breadcrumbConfig, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (!_ctx.isBreadcrumbTitleWaiting(breadcrumbConfig) && _ctx.breadcrumbLink(breadcrumbConfig))
          ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
              key: 0,
              size: "large",
              weight: "400",
              color: "monochrome-05",
              className: "header__breadcrumb"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" / "),
                _createVNode(_component_router_link, {
                  to: _ctx.breadcrumbLink(breadcrumbConfig)?.route
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.breadcrumbLink(breadcrumbConfig)?.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1024))
          : (!_ctx.isBreadcrumbTitleWaiting(breadcrumbConfig) && _ctx.breadcrumbTitle(breadcrumbConfig))
            ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                key: 1,
                size: "large",
                weight: "400",
                color: "monochrome-05",
                className: "header__breadcrumb"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" /"),
                  _createElementVNode("span", null, _toDisplayString(_ctx.breadcrumbTitle(breadcrumbConfig)), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.breadcrumbComponent(breadcrumbConfig))))
      ], 64))
    }), 128))
  ], 64))
}