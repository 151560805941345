import { FileMetaData, IFile } from "./File"
import { User } from "./User"
import { Task } from "./Task"
import { CommentSourceType, ResourceType } from "./common"
import {
  formatDateAgo,
  parseDate,
  formatDate,
} from "@/utils/dateUtil"
import { ReferencedResourcePreviews } from "./Search"
import { StructuredDescription } from "./EditorData"
import {
  updateReferencesByResourcePreviews,
} from "@/utils/updateReferencesByResourcePreviews"
export interface ReturnCommentObj {
  structuredDescription: StructuredDescription;
  targetResourceId?: CommentTarget
  source?: Source;
}

export interface PayloadCommentCount {
  projectId?: string,
  targetId: string,
  onlyGet?: boolean
}

export interface CommentTarget {
  id: string;
  type?: ResourceType;
}

export type CommentProps = Partial<Comment>

export enum CommentContentType {
  REGULAR = "REGULAR",
  UNIMPLEMENTED = "UNIMPLEMENTED",
  TITLE = "TITLE",
  STRUCTURED_DESCRIPTION = "STRUCTURED_DESCRIPTION",
  OWNER = "OWNER",
  STATUS = "STATUS",
  ITEM_TYPE = "ITEM_TYPE",
  EPIC = "EPIC",
  SEVERITY_LEVEL = "SEVERITY_LEVEL",
  STORY_POINTS = "STORY_POINTS",
  TEAM = "TEAM",
  SPRINT = "SPRINT",
  TASKS = "TASKS",
  DEFINITIONS_OF_DONE = "DEFINITIONS_OF_DONE",
  BACKLOG_PRIORITY = "BACKLOG_PRIORITY",
  MOVE_TO_TASK = "MOVE_TO_TASK",
}

export const commentTypeByAuditRecord: Record<string, CommentContentType> = {
  "regular": CommentContentType.REGULAR,
  "unimplemented": CommentContentType.UNIMPLEMENTED,
  "title": CommentContentType.TITLE,
  "structuredDescription": CommentContentType.STRUCTURED_DESCRIPTION,
  "owner": CommentContentType.OWNER,
  "status": CommentContentType.STATUS,
  "itemType": CommentContentType.ITEM_TYPE,
  "epic": CommentContentType.EPIC,
  "severityLevel": CommentContentType.SEVERITY_LEVEL,
  "storyPoints": CommentContentType.STORY_POINTS,
  "team": CommentContentType.TEAM,
  "sprint": CommentContentType.SPRINT,
  "tasks": CommentContentType.TASKS,
  "definitionsOfDone": CommentContentType.DEFINITIONS_OF_DONE,
  "backlogPriority": CommentContentType.BACKLOG_PRIORITY,
  "moveToTask": CommentContentType.MOVE_TO_TASK,
}

export interface Source {
  sourceId: string;
  sourceType: CommentSourceType;
}

export class CommentByItem {
  comment: Comment
  itemId: string

  constructor(props: CommentByItem) {
    this.comment = Comment.create(props.comment)
    this.itemId = props.itemId
  }
}

export interface CommentItemAuditItem {
  title: string | null,
  structuredDescription: { html: string } | null,
  owner: User | null,
  status: string | null,
  itemType: string | null,
  epicName: string | null,
  epicSequenceNumber: number | null,
  severityLevel: string | null,
  storyPoints: number | null,
  teamName: string | null,
  teamPictureUrl: { pictureUrl: string } | null,
  sprintSequenceNumber: number | null,
  sprintStatus: string | null,
  continuousSprint: boolean | null,
  backlogPriority: number | null,
  definitionOfDoneName: string | null,
  definitionOfDoneState: boolean | null
  sequenceNumber: number | null,
}

export interface CommentItemAuditRecord {
  after: CommentItemAuditItem
  before: CommentItemAuditItem
  fieldName: string
  taskBefore: Task | null
  taskAfter: Task | null
}

export class Comment {
  targetResourceId?: CommentTarget
  source?: Source
  id: string
  owner?: User
  createdDate: Date
  fileMetadata?: FileMetaData[]
  structuredDescription: StructuredDescription
  referencedResourcePreviews?: ReferencedResourcePreviews
  itemAuditRecord?: CommentItemAuditRecord
  type: CommentContentType

  constructor(props?: CommentProps) {
    this.setDefaultData()
    if (!props) return

    this.id = props.id ?? ""

    this.structuredDescription = props.structuredDescription ?? new StructuredDescription()
    this.targetResourceId = props.targetResourceId ?? undefined
    this.source = props.source
    this.owner = props.owner ? User.create(props.owner) : undefined
    this.createdDate = props.createdDate
      ? parseDate(String(props.createdDate), true)
      : new Date()
    this.fileMetadata = props.fileMetadata?.length
      ? props.fileMetadata
        .map((file: IFile) => FileMetaData.create(file))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      : []

    this.itemAuditRecord = props.itemAuditRecord ?? undefined
    this.type = commentTypeByAuditRecord[this.itemAuditRecord?.fieldName ?? "regular"] ?? commentTypeByAuditRecord["unimplemented"]
    if (props.referencedResourcePreviews) {
      this.structuredDescription.html = updateReferencesByResourcePreviews(props.referencedResourcePreviews, this.structuredDescription.html)
    }
  }

  setDefaultData() {
    this.targetResourceId = undefined,
    this.source = undefined,
    this.id = "",
    this.owner = undefined,
    this.createdDate = new Date(),
    this.fileMetadata = [],
    this.structuredDescription = new StructuredDescription()
    this.referencedResourcePreviews = undefined,
    this.itemAuditRecord = undefined,
    this.type = CommentContentType.REGULAR
  }

  get targetId() {
    return this.targetResourceId?.id ?? ""
  }

  get targetType() {
    return this.targetResourceId?.type ?? ""
  }

  getJsonObj(): ReturnCommentObj {
    return {
      structuredDescription: this.structuredDescription,
      targetResourceId: this.targetResourceId,
      source: this.source,
    }
  }

  get createdDateDate() {
    return parseDate(String(this.createdDate), true)
  }

  get timeAgo() {
    return formatDateAgo({
      date: new Date(),
      dateFormat: this.createdDate,
      sufixAgo: true,
    })
  }

  get timeAndDate() {
    return formatDate(this.createdDate, "MM/dd/yy h:mm bb")
  }

  get timeAndDateReversed() {
    return formatDate(this.createdDate, "MM/dd/yy hp")
  }

  get dayAndMonth() {
    const format = new Date().getFullYear() - this.createdDate.getFullYear() > 0 ? "MMMM dd, yyyy" : "MMMM dd"
    return formatDate(this.createdDate, format)
  }

  get isRegularComment(): boolean {
    return this.type === CommentContentType.REGULAR
  }

  get unimplemented(): boolean {
    return this.type === CommentContentType.UNIMPLEMENTED
  }

  get subtitleLabel(): string {
    switch (this.type) {
      case CommentContentType.TITLE:
        return "changed Title"
      case CommentContentType.OWNER:
        return "changed Owner"
      case CommentContentType.STRUCTURED_DESCRIPTION:
        return "updated Description"
      case CommentContentType.ITEM_TYPE:
        return `${this.before?.itemType === "RETROSPECTIVE_NOTE" ? "сreated Backlog Item from Retrospective Note" : "changed Type"}`
      case CommentContentType.TEAM:
        return "changed Team"
      case CommentContentType.EPIC:
        return "changed Epic"
      case CommentContentType.STORY_POINTS:
        return "changed Story Points"
      case CommentContentType.STATUS:
        return "updated Status"
      case CommentContentType.REGULAR:
        return "left a comment"
      case CommentContentType.SPRINT:
      case CommentContentType.BACKLOG_PRIORITY:
        return "moved Item"
      case CommentContentType.SEVERITY_LEVEL:
        return "updated Severity"
      case CommentContentType.TASKS:
        return this.itemAuditRecord?.taskBefore ? "updated Task" : "added Task"
      case CommentContentType.DEFINITIONS_OF_DONE:
        return `${this.before?.definitionOfDoneName === null || JSON.stringify(this.before) === "{}" ? "added" : "updated"} Definition of Done`
      case CommentContentType.MOVE_TO_TASK:
        return "moved Task to New Item"
      default:
        return "audit comment"
    }
  }

  get contentComponentName(): string {
    if (this.isRegularComment) {
      return "REGULAR_COMMENT"
    }
    if (this.unimplemented) {
      return "AUDIT_COMMENT_UNIMPLEMENTED"
    }
    return `AUDIT_COMMENT_${this.type}_CHANGED`
  }

  get before(): CommentItemAuditItem | null {
    return this.itemAuditRecord?.before ?? null
  }

  get after(): CommentItemAuditItem | null {
    return this.itemAuditRecord?.after ?? null
  }

  static create(props: CommentProps): Comment {
    return new Comment(props)
  }
}
