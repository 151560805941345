import { ToDoRelatedResourceId } from "./ToDo"

export class ToDoContent {
  addMode?: boolean
  hideOnCancel?: boolean
  description?: string
  projectId?: string
  removePrevious?: boolean
  relatedResourceId?: ToDoRelatedResourceId | null
  teamId?: string
  demo?: boolean
  constructor(props?: ToDoContent) {
    if (props) {
      const {
        addMode,
        description,
        projectId,
        removePrevious,
        relatedResourceId,
        teamId,
        demo,
      } = props

      this.addMode = addMode
      this.description = description
      this.projectId = projectId
      this.removePrevious = removePrevious
      this.relatedResourceId = relatedResourceId
      this.teamId = teamId
      this.demo = demo
    }
  }

  static createDefault(): ToDoContent {
    return new ToDoContent({
      addMode: false,
      description: "",
      hideOnCancel: false,
      projectId: "",
      removePrevious: false,
      relatedResourceId: null,
    })
  }
}
