import { Notification } from "./Notification"

export class NotificationTeamAddition extends Notification {
  constructor(props) {
    super(props)
  }

  get companyName() {
    return this.content?.companyName ?? ""
  }

  get acceptanceUrl() {
    return this.content?.acceptanceUrl ?? ""
  }

  get teamName() {
    return this.content?.teamName ?? ""
  }
}
