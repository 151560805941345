<template>
  <ReferenceComponentParentList
    :searchList="result"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    class="reference-list_md"
    @onClick="onEpicSelected"
  >
    <template #header>
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
      >
        Epics
      </BaseSubHeading>
    </template>

    <template #item="{ option }">
      <div class="reference-list__item-in">
        <span>
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-06"
          >
            E-{{ option.sequenceNumber }}
          </BaseSubHeading>
        </span>

        <span>
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-07"
          >
            {{ option.name }}
          </BaseSubHeading>
        </span>
        <span>
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-05"
            darkColor="monochrome-04"
          >
            {{ option.percentComplete }}% Done
          </BaseSubHeading>
        </span>
      </div>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { Project, SearchEpicResult } from "@/models"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"

const components = {
  ReferenceComponentParentList,
}

@Options({
  name: "EpicList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.SEARCH_RESOURCE) declare result: SearchEpicResult[]

  @Action(Actions.SEARCH_EPIC) searchEpic: (payload: { epicNumber: string }) => Promise<void>
  @Action(Actions.GET_PROJECTS) getProjects: () => Promise<Project[]>

  componentType = ReferenceType.EPIC
  waitTitle = "reference.search.epic"
  filter: {
    epicNumber: string,
  } = {
      epicNumber: "",
    }

  async created() {
    this.searchAction = this.searchEpic
  }

  onEnterHandler() {
    if (this.result) {
      this.onEpicSelected(this.result[this.selectedIndex])
    }
  }

  onEpicSelected(payload) {
    this.selectItem({
      item: payload,
      payload: {},
    })
  }

  queryUpdated(q) {
    this.filter.epicNumber = q.substring(1)
    this.search()
  }

}
</script>
