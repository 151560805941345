import { Notification, NotificationMilestoneContent } from "./Notification"

export class NotificationMilestone extends Notification {
  constructor(props) {
    super(props)
  }

  get milestone() {
    return this.content?.milestone
  }

  get milestoneName() {
    return `Milestone ${this.milestone?.title ?? ""}`
  }

  get totalItemsCount() {
    return this.milestone?.totalItemsCount ?? ""
  }

  get completedItemsCount() {
    return this.milestone?.completedItemsCount ?? ""
  }

  get percentComplete() {
    return this.milestone?.percentComplete ?? ""
  }

  get url() {
    return this.content?.url ?? ""
  }

  get milestoneDate() {
    return `${this.milestone.startDate} - ${this.milestone.endDate}`
  }
}
