import { ProjectPreview } from "./Project"
import { MilestonePreview } from "./Milestone"

export type GoalStatus = "CURRENT" | "NEXT_IN_LINE" | "CLOSE"

export interface GoalDTO {
  id: string
  status: GoalStatus
  content: string
  important: string
  whyNow:string
  timeline: string
  videoUrl: string
  project: ProjectPreview
  milestone?: MilestonePreview
  priority: number | null
  featuresCount: number
  epicsCount: number
  itemsCount: number
  completedItemsCount: number
}

export interface GoalProps {
  id?: string
  status?: GoalStatus
  content: string
  important: string
  whyNow: string
  timeline: string
  videoUrl?: string
  project: ProjectPreview
  milestone?: MilestonePreview
  priority?: number | null
  featuresCount?: number
  epicsCount?: number
  itemsCount?: number
  completedItemsCount?: number
}

export class Goal {
  id: string
  status: GoalStatus
  content: string
  important: string
  whyNow: string
  timeline: string
  videoUrl?: string
  project?: ProjectPreview
  milestone?: MilestonePreview
  priority?: number | null
  featuresCount?: number
  epicsCount?: number
  itemsCount?: number
  completedItemsCount?: number


  constructor(props?: GoalProps) {
    this.setDefaultData()

    if (!props) return

    const {
      id,
      status,
      content,
      important,
      whyNow,
      timeline,
      videoUrl,
      project,
      milestone,
      priority,
      featuresCount,
      epicsCount,
      itemsCount,
      completedItemsCount,
    } = props

    this.id = id ?? ""
    this.status = status ?? "NEXT_IN_LINE"
    this.content = content
    this.important = important
    this.whyNow = whyNow
    this.timeline = timeline
    this.videoUrl = videoUrl
    this.project = project
    this.milestone = milestone
    this.priority = priority
    this.featuresCount = featuresCount
    this.epicsCount = epicsCount
    this.itemsCount = itemsCount
    this.completedItemsCount = completedItemsCount
  }

  static create(goalDTO?: GoalDTO): Goal {
    if (goalDTO) {
      const {
        id,
        status,
        content,
        important,
        whyNow,
        timeline,
        videoUrl,
        project,
        milestone,
        priority,
        featuresCount,
        epicsCount,
        itemsCount,
        completedItemsCount,
      } = goalDTO

      return new Goal({
        id,
        status,
        content,
        important,
        whyNow,
        timeline,
        videoUrl,
        project,
        milestone,
        priority,
        featuresCount,
        epicsCount,
        itemsCount,
        completedItemsCount,
      })
    }
    return new Goal()
  }

  get projectId(): string {
    return this.project?.id ?? ""
  }

  setDefaultData() {
    this.id = ""
    this.content = ""
    this.status = "NEXT_IN_LINE"
    this.important = ""
    this.whyNow = ""
    this.timeline = ""
    this.videoUrl = ""
    this.project = undefined
    this.milestone = undefined
    this.priority = null
    this.featuresCount = 0
    this.epicsCount = 0
    this.itemsCount = 0
    this.completedItemsCount = 0
  }

  static createDefault(): Goal {
    return new Goal()
  }

  getJsonObj() {
    return {
      content: this.content,
      important: this.important,
      whyNow: this.whyNow,
      timeline: this.timeline,
      milestoneId: this.milestone?.id,
      projectId: this.project?.id,
      status: this.status,
      videoUrl: this.videoUrl,
    }
  }

  get isCurrent() {
    return this.status === "CURRENT"
  }

  get isFuture() {
    return this.status === "NEXT_IN_LINE"
  }
}
