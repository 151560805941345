<template>
  <div
    ref="text"
    class="referenced-text"
    :class="{'is-reset-style': resetStyle}"
  />
</template>

<script lang="ts">
import { EditorData } from "@/models/EditorData"
import { Options, Vue } from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import InitComponent from "./InitComponent.vue"

@Options({
  name: "ReferencedText",
})
export default class ReferencedText extends Vue {
  @Prop({ default: "" }) html: string
  @Prop({ default: false }) resetStyle: boolean

  @Ref("text") readonly el: HTMLDivElement

  mounted() {
    this.createElement()
  }

  updated() {
    this.createElement()
  }

  createElement() {
    this.el.innerHTML = ""
    const editorData = new EditorData({
      "USER": InitComponent,
      "PROJECT": InitComponent,
      "EPIC": InitComponent,
      "MILESTONE": InitComponent,
      "TEAM": InitComponent,
      "SPRINT": InitComponent,
      "ITEM": InitComponent,
      "TASK": InitComponent,
      "FILE": InitComponent,
    })
    editorData.setHtml(this.html)
    this.el.append(editorData.getHtmlElement())
  }
}

</script>
