import { ProjectPreview } from "./Project"

export interface FeatureDTO {
  id: string
  name: string
  project: ProjectPreview
  epicsCount: number
  roadmapPriority: number | null
  goalPriority: number | null
}

export interface FeatureProps {
  id: string
  name: string
  project: ProjectPreview
  epicsCount: number
  roadmapPriority?: number | null
  goalPriority?: number | null
}

export class Feature {
  id: string
  name: string
  project?: ProjectPreview
  epicsCount: number
  roadmapPriority: number | null
  goalPriority: number | null

  constructor(props?: FeatureProps) {
    this.setDefaultData()

    if (!props) return

    const {
      id,
      name,
      project,
      epicsCount,
      roadmapPriority,
      goalPriority,
    } = props

    this.id = id ?? ""
    this.name = name
    this.project = project
    this.epicsCount = epicsCount
    this.roadmapPriority = roadmapPriority ?? null
    this.goalPriority = goalPriority ?? null
  }

  static create(featureDTO?: FeatureDTO): Feature {
    if (featureDTO) {
      const {
        id,
        name,
        project,
        epicsCount,
        roadmapPriority,
        goalPriority,
      } = featureDTO

      return new Feature({
        id,
        name,
        project,
        epicsCount,
        roadmapPriority,
        goalPriority,
      })
    }
    return new Feature()
  }

  get projectId(): string {
    return this.project?.id ?? ""
  }

  setDefaultData() {
    this.id = ""
    this.name = ""
    this.epicsCount = 0
    this.project = undefined
    this.roadmapPriority = 0
    this.goalPriority = 0
  }

  static createDefault(): Feature {
    return new Feature()
  }

  getJsonObj() {
    return {
      name: this.name,
    }
  }
}
