import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "to-do__header" }
const _hoisted_2 = { class: "to-do__content" }
const _hoisted_3 = { class: "to-do__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    as: "div",
    class: "to-do"
  }, {
    default: _withCtx(({ validate, handleReset }) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {
          validate: validate,
          handleReset: handleReset
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content", {
          validate: validate,
          handleReset: handleReset
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "footer", {
          validate: validate,
          handleReset: handleReset
        })
      ])
    ]),
    _: 3
  }))
}