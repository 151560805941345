import { Module, Action } from "vuex-class-modules"
import { store } from "../store"
import { BaseModule } from "@/models/BaseModule"
import { Endpoint } from "@/services/endpoints"
import { Modules } from "../modules"
import { apiService } from "@/services/api.service"

export enum EmailVerificationActions {
  EMAIL_VERIFICATION_SEND = "EMAIL_VERIFICATION_SEND",
  EMAIL_VERIFICATION_RESEND = "EMAIL_VERIFICATION_RESEND",
}

@Module
class EmailVerificationModule extends BaseModule {
  @Action
  async [EmailVerificationActions.EMAIL_VERIFICATION_SEND]() {
    const data = await apiService.post<any>(Endpoint.EMAIL_VERIFICATION_SEND())
    return this.handleResponse<void>(data)
  }

  @Action
  async [EmailVerificationActions.EMAIL_VERIFICATION_RESEND]() {
    const data = await apiService.post<any>(Endpoint.EMAIL_VERIFICATION_RESEND())
    return this.handleResponse<void>(data)
  }
}

export const emailVerificationModule = new EmailVerificationModule({
  store,
  name: Modules.EMAIL_VERIFICATION,
})
