import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/triangle_right.svg'


const _hoisted_1 = { class: "multiselect__option_team-name" }
const _hoisted_2 = {
  key: 1,
  class: "header__breadcrumb-menu-item_backlogs"
}
const _hoisted_3 = { class: "multiselect__option_team-name" }
const _hoisted_4 = { class: "multiselect__option__avatar" }
const _hoisted_5 = { class: "multiselect__option__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!

  return (_ctx.projectName)
    ? (_openBlock(), _createBlock(_component_DropdownTooltip, {
        key: 0,
        placement: "bottom-start",
        placementSub: "right-start",
        menu: _ctx.options,
        popperClassSub: "sprint-selection header__breadcrumb-menu",
        optionMenuClass: "header__breadcrumb-menu-item",
        popperClass: "project-selection header__breadcrumb-menu",
        subMenuClass: "header__breadcrumb-menu-item",
        submenuWaitTitle: "projectTeams",
        onDropdownOptionMenu: _ctx.onTeamClick
      }, {
        "tooltip-label": _withCtx(() => [
          _createVNode(_component_BaseSubHeading, {
            size: "large",
            weight: "400",
            color: "monochrome-05",
            className: "header__breadcrumb"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" /"),
              _createElementVNode("span", null, _toDisplayString(_ctx.projectName), 1)
            ]),
            _: 1
          })
        ]),
        "tooltip-item": _withCtx(({ item }) => [
          (item?.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "header__breadcrumb-menu-item_backlogs router-link-wrapper",
                to: item.to
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1),
                  (!item.hideOptions)
                    ? (_openBlock(), _createBlock(_component_icon, {
                        key: 0,
                        width: "6px",
                        height: "8px",
                        data: _imports_0,
                        fill: "false",
                        color: _ctx.iconColor,
                        class: "team-selection__arrow"
                      }, null, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1),
                (!item.hideOptions)
                  ? (_openBlock(), _createBlock(_component_icon, {
                      key: 0,
                      width: "6px",
                      height: "8px",
                      data: _imports_0,
                      fill: "false",
                      color: _ctx.iconColor,
                      class: "team-selection__arrow"
                    }, null, 8, ["color"]))
                  : _createCommentVNode("", true)
              ]))
        ]),
        "tooltip-submenu-item": _withCtx(({ option }) => [
          _createVNode(_component_router_link, {
            class: "sprint-label router-link-wrapper",
            to: {
          name: 'backlog',
          params: {
            projectId: _ctx.selectedProject.urlName,
            teamId: option.urlName,
          },
        }
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_Avatar, {
                  src: option.picture?.pictureUrl,
                  size: _ctx.optionLabelSize,
                  type: "teamsGroup",
                  class: "user__avatar"
                }, null, 8, ["src", "size"])
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(option.name), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["menu", "onDropdownOptionMenu"]))
    : _createCommentVNode("", true)
}