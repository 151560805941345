import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@icon/backlog-icon.svg'
import _imports_1 from '@icon/arrow-to.svg'


const _hoisted_1 = { class: "label-pic is-teal" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "notification__update-item__initiator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__update-item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "link is-large",
            target: "_blank",
            href: _ctx.notification.itemUrl
          }, " Story " + _toDisplayString(_ctx.notification.itemSequenceNumber), 9, _hoisted_2),
          _createTextVNode(" Status has been updated by "),
          _createElementVNode("span", _hoisted_3, "@" + _toDisplayString(_ctx.notification.initiatorFullName), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500",
        className: "notification__update-item__content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["notification__update-item__status is-opacity", [_ctx.oldStatus.stringId]])
          }, _toDisplayString(_ctx.oldStatus.label), 3),
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: true,
            width: "24px",
            height: "24px",
            color: "#616161"
          }),
          _createElementVNode("span", {
            class: _normalizeClass(["notification__update-item__status", [_ctx.actualStatus.stringId]])
          }, _toDisplayString(_ctx.actualStatus.label), 3)
        ]),
        _: 1
      }),
      (_ctx.notification.changeStatusReason)
        ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
            key: 0,
            size: "medium",
            color: "monochrome-06",
            darkColor: "monochrome-03",
            weight: "400",
            className: "notification__update-item__description"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.notification.changeStatusReason), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["notification"]))
}