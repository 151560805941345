import { Module, Mutation, Action } from "vuex-class-modules"
import { store } from "../store"
import { Endpoint } from "@/services/endpoints"
import { Modules } from "../modules"
import { Team, TeamProfile } from "@/models/Team"
import { ServerResponse } from "@/models/ServerResponse"
import { BaseModule } from "@/models/BaseModule"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { $t } from "@/plugins/i18n"
import { Filter } from "@/models/Pageable/Filter"
import { FilterTeam } from "@/models/Filter/FilterTeam"
import { AccountActions, accountModule } from "./account.module"
import { apiService } from "@/services/api.service"
import { UserWithRole } from "@/models"
import { UpdateObjectValue } from "@/models/common"
import { ProjectActions, projectModule } from "./project.module"

export enum TeamActions {
  GET_TEAM = "GET_TEAM",
  SET_TEAM = "SET_TEAM",
  GET_TEAMS = "GET_TEAMS",
  PUT_TEAM = "PUT_TEAM",
  TEAM_PICTURE_UPDATE = "TEAM_PICTURE_UPDATE",
  TEAM_CREATE = "TEAM_CREATE",
  TEAM_UPDATE = "TEAM_UPDATE",
  GET_TEAMS_IN_PROJECT = "GET_TEAMS_IN_PROJECT",
  PICTURE_DELETE = "PICTURE_DELETE",
  GET_TEAMS_RESPONSE = "GET_TEAMS_RESPONSE",
  GET_CURRENT_TEAM_ID = "GET_CURRENT_TEAM_ID",
  SET_CURRENT_TEAM_ID = "SET_CURRENT_TEAM_ID",
  TEAM_USER_DELETE = "TEAM_USER_DELETE",
  TEAM_USER_ADD = "TEAM_USER_ADD",
  GET_CURRENT_TEAM = "GET_CURRENT_TEAM",
  GET_CLEAR_TEAMS = "GET_CLEAR_TEAMS",
  TEAMS_WITH_USERS = "TEAMS_WITH_USERS",
  TEAM_CREATE_WITH_USERS = "TEAM_CREATE_WITH_USERS",
  TEAM_WITH_EXTENDED_USERS = "TEAM_WITH_EXTENDED_USERS",
  UPDATE_TEAM_VALUE = "UPDATE_TEAM_VALUE",
  LOCAL_UPDATE_TEAM_PROFILE = "LOCAL_UPDATE_TEAM_PROFILE",
  FILTER_BY_TEAM = "FILTER_BY_TEAM",
  CHECK_TEAM_NAME = "CHECK_TEAM_NAME"
}

export interface CreateTeamPayload {
  name: string,
  description: string,
  picture: string,
  usersAndRoles: UserWithRole[],
}

export interface AddTeamUsersPayload {
  team: Team;
  userIds: string[];
}

export interface TeamPictureUpdatePayload {
  teamId: string;
  file: File;
}

@Module
class TeamModule extends BaseModule {
  team: Team
  teams: Team[] = []
  teamsWithUsers: Team[] = []
  serverResponse: ServerResponse<Team>
  currentTeamId: string
  filterByTeam: Team | null = null

  get accountId() {
    return accountModule.accountId
  }

  get accountCreating() {
    return accountModule.accountCreating
  }

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${TeamActions.GET_TEAM}`]() {
    return this.team
  }

  get [`get/${TeamActions.GET_TEAMS}`]() {
    return this.teams
  }

  get [`get/${TeamActions.TEAMS_WITH_USERS}`]() {
    return this.teamsWithUsers
  }

  get [`get/${TeamActions.GET_CURRENT_TEAM_ID}`]() {
    return this.currentTeamId
  }

  get [`get/${TeamActions.GET_TEAMS_RESPONSE}`]() {
    return this.serverResponse
  }

  get [`get/${TeamActions.FILTER_BY_TEAM}`]() {
    return this.filterByTeam
  }

  @Mutation
  setTeam(team: Team) {
    this.team = team
    const team_ = this.teams.find(t => t.id === team.id)
    if (team_) {
      team_.name = this.team.name
      team_.description = this.team.description
      team_.users = this.team.users
      team_.enabled = this.team.enabled
    }
  }

  @Mutation
  setTeams(teams: Team[]) {
    this.teams = teams
  }

  @Mutation
  setTeamProfile(teamProfile: TeamProfile) {
    Object.keys(teamProfile).forEach((e) => {
      this.team[e] = teamProfile[e]
    })
  }

  @Mutation
  setTeamsWithUsers(teams: Team[]) {
    this.teamsWithUsers = teams
  }

  @Mutation
  setTeamsResponse(serverResponse: ServerResponse<Team>) {
    this.serverResponse = serverResponse
  }

  @Mutation
  addTeams(teams: Team[]) {
    this.teams = [...this.teams, ...teams]
  }

  @Mutation
  addTeamsWithUsers(teams: Team[]) {
    this.teamsWithUsers = [...this.teamsWithUsers, ...teams]
  }

  @Mutation
  setTeamPictureUrl({ teamId, pictureUrl }: { teamId: string, pictureUrl: string }) {
    if (this.team && this.team.id === teamId) {
      this.team.picture = this.team.picture || {}
      this.team.picture.pictureUrl = pictureUrl
    }
    const team = this.teams.find(t => t.id === teamId)
    if (team) {
      team.picture = team.picture || {}
      team.picture.pictureUrl = pictureUrl
    }
  }

  @Mutation
  setCurrentTeamId(teamId?: string) {
    this.currentTeamId = teamId || this.teams[0].id
  }

  @Mutation
  setTeamValue(payload: UpdateObjectValue<Team>) {
    Object.assign(this.team, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  clearTeams() {
    this.teams = []
  }

  @Mutation
  setFilterByTeam(team: Team | null) {
    this.filterByTeam = team
  }

  @Action
  [TeamActions.SET_CURRENT_TEAM_ID](teamId: string) {
    this.setCurrentTeamId(teamId)
  }

  @Action
  async [TeamActions.GET_TEAMS](params: FilterTeam) {
    const filter = FilterTeam.create(params)
    const data = await apiService.get<ServerResponse<Team>>(Endpoint.TEAMS(this.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Team>>(data, (data) => {
      const content: Team[] = data.content.map(team => Team.create(team))
      this.setTeamsResponse(data)
      if (filter.page === 1) {
        this.setTeams(content)
      } else {
        this.addTeams(content)
      }
      return content
    })
  }

  @Action
  async [TeamActions.CHECK_TEAM_NAME](name: string): Promise<Team[]> {
    const filter = FilterTeam.createDefault()
    filter.q = name.toLowerCase().trim()
    const data = await apiService.get<ServerResponse<Team>>(Endpoint.CHECK_TEAM_NAME(this.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<Team>>(data, (data) => {
      const content = data.content.map(team => ({
        id: team.id,
        textPreview: team.name,
      }))
      return content
    })
  }

  @Action
  async [TeamActions.TEAMS_WITH_USERS](params: FilterTeam) {
    const filter = FilterTeam.create(params)
    const accountId = filter?.accountId ? filter.accountId : this.accountId
    const endpoint = filter.isSuperadmin ?
      Endpoint.TEAMS_SEARCH_ADMIN(accountId) :
      Endpoint.TEAMS_WITH_USERS_SEARCH(accountId)

    const data = await apiService.get<ServerResponse<Team>>(endpoint, filter.getJsonObj())
    return this.handleResponse<ServerResponse<Team>>(data, (data) => {
      const content: Team[] = data.content.map(team => Team.create(team))
      data = { ...data, content }
      if (params.forSearch) {
        return data
      }
      this.setTeamsResponse(data)
      if (filter.page === 1) {
        this.setTeamsWithUsers(content)
      } else {
        this.addTeamsWithUsers(content)
      }
      return data
    })
  }

  @Action
  async [TeamActions.GET_TEAMS_IN_PROJECT](projectId: string) {
    const data = await apiService.get<Team[]>(Endpoint.TEAM_IN_PROJECT(this.accountId, projectId ?? this.projectId))
    return this.handleResponse<Team[]>(data, (data) => {
      this.setTeams(data.map(team => Team.create(team)))
      return data
    })
  }

  @Action
  async [TeamActions.PUT_TEAM](team: Team) {
    const data = await apiService.put<Team>(Endpoint.TEAM_UPDATE_ADMIN(this.accountId, team.id), team.getJsonObj())

    return this.handleResponse<Team>(data, (data) => {
      const createdTeam = Team.create(data)
      const alertContent: AlertContentItem[] = [{
        text: `Team ${$t("alert.updated")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setTeam(createdTeam)
      return createdTeam
    })
  }

  @Action
  async [TeamActions.TEAM_PICTURE_UPDATE](payload: TeamPictureUpdatePayload) {
    const file = new FormData()
    file.append("file", payload.file)
    const data = await apiService.post<string>(Endpoint.TEAM_PICTURE(this.accountId, payload.teamId), file)
    return this.handleResponse<string>(data, (data) => {
      if (!this.accountCreating) {
        const alertContent: AlertContentItem[] = [
          {
            text: "Team ",
            type: "regular",
          },
          {
            text: "Picture ",
            type: "bold",
          },
          {
            text: $t("alert.updated"),
            type: "regular",
          },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }
      this.setTeamPictureUrl({ teamId: payload.teamId, pictureUrl: data })
    })
  }

  @Action
  async [TeamActions.PICTURE_DELETE](teamId: string) {
    const data = await apiService.delete<void>(Endpoint.TEAM_PICTURE(this.accountId, teamId))
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "Team ",
          type: "regular",
        },
        {
          text: "Picture ",
          type: "bold",
        },
        {
          text: $t("alert.deleted"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setTeamPictureUrl({ teamId, pictureUrl: "" })
    })
  }

  @Action
  async [TeamActions.GET_TEAM]({ teamId, onlyGet } : { teamId: string, onlyGet?: boolean }) {
    const data = await apiService.get<Team>(Endpoint.TEAM(this.accountId, teamId))
    return this.handleResponse<Team>(data, (data) => {
      const team = Team.create(data)
      if (!onlyGet) {
        this.setTeam(team)
      }
      return team
    })
  }

  @Action
  async [TeamActions.SET_TEAM](team: Team) {
    this.setTeam(team)
  }

  @Action
  async [TeamActions.TEAM_UPDATE](team: Team) {
    const data = await apiService.patch<Team>(Endpoint.TEAM(this.accountId, team.id), team.getJsonObj())
    return this.handleResponse<Team>(data, (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Team ${$t("alert.updated")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setTeam(Team.create(data))
    })
  }

  @Action
  async [TeamActions.TEAM_CREATE_WITH_USERS](payload) {
    const data = await apiService.post<Team>(Endpoint.TEAM_CREATE_WITH_USERS(this.accountId), payload.getJsonObj())
    return this.handleResponse<Team>(data, (data) => {
      const newTeam = Team.create(data)
      if (!this.accountCreating) {
        const alertContent: AlertContentItem[] = [{
          text: `Team ${$t("alert.created")}`,
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }
      this.setTeam(newTeam)
      return newTeam
    })
  }

  @Action
  async [TeamActions.TEAM_CREATE](payload) {
    const data = await apiService.post<Team>(Endpoint.TEAMS(this.accountId), payload.getJsonObj())
    return this.handleResponse<Team>(data, (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `Team ${$t("alert.created")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setTeam(Team.create(data))
    })
  }

  @Action
  async [TeamActions.TEAM_USER_ADD]({ teamId, userId, teamRole }: { teamId: string, userId: string, teamRole: string }) {
    const data = await apiService.post<Team>(Endpoint.TEAM_USER_ADD(this.accountId, teamId), {
      userId: userId,
      teamRole: teamRole,
    })
    return this.handleResponse<Team>(data, () => {
      if (!this.accountCreating) {
        const alertContent: AlertContentItem[] = [{
          text: `User ${$t("alert.added")}`,
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }
    })
  }


  @Action
  async [TeamActions.TEAM_USER_DELETE]({ teamId, userId } : {teamId: string, userId: string}) {
    const data = await apiService.delete<void>(Endpoint.TEAM_USER_DELETE(this.accountId, teamId, userId))
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [{
        text: `User ${$t("alert.removed")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [TeamActions.GET_CURRENT_TEAM](teamId: string) {
    const data = await apiService.get<Team>(Endpoint.TEAM(this.accountId, teamId))
    return this.handleResponse<Team>(data, data => data)
  }

  @Action
  async [TeamActions.TEAM_WITH_EXTENDED_USERS]({ teamId, onlyGet } : { teamId: string, onlyGet?: boolean}) {
    const data = await apiService.get<Team>(Endpoint.TEAM_WITH_EXTENDED_USERS(this.accountId, teamId))
    return this.handleResponse<Team>(data, (data) => {
      const team = Team.create(data)
      if (!onlyGet) {
        this.setTeam(team)
      }
      return team
    },
    )
  }

  @Action
  [TeamActions.GET_CLEAR_TEAMS]() {
    this.clearTeams()
  }

  @Action
  [TeamActions.FILTER_BY_TEAM](team: Team | null) {
    this.setFilterByTeam(team)
  }

  @Action
  [TeamActions.LOCAL_UPDATE_TEAM_PROFILE](teamProfile: TeamProfile) {
    this.setTeamProfile(teamProfile)
  }

  @Action
  [TeamActions.UPDATE_TEAM_VALUE](payload: UpdateObjectValue<Team>) {
    this.setTeamValue(payload)
  }
}

export const teamModule = new TeamModule({ store, name: Modules.TEAM })
