import { AppContext } from "vue"
import { Store as VuexStore } from "vuex"

export const Context = <T extends { new (...args: any[]): { $context: AppContext; } }>(constructor: T) => class extends constructor {
  $context: AppContext = decoratorService._context
}

export const Store = <T extends { new (...args: any[]): { $store?: VuexStore<any> } }>(constructor: T) => class extends constructor {
  $store: VuexStore<any> = decoratorService._store
}

class DecoratorService {
  _context: AppContext
  _store: VuexStore<any>

  setContext(context: AppContext) {
    this._context = context
  }

  setStore(store: VuexStore<any>) {
    this._store = store
  }
}

export const decoratorService = new DecoratorService()
