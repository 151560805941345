import { Filter } from "../Pageable"

export type ResourceSearchTypes = "ACCOUNT" | "PROJECT" | "SPRINT" | "ITEM" | "TODO_ITEM" | "COMMENT" | "USER" | "TEAM" | "INVITATION" | "STORY_POKER" | "EPIC" |
            "FILE_METADATA" | "MILESTONE" | "LABEL" | "ITEM_SNAPSHOT" | "SPRINT_REVIEW" | "SPRINT_STATS" | "FIVE_WHYS" | "PROJECT_STATS" | "NOTIFICATION" |
            "BUG_WATERMARK_CHECK_LOG" | "RETROSPECTIVE" | "TASK" | "FEATURE" | "GOAL" | "EPIC_SNAPSHOT" | "MILESTONE_REVIEW" | "EMAIL_VERIFICATION"

export class LabelSearchFilter extends Filter {
  q = ""
  type: ResourceSearchTypes = "ITEM"

  constructor(props) {
    super(props)
    if (!props) return

    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  getJsonObj() {
    const data = {
      q: this.q,
      type: this.type,
    }
    return super.getJsonObj({ params: data })
  }
}
