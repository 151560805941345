import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/sprint-alert.svg'


const _hoisted_1 = { class: "label-pic is-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_NotificationSprintHeader = _resolveComponent("NotificationSprintHeader")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, { notification: _ctx.notification }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          color: "#616161",
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NotificationSprintHeader, { sprint: _ctx.sprintContent }, {
            default: _withCtx(() => [
              _createTextVNode(" Halted ")
            ]),
            _: 1
          }, 8, ["sprint"])
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400",
        className: "notification__desc"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.sprintContent?.haltReasonSummary), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["notification"]))
}