import { Sprint, SprintMetrics } from "./Sprint"
import { ItemInSprintReview } from "./Item"

export class SprintReviewContent {
  completed = 0
  remaining = 0
  blocked = 0
  notStarted = 0
  id = ""
  projectId = ""
  createdDate = ""
  items: ItemInSprintReview[] = []

  constructor(props?: Partial<SprintReviewContent>) {
    if (!props) return
    this.completed = props.completed ?? 0
    this.remaining = props.remaining ?? 0
    this.blocked = props.blocked ?? 0
    this.notStarted = props.notStarted ?? 0
    this.id = props.id ?? ""
    this.projectId = props.projectId ?? ""
    this.createdDate = props.createdDate ?? ""
    this.items = props.items?.map(e => ItemInSprintReview.create(e)) ?? []
  }
}

export class SprintReview {
  sprint: Sprint
  stats: SprintMetrics
  review: SprintReviewContent


  constructor(props?) {
    this.setDefaultData()

    if (!props) return
    this.sprint = Sprint.create(props.sprint)
    this.stats = props.stats
    this.review = new SprintReviewContent(props.review)
  }

  setDefaultData() {
    this.review = new SprintReviewContent()
    this.sprint = new Sprint()
    // this.stats = {}
  }

  static create(props: SprintReview): SprintReview {
    return new SprintReview(props)
  }
}
