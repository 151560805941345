<template>
  <p class="toast__message body-text">
    <span
      v-for="(message, index) in content"
      :key="index"
      :class="`body-text-medium-${message.type === 'bold' ? '600' : '400'}`"
    >{{ message.text }}</span>
  </p>
</template>

<script>
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "AlertText",
})
export default class extends Vue {
  @Prop({ default: [] }) content
}
</script>
