<template>
  <ReferenceComponentParentList
    :searchList="result"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    @onClick="onTeamSelected"
  >
    <template #header>
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
      >
        Teams
      </BaseSubHeading>
    </template>

    <template #item="{ option }">
      <div class="reference-team">
        <div class="reference-team__left">
          <Avatar
            :src="option.picture?.pictureUrl"
            size="md"
          />
        </div>
        <div class="reference-team__right">
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-07"
          >
            {{ option.name }} ({{ option.size }})
          </BaseSubHeading>
        </div>
      </div>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import Avatar from "@/components/Avatar/index.vue"
import { SearchTeamResult, Project } from "@/models"
import { Getters, Actions } from "@/store"
import { Getter, Action } from "s-vuex-class"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"

const components = {
  ReferenceComponentParentList,
  Avatar,
}

@Options({
  name: "TeamList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.SEARCH_RESOURCE) declare result: SearchTeamResult[]
  @Getter(Getters.GET_PROJECT) declare currentProject: Project

  @Action(Actions.SEARCH_TEAM) findTeams: (params) => Promise<void>

  componentType = ReferenceType.TEAM
  waitTitle = "reference.search.team"
  filter: {
    teamName: string,
    projectId: string,
    demo: boolean,
  } = {
      teamName: "",
      projectId: "",
      demo: false,
    }

  async mounted() {
    this.filter.projectId = this.projectId
    this.filter.demo = this.currentProject?.demo ?? false
    this.searchAction = this.findTeams
  }

  queryUpdated(q) {
    this.filter.teamName = q.substring(1) ?? ""
    this.search()
  }

  onEnterHandler() {
    this.onTeamSelected(this.result[this.selectedIndex])
  }

  onTeamSelected(payload) {
    this.selectItem({
      item: payload,
      payload: {},
    })
  }
}
</script>
