<template>
  <div class="custom-editor__picker custom-editor__button">
    <button
      ref="picker"
      v-click-outside="hideColorPicker"
      type="button"
      :tabindex="-1"
      class="custom-editor__picker-button heading-level heading-level-3"
      :title="title"
      @click.prevent="toggleColorPicker"
    >
      <icon
        :data="icon"
        width="1em"
        height="1em"
      />
    </button>
    <ul
      v-if="showColorPicker"
      ref="pickerDropdown"
      class="custom-editor__picker-list"
      :style="{ top: top + 'px', left: left + 'px' }"
    >
      <li
        v-for="(color, index) in colors"
        :key="index"
        :class="{ 'is-active': isActive ? isActive(color) : null }"
        class="custom-editor__picker-item"
      >
        <button
          :style="{ background: color}"
          type="button"
          @click.prevent="action(color)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { directive as ClickOutside } from "click-outside-vue3"
import { Prop, Ref } from "vue-property-decorator"
import { Icon } from "@yzfe/vue3-svgicon"
import { CallbackFunction } from "@/models"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "EditorColorPicker",
  directives: {
    ClickOutside,
  },
})
export default class EditorColorPicker extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) readonly colors: string[]
  @Prop({
    type: Object,
    required: true,
  }) readonly icon: Icon
  @Prop({
    type: String,
    required: true,
  }) readonly title: string
  @Prop({
    type: Function,
    required: true,
  }) action: CallbackFunction
  @Prop({
    type: Function,
    default: null,
  }) isActive: CallbackFunction
  @Ref("picker") readonly picker!: HTMLElement
  @Ref("pickerDropdown") readonly pickerDropdown!: HTMLElement

  showColorPicker = false
  top = 0
  left = 0

  toggleColorPicker() {
    this.showColorPicker = true

    this.$nextTick(() => {
      this.getDropdownPosition()
    })
  }

  hideColorPicker() {
    this.showColorPicker = false
  }

  getDropdownPosition() {
    const {
      top,
      left,
      height,
      width: pickerWidth,
    } = this.picker.getBoundingClientRect()

    const {
      width: dropdownWidth,
    } = this.pickerDropdown.getBoundingClientRect()

    this.left = left - (dropdownWidth - pickerWidth)
    this.top = top + height + 8
  }
}
</script>
