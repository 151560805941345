import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"
import { BaseModule } from "@/models/BaseModule"
import { BacklogSprintSetup, BacklogTab, Sprint } from "@/models"
import { SprintActions, sprintModule } from "./sprint.module"
import { FilterSprint } from "@/models/Filter/FilterSprint"

export enum TabsActions {
  GET_TABS = "GET_TABS",
  GET_TABS_WITH_SPRINTS = "GET_TABS_WITH_SPRINTS",
  CLEAR_TABS = "CLEAR_TABS",
  NOT_COMPLETED_SPRINTS_TABS = "NOT_COMPLETED_SPRINTS_TABS",
  GET_REQUESTED_TABS = "GET_REQUESTED_TABS",
  REMOVE_TAB_BY_ID = "REMOVE_TAB_BY_ID",
  ADD_REQUESTED_IN_TABS = "ADD_REQUESTED_IN_TABS",
  TOGGLE_IS_OPEN = "TOGGLE_IS_OPEN",
  CHANGE_TOGGLE_IS_OPEN = "CHANGE_TOGGLE_IS_OPEN"
}

@Module
class TabsModule extends BaseModule {
  tabs: BacklogTab[] = [
    {
      id: "BacklogProduct",
      label: "Team Backlog",
      filterId: ["STORY", "SPIKE"],
      countOptions: {
        number: "storyAndSpike",
      },
    },
    {
      id: "BacklogBug",
      label: "Bugs",
      filterId: "BUG",
      countOptions: {
        number: "bug",
      },
    },
    {
      id: "BacklogSupportCases",
      label: "Support Cases",
      filterId: "SUPPORT_CASE",
      countOptions: {
        number: "supportCase",
      },
    },
    {
      id: "BacklogTechDebt",
      label: "Tech Debt",
      filterId: "TECH_DEBT",
      countOptions: {
        number: "techDebt",
      },
    },
    {
      id: "BacklogDevOps",
      label: "DevOps",
      filterId: "DEV_OPS",
      countOptions: {
        number: "devOps",
      },
    },

  ]
  checkToggle = false

  get [`get/${TabsActions.GET_TABS}`]() {
    return this.tabs
  }
  get [`get/${TabsActions.TOGGLE_IS_OPEN}`]() {
    return this.checkToggle
  }

  get [`get/${TabsActions.NOT_COMPLETED_SPRINTS_TABS}`]() {
    const sprints: BacklogTab[] = []
    const notCompletedSprints = sprintModule.notCompletedSprints
    if (!notCompletedSprints) return sprints
    notCompletedSprints
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .forEach((info) => {
        sprints.push({
          id: `BacklogSprint#${info.sequenceNumber}`,
          label: `Sprint #${info.sequenceNumber}`,
          status: info.status,
          startDate: info.startDate,
          endDate: info.endDate,
          backlogSetup: new BacklogSprintSetup(info),
          countOptions: {
            number: info.id,
          },
        })
      })
    return sprints
  }

  @Mutation
  addSprintInTabs({ sprints, beforeTabs } : { sprints: Sprint[], beforeTabs?: boolean}) {
    this.tabs = this.tabs.filter(radio => radio.id.includes("BacklogSprint") === false)
    const sprintsTab: BacklogTab[] = []
    // const notCompletedSprints = sprintModule.notCompletedSprints

    sprints
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .forEach((info) => {
        sprintsTab.push({
          id: `BacklogSprint#${info.sequenceNumber}`,
          label: `Sprint #${info.sequenceNumber}`,
          status: info.status,
          startDate: info.startDate,
          endDate: info.endDate,
          backlogSetup: new BacklogSprintSetup(info),
          countOptions: {
            number: info.id,
          },
        })
      })

    this.tabs = beforeTabs ? [
      ...sprintsTab,
      ...this.tabs,
    ] : [
      ...this.tabs,
      ...sprintsTab,
    ]
  }
  @Mutation
  toggleIsOpen() {
    this.checkToggle = !this.checkToggle
  }

  @Mutation
  clearTabs() {
    this.tabs = this.tabs.filter(radio => radio.id.includes("BacklogSprint") === false && radio.id.includes("BacklogStoryPoker") === false)
  }

  @Mutation
  removeTebById(tabId) {
    this.tabs = this.tabs.filter(tab => tab.id !== tabId)
  }

  @Mutation
  addRequestedInTabs() {
    if (this.tabs.find(e => e.id === "BacklogStoryPoker")) return
    this.tabs = [{ id: "BacklogStoryPoker", label: "Requested" }, ...this.tabs]
  }

  @Action
  async [TabsActions.GET_TABS_WITH_SPRINTS]({ teamId, beforeTabs } : { teamId: string, beforeTabs?: boolean }) {
    const sprints = await sprintModule[SprintActions.NOT_COMPLETED_SPRINTS]({
      teamId,
    })
    this.addSprintInTabs({ sprints, beforeTabs })
  }

  @Action
  async [TabsActions.CHANGE_TOGGLE_IS_OPEN]() {
    this.toggleIsOpen()
  }

  @Action
  async [TabsActions.CLEAR_TABS]() {
    this.clearTabs()
  }

  @Action
  async [TabsActions.GET_REQUESTED_TABS](teamId) {
    this.addRequestedInTabs()
    const filterSprint = new FilterSprint({
      includedStatuses: ["NOT_STARTED"],
      teamId,
    })
    const sprintsServerResponse = await sprintModule[SprintActions.SPRINTS_WITH_STATS](filterSprint)
    const sprints = sprintsServerResponse.content.map(sprintWithStats => new Sprint(sprintWithStats.sprint))
    this.addSprintInTabs({
      sprints,
    })
  }

  @Action
  async [TabsActions.REMOVE_TAB_BY_ID](tabId) {
    this.removeTebById(tabId)
  }

  @Action
  async [TabsActions.ADD_REQUESTED_IN_TABS]() {
    this.addRequestedInTabs()
  }
}


export const tabsModule = new TabsModule({ store, name: Modules.TABS })
