<script lang="ts">
import { Getters } from "@/store"
import { Getter } from "s-vuex-class"
import { h } from "vue"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

type HeadingLevel = "1" | "2" | "3" | "4" | "5"

@Options({
  name: "BaseHeading",
})
export default class extends Vue {
  @Prop() readonly level: HeadingLevel = "1"
  @Prop() readonly className: string = ""
  @Prop() readonly color: string = ""
  @Prop() readonly darkColor: string = ""

  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean

  get styleObject() {
    if (!this.color) return {}
    let color = this.color

    if (this.isDark && this.darkColor) {
      color = this.darkColor
    }

    return {
      color: `var(--${color})`,
    }
  }

  render() {
    return h("div",
      {
        class: `heading-level heading-level-${this.level} ${this.className ?? ""}`,
        style: this.styleObject,
      },
      this.$slots.default ? this.$slots.default() : "")
  }
}
</script>
