import { BaseModule } from "@/models/BaseModule"
import { Modules } from "@/store/modules"
import { store } from "@/store/store"
import { Action, Module, Mutation } from "vuex-class-modules"


export enum KeyboardNavigationActions {
  KEYBOARD_NAVIGATION_ID = "KEYBOARD_NAVIGATION_ID"
}

@Module
class KeyboardNavigationModule extends BaseModule {
  keyboardNavigationId = ""

  get [`get/${KeyboardNavigationActions.KEYBOARD_NAVIGATION_ID}`]() {
    return this.keyboardNavigationId
  }

  @Mutation
  setKeyboardNavigationId(keyboardNavigationId: string) {
    this.keyboardNavigationId = keyboardNavigationId
  }

  @Action
  async [KeyboardNavigationActions.KEYBOARD_NAVIGATION_ID](keyboardNavigationId: string) {
    this.setKeyboardNavigationId(keyboardNavigationId)
  }
}

export const keyboardNavigationModule = new KeyboardNavigationModule({
  store,
  name: Modules.KEYBOARD_NAVIGATION,
})

