import { formatDateAgo, parseDate } from "@/utils/dateUtil"
import { NotificationType } from "./common"

export interface NotificationMilestoneContent {
  name: string
  startDate: string
  endDate: string
}

export class Notification {
  createdDate: string
  id: string
  model: string
  read: boolean
  type: NotificationType

  constructor(props) {
    if (!props) return

    this.createdDate = props.createdDate ?? ""
    this.id = props.id ?? ""
    this.model = props.model ?? ""
    this.read = props.read
    this.type = props.type
  }

  get timeAgo() {
    return formatDateAgo({
      date: new Date(),
      dateFormat: parseDate(this.createdDate, true),
      short: true,
      sufixAgo: true,
    })
  }

  get content() {
    if (this.model && this.isContentValidate) {
      return this.parseModel()
    }

    return {}
  }

  get isContentValidate() {
    try {
      JSON.parse(this.model)
    } catch {
      return false
    }
    return true
  }

  parseModel() {
    return JSON.parse(this.model)
  }

  static create(props) {
    return new Notification(props)
  }
}
