import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { Action, Getter } from "s-vuex-class"

import {
  AlertType,
  BacklogSetup,
  InsertItemInBacklog,
  Item,
  ItemMoveTo,
  ItemQueryPriority,
  ItemStatus,
  ItemType,
  RemoveItemFromBacklog,
  ShowAlertConfig,
  Sprint,
  Task,
  UpdateObjectValue
} from "@/models"
import { Actions, Getters, store } from "@/store"
import { excludedStatusFromBacklog } from "@/utils"

@Options({
  name: "moveTo"
})
export class moveTo extends Vue {
  @Prop({ required: true }) readonly backlogSetup: BacklogSetup

  @Getter(Getters.GET_ITEM) item: Item
  @Getter(Getters.GET_TASKS) tasks: Task[]

  @Action(Actions.SHOW_ALERT) showToast: (params: ShowAlertConfig) => void
  @Action(Actions.UPDATE_ITEM_VALUES) updateOpenedItemValues: (params) => void
  @Action(Actions.GET_BACKLOG_CREATED_ITEM) setCreatedBacklogItem: (item: Item | null) => void
  @Action(Actions.INSERT_BACKLOG_ITEM) insertItem: (payload: any) => void
  @Action(Actions.REMOVE_ITEM_FROM_BACKLOG) removeItemFromBacklog: (
    params: RemoveItemFromBacklog
  ) => void
  @Action(Actions.UPDATE_ITEM_VALUE) updateItemValue: (params: UpdateObjectValue<Item>) => void
  @Action(Actions.SET_ITEM) setItem: (item: Item) => void
  @Action(Actions.UPDATE_ITEM_DETAILS) updateItem: ({
    item,
    notSetItem
  }: {
    item: Item
    notSetItem?: boolean
  }) => void
  @Action(Actions.GET_ITEM) get: ({
    itemId,
    onlyGet
  }: {
    itemId: string
    onlyGet?: boolean
  }) => void
  @Action(Actions.GET_SPRINT) getSprint: ({
    sprintId,
    onlyGet
  }: {
    sprintId: string
    onlyGet
  }) => Promise<Sprint>

  canMoveToActiveSprint = true
  itemMoved = false

  get estimatedTasks() {
    return Task.getEstimatedTasksCount({ tasks: this.tasks })
  }

  get backlogType() {
    return this.backlogSetup?.backlogType ?? ""
  }

  async onMoveToAndValid(payload: ItemMoveTo) {
    const { toSprintStatus, item, toSprintInfo } = payload

    if (!item.id) return
    this.updateItemValue({
      id: "sprintIsContinuous",
      value: !!toSprintInfo?.continuousSprint
    } as any)
    const sprintValidationCallback = () => {
      if (toSprintStatus === "STARTED" && toSprintInfo) {
        this.setCreatedBacklogItem(Object.assign(item, { moveFrom: "moveTo" }))
        return this.canAddItemIntoActiveSprint()
      }
    }

    await this.sprintValidationByMethodAndAlert(payload, sprintValidationCallback)
  }

  canAddItemIntoActiveSprint() {
    let valid = true
    const storyPoint = this.item.storyPoints !== null

    if (
      (!this.item.sprintIsContinuous && (!this.estimatedTasks || !storyPoint)) ||
      !this.estimatedTasks
    ) {
      this.onShowModalEstimationRequired()
      valid = false
    }

    return valid
  }

  onShowModalEstimationRequired() {
    //
  }

  async sprintValidationByMethodAndAlert(payload: ItemMoveTo, validationMethod) {
    const { to, toSprintId, toSprintStatus, toSprintSequenceNumber, item } = payload

    if (!item.id) return

    this.canMoveToActiveSprint = true

    if (to === "SPRINT" && toSprintStatus === "STARTED") {
      this.canMoveToActiveSprint = validationMethod()
    }

    if (this.canMoveToActiveSprint) {
      let alertTo = ""

      if (to === "SPRINT") {
        alertTo = this.$t("backlog.titles.backlogSprint") + `#${toSprintSequenceNumber}`
        this.updateOpenedItemValues({
          payload: [
            {
              id: "sprint",
              value: Sprint.create({
                id: toSprintId,
                sequenceNumber: toSprintSequenceNumber,
                status: toSprintStatus
              })
            }
          ]
        })
        this.updateOpenedItemValues({
          payload: [{ id: "sprintId", value: toSprintId }]
        })
      } else if (to === "BACKLOG") {
        alertTo = this.$t(`backlog.titlesByItemType['${item.itemType.toLowerCase()}']`)
        this.updateOpenedItemValues({
          payload: [
            { id: "backlogPriority", value: 0 },
            { id: "sprint", value: undefined }
          ]
        })
      } else if (to === "UNPRIORITIZED") {
        alertTo = this.$t("backlog.titles.backlogUnprioritized")
        this.updateOpenedItemValues({
          payload: [
            { id: "backlogPriority", value: null },
            { id: "sprint", value: undefined }
          ]
        })
      }

      this.showToast({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: [
          {
            text: "Item",
            type: "regular"
          },
          {
            text: ` #${item.sequenceNumber} `,
            type: "bold"
          },
          {
            text: "was moved to ",
            type: "regular"
          },
          {
            text: alertTo,
            type: "bold"
          }
        ]
      })
      await this.onMoveTo(payload)
    }
  }

  async onMoveTo(payload: ItemMoveTo) {
    if (!payload) {
      return
    }

    const { from, fromSprintId, to, toSprintId, position, item } = payload
    if (!item.id) {
      return
    }

    this.setCreatedBacklogItem(null)

    const getBacklogTypeByItem = (itemType: ItemType) => {
      const name = this.$t(`backlog.backlogQueryByItemType['${itemType.toLowerCase()}']`)
      const backlogName = this.$t(`backlog.main['${name}']`)
      return BacklogSetup[backlogName]
    }

    const moveTo = async () => {
      if (!item.id) return

      const payload: ItemQueryPriority = {
        previousItemId: null,
        itemId: item.id,
        nextItemId: null,
        priorityType: to,
        lowest: position === "BOTTOM",
        highest: position === "TOP"
      }
      if (to === "SPRINT") {
        if (BacklogSetup.BacklogSprint.addAction) {
          await store.dispatch(Actions[BacklogSetup.BacklogSprint.addAction], {
            sprintId: toSprintId,
            itemId: item.id
          })

          this.insertItemInBacklog({
            item,
            index: null,
            backlogName: "backlogSprint",
            sprintId: toSprintId
          })
        }
      } else if (to === "BACKLOG") {
        await store.dispatch(Actions[getBacklogTypeByItem(item.itemType).priorityAction], payload)

        this.insertItemInBacklog({
          item,
          index: position === "TOP" ? 0 : null,
          backlogName: getBacklogTypeByItem(item.itemType).backlogType
        })
      } else if (to === "UNPRIORITIZED" && BacklogSetup.BacklogUnprioritized?.priorityAction) {
        await store.dispatch(Actions[BacklogSetup.BacklogUnprioritized.priorityAction], payload)

        this.insertItemInBacklog({
          item,
          index: position === "TOP" ? 0 : null,
          backlogName: "backlogUnprioritized"
        })
      }
      this.canMoveToActiveSprint = true
      this.itemMoved = true
    }

    if (from === to && fromSprintId === toSprintId) return

    if (!this.canMoveToActiveSprint) return

    if (from === "SPRINT") {
      if (BacklogSetup.BacklogSprint.removeAction && fromSprintId) {
        const sprint = await this.getSprint({
          sprintId: fromSprintId,
          onlyGet: true
        })
        if (sprint.isCompleted || sprint.isHalted) return
        await store.dispatch(Actions[BacklogSetup.BacklogSprint.removeAction], {
          sprintId: fromSprintId,
          itemId: item.id
        })
      }
    }
    await moveTo()
  }

  @Emit()
  getAllMetrics() {
    //
  }

  async onMoveItemToAnotherTeam(payload: ItemMoveTo) {
    const { item } = payload
    this.setItem(item)
    this.item.setInitialState()
    await this.onMoveToAndValid(payload)
    await this.updateItem({ item: this.item, notSetItem: true })
  }

  insertItemInBacklog(payload: InsertItemInBacklog) {
    if (!this.backlogSetup) return
    const itemStatus = this.item.statusParsed?.id as ItemStatus
    const isExcludedStatus = excludedStatusFromBacklog({
      backlogFilter: this.backlogSetup.filter,
      itemStatus
    })
    if (isExcludedStatus) {
      return
    }
    this.insertItem(payload)
  }
}
