<template>
  <component
    :is="parent"
    :type="isButton ? 'button' : null"
    tabindex="0"
    class="dropdown-bordered"
    :class="[
      {'dropdown-bordered_startIcon': beforeIcon},
      {'is-disable-dropdown': disabled}
    ]"
    @keydown.enter="onEnter"
    @click="toggleDropdown"
  >
    <BaseSubHeading
      className="dropdown-bordered__label"
      size="large"
      weight="400"
      color="monochrome-05"
      darkColor="monochrome-03"
    >
      <slot
        name="label"
      >
        {{ label }}
        <Tooltip
          v-if="tooltip"
          :text="tooltipText"
        />
      </slot>
    </BaseSubHeading>
    <div class="dropdown-bordered__multiselect">
      <div
        v-if="beforeIcon"
        class="dropdown-bordered__start-icon"
      >
        <slot name="start-icon">
          <icon
            data="@icon/loupe.svg"
            :fill="false"
            width="18px"
            height="18px"
            color="#7D92A7"
          />
        </slot>
      </div>
      <Multiselect
        :id="id"
        :ref="`multiselect-${id}`"
        :modelValue="value"
        :trackBy="track"
        :label="labelName"
        :options="options"
        :showLabels="false"
        :placeholder="placeholder"
        :hideSelected="hideSelected"
        :closeOnSelect="closeOnSelect"
        :tabindex="-1"
        :preserveSearch="preserveSearch"
        :multiple="multiple"
        :taggable="taggable"
        :searchable="searchable"
        :allowEmpty="allowEmpty"
        :clearOnSelect="clearOnSelect"
        :preselectFirst="preselectFirst"
        :disabled="disabledMultiselect"
        class="dropdown-bordered__item"
        :class="[{'is-multiple': multiple}]"
        :optionHeight="optionHeight"
        @open="onOpen"
        @close="onClose"
        @searchChange="onSearchChange"
        @remove="onRemove"
        @update:modelValue="onInput"
        @keydown.tab.exact.stop="onSelectHighlighted"
        @keyup.enter="onSelectHighlightedByEnter"
      >
        <template #singleLabel="props">
          <slot
            name="singleLabel"
            :option="props.option"
          />
        </template>
        <template #tag="props">
          <slot
            name="tag"
            :option="props.option"
          />
        </template>

        <template #beforeList>
          <slot name="beforeList" />
        </template>
        <template #option="props">
          <slot
            name="option"
            :option="props.option"
          />
        </template>
        <template #afterList>
          <slot name="afterList" />
        </template>

        <!-- <template #tag="props">
          <slot name="option" :option="props.option"></slot>
        </template> -->
      </Multiselect>
    </div>
  </component>
</template>


<script lang="ts">
import { Emit, Mixins, Prop } from "vue-property-decorator"
import Multiselect from "vue-multiselect"
import { AppendToBody } from "@/mixins"
import { Options } from "vue-class-component"
import Tooltip from "../Tooltip/index.vue"

@Options({
  name: "DropdownBordered",
  components: {
    Multiselect,
    Tooltip,
  },
})
export default class extends Mixins(AppendToBody) {
  @Prop({ required: true }) value: any
  @Prop({ default: [] }) options: any[]
  @Prop() declare id: string
  @Prop() placeholder: string
  @Prop({ default: true }) tooltip: boolean
  @Prop({ default: false }) hideSelected: boolean
  @Prop() tooltipText: string
  @Prop() label: string
  @Prop({ default: true }) multiple: boolean
  @Prop({ default: false }) declare appendDropDownToBody: boolean
  @Prop() labelName: string
  @Prop() track: string
  @Prop() closeOnSelect: boolean | string = "close-on-select"
  @Prop({ default: false }) taggable: boolean
  @Prop({ default: false }) beforeIcon: boolean
  @Prop({ default: false }) preselectFirst: boolean
  @Prop({ default: false }) clearOnSelect: boolean
  @Prop({ default: false }) preserveSearch: boolean
  @Prop({ default: true }) searchable: boolean
  @Prop({ default: true }) allowEmpty: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) disabledMultiselect: boolean
  @Prop({ default: 45 }) optionHeight: number
  @Prop({ default: "div" }) parent: string

  repositionDropDown: () => void
  isOpen = false
  onEnter() {
    if (this.isOpen) {
      this.multiselectRef.deactivate()
      this.isOpen = false
    }
  }
  toggleDropdown() {
    this.isOpen = !this.isOpen
    this.isOpen ? this.multiselectRef.activate() : this.multiselectRef.deactivate()
  }
  get isButton() {
    return this.parent === "button"
  }

  get multiselectRef() {
    return (this.$refs[`multiselect-${this.id}`] as any)
  }

  onActivateMultiselect() {
    this.multiselectRef.activate()
  }

  onDeactivateMultiselect() {
    this.multiselectRef.deactivate()
  }

  onSelectHighlightedByEnter() {
    this.$nextTick(() => {
      this.onSelectHighlighted()
      this.onDeactivateMultiselect()
    })
  }

  onSelectHighlighted() {
    if (!this.isButton) return
    this.multiselectRef.addPointerElement()
  }

  onInput(value) {
    this.isOpen = false
    if (this.multiple) {
      const allMembers = value.map(u => u.id).includes("all")
      if (allMembers && this.id === "changeTimeOffMembers") {
        (this.$refs["multiselect-changeTimeOffMembers"] as any).deactivate()
      }
    }
    this.$emit("input", {
      value,
      id: this.id,
    })
  }
  @Emit("onOpen")
  onOpen() {
    this.repositionDropDown()
  }
  @Emit("onClose")
  onClose() {}

  @Emit("dropdownInput")
  onSearchChange(searchQuery) {
    return searchQuery
  }

  @Emit("remove")
  onRemove(removedOption) {
    removedOption
  }
}
</script>
