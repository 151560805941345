import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/attention.svg'
import _imports_1 from '@icon/close.svg'


const _hoisted_1 = { class: "required-alert" }
const _hoisted_2 = { class: "required-alert__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseSubHeading, {
      size: _ctx.sizeText,
      weight: "400",
      color: "monochrome-07",
      class: "required-alert__content"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_icon, {
          data: _imports_0,
          fill: true,
          color: "#D32F2F",
          width: "1.5em",
          height: "1.5em"
        }),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.alertText), 1),
        _createElementVNode("button", {
          class: "required-alert__close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlert')))
        }, [
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: false,
            width: "1.25em",
            height: "1.25em",
            color: "#81889B"
          })
        ])
      ]),
      _: 1
    }, 8, ["size"])
  ]))
}