import { Module, Action } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { BaseModule } from "@/models/BaseModule"

export enum PictureActions {
  UPLOAD_PICTURE = "UPLOAD_PICTURE",
}

@Module
class PictureModule extends BaseModule {
  @Action
  async [PictureActions.UPLOAD_PICTURE](file_: File) {
    const file = new FormData()
    file.append("file", file_)
    const data = await apiService.post<string>(Endpoint.PICTURE(), file)
    return this.handleResponse<string>(data)
  }
}

export const pictureModule = new PictureModule({
  store,
  name: Modules.PICTURE,
})
