import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@icon/chevron-blue.svg'


const _hoisted_1 = { class: "avatar-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "avatar-group__carousel" }
const _hoisted_4 = { class: "avatar-group__carousel__arrow-icon" }
const _hoisted_5 = { class: "avatar-group__carousel__arrow-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_icon = _resolveComponent("icon")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.listOfVisibleUsers.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            src: null,
            type: _ctx.type,
            bordered: ""
          }, null, 8, ["type"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfVisibleUsers, (item, index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_Avatar, {
              key: index,
              userEnabled: item ? item.enabled : true,
              src: item?.picture?.pictureUrl,
              gap: item?.getInitials(),
              type: _ctx.type,
              style: _normalizeStyle(`z-index: ${index}`),
              class: _normalizeClass([[{'avatar-select__gap avatar is-md':!item}], "avatar-group__item is-scale-by-hover"]),
              bordered: ""
            }, null, 8, ["userEnabled", "src", "gap", "type", "style", "class"])), [
              [
                _directive_tooltip,
                { content: item?.username, theme: 'info-tooltip-sm' },
                void 0,
                { bottom: true }
              ]
            ])
          }), 128)),
          (_ctx.listOfHideUsers.length && _ctx.lengthOfHiddenAvatars > 0)
            ? _withDirectives((_openBlock(), _createBlock(_component_VDropdown, {
                key: 0,
                showTriggers: ['click'],
                hideTriggers: [],
                popperTriggers: [],
                autoHide: false,
                shown: _ctx.isShowUserpicMenu,
                style: _normalizeStyle(`z-index: ${_ctx.numberOfVisibleUsers + 1}`),
                class: "avatar-group__item",
                popperClass: "avatar-group__carousel-wrapper",
                placement: "right",
                onApplyShow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onToggle(true))),
                onHide: _ctx.onHide
              }, {
                popper: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("button", {
                      ref: "prevButtonRef",
                      class: _normalizeClass(["avatar-group__carousel__item", {'is-disabled-button': !_ctx.stepOfMovingUserPicList}]),
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onPrevious && _ctx.onPrevious(...args)), ["stop"]))
                    }, [
                      _createElementVNode("span", _hoisted_4, [
                        _createVNode(_component_icon, {
                          data: _imports_0,
                          width: "1em",
                          height: "1em",
                          dir: "down",
                          style: _normalizeStyle({ rotate: `${_ctx.calculatedStyle[0]?.angle}deg` }),
                          fill: true
                        }, null, 8, ["style"])
                      ])
                    ], 2),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersPicList, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        ref_for: true,
                        ref: "hiddenAvatarRefs",
                        class: "avatar-group__carousel__item is-avatar"
                      }, [
                        _withDirectives(_createVNode(_component_Avatar, {
                          userEnabled: item ? item.enabled : true,
                          src: item?.pictureUrl ?? null,
                          gap: item?.getInitials(),
                          type: _ctx.type,
                          bordered: ""
                        }, null, 8, ["userEnabled", "src", "gap", "type"]), [
                          [
                            _directive_tooltip,
                            { content: item?.username, theme: 'info-tooltip-sm' },
                            void 0,
                            { right: true }
                          ]
                        ])
                      ]))
                    }), 128)),
                    _createElementVNode("button", {
                      ref: "nextButtonRef",
                      class: _normalizeClass([{'is-disabled-button': _ctx.isLast}, "avatar-group__carousel__item"]),
                      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)), ["stop"]))
                    }, [
                      _createElementVNode("span", _hoisted_5, [
                        _createVNode(_component_icon, {
                          data: _imports_0,
                          width: "1em",
                          height: "1em",
                          fill: true,
                          style: _normalizeStyle({ rotate: `${_ctx.calculatedStyle.at(-1)?.angle}deg` })
                        }, null, 8, ["style"])
                      ])
                    ], 2)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref: "hiddenAvatarCountRef",
                    class: _normalizeClass(["avatar is-md is-bordered avatar-group__item avatar__length", { 'is-scale-by-hover' : !_ctx.isShowUserpicMenu }]),
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onHide && _ctx.onHide(...args)), ["stop"]))
                  }, [
                    (!_ctx.isShowUserpicMenu)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" +" + _toDisplayString(_ctx.lengthOfHiddenAvatars), 1)
                        ], 64))
                      : (_openBlock(), _createBlock(_component_icon, {
                          key: 1,
                          data: _imports_0,
                          class: "avatar-group__arrow-pointer",
                          width: "1em",
                          height: "1em",
                          dir: "left",
                          fill: true
                        }))
                  ], 2)
                ]),
                _: 1
              }, 8, ["shown", "style", "onHide"])), [
                [_directive_click_outside, _ctx.onHide]
              ])
            : _createCommentVNode("", true)
        ], 64))
  ]))
}