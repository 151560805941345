import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"
import { BaseModule } from "@/models/BaseModule"
import { apiService } from "@/services"
import { ServerResponse } from "@/models"
import { Endpoint } from "@/services/endpoints"
import { JiraImportData, JiraImportResponse } from "@/models/JiraImport"

export enum JiraImportActions {
  JIRA_IMPORT_START = "JIRA_IMPORT_START",
  JIRA_IMPORT_GET = "JIRA_IMPORT_GET",
  JIRA_IMPORT_USERS = "JIRA_IMPORT_USERS",
  JIRA_IMPORT_TEST = "JIRA_IMPORT_TEST",
  JIRA_IMPORT_USERS_START = "JIRA_IMPORT_USERS_START",
  JIRA_TOKEN = "JIRA_TOKEN",
  JIRA_IMPORT_BACKLOG_START = "JIRA_IMPORT_BACKLOG_START",
  JIRA_IMPORT_BACKLOG_FINISH = "JIRA_IMPORT_BACKLOG_FINISH",
  JIRA_IMPORT_BACKLOG_SYNC_ENABLE = "JIRA_IMPORT_BACKLOG_SYNC_ENABLE",
  JIRA_IMPORT_BACKLOG_SYNC_DISABLE = "JIRA_IMPORT_BACKLOG_SYNC_DISABLE",
  JIRA_IMPORT_STATUS = "JIRA_IMPORT_STATUS"
}

@Module
class JiraImportModule extends BaseModule {
  jiraToken = ""

  get [`get/${JiraImportActions.JIRA_TOKEN}`]() {
    return this.jiraToken
  }

  @Mutation
  clearTabs() {}

  @Action
  async [JiraImportActions.JIRA_IMPORT_START](jiraImportData: JiraImportData) {
    const data = await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_START(jiraImportData.accountId), jiraImportData)
    return data
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_GET](accountId: string) {
    const data = await apiService.get<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_START(accountId))
    return data
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_USERS]({ accountId, importId }:{accountId: string, importId: string}) {
    return apiService.get<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_USERS(accountId, importId))
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_USERS_START]({ accountId, importId }:{accountId: string, importId: string}) {
    await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_USERS_START(accountId, importId))
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_BACKLOG_START]({ accountId, importId }:{accountId: string, importId: string}) {
    await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_BACKLOG_START(accountId, importId))
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_BACKLOG_FINISH](importId:string) {
    await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_BACKLOG_FINISH(importId))
  }


  @Action
  async [JiraImportActions.JIRA_IMPORT_BACKLOG_SYNC_ENABLE]({ accountId, importId }:{accountId: string, importId: string}) {
    await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_BACKLOG_SYNC_ENABLE(accountId, importId))
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_BACKLOG_SYNC_DISABLE]({ accountId, importId }:{accountId: string, importId: string}) {
    await apiService.post<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_BACKLOG_SYNC_ENABLE(accountId, importId))
  }

  @Action
  async [JiraImportActions.JIRA_IMPORT_STATUS]({ accountId }: {accountId:string}) {
    return await apiService.get<ServerResponse<JiraImportResponse>>(Endpoint.JIRA_IMPORT_STATUS(accountId))
  }
}


export const jiraImportModule = new JiraImportModule({
  store,
  name: Modules.JIRA_IMPORT,
})
