import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "./account.module"
import { AlertActions, alertModule } from "./alert.module"
import { EpicActions, epicModule } from "./epic.module"
import { GoalActions, goalModule } from "./goal.module"

import { Epic } from "@/models/Epic"
import { Feature, FeatureDTO } from "@/models/Feature"
import { ServerResponse } from "@/models/ServerResponse"
import { UpdateObjectValue } from "@/models/common"
import { AlertContentItem, AlertType } from "@/models/Alert"
import {
  FilterEpic,
  ServerPriorityResponse,
  FilterFeature,
  ProjectPreview,
} from "@/models"
import { Goal } from "@/models/Goal"
import { $t } from "@/plugins/i18n"


export enum FeatureActions {
  GET_FEATURES = "GET_FEATURES",
  GET_FEATURE = "GET_FEATURE",
  SET_FEATURE_AND_STATUS = "SET_FEATURE_AND_STATUS",
  GET_FEATURES_RESPONSE = "GET_FEATURES_RESPONSE",
  ADD_FEATURE = "ADD_FEATURE",
  ADD_FEATURE_PROJECT = "ADD_FEATURE_PROJECT",
  UPDATE_FEATURE = "UPDATE_FEATURE",
  UPDATE_FEATURE_EPIC = "UPDATE_FEATURE_EPIC",
  UPDATE_FEATURE_VALUE = "UPDATE_FEATURE_VALUE",
  DELETE_FEATURE = "DELETE_FEATURE",
  GET_EPICS_BY_FEATURE = "GET_EPICS_BY_FEATURE",
  UPDATE_FEATURE_DRAGGABLE_LIST = "UPDATE_FEATURE_DRAGGABLE_LIST",
  CHANGE_FEATURE_PRIORITY = "CHANGE_FEATURE_PRIORITY",
  GET_UNLINKED_EPICS_BY_FEATURE = "GET_UNLINKED_EPICS_BY_FEATURE",
  CLEAN_UNLINKED_EPICS_BY_FEATURE = "CLEAN_UNLINKED_EPICS_BY_FEATURE",
  UPDATE_UNLINKED_EPIC = "UPDATE_UNLINKED_EPIC",
  GET_LINK_DROPDOWN_STATUS = "GET_LINK_DROPDOWN_STATUS",
  UPDATE_FEATURE_IN_LIST = "UPDATE_FEATURE_IN_LIST",
  GET_LINK_DROPDOWN_GOAL_ID = "GET_LINK_DROPDOWN_GOAL_ID",
  SET_FEATURRE_IN_LIST = "SET_FEATURRE_IN_LIST",
  REMOVE_FEATURE_FROM_LIST = "REMOVE_FEATURE_FROM_LIST",
}

@Module
class FeatureModule extends BaseModule {
  featureResponse: ServerResponse<Feature> | null
  featureContent: Feature[] = []
  feature: Feature | null
  epicInFeature: { [key: string]: Epic[] } = {}
  unlinkedEpics: Epic[] = []
  status = ""
  goalId = ""

  get accountId() {
    return accountModule.getCurrentAccountId()
  }

  get [`get/${FeatureActions.GET_EPICS_BY_FEATURE}`]() {
    return (featureId: string) => this.epicInFeature[featureId] ?? []
  }

  get [`get/${FeatureActions.GET_UNLINKED_EPICS_BY_FEATURE}`]() {
    return this.unlinkedEpics.sort((a, b) => a.roadMapPriority - b.roadMapPriority)
  }

  get [`get/${FeatureActions.GET_FEATURES_RESPONSE}`]() {
    return this.featureResponse
  }

  get [`get/${FeatureActions.GET_FEATURES}`]() {
    return this.featureContent
  }

  get [`get/${FeatureActions.GET_FEATURE}`]() {
    return this.feature
  }

  get [`get/${FeatureActions.GET_LINK_DROPDOWN_STATUS}`]() {
    return this.status
  }

  get [`get/${FeatureActions.GET_LINK_DROPDOWN_GOAL_ID}`]() {
    return this.goalId
  }

  @Mutation
  setEpicInFeature({ featureId, epics } : { featureId: string, epics: Epic[] }) {
    this.epicInFeature[featureId] = epics
  }

  @Mutation
  setFeatureResponse(serverResponse: ServerResponse<Feature> | null) {
    this.featureResponse = serverResponse
  }

  @Mutation
  addFeatures(content: Feature[]) {
    this.featureContent = [...this.featureContent, ...content]
  }

  @Mutation
  updateUnlinkedEpicValue({ payload, epic }) {
    this.unlinkedEpics = this.unlinkedEpics.map((el) => {
      if (el.id === epic.id) el[payload?.["id"]] = payload?.["value"]
      return el
    })
  }

  @Mutation
  updateFeatureInList(feature: Feature) {
    this.featureContent = this.featureContent.map(f => (f.id !== feature.id ? f : feature))
  }

  @Mutation
  setFeatures(content: Feature[]) {
    this.featureContent = content
  }

  @Mutation
  setUnlinkedEpics(epics: Epic[]) {
    this.unlinkedEpics = epics
  }

  @Mutation
  setFeature(feature: Feature) {
    this.feature = feature
  }

  @Mutation
  setStatus(status: string) {
    this.status = status
  }

  @Mutation
  setFeatureGoalId(goalId?: string) {
    this.goalId = goalId ?? ""
  }

  @Mutation
  removeFeatureFromList(feature: Feature) {
    const updatedList = this.featureContent.filter(el => el.id !== feature.id)
    this.featureContent = updatedList
    if (this.featureResponse) this.featureResponse.content = updatedList
  }

  @Mutation
  setFeatureInList(feature: Feature) {
    const updatedList = [...this.featureContent, feature]
    this.featureContent = updatedList
    if (this.featureResponse) this.featureResponse.content = updatedList
  }

  @Mutation
  updateFeatureValue({ payload, featureId }: { payload: UpdateObjectValue<Feature>, featureId: string}) {
    // TODO - Replace with update method
    this.featureContent = this.featureContent.map((el) => {
      if (el.id === featureId) el[payload?.["id"]] = payload?.["value"]
      return el
    })
  }

  @Action
  async [FeatureActions.GET_FEATURES_RESPONSE]({ filter }: { filter: FilterFeature }) {
    const filterJson = FilterFeature.create(filter).getJsonObj()
    this.setFeatureResponse(null)

    const data = await apiService.get<ServerResponse<FeatureDTO>>(Endpoint.SEARCH_FEATURE(this.accountId), filterJson)
    return this.handleResponse<ServerResponse<FeatureDTO>>(data, (data) => {
      const content: Feature[] = data.content.map(feature => Feature.create(feature))
      this.setFeatureResponse(Object.assign(data, { content }))

      if (filter.page === 1) {
        this.setFeatures(content)
      } else {
        this.addFeatures(content)
      }
    })
  }

  @Action
  async [FeatureActions.ADD_FEATURE]({ feature, goal, status } : { feature: Feature, goal?: Goal, status?: string }) {
    const data = await apiService.post<FeatureDTO>(Endpoint.FEATURE(feature.projectId), feature.getJsonObj())
    return this.handleResponse<FeatureDTO>(data, (data) => {
      const feature = Feature.create(data)
      this.setFeature(feature)
      const alertStatus = $t(`goalStatus.${status}`)

      let alertContent: AlertContentItem[] = []
      if (goal) {
        alertContent = [
          {
            text: "Feature ",
            type: "regular",
          },
          {
            text: ` ${feature.name.toUpperCase()} `,
            type: "bold",
          },
          {
            text: "saved and added to",
            type: "regular",
          },
          {
            text: ` ${alertStatus} `,
            type: "bold",
          },
          {
            text: `Goal - ${goal.content}`,
            type: "regular",
          },
        ]
      } else {
        alertContent = [
          {
            text: "Feature ",
            type: "regular",
          },
          {
            text: ` ${feature.name.toUpperCase()} `,
            type: "bold",
          },
          { text: "successfully saved", type: "regular" },
        ]
      }


      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })


      return feature
    })
  }

  @Action
  async [FeatureActions.ADD_FEATURE_PROJECT]({ feature, toProject, hasFilter }: { feature: Feature, toProject: ProjectPreview, hasFilter?: boolean }) {
    const { projectId, id } = feature
    const data = await apiService.post<FeatureDTO>(Endpoint.ADD_PROJECT_FEATURE(projectId, id, toProject.id), null)
    return this.handleResponse<FeatureDTO>(data, () => {
      let alertContent: AlertContentItem[] = []

      if (hasFilter) {
        alertContent = [
          {
            text: "Feature",
            type: "regular",
          },
          {
            text: ` "${feature.name}" `,
            type: "bold",
          },
          { text: "is assigned to Project", type: "regular" },
          {
            text: ` ${toProject.name} `,
            type: "bold",
          },
          { text: "and removed from current filtered view", type: "regular" },
        ]
      } else {
        alertContent = [
          {
            text: "Feature",
            type: "regular",
          },
          {
            text: ` "${feature.name}" `,
            type: "bold",
          },
          { text: "is assigned to Project", type: "regular" },
          {
            text: ` ${toProject.name}`,
            type: "bold",
          },
        ]
      }

      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [FeatureActions.UPDATE_FEATURE](feature: Feature) {
    const { projectId, id } = feature
    const data = await apiService.put<FeatureDTO>(Endpoint.UPDATE_FEATURE(projectId, id), feature.getJsonObj())
    return this.handleResponse<FeatureDTO>(data, (data) => {
      const feature = Feature.create(data)
      this.setFeature(feature)
      return feature
    })
  }

  @Action
  async [FeatureActions.UPDATE_FEATURE_EPIC]({ feature, epicIds, unlinkedEpic }: { feature: Feature, epicIds: string[], unlinkedEpic?: Epic }) {
    const { projectId, id } = feature
    const data = await apiService.put<FeatureDTO>(Endpoint.UPDATE_FEATURE_EPIC(projectId, id), epicIds)
    return this.handleResponse<FeatureDTO>(data, (data) => {
      const feature = Feature.create(data)
      this.setFeature(feature)
      if (unlinkedEpic) {
        const alertContent: AlertContentItem[] = [
          {
            text: "Epic",
            type: "regular",
          },
          {
            text: ` ${unlinkedEpic.name} `,
            type: "bold",
          },
          { text: "was unlinked", type: "regular" },
        ]
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertContent,
        })
      }

      return feature
    })
  }

  @Action
  async [FeatureActions.DELETE_FEATURE]({ feature, goalId }: { feature: Feature, goalId?: string }) {
    const { projectId, id } = feature
    const data = await apiService.delete<Feature>(Endpoint.UPDATE_FEATURE(projectId, id))
    const alertContent: AlertContentItem[] = [
      {
        text: "Feature",
        type: "regular",
      },
      {
        text: ` ${feature.name} `,
        type: "bold",
      },
      { text: "was deleted", type: "regular" },
    ]
    alertModule[AlertActions.SHOW_ALERT]({
      type: AlertType.SUCCESS,
      theme: "toast",
      content: alertContent,
    })

    if (goalId) {
      goalModule[GoalActions.DELETE_FEATURE_BY_GOAL]({
        feature,
        goalId,
      })
    } else {
      this.removeFeatureFromList(feature)
    }
    return this.handleResponse<Feature>(data)
  }

  @Action
  async [FeatureActions.GET_EPICS_BY_FEATURE](feature: Feature) {
    if (!feature.project?.id) return []

    const filter = FilterEpic.create({
      featureId: feature.id,
      inFeature: true,
    })
    const epicsResponse = await epicModule[EpicActions.GET_EPICS]({
      filter,
      projectId: feature.projectId,
      immediate: true,
    })
    const epics = epicsResponse.content

    this.setEpicInFeature({
      featureId: feature.id,
      epics,
    })
  }

  @Action
  async [FeatureActions.GET_UNLINKED_EPICS_BY_FEATURE]({ feature, filter } : { feature: Feature, filter: FilterEpic }) {
    if (!feature.project?.id) return []

    const selectedEpicsResponse = await epicModule[EpicActions.GET_EPICS]({
      filter: FilterEpic.create({
        featureId: feature.id,
        inFeature: true,
        q: filter.q,
      }),
      projectId: feature.projectId,
      immediate: true,
    })

    const selectedEpics = selectedEpicsResponse.content

    const unlinkedEpicsResponse = await epicModule[EpicActions.GET_EPICS]({
      filter,
      projectId: feature.projectId,
      immediate: true,
    })

    const unlinkedEpics = unlinkedEpicsResponse.content

    this.setEpicInFeature({
      featureId: feature.id,
      epics: selectedEpics,
    })

    this.setUnlinkedEpics([
      ...selectedEpics,
      ...unlinkedEpics,
    ])
  }

  @Action
  async [FeatureActions.UPDATE_FEATURE_VALUE]({ payload, featureId }: { payload: UpdateObjectValue<Feature>, featureId: string}) {
    this.updateFeatureValue({ payload, featureId })
  }

  @Action
  async [FeatureActions.CLEAN_UNLINKED_EPICS_BY_FEATURE]() {
    this.setUnlinkedEpics([])
  }

  @Action
  async [FeatureActions.UPDATE_UNLINKED_EPIC]({ payload, epic }: { payload: UpdateObjectValue<Epic>, epic: Epic}) {
    this.updateUnlinkedEpicValue({ payload, epic })
  }

  @Action
  async [FeatureActions.SET_FEATURE_AND_STATUS]({ feature, status, goalId } : { feature: Feature, status: string, goalId?: string }) {
    this.setFeature(feature)
    this.setStatus(status)
    this.setFeatureGoalId(goalId)
  }

  @Action
  async [FeatureActions.UPDATE_FEATURE_DRAGGABLE_LIST]({ draggableList } : { draggableList: Feature[]}) {
    if (!draggableList) return false

    this.setFeatures(draggableList)
  }

  @Action
  async [FeatureActions.UPDATE_FEATURE_IN_LIST](feature: Feature) {
    if (!feature) return false

    this.updateFeatureInList(feature)
  }

  @Action
  async [FeatureActions.SET_FEATURRE_IN_LIST](feature: Feature) {
    this.setFeatureInList(feature)
  }

  @Action
  async [FeatureActions.REMOVE_FEATURE_FROM_LIST](feature: Feature) {
    this.removeFeatureFromList(feature)
  }

  @Action
  async [FeatureActions.CHANGE_FEATURE_PRIORITY]({ element, nextElement, previousElement, goalId, alertMessage, lowest } : { element: Feature, nextElement: Feature, previousElement: Feature, goalId? : string, alertMessage?, lowest?: boolean }) {
    const data = await apiService.patch<ServerPriorityResponse>(Endpoint.CHANGE_FEATURE_PRIORITY(element.projectId), {
      previousItemId: previousElement?.id || null,
      nextItemId: nextElement?.id || null,
      itemId: element.id,
      lowest,
      goalId,
    })
    return this.handleResponse<ServerPriorityResponse>(data, (data) => {
      if (data.normalizedAll) return "reload"

      if (alertMessage && alertMessage.length) {
        alertModule[AlertActions.SHOW_ALERT]({
          type: AlertType.SUCCESS,
          theme: "toast",
          content: alertMessage,
        })
      }
    })
  }
}

export const featureModule = new FeatureModule({
  store,
  name: Modules.FEATURE,
})
