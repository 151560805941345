import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([{ 'is-active': _ctx.isActive ? _ctx.isActive() : null }, "custom-editor__button heading-level heading-level-3"]),
    title: _ctx.title,
    tabindex: -1,
    type: "button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["prevent"]))
  }, [
    _createVNode(_component_icon, {
      data: _ctx.icon,
      width: "1em",
      height: "1em"
    }, null, 8, ["data"])
  ], 10, _hoisted_1))
}