import { Observable, Subject } from "rxjs"

export type UpdatedValues<T> = { [K in keyof T]: { id: K; value: T[K] }}[keyof T]

export class Base<T> {
  protected initialState: string
  updated$ = new Subject<T>()

  constructor(props?: Partial<T>) {
    if (props) {
      this.set(props)
    }
  }

  public set(props?: Partial<T>): T {
    if (props) {
      Object.keys(props).forEach((key) => {
        this[key] = props[key]
      })
      this.updated$.next(this as unknown as T)
    }
    return this as unknown as T
  }

  public update(obj: UpdatedValues<T>): Observable<T> {
    this[obj.id as string] = obj.value
    this.updated$.next(this as unknown as T)
    return this.updated$
  }

  public clone() {
    const copy = new (this.constructor as { new (): T })()
    Object.keys(this).forEach((key) => {
      copy[key] = this[key]
    })
    return copy
  }

  public setInitialState() {
    this.initialState = JSON.stringify({ ...this, initialState: undefined })
  }

  public isModified(keys?: (keyof T)[], inverse?: boolean) {
    if (keys && this.initialState) {
      const initial = JSON.parse(this.initialState)
      if (!inverse) {
        const modified = keys
          .filter(key => JSON.stringify(this[key as string]) !== JSON.stringify(initial[key]))
        return modified.length > 0
      } else {
        const modified =
        Object.keys(this)
          .filter(key => ![...keys, "initialState"].includes(key as keyof T))
          .filter(key => JSON.stringify(this[key]) !== JSON.stringify(initial[key]))
        return modified.length > 0
      }
    }
    return JSON.stringify({
      ...this,
      initialState: undefined,
    }) !== this.initialState
  }
}
