import { Module, Mutation, Action } from "vuex-class-modules"

import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { ProjectActions, projectModule } from "./project.module"
import { ServerResponse } from "@/models/ServerResponse"
import { Comment, CommentByItem, PayloadCommentCount } from "@/models/Comment"
import {
  AlertContentItem,
  FilterComment,
  StructuredDescription,
} from "@/models"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { AlertActions, alertModule } from "./alert.module"
import { AlertType } from "@/models"
import { $t } from "@/plugins/i18n"

export enum CommentActions {
  GET_COMMENTS = "GET_COMMENTS",
  GET_COMMENT = "GET_COMMENT",
  GET_COMMENTS_BY_SOURCE = "GET_COMMENTS_BY_SOURCE",
  POST_COMMENT = "POST_COMMENT",
  UPDATE_COMMENT_DESCRIPTION = "UPDATE_COMMENT_DESCRIPTION",
  DELETE_COMMENT = "DELETE_COMMENT",
  UPDATE_COMMENT_TEXT_IN_LIST = "UPDATE_COMMENT_TEXT_IN_LIST",
  SET_FILES_TO_COMMENT = "SET_FILES_TO_COMMENT",
  GET_COMMENT_COUNT = "GET_COMMENT_COUNT",
  GET_ITEM_COMMENT = "GET_ITEM_COMMENT",
  UPDATE_STATUS_DESCRIPTION = "UPDATE_STATUS_DESCRIPTION",
}

@Module
class CommentModule extends BaseModule {
  commentsByItems: CommentByItem[] = []
  comments: ServerResponse<Comment>
  comment: Comment
  commentCount = 0

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${CommentActions.GET_COMMENTS}`]() {
    return this.comments
  }

  get [`get/${CommentActions.GET_COMMENT}`]() {
    return this.comment
  }

  get [`get/${CommentActions.GET_COMMENTS_BY_SOURCE}`]() {
    return this.comments
  }

  get [`get/${CommentActions.GET_COMMENT_COUNT}`]() {
    return this.commentCount
  }

  get [`get/${CommentActions.GET_ITEM_COMMENT}`]() {
    return this.commentsByItems
  }

  @Mutation
  updateCommentsByItems(value: CommentByItem[]) {
    this.commentsByItems = value
  }

  @Mutation
  setCommentCount(count: number) {
    this.commentCount = count
  }

  @Mutation
  setComments(comments: ServerResponse<Comment>) {
    this.comments = comments
  }

  @Mutation
  setComment(comment: Comment) {
    this.comment = comment
  }

  @Mutation
  removeComment(commentId) {
    if (!this.comments?.content) return
    this.comments.content = this.comments.content.filter(remove => remove.id !== commentId)
  }

  @Mutation
  updateCommentFiles({ id, files }) {
    this.comments.content = this.comments.content.map((comment) => {
      if (comment.id === id) {
        comment.fileMetadata = [...comment.fileMetadata ?? [], ...files]
      }
      return comment
    })
  }

  @Mutation
  onUpdateCommentTextInList({ structuredDescription, commentId } : { structuredDescription: StructuredDescription, commentId: string }) {
    this.comments.content = this.comments.content.map((comment) => {
      if (comment.id === commentId) {
        comment.structuredDescription = structuredDescription
      }
      return comment
    })
  }

  @Action
  async [CommentActions.GET_COMMENTS]({ params } : { params: FilterComment}) {
    const filter = FilterComment.create(params).getJsonObj()

    const data = await apiService.get<ServerResponse<Comment>>(Endpoint.GET_COMMENTS(this.projectId), filter)
    return this.handleResponse<ServerResponse<Comment>>(data, (data) => {
      const content: Comment[] = data.content.map(c => Comment.create(c))
      this.setComments(Object.assign(data, { content }))
    })
  }

  @Action
  async [CommentActions.GET_ITEM_COMMENT]({ itemIds, onlyGet } : { itemIds: string[], onlyGet: boolean }) {
    const data = await apiService.post<CommentByItem[]>(Endpoint.GET_ITEM_COMMENT(this.projectId), itemIds)
    return this.handleResponse<CommentByItem[]>(data, (data) => {
      const commentsByItems = data.map(item => new CommentByItem(item))
      if (!onlyGet) {
        this.updateCommentsByItems(commentsByItems)
      }
      return commentsByItems
    })
  }

  @Action
  async [CommentActions.GET_COMMENT](commentId: string) {
    const data = await apiService.get<Comment>(Endpoint.GET_COMMENT(this.projectId, commentId))
    return this.handleResponse<Comment>(data, (data) => {
      this.setComment(Comment.create(data))
    })
  }

  @Action
  async [CommentActions.GET_COMMENTS_BY_SOURCE]({ sourceId, sourceType }:{ sourceId: string, sourceType: string }) {
    const data = await apiService.get<Comment[]>(Endpoint.GET_COMMENTS_BY_SOURCE(this.projectId, sourceId, sourceType))
    return this.handleResponse<Comment[]>(data, (data) => {
      const content: Comment[] = data.map(c => Comment.create(c))
      this.setComments(Object.assign(content))
    })
  }

  @Action
  async [CommentActions.POST_COMMENT]({ comment, hideToastMessage } : { comment: Comment, hideToastMessage?: boolean }) {
    const data = await apiService.post<Comment>(Endpoint.POST_COMMENT(this.projectId), comment.getJsonObj())
    return this.handleResponse<Comment>(data, (data) => {
      const updateComment = Comment.create(data)
      this.setComment(updateComment)

      if (!hideToastMessage) {
        const alertContent: AlertContentItem[] = [{
          text: `Comment ${$t("alert.created")}`,
          type: "regular",
        }]
        alertModule[AlertActions.SHOW_ALERT]({
          content: alertContent,
          type: AlertType.SUCCESS,
          theme: "toast",
        })
      }

      return updateComment
    })
  }

  @Action
  async [CommentActions.UPDATE_COMMENT_DESCRIPTION](comment:Comment) {
    const endpoint = comment.source?.sourceType === "ITEM_STATUS_CHANGE" ? Endpoint.UPDATE_STATUS_DESCRIPTION(this.projectId, comment.id) : Endpoint.UPDATE_COMMENT_DESCRIPTION(this.projectId, comment.id)
    const data = await apiService.patch<Comment>(endpoint, comment.getJsonObj())
    return this.handleResponse<Comment>(data, (data) => {
      const updateComment = Comment.create(data)
      this.setComment(updateComment)
      const alertContent: AlertContentItem[] = [{
        text: `Comment ${$t("alert.updated")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      return updateComment
    })
  }

  @Action
  async [CommentActions.DELETE_COMMENT](comment: Comment) {
    const data = await apiService.delete<Comment>(Endpoint.DELETE_COMMENT(this.projectId, comment.id))
    return this.handleResponse<Comment>(data, () => {
      this.removeComment(comment.id)
      const alertContent: AlertContentItem[] = [{
        text: `Comment ${$t("alert.deleted")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
    })
  }

  @Action
  async [CommentActions.GET_COMMENT_COUNT](payload: PayloadCommentCount) {
    const { targetId, onlyGet } = payload
    const projectId = payload.projectId ? payload.projectId : this.projectId
    const data = await apiService.get<number>(Endpoint.GET_COMMENT_COUNT(projectId), {
      targetId,
    })
    return this.handleResponse<number>(data, (data) => {
      if (!onlyGet) {
        this.setCommentCount(data)
      }
      return data
    })
  }

  @Action
  async [CommentActions.UPDATE_COMMENT_TEXT_IN_LIST]({ structuredDescription, commentId } : { structuredDescription: StructuredDescription, commentId: string }) {
    this.onUpdateCommentTextInList({ structuredDescription, commentId })
  }

  @Action
  [CommentActions.SET_FILES_TO_COMMENT]({ id, files }) {
    this.updateCommentFiles({ id, files })
  }
}

export const commentModule = new CommentModule({
  store,
  name: Modules.COMMENT,
})
