import { BacklogSetup, FilterBacklogMetrics } from "@/models"
import {
  BacklogMetricsActions,
  backlogMetricsModule,
} from "@/store/modules/backlogMetrics.module"
import { SprintActions, sprintModule } from "@/store/modules/sprint.module"
import { Options, Vue } from "vue-class-component"

@Options({
  name: "BacklogCommonMetrics",
})

export default class BacklogCommonMetrics extends Vue {
  async getMetrics(backlogSetup: BacklogSetup) {
    if (!backlogSetup.backlogMetrics) return
    const params = new FilterBacklogMetrics({
      ...backlogSetup.filter,
    })
    const sprintId = backlogSetup.sprintInfo?.id ?? ""
    if (sprintId) {
      await sprintModule[SprintActions["UPDATE_SPRINT_METRIC_UPDATE_FLAG"]](false)

      await sprintModule[SprintActions[backlogSetup.backlogMetrics]](sprintId)
      await sprintModule[SprintActions["UPDATE_SPRINT_METRICS_BY_FILTER"]]({
        sprintId,
        params,
      })
      return
    }

    await backlogMetricsModule[BacklogMetricsActions[backlogSetup.backlogMetrics]]({
      params,
      backlogKey: backlogSetup.storeBacklogId,
    })
  }
}
