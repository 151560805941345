import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-menu" }
const _hoisted_2 = {
  key: 0,
  class: "profile-menu__list"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ModalTheme = _resolveComponent("ModalTheme")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createVNode(_component_Avatar, {
        src: _ctx.user ? _ctx.user.pictureUrl : '',
        gap: _ctx.user ? _ctx.user.getInitials() : '',
        size: "lg"
      }, null, 8, ["src", "gap"])
    ])), [
      [_directive_click_outside, _ctx.hide]
    ]),
    (_ctx.opened)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.visible)
                ? (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "profile-menu__item"
                  }, [
                    (item.action)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "profile-menu__button",
                          onClick: _withModifiers(($event: any) => (_ctx.onClick(item.action)), ["prevent"])
                        }, [
                          _createVNode(_component_icon, {
                            data: item.icon,
                            fill: item.fill,
                            class: "profile-menu__icon",
                            color: item.iconColor,
                            width: "1em",
                            height: "1em"
                          }, null, 8, ["data", "fill", "color"]),
                          _createTextVNode(" " + _toDisplayString(item.label), 1)
                        ], 8, _hoisted_3))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          class: "profile-menu__button",
                          to: item.to
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icon, {
                              data: item.icon,
                              fill: item.fill,
                              class: "profile-menu__icon",
                              color: item.iconColor,
                              width: "1em",
                              height: "1em"
                            }, null, 8, ["data", "fill", "color"]),
                            _createTextVNode(" " + _toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalTheme)
  ]))
}