<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="notification.projectName"
    class="notification__item"
  >
    <template #pic>
      <div class="label-pic is-green">
        <icon
          data="@icon/happy-smile.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Welcome to Project Simple!
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__update-item__description"
      >
        We’re excited to have you join the fastest growing next generation Agility Platform.
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification"

@Options({
  name: "WELCOME",
  components: {
    NotificationTemplate,
  },
})
export default class WELCOME extends Vue {
  @Prop() readonly notification: Notification
}
</script>
