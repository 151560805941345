<template>
  <div
    class="to-do__item__wrapper"
  >
    <div
      class="to-do__item"
      :class="[
        { 'is-show-users': isSent },
        `is-${item.status.toLowerCase()}`
      ]"
    >
      <div>
        <icon
          v-if="item.urgent"
          data="@icon/flag.svg"
          class="to-do__item__flag"
          :fill="true"
          width="14"
          height="14"
          color="var(--serviceAlert-02)"
        />
      </div>
      <span
        v-tooltip="{
          content: item.name,
          theme: 'info-tooltip',
          distance: 10,
          skidding: 16,
          placement: 'bottom',
          popperClass: 'to-do__tooltip-info',
        }"
        class="to-do__item__name-wrapper"
      >
        <router-link
          v-if="ticketParams?.relatedUrl"
          :to="getRouteHref(ticketParams.relatedUrl)"
          class="link to-do__item__name"
          :class="[{ 'check-done': doneToDo }]"
        >
          {{ item.name }}
        </router-link>
        <BaseSubHeading
          v-else
          v-ticket="ticketParams"
          class="to-do__item__name"
          size="large"
          weight="400"
          color="monochrome-06"
          :class="[{ 'check-done': doneToDo }]"
        >
          {{ item.name }}
        </BaseSubHeading>
      </span>
      <button
        type="button"
        class="link is-large"
        @click="onExpand"
      >
        <icon
          v-if="item.structuredDescription.html"
          data="@icon/dots-horizontal-center.svg"
          :fill="true"
          width="1.5em"
          height="1.5em"
        />
      </button>
      <VDropdown
        v-if="isSent"
        :disabled="item.owners.length <= 1"
        :triggers="['hover']"
        :popperHideTriggers="['hover']"
        :popperShowTriggers="['hover']"
        :showGroup="`to-do-${index}`"
        placement="bottom-end"
        popperClass="to-do__item__info__wrapper"
        theme="info-dropdown"
      >
        <AvatarGroupToDo
          :avatars="item.owners"
          type="avatarGroup"
        />
        <template #popper>
          <div
            v-for="(owner, index) in item.owners"
            :key="index"
            class="to-do__item__info"
          >
            <div class="to-do__item__info-user">
              <Avatar
                :src="owner.pictureUrl"
                :gap="owner.getInitials()"
              />
              <BaseSubHeading
                size="large"
                weight="400"
                color="monochrome-06"
                class="to-do__item__user-info__name"
              >
                {{ owner.username }}
              </BaseSubHeading>
            </div>
            <button
              type="button"
              class="to-do__item__checkbox to-do__item__user-info__checkbox"
            >
              <icon
                v-if="item.ownersCompletionStatuses[owner.id]"
                data="@icon/doubleCheck.svg"
                :color="isDark ? 'var(--markingGreen-03)' : 'var(--markingGreen-02)'"
                :fill="true"
                original
                width="24"
                height="24"
              />
              <div v-else>
                &nbsp;
              </div>
            </button>
          </div>
        </template>
      </VDropdown>
      <button
        type="button"
        class="to-do__item__checkbox"
        :class="[{ 'pointer-none': isSent }]"
        @click="onCheckboxClick"
      >
        <icon
          v-if="getToDoIcon.icon"
          :data="getToDoIcon.icon"
          :color="getToDoIcon.color"
          :fill="true"
          original
          width="24"
          height="24"
        />
      </button>
    </div>
    <Transition
      @beforeEnter="onBeforeEnterDescription"
      @beforeLeave="onBeforeLeaveDescription"
    >
      <div
        v-if="showDescription"
        class="to-do__item__body"
      >
        <Editor
          id="body-description"
          type="read"
          class="to-do__item__description"
          :targetConfig="typeConfig"
          :value="item.structuredDescription.html"
          :extensions="extensions"
          :isBodyColor="true"
        />
        <button
          type="button"
          class="link is-medium-500 to-do__item__edit-button"
          @click="onEdit"
        >
          <icon
            data="@icon/pen.svg"
            width="1.15em"
            height="1.15em"
          />
          Edit
        </button>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Emit, Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { ToDo } from "@/models/ToDo"
import { formatRelative } from "date-fns"
import enUS from "date-fns/locale/en-US"
import { ticket } from "@/directives"
import AvatarGroupToDo from "@/components/Avatar/AvatarGroupToDo.vue"
import { ReferenceAt } from "@/plugins/extensions/reference/referenceAt"
import { ReferenceHash } from "@/plugins/extensions/reference/referenceHash"
import { ReferenceLink } from "@/plugins/extensions/reference/referenceLink"
import Editor from "@/components/Editor/index.vue"
import { unfoldAnimation } from "@/utils/gsapAnimation"
import Avatar from "@/components/Avatar/index.vue"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"

interface TicketParams{
  wholeLink: boolean,
  query: any,
  todo: ToDo,
  params: any,
  routerName: string
  relatedUrl: string
}

@Options({
  name: "ToDoItem",
  components: {
    ToDo,
    AvatarGroupToDo,
    Editor,
    Avatar,
  },
  directives: {
    ticket,
  },
})
export default class ToDoItem extends Vue {
  @Prop({ required: true }) readonly item: ToDo
  @Prop({ default: 0 }) readonly index: number
  @Prop({ default: false }) readonly sm: boolean
  @Prop({ default: false }) readonly isSent: boolean

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  left: any
  doneToDo = false
  showDescription = false
  moreOptions = [
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "delete",
      label: "Delete",
    },
  ]

  get typeConfig() {
    return null
  }

  get extensions() {
    return [ReferenceAt, ReferenceHash, ReferenceLink]
  }

  get getToDoIcon() {
    let config = {
      icon: "",
      color: "",
    }
    if (this.isSent) {
      const greenColor = this.isDark ? "var(--markingGreen-03)" : "var(--markingGreen-02)"
      config.icon = Object.values(this.item.ownersCompletionStatuses).every(value => !value) ?
        "" :
        require(`@icon/${this.doneToDo ? "doubleCheck" : "check"}.svg`)
      config.color = this.doneToDo ? greenColor : "var(--monochrome05to04)"
    } else {
      config.icon = require(`@icon/${this.doneToDo ? "checkbox-checked" : "checkbox-unchecked"}.svg`)
      config.color = "var(--monochrome05to04)"
    }
    return config
  }

  get ticketParams() {
    let result: TicketParams = {
      wholeLink: true,
      query: {},
      todo: this.item,
      params: {},
      routerName: "",
      relatedUrl: "",
    }
    if (this.item?.relatedUrl) {
      result = { ...result, relatedUrl: this.item.relatedUrl }
    }
    return result
  }

  get timeAgo() {
    const formatRelativeLocale = {
      lastWeek: "'Last Week'",
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: "'Next Week'",
      other: "dd.MM.yyyy",
    }
    const locale = {
      ...enUS,
      formatRelative: token => formatRelativeLocale[token],
    }
    if (!this.item.estimatedDeadline) return this.left
    this.left = formatRelative(this.item.estimatedDeadline, new Date(), {
      locale,
      weekStartsOn: 1,
    })
    return this.left
  }

  mounted() {
    this.doneToDo = this.item.status === "COMPLETED" || this.item.completedByMe
  }

  @Emit()
  onCheckboxClick() {
    this.doneToDo = !this.doneToDo
    return {
      status: this.doneToDo ? "COMPLETED" : "ACTIVE",
      toDoItemId: this.item.id,
    }
  }

  @Emit("options")
  onDropdownOption({ value }: { value: { id: string; label: string } }) {
    return {
      value: value,
      index: this.index,
    }
  }

  getRouteHref(param: string) {
    const { pathname, search } = new URL(param)
    const routeData = this.$router.resolve(`${pathname}${search}`)
    return routeData.href
  }

  onExpand() {
    this.showDescription = !this.showDescription
  }

  onBeforeEnterDescription(element) {
    unfoldAnimation(element).play().eventCallback("onComplete", () => {
      element.style.height = element.scrollHeight + "px"
    })
  }

  onBeforeLeaveDescription(element) {
    unfoldAnimation(element).reverse(0)
  }

  @Emit("onEdit")
  onEdit() {
    return this.item
  }
}
</script>
