import { RouteRecordRaw } from "vue-router"
import { h, resolveComponent } from "vue"

import { Item } from "@/models/Item"
import { $t } from "@/plugins/i18n"
import { $keycloak } from "@/plugins/keycloak"
import { parsingProjectIdAndItemNumber, splitAndCapitalize } from "@/utils/helpers"

export const pathSignUp = "/sign-up"

function page(path: string) {
  return () => import(/* webpackChunkName: '' */ `../views/${path}`).then(m => m.default || m)
}

const routers: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: to => ({ path: `/${to.params.accountId}` })
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error"
  },
  {
    path: pathSignUp,
    redirect: () => {
      $keycloak.logout()
      window.location.href = $keycloak.createRegisterUrl({
        redirectUri: window.location.origin
      })
      return ""
    }
  },
  {
    path: "/error",
    name: "Error",
    component: page("Error.vue"),
    meta: {
      layout: "ErrorLayout",
      withoutAccount: true
    }
  },
  {
    path: "/company/create/:priceId?",
    name: "CompanySetup",
    component: page("Create/CreateAccount"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/company/invite",
    name: "InviteUser",
    component: page("Create/CreateAccount/InviteUser.vue"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/verification-alert/:priceId?",
    name: "VerificationAlert",
    component: page("Verification/VerificationAlert"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true
    }
  },
  {
    path: "/email-verification",
    name: "VerificationEmail",
    component: page("Verification/VerificationEmail"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/storybook",
    name: "Storybook",
    component: page("Storybook"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true
    }
  },
  {
    path: "/subscription/:priceId",
    name: "Subscription",
    component: page("Subscription"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/subscription-success/:sessionId",
    name: "SubscriptionSuccess",
    component: page("Subscription"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/subscription-cancel/:sessionId",
    name: "SubscriptionCancel",
    component: page("Subscription"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/company/disabled",
    name: "DisabledAccount",
    component: page("DisabledAccount"),
    meta: {
      layout: "EmptyLayout",
      withoutAccount: true,
      createAccount: true
    }
  },
  {
    path: "/accounts",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "",
        name: "accounts",
        component: page("Accounts.vue"),
        meta: {
          adminPage: true,
          browserTabConfig: {
            title: "Accounts"
          }
        }
      },
      {
        path: "/edit-account",
        name: "edit-account",
        component: page("AdminPanel/AccountPage.vue"),
        meta: {
          breadcrumbConfigList: [
            {
              bcWait: "account.get",
              bcGetter: "GET_ACCOUNT",
              breadcrumbTitle: ({ value }) => (value?.name ? value.name : "Edit Account")
            }
          ],
          browserTabConfig: {
            getter: "GET_ACCOUNT",
            title: ({ value }) => (value?.name ? `Account ${value.name}` : ""),
            wait: "account.get"
          },
          adminPage: true
        }
      }
    ]
  },
  {
    path: "/email-templates",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "",
        name: "emails",
        component: page("EmailsAdmin.vue"),
        meta: {
          adminPage: true,
          browserTabConfig: {
            title: "Email Templates"
          }
        }
      },
      {
        path: "/edit-template/:template",
        name: "edit-template",
        component: page("Emails/CreateTemplate"),
        meta: {
          adminPage: true,
          browserTabConfig: {
            getter: "GET_EMAIL_TEMPLATE_TYPE",
            title: ({ value }) =>
              value?.type ? `Email Template ${splitAndCapitalize(value.type)}` : ""
          }
        }
      }
    ]
  },
  {
    path: "/:accountId",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "",
        redirect: { name: "projects" }
      },
      {
        path: "myWork",
        name: "myWork",
        meta: {
          breadcrumbConfigList: [
            {
              bcGetter: "GET_ITEM",
              breadcrumbTitle: ({ route }) => (!route.query?.i ? "My Work" : "")
            }
          ],
          browserTabConfig: {
            title: "My Work"
          }
        },
        component: page("MyWork.vue")
      },
      {
        path: "myWork/:projectId",
        name: "myWorkEditItem",
        meta: {
          breadcrumbConfigList: [
            {
              bcGetter: "GET_ITEM",
              breadcrumbTitle: ({ value }) => (value?.team ? value.team.name : "")
            },
            {
              bcGetter: "GET_ITEM",
              breadcrumbLink: ({ value, route }) => ({
                title: value.team ? Item.getBacklogNameByItem(value) : "",
                route: {
                  name: "backlog",
                  params: {
                    ...route.params,
                    teamId: value?.team?.urlName ?? ""
                  },
                  query: Item.getBacklogQueryByItem(value)
                }
              })
            },
            {
              bcGetter: "GET_ITEM",
              breadcrumbTitle: ({ value }) => (value?.id ? "Edit Item" : "")
            }
          ],
          browserTabConfig: {
            title: "My Work"
          }
        },
        component: page("MyWork.vue")
      },
      {
        path: "roadmap",
        name: "roadmap",
        meta: {
          browserTabConfig: {
            getter: "FILTER_BY_PROJECT",
            title: ({ value }) => `${value?.name ?? ""} Roadmap`
          },
          breadcrumbConfigList: [
            {
              breadcrumbTitle: "Roadmap"
            },
            {
              bcGetter: "FILTER_BY_PROJECT",
              breadcrumbTitle: ({ value }) => value?.name ?? ""
            }
          ]
        },
        component: page("Roadmap.vue")
      },
      {
        path: "user/:userId?",
        name: "edit-user",
        component: page("AdminPanel/UserPage.vue"),
        meta: {
          breadcrumbConfigList: [
            {
              bcGetter: "GET_USER_CURRENT_PROFILE",
              breadcrumbLink: ({ value, route }) =>
                route.query.myAccount
                  ? {
                      title: "My Account",
                      route: {
                        name: "edit-account",
                        query: {
                          accountId: value.accountId
                        }
                      }
                    }
                  : null
            },
            {
              bcGetter: "GET_USER_CURRENT_PROFILE",
              breadcrumbLink: ({ value, route }) =>
                route.query.myAccount
                  ? {
                      title: "Users",
                      route: {
                        name: "edit-account",
                        query: {
                          accountId: value.accountId,
                          t: "users"
                        }
                      }
                    }
                  : null
            },
            {
              bcGetter: "USER_BY_ID",
              breadcrumbTitle: ({ value }) => value?.fullName ?? "My Profile"
            }
          ],
          browserTabConfig: {
            getter: "USER_BY_ID",
            title: ({ value }) => (value ? `${value.fullName} Profile` : "My Profile")
          }
        }
      },

      {
        path: "team/:teamId?",
        name: "edit-team",
        component: page("Create/CreateTeam/CreateTeam.vue"),
        meta: {
          hideTeamLabel: true,
          title: "My Account",
          breadcrumbConfigList: [
            {
              bcGetter: "GET_USER_CURRENT_PROFILE",
              breadcrumbLink: ({ value }) =>
                value
                  ? {
                      title: "My Account",
                      route: {
                        name: "edit-account",
                        query: {
                          accountId: value.accountId
                        }
                      }
                    }
                  : null
            },
            {
              bcGetter: "GET_USER_CURRENT_PROFILE",
              breadcrumbLink: ({ value }) =>
                value
                  ? {
                      title: "Teams",
                      route: {
                        name: "edit-account",
                        query: {
                          accountId: value.accountId,
                          t: "teams"
                        }
                      }
                    }
                  : null
            },
            {
              bcGetter: "GET_TEAM",
              breadcrumbTitle: ({ value }) => value.name || ""
            }
          ]
        }
      },
      {
        path: "projects",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "",
            name: "projects",
            meta: {
              hideBackButtonInFooter: true,
              browserTabConfig: {
                title: "Project Simple"
              },
              updateComponentByRoute: true
            },
            component: page("Projects/index.vue")
          },
          {
            path: ":projectId",
            name: "project",
            component: page("Projects/index.vue"),
            meta: {
              hideProjectLabel: true,
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => value.name ?? ""
              },
              updateComponentByRoute: true,
              breadcrumbConfigList: [
                {
                  bcGetter: "GET_USER_CURRENT_PROFILE",
                  breadcrumbLink: ({ value, route }) =>
                    route.query.myAccount
                      ? {
                          title: "My Account",
                          route: {
                            name: "edit-account",
                            query: {
                              accountId: value.accountId
                            }
                          }
                        }
                      : null
                },
                {
                  bcGetter: "GET_USER_CURRENT_PROFILE",
                  breadcrumbLink: ({ value, route }) =>
                    route.query.myAccount
                      ? {
                          title: "Projects",
                          route: {
                            name: "edit-account",
                            query: {
                              accountId: value.accountId,
                              t: "projects"
                            }
                          }
                        }
                      : null
                },
                {
                  bcGetter: "GET_PROJECT",
                  breadcrumbTitle: ({ value }) => (value ? value.name : ""),
                  wait: "project.get"
                }
              ]
            }
          }
        ]
      },
      {
        path: "create-project",
        name: "create-project",
        component: page("Create/CreateProjectWizard"),
        meta: {
          browserTabConfig: {
            title: "New Project"
          },
          layout: "EmptyLayoutWithHeader"
        }
      },
      {
        path: ":projectId-:itemId",
        name: "Item",
        component: page("Create/CreateItem/CreateItem.vue"),
        beforeEnter: (to, from, next) => {
          const initialParams = to.params.projectId + "-" + to.params.itemId

          const result = parsingProjectIdAndItemNumber(initialParams)

          to.params.projectId = result.projectId
          to.params.itemId = result.itemId
          next()
        },
        meta: {
          breadcrumbConfigList: [
            {
              bcGetter: "GET_ITEM",
              breadcrumbComponent: ({ value }) =>
                value?.isSupportCase && !value.team && !value.epic ? "SupportCaseDropdown" : null
            },
            {
              bcGetter: "GET_ITEM",
              breadcrumbLink: ({ value, route }) =>
                !value.team && value?.epic
                  ? {
                      title: `Epic #${value.epic.sequenceNumber}`,
                      route: {
                        name: "epic",
                        params: {
                          ...route.params,
                          epicId: value.epic.sequenceNumber ?? ""
                        }
                      }
                    }
                  : null
            },
            {
              bcGetter: "GET_ITEM",
              breadcrumbLink: ({ value, route }) => {
                const reviewPage = value.sprint?.isReview || value.sprint?.isReviewCompleted
                const params = {
                  ...route.params,
                  teamId: value?.team?.urlName ?? ""
                }

                if (reviewPage) {
                  params.sprintNumber = value.sprint?.sprintNumber
                  params.sprintId = value.sprint?.sprintNumber
                }

                return {
                  title: value.team ? Item.getBacklogNameByItem(value) : "",
                  route: {
                    name: reviewPage ? "SprintReview" : "backlog",
                    params,
                    query: reviewPage ? "" : Item.getBacklogQueryByItem(value)
                  }
                }
              }
            },
            {
              bcGetter: "GET_ITEM",
              breadcrumbTitle: ({ value }) => (value?.id ? "Edit Item" : "Create Item")
            }
          ],
          breadcrumbSelectedTeam: {
            bcGetter: "GET_ITEM",
            selectedTeam: ({ value }) => value?.team
          },
          browserTabConfig: {
            getter: "GET_ITEM",
            title: ({ value }) => (value?.id ? `#${value.sequenceNumber} ${value.title}` : "")
          },
          canSelectTeam: true,
          updateComponentByRoute: true,
          middleware: "checkUserInTeamForItem"
        }
      },
      {
        path: ":projectId",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "edit",
            name: "edit-project",
            component: page("Create/CreateProject"),
            meta: {
              editProject: true,
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Settings` : "")
              }
            }
          },
          {
            path: "project-wizard",
            name: "project-wizard",
            component: page("Create/CreateProjectWizard"),
            meta: {
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Settings` : "")
              },
              layout: "EmptyLayoutWithHeader"
            }
          },
          {
            path: "create-team",
            name: "create-team",
            component: page("Create/CreateTeamWizard"),
            meta: {
              browserTabConfig: {
                title: "New Team"
              },
              layout: "EmptyLayoutWithHeader"
            }
          },
          {
            path: "epics",
            name: "epics",
            meta: {
              breadcrumbConfigList: [
                {
                  breadcrumbTitle: "Epics"
                }
              ],
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Epics` : ""),
                wait: "teamsAndProject.get"
              }
            },
            component: page("Epics.vue")
          },
          {
            path: "epic/:epicId",
            name: "epic",
            component: page("Create/CreateEpic"),
            meta: {
              createStoryInBacklog: true,
              breadcrumbConfigList: [
                {
                  breadcrumbLink: ({ route }) =>
                    !route.query.i
                      ? {
                          title: "Epics",
                          route: {
                            name: "epics"
                          }
                        }
                      : null
                },
                {
                  bcGetter: "GET_EPIC",
                  bcWait: "epic.get",
                  breadcrumbTitle: ({ value, route }) =>
                    value?.name && !route.query.i ? value.name : ""
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbLink: ({ value, route }) =>
                    !value.team && value?.epic
                      ? {
                          title: `Epic #${value.epic.sequenceNumber}`,
                          route: {
                            name: "epic",
                            params: {
                              ...route.params,
                              epicId: value.epic.sequenceNumber ?? ""
                            }
                          }
                        }
                      : null
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbLink: ({ value, route }) => ({
                    title: value.team ? Item.getBacklogNameByItem(value) : "",
                    route: {
                      name: "backlog",
                      params: {
                        ...route.params,
                        teamId: value?.team?.urlName ?? ""
                      },
                      query: Item.getBacklogQueryByItem(value)
                    }
                  })
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbTitle: ({ value }) => (value?.id ? "Edit Item" : "")
                }
              ],
              browserTabConfig: {
                getter: "GET_EPIC",
                title: ({ value }) =>
                  value?.name && value.sequenceNumber
                    ? `E-${value.sequenceNumber} ${value.name}`
                    : "",
                wait: "epic.get"
              }
            }
          },
          {
            path: "edit-sprint/:teamId/:sprintId",
            name: "edit-sprint",
            meta: {
              cacheData: true,
              updateComponentByRoute: true,
              middleware: "createSprintMiddleware"
            },
            component: page("Create/CreateSprint/index.vue")
          },
          {
            path: "create-sprint",
            name: "create-sprint",
            meta: {
              updateComponentByRoute: true,
              browserTabConfig: {
                title: "New Sprint"
              },
              middleware: "createSprintMiddleware"
            },
            component: page("Create/CreateSprint/index.vue")
          },
          {
            path: "sprints",
            name: "sprints",
            meta: {
              breadcrumbConfigList: [
                {
                  breadcrumbTitle: "Sprints"
                }
              ],
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Active Sprints` : ""),
                wait: "teamsAndProject.get"
              },
              middleware: "activeSprintsMiddleware"
            },
            component: page("Sprints.vue")
          },
          {
            path: "milestones/:index?",
            name: "Milestones",
            meta: {
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Milestones` : ""),
                wait: "teamsAndProject.get"
              },
              breadcrumbConfigList: [
                {
                  breadcrumbTitle: "Milestones"
                }
              ]
            },
            component: page("Milestone.vue")
          },
          {
            path: "allSprints",
            name: "allSprints",
            meta: {
              breadcrumbConfigList: [
                {
                  breadcrumbTitle: "All Sprints"
                }
              ],
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} All Sprints` : ""),
                wait: "teamsAndProject.get"
              }
            },
            component: page("AllSprints.vue")
          },
          {
            path: "retros",
            name: "retros",
            meta: {
              browserTabConfig: {
                getter: "GET_PROJECT",
                title: ({ value }) => (value?.name ? `${value.name} Retrospectives` : ""),
                wait: "teamsAndProject.get"
              }
            },
            component: page("Retrospective/AllRetrospectives.vue")
          },
          {
            path: "product-backlog",
            name: "product-backlog",
            meta: {
              createStoryInBacklog: true,
              browserTabConfig: {
                title: "Product Backlog"
              },
              breadcrumbConfigList: [
                {
                  breadcrumbTitle: "Product Backlog"
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbTitle: ({ value }) => (value?.team ? value.team.name : "")
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbLink: ({ value, route }) => ({
                    title: value.team ? Item.getBacklogNameByItem(value) : "",
                    route: {
                      name: "backlog",
                      params: {
                        ...route.params,
                        teamId: value?.team?.urlName ?? ""
                      },
                      query: Item.getBacklogQueryByItem(value)
                    }
                  })
                },
                {
                  bcGetter: "GET_ITEM",
                  breadcrumbTitle: ({ value }) => (value?.id ? "Edit Item" : "")
                }
              ]
            },
            component: page("ProductBacklog/index.vue")
          },
          {
            path: ":teamId?",
            component: {
              render() {
                return h(resolveComponent("router-view"))
              }
            },
            children: [
              {
                path: "items/create",
                name: "CreateItem",
                component: page("Create/CreateItem/CreateItem.vue"),
                meta: {
                  breadcrumbConfigList: [
                    {
                      bcGetter: "GET_ITEM",
                      breadcrumbTitle: ({ value }) => (value.team || !value.epic) && "Unprioritized"
                    },
                    {
                      bcGetter: "GET_ITEM",
                      breadcrumbLink: ({ value, route }) =>
                        value.epic && !value.team
                          ? {
                              title: `Epic #${value.epic.sequenceNumber}`,
                              route: {
                                name: "epic",
                                params: {
                                  ...route.params,
                                  epicId: value.epic.sequenceNumber ?? ""
                                }
                              }
                            }
                          : null
                    },
                    {
                      breadcrumbTitle: "Create Item"
                    }
                  ],
                  canSelectTeam: true,
                  breadcrumbSelectedTeam: {
                    bcGetter: "GET_ITEM",
                    selectedTeam: ({ value }) => value?.team
                  },
                  updateComponentByRoute: true,
                  browserTabConfig: {
                    title: "New Item"
                  },
                  middleware: "checkUserInTeamForItem"
                }
              },
              {
                path: "backlog",
                name: "backlog",
                meta: {
                  createStoryInBacklog: true,
                  breadcrumbConfigList: [
                    {
                      bcGetter: "GET_MAIN_BACKLOG_SETUP",
                      breadcrumbTitle: ({ value, route }) => {
                        if (route.query?.i || !value) return ""

                        const backlogTitle = $t(`backlog.titles.${value.storeBacklogId}`)

                        return value?.sprintInfo
                          ? backlogTitle + ` #${value.sprintInfo.sprintNumber}`
                          : backlogTitle
                      }
                    },
                    {
                      bcGetter: "GET_ITEM",
                      breadcrumbLink: ({ value, route }) => {
                        if (!value?.id) {
                          return
                        }
                        const params = { ...route.params }
                        return {
                          title: route.query.i && value?.id ? Item.getBacklogNameByItem(value) : "",
                          route: {
                            ...route,
                            query: Item.getBacklogQueryByItem(value),
                            params: value?.team
                              ? {
                                  ...params,
                                  teamId: value.team.urlName
                                }
                              : params
                          }
                        }
                      },
                      bcWait: "get.item"
                    },
                    {
                      bcGetter: "GET_BACKLOG_CREATED_ITEM",
                      breadcrumbLink: ({ value, route }) => {
                        if (!value) {
                          return
                        }
                        return {
                          title: route.query.i === "create" ? Item.getBacklogNameByItem(value) : "",
                          route: {
                            ...route,
                            query: Item.getBacklogQueryByItem(value)
                          }
                        }
                      }
                    },
                    {
                      bcGetter: "GET_ITEM",
                      breadcrumbTitle: ({ value, route }) => {
                        if (value?.id) return "Edit Item"

                        return route.query?.i === "create" ? "Create Item" : ""
                      }
                    }
                  ],
                  browserTabConfig: {
                    getter: "GET_SESSION_TEAM",
                    secondaryGetter: "GET_ITEM",
                    title: ({ value, route, secondaryValue }) => {
                      if (value?.name && !route.query.i) return `${value.name} Backlog`
                      if (route.query.i === "create") return "New Item"
                      return secondaryValue?.id
                        ? `#${secondaryValue.sequenceNumber} ${secondaryValue.title}`
                        : ""
                    },
                    wait: "get.item"
                  },
                  breadcrumbSelectedTeam: {
                    bcGetter: "GET_ITEM",
                    selectedTeam: ({ value }) => value?.team
                  },
                  canSelectTeam: true,
                  updateComponentByRoute: true,
                  middleware: "backlogMiddleware"
                },
                component: page("Home.vue")
              }
            ]
          },
          {
            path: ":teamId",
            component: {
              render() {
                return h(resolveComponent("router-view"))
              }
            },
            children: [
              {
                path: "storypoker",
                name: "storypoker",
                meta: {
                  breadcrumbConfigList: [
                    {
                      breadcrumbTitle: "Story Poker"
                    }
                  ],
                  browserTabConfig: {
                    getter: "FILTER_BY_TEAM",
                    title: ({ value }) => (value?.name ? `${value.name} Story Poker` : "")
                  },
                  canSelectTeam: true
                },
                component: page("StoryPoker")
              },
              {
                path: "sprint",
                component: {
                  render() {
                    return h(resolveComponent("router-view"))
                  }
                },
                children: [
                  {
                    path: ":sprintId",
                    component: {
                      render() {
                        return h(resolveComponent("router-view"))
                      }
                    },
                    children: [
                      {
                        path: "",
                        name: "sprint",
                        component: page("SprintOverview"),
                        meta: {
                          updateComponentByRoute: true,
                          browserTabConfig: {
                            getter: "SPRINT_WITH_STATS",
                            title: ({ value }) => {
                              if (!value) return ""
                              const { sprint } = value
                              return `${sprint.team.name} Sprint #${sprint.sequenceNumber} Overview`
                            },
                            wait: "loading"
                          },
                          middleware: "sprintOverviewMiddleware"
                        }
                      },
                      {
                        path: "analytics",
                        name: "sprint-analytics",
                        component: page("SprintAnalytics/index.vue"),
                        meta: {
                          updateComponentByRoute: true
                        }
                      },
                      {
                        path: "review",
                        name: "SprintReview",
                        meta: {
                          breadcrumbConfigList: [
                            {
                              breadcrumbTitle: "Sprint Review"
                            }
                          ],
                          browserTabConfig: {
                            getter: "SPRINT_WITH_STATS",
                            title: ({ value }) => {
                              if (!value) return ""
                              const { sprint } = value
                              return `${sprint.team.name} Sprint #${sprint.sequenceNumber} Review`
                            },
                            wait: "loading"
                          },
                          middleware: "sprintReviewMiddleware"
                        },
                        component: page("SprintReview.vue")
                      },

                      {
                        path: "retrospective-developer",
                        name: "RetrospectiveDeveloper",
                        meta: {
                          breadcrumbConfigList: [
                            {
                              breadcrumbTitle: "Retrospective Developer"
                            }
                          ],
                          browserTabConfig: {
                            getter: "GET_RETROSPECTIVE",
                            title: ({ value }) => {
                              if (!value?.id) return ""
                              const { sprint } = value
                              return `${sprint.team.name} Sprint #${sprint.sequenceNumber} Retrospective`
                            },
                            wait: "loading"
                          },
                          middleware: "sprintRetrospectiveDeveloperMiddleware"
                        },
                        component: page("Retrospective/RetrospectiveDeveloper.vue")
                      },
                      {
                        path: "retrospective-vote",
                        name: "retrospective-vote",
                        component: page("Retrospective/RetrospectiveVote.vue"),
                        meta: {
                          browserTabConfig: {
                            getter: "GET_RETROSPECTIVE",
                            title: ({ value }) => {
                              if (!value?.id) return ""
                              const { sprint } = value
                              return `${sprint.team.name} Sprint #${sprint.sequenceNumber} Retrospective`
                            },
                            wait: "loading"
                          },
                          middleware: "sprintRetrospectiveVoteMiddleware"
                        }
                      },
                      {
                        path: "retrospective",
                        name: "retrospective",
                        component: page("Retrospective/RetrospectiveBoard.vue"),
                        meta: {
                          browserTabConfig: {
                            getter: "GET_RETROSPECTIVE",
                            title: ({ value }) => {
                              if (!value?.id) return ""
                              const { sprint } = value
                              return `${sprint.team.name} Sprint #${sprint.sequenceNumber} Retrospective`
                            },
                            wait: "loading"
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]
export default routers
