<template>
  <span
    class="dot dot_notification is-red"
    :class="`is-${color}`"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

const components = {}

@Options({
  name: "DotNotification",
  components,
})
export default class extends Vue {
  @Prop({ default: "red" }) readonly color: string
}
</script>
