import {
  AnimateConfetti,
  CrateConfetti,
  LoopAnimationParams,
  PositionParams,
  StoryAnimationParams,
} from "@/models/Animation"
import { gsap } from "gsap"

export const slideAnimation = (el, duration?: number) => gsap.fromTo(el, {
  x: "0",
}, {
  x: "110%",
  duration: duration ?? 0.3,
  opacity: 1,
  paused: true,
})

export const slideOutAnimationWithHide = (el, duration?: number) => gsap.fromTo(el, {
  x: "0",
  display: "block",
}, {
  x: "110%",
  duration: duration ?? 0.3,
  opacity: 1,
  paused: true,
  display: "none",
})

export const animationContent60to40 = (el, duration?: number) => gsap.fromTo(el, {
  gridTemplateColumns: "100%",
}, {
  gridTemplateColumns: "62% calc(38% - 30px)",
  duration: duration ?? 0.3,
  paused: true,
})

export const unfoldAnimation = (el, duration?: number) => gsap.timeline({
  paused: true,
}).to(el, {
  duration: 0,
  height: 0,
}, 0).to(el, {
  duration: duration ?? 0.3,
  height: "auto",
  clearProps: "transform",
})

export const reverseScaleAnimation = el => gsap.timeline({ paused: true })
  .to(el, {
    scale: 1,
    opacity: 1,
    duration: 0,
  })
  .to(el, {
    scale: 0,
    opacity: 0,
    duration: 0.5,
    clearProps: "transform",
  })

export const translateTransformAnimation = ({ element, x, y } : { element: HTMLElement, x, y }) => gsap.fromTo(element, {
  x: 0,
  y: 0,
  opacity: 0,
}, {
  x: x,
  y: y,
  duration: 0.5,
  opacity: 1,
  paused: true,
})

export const removeTransform = el => gsap.to(el, {
  duration: 0.3,
  clearProps: "transform",
  paused: true,
})

export const elementExpansion = (el, width?: number) => gsap.timeline({
  paused: true,
},
).to(el, {
  width: 0,
  opacity: 0,
  duration: 0,
}, 0).to(el, {
  opacity: 1,
  duration: 0.3,
  width: width ?? "auto",
  clearProps: "transform",
}, 0)

export const scaleAnimation = (el, duration?: number) => gsap.timeline(
  {
    paused: true,
  },
)
  .to(el, {
    scale: 0,
    duration: 0,
  })
  .to(el, {
    duration: duration ?? 0.5,
    scale: 1,
    clearProps: "transform",
  })

export const flipFadeOutAnimation = el => gsap.timeline({ paused: true })
  .to(el, {
    scale: 0.5,
    rotationX: 90,
    opacity: 0,
    duration: 0.5,
    clearProps: "all",
  })

export const animateConfetti = (payload: AnimateConfetti) => {
  const {
    angle,
    speed,
    element,
    gravity = 10,
  } = payload
  const timeline = gsap.timeline({ pause: true })
  const duration = 1.2
  let updateTime = 0
  let x = 0
  let y = 0
  while (updateTime < 6) {
    const cos = Math.cos(angle)
    const sin = Math.sin(angle)
    updateTime += 0.1
    x = updateTime * speed * cos
    y = updateTime * speed * sin - gravity * Math.pow(updateTime, 2) / 2
    timeline.to(element, {
      duration: duration / 60,
      x: x,
      y: -y,
    }).play()
  }
}

export const storyOpeningAnimation = (params: StoryAnimationParams) => {
  const { itemElement, elementWrapper, animateElement } = params
  const itemPosition = itemElement?.getBoundingClientRect()
  const itemWrapperPosition = elementWrapper.getBoundingClientRect()
  const startTop = itemPosition ? itemPosition.top : itemWrapperPosition.height / 2
  const startLeft = itemPosition ? itemPosition.left + itemPosition.width / 2 : itemWrapperPosition.width / 2

  return gsap.timeline({
    paused: true,
  }).to(animateElement, {
    duration: 0,
    position: "fixed",
    top: startTop,
    left: startLeft,
    width: 0,
    height: 0,
    overflow: "hidden",
  }).to(animateElement, {
    position: "fixed",
    duration: 0.5,
    top: itemWrapperPosition.top,
    width: itemWrapperPosition.width,
    height: itemWrapperPosition.height,
    left: itemWrapperPosition.left,
  }).to(animateElement, {
    duration: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  })
}

export const storyScalingAnimation = (itemElement: HTMLElement) => gsap.timeline({
  paused: true,
}).to(itemElement, {
  scale: 0.98,
  duration: 0.15,
}).to(itemElement, {
    scale: 1,
    duration: 0.15,
  clearProps: "transform",
  })

export const crateConfetti = (payload: CrateConfetti) => {
  const {
    quantity,
    minAngle,
    maxAngle,
    parentElement,
    minSpeed,
    maxSpeed,
    colors = [
      "#FFFF04",
      "#EA4C89",
      "#892AB8",
      "#4AF2FD",
    ],
  } = payload
  for (let i = quantity - 1; i >= 0; i--) {
    const angle = gsap.utils.random(minAngle, maxAngle),
      speed = gsap.utils.random(minSpeed, maxSpeed),
      dot = document.createElement("div")
    dot.style.setProperty("background", colors[Math.floor(gsap.utils.random(0, colors.length - 1))])
    parentElement.appendChild(dot)
    gsap.set(dot, {
      opacity: 0,
      scale: gsap.utils.random(.4, .7),
    })
    gsap.timeline({
      onComplete() {
        dot.remove()
      },
      onStart() {
        animateConfetti({ element: dot, angle, speed })
      },
    }).to(dot, {
      duration: .05,
      opacity: 1,
    }, 0).to(dot, {
      duration: 1.8,
      rotationX: `-=${gsap.utils.random(720, 1440)}`,
      rotationZ: `+=${gsap.utils.random(720, 1440)}`,
    }, 0).to(dot, {
      duration: 1,
      opacity: 0,
    }, .5)
  }
}

export const loopAnimation = (params: LoopAnimationParams) => {
  const { element, newWidth, newHeight, defaultC } = params

  const position = element.getBoundingClientRect()

  const oldWidth = element.getBoundingClientRect().width
  const oldHeight = element.getBoundingClientRect().height

  const newPosition: PositionParams = {
    top: position.top - (newHeight - oldHeight) / 2,
    left: position.left - (newWidth - oldWidth) / 2,
    right: position.right + (newWidth - oldWidth) / 2,
    bottom: position.bottom + (newHeight - oldHeight) / 2,
  }

  if (newPosition.top && newPosition.top < defaultC) {
    newPosition.top = defaultC
    newPosition.bottom = undefined
  }
  if (newPosition.bottom && newPosition.bottom < defaultC) {
    newPosition.top = undefined
    newPosition.bottom = defaultC
  }
  if (newPosition.left && newPosition.left < defaultC) {
    newPosition.left = defaultC
    newPosition.right = undefined
  }
  if (newPosition.right && newPosition.right < defaultC) {
    newPosition.right = defaultC
    newPosition.left = undefined
  }

  return gsap.timeline({
    paused: true,
  }).set(element, {
    opacity: 0,
    position: "fixed",
    duration: 0,
    top: position.top,
    right: position.right,
    bottom: position.bottom,
    left: position.left,
    width: oldWidth,
    height: oldHeight,
  }).to(element, {
    duration: 0.3,
    opacity: 1,
    position: "fixed",
    top: newPosition.top,
    right: newPosition.right,
    bottom: newPosition.bottom,
    left: newPosition.left,
    width: newWidth,
    height: newHeight,
    zIndex: 999,
    transform: "translate(0)",
  })
}

export const slideItemAnimationLeft = (el, skeleton) => gsap.timeline({
  paused: true,
}).to(skeleton, {
  duration: 0,
  transform: "translateX(-110vw)",
})
  .to(el, {
    duration: 0.3,
    transform: "translateX(110vw)",
  })
  .to(skeleton, {
    duration: 0.3,
    transform: "translateX(0)",
  }, 0)


export const slideItemAnimationRight = (el, skeleton) => gsap.timeline({
  paused: true,
}).to(skeleton, {
  duration: 0,
  transform: "translateX(110vw)",
})
  .to(el, {
    duration: 0.3,
    transform: "translateX(-110vw)",
  })
  .to(skeleton, {
    duration: 0.3,
    transform: "translateX(0)",
  }, 0)
