import { Node, mergeAttributes } from '@tiptap/core';
import { dropAttachmentPlugin, UploadAttachmentFn, TypeConfig, VUFile } from "@/models";
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import EditorAttachment from '@/components/Editor/EditorAttachment.vue'
import { uploadAttachments } from '@/utils/helpers';
import {ATTACHMENT_IMG_HEIGHT, ATTACHMENT_IMG_WIDTH} from "@/utils/Constans";

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    file: {
       setFile: (files: VUFile[], config: TypeConfig | null) => ReturnType
    }
  }
}
export const Attachment = (uploadFn: UploadAttachmentFn, config: TypeConfig | null, isResizable: boolean) => {
  return Node.create({
    name: 'attachment',
    inline: true,
    group: 'inline',
    draggable: true,

    addAttributes() {
      return {
        src: {},
        href: {},
        id: {},
        alt: { default: null },
        info: { default: "{}" },
        title: { default: null },
        isResizable: { default: true },
        type: { default: 'img' },
        width: {
          default: ATTACHMENT_IMG_WIDTH,
        },
        height: {
          default: ATTACHMENT_IMG_HEIGHT
        },
        isSkeletor: {
          default: false
        }
      }
    },
    parseHTML() {
      return [
        { tag: 'vue-component[data-type="draggable-item"]' },
        {
          tag: 'img[src]',
          getAttrs: (element: any) => ({
            src: element.getAttribute('src'),
            alt: element.getAttribute('alt'),
            title: element.getAttribute('title'),
            isSkeletor: true
          }),
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      const data = window.btoa(encodeURI(JSON.stringify(HTMLAttributes)));

      return ['vue-component', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item', data, isResizable})]
    },

    addNodeView() {
      return VueNodeViewRenderer(EditorAttachment)
    },

    // @ts-ignore
     addCommands() {
      return {
        setFile: (files, config) => async ({ view }) => {
          return await uploadAttachments({
            upload: uploadFn,
            files,
            view,
            config,
            editor: this.editor
          });
        }
      }
    },
    addProseMirrorPlugins() {
      return [dropAttachmentPlugin(uploadFn, config, this.editor)];
    },
  });
};
