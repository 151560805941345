<template>
  <DropdownTooltip
    placement="bottom-start"
    placementSub="right-start"
    :menu="teamsOptions"
    popperClass="team-selection header__breadcrumb-menu"
    popperClassSub="sprint-selection header__breadcrumb-menu"
    optionMenuClass="header__breadcrumb-menu-item"
    submenuWaitTitle="sprints.notCompleleted"
    @dropdownSubMenu="onTeamClick"
    @dropdownOptionMenu="onMenuItemClick"
  >
    <template #tooltip-label>
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        className="header__breadcrumb"
      >
        /<span>{{ selectedTeamName }}</span>
      </BaseSubHeading>
    </template>
    <template #tooltip-submenu-empty="{ item }">
      <div
        class="header__breadcrumb-menu-item empty-sprints-label"
        @click="createNewSprint(item)"
      >
        <span class="empty-label">No Sprints found</span>
        <span class="emply-link">Create Sprint #{{ nextSprintNumber }}</span>
      </div>
    </template>
    <template #tooltip-item="{ item }">
      <div class="team-selection__item">
        <Avatar
          :src="item.team.picture?.pictureUrl"
          class="team__avatar"
          type="teamsGroup"
        />
        <span class="multiselect__option_team-name">{{ item.team?.name }}</span>
        <icon
          width="6px"
          height="8px"
          data="@icon/triangle_right.svg"
          fill="false"
          color="#363945"
          class="team-selection__arrow"
        />
      </div>
    </template>
    <template #tooltip-submenu-item="{ option }">
      <SprintHeading
        :sprint="option"
        :showDate="false"
        class="sprint-label"
      >
        <template #title="{ title }">
          <BaseSubHeading
            size="medium"
            weight="400"
            color="monochrome-07"
          >
            {{ title }}
          </BaseSubHeading>
        </template>
      </SprintHeading>
    </template>
  </DropdownTooltip>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit } from "vue-property-decorator"
import { Getter, Action } from "s-vuex-class"
import { Getters, Actions } from "@/store"

import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"
import Avatar from "@/components/Avatar/index.vue"
import { Team, SprintInfo } from "@/models"
import SprintHeading from "@/components/Sprint/SprintHeading.vue"

@Options({
  name: "BreadcrumbsTeamsBlock",
  components: {
    DropdownTooltip,
    Avatar,
    SprintHeading,
  },
})
export default class BreadcrumbsTeamsBlock extends Vue {
  @Getter(Getters.GET_TEAMS) teams: Team[]
  @Getter(Getters.GET_SESSION_TEAM) selectedTeam: Team

  @Getter(Getters.GET_SPRINT_NUMBER) readonly nextSprintNumber: number
  @Action(Actions.NOT_COMPLETED_SPRINTS) getNotCompletedSprints: ({ teamId, setMetrics } : {teamId: string, setMetrics?: boolean}) => SprintInfo[]
  @Action(Actions.GET_SPRINT_NUMBER) getSprintNumber: (teamId: string) => void
  sprintsListByTeam: {[key: string] : SprintInfo[] } = {}

  get selectedTeamName() {
    return this.selectedTeam?.name ?? this.$route.params.teamId
  }

  get teamsOptions() {
    return this.teams.map(team => ({
      id: team.id,
      team: team,
      options: this.sprintsListByTeam[team.id] ?? [],
    }))
  }

  async onTeamClick({ id }) {
    if (!id) {
      return
    }
    this.$wait.start("sprints.notCompleleted")
    const sprints = await this.getNotCompletedSprints({
      teamId: id,
      setMetrics: false,
    })
    this.sprintsListByTeam[id] = sprints.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    await this.getSprintNumber(id)
    this.$wait.end("sprints.notCompleleted")
  }

  createNewSprint(item) {
    const query = item.team ? { t: item.team?.urlName } : {}
    this.$router.push({
      name: "create-sprint",
      query,
    })
  }

  @Emit()
  onMenuItemClick({ value, item, hide }) {
    hide()
    return { sprint: value, team: item.team }
  }
}
</script>
