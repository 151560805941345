import { Module, Mutation, Action } from "vuex-class-modules"
import { Modules } from "../modules"
import { store } from "../store"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { BaseModule } from "@/models/BaseModule"

export enum PaymentActions {
  START_SUBSCRIPTION = "START_SUBSCRIPTION",
  CONFIRM_SUBSCRIPTION = "CONFIRM_SUBSCRIPTION",
  GET_SUBSCRIPTION_URL = "GET_SUBSCRIPTION_URL",
}

@Module
class PaymentModule extends BaseModule {
  subscriptionUrl: string
  sessionId: string

  get [`get/${PaymentActions.GET_SUBSCRIPTION_URL}`]() {
    return this.subscriptionUrl
  }

  @Mutation
  setSubscriptionUrl(url: string) {
    this.subscriptionUrl = url
  }

  @Mutation
  setSessionId(sessionId: string) {
    this.sessionId = sessionId
  }

  @Action
  async [PaymentActions.START_SUBSCRIPTION]({ id, priceId }: { id: string; priceId: string }) {
    const data = await apiService.post<string>(Endpoint.START_SUBSCRIPTION(id), null, {
      priceId,
    })
    return this.handleResponse<string>(data, (data) => {
      this.setSubscriptionUrl(data)
    })
  }

  @Action
  async [PaymentActions.CONFIRM_SUBSCRIPTION]({ id, sessionId }: { id: string; sessionId: string }) {
    const data = await apiService.post<string>(Endpoint.CONFIRM_SUBSCRIPTION(id), null, {
      sessionId,
    })
    return this.handleResponse<string>(data, (data) => {
      this.setSessionId(data)
    })
  }
}


export const paymentModule = new PaymentModule({
  store,
  name: Modules.PAYMENT,
})
