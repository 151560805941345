import { Filter } from "@/models/Pageable"

export class FilterUser extends Filter {
  text: string
  projectId: string
  teamId: string
  accountId: string
  isSuperadmin: boolean
  forSearch: boolean
  extended: boolean
  includeDemo: boolean
  includeEnabledOnly:boolean
  constructor(props) {
    super(props)

    this.text = props.text ?? ""
    this.projectId = props.projectId ?? ""
    this.teamId = props.teamId ?? ""
    this.accountId = props.accountId ?? ""
    this.isSuperadmin = props.isSuperadmin ?? false
    this.forSearch = props.forSearch ?? false
    this.extended = props.extended ?? false
    this.includeDemo = props.includeDemo ?? false
    this.includeEnabledOnly = props.includeEnabledOnly ?? false
  }

  getJsonObj() {
    const data = {
      text: this.text,
      projectId: this.projectId,
      accountId: this.accountId,
      teamId: this.teamId,
      includeDemo: this.includeDemo,
      includeEnabledOnly: this.includeEnabledOnly,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterUser(props)
  }

  static createDefault() {
    const props = {
      text: "",
      projectId: "",
      teamId: "",
      accountId: "",
      isSuperadmin: false,
      forSearch: false,
      extended: false,
    }

    const filter = new FilterUser(props)
    // filter.addSort("firstName_keyword", true)
    return filter
  }
}
