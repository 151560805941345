import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@icon/info.svg'


const _hoisted_1 = { class: "custom-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon, {
      data: _imports_0,
      fill: false,
      width: _ctx.size,
      height: _ctx.size,
      color: "#7D92A7"
    }, null, 8, ["width", "height"])
  ])), [
    [_directive_tooltip, {
      content: _ctx.text,
      theme: 'info-tooltip',
      placement: _ctx.placement,
      popperClass: _ctx.className
    }]
  ])
}