import { Emit, Prop, Watch } from "vue-property-decorator"

import { Options, Vue } from "vue-class-component"

import { addDays } from "@/utils/dateUtil"
import { format } from "date-fns"
import {
  PokerCreateRequest,
  Team,
  UpdateObjectValue,
  User,
} from "@/models"
import { ToDoContent } from "@/models/ToDoToggleContent"
import { ToDo } from "@/models/ToDo"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"

@Options({
  name: "ToDoCreateAndUpdate",
  components: {},
})
export default class extends Vue {
  @Getter(Getters.GET_TODO) readonly toDo: ToDo
  @Getter(Getters.GET_CURRENT_PROJECT_ID) currentProjectId: string
  @Getter(Getters.GET_ACCOUNT_ID) accountId: string
  @Getter(Getters.GET_SESSION_TEAM_ID) currentTeamId: string

  @Action(Actions.UPDATE_TODO_VALUE) updateToDoValue: (payload: UpdateObjectValue<ToDo>) => void
  @Action(Actions.ADD_TODO) createToDo: (toDo: ToDo) => void
  @Action(Actions.UPDATE_TODO) updateToDo: (toDo: ToDo) => void
  @Action(Actions.CREATE_POKER) createPoker: (payload: PokerCreateRequest) => boolean
  @Action(Actions.GET_OPENED_TODO_COUNT) getOpenedToDoCount: () => void

  @Prop() widgetContent: ToDoContent

  today = false
  tomorrow = false
  users: User[] = []

  get estimatedDeadline() {
    return this.toDo.estimatedDeadline
  }

  get isEdited() {
    return this.toDo.isModified(this.toDo.changeByFields)
  }

  mounted() {
    if (!this.toDo.addMode) {
      this.toDo.setInitialState()
    }
    if (!this.toDo.id) {
      this.handleInput({
        id: "projectId",
        value: this.currentProjectId,
      })
      this.handleInput({
        id: "relatedResourceId",
        value: {
          id: this.accountId,
          type: "ACCOUNT",
        },
      })
    }
  }

  @Watch("estimatedDeadline")
  getDate() {
    if (this.estimatedDeadline) {
      const inputDate = format(this.estimatedDeadline, "dd")
      const todayDate = format(new Date(), "dd")
      const tomorrowDate = format(addDays(new Date(), 1), "dd")

      if (inputDate === todayDate) {
        this.today = true
        this.tomorrow = false
      } else if (inputDate === tomorrowDate) {
        this.tomorrow = true
        this.today = false
      } else if (inputDate !== todayDate && inputDate !== tomorrowDate) {
        this.today = false
        this.tomorrow = false
      }
    }
  }

  @Emit("onSave")
  async onSave() {
    if (this.toDo.isStoryPoker && this.currentTeamId) {
      await this.createPoker(new PokerCreateRequest({
        accountId: this.accountId,
        itemId: this.toDo.itemId,
        teamId: this.currentTeamId,
        estimatedDeadline: this.toDo.estimatedDeadline,
        createTodoItemRequest: this.toDo,
        asyncMode: true,
      }))
      await this.getOpenedToDoCount()
      this.onCancel()
      return
    }
    if (!this.toDo.id) {
      await this.createToDo(this.toDo)
      await this.getOpenedToDoCount()
      this.onCancel()
      return
    }
    await this.updateToDo(this.toDo)
    await this.getOpenedToDoCount()
    this.onCancel()
  }

  @Emit("onCancel")
  onCancel() {
    return
  }

  updateDate(date) {
    this.handleInput({
      id: "estimatedDeadline",
      value: date,
    })
  }

  onCheckboxToday(value) {
    if (value === true) {
      this.handleInput({
        id: "estimatedDeadline",
        value: new Date(),
      })
      this.tomorrow = false
    } else {
      this.today = false
    }
  }

  onCheckboxTomorrow(value) {
    if (value === true) {
      this.handleInput({
        id: "estimatedDeadline",
        value: addDays(new Date(), 1),
      })
      this.today = false
    } else {
      this.tomorrow = false
    }
  }

  updateUrgent(value) {
    this.handleInput({
      id: "urgent",
      value: value,
    })
  }

  handleInputEditor(editorData) {
    this.handleInput({
      id: "structuredDescription",
      value: {
        html: editorData.html,
        json: JSON.stringify(editorData.json),
        text: editorData.getEmailText(),
      },
    })
    const fileName = editorData.refers[0]?.attrs?.title
    this.handleInput({
      id: "name",
      value: editorData.text ? editorData.text : fileName,
    })
  }

  onSelectedUsers(users: User[]) {
    this.handleInput({
      id: "owners",
      value: users,
    })
  }

  onSelectedTeams(teams: Team[]) {
    this.handleInput({
      id: "selectedTeams",
      value: teams,
    })
  }

  handleInput(payload) {
    this.updateToDoValue(payload)
    this.updateToDoValue({ id: "addMode", value: this.isEdited } as any)
  }
}
