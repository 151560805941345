<template>
  <NotificationTemplate
    :notification="notification"
    :projectName="notification.projectName"
    class="notification__item"
  >
    <template #pic>
      <div class="label-pic is-green">
        <icon
          data="@icon/goal-icon.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Roadmap Goal Reached
      </BaseSubHeading>
    </template>
    <template #content>
      <div className="notification__update-item__content">
        <a
          :href="notification.url"
          class="link is-medium"
          target="_blank"
        >
          {{ notification.goal }}
        </a>
      </div>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationGoal } from "@/models/Notification"

@Options({
  name: "GOAL_REACHED",
  components: {
    NotificationTemplate,
  },
})
export default class GOAL_REACHED extends Vue {
  @Prop() readonly notification: NotificationGoal
}
</script>
