<template>
  <div class="avatar-group avatar-group_to-do">
    <Avatar
      v-if="!croppedAvatarsByLength.length"
      :src="null"
      type="avatarGroup"
      class="is-blue"
      :size="size"
      bordered
    />
    <div
      v-for="(item, index) in croppedAvatarsByLength"
      :key="index"
      :style="`z-index: ${index}`"
      class="avatar-group__item"
    >
      <Avatar
        v-tooltip.bottom="{
          content: item?.username,
          theme: 'info-tooltip-sm',
          disabled: !item?.username || !scaleByHover,
        }"
        :src="item?.pictureUrl"
        :type="type"
        :size="size"
        :gap="item?.getInitials()"
        :index="index"
        :showRemoveButton="showRemoveButton"
        :class="{
          'is-scale-by-hover': scaleByHover,
        }"
        bordered
        @removeAvatar="onRemoveAvatar(item)"
      />
    </div>
    <div
      v-if="lengthOfHiddenAvatars"
      :style="`z-index: ${numberOfVisibleUsers + 1}`"
      class="avatar is-bordered avatar-group__item avatar__length"
      :class="`is-${size}`"
    >
      +{{ lengthOfHiddenAvatars }}
    </div>
  </div>
</template>

<script lang="ts">
import { Emit, Prop } from "vue-property-decorator"
import Avatar from "@/components/Avatar/index.vue"
import { Options, Vue } from "vue-class-component"
import { User } from "@/models"

@Options({
  name: "AvatarGroupToDo",
  components: {
    Avatar,
  },
})

export default class AvatarGroupToDo extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly type!: string
  @Prop({
    default: () => [""],
    type: Array,
  }) readonly avatars!: User[]
  @Prop({ default: "md" }) size: string
  @Prop({ default: 3 }) numberOfVisibleUsers: number
  @Prop({ default: false }) showRemoveButton: boolean
  @Prop({ default: false }) scaleByHover: boolean
  
  get lengthOfHiddenAvatars() {
    return this.avatars.length - this.croppedAvatarsByLength.length
  }

  get croppedAvatarsByLength() {
    if (this.avatars.length <= this.numberOfVisibleUsers) {
      return this.avatars
    }
    return this.avatars.slice(0, this.numberOfVisibleUsers - 1)
  }

  @Emit()
  onRemoveAvatar(item) {
    return item
  }
}
</script>
