import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "avatar-group avatar-group_to-do" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.croppedAvatarsByLength.length)
      ? (_openBlock(), _createBlock(_component_Avatar, {
          key: 0,
          src: null,
          type: "avatarGroup",
          class: "is-blue",
          size: _ctx.size,
          bordered: ""
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.croppedAvatarsByLength, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: _normalizeStyle(`z-index: ${index}`),
        class: "avatar-group__item"
      }, [
        _withDirectives(_createVNode(_component_Avatar, {
          src: item?.pictureUrl,
          type: _ctx.type,
          size: _ctx.size,
          gap: item?.getInitials(),
          index: index,
          showRemoveButton: _ctx.showRemoveButton,
          class: _normalizeClass({
          'is-scale-by-hover': _ctx.scaleByHover,
        }),
          bordered: "",
          onRemoveAvatar: ($event: any) => (_ctx.onRemoveAvatar(item))
        }, null, 8, ["src", "type", "size", "gap", "index", "showRemoveButton", "class", "onRemoveAvatar"]), [
          [
            _directive_tooltip,
            {
          content: item?.username,
          theme: 'info-tooltip-sm',
          disabled: !item?.username || !_ctx.scaleByHover,
        },
            void 0,
            { bottom: true }
          ]
        ])
      ], 4))
    }), 128)),
    (_ctx.lengthOfHiddenAvatars)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle(`z-index: ${_ctx.numberOfVisibleUsers + 1}`),
          class: _normalizeClass(["avatar is-bordered avatar-group__item avatar__length", `is-${_ctx.size}`])
        }, " +" + _toDisplayString(_ctx.lengthOfHiddenAvatars), 7))
      : _createCommentVNode("", true)
  ]))
}