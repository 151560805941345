import { store, Actions } from "@/store"
import { $keycloak, forceUpdateToken } from "@/plugins/keycloak"
export default async (to, _from, next: (...args) => void): Promise<void> => {
  const auth = $keycloak.authenticated
  if (!auth) {
    next()
    return
  }

  const createAccount = to.meta.createAccount || to.params?.accountId === "undefined"
  if (to.hash && to.hash.includes("#state")) {
    const sessionState = to.hash.split("session_state=")[1].split("&")[0]

    await setLogin({ sessionState, createAccount })
    to.hash = ""
    to.href = to.href.split("#state")[0]
    to.fullPath = to.fullPath.split("#state")[0]

  }
  if (onLogin && to.params?.accountId !== "undefined" && !to.query.login) {
    // Set login query param to prevent redirect back to login page
    to.fullPath = `${to.fullPath.split("?")[0]}?${addQueryToPath(to.query)}`
    to.href = `${to.href.split("?")[0]}?${addQueryToPath(to.query)}`

    to.query = { ...to.query, login: "true" }

    onLogin = false
  }
  next()
}

const addQueryToPath = (query: any) => {
  const searchParams = new URLSearchParams(query)

  searchParams.append("login", "true")

  return searchParams.toString()
}

let onLogin = false

const setLogin = async ({ sessionState, createAccount } : { sessionState: string, createAccount: boolean }) => {
  let sessionStateCached: string | null = null

  if (!createAccount) {
    sessionStateCached = localStorage.getItem("sessionState")
  } else {
    sessionStateCached = null
    localStorage.setItem("sessionState", "")
  }

  if (sessionState !== sessionStateCached) {
    localStorage.setItem("sessionState", sessionState)
    await store.dispatch(Actions.LOGIN)
    await forceUpdateToken()
    onLogin = true
  }
}

