<template>
  <NotificationTemplate
    :notification="notification"
    :showSubTitle="false"
    class="notification__update-item notification__update-item-team"
  >
    <template #pic>
      <div class="label-pic is-green">
        <icon
          data="@icon/team-noAvatar.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        <a
          class="link is-large"
          target="_blank"
          :href="notification.itemUrl"
        >
          Item {{ notification.itemSequenceNumber }}
        </a>
        <br>
        <span>{{ actualTeamName ? ' moved to another Team' : 'removed from Team Backlog' }}</span>

        <BaseSubHeading
          v-if="computedProjectName"
          size="medium"
          color="monochrome-05"
          darkColor="monochrome-04"
          weight="500"
          class="notification__project-name"
        >
          {{ computedProjectName }}
        </BaseSubHeading>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
        className="notification__update-item__content"
      >
        <span
          v-if="oldTeamName"
          class="notification__update-item__team is-opacity"
        >
          {{ oldTeamName }}
        </span>
        <span
          v-else
          class="is-opacity"
        >None</span>
        <icon
          data="@icon/arrow-to.svg"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
        <span
          v-if="actualTeamName"
          class="notification__update-item__team"
        >
          {{ actualTeamName }}
        </span>
        <span v-else>None</span>
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

  @Options({
    name: "ITEM_UPDATE_TEAM",
    components: {
      NotificationTemplate,
    },
  })
export default class ITEM_UPDATE_STATUS extends Vue {
  @Prop() readonly notification: NotificationItem

  get oldTeamName() {
    return this.notification.getOldItemValue("teamName")
  }

  get actualTeamName() {
    return this.notification.getItemValue("teamName")
  }

  get computedProjectName() {
    return this.notification.content.projectName
  }
}
</script>
