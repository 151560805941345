import routers from "@/router/routers"
import { trackService } from "@/services/track.service"
import { createRouter, createWebHistory } from "vue-router"

let routeMiddleware = {}
const globalMiddleware: string[] = [
  "onLogin",
  "currentUser",
  "resolveName",
] // The middleware for every page of the application.

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routers,
  scrollBehavior,
})

export const applyMiddleware = async () => {
  routeMiddleware = await resolveMiddleware(
    require.context("../middleware", false, /.*\.ts$/),
  )
  router.beforeResolve(beforeResolve)
  router.afterEach(afterEach)
}

// Load middleware modules dynamically.
const resolveMiddleware = requireContext => requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.ts$)/g, ""), requireContext(file)])
  .reduce(
    (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
    {},
  )

const beforeResolve = async (to, from, next: (...args: any[]) => void): Promise<void> => {
  let components: any[] = []

  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(to.matched.flatMap(item => item.components.default))

    const isErrorPageExist = components.find(item => item.name === "Error")

    if (isErrorPageExist) return next()
  } catch (error) {
    if (error instanceof Error && (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message))) {
      window.location.reload()
      return
    }
  }

  if (components.length === 0) {
    next()
    return
  }

  // Get the middleware for all the matched components.
  const middleware: string[] = getMiddleware(to.matched.map(x => x.meta))

  trackService.createPageLoadEvent(`${window.document.location.origin}${to.path}`)

  // Call each middleware.
  await callMiddleware(middleware, to, from, (...args: string[]) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      // router.app.setLayout(components[0].layout || "")
    }

    next(...args)
  })
}

const afterEach = () => {
  // add something
}

const callMiddleware = async (middlewares: string[], to, from, next: (...args: string[]) => void): Promise<void> => {
  const stack = middlewares.reverse()

  return new Promise((resolve) => {
    const _next = async (...args: string[]): Promise<void> => {
      // Stop if "_next" was called with an argument or the stack is empty.
      if (args.length > 0 || stack.length === 0) {
        next(...args)
        return resolve()
      }

      const middleware: string | undefined = stack.pop()

      if (middleware && routeMiddleware[middleware]) {
        routeMiddleware[middleware](to, from, _next)
      } else {
        throw Error(`Undefined middleware [${middleware}]`)
      }
    }

    return _next()
  })
}

function resolveComponents<T>(components): Promise<T[]> {
  return Promise.all(
    components.map((component: T) => typeof component === "function" ? component() : component),
  )
}

function getMiddleware(components): string[] {
  const middleware: string[] = [...globalMiddleware]

  components
    .filter(c => c.middleware)
    .forEach((component) => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware ? component.middleware : "")
      }
    })

  return middleware
}

// function scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition): Promise<false | void> {
//   if (savedPosition) savedPosition

//   if (to.hash) {
//     return new Promise(resolve => resolve({ selector: to.hash }));
//   }

//   const [component] = router.currentRoute.value.matched.map({ ...to }).slice(-1)

//   if (component) {
//     return new Promise(() => ({}));
//   }

//   return new Promise(resolve => resolve({ x: 0, y: 0 }));
// }

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) return savedPosition
  if (to.hash) {
    return { selector: to.hash }
  }

  return { x: 0, y: 0 }
}
