import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"
import _imports_0 from '@icon/info.svg'


const _hoisted_1 = { class: "reference-list__separate" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_icon = _resolveComponent("icon")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.content,
    componentType: _ctx.componentType,
    class: _normalizeClass([{'reference-list_sm': !_ctx.showTips}])
  }, _createSlots({
    footer: _withCtx(() => [
      (_ctx.showTips)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " "))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "reference-list__link",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onToggleTips(!_ctx.showTips)), ["prevent"]))
      }, [
        _createTextVNode(_toDisplayString(_ctx.tipButtonText) + " Tips ", 1),
        (!_ctx.showTips)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              class: "reference-list__icon",
              data: _imports_0,
              fill: false,
              color: "#7D92A7"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 2
  }, [
    (_ctx.showTips)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_BaseSubHeading, {
              weight: "600",
              size: "medium",
              color: "monochrome-06",
              className: "reference-list__header"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Easy to use Hashtag conventions: ")
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.showTips)
      ? {
          name: "item",
          fn: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_BaseSubHeading, {
                weight: "400",
                size: "medium",
                color: "monochrome-07",
                className: "reference-list__separate-left"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`reference.${option}.key`)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BaseSubHeading, {
                weight: "400",
                size: "medium",
                color: "monochrome-05",
                className: "reference-list__separate-right"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`reference.${option}.desc`)), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["searchList", "componentType", "class"]))
}