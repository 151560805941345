import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "reference-list__header"
}
const _hoisted_2 = {
  key: 1,
  class: "reference-list__in"
}
const _hoisted_3 = { class: "reference-list__list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 2,
  class: "reference-loading"
}
const _hoisted_6 = {
  key: 3,
  class: "reference-loading"
}
const _hoisted_7 = {
  key: 4,
  class: "reference-list__footer"
}
const _hoisted_8 = { class: "reference-list__second-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reference-list", [`reference-list_${_ctx.componentType.toLowerCase()}`]])
  }, [
    (!_ctx.hideHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    (_ctx.showList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass([{ 'is-selected': index === _ctx.selectedIndex }, "reference-list__item"])
              }, [
                _createElementVNode("button", {
                  type: "button",
                  class: "reference-list__button",
                  onClick: _withModifiers(($event: any) => (_ctx.selectItem(item)), ["stop"])
                }, [
                  _renderSlot(_ctx.$slots, "item", { option: item })
                ], 8, _hoisted_4)
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$wait.is('referenceSearch') || _ctx.$wait.is('referenceSprintSearch'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " ...loading "))
      : (_ctx.showNotFound)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, " No results found. "))
        : _createCommentVNode("", true),
    (!_ctx.hideFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "screen")
    ])
  ], 2))
}