<template>
  <ReferenceComponentParentList
    :searchList="result"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    @onClick="onSelectItem"
  >
    <template #header>
      <div :class="['reference-item', `is-${currentItem?.itemTypeParsed.id.toLowerCase()}`]">
        <div class="reference-item__header">
          <BaseSubHeading
            weight="500"
            size="medium"
            color="monochrome-06"
          >
            #{{ currentItem?.sequenceNumber }}
          </BaseSubHeading>

          <span :class="['reference-item__status', `is_${currentItem?.statusParsed?.id.toLowerCase()}`]">{{ currentItem?.statusParsed?.label }}</span>
        </div>
        <BaseCaption
          size="large"
          color="monochrome-05"
          className="reference-item__body"
        >
          {{ currentItem?.title }}
        </BaseCaption>
      </div>
    </template>

    <template #item="{ option }">
      <div :class="['reference-task']">
        <BaseCaption
          size="large"
          color="monochrome-05"
          className="reference-item__order"
        >
          {{ option.order }}
        </BaseCaption>
        <BaseCaption
          size="large"
          color="monochrome-06"
          className="reference-task__body"
        >
          <div v-html="option.name" />
        </BaseCaption>
        <BaseCaption
          size="large"
          color="monochrome-05"
        >
          {{ option.typeParsed.label }}
        </BaseCaption>
      </div>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import { SearchItemResult, Item, Task } from "@/models"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import { stripHtml } from "@/utils/helpers"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"

const components = {
  ReferenceComponentParentList,
}

@Options({
  name: "TaskList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.SEARCH_RESOURCE) searchItemResult: SearchItemResult[]

  @Action(Actions.SEARCH_ITEM_WITH_TASKS) searchTasks: (payload: { sequenceNumber: string }) => Promise<Item>

  componentType = ReferenceType.TASK
  itemSequenceNumber = ""
  query = ""
  currentItem: Item | null = null
  result: Task[] = []
  waitTitle = "reference.search.task"

  queryUpdated(q) {
    [this.itemSequenceNumber, this.query] = q.split(".")
    this.search()
  }

  async search() {
    if (!this.itemSequenceNumber) {
      return
    }
    this.currentItem = await this.searchTasks({
      sequenceNumber: this.itemSequenceNumber,
    })
    if (this.currentItem?.tasks) {
      this.result = this.currentItem.tasks.filter(task => task.order.toString().startsWith(this.query))
    }
  }

  onEnterHandler() {
    this.onSelectItem(this.result[this.selectedIndex])
  }

  onSelectItem(payload) {
    payload.name = stripHtml(payload.name)
    this.selectItem({
      item: payload,
      payload: {
        parent: this.currentItem,
        refType: this.componentType,
      },
    })
  }
}
</script>
