import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reference-component__link" }
const _hoisted_2 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_5 = { class: "reference-component__link" }
const _hoisted_6 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_7 = { class: "reference-component__link" }
const _hoisted_8 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_9 = { class: "reference-component__link" }
const _hoisted_10 = { class: "reference-component__link" }
const _hoisted_11 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_12 = {
  key: 1,
  class: "reference-component__desc"
}
const _hoisted_13 = { class: "reference-component__link" }
const _hoisted_14 = {
  key: 0,
  class: "reference-component__desc"
}
const _hoisted_15 = { key: 8 }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserReferenceTooltip = _resolveComponent("UserReferenceTooltip")!
  const _component_Attachment = _resolveComponent("Attachment")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nodeViewWrapper), {
    class: _normalizeClass([
      'reference-component-wrap',
      {'reference-component-wrap_custom': _ctx.type === _ctx.referenceTypes.FILE }
    ])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(['reference-component', `reference-component_${_ctx.type.toLowerCase()}`])
      }, [
        (!_ctx.isShowLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.type === _ctx.referenceTypes.USER)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                  }, [
                    _createVNode(_component_UserReferenceTooltip, {
                      text: _ctx.text,
                      user: _ctx.info,
                      isShowDetails: _ctx.isShowDetails
                    }, null, 8, ["text", "user", "isShowDetails"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.type === _ctx.referenceTypes.ITEM)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "is-flex",
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                  }, [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
                    (_ctx.info?.itemTypeParsed?.label)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.info.itemTypeParsed.label) + ", " + _toDisplayString(_ctx.info?.statusParsed?.label) + ") ", 1))
                      : _createCommentVNode("", true)
                  ]))
                : (_ctx.type === _ctx.referenceTypes.TASK)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 2,
                      class: "is-flex",
                      type: "button",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                    }, [
                      _createElementVNode("span", {
                        class: "reference-component__link",
                        innerHTML: _ctx.text
                      }, null, 8, _hoisted_3),
                      (_ctx.info?.typeParsed?.label)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(_ctx.info.typeParsed.label) + ", " + _toDisplayString(_ctx.info?.estimatedHours) + "hr)", 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.type === _ctx.referenceTypes.MILESTONE)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 3,
                        class: "is-flex",
                        type: "button",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                      }, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.text), 1),
                        (_ctx.info?.date)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.info.date), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : (_ctx.type === _ctx.referenceTypes.EPIC)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 4,
                          class: "is-flex",
                          type: "button",
                          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.text), 1),
                          (_ctx.info?.percentComplete)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.info.percentComplete) + "% Done ", 1))
                            : _createCommentVNode("", true)
                        ]))
                      : (_ctx.type === _ctx.referenceTypes.PROJECT)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 5,
                            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                          }, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.text), 1)
                          ]))
                        : (_ctx.type === _ctx.referenceTypes.SPRINT)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 6,
                              class: "is-flex",
                              type: "button",
                              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                            }, [
                              _createElementVNode("span", {
                                class: _normalizeClass(["dot dot_sm reference-component__meta", [`is-${_ctx.info.status.toLowerCase()}`]])
                              }, null, 2),
                              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.text), 1),
                              (_ctx.info?.startDate)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.info.startDate) + " - " + _toDisplayString(_ctx.info.endDate), 1))
                                : _createCommentVNode("", true),
                              (_ctx.info?.team?.name)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " (Team " + _toDisplayString(_ctx.info.team.name) + ") ", 1))
                                : _createCommentVNode("", true)
                            ]))
                          : (_ctx.type === _ctx.referenceTypes.TEAM)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 7,
                                class: "is-flex",
                                type: "button",
                                onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.type)), ["prevent"]))
                              }, [
                                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.text), 1),
                                (_ctx.info?.size)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, " (" + _toDisplayString(_ctx.info.size) + ") ", 1))
                                  : _createCommentVNode("", true)
                              ]))
                            : (_ctx.type === _ctx.referenceTypes.FILE)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  _createVNode(_component_Attachment, {
                                    node: _ctx.node,
                                    editor: _ctx.editor,
                                    updateAttributes: _ctx.updateAttributes,
                                    getPos: _ctx.getPos
                                  }, null, 8, ["node", "editor", "updateAttributes", "getPos"])
                                ]))
                              : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("span", _hoisted_16, "...Loading..."))
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}