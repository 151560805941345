<template>
  <div
    class="toast"
  >
    <div class="toast__content">
      <div
        class="toast__card"
        :class="[
          `toast__card-${alert.type.toLowerCase()}`,
          alert.timeout === 0 && 'toast__card--hidden',
          alert.timeout === -1 && 'toast__card--hidden alert__message--removed',
        ]"
        @mouseenter="cancelAlertTimeout"
        @mouseleave="restartAlertTimeout"
      >
        <AlertText
          v-if="alert.content?.length"
          :content="alert.content"
        />

        <p
          v-else
          class="toast__message body-text body-text-medium-400"
        >
          {{ alert.name }}
          <span class="body-text-medium-600">
            {{ alert.title }}
          </span>
          {{ alert.message }}
        </p>

        <div class="toast__buttons">
          <button
            class="toast__close"
            @click="$emit('closeAlert')"
          >
            <icon
              data="@icon/close.svg"
              :fill="false"
              width="1em"
              height="1em"
              color="#000000"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Alert } from "@/models"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

import AlertText from "./AlertText.vue"

@Options({
  name: "ToastAlert",
  components: {
    AlertText,
  },
})
export default class extends Vue {
  @Prop() alert!: Alert
  onMouseEnter() {
    this.alert.restartTimeout()
  }
  cancelAlertTimeout() {
    this.alert.cancelTimeout()
  }
  restartAlertTimeout() {
    this.alert.restartTimeout()
  }
}
</script>
