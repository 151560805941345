<template>
  <Multiselect
    :id="id"
    :ref="`multiselect-${id}`"
    v-model="value"
    class="dropdown-single dropdown-single_icon"
    :class="[`is-position-right`]"
    :options="options"
    :showLabels="false"
    :trackBy="trackBy"
    :label="label"
    placeholder=""
    :searchable="false"
    :allowEmpty="false"
    :closeOnSelect="closeOnSelect"
    @open="onOpen"
    @update:modelValue="onInput"
  >
    <template #placeholder>
      <div class="dropdown-single__label">
        <slot name="label">
          <icon
            data="@icon/more-vertical.svg"
            :width="size.width"
            :height="size.height"
            :fill="false"
            color="#7D92A7"
          />
        </slot>
      </div>
    </template>
    <template
      #option="props"
    >
      {{ props.option.label }}
      <icon
        data="@icon/trash.svg"
        :fill="false"
        color="#7D92A7"
        width="16"
        height="16"
      />
    </template>
  </Multiselect>
</template>


<script lang="ts">
import { Mixins, Prop } from "vue-property-decorator"
import Multiselect from "vue-multiselect"
import { AppendToBody } from "@/mixins"
import { Options } from "vue-class-component"

type IconSize = "sm" | "md"

@Options({
  name: "DropdownSingleLabel",
  components: {
    Multiselect,
  },
})
export default class extends Mixins(AppendToBody) {
  @Prop({ default: [] }) options
  @Prop() declare id: string
  @Prop() label: string
  @Prop() iconsrc: string
  @Prop({ default: "sm" }) iconSize: IconSize

  value = ""
  repositionDropDown: () => void

  get size() {
    let width: number
    let height: number

    switch (this.iconSize) {
      case "sm":
        width = height = 16
        break
      case "md":
        width = height = 24
        break
      default:
        width = height = 16
    }

    return {
      width,
      height,
    }
  }

  onInput(value) {
    this.$emit("input", {
      value,
      id: this.id,
    })

    this.value = ""
  }

  onOpen() {
    this.repositionDropDown()
  }
}
</script>
