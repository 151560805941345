import { Options, Vue } from "vue-class-component"
import { InjectReactive, Watch, Prop } from "vue-property-decorator"

@Options({
  name: "FloatButton",
})

export class FloatButton extends Vue {
  @Prop() readonly index: number
  @InjectReactive() readonly scrollPosition: number

  expandButtonPosition = {
    top: 0,
    left: 0,
  }

  declare show

  get containerElement() {
    return this.$refs[`float-container-${this.index}`] as HTMLElement | null
  }

  get expandButtonElement() {
    const button = this.$refs[`float-container-button-${this.index}`] as any
    return button ? button.$el : null
  }

  get elementsExist() {
    return this.expandButtonElement && this.containerElement
  }

  @Watch("scrollPosition")
  onTriggerScroll() {
    this.handleScroll()
  }

  mounted() {
    window.addEventListener("resize", this.onResize)
    this.setDefaultPositionForExpandButton()
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize() {
    this.setDefaultPositionForExpandButton()
  }

  setDefaultPositionForExpandButton() {
    if (this.show || !this.elementsExist) return

    const expandButton = this.expandButtonElement
    const container = this.containerElement

    const rect = container!.getBoundingClientRect()

    this.expandButtonPosition = {
      top: rect.top + rect.height / 2 - expandButton!.clientHeight / 2,
      left: rect.right,
    }
  }

  handleScroll() {
    if (this.show) return

    const expandButton = this.expandButtonElement.$el

    if (!expandButton || !this.containerElement) return

    const rect = this.containerElement.getBoundingClientRect()

    const topPosition = this.expandButtonPosition.top - this.scrollPosition

    this.expandButtonPosition = {
      top: topPosition >= 0 ? topPosition : 0,
      left: rect.right,
    }
  }

  handleMouseMove(event) {
    if (this.show && this.elementsExist) {
      const expandButton = this.expandButtonElement
      const container = this.containerElement

      const expandButtonHeight = expandButton!.clientHeight
      const { top, bottom } = container!.getBoundingClientRect()
      const mouseTop = event.pageY + window.scrollY
      const buttonPosition = mouseTop - expandButtonHeight / 2

      if (top >= buttonPosition) {
        this.expandButtonPosition.top = top
        return
      }

      if (bottom - expandButtonHeight <= mouseTop) {
        this.expandButtonPosition.top = bottom - expandButtonHeight
        return
      }

      this.expandButtonPosition.top = buttonPosition
    } else {
      this.setDefaultPositionForExpandButton()
    }
  }
}
