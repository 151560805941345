export enum NotificationType {
  WELCOME = "WELCOME",
  ACCOUNT_INVITATION = "ACCOUNT_INVITATION",
  PROJECT_INVITATION = "PROJECT_INVITATION",
  PROJECT_ADDITION = "PROJECT_ADDITION",
  TEAM_INVITATION = "TEAM_INVITATION",
  TEAM_ADDITION = "TEAM_ADDITION",
  PASSWORD_RESET = "PASSWORD_RESET",
  USER_PASSWORD_CHANGED = "USER_PASSWORD_CHANGED",
  STORY_ASSIGNMENT = "STORY_ASSIGNMENT",
  TODO_ASSIGNMENT = "TODO_ASSIGNMENT",
  TODO_REMINDER = "TODO_REMINDER",
  UPDATE_STORY_TIME_LEFT_REMINDER = "UPDATE_STORY_TIME_LEFT_REMINDER",
  COMMENTS = "COMMENTS",
  UNPRIORITIZED_BACKLOG_ADD_ITEM = "UNPRIORITIZED_BACKLOG_ADD_ITEM",
  BLOCKED_STORY_NOTIFICATION = "BLOCKED_STORY_NOTIFICATION",
  OBSOLETE_STORY_NOTIFICATION = "OBSOLETE_STORY_NOTIFICATION",
  STORY_DESCRIPTION_UPDATE = "STORY_DESCRIPTION_UPDATE",
  ITEM_SUB_TASK_ADDED_UPDATE = "ITEM_SUB_TASK_ADDED_UPDATE",
  ITEM_SUB_TASK_TIME_LEFT_UPDATED_TO_ZERO = "ITEM_SUB_TASK_TIME_LEFT_UPDATED_TO_ZERO",
  SPRINT_REVIEW_COMPLETED = "SPRINT_REVIEW_COMPLETED",
  SUPPORT_CASE_CREATED = "SUPPORT_CASE_CREATED",
  BUG_WATERMARK_EXCEEDED = "BUG_WATERMARK_EXCEEDED",
  SPRINT_RISK_ALERT = "SPRINT_RISK_ALERT",
  HALT_SPRINT_NOTIFICATION = "HALT_SPRINT_NOTIFICATION",
  ITEM_CREATED = "ITEM_CREATED",
  ITEM_UPDATED = "ITEM_UPDATED",
  ITEM_UPDATE_STATUS = "ITEM_UPDATE_STATUS",
  ITEM_UPDATE_STORYPOINTS = "ITEM_UPDATE_STORYPOINTS",
  ITEM_UPDATE_DESCRIPTION = "ITEM_UPDATE_DESCRIPTION",
  ITEM_UPDATE_OWNER = "ITEM_UPDATE_OWNER",
  ITEM_UPDATE_ACTIVE_SPRINT_ADDED = "ITEM_UPDATE_ACTIVE_SPRINT_ADDED",
  UPDATE_TASK = "UPDATE_TASK",
  USER_MENTION = "USER_MENTION",
  SPRINT_RETROSPECTIVE = "SPRINT_RETROSPECTIVE",
  STORY_POKER_CREATED = "STORY_POKER_CREATED",
  MILESTONE_COMPLETED = "MILESTONE_COMPLETED",
  MILESTONE_AT_RISK = "MILESTONE_AT_RISK",
  GOAL_REACHED = "GOAL_REACHED",
  JIRA_BACKLOG_IMPORT_COMPLETED = "JIRA_BACKLOG_IMPORT_COMPLETED"
}

export enum NotificationClass {
  NOTIFICATION = "NOTIFICATION",
  NOTIFICATION_TODO = "NOTIFICATION_TODO",
  NOTIFICATION_ITEM = "NOTIFICATION_ITEM",
  NOTIFICATION_TASK = "NOTIFICATION_TASK",
  NOTIFICATION_COMMENTS = "NOTIFICATION_COMMENTS",
  NOTIFICATION_BUG_WATERMARK = "NOTIFICATION_BUG_WATERMARK",
  NOTIFICATION_STORY_POKER = "NOTIFICATION_STORY_POKER",
  NOTIFICATION_SPRINT_RETROSPECTIVE = "NOTIFICATION_SPRINT_RETROSPECTIVE",
  NOTIFICATION_MILESTONE = "NOTIFICATION_MILESTONE",
  NOTIFICATION_GOAL = "NOTIFICATION_GOAL",
  NOTIFICATION_PROJECT_ADDITION = "NOTIFICATION_PROJECT_ADDITION",
  NOTIFICATION_TEAM_ADDITION = "NOTIFICATION_TEAM_ADDITION",
  NOTIFICATION_ACTIVE_SPRINT_ADDED = "NOTIFICATION_ACTIVE_SPRINT_ADDED",
}

export const classByType: Record<NotificationType, NotificationClass> = {
  [NotificationType.WELCOME]: NotificationClass.NOTIFICATION,
  [NotificationType.ACCOUNT_INVITATION]: NotificationClass.NOTIFICATION,
  [NotificationType.PROJECT_INVITATION]: NotificationClass.NOTIFICATION,
  [NotificationType.PROJECT_ADDITION]: NotificationClass.NOTIFICATION_PROJECT_ADDITION,
  [NotificationType.TEAM_INVITATION]: NotificationClass.NOTIFICATION,
  [NotificationType.TEAM_ADDITION]: NotificationClass.NOTIFICATION_TEAM_ADDITION,
  [NotificationType.PASSWORD_RESET]: NotificationClass.NOTIFICATION,
  [NotificationType.USER_PASSWORD_CHANGED]: NotificationClass.NOTIFICATION,
  [NotificationType.STORY_ASSIGNMENT]: NotificationClass.NOTIFICATION,
  [NotificationType.TODO_ASSIGNMENT]: NotificationClass.NOTIFICATION_TODO,
  [NotificationType.TODO_REMINDER]: NotificationClass.NOTIFICATION_TODO,
  [NotificationType.UPDATE_STORY_TIME_LEFT_REMINDER]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.COMMENTS]: NotificationClass.NOTIFICATION_COMMENTS,
  [NotificationType.UNPRIORITIZED_BACKLOG_ADD_ITEM]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.BLOCKED_STORY_NOTIFICATION]: NotificationClass.NOTIFICATION,
  [NotificationType.OBSOLETE_STORY_NOTIFICATION]: NotificationClass.NOTIFICATION,
  [NotificationType.STORY_DESCRIPTION_UPDATE]: NotificationClass.NOTIFICATION,
  [NotificationType.ITEM_SUB_TASK_ADDED_UPDATE]: NotificationClass.NOTIFICATION,
  [NotificationType.ITEM_SUB_TASK_TIME_LEFT_UPDATED_TO_ZERO]: NotificationClass.NOTIFICATION,
  [NotificationType.SPRINT_REVIEW_COMPLETED]: NotificationClass.NOTIFICATION,
  [NotificationType.SUPPORT_CASE_CREATED]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.BUG_WATERMARK_EXCEEDED]: NotificationClass.NOTIFICATION_BUG_WATERMARK,
  [NotificationType.SPRINT_RISK_ALERT]: NotificationClass.NOTIFICATION,
  [NotificationType.HALT_SPRINT_NOTIFICATION]: NotificationClass.NOTIFICATION,
  [NotificationType.ITEM_CREATED]: NotificationClass.NOTIFICATION,
  [NotificationType.ITEM_UPDATED]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.ITEM_UPDATE_STATUS]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.ITEM_UPDATE_STORYPOINTS]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.ITEM_UPDATE_DESCRIPTION]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.ITEM_UPDATE_OWNER]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.ITEM_UPDATE_ACTIVE_SPRINT_ADDED]: NotificationClass.NOTIFICATION_ITEM,
  [NotificationType.UPDATE_TASK]: NotificationClass.NOTIFICATION_TASK,
  [NotificationType.USER_MENTION]: NotificationClass.NOTIFICATION_COMMENTS,
  [NotificationType.SPRINT_RETROSPECTIVE]: NotificationClass.NOTIFICATION_SPRINT_RETROSPECTIVE,
  [NotificationType.STORY_POKER_CREATED]: NotificationClass.NOTIFICATION_STORY_POKER,
  [NotificationType.MILESTONE_AT_RISK]: NotificationClass.NOTIFICATION_MILESTONE,
  [NotificationType.MILESTONE_COMPLETED]: NotificationClass.NOTIFICATION_MILESTONE,
  [NotificationType.GOAL_REACHED]: NotificationClass.NOTIFICATION_GOAL,
  [NotificationType.JIRA_BACKLOG_IMPORT_COMPLETED]: NotificationClass.NOTIFICATION,
}
