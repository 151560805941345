<template>
  <Field
    v-slot="{ errors, resetField }"
    :as="validationTag"
    :modelValue="selectedTeam"
    name="Team"
    :rules="rules"
    :validateOnBlur="validateOnBlur"
  >
    <DropdownBasic
      id="team"
      :value="selectedTeam"
      :options="dropdownTeams"
      :trackBy="'name'"
      :label="'name'"
      :searchable="false"
      placeholder=""
      :optionPosition="optionPosition"
      :closeOnSelect="true"
      :appendDropDownToBody="appendDropDownToBody"
      :scrollableContainer="scrollableContainer"
      :hideSelected="true"
      :noOptions="loading ? loadingResult : 'No teams found'"
      :class="[className]"
      parent="button"
      class="dropdown__select-team"
      @onOpen="$emit('onOpen')"
      @onClose="$emit('onClose')"
      @onSelect="onSelectTeam($event.value)"
      @checkOpenDropdown="$emit('checkOpenDropdown')"
    >
      <template #placeholder>
        <div
          :class="{'select-team__title-wrapper_alert':errors[0]}"
        >
          <slot name="placeholder">
            <BaseSubHeading
              weight="500"
              size="medium"
              className="select-team__title"
            >
              Select Team
            </BaseSubHeading>
          </slot>
        </div>
      </template>

      <template #singleLabel="{ option }">
        <slot
          name="singleLabel"
          :option="option"
        >
          <BorderedTeam
            :src="option.picture?.pictureUrl"
            :isFull="isFull"
            :teamName="option.name"
          />
        </slot>
      </template>
      <template #option="{ option }">
          <Avatar
            :src="option.picture?.pictureUrl || null"
            :size="optionLabelSize"
          :class="{'avatar-select__gap': option.id === 'unassignTeam'}"
          class="multiselect__option__avatar"
            type="teamsGroup"
          />
        <span class="multiselect__option__name">{{ option.name }}</span>
      </template>
    </DropdownBasic>
  </Field>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"
import { Emit, Prop, Ref } from "vue-property-decorator"
import validators from "@/utils/validators"
import { DropdownBasic } from "@/components/index"
import Avatar from "@/components/Avatar/index.vue"
import BorderedTeam from "@/components/BorderedTeam/BorderedTeam.vue"
import RequireAlert from "@/components/Alert/RequireAlert.vue"
import { Team } from "@/models"
import { Field } from "vee-validate"
@Options({
  name: "TeamSelectionDropdown",
  components: {
    DropdownBasic,
    Avatar,
    BorderedTeam,
    Field,
    RequireAlert,
  },
})
export default class TeamSelectionDropdown extends Vue {
  @Prop({ required: true }) selectedTeam: Team | null
  @Prop({ default: "sm" }) optionLabelSize: string
  @Prop({ default: "" }) className: string
  @Prop({ default: false }) appendDropDownToBody: boolean
  @Prop({ default: "" }) readonly rules: () => boolean | string
  @Prop({ default: "" }) validationTag: string
  @Prop({ default: true }) validateOnBlur: boolean
  @Prop({ default: false }) readonly loading: boolean
  @Prop({ default: false }) readonly showUnassignTeam: boolean
  @Prop({ default: true }) readonly isFull: boolean
  @Prop() scrollableContainer: string
  @Prop() readonly optionPosition: string
  @Prop({ default: "Loading..." }) readonly loadingResult
  @Getter(Getters.GET_TEAMS) teams: Team[]

  @Ref("filedValidator") filedValidator: any
  validators = validators

  unassignTeam = Team.createDefault()

  created() {
    this.unassignTeam.name = "Unassign Team"
    this.unassignTeam.id = "unassignTeam"
  }

  get dropdownTeams(): Team[] {
    const res = [...this.teams]

    if (this.showUnassignTeam && this.selectedTeam?.id) {
      res.unshift(this.unassignTeam)
    }

    return res
  }

  @Emit()
  onSelectTeam(team) {
    return (team.id === "unassignTeam") ? null : team
  }
}
</script>
