import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { Subscription } from "@/models/Subscription"
import { BaseModule } from "@/models/BaseModule"
import {
  ServerSendEventMessage,
  ResourceType,
  resourceTypes,
  ResourceEvent,
  resourceEvents,
  ServerSendEventNotification,
} from "@/models/common"

export enum SubscriptionActions {
  GET_SUBSCRIPTION = "GET_SUBSCRIPTION",
  SET_SUBSCRIPTION = "SET_SUBSCRIPTION",
  GET_SUBSCRIPTION_TOKEN = "GET_SUBSCRIPTION_TOKEN",
  SUBSCRIPTION_MESSAGE = "SUBSCRIPTION_MESSAGE",
  SUBSCRIPTION_MESSAGE_NOTIFICATION = "SUBSCRIPTION_MESSAGE_NOTIFICATION",
}

@Module
class SubscriptionModule extends BaseModule {
  subscription: Subscription
  token = ""
  messages: Partial<{[key in ResourceType]: ServerSendEventMessage[]}> = {}
  notifications: Partial<{[key in ResourceEvent]: ServerSendEventNotification}> = {}

  get [`get/${SubscriptionActions.GET_SUBSCRIPTION}`]() {
    return this.subscription
  }

  get [`get/${SubscriptionActions.GET_SUBSCRIPTION_TOKEN}`]() {
    return this.token
  }

  get [`get/${SubscriptionActions.SUBSCRIPTION_MESSAGE}`]() {
    return (type: ResourceType) => {
      if (!type) return false

      return this.messages[type]
    }
  }

  get [`get/${SubscriptionActions.SUBSCRIPTION_MESSAGE_NOTIFICATION}`]() {
    return (type: ResourceEvent) => {
      if (!type) return false

      return this.notifications[type]
    }
  }

  @Mutation
  setSubscription(subscription: Subscription) {
    this.subscription = subscription
  }

  @Mutation
  setToken(token: string) {
    this.token = token
  }

  @Mutation
  setResourceTypeMessage(message: ServerSendEventMessage) {
    const { type } = message

    this.messages[type] = [message]
  }

  @Mutation
  setResourceTypeNotification(message: ServerSendEventNotification) {
    const { type } = message

    this.notifications[type] = message
  }

  @Action
  async [SubscriptionActions.GET_SUBSCRIPTION](params: Subscription) {
    const { resourceType, resourceId } = params
    const data = await apiService.get<Subscription>(Endpoint.GET_SUBSCRIPTION(resourceType, resourceId))
    // Do not show error if subscription does not exist
    return this.handleResponse<Subscription>(data, (data) => {
      this.setSubscription(data)
      return data
    },
    data => data.response.status === 404,
    () => null,
    )
  }

  @Action
  async [SubscriptionActions.SET_SUBSCRIPTION](params: Subscription) {
    const data = await apiService.post<Subscription>(Endpoint.SET_SUBSCRIPTION(), params)
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.handleResponse<Subscription>(data, () => {})
  }

  @Action
  async [SubscriptionActions.GET_SUBSCRIPTION_TOKEN]() {
    const data = await apiService.post<string>(Endpoint.GET_SUBSCRIPTION_TOKEN())
    return this.handleResponse<string>(data, (data) => {
      this.setToken(data)
      return data
    })
  }

  @Action
  async [SubscriptionActions.SUBSCRIPTION_MESSAGE](data) {
    if (data.type && resourceTypes.includes(data.type)) {
      this.setResourceTypeMessage(data)
    }

    if (data.type && resourceEvents.includes(data.type)) {
      this.setResourceTypeNotification(data)
    }
  }
}

export const subscriptionModule = new SubscriptionModule({
  store,
  name: Modules.SUBSCRIPTION,
})
