import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/backlog-icon.svg'
import _imports_1 from '@icon/arrow-to.svg'


const _hoisted_1 = { class: "label-pic is-teal" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "notification__update-item__initiator" }
const _hoisted_4 = { class: "notification__update-item__severityLevel is-opacity" }
const _hoisted_5 = { class: "notification__update-item__severityLevel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_SeverityLevelIcon = _resolveComponent("SeverityLevelIcon")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__update-item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "link is-large",
            target: "_blank",
            href: _ctx.notification.itemUrl
          }, " Story " + _toDisplayString(_ctx.notification.itemSequenceNumber), 9, _hoisted_2),
          _createTextVNode(" Severity has been updated by "),
          _createElementVNode("span", _hoisted_3, "@" + _toDisplayString(_ctx.notification.initiatorFullName), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500",
        className: "notification__update-item__content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_SeverityLevelIcon, {
              level: _ctx.notification.getOldItemValue('severityLevel')
            }, null, 8, ["level"]),
            _createTextVNode(" " + _toDisplayString(_ctx.notification.getOldItemValue('severityLevel').toLowerCase()), 1)
          ]),
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: true,
            width: "24px",
            height: "24px",
            color: "#616161"
          }),
          _createElementVNode("span", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.notification.getItemValue('severityLevel').toLowerCase()) + " ", 1),
            _createVNode(_component_SeverityLevelIcon, {
              level: _ctx.notification.getItemValue('severityLevel')
            }, null, 8, ["level"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["notification"]))
}