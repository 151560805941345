import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@icon/chat-gpt.svg'
import _imports_1 from '@icon/paperplane.svg'


const _hoisted_1 = {
  ref: "chatGptItems",
  class: "modal-chat-gpt__items-wrapper custom-scroll"
}
const _hoisted_2 = { class: "modal-chat-gpt__avatar" }
const _hoisted_3 = { class: "modal-chat-gpt__spinner-wrapper" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "bordered-textarea" }
const _hoisted_6 = ["maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_icon = _resolveComponent("icon")!
  const _component_ChatGPTResponseContent = _resolveComponent("ChatGPTResponseContent")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_ControlButtons = _resolveComponent("ControlButtons")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createBlock(_component_CustomModal, {
    id: _ctx.id,
    modalClass: "modal-chat-gpt",
    width: "80%",
    onOnClosed: _ctx.onClosed
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseHeading, { level: "3" }, {
        default: _withCtx(() => [
          _createTextVNode(" Ask ChatGPT ")
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatItems, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: _normalizeClass(["modal-chat-gpt__item", [{ 'modal-chat-gpt__item--mine': item.mine }]])
          }, [
            _createElementVNode("div", _hoisted_2, [
              (item.mine)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    src: _ctx.user?.pictureUrl ?? '',
                    gap: _ctx.user?.getInitials() ?? ''
                  }, null, 8, ["src", "gap"]))
                : (_openBlock(), _createBlock(_component_icon, {
                    key: 1,
                    data: _imports_0,
                    fill: true,
                    color: "white",
                    width: "22px",
                    height: "22px"
                  }))
            ]),
            _createVNode(_component_ChatGPTResponseContent, {
              content: item.content
            }, null, 8, ["content"])
          ], 2))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$wait.is('chatgpt.get'))
          ? (_openBlock(), _createBlock(_component_Spinner, {
              key: 0,
              loading: true,
              size: 32
            }))
          : (_ctx.chatItems.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetChatContext && _ctx.resetChatContext(...args)))
                }, " Clear History and context ")
              ]))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("textarea", {
          id: "promptTextarea",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.prompt) = $event)),
          rows: "2",
          placeholder: "Send a message",
          class: "modal-chat-gpt__text-area custom-scroll",
          maxlength: _ctx.tokensLeft,
          onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onPromptInput && _ctx.onPromptInput(...args)), ["prevent"]), ["enter"]))
        }, null, 40, _hoisted_6), [
          [_vModelText, _ctx.prompt]
        ]),
        _createVNode(_component_icon, {
          data: _imports_1,
          fill: true,
          color: "#336DFF",
          width: "24",
          height: "24",
          class: _normalizeClass(["modal-chat-gpt__send-icon", [{ 'is-disabled': !_ctx.prompt }]]),
          onClick: _ctx.onPromptInput
        }, null, 8, ["class", "onClick"]),
        _createVNode(_component_BaseSubHeading, {
          size: "extra-small",
          weight: "400",
          color: "monochrome-05",
          class: "custom-modal_halted-sprint_input_lenght"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.prompt.length) + "/" + _toDisplayString(_ctx.tokensLeft), 1)
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_ControlButtons, {
        hideSave: true,
        onCancel: _ctx.hide
      }, {
        cancel: _withCtx(() => [
          _createTextVNode(" Hide ")
        ]),
        _: 1
      }, 8, ["onCancel"])
    ]),
    _: 1
  }, 8, ["id", "onOnClosed"]))
}