import { ItemPreviewDto } from "./Item"
import { RetroStackItemsList } from "./RetroStackItemsList"

export interface ActionItemsDTO {
  retrospectiveNoteId?: string;
  improvementPlan: string;
  priority?: number;
  itemPreviewDto?: ItemPreviewDto;
  selectedNote?: RetroStackItemsList[]
}

export interface ActionItemsProps {
  retrospectiveNoteId?: string;
  itemId?: string;
  itemPreviewDto?: ItemPreviewDto;
  improvementPlan: string;
  priority?: number;
  selectedNote?: RetroStackItemsList[]
}

export class ActionItems {
  retrospectiveNoteId?: string
  itemId?: string
  itemPreviewDto?: ItemPreviewDto
  improvementPlan: string
  priority: number
  selectedNote: RetroStackItemsList[]

  constructor(props?: ActionItemsProps) {
    this.setDefaultData()
    if (!props) return

    const {
      retrospectiveNoteId,
      improvementPlan,
      priority,
      selectedNote,
      itemId,
      itemPreviewDto,
    } = props

    this.retrospectiveNoteId = retrospectiveNoteId
    this.improvementPlan = improvementPlan
    this.selectedNote = selectedNote ?? []
    this.priority = priority ?? 0
    this.itemId = itemId ?? ""
    this.itemPreviewDto = itemPreviewDto
  }

  static create(retroNoteDTO?: ActionItemsDTO): ActionItems {
    if (retroNoteDTO) {
      const {
        retrospectiveNoteId,
        improvementPlan,
        priority,
        selectedNote,
        itemPreviewDto,
      } = retroNoteDTO

      const retroNote = new ActionItems({
        retrospectiveNoteId: retrospectiveNoteId,
        improvementPlan: improvementPlan,
        priority: priority,
        selectedNote: selectedNote,
        itemPreviewDto: itemPreviewDto,
      })
      return retroNote
    }
    return new ActionItems()
  }

  setDefaultData() {
    this.retrospectiveNoteId = ""
    this.improvementPlan = ""
    this.selectedNote = []
    this.priority = 0
    this.itemId = ""
    this.itemPreviewDto = undefined
  }

  getJsonObj() {
    return {
      retrospectiveNoteId: this.selectedNote?.[0].id ?? "",
      improvementPlan: this.improvementPlan,
      priority: this.priority,
      itemId: this.itemId,
    }
  }
}
