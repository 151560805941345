import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "content__full-screen"
}
const _hoisted_3 = { class: "content__block content__left" }
const _hoisted_4 = { class: "content__top content__space-between" }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["content", ['is-'+_ctx.size]])
  }, [
    (_ctx.size === 'fullsize')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "top")
            ]),
            _renderSlot(_ctx.$slots, "before-left"),
            _renderSlot(_ctx.$slots, "left")
          ]),
          _createElementVNode("div", {
            id: _ctx.idRight,
            class: _normalizeClass(["content__right", [{'content__block': !_ctx.withTabs}]])
          }, [
            _renderSlot(_ctx.$slots, "before-right"),
            _renderSlot(_ctx.$slots, "right")
          ], 10, _hoisted_5)
        ], 64))
  ], 10, _hoisted_1))
}