<template>
  <Field
    v-slot="{ errors }"
    ref="basicInput"
    :name="id"
    :rules="rules"
    :modelValue="content"
  >
    <div
      class="basic-input"
      :class="[computedClass, {'basic-input_results' : showSearchResult, 'is-error': errors[0]}]"
    >
      <icon
        v-if="searchIcon"
        data="@icon/loupe.svg"
        :fill="false"
        :color="isDark ? 'var(--monochrome-03)' : 'var(--monochrome-05)'"
        width="1.5em"
        height="1.5em"
        class="basic-input__search-icon"
      />
      <input
        :id="id"
        :ref="elRef"
        type="text"
        :placeholder="placeholder"
        :value="modelValue"
        @input="handleInput($event.target.value)"
        @keyup.enter="$emit('enter')"
        @change="$emit('onChange', $event)"
      >
      <slot
        v-if="modelValue.length && showSearchResult"
        name="searchResult"
      />
      <button
        class="basic-input__remove"
        :class="{'is-show': showRemoveButtonAlways || modelValue.length && showRemoveButton}"
        @click.prevent="$emit('remove')"
      >
        <icon
          data="@icon/close2.svg"
          :fill="true"
          width="1em"
          height="1em"
        />
      </button>
      <span
        v-if="errors[0]"
        class="error-text bordered-input__error"
      >
        {{ errors[0] }}
      </span>
    </div>
  </Field>
</template>

<script lang="ts">
import { Getters } from "@/store"
import { Getter } from "s-vuex-class"
import { Options, Vue } from "vue-class-component"
import { Prop, Emit } from "vue-property-decorator"
import { Field } from "vee-validate"
@Options({
  name: "BasicInput",
  components: {
    Field,
  },
})
export default class BasicInput extends Vue {
  @Prop({ default: "" }) readonly modelValue: string
  @Prop({ default: "", required: true }) readonly id: string
  @Prop({ default: "Search" }) readonly placeholder: string
  @Prop({ default: false }) readonly showRemoveButton: boolean
  @Prop({ default: false }) readonly showRemoveButtonAlways: boolean
  @Prop({ default: false }) readonly showSearchResult: boolean
  @Prop({ default: false }) readonly searchIcon: boolean
  @Prop({ default: "" }) readonly elRef: string
  @Prop({ default: "" }) readonly rules: () => boolean | string
  @Prop({ default: "" }) readonly class: string

  @Getter(Getters.THEME_IS_DARK) readonly isDark: boolean

  @Emit("update:modelValue")
  handleInput(value) {
    value
  }

  get content() {
    return this.modelValue
  }

  get computedClass() {
    return this.class
  }
}
</script>
