import { Filter } from "@/models/Pageable"

export class FilterInvitation extends Filter {
  accountId: string
  teamId: string
  text: string
  statuses: string[]

  constructor(props) {
    super(props)
    this.setDefaultData()

    if (!props) {
      return
    }

    this.accountId = props.accountId ?? ""
    this.teamId = props.teamId ?? ""
    this.text = props.text ?? ""
    this.statuses = props.statuses ?? []
  }

  setDefaultData() {
    this.accountId = ""
    this.teamId = ""
    this.text = ""
    this.statuses = []
  }

  getJsonObj() {
    const data = {
      teamId: this.teamId,
      q: this.text,
      statuses: this.statuses,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterInvitation(props)
  }
}
