<script lang="ts">
import { h } from "vue"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Getter } from "s-vuex-class"
import { Getters } from "@/store"

type ButtonSize = "large" | "medium"

@Options({
  name: "BaseButton",
})
export default class extends Vue {
  @Prop({ default: "large" }) readonly level: ButtonSize
  @Prop() readonly className: string = ""
  @Prop({ default: true }) readonly padding: boolean
  @Prop({ default: "accent-03" }) readonly color: string = ""
  @Prop({ default: "accent-200" }) readonly darkColor: string = ""

  @Getter(Getters.THEME_IS_DARK) isDark: boolean

  get styleObject() {
    if (!this.color) return {}

    let color = this.color

    if (this.isDark && this.darkColor) {
      color = this.darkColor
    }

    return {
      color: `var(--${color})`,
    }
  }

  render() {
    return h("button",
      {
        class: `button-level button-level-${this.level} ${this.className ?? ""} ${!this.padding ? "button-level_padding" : ""}`,
        style: this.styleObject,
        type: "button",
      },
      this.$slots.default ? this.$slots.default() : "")
  }
}
</script>
