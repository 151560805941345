import { $t } from "@/plugins/i18n"

export const healthCheckTypeList = [
  "SPRINT_HAPPINESS",
  "WORK_LIFE_BALANCE",
  "IS_WORK_SATISFYING",
  "ATTITUDE",
]

export const healthCheckStatusList = [
  "HEALTHY",
  "NEUTRAL",
  "UNHEALTHY",
]

export type HEALTH_CHECK_TYPE = typeof healthCheckTypeList[number]
export type HEALTH_CHECK_STATUS = typeof healthCheckStatusList[number]

export interface HealthCheckQuestion {
  title: string
  id: HEALTH_CHECK_TYPE
  options: HealthCheckQuestionOption[]
}

export interface HealthCheckQuestionOption {
  id: HEALTH_CHECK_STATUS
  label: string
  icon: string
}

export interface CreateSprintHealthCheckDto {
  status: HEALTH_CHECK_STATUS,
  type: HEALTH_CHECK_TYPE
}

export interface HealthCheckDto {
  count: number,
  status: HEALTH_CHECK_STATUS,
  type: HEALTH_CHECK_TYPE
}

export interface HealthCheck {
  summaryDtoList: HealthCheckDto[]
  teamSize: number
  votedSize: number
}

export interface HealthCheckItem {
  title: string,
  count: number,
  status: HEALTH_CHECK_STATUS,
  type: HEALTH_CHECK_TYPE,
  icon: string
}

export class HealthCheckSummary {
  healthSummary: {
    [key: string]: HealthCheckItem[]
  }
  teamSize = 0
  votedSize = 0

  constructor(props?: Partial<HealthCheck>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      if (field === "summaryDtoList") {
        this.healthSummary = this.parseHealthSummary(props[field] as HealthCheckDto[])
        return
      }
      this[field] = props[field]
    })
  }

  parseHealthSummary(summaryDtoList: HealthCheckDto[]) {
    const healthSummaryTypes: HEALTH_CHECK_TYPE[] = healthCheckTypeList
    const healthSummary = {}
    healthSummaryTypes.forEach((type) => {
      const healthSummaryStatuses = type === "WORK_LIFE_BALANCE" ? [
        "HEALTHY",
        "UNHEALTHY",
      ] : healthCheckStatusList
      healthSummary[type] = healthSummaryStatuses.map((status) => {
        const count = summaryDtoList.find(dto => dto.type === type && dto.status === status)?.count || 0
        return {
          count,
          status,
          type,
          icon: $t(`healthSummaryIcon.${status}`),
        }
      })
    })
    return healthSummary
  }
}
