<template>
  <div class="progress-loading">
    <span :style="{ width: progress + '%' }" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

const components = {}

@Options({
  name: "ProgressLoadingLine",
  components,
})
export default class extends Vue {
  @Prop({ default: 0 }) readonly progress: number
}
</script>
