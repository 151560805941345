<template>
  <div
    class="basic-checkbox"
    :class="[
      {'is-bordered': bordered },
      {'is-checked' : isChecked},
      {'is-danger' : danger},
      {'is-disabled' : isDisabled},
      {'basic-checkbox_lineThrough' : lineThrough},
      `basic-checkbox_${checkType}`,
      `basic-checkbox_${checkSize}`
    ]"
  >
    <input
      :id="value"
      :key="value"
      type="checkbox"
      class="basic-checkbox__input"
      :checked="isChecked"
      :value="value"
      :disabled="isDisabled"
      @change="updateInput"
      @input="$emit('input')"
    >
    <label
      :for="value"
      class="body-text body-text-medium-400"
      :class="[
        {'basic-checkbox_switch__label': addSwitch},
        {'basic-checkbox__label': !addSwitch}]"
    >
      <span>{{ label }}</span>
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

type checkTypes = "box" | "check" | "filter" | "switch"
type checkSize = "sm" | "md"

@Options({
  name: "BasicCheckbox",
})
export default class extends Vue {
  @Prop({ default: "" }) value: string
  @Prop({ default: "" }) label: string
  @Prop({ default: false }) isDisabled: boolean
  @Prop({ default: true }) trueValue: boolean
  @Prop({ default: false }) falseValue: boolean
  @Prop({ default: false }) bordered: boolean
  @Prop({ default: false }) singleValue: boolean
  @Prop({ default: true }) allowEmpty: boolean
  @Prop({ default: false }) danger: boolean
  @Prop({ default: true }) lineThrough: boolean
  @Prop({ default: "box" }) checkType: checkTypes
  @Prop({ default: "sm" }) checkSize: checkSize
  @Prop() readonly modelValue: string | string[] | boolean

  get isChecked() {
    if (this.modelValue instanceof Array) {
      return this.modelValue.includes(this.value)
    }
    // Note that `true-value` and `false-value` are camelCase in the JS
    return this.modelValue === this.trueValue
  }

  updateInput(event) {
    let isChecked = event.target.checked

    if (this.modelValue instanceof Array) {
      let newValue = [...this.modelValue]
      if (isChecked) {
        if (this.singleValue) {
          newValue = [this.value]
        } else {
          newValue.push(this.value)
        }
      } else if (newValue.length > 1 || this.allowEmpty) {
        newValue.splice(newValue.indexOf(this.value), 1)
      } else {
        return
      }
      this.$emit("update:modelValue", newValue)
    } else {
      this.$emit("update:modelValue", isChecked ? this.trueValue : this.falseValue, this.value)
    }
  }
  get addSwitch() {
    return this.checkType === "switch"
  }
}
</script>
