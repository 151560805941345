import { Options, Vue } from "vue-class-component"

@Options({
  name: "ShowAlertUnsavedChanges",
})

export class ShowAlertUnsavedChanges extends Vue {
  unsavedChanges = false
  goTo = ""
  continueUnsave = false
  cancelUnsave = false
  onGoingToLeave = false
  disabledBeforeUnload = false
  created() {
    window.addEventListener("beforeunload", this.beforeUnloadListener)
  }

  beforeUnloadListener(event) {
    if (this.disabledBeforeUnload) return
    this.onBeforeShowModal()
    if (this.unsavedChanges) {
      event.preventDefault()
      event.returnValue = ""
      return ""
    }
    return null
  }

  async onBeforeRouteUpdate(to, from, next) {
    if (this.unsavedChanges) {
      this.onGoingToLeave = true
      this.showUnsavedChangesModal()
      this.goTo = to
      next(false)
    } else {
      next()
    }
  }

  showUnsavedChangesModal() {
    this.$modal.show("unsaved-changes")
  }

  onBeforeShowModal() {
    return
  }

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnloadListener)
  }

  goNext() {
    this.$modal.hide("unsaved-changes")
    this.unsavedChanges = false
    this.continueUnsave = !this.continueUnsave
    this.onGoingToLeave = false
    this.onContinueUnsave()
    if (!this.goTo) return false
    this.$nextTick(() => this.$router.push(this.goTo))
  }

  onClosedUnsavedModal() {
    this.cancelUnsave = !this.cancelUnsave
    this.onGoingToLeave = false
  }

  onContinueUnsave() {
    //
  }
}
