import { ActionItems } from "./ActionItems"
import { RetroNote } from "./RetroNote"
import { RetroStackItemsList } from "./RetroStackItemsList"
import { Sprint } from "./Sprint"
import { User } from "./User"

export type RetroStatus = "NOT_STARTED" | "STARTED" | "REQUESTED" | "REVEALED" | "COMPLETED";
export interface RetroChangePriority {
  previousItemId?: string;
  itemId: string;
  nextItemId?: string;
  lowest: boolean;
  retrospectiveId: string;
}
export interface RetroPriority {
  newPriority: string
  normalizedAll: boolean
}

export interface RetroStack {
  title: string;
  noteIds: string[];
  wentWell: boolean;
}

export interface RetroStackNoteDto {
  stackId: string;
  noteId: string;
}

export interface StackNote {
  title: string;
  noteIds: string[];
  wentWell: boolean;
  id?: string;
  priority?: 0;
  elements?: RetroNote[]
}

export interface RetrospectiveDTO {
  id: string;
  sprint?: Sprint;
  notes: RetroNote[];
  actions?: ActionItems[];
  stacks?: StackNote[];
  status: RetroStatus
  relatedUsers: User[]
  demo: boolean
}

export interface RetrospectiveProps {
  id: string;
  sprint?: Sprint;
  notes: RetroNote[];
  actions?: ActionItems[];
  stacks?: StackNote[];
  status: RetroStatus;
  relatedUsers: User[]
  demo: boolean
}

export class Retrospective {
  id: string
  sprint?: Sprint
  notes: RetroNote[]
  actions?: ActionItems[]
  stacks: StackNote[]
  status: RetroStatus
  wentWell: RetroStackItemsList[]
  toImprove: RetroStackItemsList[]
  notReviewed: RetroStackItemsList[]
  actionItems: RetroStackItemsList[]
  relatedUsers: User[]
  demo: boolean

  constructor(props?: RetrospectiveProps) {
    this.setDefaultData()
    if (!props) return

    const {
      id,
      sprint,
      notes,
      actions,
      stacks,
      status,
      relatedUsers,
      demo,
    } = props

    this.id = id ?? ""
    this.sprint = sprint ? Sprint.create(sprint) : undefined
    this.notes = notes.sort((a, b) => a.priority - b.priority).map(e => RetroNote.create(e)) ?? []
    this.actions = actions ?? []
    this.stacks = stacks ? stacks.map((stack) => {
      stack.elements = notes.filter(e => e.stackId === stack.id)
      return stack
    }) : []
    this.status = status ?? "NOT_STARTED"
    this.wentWell = this.notesWithStack.filter(note => note.reviewed && note.wentWell) ?? []
    this.toImprove = this.notesWithStack.filter(note => note.reviewed && !note.wentWell && !note.hasActionItem) ?? []
    this.notReviewed = this.notesWithStack.filter(note => !note.reviewed) ?? []
    this.actionItems = this.notesWithStack.filter(note => !note.wentWell && note.hasActionItem) ?? []
    this.relatedUsers = relatedUsers.map(e => User.create(e)) ?? []
    this.demo = demo ?? false
  }

  static create(retroNoteDTO?: RetrospectiveDTO): Retrospective {
    if (retroNoteDTO) {
      const {
        id,
        sprint,
        notes,
        actions,
        stacks,
        status,
        relatedUsers,
        demo,
      } = retroNoteDTO

      const retroNote = new Retrospective({
        id: id,
        sprint: sprint,
        notes: notes,
        actions: actions,
        stacks: stacks,
        status: status,
        relatedUsers: relatedUsers,
        demo,
      })
      return retroNote
    }
    return new Retrospective()
  }

  setDefaultData() {
    this.id = ""
    this.sprint = undefined
    this.notes = []
    this.actions = []
    this.stacks = []
    this.status = "NOT_STARTED"
  }

  static createDefault(): Retrospective {
    return new Retrospective({
      id: "",
      notes: [],
      status: "NOT_STARTED",
      relatedUsers: [],
      demo: false,
    })
  }

  getJsonObj() {
    return {
      id: this.id,
      sprint: this.sprint,
      notes: this.notes,
      actions: this.actions,
      stacks: this.stacks,
      status: this.status,
      demo: this.demo,
    }
  }

  get isCompleted() {
    return this.status === "COMPLETED"
  }

  get isRequested() {
    return this.status === "REQUESTED"
  }

  get isStarted() {
    return this.status === "STARTED"
  }

  get isRevealed() {
    return this.status === "REVEALED"
  }

  get isNotStarted() {
    return this.status === "NOT_STARTED"
  }

  get sprintSequenceNumber() {
    return this.sprint?.sequenceNumber ?? 0
  }

  get notesWithStack(): RetroStackItemsList[] {
    const stackList: RetroStackItemsList[] = []
    const notes: RetroStackItemsList[] = []

    this.notes.forEach((note) => {
      if (!note.stackId?.length) {
        notes.push(RetroStackItemsList.create(note))
      }
    })

    this.stacks.forEach((stack) => {
      stackList.push(RetroStackItemsList.create(stack))
    })

    return [...notes, ...stackList].sort((a, b) => a.priority - b.priority)
  }

  get notesAreHidden() {
    return this.demo && !this.isCompleted
  }

  get wentWellNotes() {
    if (this.notesAreHidden) {
      return []
    }
    return this.notes.filter(note => note.wentWell)
  }

  get toImproveNotes() {
    if (this.notesAreHidden) {
      return []
    }
    return this.notes.filter(note => !note.wentWell)
  }

  createStack({ draggableList, parentKey, index } : { draggableList: RetroNote[], parentKey: string, index: number }) {
    const nameOfStackByTypeList = this[parentKey][index]
    const updateDecription = `Stack ${this.stacks.length + 1}`

    nameOfStackByTypeList.elements = draggableList
    nameOfStackByTypeList.elements.push({
      ...nameOfStackByTypeList,
      elements: [],
    })
    nameOfStackByTypeList.description = updateDecription
    nameOfStackByTypeList.isNewStack = true
    const noteIds = nameOfStackByTypeList.elements.map(e => e.id)
    const retroStack = {
      title: updateDecription,
      noteIds: noteIds,
      wentWell: nameOfStackByTypeList.wentWell,
    }
    return {
      retroStack,
      listKey: parentKey,
    }
  }

  addNoteInStack({ draggableList, parentKey, index } : { draggableList: RetroNote[], parentKey: string, index: number }) {
    const nameOfStackByTypeList = this[parentKey][index]
    let addNoteId = ""
    addNoteId = draggableList.find(updateNote => !nameOfStackByTypeList.elements.find(e => e.id === updateNote.id))?.id ?? ""

    nameOfStackByTypeList.elements = draggableList

    return {
      stackId: nameOfStackByTypeList.id,
      noteId: addNoteId,
    }
  }

  isVoted(userId) {
    return this.notes.some(e => e.user?.id === userId)
  }

}
