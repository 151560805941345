import { Action, Module } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "@/store/modules/account.module"
import {
  SearchResult,
  SearchPayload,
  SearchItemResult,
  SearchProjectResult,
  SearchTeamResult,
  SearchSprintResult,
  SearchMilestoneResult,
  SearchEpicResult,
} from "@/models/Search"
import { Mutation } from "vuex-class-modules"
import { Status } from "@/models/Status"
import { typeCreateById } from "@/models/Type"
import { ServerResponse } from "@/models/ServerResponse"
import {
  Filter,
  HttpError,
  Item,
  Project,
  Task,
  Team,
  FilterSearchItem,
  FilterSearch,
  LabelSearchFilter,
  FilterTeam,
} from "@/models"
import { ProjectActions, projectModule } from "./project.module"
import { FilterSprint } from "@/models/Filter/FilterSprint"
import { parsingServerResponseContent } from "@/utils/helpers"

export enum SearchActions {
  SEARCH_RESOURCE = "SEARCH_RESOURCE",
  SEARCH_ITEM = "SEARCH_ITEM",
  SEARCH_PROJECT = "SEARCH_PROJECT",
  SEARCH_SPRINT = "SEARCH_SPRINT",
  SEARCH_EPIC = "SEARCH_EPIC",
  SEARCH_TEAM = "SEARCH_TEAM",
  SEARCH_MILESTONE = "SEARCH_MILESTONE",
  SEARCH_ITEM_WITH_TASKS = "SEARCH_ITEM_WITH_TASKS",
  SEARCH_LABELS = "SEARCH_LABELS",
  RESET_SEARCH = "RESET_SEARCH",
}

const FILTER_OPTIONS = {
  page: 0,
  size: 1000,
  sort: [],
}

@Module
class SearchModule extends BaseModule {
  searchResult: SearchResult[]
  searchSprintResult: SearchSprintResult[]

  get projectId() {
    return projectModule.currentProjectId
  }

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${SearchActions.SEARCH_RESOURCE}`]() {
    return this.searchResult
  }

  get [`get/${SearchActions.SEARCH_SPRINT}`]() {
    return this.searchSprintResult
  }


  @Mutation
  setResult(result: SearchResult[]) {
    this.searchResult = result
  }

  @Mutation
  setSearchSprintResult(result: ServerResponse<SearchSprintResult>) {
    this.searchSprintResult = parsingServerResponseContent({
      originalContent: this.searchSprintResult,
      serverResponseContent: result.content,
      numberOfPage: result.pageable.pageNumber,
    })
  }

  @Action
  async [SearchActions.SEARCH_RESOURCE](payload: SearchPayload) {
    const data = await apiService.get<SearchResult[]>(Endpoint.SEARCH(this.accountId), payload)
    return this.handleResponse<SearchResult[]>(data, (data) => {
      this.setResult(data)
      return data
    })
  }

  @Action
  async [SearchActions.SEARCH_LABELS](payload: LabelSearchFilter) {
    const data = await apiService.get<ServerResponse<string>>(Endpoint.SEARCH_LABELS(this.projectId), payload.getJsonObj())
    return this.handleResponse<ServerResponse<string>>(data, (data) => {
      return data
    })
  }

  @Action
  async [SearchActions.SEARCH_ITEM](payload: { sequenceNumber: string, projectIds?: string[], projectName?: string }) {
    const { projectIds, sequenceNumber } = payload

    const filter = FilterSearchItem.create({
      q: sequenceNumber,
      projectIds: projectIds,
    })

    const data = await apiService.get<ServerResponse<SearchItemResult>>(Endpoint.SEARCH_ITEM(this.accountId), filter.getJsonObj())

    let projectIdFromName
    if (payload.projectName) {
      projectIdFromName = await apiService.get<string>(Endpoint.PROJECT_RESOLVE(this.accountId), {
        name: payload.projectName,
      })
    }
    return this.handleResponse<ServerResponse<SearchItemResult>>(data, (data) => {
      const items = data.content.map((result) => {
        const itemType = result.itemType
        const status = result.status
        const itemTypeParsed = typeCreateById({ id: itemType })
        return {
          title: result.title ?? "Item",
          itemType,
          itemTypeParsed,
          id: result.id,
          status,
          statusParsed: Status.createById({ id: status }),
          sequenceNumber: result.sequenceNumber,
          projectId: result.projectId,
        }
      })

      this.setResult(items as unknown as SearchResult[])
      return projectIdFromName ? items.filter(res => res.projectId === projectIdFromName) : items
    }, true)
  }

  @Action
  async [SearchActions.SEARCH_ITEM_WITH_TASKS](payload: { sequenceNumber: string, projectName?: string }) {
    const result = await this[SearchActions.SEARCH_ITEM]({
      sequenceNumber: payload.sequenceNumber,
      projectName: payload.projectName,
    })
    const item = result.find(result => result.sequenceNumber.toString() === payload.sequenceNumber)
    if (item) {
      const data = await apiService.get<Item>(Endpoint.GET_ITEM(item.projectId, item.id))
      return this.handleResponse<Item>(data, (data) => {
        const itemType = data.itemType
        const status = data.status
        const itemTypeParsed = typeCreateById({ id: itemType })
        return {
          id: data.id,
          title: data.title,
          itemType,
          itemTypeParsed,
          status,
          statusParsed: Status.createById({ id: status }),
          sequenceNumber: data.sequenceNumber,
          tasks: data.tasks.map(task => new Task(task)).sort((a, b) => a.order - b.order),
        }
      }, true)
    } else {
      return this.handleResponse<boolean>(false)
    }
  }

  @Action
  async [SearchActions.SEARCH_PROJECT](q: string) {
    const filter = FilterSearch.create({
      q,
    })

    filter.addSort("name_keyword", true)
    const data = await apiService.get<ServerResponse<SearchProjectResult>>(Endpoint.SEARCH_PROJECT(this.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<SearchProjectResult>>(data, (data) => {
      const items = data.content.map(result => ({
        id: result.id,
        name: result.name,
        urlName: result.urlName,
      }))

      this.setResult(items as unknown as SearchResult[])
      return data
    }, true)
  }

  @Action
  async [SearchActions.SEARCH_SPRINT]({ filter, onlyGet } : { filter: FilterSprint, onlyGet?: boolean }) {
    let team
    if (!filter.teamId && filter.teamName) {
      const data = await apiService.get<string>(Endpoint.TEAM_RESOLVE(this.accountId), {
        name: filter.teamName,
      })
      if (!(data instanceof HttpError)) {
        filter.teamId = data
        team = await apiService.get<Team>(Endpoint.TEAM(this.accountId, filter.teamId))
      }
    }

    const data = await apiService.get<ServerResponse<SearchSprintResult>>(Endpoint.SEARCH_SPRINT(this.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<SearchSprintResult>>(data, (data) => {
      data.content = data.content.map(result => new SearchSprintResult({
        id: result.id,
        startDate: result.startDate,
        endDate: result.endDate,
        projectId: result.projectId,
        sequenceNumber: result.sequenceNumber,
        status: result.status,
        team,
      }))
      if (!onlyGet) {
        this.setResult(data.content as unknown as SearchResult[])
      }
      return data
    }, true)
  }

  @Action
  async [SearchActions.SEARCH_EPIC](payload: { epicNumber: string, projectName?: string, projectId?: string }) {
    let projectId: string | HttpError = payload.projectId || ""

    if (!projectId && payload.projectName) {
      projectId = await apiService.get<string>(Endpoint.PROJECT_RESOLVE(this.accountId), {
        name: payload.projectName,
      })
    }

    let projects = [] as Project[]
    const data_ = await apiService.get<{ content: Project[] }>(Endpoint.PROJECT(this.accountId), new Filter(FILTER_OPTIONS).getJsonObj())
    if (data_ && !(data_ instanceof HttpError)) {
      projects = data_.content
    }

    const filter = new Filter(FILTER_OPTIONS)
    const query = filter?.getJsonObj({
      params: {
        q: payload.epicNumber || " ",
      },
    })
    const data = await apiService.get<ServerResponse<SearchEpicResult>>(Endpoint.SEARCH_EPIC(this.accountId), query)
    this.handleResponse<ServerResponse<SearchEpicResult>>(data, (data) => {
      const epicResults = data.content.map(result => ({
        id: result.id,
        name: result.name,
        percentComplete: result.percentComplete,
        sequenceNumber: result.sequenceNumber,
        projectId: result.projectId,
        projectName: projects.find(project => project.id === result.projectId)?.urlName,
      }))

      const searchResult = projectId ? epicResults.filter(res => res.projectId === projectId) : epicResults
      this.setResult(searchResult as unknown as SearchResult[])

      return searchResult
    }, true)
  }

  @Action
  async [SearchActions.SEARCH_TEAM](payload: FilterTeam) {
    let projectId = payload.projectId

    if (payload.projectName) {
      const data = await apiService.get<string>(Endpoint.PROJECT_RESOLVE(this.accountId), {
        name: payload.projectName,
      })
      if (data && !(data instanceof HttpError)) {
        projectId = data
      }
    }

    const filter = FilterTeam.create({ ...payload, projectId })

    const data = await apiService.get<ServerResponse<SearchTeamResult>>(Endpoint.SEARCH_TEAM(this.accountId), filter.getJsonObj())
    return this.handleResponse<ServerResponse<SearchTeamResult>>(data, (data) => {
      const items = data.content.map(result => ({
        id: result.id,
        name: result.name,
        size: result.size,
        // In this field, the redundant code will be removed as soon as this field is added to the backend
        urlName: result.urlName ?? result.name.trim().toLowerCase().replace(/\s/g, "-"),
        picture: result.picture,
      }))

      this.setResult(items as unknown as SearchResult[])
      return items
    }, true)
  }

  @Action
  async [SearchActions.SEARCH_MILESTONE](payload: { milestone: string, projectName?: string }) {
    let projectId
    if (payload.projectName) {
      projectId = await apiService.get<string>(Endpoint.PROJECT_RESOLVE(this.accountId), {
        name: payload.projectName,
      })
    }

    let projects = [] as Project[]
    const data_ = await apiService.get<{ content: Project[] }>(Endpoint.PROJECT(this.accountId), new Filter(FILTER_OPTIONS).getJsonObj())
    if (data_ && !(data_ instanceof HttpError)) {
      projects = data_.content
    }

    const filter = new Filter(FILTER_OPTIONS)
    const query = filter?.getJsonObj({
      params: {
        q: payload.milestone || " ",
      },
    })
    const data = await apiService.get<ServerResponse<SearchMilestoneResult>>(Endpoint.SEARCH_MILESTONE(this.accountId), query)
    return this.handleResponse<ServerResponse<SearchMilestoneResult>>(data, (data) => {
      const milestoneResults = data.content
        .map(result => ({
          id: result.id,
          title: result.title,
          endDate: result.endDate,
          projectId: result.projectId,
          projectName: projects.find(project => project.id === result.projectId)?.urlName,
        }))

      const result = projectId ? milestoneResults.filter(res => res.projectId === projectId) : milestoneResults
      this.setResult(result as unknown as SearchResult[])
      return result
    }, true)
  }

  @Action
  async [SearchActions.RESET_SEARCH]() {
    this.setResult([])
  }
}

export const searchModule = new SearchModule({ store, name: Modules.SEARCH })
