<template>
  <div class="layout">
    <Header />
    <div
      id="mainContent"
      class="inner"
    >
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import Header from "@/components/Header/index.vue"
@Options({
  components: {
    Header,
  },
  name: "ErrorLayout",
})
export default class App extends Vue {
}
</script>
