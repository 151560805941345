export const config = {
  VUE_APP_KEYCLOAK_URL: process.env.VUE_APP_KEYCLOAK_URL,
  VUE_APP_FILES_LOCATION: process.env.VUE_APP_FILES_LOCATION,
  VUE_APP_API_LOCATION: process.env.VUE_APP_API_LOCATION,
  VUE_APP_MODE: process.env.VUE_APP_MODE,
  VUE_APP_UPLOAD_MAX_FILE_SIZE_KB: process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE_KB,
  VUE_APP_UNLEASH_FE_API_TOKEN: process.env.VUE_APP_UNLEASH_FE_API_TOKEN,
}

export const isStaging = (): boolean => config.VUE_APP_MODE === "staging"
export const isLocalHost = () => config.VUE_APP_MODE === "development"
export const trainingYoutubeVideoId = (): string => "IG5IHjAKYu8"

