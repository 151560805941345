<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <Avatar
        :src="notification.initiatorAvatarSrc || null"
        :gap="notification.getInitials()"
      />
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
      >
        You have been mentioned by
        <span class="notification__update-item__initiator">{{ notification.initiatorFullName }}</span> to
        <a
          class="link is-large"
          target="_blank"
          :href="itemUrl"
        >
          Story {{ notification.itemSequenceNumber }}:
        </a>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__desc"
      >
        <ReferencedText
          :resetStyle="true"
          :html="notification.text"
        />
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop, Ref } from "vue-property-decorator"
import { NotificationComment } from "@/models/Notification/NotificationComment"
import Avatar from "@/components/Avatar/index.vue"
import { ReferencedText } from "../../Reference"
@Options({
  name: "USER_MENTION",
  components: {
    NotificationTemplate,
    Avatar,
    ReferencedText,
  },
})
export default class USER_MENTION extends Vue {
  @Prop() readonly notification: NotificationComment
  @Ref("text") readonly el: HTMLDivElement

  get itemUrl() {
    return this.notification.content?.resourceUrl
  }
}
</script>
