import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  name: "hideDropdownToScroll",
})
export class hideDropdownToScroll extends Vue {
  @Prop() scrollableContainer: string
  get parentRef():HTMLElement | null {
    return document.getElementById(this.scrollableContainer)
  }

  mounted() {
    window.addEventListener("scroll", this.hideDropDown)
    window.addEventListener("resize", this.hideDropDown)
    this.parentRef?.addEventListener("scroll", this.hideDropDown)
  }

  hideDropDown() {
    return
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.hideDropDown)
    window.removeEventListener("resize", this.hideDropDown)
    this.parentRef?.removeEventListener("scroll", this.hideDropDown)
  }
}
