import { EditorData } from "@/models/EditorData"
import { ReferencedResourcePreviews } from "@/models/Search"
import { Status } from "@/models/Status"
import { TaskTypeOption } from "@/models/Task"
import { typeCreateById } from "@/models/Type"
import { $t } from "@/plugins/i18n"

export const updateReferencesByResourcePreviews = (referencedResourcePreviews: ReferencedResourcePreviews, html: string) => {
  const editorData = new EditorData()

  editorData.setHtml(html)

  if (!referencedResourcePreviews || !Object.keys(referencedResourcePreviews).length) {
    return editorData.getHtmlText()
  }

  referencedResourcePreviews.items?.forEach((item) => {
    if (!item) return
    const { id, title, status, itemType } = item
    editorData.updateHtmlFromRefers(id, {
      title,
      status,
      itemType,
      statusParsed: Status.createById({ id: status }),
      itemTypeParsed: typeCreateById({ id: itemType }),
    })
  })

  referencedResourcePreviews.tasks?.forEach((task) => {
    if (!task) return
    const { id, name, order, type } = task
    const typeParsed = {
      id: type,
      label: $t(`task.types.${type.toLocaleLowerCase()}`),
    } as TaskTypeOption
    editorData.updateHtmlFromRefers(id, {
      name,
      order,
      type,
      typeParsed,
    })
  })

  referencedResourcePreviews.milestones?.forEach((milestone) => {
    if (!milestone) return
    const { id, title, endDate } = milestone
    editorData.updateHtmlFromRefers(id, {
      title,
      endDate,
    })
  })

  referencedResourcePreviews.epics?.forEach((epic) => {
    if (!epic) return
    const { id, name, percentComplete } = epic
    editorData.updateHtmlFromRefers(id, {
      name,
      percentComplete,
    })
  })

  referencedResourcePreviews.projects?.forEach((project) => {
    if (!project) return
    const { id, name } = project
    editorData.updateHtmlFromRefers(id, {
      name,
    })
  })

  referencedResourcePreviews.sprints?.forEach((sprint) => {
    if (!sprint) return
    const { id, startDate, endDate, status } = sprint
    editorData.updateHtmlFromRefers(id, {
      startDate,
      endDate,
      status,
    })
  })

  referencedResourcePreviews.users?.forEach((user) => {
    if (!user) return
    const { id, email, pictureUrl, enabled, teamRolesString } = user
    editorData.updateHtmlFromRefers(id, {
      email,
      pictureUrl,
      enabled,
      teamRolesString,
    })
  })
  return editorData.getHtmlText()
}
