import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_ToDoSelectUser = _resolveComponent("ToDoSelectUser")!
  const _component_TodoWidgetDatePicker = _resolveComponent("TodoWidgetDatePicker")!
  const _component_BasicCheckbox = _resolveComponent("BasicCheckbox")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ControlButtons = _resolveComponent("ControlButtons")!
  const _component_ToDoContent = _resolveComponent("ToDoContent")!

  return (_openBlock(), _createBlock(_component_ToDoContent, {
    class: _normalizeClass(_ctx.className)
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        level: "3",
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add ToDo Item ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_ToDoSelectUser, {
        toDoUsers: _ctx.toDo.owners,
        toDoTeams: _ctx.toDo.selectedTeams,
        rules: _ctx.validators.isRequired('Owner'),
        canSelectTeam: !_ctx.toDo.isStoryPoker,
        onOnSelectedUsers: _ctx.onSelectedUsers,
        onOnSelectedTeams: _ctx.onSelectedTeams
      }, null, 8, ["toDoUsers", "toDoTeams", "rules", "canSelectTeam", "onOnSelectedUsers", "onOnSelectedTeams"]),
      _createVNode(_component_TodoWidgetDatePicker, {
        today: _ctx.today,
        tomorrow: _ctx.tomorrow,
        date: _ctx.toDo.estimatedDeadline,
        rules: _ctx.validators.isRequired('Date'),
        onOnCheckboxTomorrow: _ctx.onCheckboxTomorrow,
        onOnCheckboxToday: _ctx.onCheckboxToday,
        onUpdateDate: _ctx.updateDate
      }, null, 8, ["today", "tomorrow", "date", "rules", "onOnCheckboxTomorrow", "onOnCheckboxToday", "onUpdateDate"]),
      _createVNode(_component_BasicCheckbox, {
        modelValue: _ctx.toDo.urgent,
        class: "to-do__mark-as-urgent",
        label: "Mark as Urgent",
        value: "urgent",
        bordered: false,
        lineThrough: false,
        checkType: "check",
        "onUpdate:modelValue": _ctx.updateUrgent
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_Field, {
        modelValue: _ctx.toDo.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toDo.name) = $event)),
        as: "div",
        validateOnBlur: false,
        name: "toDoDescription",
        class: "to-do__description__wrapper",
        rules: _ctx.validators.isRequired('Description')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Editor, {
            id: "toDoDescription",
            value: _ctx.toDo.structuredDescription.html,
            extensions: _ctx.extensions,
            triggerHandleInputOnMount: false,
            taskEditor: true,
            class: "to-do__description",
            onHandleInput: _ctx.handleInputEditor,
            onInput: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["value", "extensions", "onHandleInput"]),
          _createVNode(_component_ErrorMessage, {
            class: "error-text error-text-small",
            name: "toDoDescription"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "rules"])
    ]),
    footer: _withCtx(({ validate, handleReset }) => [
      _createVNode(_component_ControlButtons, {
        small: true,
        onSave: ($event: any) => (_ctx.onValidateAndSave(validate, handleReset)),
        onCancel: _ctx.onCancel
      }, null, 8, ["onSave", "onCancel"])
    ]),
    _: 1
  }, 8, ["class"]))
}