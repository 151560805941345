import { ReferenceExtension } from "../Reference"
import HashSuggestion from "@/components/Reference/Hash/HashSuggestion"
import HashSuggestionFiles from "@/components/Reference/Hash/HashSuggestionFiles"
import { InitComponent } from "@/components/Reference"

export const ReferenceHash = ReferenceExtension.extend({
  name: "referenceHash",
}).configure({
  suggestion: HashSuggestion,
  pasteRules: "#((P|S|M|E|T) - \w+|[0-9]+( |\.[0-9]+))", 
  component: InitComponent
})

export const ReferenceHashFile = ReferenceExtension.extend({
  name: "referenceHashFile",
}).configure({
  suggestion: HashSuggestionFiles,
  pasteRules: "#(F)", 
  component: InitComponent
})

