
import { mixins, Options } from "vue-class-component"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import ReferenceComponentCommon from "../helpers/ReferenceComponentCommon"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import {
  FilterUser,
  Item,
  ItemInfo,
  SearchEpicResult,
  SearchItemResult,
  SearchMilestoneResult,
  SearchSprintResult,
  ServerResponse,
  User,
} from "@/models"
import { Team } from "project-simple-server-open-api/client"
import { parsingProjectIdAndItemNumber } from "@/utils/helpers"
import { FilterSprint } from "@/models/Filter/FilterSprint"

@Options({
  name: "ParseReference",
})
export default class extends mixins(ReferenceComponentCommon) {
  @Getter(Getters.GET_SESSION_TEAM_ID) readonly teamId: string

  @Action(Actions.GET_USERS) findUsers: (params: FilterUser) => Promise<ServerResponse<User>>
  @Action(Actions.SEARCH_ITEM) findItem: (payload: { sequenceNumber: string, projectName: string }) => Promise<SearchItemResult[]>
  @Action(Actions.SEARCH_ITEM_WITH_TASKS) findTasks: (payload: { sequenceNumber: string, projectName: string }) => Promise<Item>
  @Action(Actions.SEARCH_EPIC) findEpics: (payload: { epicNumber: string, projectName?: string }) => Promise<SearchEpicResult[]>
  @Action(Actions.SEARCH_MILESTONE) findMilestones: (payload: { milestone: string, projectName?: string }) => Promise<SearchMilestoneResult[]>
  @Action(Actions.SEARCH_SPRINT) findSprint: ({ filter, onlyGet } : { filter: FilterSprint, onlyGet?: boolean }) => Promise<ServerResponse<SearchSprintResult>>
  @Action(Actions.RESOLVE_SESSION_TEAM_ID) getTeamIdByUrlName: ({ name, onlyGet } : { name: string, onlyGet?: boolean }) => Promise<string>
  @Action(Actions.RESOLVE_SESSION_PROJECT_ID) getProjectIdByUrlName: ({ name, onlyGet }:{ name: string, onlyGet?: boolean }) => Promise<string>
  @Action(Actions.RESOLVE_ITEM_ID) getItemIdByUrlName: ({ sequenceNumber, onlyGet }:{ sequenceNumber: number | string, onlyGet?: boolean }) => Promise<string>
  @Action(Actions.GET_TEAM) getTeam: ({ teamId, onlyGet } : { teamId: string, onlyGet?: boolean }) => Promise<Team>
  @Action(Actions.GET_ITEM) getItem: ({ itemId, onlyGet } : { itemId: string, onlyGet?: boolean }) => Promise<Item>

  mounted() {
    if (this.node?.attrs?.loading) {
      if (this.type === ReferenceType.PROJECT) {
        this.searchProject()
      }
      if (this.type === ReferenceType.USER) {
        this.searchUser()
      }
      if (this.type === ReferenceType.ITEM) {
        this.searchItem()
      }
      if (this.type === ReferenceType.TASK) {
        this.searchTask()
      }
      if (this.type === ReferenceType.SPRINT) {
        this.searchSprint()
      }
      if (this.type === ReferenceType.MILESTONE) {
        this.searchMilestone()
      }
      if (this.type === ReferenceType.EPIC) {
        this.searchEpic()
      }
      if (this.type === ReferenceType.TEAM) {
        this.searchTeam()
      }
    }
  }

  async searchUser() {
    const userName = this.text.toLowerCase().substring(1)
    const filter = FilterUser.createDefault()
    filter.text = userName
    const serverResponseUser = await this.findUsers(filter)
    const users = serverResponseUser.content
    if (users.length) {
      const user = users.find(user => user.username.toLowerCase() === userName)
      if (user) {
        this.updateAttributes({
          id: user.id,
          info: JSON.stringify(user),
          linkText: `@${user.fullName}`,
          linkHref: await this.getHrefByType(ReferenceType.USER, user),
          loading: false,
        })
      } else {
        this.setDefaultText()
      }
    }
  }

  async searchItem() {
    let sequenceNumber
    if (this.route.params.itemId) {
      const parsingResult = parsingProjectIdAndItemNumber(this.route.params.projectId + "-" + this.route.params.itemId)
      sequenceNumber = parsingResult.itemId
    } else {
      sequenceNumber = this.route.query.i ?? 0
    }
    if (this.text.includes("#")) {
      sequenceNumber = this.text.split("#")[1]
    }

    const itemId = await this.getItemIdByUrlName({
      sequenceNumber,
      onlyGet: true,
    })
    const result = await this.getItem({
      itemId,
      onlyGet: true,
    })
    const itemInfo = new ItemInfo({
      ...result,
    }) // this necessary to remove the redundant fields
    if (itemInfo) {
      this.updateAttributes({
        id: itemInfo.id,
        info: JSON.stringify(itemInfo),
        linkText: `#${itemInfo.sequenceNumber} - ${itemInfo.title}`,
        linkHref: await this.getHrefByType(ReferenceType.ITEM, itemInfo),
          loading: false,
        })
      } else {
        this.setDefaultText()
      }
  }

  async searchTask() {
    const parsingResult = parsingProjectIdAndItemNumber(this.route.params.projectId + "-" + this.route.params.itemId)
    const sequenceNumber = parsingResult.itemId
    const taskNumber = this.route.query.task as string
    const projectName = this.route.params.projectId as string

    const itemResult = await this.findTasks({ sequenceNumber, projectName })
    if (itemResult) {
      const task = itemResult.tasks[taskNumber]
      if (task) {
        const info = { ...task, parentSequenceNumber: sequenceNumber }
        this.updateAttributes({
          id: task.id,
          info: JSON.stringify(info),
          linkText: `#${itemResult.sequenceNumber}.${Number(taskNumber) + 1} - ${task.name}`,
          linkHref: await this.getHrefByType(ReferenceType.TASK, info),
          loading: false,
        })
      } else {
        this.setDefaultText()
      }
    } else {
      this.setDefaultText()
    }
  }

  async searchProject() {
    let name = this.route.params.projectId as string
    if (this.text.includes("#")) {
      name = this.text.split("#P - ")[1]
    }
    name = decodeURI(name)

    const projectId = await this.getProjectIdByUrlName({
      name,
      onlyGet: true,
    })
    const result = await this.getProject({
      projectId,
      onlyGet: true,
    })

    if (result) {
      const info = { id: result.id, urlName: result.urlName }
      this.updateAttributes({
        id: result.id,
        info: JSON.stringify(info),
        linkText: `#P - ${result.name}`,
        linkHref: await this.getHrefByType(ReferenceType.PROJECT, info),
        loading: false,
      })
    } else {
      this.nodeViewWrapper = "span"
      // setTimeout(() => {
      this.setDefaultText()
      // });
    }
  }

  async searchSprint() {
    let sequenceNumber = this.route.params.sprintId as string
    const teamName = this.route.params.teamId as string
    if (this.text.includes("#")) {
      sequenceNumber = this.text.split("#S - ")[1]
    } else {
      const filter = FilterSprint.createDefault()
      filter.teamName = teamName
      filter.q = sequenceNumber
      const serverResponse = await this.findSprint({ filter, onlyGet: true })
      const sprintResults = serverResponse.content
      if (sprintResults?.length) {
        const result = sprintResults.find(result => result.sequenceNumber.toString() === sequenceNumber)
        if (result) {
          this.updateAttributes({
            id: result.id,
            info: JSON.stringify(result),
            linkText: `Sprint #${result.sequenceNumber}`,
            linkHref: await this.getHrefByType(ReferenceType.SPRINT, result),
            loading: false,
          })
        } else {
          this.setDefaultText()
        }
      } else {
        this.setDefaultText()
      }
    }
  }

  async searchMilestone() {
    let milestone = this.route.params.index ? this.route.params.index as string : "a"
    const projectName = this.route.params.projectId as string
    if (this.text.includes("#")) {
      this.updateAttributes({ loading: false, info: "{}" })
      milestone = this.text.split("#M - ")[1]
    } else {
      const milestoneResults = await this.findMilestones({
        milestone,
        projectName,
      })

      if (milestoneResults?.length) {
        const result = milestoneResults.find(result => result.title.toLowerCase() === milestone)
        if (result) {
          const info = { ...result, projectName }
          this.updateAttributes({
            id: result.id,
            info: JSON.stringify(info),
            linkText: `#M - Milestone ${result.title}`,
            linkHref: await this.getHrefByType(ReferenceType.MILESTONE, info),
            loading: false,
          })
        } else {
          this.setDefaultText()
        }
      } else {
        this.setDefaultText()
      }
    }
  }

  async searchEpic() {
    let epicNumber = this.route.params.epicId as string
    const projectName = this.route.params.projectId as string
    if (this.text.includes("#")) {
      this.updateAttributes({ loading: false, info: "{}" })
      epicNumber = this.text.split("#E - ")[1]
    } else {
      const epicResults = await this.findEpics({ epicNumber, projectName })
      if (epicResults?.length) {
        const result = epicResults.find(result => result.sequenceNumber.toString() === epicNumber)
        if (result) {
          const info = { ...result, projectName }
          this.updateAttributes({
            id: result.id,
            info: JSON.stringify(info),
            linkText: `#E - ${result.sequenceNumber} ${result.name}`,
            linkHref: await this.getHrefByType(ReferenceType.EPIC, info),
            loading: false,
          })
        } else {
          this.setDefaultText()
        }
      } else {
        this.setDefaultText()
      }
    }
  }

  async searchTeam() {
    let teamName = this.route.params.teamId as string
    if (this.text.includes("#")) {
      teamName = this.text.split("#T - ")[1]
    }

    teamName = decodeURI(teamName)
    const teamId = await this.getTeamIdByUrlName({
      name: teamName,
      onlyGet: true,
    })
    const team = await this.getTeam({
      teamId,
      onlyGet: true,
    })
    if (team) {
      this.updateAttributes({
        id: team.id,
        info: JSON.stringify(team),
        linkText: `#T - ${team.name}`,
        linkHref: await this.getHrefByType(ReferenceType.TEAM, team),
        loading: false,
      })
    } else {
      this.setDefaultText()
    }
  }

  setDefaultText() {
    this.nodeViewWrapper = "span"
    this.updateAttributes({
      loading: false,
      info: "{}",
      linkText: `${this.originUrl}`,
    })
  }
}
