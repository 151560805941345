import { Module, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"

export enum LoginActions {
  LOGIN = "LOGIN"
}

@Module
class LoginModule extends BaseModule {
  @Action
  async [LoginActions.LOGIN]() {
    const data = await apiService.post<void>(Endpoint.LOGIN())
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.handleResponse<void>(data, () => {})
  }
}

export const loginModule = new LoginModule({ store, name: Modules.LOGIN })
