<template>
  <a
    id="videoPath"
    :data-fancybox="fancyboxData"
    :href="videoPath"
    data-type="video"
    class="video-attachment__link"
  >
    <img
      :id="videoPath + 'img'"
      :src="img"
      alt="Thumbnail"
      class="video-attachment__poster"
    >
    <div class="video-attachment">
      <div
        class="video-attachment__video ready-to-play"
      >
        <video
          class="video-attachment__video"
          :src="videoPath"
        />
      </div>


      <div class="video-attachment__info">
        <BaseSubHeading
          color="monochrome-07"
          weight="400"
          size="large"
          class="video-attachment__title"
        >
          Video: {{ file.name }}
        </BaseSubHeading>
        <BaseSubHeading
          size="medium"
          weight="500"
          color="monochrome-05"
          darkColor="monochrome-04"
          class="video-attachment__size"
        >
          {{ formatBytes(file.size.toString()) }}
        </BaseSubHeading>
      </div>
    </div>

  </a>
</template>

<script lang=ts>
import formatBytes from "@/helpers/formatBytes"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { FileMetaData } from "@/models"
import { Fancybox } from "@fancyapps/ui"
import BaseSubHeading from "@/components/Base/BaseSubHeading.vue"
import Spinner from "@/components/Spinner/index.vue"

@Options({
  name: "VideoAttachmentItem",
  components: { BaseSubHeading, Spinner },
})

export default class VideoAttachmentItem extends Vue {
  @Prop({
    default: [],
  }) file: FileMetaData

  @Prop({
    require: true,
  }) videoPath: string

  @Prop({
    require: true,
  }) fancyboxData: string

  img = ""

  options = {
    Carousel: {
      infinite: false,
    },
  }

  mounted() {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        autoEnable: false,
      },
      on: {
        done: (fancybox, slide) => {
          if (slide.type === "video") {
            if (slide.$content) {
              slide.$content.innerHTML = `<video src="${slide.src}" controls> </video>`
            }
          }
        },
        "Carousel.change": () => {
          const videos = document.querySelectorAll("video")
          videos.forEach((video) => {
            video.pause()
          })
        },
      },
    })
  }

  formatBytes(value: string) {
    return formatBytes(value)
  }
}
</script>
