<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-green">
        <icon
          data="@icon/sprint-alert.svg"
          class="label-pic__icon"
          :fill="true"
          color="#43A047"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        <NotificationSprintHeader
          :sprint="sprintContentHeader"
        >
          Completed
        </NotificationSprintHeader>
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        v-if="sprintContent.summary?.length"
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__desc"
      >
        Summary: {{ sprintContent.summary }}
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { Notification } from "@/models/Notification"
import NotificationSprintHeader from "../components/NotificationSprintHeader.vue"

@Options({
  name: "SPRINT_REVIEW_COMPLETED",
  components: {
    NotificationTemplate,
    NotificationSprintHeader,
  },
})
export default class SPRINT_REVIEW_COMPLETED extends Vue {
  @Prop() readonly notification: Notification

  get sprintContent() {
    return this.notification.content.sprint
  }

  get sprintContentHeader() {
    return Object.assign(this.sprintContent, {
      sprintSequenceNumber: this.sprintContent.sequenceNumber,
    })
  }
}
</script>
