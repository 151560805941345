<template>
  <div
    class="to-do__wrapper"
  >
    <transition
      @beforeLeave="slideFadeLeave"
      @beforeEnter="slideFadeEnter"
    >
      <ToDoView
        v-if="!addToDo"
        @addToDo="onShowAddToDo"
      />
    </transition>
    <transition
      @beforeLeave="slideFadeLeave"
      @beforeEnter="slideFadeEnter"
    >
      <ToDoAdd
        v-if="addToDo"
        @onCancel="onCloseAddAddToDo"
      />
    </transition>
    <button
      v-if="showCloseButton"
      class="to-do-widget__close"
      @click.prevent="onHideToDoWidget"
    >
      <icon
        data="@icon/close.svg"
        :fill="false"
        width="24px"
        height="24px"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import ToDoView from "@/components/ToDo/ToDoView.vue"
import ToDoAdd from "@/components/ToDo/ToDoAdd.vue"
import { slideAnimation } from "@/utils/gsapAnimation"
import { Emit, Prop } from "vue-property-decorator"
import { Getters } from "@/store/getters"
import { Action, Getter } from "s-vuex-class"
import { ToDo } from "@/models/ToDo"
import { Actions } from "@/store"
const components = {
  ToDoView,
  ToDoAdd,
}

@Options({
  name: "ToDoComponent",
  components,
})
export default class ToDoComponent extends Vue {
  @Getter(Getters.GET_TODO) readonly toDo: ToDo

  @Action(Actions.ADD_FILE_BY_ACCOUNT) addFileByAccount: (fileByAccount: boolean) => void

  @Prop({ default: false }) showCloseButton: boolean

  addToDo = false
  animationDuration = 0.3

  created() {
    this.addToDo = this.toDo.addMode
    this.addFileByAccount(true)
  }

  onShowAddToDo() {
    this.addToDo = true
  }

  onCloseAddAddToDo() {
    if (this.addToDo) {
      this.onHideToDoWidget()
      return
    }
    this.addToDo = false
  }

  slideFadeEnter(el) {
    slideAnimation(el).reverse(0).delay(this.animationDuration)
  }

  slideFadeLeave(el) {
    slideAnimation(el, this.animationDuration).play()
  }

  @Emit()
  onHideToDoWidget() {
    return
  }

  beforeUnmount() {
    this.addFileByAccount(false)
  }
}
</script>
