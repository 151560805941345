import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots } from "vue"
import _imports_0 from '@icon/more-vertical.svg'


const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    theme: _ctx.theme,
    placement: _ctx.placement,
    skidding: _ctx.skidding,
    distance: _ctx.distance,
    popperClass: _ctx.popperClass,
    triggers: _ctx.triggers,
    popperHideTriggers: _ctx.popperHideTriggers,
    popperShowTriggers: _ctx.popperShowTriggers,
    shown: _ctx.shown,
    class: _normalizeClass([_ctx.dropdownClass]),
    autoHide: _ctx.autoHide,
    disabled: _ctx.disabled,
    onApplyShow: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onShowDropdown'))),
    onApplyHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onHideDropdown')))
  }, {
    popper: _withCtx(({ hide }) => [
      _renderSlot(_ctx.$slots, "tooltip-content", { hide: hide }, () => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.subDropdownClass)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
            return (_openBlock(), _createBlock(_component_VDropdown, {
              key: index,
              theme: "info-dropdown",
              placement: _ctx.placementSub,
              skidding: _ctx.skiddingSub,
              distance: _ctx.distanceSub,
              popperClass: _ctx.popperClassSub,
              shown: _ctx.shownSub,
              triggers: _ctx.subTriggers,
              disabled: _ctx.disable || item.hideOptions,
              onApplyShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onShowSubDropdown'))),
              onApplyHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onHideSubDropdown')))
            }, _createSlots({
              popper: _withCtx(({ hide }) => [
                (item.options?.length === 0)
                  ? _renderSlot(_ctx.$slots, "tooltip-submenu-empty", {
                      key: 0,
                      item: item
                    }, () => [
                      _createTextVNode(_toDisplayString(_ctx.submenuEmptyTitle), 1)
                    ])
                  : _renderSlot(_ctx.$slots, "tooltip-subContent", {
                      key: 1,
                      hide: hide
                    }, () => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option, key) => {
                        return (_openBlock(), _createBlock(_component_BaseSubHeading, {
                          key: key,
                          size: "medium",
                          weight: "400",
                          class: _normalizeClass([_ctx.optionMenuClass, `option-menu_${item.id}`])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", {
                              id: option.id,
                              tabindex: "-1",
                              class: "option-menu_button",
                              onClick: ($event: any) => (_ctx.dropdownOptionMenu(option, hide, item))
                            }, [
                              _renderSlot(_ctx.$slots, "tooltip-submenu-item", {
                                option: option,
                                item: item,
                                hide: hide
                              }, () => [
                                _createTextVNode(_toDisplayString(option.label), 1)
                              ])
                            ], 8, _hoisted_3)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BaseSubHeading, {
                  size: "large",
                  weight: "400",
                  className: _ctx.subMenuClass
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createElementBlock("button", {
                      id: item.id,
                      tabindex: "-1",
                      class: "body-text body-text-medium-400 tooltip-submenu__button",
                      onClick: ($event: any) => (_ctx.dropdownSubMenu(item, hide))
                    }, [
                      _renderSlot(_ctx.$slots, "tooltip-item", { item: item }, () => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ])
                    ], 8, _hoisted_2)), [
                      [
                        _directive_close_popper,
                        void 0,
                        void 0,
                        { all: true }
                      ]
                    ])
                  ]),
                  _: 2
                }, 1032, ["className"])
              ]),
              _: 2
            }, [
              (_ctx.$wait.is(_ctx.submenuWaitTitle))
                ? {
                    name: "popper",
                    fn: _withCtx(() => [
                      _createTextVNode(" Loading... ")
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["placement", "skidding", "distance", "popperClass", "shown", "triggers", "disabled"]))
          }), 128))
        ], 2)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: _normalizeClass(["tooltip-dropdown__label", `icon-button__${_ctx.size}`]),
        tabindex: _ctx.tabIndex
      }, [
        _renderSlot(_ctx.$slots, "tooltip-label", {}, () => [
          _createVNode(_component_icon, {
            data: _imports_0,
            width: _ctx.mainIconSize,
            height: _ctx.mainIconSize,
            fill: false,
            color: "var(--monochrome05to04)"
          }, null, 8, ["width", "height"])
        ])
      ], 10, _hoisted_1)
    ]),
    _: 3
  }, 8, ["theme", "placement", "skidding", "distance", "popperClass", "triggers", "popperHideTriggers", "popperShowTriggers", "shown", "class", "autoHide", "disabled"]))
}