
import { Filter } from "@/models/Pageable"

export class FilterFeature extends Filter {
  q?: string
  goalId?: string
  inGoal?: boolean
  projectId?: string
  inProject?: boolean
  demo?: boolean

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.goalId = props.goalId ?? ""
    this.inGoal = props.inGoal ?? ""
    this.projectId = props.projectId ?? ""
    this.inProject = props.inProject ?? ""
    this.demo = props.demo
  }

  getJsonObj() {
    const data = {
      q: this.q,
      goalId: this.goalId,
      inGoal: this.inGoal,
      projectId: this.projectId,
      inProject: this.inProject,
      demo: this.demo,
    }

    return super.getJsonObj({ params: data })
  }

  get hasProjectFilter() {
    return !!this.projectId?.length
  }

  static create(props) {
    return new FilterFeature(props)
  }

  static createDefault() {
    const props = {}

    return new FilterFeature(props)
  }
}
