import { Module, Mutation, Action } from "vuex-class-modules"
import { store } from "../store"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { Modules } from "../modules"
import { User, UserSettings } from "@/models/User"
import { UpdateObjectValue } from "@/models/common"
import { FilterUser } from "@/models/Filter/FilterUser"
import { ServerResponse } from "@/models/ServerResponse"
import { BaseModule } from "@/models/BaseModule"
import { AccountActions, accountModule } from "./account.module"
import { sessionModule } from "./session.module"
import { AlertActions, alertModule } from "./alert.module"
import { AlertType, AlertContentItem } from "@/models/Alert"
import { $t } from "@/plugins/i18n"
import { Account } from "@/models/Account"
import { parsingServerResponseContent } from "@/utils/helpers"

export enum UserActions {
  GET_USERS = "GET_USERS",
  RESET_USERS = "RESET_USERS",
  GET_USER_CURRENT = "GET_USER_CURRENT",
  GET_USER_CURRENT_PROFILE = "GET_USER_CURRENT_PROFILE",
  GET_USER_BY_ID = "GET_USER_BY_ID",
  UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
  UPDATE_CURRENT_USER_ROLE = "UPDATE_CURRENT_USER_ROLE",
  UPDATE_CURRENT_USER_VALUE = "UPDATE_CURRENT_USER_VALUE",
  POST_SELF_PICTURE = "POST_SELF_PICTURE",
  DELETE_SELF_PICTURE = "DELETE_SELF_PICTURE",
  GET_USER_ACCOUNT_OVERVIEW = "GET_USER_ACCOUNT_OVERVIEW",
  GET_USER_CURRENT_OVERVIEW = "GET_USER_CURRENT_OVERVIEW",
  UPDATE_PROFILE_SETTINGS = "UPDATE_PROFILE_SETTINGS",
  GET_USER_CURRENT_PROFILE_SETTINGS = "GET_USER_CURRENT_PROFILE_SETTINGS",
  USER_REMOVE_FROM_ACCOUNT_SUPER_ADMIN = "USER_REMOVE_FROM_ACCOUNT_SUPER_ADMIN",
  USER_REMOVE_FROM_ACCOUNT_ADMIN = "USER_REMOVE_FROM_ACCOUNT_ADMIN",
  UPDATE_ACCOUNT_URL_NAME_IN_CURRENT_PROFILE = "UPDATE_ACCOUNT_URL_NAME_IN_CURRENT_PROFILE",
  REMOVE_USER_FROM_LIST = "REMOVE_USER_FROM_LIST",
  THEME_IS_DARK = "THEME_IS_DARK",
}

@Module
class UserModule extends BaseModule {
  users: User[] = []
  user: User
  userProfile: User

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${UserActions.GET_USER_CURRENT}`]() {
    return this.user
  }

  get [`get/${UserActions.GET_USERS}`]() {
    return this.users
  }

  get [`get/${UserActions.GET_USER_CURRENT_PROFILE}`]() {
    return this.userProfile
  }

  get [`get/${UserActions.GET_USER_CURRENT_PROFILE_SETTINGS}`]() {
    return this.userProfile.settings
  }

  get [`get/${UserActions.THEME_IS_DARK}`]() {
    const isDark = !!this.userProfile.settings?.darkTheme
    if (!isDark) {
      document.body.classList.remove("dark")
      document.querySelector(":root")?.classList.remove("dark")
    } else {
      document.body.classList.add("dark")
      document.querySelector(":root")?.classList.add("dark")
    }
    return isDark
  }

  @Mutation
  setUserProfile(userProfile: User) {
    this.userProfile = userProfile
  }

  @Mutation
  setUserValue(payload: UpdateObjectValue<User>) {
    Object.assign(this.userProfile, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  updateUserSettings(settings: UserSettings) {
    this.userProfile.settings = { ...this.userProfile.settings, ...settings }
  }

  @Mutation
  setUsers(serverResponse: ServerResponse<User>) {
    this.users = parsingServerResponseContent({
      originalContent: this.users,
      serverResponseContent: serverResponse.content,
      numberOfPage: serverResponse.pageable.pageNumber,
    })
  }

  @Mutation
  resetUsers() {
    this.users = []
  }

  @Mutation
  removeUserFromList(userId: string) {
    this.users = this.users.filter(e => e.id !== userId)
  }

  @Mutation
  setUser(user: User) {
    this.user = user
  }

  @Mutation
  setNewAccountUrlName(account: Account) {
    if (account.id !== this.userProfile.accountId) return

    const updateAccounts = this.userProfile.accounts.map((e) => {
      if (e.id === account.id && account.urlName !== e.urlName) {
        e.urlName = account.urlName
      }
      return e
    })
    userModule[UserActions.UPDATE_CURRENT_USER_VALUE]({
      id: "accounts",
      value: updateAccounts,
    } as any)
  }


  @Mutation
  setUserPictureUrl({ pictureUrl }: { pictureUrl: string }) {
    this.userProfile.picture = this.userProfile.picture || {}
    this.userProfile.picture.pictureUrl = pictureUrl
    this.userProfile.pictureUrl = pictureUrl
  }


  @Action
  async [UserActions.GET_USERS](params: FilterUser) {
    const filter = FilterUser.create(params)
    const endpoint = filter.extended ? Endpoint.GET_USERS_EXTENDED(this.accountId) : Endpoint.GET_USERS(this.accountId)

    const data = await apiService.get<ServerResponse<User>>(endpoint, filter.getJsonObj())
    return this.handleResponse<ServerResponse<User>>(data, (data) => {
      const content: User[] = data.content.map(c => User.create(c))
      data = { ...data, content }
      if (filter.forSearch) {
        return data
      }
      this.setUsers(data)
      return data
    })
  }

  @Action
  async [UserActions.GET_USER_CURRENT]() {
    const data = await apiService.get<User>(Endpoint.USER_CURRENT())
    return this.handleResponse<User>(data, (data) => {
      this.setUser(User.create(data))
    })
  }

  @Action
  async [UserActions.GET_USER_BY_ID](id: string) {
    const data = await apiService.get<User>(Endpoint.GET_USER_BY_ID(id))
    return this.handleResponse<User>(data, (data) => {
      this.setUser(User.create(data))
    })
  }

  @Action
  async [UserActions.GET_USER_CURRENT_PROFILE]() {
    const data = await apiService.get<User>(Endpoint.USER_CURRENT_PROFILE())
    return this.handleResponse<User>(data, (data) => {
      const userProfile = User.create(data)
      this.setUserProfile(userProfile)
      accountModule.setAccountOverview(userProfile)
      sessionModule.setAccountOverview(userProfile)
    })
  }

  @Action
  async [UserActions.UPDATE_CURRENT_USER](user: User) {
    const data = await apiService.patch<User>(Endpoint.USER_CURRENT(), user)
    return this.handleResponse<User>(data)
  }

  @Action
  async [UserActions.UPDATE_CURRENT_USER_ROLE](role: string) {
    const data = await apiService.patch<User>(Endpoint.USER_CURRENT_ROLE(role))
    return this.handleResponse<User>(data)
  }

  @Action
  async [UserActions.POST_SELF_PICTURE](picture: Blob) {
    const file = new FormData()
    file.append("file", picture)
    const data = await apiService.post<string>(Endpoint.USER_CURRENT_PICTURE(), file)
    return this.handleResponse<string>(data, (data) => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User Picture",
          type: "bold",
        },
        {
          text: " updated",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      this.setUserPictureUrl({
        pictureUrl: data,
      })
    })
  }

  @Action
  async [UserActions.DELETE_SELF_PICTURE]() {
    const data = await apiService.delete<User>(Endpoint.USER_CURRENT_PICTURE())
    return this.handleResponse<User>(data)
  }

  @Action
  async [UserActions.UPDATE_PROFILE_SETTINGS]({ settings, immediate = false } : { settings: UserSettings, immediate?: boolean }) {
    if (immediate) this.updateUserSettings(settings)
    const data = await apiService.put<UserSettings>(Endpoint.UPDATE_PROFILE_SETTINGS(), settings)
    return this.handleResponse<UserSettings>(data, (data) => {
      if (!immediate) this.updateUserSettings(data)
      return data
    })
  }

  @Action
  [UserActions.UPDATE_CURRENT_USER_VALUE](payload: UpdateObjectValue<User>) {
    this.setUserValue(payload)
  }

  @Action
  [UserActions.UPDATE_ACCOUNT_URL_NAME_IN_CURRENT_PROFILE](account: Account) {
    this.setNewAccountUrlName(account)
  }

  @Action
  [UserActions.RESET_USERS]() {
    this.resetUsers()
  }

  @Action
  [UserActions.REMOVE_USER_FROM_LIST](userId: string) {
    this.removeUserFromList(userId)
  }


  @Action
  async [UserActions.USER_REMOVE_FROM_ACCOUNT_SUPER_ADMIN]({ userId, accountId }) {
    const data = await apiService.delete<void>(Endpoint.SUPERADMIN_USER_BY_ID(accountId, userId))
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User",
          type: "bold",
        },
        {
          text: " deleted",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }

  @Action
  async [UserActions.USER_REMOVE_FROM_ACCOUNT_ADMIN]({ userId, accountId }) {
    const data = await apiService.delete<void>(Endpoint.ADMIN_USER_BY_ID(accountId, userId))
    return this.handleResponse<void>(data, () => {
      const alertContent: AlertContentItem[] = [
        {
          text: "User",
          type: "bold",
        },
        {
          text: " deleted",
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
    })
  }
}

export const userModule = new UserModule({ store, name: Modules.USER })
