import { Filter } from "@/models/Pageable"
import { ToDoRelatedResourceId, ToDoStatus } from "@/models/ToDo"

export class FilterToDo extends Filter {
  completedByMe?: boolean = undefined
  createdByMe?: boolean = undefined
  q?: string | string = undefined
  relatedResourceId?: ToDoRelatedResourceId = undefined
  status?: ToDoStatus = undefined

  constructor(props?: Partial<FilterToDo>) {
    super(props as FilterToDo)
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
  getJsonObj() {
    const data = {
      completedByMe: this.completedByMe,
      createdByMe: this.createdByMe,
      q: this.q,
      relatedResourceId: this.relatedResourceId,
      status: this.status,
    }
    return super.getJsonObj({ params: data })
  }
}

export class FilterToDoCount {
  completedByMe?: boolean = undefined
  createdByMe?: boolean = undefined
  q?: string | string = undefined
  relatedResourceId?: ToDoRelatedResourceId = undefined
  status?: ToDoStatus = undefined

  constructor(props?: Partial<FilterToDoCount>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
  getJsonObj() {
    return {
      completedByMe: this.completedByMe,
      createdByMe: this.createdByMe,
      q: this.q,
      relatedResourceId: this.relatedResourceId,
      status: this.status,
    }
  }
}
