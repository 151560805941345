import { ResourceType } from "@/models/common"
import {
  ItemStatus,
  ItemType,
  Picture,
  TaskType,
  User,
} from "@/models"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { formatDate } from "@/utils/dateUtil"
import { Team } from "project-simple-server-open-api/client/models/Team"

export interface SearchResult {
  id: string,
  resourceType: ResourceType,
  textPreview: string,
  type: ReferenceType
}

export interface SearchItemResult {
  id: string,
  title: string,
  itemType: ItemType
  sequenceNumber: number,
  status: ItemStatus
  projectId: string;
}
export interface SearchProjectResult {
  id: string,
  name: string,
  urlName: string
}

export class SearchSprintResult {
  id = ""
  startDate = ""
  endDate = ""
  projectId = ""
  sequenceNumber = ""
  status = ""
  team?: Team
  constructor(props?: Partial<SearchSprintResult>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  get formatedDateStart() {
    return this.startDate ? formatDate(new Date(this.startDate), "dd MMM") : ""
  }

  get formatedDateEnd() {
    return this.endDate ? formatDate(new Date(this.endDate), "dd MMM") : ""
  }
}

export interface SearchTeamResult {
  id: string,
  name: string,
  size: number,
  urlName: string
  picture: Picture;
}
export interface SearchMilestoneResult {
  id: string;
  title: string;
  endDate: string;
  projectId: string;
  projectName: string;
}

export interface SearchEpicResult {
  id: string;
  name: string;
  percentComplete: number;
  sequenceNumber: number;
  projectId: string;
  projectName: string;
}

export interface SearchTaskResult {
  id: string;
  name: string;
  order:number;
  type: TaskType;
}

export interface SearchPayload {
  text: string,
  resourceTypes: ResourceType,
  projectId?: string,
  includeDemo?: boolean
}

export interface ReferencedResourcePreviews {
  epics: SearchEpicResult[]
  items: SearchItemResult[]
  milestones: SearchMilestoneResult[]
  projects: SearchProjectResult[]
  sprints: SearchSprintResult[]
  users: User[]
  tasks: SearchTaskResult[]
}
