import { ToDo } from "./ToDo"

export type PokerProps = Partial<Poker>

export class PokerEstimation {
  dev: number | null
  qa: number | null
}
export class Poker {
  itemId: string
  teamId: string
  id: string
  estimations: { [id: string] : PokerEstimation }
  asyncMode: boolean
  demo: boolean

  private constructor() {}

  static create(): Poker {
    return new Poker()
  }
}

export class PokerCreateRequest {
  accountId: string
  itemId: string
  teamId: string
  id: string
  estimatedDeadline: Date
  asyncMode: boolean
  demo: boolean
  createTodoItemRequest?: ToDo

  constructor(props: Partial<PokerCreateRequest>) {
    Object.assign(this, props)
  }

  getJsonObj() {
    return {
      accountId: this.accountId,
      itemId: this.itemId,
      teamId: this.teamId,
      id: this.id,
      estimatedDeadline: this.estimatedDeadline,
      asyncMode: this.asyncMode ?? false,
      createTodoItemRequest: this.createTodoItemRequest ? this.createTodoItemRequest.getJsonObj() : {},
    }
  }
}
