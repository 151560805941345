import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = {
  key: 1,
  class: "link"
}
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = ["tabindex"]
const _hoisted_5 = {
  ref: "container",
  class: "avatar-select__list"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_AvatarGroupWithMenu = _resolveComponent("AvatarGroupWithMenu")!
  const _component_AvatarGroupTask = _resolveComponent("AvatarGroupTask")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BorderedInput = _resolveComponent("BorderedInput")!
  const _component_UserComponent = _resolveComponent("UserComponent")!
  const _component_DropdownTooltip = _resolveComponent("DropdownTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar-select", [`is-${_ctx.size}`, `is-${_ctx.dropdownPosition}`]])
  }, [
    _createVNode(_component_DropdownTooltip, {
      placement: undefined,
      shown: _ctx.opened,
      triggers: [],
      tabIndex: -1,
      popperClass: "avatar-select__dropdown",
      iconSize: "md",
      onOnHideDropdown: _ctx.hideDropDown,
      onOnShowDropdown: _ctx.onShowDropdown
    }, {
      "tooltip-label": _withCtx(() => [
        _createElementVNode("button", {
          ref: "avatarPlaceholder",
          class: "avatar-select__placeholder add-custom-outline",
          type: "button",
          tabindex: _ctx.tabindex,
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]))
        }, [
          (_ctx.editItem)
            ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                key: 0,
                tabindex: "-1",
                size: "large",
                weight: "400",
                class: "avatar-select_label"
              }, {
                default: _withCtx(() => [
                  (_ctx.selectedUser)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass({'avatar-select_label-disabled': !_ctx.selectedUser.enabled})
                      }, _toDisplayString(_ctx.selectedUser.username), 3))
                    : (_openBlock(), _createElementBlock("span", _hoisted_2, "Assign Owner"))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.avatarGroup)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                tabindex: _ctx.tabindex,
                onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.toggle()), ["enter"]))
              }, [
                (_ctx.showUserMenu)
                  ? (_openBlock(), _createBlock(_component_AvatarGroupWithMenu, {
                      key: 0,
                      avatars: _ctx.avatars,
                      type: "avatarGroup",
                      size: "md",
                      onOnToggle: _ctx.onToggleAvatarMenu
                    }, null, 8, ["avatars", "onOnToggle"]))
                  : (_openBlock(), _createBlock(_component_AvatarGroupTask, {
                      key: 1,
                      avatars: _ctx.avatars,
                      user: true,
                      type: "avatarGroup",
                      size: "md"
                    }, null, 8, ["avatars"]))
              ], 40, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                tabindex: _ctx.tabindex,
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.toggle()), ["enter"]))
              }, [
                _createVNode(_component_Avatar, {
                  src: _ctx.selectedUser?.pictureUrl ?? null,
                  userEnabled: _ctx.selectedUser ? _ctx.selectedUser.enabled : true,
                  gap: _ctx.selectedUser?.getInitials(),
                  size: _ctx.size,
                  type: "avatarGroup",
                  class: _normalizeClass({'avatar-select__gap' : !_ctx.selectedUser})
                }, null, 8, ["src", "userEnabled", "gap", "size", "class"])
              ], 40, _hoisted_4))
        ], 8, _hoisted_1)
      ]),
      "tooltip-content": _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onKeydown: [
                _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hideDropDown && _ctx.hideDropDown(...args)), ["esc"])),
                _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSelectHighlighted && _ctx.onSelectHighlighted(...args)), ["enter"])),
                _cache[6] || (_cache[6] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onSelectHighlighted && _ctx.onSelectHighlighted(...args)), ["exact","stop"]), ["tab"]))
              ]
            }, [
              _createVNode(_component_BorderedInput, {
                id: "search",
                className: "avatar-select__input custom-outline",
                modelValue: _ctx.search,
                type: "text",
                placeholder: "Search by Name",
                tooltip: false,
                autocomplete: false,
                tabindex: -1,
                onWrapEvent: true,
                triggerFocus: _ctx.triggerFocus,
                onOnFocus: _ctx.onInputFocus,
                "onUpdate:modelValue": _ctx.handleInput
              }, null, 8, ["modelValue", "triggerFocus", "onOnFocus", "onUpdate:modelValue"]),
              _createElementVNode("ul", _hoisted_5, [
                (_ctx.selectedUser)
                  ? (_openBlock(), _createElementBlock("li", {
                      key: 0,
                      ref: `user-${_ctx.id}-first`,
                      class: _normalizeClass(["avatar-select__item custom-outline", [{
                'is-selected': _ctx.selectedUser === null,
                'active-item': _ctx.currentItem === -1
              }]])
                    }, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "avatar-select__button",
                        tabindex: -1,
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onUserSelect(null)), ["prevent"]))
                      }, [
                        _createVNode(_component_UserComponent, { user: null })
                      ])
                    ], 2))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    ref_for: true,
                    ref: `user-${_ctx.id}-${index}`,
                    class: _normalizeClass(["avatar-select__item custom-outline", [{
                'is-selected': _ctx.selectedUser && _ctx.selectedUser.id === item.id,
                'active-item': _ctx.currentItem === index
              }]])
                  }, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "avatar-select__button",
                      tabindex: -1,
                      onClick: _withModifiers(($event: any) => (_ctx.onUserSelect(item)), ["prevent"])
                    }, [
                      _createVNode(_component_UserComponent, { user: item }, null, 8, ["user"])
                    ], 8, _hoisted_6)
                  ], 2))
                }), 128))
              ], 512)
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["shown", "onOnHideDropdown", "onOnShowDropdown"])
  ], 2))
}