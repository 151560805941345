import { Filter } from "@/models/Pageable"

export class FilterProject extends Filter {
  q: string
  accountId: string
  teamId: string
  userId: string
  includeDemo: boolean
  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.accountId = props.accountId ?? ""
    this.teamId = props.teamId ?? ""
    this.userId = props.userId ?? ""
    this.includeDemo = props.includeDemo ?? false
  }

  getJsonObj() {
    const data = {
      q: this.q,
      accountId: this.accountId,
      includeDemo: this.includeDemo,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterProject(props)
  }

  static createDefault() {
    const props = {
      q: "",
      accountId: "",
      teamId: "",
      userId: "",
    }

    return new FilterProject(props)
  }
}
