import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "attachment-component__content" }
const _hoisted_2 = {
  class: "attachment-component__drag-handle",
  contenteditable: "false",
  draggable: "true",
  "data-drag-handle": ""
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["data-fancybox", "href"]
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!
  const _component_VueDraggableResizable = _resolveComponent("VueDraggableResizable")!
  const _component_VideoAttachmentItem = _resolveComponent("VideoAttachmentItem")!
  const _component_FileItem = _resolveComponent("FileItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["attachment-component", [`attachment-component_${_ctx.attachmentType}`]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isImage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "attachment-component__content-in",
            style: _normalizeStyle({width: _ctx.width+'px', height: _ctx.height+'px'})
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: require('@/assets/images/icons/drag.svg'),
                width: "10",
                height: "10"
              }, null, 8, _hoisted_3)
            ]),
            (_ctx.node?.attrs?.isSkeletor)
              ? (_openBlock(), _createBlock(_component_Skeletor, {
                  key: 0,
                  width: `${_ctx.width}px`,
                  height: `${_ctx.height}px`
                }, null, 8, ["width", "height"]))
              : (_ctx.width && _ctx.height)
                ? (_openBlock(), _createBlock(_component_VueDraggableResizable, {
                    key: 1,
                    ref: 'resizable_'+_ctx.attachmentId,
                    w: _ctx.width,
                    h: _ctx.height,
                    draggable: false,
                    handles: ['br'],
                    parent: false,
                    lockAspectRatio: true,
                    maxWidth: _ctx.maxWidth,
                    maxHeight: _ctx.maxHeight,
                    resizable: _ctx.resizable,
                    minWidth: 100,
                    minHeight: 100,
                    onResizing: _ctx.onResize
                  }, {
                    default: _withCtx(() => [
                      (_ctx.blobUrl)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "attachment-component__link",
                            tabindex: -1,
                            "data-fancybox": `editor_fancybox_${_ctx.projectId}`,
                            href: _ctx.blobUrl
                          }, [
                            _createElementVNode("img", {
                              class: "attachment-component__img",
                              src: _ctx.blobUrl,
                              alt: _ctx.attachmentName,
                              style: {"user-select":"none"}
                            }, null, 8, _hoisted_5)
                          ], 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["w", "h", "maxWidth", "maxHeight", "resizable", "onResizing"]))
                : _createCommentVNode("", true)
          ], 4))
        : (_ctx.isVideo)
          ? (_openBlock(), _createBlock(_component_VideoAttachmentItem, {
              key: 1,
              file: _ctx.initFileInstance,
              videoPath: _ctx.currentVideoUrl,
              fancyboxData: `editor_fancybox_${_ctx.projectId}`
            }, null, 8, ["file", "videoPath", "fancyboxData"]))
          : (_ctx.isFile)
            ? (_openBlock(), _createBlock(_component_FileItem, {
                key: _ctx.attachmentId,
                file: _ctx.initFileInstance,
                fileMarkup: "line",
                class: "custom-file_reference",
                removeButton: true
              }, null, 8, ["file"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "attachment-component__link",
                tabindex: -1,
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDownload && _ctx.onDownload(...args)), ["prevent"]))
              }, _toDisplayString(_ctx.attachmentName), 1))
    ])
  ], 2))
}