import { UniqActions } from "@/models/UniqActions"
import { Modules } from "./modules"
import { getActionsMap } from "@/utils/helpers"
import {
  AccountActions,
  AlertActions,
  BacklogActions,
  BacklogMetricsActions,
  CommentActions,
  DefinitionOfDoneActions,
  EpicActions,
  FileActions,
  InvitationActions,
  ItemActions,
  ItemStatusActions,
  ItemTypeActions,
  LoginActions,
  MilestoneActions,
  PaymentActions,
  PictureActions,
  PokerActions,
  ProjectActions,
  RetrospectiveActions,
  SprintActions,
  SubscriptionActions,
  PostMortemActions,
  EmailTemplateActions,
  TaskActions,
  TeamActions,
  ToDoActions,
  UserActions,
  UserManagementActions,
  LogActions,
  SessionActions,
  SearchActions,
  TabsActions,
  KeyboardNavigationActions,
  NotificationActions,
  GoalActions,
  FeatureActions,
  EmailVerificationActions,
  ChatGPTActions,
  HealthCheckActions,
  JiraImportActions,
} from "./modules/"

export type TypeActions = {
  [key in UniqActions]: string;
}

export const Actions = {
  ...getActionsMap(Modules.PROJECT, ProjectActions as TypeActions),
  ...getActionsMap(Modules.USER, UserActions as TypeActions),
  ...getActionsMap(Modules.USER_MANAGEMENT, UserManagementActions as TypeActions),
  ...getActionsMap(Modules.ACCOUNT, AccountActions as TypeActions),
  ...getActionsMap(Modules.PAYMENT, PaymentActions as TypeActions),
  ...getActionsMap(Modules.ALERT, AlertActions as TypeActions),
  ...getActionsMap(Modules.COMMENT, CommentActions as TypeActions),
  ...getActionsMap(Modules.ITEM, ItemActions as TypeActions),
  ...getActionsMap(Modules.ITEM_STATUS, ItemStatusActions as TypeActions),
  ...getActionsMap(Modules.ITEM_TYPE, ItemTypeActions as TypeActions),
  ...getActionsMap(Modules.DEFINITION_OF_DONE, DefinitionOfDoneActions as TypeActions),
  ...getActionsMap(Modules.FILE, FileActions as TypeActions),
  ...getActionsMap(Modules.TASK, TaskActions as TypeActions),
  ...getActionsMap(Modules.TEAM, TeamActions as TypeActions),
  ...getActionsMap(Modules.INVITATION, InvitationActions as TypeActions),
  ...getActionsMap(Modules.PICTURE, PictureActions as TypeActions),
  ...getActionsMap(Modules.BACKLOG, BacklogActions as TypeActions),
  ...getActionsMap(Modules.EPIC, EpicActions as TypeActions),
  ...getActionsMap(Modules.SPRINT, SprintActions as TypeActions),
  ...getActionsMap(Modules.POKER, PokerActions as TypeActions),
  ...getActionsMap(Modules.MILESTONE, MilestoneActions as TypeActions),
  ...getActionsMap(Modules.TODO, ToDoActions as TypeActions),
  ...getActionsMap(Modules.BACKLOG_METRICS, BacklogMetricsActions as TypeActions),
  ...getActionsMap(Modules.LOGIN, LoginActions as TypeActions),
  ...getActionsMap(Modules.RETROSPECTIVE, RetrospectiveActions as TypeActions),
  ...getActionsMap(Modules.SUBSCRIPTION, SubscriptionActions as TypeActions),
  ...getActionsMap(Modules.POSTMORTEM, PostMortemActions as TypeActions),
  ...getActionsMap(Modules.EMAIL_TEMPLATE, EmailTemplateActions as TypeActions),
  ...getActionsMap(Modules.SEARCH, SearchActions as TypeActions),
  ...getActionsMap(Modules.LOG, LogActions as TypeActions),
  ...getActionsMap(Modules.SESSION, SessionActions as TypeActions),
  ...getActionsMap(Modules.TABS, TabsActions as TypeActions),
  ...getActionsMap(Modules.KEYBOARD_NAVIGATION, KeyboardNavigationActions as TypeActions),
  ...getActionsMap(Modules.NOTIFICATION, NotificationActions as TypeActions),
  ...getActionsMap(Modules.GOAL, GoalActions as TypeActions),
  ...getActionsMap(Modules.FEATURE, FeatureActions as TypeActions),
  ...getActionsMap(Modules.EMAIL_VERIFICATION, EmailVerificationActions as TypeActions),
  ...getActionsMap(Modules.CHAT_GPT, ChatGPTActions as TypeActions),
  ...getActionsMap(Modules.HEALTH_CHECK, HealthCheckActions as TypeActions),
  ...getActionsMap(Modules.JIRA_IMPORT, JiraImportActions as TypeActions),

} as TypeActions
