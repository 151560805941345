import {
  SubscriptionActions,
  subscriptionModule,
} from "@/store/modules/subscription.module"

export class ServerSendEventHandler {
  type: string

  constructor(type: string) {
    this.type = type
  }

  callback(event: MessageEvent) {
    if (event.data && this.validate(event.data)) {
      subscriptionModule[SubscriptionActions.SUBSCRIPTION_MESSAGE](this.parseMessage(event.data))
    }
  }

  validate(str: string) {
    try {
      JSON.parse(str)
    } catch {
      return false
    }
    return true
  }

  parseMessage(data: string) {
    return JSON.parse(data)
  }
}
