<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-orange">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
          color="#F57C00"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Support Case {{ wasSupportCaseFromActiveSprint ? 'was added to Active Sprint' : 'Created' }}
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__unprioritized-add-item notification__item-content"
      >
        <span class="notification__item-content__icon">
          <SeverityLevelIcon :level="notification.getItemValue('severityLevel')" />
        </span>
        <a
          class="link is-medium-500"
          target="_blank"
          :href="notification.itemUrl"
        >
          {{ notification.itemSequenceNumber }}
        </a>
        <span class="notification__unprioritized-add-item__title notification__item-content__title">
          {{ notification.getItemValue('title') }}
        </span>
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"
import SeverityLevelIcon from "@/components/SeverityLevel/SeverityLevelIcon.vue"

@Options({
  name: "SUPPORT_CASE_CREATED",
  components: {
    NotificationTemplate,
    SeverityLevelIcon,
  },
})
export default class SUPPORT_CASE_CREATED extends Vue {
  @Prop() readonly notification: NotificationItem

  get wasSupportCaseFromActiveSprint() {
    return this.notification.content?.addedOrRemovedFromActiveSprint ?? false
  }
}
</script>
