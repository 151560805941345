import { JSONContent } from "@tiptap/vue-3"
import { AppContext, h, render } from "vue"
import { Base } from "./Base"
import { Context } from "../services/decorator.service"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { replaceHtmlTagsV2 } from "@/utils/helpers"

interface Refer {
  id: string;
  type: ReferenceType;
  input: string;
  attrs: any;
}

interface Attachment {
  id: string;
  type: AttachmentType;
  input: string;
  attrs: any;
}

type AttachmentType = "ATTACHMENT";

@Context
export class EditorData extends Base<EditorData> {
  $context: AppContext
  text: string
  json: JSONContent
  html: string
  refers: Refer[] = []
  attachments: Attachment[] = []
  mapComponent?: { [K in ReferenceType]?: any }
  constructor(mapComponent?: { [K in ReferenceType | AttachmentType]?: any }) {
    super()
    this.mapComponent = mapComponent
  }

  setText(str: string) {
    this.text = str
  }

  setJson(json: JSONContent) {
    this.json = json
  }

  setHtml(html: string) {
    this.html = html
    this.refers = []
    this.parseRefers(html)
    this.parseAttachments(html)
  }

  getHtmlElement() {
    const htmlText = this.html
    const textEl = document.createElement("span")
    textEl.innerHTML = htmlText
    if (this.mapComponent && (this.refers?.length || this.attachments?.length)) {
      [
        ...this.refers,
        ...this.attachments,
      ].forEach(({ attrs, input, type }) => {
        if (this.mapComponent && this.mapComponent[type]) {
          const el = document.createElement("span")
          const nodeApp = h(this.mapComponent[type] || "span", {
            node: { attrs },
          })
          nodeApp.appContext = this.$context
          render(nodeApp, el)
          const id = input.split("id=\"")[1]?.split("\"")[0]
          const refs = textEl.querySelectorAll("reference")
          refs.forEach((_el) => {
            if (id === _el.id) {
              _el.replaceWith(el)
            }
          })
        }
      })
    }
    return textEl
  }

  getEmailText() {
    const origin = window.location.origin
    const htmlText = this.html
    const textEl = document.createElement("span")
    textEl.innerHTML = htmlText
    if (this.refers?.length || this.attachments?.length) {
      [
        ...this.refers,
        ...this.attachments,
      ].forEach(({ attrs, input, type }) => {
        const id = input.split("id=\"")[1]?.split("\"")[0]
        const refs = textEl.querySelectorAll("reference")
        const el = document.createElement("a")
        el.href = type !== ReferenceType.USER ? origin + attrs.linkHref : attrs.linkHref
        el.textContent = attrs.linkText
        refs.forEach((_el) => {
          if (id === _el.id) {
            _el.replaceWith(el)
          }
        })
      })

      let html = textEl.innerHTML
      html = html.replace(/<\/a>\s+/g, "</a>")
      html = html.replace(/<\/a>(?!&nbsp;)/g, "</a>&nbsp;")
      textEl.innerHTML = html
    }
    return textEl.outerHTML.slice(6).slice(0, -7)
  }

  updateHtmlFromRefers(id: string, update: { [key: string]: any }) {
    if (this.refers?.length) {
      const textEl = document.createElement("span")
      textEl.innerHTML = this.html
      this.refers.forEach(({ attrs }, i) => {
        if (!attrs?.info || !Object.keys(attrs.info).length) return
        const infoObj = JSON.parse(attrs.info)

        if (id !== infoObj.id) return

        Object.keys(update).forEach((key) => {
          if (key === "title") {
            const title = infoObj[key]
            attrs.linkText = attrs.linkText.replace(title, update[key])
          }
          infoObj[key] = update[key]
        })
        attrs.info = JSON.stringify(infoObj)
        const el = document.createElement("reference")
        el.setAttribute("id", attrs.id)
        el.setAttribute("loading", attrs.loading)
        el.setAttribute("showDetails", attrs.showDetails)
        el.setAttribute("type", attrs.type)
        el.setAttribute("configtype", attrs.configType)
        el.setAttribute("info", JSON.stringify(infoObj))
        el.setAttribute("linkText", attrs.linkText)
        el.setAttribute("linkHref", attrs.linkHref)
        el.setAttribute("originUrl", attrs.originUrl)
        el.setAttribute("data", window.btoa(encodeURI(JSON.stringify(attrs))))

        this.refers[i].input = el.outerHTML

        const refs = textEl.querySelectorAll("reference")
        refs.forEach((_el) => {
          if (attrs.id === _el.id) {
            _el.replaceWith(el)
          }
        })
        this.html = textEl.outerHTML.slice(6).slice(0, -7)
      })
    }
  }

  getHtmlText() {
    return this.getHtmlElement().outerHTML.slice(6).slice(0, -7)
  }

  private parseRefers(html: string) {
    const refs = html.match(/<reference.+?reference>/g)
    if (refs?.length) {
      this.refers = refs.map((ref) => {
        const data = ref.match(/data="([^"]+)"/)?.at(-1)
        const attrs = data ? JSON.parse(decodeURI(window.atob(data)).replace(/&quot;/g, "\"")) : {}
        const info = attrs?.info || {}
        return {
          id: attrs.id,
          type: attrs.configType,
          attrs: { ...attrs, info },
          input: ref,
        }
      })
    }
  }

  private parseAttachments(html: string) {
    const attachments = html.match(/<vue-component.+?vue-component>/g)
    if (attachments?.length) {
      this.attachments = attachments.map((att) => {
        const data = att.split("data=\"")[1]?.split("\"")?.[0]
        const attrs = data ? JSON.parse(decodeURI(window.atob(data))) : {}
        const title = attrs?.title || ""
        return {
          id: attrs.id,
          type: "ATTACHMENT",
          attrs,
          input: att,
          title,
        }
      })
    }
  }

  getAdditionalData(refer: Refer) {
    refer.id = ""
  }
}

export class StructuredDescription {
  text = ""
  html = ""
  json = ""

  constructor(props?: Partial<StructuredDescription>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export class StructuredDescriptionHelper extends StructuredDescription {
  constructor(props?: Partial<StructuredDescription>) {
    super(props)
  }

  get getTextFromHtml() {
    return replaceHtmlTagsV2(this.html)
  }
}
