import { Options, Vue } from "vue-class-component"
import { hideAllPoppers } from "floating-vue"

@Options({
  name: "handleScrollOnElement",
  directives: {
    scroll: {
      mounted(el, binding) {
        const f = function (evt) {
          if (binding.value(evt, el)) {
            el.removeEventListener("scroll", f)
          }
        }
        el.addEventListener("scroll", f)
      },
    },
  },
})

export class handleScrollOnElement extends Vue {
  handleScroll() {
    hideAllPoppers()
  }
}
