import { Node } from '@tiptap/core';

export const CustomHeader = Node.create({
  name: 'customHeader',
  group: 'block',
  content: "inline*",
  parseHTML() {
    return [{ tag: 'header' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['header', HTMLAttributes, 0]
  }
});
