import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_FileItem = _resolveComponent("FileItem")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.list,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    onOnClick: _ctx.onFileSelected
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        weight: "600",
        size: "medium",
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Files ")
        ]),
        _: 1
      })
    ]),
    item: _withCtx(({ option }) => [
      (_openBlock(), _createBlock(_component_FileItem, {
        key: option.id,
        file: option,
        preventShow: true,
        fileMarkup: "element",
        removeButton: false
      }, null, 8, ["file"]))
    ]),
    _: 1
  }, 8, ["searchList", "componentType", "selectedIndex", "onOnClick"]))
}