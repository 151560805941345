<template>
  <img
    referrerpolicy="no-referrer"
    :src="filterUrl"
    :alt="alt"
  >
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Options } from "vue-class-component"
import { Vue } from "vue-class-component"
@Options({
  name: "ImageComponent",
})
export default class Avatar extends Vue {
  @Prop({ default: "" }) readonly src: string
  @Prop() readonly alt: string
  get filterUrl() {
    if (this.src && (this.src.includes("http") || this.src.includes("data"))) {
      return this.src
    }
    return ""
  }
}
</script>
