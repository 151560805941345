<!-- TODO: fix multiselect -->
<template>
  <component
    :is="parent"
    :ref="`multiselect-parent-${id}`"
    :type="isButton ? 'button' : null"
    :tabindex="tabindex"
    class="dropdown-basic"
    @click.prevent.stop.enter="onEnter"
  >
    <Multiselect
      :id="id"
      :ref="`multiselect-${id}`"
      :class="[`is-position-${optionPosition}`]"
      :modelValue="value"
      :options="unselectedOptions"
      :showLabels="false"
      :closeOnSelect="closeOnSelect"
      :placeholder="placeholder"
      :searchable="searchable"
      :maxHeight="maxHeight"
      :trackBy="trackBy"
      :label="label"
      :tabindex="-1"
      :hideSelected="hideSelected"
      :allowEmpty="allowEmpty"
      :multiple="multiple"
      class=""
      @open="onOpen"
      @close="onClose"
      @update:modelValue="onSelect"
      @searchChange="dropdownInput"
      @remove="removeOption"
      @keydown.tab.exact.stop="onSelectHighlightedByTab($event)"
      @keydown.enter.stop="onSelectHighlightedByEnter"
    >
      <template #noResult>
        <div class="dropdown-basic__desc">
          {{ noResult }}
        </div>
      </template>

      <template #noOptions>
        {{ noOptions }}
      </template>

      <template #singleLabel="props">
        <slot
          name="singleLabel"
          :option="props.option"
        />
      </template>

      <template #option="props">
        <slot
          name="option"
          :option="props.option"

        />
      </template>

      <template #placeholder>
        <slot name="placeholder" />
      </template>

      <template #team="props">
        <slot
          name="team"
          :team="props.team"
        />
      </template>
    </Multiselect>
    <button
      v-if="remove && value"
      type="button"
      class="dropdown-basic__close"
      @click="$emit('remove')"
    >
      <icon
        data="@icon/x-circle.svg"
        :fill="false"
        width="1em"
        height="1em"
        color="#7D92A7"
        class=""
      />
    </button>
  </component>
</template>

<script lang="ts">
import { Emit, Mixins, Prop } from "vue-property-decorator"
import Multiselect from "vue-multiselect"
import { AppendToBody } from "@/mixins"
import { Options } from "vue-class-component"

type OptionPosition = "left" | "right"

@Options({
  name: "DropdownBasic",
  components: {
    Multiselect,
  },
})

export default class extends Mixins(AppendToBody) {
  openDropdown = false
  @Prop() value
  @Prop() options
  @Prop() declare id: string
  @Prop() noResult: string
  @Prop() noOptions: string
  @Prop() placeholder: string
  @Prop() trackBy: string
  @Prop() label: string
  @Prop({ default: "0" }) tabindex: string
  @Prop({ default: 300 }) maxHeight: number
  @Prop({ default: true }) searchable: boolean
  @Prop({ default: false }) remove: boolean
  @Prop({ default: false }) declare appendDropDownToBody: boolean
  @Prop({ default: true }) hideSelected: boolean
  @Prop({ default: true }) allowEmpty: boolean
  @Prop({ default: false }) multiple: boolean
  @Prop({ default: true }) closeOnSelect: boolean
  @Prop({ default: true }) clearOnSelect: boolean
  @Prop({ default: "div" }) parent: string
  @Prop() readonly index: number
  @Prop({ default: "left" }) declare optionPosition: OptionPosition

  repositionDropDown: () => void

  onEnter() {
    if (!this.isButton) return

    this.onActivateMultiselect()
  }

  onActivateMultiselect() {
    this.multiselectRef.activate()
  }

  onDeactivateMultiselect() {
    this.multiselectRef.deactivate()
  }

  onSelectHighlightedByTab(event) {
    if (!this.isButton) {
      event.preventDefault()
    }

    this.onSelectHighlightedByEnter()
  }

  async onSelectHighlightedByEnter() {
    await this.$nextTick(() => {
      this.onSelectHighlighted()
      this.onDeactivateMultiselect()
    })
  }

  onSelectHighlighted() {
    this.multiselectRef.addPointerElement()
  }

  @Emit("onSelect")
  onSelect(value) {
    return {
      value,
      id: this.id,
    }
  }

  get isButton() {
    return this.parent === "button"
  }

  get multiselectRef() {
    return (this.$refs[`multiselect-${this.id}`] as any)
  }

  get unselectedOptions() {
    if (this.hideSelected) {
      return this.options.filter(option => option !== this.value)
    }
    return this.options
  }

  @Emit()
  onOpen() {
    this.repositionDropDown()
    this.openDropdown = true
    this.checkOpenDropdown()
  }

  @Emit()
  onClose() {
    this.openDropdown = false
    this.checkOpenDropdown()
  }

  @Emit("checkOpenDropdown")
  checkOpenDropdown() {
    return {
      openDropdown: this.openDropdown,
      index: this.index,
    }
  }

  @Emit("dropdownInput")
  dropdownInput(searchQuery) {
    searchQuery
  }

  @Emit("removeOption")
  removeOption(removedOption) {
    removedOption
  }
}
</script>
