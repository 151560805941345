import { Filter } from "@/models/Pageable"

export class FilterComment extends Filter {
  includeAudit: boolean
  targetId: string

  constructor(props) {
    super(props)

    this.includeAudit = props.includeAudit ?? false
    this.targetId = props.targetId ?? ""
  }

  getJsonObj() {
    const data = {
      includeAudit: this.includeAudit,
      targetId: this.targetId,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterComment(props)
  }

  static createDefault() {
    const props = {}

    return new FilterComment(props)
  }
}
