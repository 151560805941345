import { Module, Mutation, Action } from "vuex-class-modules"
import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import { ServerResponse } from "@/models/ServerResponse"
import { store } from "../store"
import { Modules } from "../modules"
import { ToDo } from "@/models/ToDo"
import { BaseModule } from "@/models/BaseModule"
import { FilterToDo, FilterToDoCount } from "@/models/Filter/FilterToDo"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { $t } from "@/plugins/i18n"
import { accountModule } from "./account.module"
import { UserActions, userModule } from "./user.module"
import { User } from "@/models/User"
import { parsingServerResponseContent } from "@/utils/helpers"
import { UpdateObjectValue } from "@/models"

export enum ToDoActions {
  GET_TODOS = "GET_TODOS",
  GET_TODOS_SERVER_RESPONSE = "GET_TODOS_SERVER_RESPONSE",
  ADD_TODO = "ADD_TODO",
  GET_TODO = "GET_TODO",
  UPDATE_TODO_VALUE = "UPDATE_TODO_VALUE",
  RESET_TO_DO = "RESET_TO_DO",
  RESET_TO_DOS = "RESET_TO_DOS",
  TODO_ITEM_RESOLVE = "TODO_ITEM_RESOLVE",
  TODO_ITEM_UNRESOLVE = "TODO_ITEM_UNRESOLVE",
  REMOVE_TODO_FROM_LIST = "REMOVE_TODO_FROM_LIST",
  SET_TODO_IN_LIST = "SET_TODO_IN_LIST",
  SET_TODO = "SET_TODO",
  UPDATE_TODO = "UPDATE_TODO",
  GET_OPENED_TODO_COUNT = "GET_OPENED_TODO_COUNT",
}

@Module
class ToDoModule extends BaseModule {
  toDos: ToDo[] = []
  toDo: ToDo = new ToDo()
  toDosServerResponse: ServerResponse<ToDo>
  openedToDoCount = 0

  get accountId() {
    return accountModule.getCurrentAccountId()
  }

  get profileId() {
    const profile: User = userModule[`get/${UserActions.GET_USER_CURRENT_PROFILE}`]

    return profile.id
  }

  get [`get/${ToDoActions.GET_TODO}`]() {
    return this.toDo
  }

  get [`get/${ToDoActions.GET_TODOS}`]() {
    return this.toDos
  }

  get [`get/${ToDoActions.GET_TODOS_SERVER_RESPONSE}`]() {
    return this.toDosServerResponse
  }

  get [`get/${ToDoActions.GET_OPENED_TODO_COUNT}`]() {
    return this.openedToDoCount
  }

  @Mutation
  setToDos(serverResponse: ServerResponse<ToDo>) {
    this.toDos = parsingServerResponseContent({
      originalContent: this.toDos,
      serverResponseContent: serverResponse.content,
      numberOfPage: serverResponse.pageable.pageNumber,
    })
    this.toDosServerResponse = { ...serverResponse, content: this.toDos }
  }

  @Mutation
  resetToDos() {
    this.toDos = []
  }

  @Mutation
  onUpdateToDoValue(payload: UpdateObjectValue<ToDo>) {
    Object.assign(this.toDo, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  setToDo(toDo: ToDo) {
    this.toDo = new ToDo(toDo)
  }

  @Mutation
  setToDoInList(toDo: ToDo) {
    const index = this.toDos.findIndex(item => item.id === toDo.id)
    if (index === -1) {
      this.toDos.push(toDo)
    } else {
      this.toDos[index] = toDo
    }
  }

  @Mutation
  removeToDoFromList(toDoItemId: string) {
    this.toDos = this.toDos.filter(toDo => toDo.id !== toDoItemId)
  }

  @Mutation
  setOpenedToDoCount(count: number) {
    this.openedToDoCount = count
  }

  @Action
  async [ToDoActions.GET_TODOS](filterToDo : FilterToDo) {
    const filter = filterToDo.getJsonObj()

    const data = await apiService.get<ServerResponse<ToDo>>(Endpoint.TODO(this.accountId), filter)
    return this.handleResponse<ServerResponse<ToDo>>(data, (data) => {
      const content: ToDo[] = data.content.map(toDo => new ToDo(toDo))
      data = { ...data, content }

      this.setToDos(data)
      return data
    })
  }

  @Action
  async [ToDoActions.ADD_TODO](toDo: ToDo) {
    const data = await apiService.post<ToDo>(Endpoint.TODO(this.accountId), toDo.getJsonObj())
    return this.handleResponse<ToDo>(data, (data) => {
      const alertContent: AlertContentItem[] = [{
        text: `ToDo ${$t("alert.created")}`,
        type: "regular",
      }]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      return new ToDo(data)
    })
  }

  @Action
  async [ToDoActions.TODO_ITEM_RESOLVE](todoItemId: string) {
    const data = await apiService.patch<ToDo>(Endpoint.TODO_ITEM_RESOLVE(this.accountId, todoItemId))
    return this.handleResponse<ToDo>(data, data => new ToDo(data))
  }

  @Action
  async [ToDoActions.TODO_ITEM_UNRESOLVE](todoItemId: string) {
    const data = await apiService.patch<ToDo>(Endpoint.TODO_ITEM_UNRESOLVE(this.accountId, todoItemId))
    return this.handleResponse<ToDo>(data, data => new ToDo(data))
  }

  @Action
  async [ToDoActions.UPDATE_TODO](toDo: ToDo) {
    const data = await apiService.put<ToDo>(Endpoint.PUT_TODO(this.accountId, toDo.id), toDo.getJsonObj())
    return this.handleResponse<ToDo>(data, (data) => {
      const todo = new ToDo(data)
      const alertContent: AlertContentItem[] = [
        {
          text: "ToDo ",
          type: "regular",
        },
        {
          text: `${todo.name} `,
          type: "bold",
        },
        {
          text: $t("alert.updated"),
          type: "regular",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        content: alertContent,
        type: AlertType.SUCCESS,
        theme: "toast",
      })
      this.setToDoInList(todo)
      return todo
    })
  }

  @Action
  async [ToDoActions.GET_OPENED_TODO_COUNT]() {
    const filter = new FilterToDoCount({
      status: "ACTIVE",
      completedByMe: false,
    })
    const data = await apiService.get<number>(Endpoint.TODO_COUNT(this.accountId), filter)
    return this.handleResponse<number>(data, (data) => {
      this.setOpenedToDoCount(data)
      // This is a hack to avoid getting true if data is 0
      return `${data}`
    })
  }

  @Action
  [ToDoActions.UPDATE_TODO_VALUE](payload: UpdateObjectValue<ToDo>) {
    this.onUpdateToDoValue(payload)
  }

  @Action
  [ToDoActions.RESET_TO_DO]() {
    this.setToDo(new ToDo())
  }

  @Action
  [ToDoActions.RESET_TO_DOS]() {
    this.resetToDos()
  }

  @Action
  [ToDoActions.REMOVE_TODO_FROM_LIST](toDoItemId: string) {
    this.removeToDoFromList(toDoItemId)
  }

  @Action
  [ToDoActions.SET_TODO_IN_LIST](toDo: ToDo) {
    this.setToDoInList(toDo)
  }

  @Action
  [ToDoActions.SET_TODO](toDo: ToDo) {
    this.setToDo(toDo)
  }
}

export const toDoModule = new ToDoModule({ store, name: Modules.TODO })
