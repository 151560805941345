import { EpicStatus } from "@/models"
import { Filter } from "@/models/Pageable"

export class FilterEpic extends Filter {
  q?: string
  inMilestone?: boolean
  inTeam?: boolean
  teamIds?: string[]
  archived?: boolean
  label?: string
  status?: EpicStatus
  featureId?: string
  inFeature?: boolean | null
  tshirtSize?: boolean

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.inMilestone = props.inMilestone ?? ""
    this.inTeam = props.inTeam ?? ""
    this.teamIds = props.teamIds ?? []
    this.archived = props.archived ?? ""
    this.label = props.label ?? ""
    this.status = props.status ?? ""
    this.featureId = props.featureId ?? ""
    this.inFeature = props.inFeature ?? ""
    this.tshirtSize = props.tshirtSize ?? ""
  }

  getJsonObj() {
    const data = {
      q: this.q,
      inMilestone: this.inMilestone,
      inTeam: this.inTeam,
      teamIds: this.teamIds,
      archived: this.archived,
      label: this.label,
      status: this.status,
      featureId: this.featureId,
      inFeature: this.inFeature,
      tshirtSize: this.tshirtSize,
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterEpic(props)
  }

  static createDefault() {
    const props = {}

    return new FilterEpic(props)
  }
}
