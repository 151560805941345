<template>
  <VDropdown
    :id="id"
    :ref="`dropdownMenuRef_${id}`"
    :distance="distance"
    :skidding="skidding"
    :placement="placement"
    :triggers="triggers"
    :autoHide="autoHide"
    :class="className"
    :popperClass="popperClass"
    :shown="shown"
    :delay="delay"
    class="dropdown-menu"
    theme="info-dropdown"
    @applyShow="onApplyShow"
    @applyHide="onApplyHide"
    @show="$emit('onShow')"
  >
    <slot
      name="label"
      :hideDropdown="hideDropdown"
    />

    <template #popper>
      <slot
        :hideDropdown="hideDropdown"
        name="before-list"
      />
      <slot
        name="list"
        :hideDropdown="hideDropdown"
      >
        <div
          class="custom-scroll dropdown-menu__list"
        >
          <div
            v-for="(value, index) in values"
            :key="index"
            :ref="`listOfElementRefs_${id}`"
            :class="[{'is-selected' : index === selectedElementIndex}, value?.class]"
            class="dropdown-menu__item"
            @click="onSelect({value, index})"
          >
            <slot
              name="item"
              :value="value"
              :index="index"
              :hideDropdown="hideDropdown"
            />
          </div>
          <ObserverComponent
            :last="isLast"
            @intersect="$emit('onScroll')"
          />
        </div>
      </slot>

      <slot
        name="after-list"
        :hideDropdown="hideDropdown"
      />
    </template>
  </VDropdown>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"

import eventKey from "@/helpers/eventKeyHelper"

import ObserverComponent from "@/components/Observer/ObserverComponent.vue"
const components = {
  ObserverComponent,
}
@Options({
  name: "VDropdownWithKeyboardNavigation",
  components,
})

export default class VDropdownWithKeyboardNavigation extends Vue {
  @Prop({ required: true }) id: string
  @Prop({ required: true }) values: any[]
  @Prop({ default: 0 }) readonly skidding: number
  @Prop({ default: 0 }) readonly distance: number
  @Prop({ default: 0 }) readonly delay: number
  @Prop({ default: -1 }) readonly selectedElementIndex: number
  @Prop({ default: "bottom" }) readonly placement: string | undefined
  @Prop({ default: ["click"] }) readonly triggers: string[]
  @Prop({ default: "" }) readonly className: string
  @Prop({ default: "" }) readonly popperClass: string
  @Prop({ default: false }) readonly shown: boolean
  @Prop({ default: true }) readonly isLast: boolean
  @Prop({ default: true }) readonly autoHide: boolean
  isDropdownOpened = false

  get selectedElementIndexLocal() {
    return this.selectedElementIndex
  }

  set selectedElementIndexLocal(value) {
    this.$emit("selectedIndexUpdate", value)
  }

  mounted() {
    document.addEventListener("keydown", this.onKeyDown)
  }

  beforeUnmount() {
    document.removeEventListener("keydown", this.onKeyDown)
  }

  onKeyDown(event) {
    if (!this.isDropdownOpened) return

    if (eventKey(event).arrowUpKey && this.selectedElementIndex > 0) {
      this.selectedElementIndexLocal -= 1
      this.$nextTick(() => this.scrollToElement(this.selectedElementIndexLocal))
    }
    if (eventKey(event).arrowDownKey && this.values.length - 1 > this.selectedElementIndex) {
      this.selectedElementIndexLocal += 1
      this.$nextTick(() => this.scrollToElement(this.selectedElementIndexLocal))
    }
    if (eventKey(event).enterKey && this.selectedElementIndex >= 0) {
      event.preventDefault()
      this.onSelect({
        value: this.values[this.selectedElementIndex],
        index: this.selectedElementIndex,
      })
    }
  }

  scrollToElement(index) {
    const listOfElementRefs = this.$refs[`listOfElementRefs_${this.id}`] as any[]
    if (!listOfElementRefs?.[index]) return
    listOfElementRefs[index].scrollIntoView({
      behavior: "instant",
      block: "nearest",
    })
  }

  hideDropdown() {
    const dropdownMenuRef = this.$refs[`dropdownMenuRef_${this.id}`] as any
    if (!dropdownMenuRef) return
    dropdownMenuRef.hide()
  }

  @Emit()
  onSelect({ value, index }) {
    this.hideDropdown()
    return {
      id: this.id,
      value,
      index,
    }
  }

  @Emit()
  onApplyShow() {
    this.isDropdownOpened = true
  }

  @Emit()
  onApplyHide() {
    this.isDropdownOpened = false
  }
}
</script>
