import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"
import { Retrospective } from "@/models/Retrospective"
import { Team } from "@/models/Team"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  const currentProfile = store.getters[Getters.GET_USER_CURRENT_PROFILE]
  const teamId: string = store.getters[Getters.GET_SESSION_TEAM_ID]
  const currentTeam: Team = await store.dispatch(Actions.TEAM_WITH_EXTENDED_USERS, {
    teamId,
    onlyGet: true,
  })

  const checkUserInTeam = currentTeam.users.find(user => user.id === currentProfile.id)
  if (!checkUserInTeam && to.name === "RetrospectiveDeveloper") {
    return next({
      name: "Error",
      query: {
        code: 404,
      },
    })
  }

  const sprintId = store.getters[Getters.GET_SESSION_SPRINT_ID]
  const retrospective: Retrospective = await store.dispatch(Actions.GET_RETROSPECTIVE_BY_SPRINT_ID, sprintId)

  if (retrospective.isCompleted) {
    next({
      name: "retrospective",
      params: to.params,
    })
  }

  next()
}
