<template>
  <VDropdown
    :theme="theme"
    :placement="placement"
    :skidding="skidding"
    :distance="distance"
    :popperClass="popperClass"
    :triggers="triggers"
    :popperHideTriggers="popperHideTriggers"
    :popperShowTriggers="popperShowTriggers"
    :shown="shown"
    :class="[dropdownClass]"
    :autoHide="autoHide"
    :disabled="disabled"
    @applyShow="$emit('onShowDropdown')"
    @applyHide="$emit('onHideDropdown')"
  >
    <button
      class="tooltip-dropdown__label"
      :tabindex="tabIndex"
      :class="`icon-button__${size}`"
    >
      <slot name="tooltip-label">
        <icon
          data="@icon/more-vertical.svg"
          :width="mainIconSize"
          :height="mainIconSize"
          :fill="false"
          color="var(--monochrome05to04)"
        />
      </slot>
    </button>

    <template #popper="{ hide }">
      <slot
        name="tooltip-content"
        :hide="hide"
      >
        <div :class="subDropdownClass">
          <VDropdown
            v-for="(item, index) in menu"
            :key="index"
            theme="info-dropdown"
            :placement="placementSub"
            :skidding="skiddingSub"
            :distance="distanceSub"
            :popperClass="popperClassSub"
            :shown="shownSub"
            :triggers="subTriggers"
            :disabled="disable || item.hideOptions"
            @applyShow="$emit('onShowSubDropdown')"
            @applyHide="$emit('onHideSubDropdown')"
          >
            <BaseSubHeading
              size="large"
              weight="400"
              :className="subMenuClass"
            >
              <button
                :id="item.id"
                tabindex="-1"
                v-close-popper.all
                class="body-text body-text-medium-400 tooltip-submenu__button"
                @click="dropdownSubMenu(item, hide)"
              >
                <slot
                  name="tooltip-item"
                  :item="item"
                >
                  {{ item.label }}
                </slot>
              </button>
            </BaseSubHeading>
            <template
              v-if="$wait.is(submenuWaitTitle)"
              #popper
            >
              Loading...
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #popper="{ hide }">
              <template v-if="item.options?.length === 0">
                <slot
                  name="tooltip-submenu-empty"
                  :item="item"
                >
                  {{ submenuEmptyTitle }}
                </slot>
              </template>
              <template v-else>
                <slot
                  name="tooltip-subContent"
                  :hide="hide"
                >
                  <BaseSubHeading
                    v-for="(option, key) in item.options"
                    :key="key"
                    size="medium"
                    weight="400"
                    :class="[optionMenuClass, `option-menu_${item.id}`]"
                  >
                    <button
                      :id="option.id"
                      tabindex="-1"
                      class="option-menu_button"
                      @click="dropdownOptionMenu(option, hide, item)"
                    >
                      <slot
                        name="tooltip-submenu-item"
                        :option="option"
                        :item="item"
                        :hide="hide"
                      >
                        {{ option.label }}
                      </slot>
                    </button>
                  </BaseSubHeading>
                </slot>
              </template>
            </template>
          </VDropdown>
        </div>
      </slot>
    </template>
  </VDropdown>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { directive as ClickOutside } from "click-outside-vue3"
type IconSize = "sm" | "md" | "none"

@Options({
  name: "DropdownTooltip",
  directives: {
    ClickOutside,
  },
})

export default class extends Vue {
  @Prop({ default: "info-dropdown" }) readonly theme: string
  @Prop({ default: "sm" }) iconSize: IconSize
  @Prop({ default: 0 }) readonly skidding: number
  @Prop({ default: ["click"] }) readonly triggers: string[]
  @Prop({ default: ["click"] }) readonly hideTriggers: string[]
  @Prop({ default: 0 }) readonly distance: number
  @Prop({ default: "bottom" }) readonly placement: string | undefined
  @Prop({ default: "" }) readonly popperClass: string
  @Prop({ default: "" }) readonly dropdownClass: string
  @Prop({ default: false }) readonly shown: boolean
  @Prop({ default: false }) readonly shownSub: boolean

  @Prop({ default: [] }) readonly popperHideTriggers: string[]
  @Prop({ default: [] }) readonly popperShowTriggers: string[]
  @Prop({ default: true }) readonly autoHide: boolean

  @Prop({ default: ["click"] }) readonly subTriggers: string[]
  @Prop({ default: "sm" }) iconSizeSub: IconSize
  @Prop({ default: 0 }) readonly skiddingSub: number
  @Prop({ default: 0 }) readonly distanceSub: number
  @Prop({ default: "bottom" }) readonly placementSub: string
  @Prop({ default: "bottom" }) readonly popperClassSub: string
  @Prop({ default: false }) readonly disable: boolean
  @Prop({ default: false }) readonly disabled: boolean

  @Prop({}) readonly menu: any[]
  @Prop({ default: "" }) readonly subMenuClass: string
  @Prop({ default: "" }) readonly submenuWaitTitle: string
  @Prop({ default: "No Options" }) readonly submenuEmptyTitle: string
  @Prop({ default: "" }) readonly subDropdownClass: string
  @Prop({ default: "" }) readonly optionMenuClass: string
  @Prop({ default: "1em" }) readonly mainIconSize: string
  @Prop({ default: 0 }) readonly tabIndex: number
  get size() {
    switch (this.iconSize) {
      case "sm":
        return "sm"
      case "md":
        return "md"
      case "none":
        return "none"
      default:
        return "sm"
    }
  }

  @Emit()
  dropdownSubMenu(value, hide) {
    return {
      id: value.id,
      hide: hide,
    }
  }

  @Emit()
  dropdownOptionMenu(value, hide, item) {
    return { value, hide, item }
  }
}
</script>
