import { User } from "./User"
import { ResourceType } from "./common"

import { isImage } from "@/helpers/isImg"
import { isVideo } from "@/helpers/isVideo"
import { parseDate } from "@/utils/dateUtil"

export type UploadAttachmentTarget = {
  attachments: Blob[],
  resourceType: ResourceType,
  targetId: string,
  onUploadProgress?: any
  excludeDuplicate?: boolean
}

export type UploadQueryFile = {
  onUploadProgress?: any
  params?: {
    excludeDuplicate: boolean
  }
}

export type UploadAttachment = {
  attachments: Blob[],
  onUploadProgress?: any
}

export type UploadAttachmentFn = (args: UploadAttachmentTarget | UploadAttachment) => FileMetaData[]

export type TypeConfig = {
  type: ResourceType,
  targetId: string
}

export class VUFile {
  file: File = new File([], "")
  readonly fileObject = false
  id: string | number = ""
  size = 0
  name = ""
  type = ""
  active = false
  error = ""
  success = false
  putAction = false
  postAction = false
  headers: Record<string, unknown> = {}
  data: Record<string, unknown> = {}
  timeout = 0
  response: Record<string, unknown> | string = ""
  progress = ""
  speed = 0
  xhr: XMLHttpRequest = new XMLHttpRequest()
  iframe?: Element

  constructor(props?: Partial<VUFile>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export interface IFile {
  id: string;
  owner: User;
  name: string;
  size: number;
  createdDate: string;
  targetType: string;
  custom?: boolean
}

export class FileMetaData implements IFile {
  id: string
  owner: User
  name: string
  size: number
  createdDate: string
  targetType: string
  custom?: boolean

  constructor(props?: IFile) {
    if (!props) return

    this.set(props)
  }

  set(props: IFile) {
    this.id = props.id ?? ""
    this.owner = User.create(props.owner)
    this.name = props.name ?? ""
    this.size = props.size ?? 0
    this.createdDate = props.createdDate ?? ""
    this.targetType = props.targetType ?? ""
    this.custom = props.custom
  }

  get createdDateParsed() {
    return parseDate(this.createdDate, true)
  }

  get ext() {
    return this.name.split(".").pop() ?? ""
  }

  get isImage() {
    return isImage(this.name)
  }

  get isVideo() {
    return isVideo(this.name)
  }

  getJsonObj() {
    return {}
  }

  static create(props?: IFile): FileMetaData {
    return new FileMetaData(props)
  }
}
