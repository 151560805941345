import { AxiosResponse } from "axios"

// export class Error {
//   type: string = '';
//   code: number = 0;
//   message: string = '';
//   url: string = '';
//   fields: any;

//   constructor(props: any) {
//     this.set(props)
//   }

//   set(props: any) {
//     if (!props) return

//     this.type = props.data.status ?? "error"
//     this.code = props.status ?? 0
//     this.message = props.data && props.data.message
//         ? props.data.message
//         : props.data && props.data.data && props.data.data.exception
//         ? props.data.data.exception
//         : ""
//     this.fields = props.data && props.data.data && !props.data.data.exception
//         ? props.data.data
//         : null
//     this.url = props.config && props.config.url ? props.config.url : ""
//   }
// }

export class HttpError implements Error {
  name: string
  message: string
  stack?: string
  response: AxiosResponse

  constructor(e: HttpError) {
    const url = `${e.response.config.url}`
    this.name = e.name
    this.message = `${url}: ${e.response.data?.message || e.message}`
    this.stack = e.stack
    this.response = e.response
  }
}

export class CustomError {
  message: string
  code: number

  constructor(props) {
    this.message = props?.message ?? ""
    this.code = props?.code ?? ""
  }
}
