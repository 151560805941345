import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/backlog-icon.svg'
import _imports_1 from '@icon/arrow-to.svg'


const _hoisted_1 = { class: "label-pic is-teal" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "link is-medium-500 is-opacity" }
const _hoisted_4 = { class: "link is-medium-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, {
    notification: _ctx.notification,
    class: "notification__update-item"
  }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          fill: true,
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: "link is-large",
            target: "_blank",
            href: _ctx.notification.itemUrl
          }, " Story " + _toDisplayString(_ctx.notification.itemSequenceNumber), 9, _hoisted_2),
          _createTextVNode(" " + _toDisplayString(_ctx.notification.getItemValue('title')), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "small",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "400"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Owner has been changed ")
        ]),
        _: 1
      }),
      _createVNode(_component_BaseSubHeading, {
        size: "medium",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500",
        className: "notification__update-item__content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, " @" + _toDisplayString(_ctx.oldOwnerFullName), 1),
          _createVNode(_component_icon, {
            data: _imports_1,
            fill: true,
            width: "24px",
            height: "24px",
            color: "#616161"
          }),
          _createElementVNode("span", _hoisted_4, " @" + _toDisplayString(_ctx.actualOwnerFullName), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["notification"]))
}