import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notification__header" }
const _hoisted_2 = { class: "notification__avatar" }
const _hoisted_3 = { class: "notification__title" }
const _hoisted_4 = {
  key: 0,
  class: "notification__mark"
}
const _hoisted_5 = { class: "notification__container" }
const _hoisted_6 = {
  key: 0,
  class: "notification__sub-title"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "notification__content" }
const _hoisted_9 = { class: "notification__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!

  return (_openBlock(), _createElementBlock("div", {
    key: _ctx.notification.id,
    class: "notification__item"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "pic")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      (!_ctx.notification.read)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.showSubTitle && (_ctx.computedProjectName || _ctx.computedTeamName))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.computedTeamName)
              ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                  key: 0,
                  size: "medium",
                  color: "monochrome-05",
                  darkColor: "monochrome-04",
                  weight: "500"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.computedTeamName), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, " ")),
            (_ctx.computedProjectName)
              ? (_openBlock(), _createBlock(_component_BaseSubHeading, {
                  key: 2,
                  size: "medium",
                  color: "monochrome-05",
                  darkColor: "monochrome-04",
                  weight: "500"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.computedProjectName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BaseSubHeading, {
          size: "extra-small",
          color: "monochrome-05",
          darkColor: "monochrome-04",
          className: "notification__footer-right",
          weight: "400"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notification.timeAgo), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}