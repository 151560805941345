import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icon/plus-16.svg'


const _hoisted_1 = { class: "to-do-assign__wrapper" }
const _hoisted_2 = { class: "to-do__plus" }
const _hoisted_3 = { class: "to-do-assign__change-list__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_AvatarGroupToDo = _resolveComponent("AvatarGroupToDo")!
  const _component_icon = _resolveComponent("icon")!
  const _component_BorderedInput = _resolveComponent("BorderedInput")!
  const _component_ToDoAssignItem = _resolveComponent("ToDoAssignItem")!
  const _component_VDropdownWithKeyboardNavigation = _resolveComponent("VDropdownWithKeyboardNavigation")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      as: "",
      name: "toDoOwner",
      rules: _ctx.rules,
      modelValue: !!_ctx.selectedUsers?.length,
      validateOnBlur: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseSubHeading, {
          size: "large",
          weight: "400",
          color: "monochrome-05",
          darkColor: "monochrome-03"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Assign to: ")
          ]),
          _: 1
        }),
        _createVNode(_component_VDropdownWithKeyboardNavigation, {
          triggers: ['click'],
          values: _ctx.selectedTeamList ? _ctx.searchedTeams : _ctx.users,
          isLast: _ctx.isLast,
          popperClass: "to-do-assign__dropdown",
          class: "to-do-assign",
          iconSize: "md",
          onOnScroll: _ctx.onScroll
        }, _createSlots({
          label: _withCtx(() => [
            _createVNode(_component_AvatarGroupToDo, {
              avatars: _ctx.selectedUsers,
              numberOfVisibleUsers: 20,
              showRemoveButton: true,
              scaleByHover: true,
              type: "avatarGroup",
              size: "md",
              onOnRemoveAvatar: _ctx.onRemoveUser
            }, null, 8, ["avatars", "onOnRemoveAvatar"]),
            _withDirectives(_createElementVNode("button", _hoisted_2, [
              _createVNode(_component_icon, {
                data: _imports_0,
                fill: true,
                width: "24",
                height: "24",
                color: "var(--monochrome05to04)"
              })
            ], 512), [
              [_vShow, _ctx.selectedUsers?.length]
            ])
          ]),
          "before-list": _withCtx(() => [
            (!_ctx.selectedTeamList)
              ? (_openBlock(), _createBlock(_component_BorderedInput, {
                  key: 0,
                  id: "searchTeamUsers",
                  modelValue: _ctx.filterUsers.text,
                  type: "search",
                  placeholder: "Search by Name or Email",
                  tooltip: false,
                  searchIcon: true,
                  autocomplete: false,
                  focus: true,
                  showRemoveButton: false,
                  className: "to-do-assign__search",
                  "onUpdate:modelValue": _ctx.onSearchUsers
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true)
          ]),
          item: _withCtx(({ value }) => [
            _createVNode(_component_ToDoAssignItem, {
              value: value,
              isAssigned: _ctx.getSelectedItemIndex(value) !== -1,
              selectedTeamList: _ctx.selectedTeamList,
              onOnSelectTeam: _ctx.onSelectTeam,
              onOnSelectUser: _ctx.onUpdateSelectedList
            }, null, 8, ["value", "isAssigned", "selectedTeamList", "onOnSelectTeam", "onOnSelectUser"])
          ]),
          _: 2
        }, [
          (_ctx.canSelectTeam)
            ? {
                name: "after-list",
                fn: _withCtx(() => [
                  _createElementVNode("button", {
                    type: "button",
                    class: "to-do-assign__change-list",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleList && _ctx.toggleList(...args)))
                  }, [
                    _createElementVNode("span", _hoisted_3, " or Select a " + _toDisplayString(_ctx.selectedTeamList ? "User" : "Team"), 1)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["values", "isLast", "onOnScroll"]),
        _createVNode(_component_ErrorMessage, {
          class: "error-text error-text-small",
          name: "toDoOwner"
        })
      ]),
      _: 1
    }, 8, ["rules", "modelValue"])
  ]))
}