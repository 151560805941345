
import { Filter } from "@/models/Pageable"
export class FilterSearchItem extends Filter {
  q?: string
  projectIds?: string[]

  constructor(props) {
    super(props)

    this.q = props.q ?? ""
    this.projectIds = props.projectIds ?? []
    this.empty = true
  }

  getJsonObj() {
    const data = {
      q: this.q ?? "",
      projectIds: this.projectIds ?? [],
    }

    return super.getJsonObj({ params: data })
  }

  static create(props) {
    return new FilterSearchItem(props)
  }

  static createDefault() {
    const props = {
      q: "",
      projectIds: [],
    }

    const filter = new FilterSearchItem(props)
    return filter
  }
}

