import {
  ReferenceConfig,
} from "@/plugins/extensions/Reference"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { getTypeByText } from "@/plugins/extensions/Reference"
import FileList from "./File/FileList.vue"

export default {
  char: ReferenceConfig[ReferenceType.TIP].char,
  items: async ({ query }) => {
    const type = getTypeByText(`${ReferenceConfig[ReferenceType.TIP].char}${query}`)
    let component
    if (type === ReferenceType.FILE) {
      component = FileList
    }
    return {
      query,
      component,
    }
  },
}
