<template>
  <div class="to-do__datepicker">
    <div class="to-do__datepicker-labels">
      <BaseSubHeading
        size="large"
        weight="400"
        color="monochrome-05"
        class="to-do__datepicker-title"
        darkColor="monochrome-03"
      >
        Due
      </BaseSubHeading>
      <div class="to-do__datepicker-pressets">
        <BasicCheckbox
          :modelValue="today"
          label="Today"
          value="todayDate"
          :bordered="true"
          checkType="filter"
          @update:modelValue="onCheckboxToday"
        />
        <BasicCheckbox
          :modelValue="tomorrow"
          label="Tomorrow"
          value="tomorrowDate"
          :bordered="true"
          checkType="filter"
          @update:modelValue="onCheckboxTomorrow"
        />
      </div>
    </div>
    <div class="to-do__datepicker-input">
      <v-date-picker
        :modelValue="date"
        mode="date"
        color="blue"
        locale="en"
        :minDate="new Date()"
        :popover="popoverConfig"
        class="input_to-do"
        @update:modelValue="updateDate"
        @mousedown="$event.stopPropagation()"
      >
        <template #default="{ inputEvents, togglePopover }">
          <BorderedInput
            id="selectToDoDate"
            placeholder="MM/DD/YYYY"
            :rules="rules"
            :modelValue="parseDate"
            :inputEvents="inputEvents"
            :validateOnBlur="false"
            :validateOnModelUpdate="true"
            :validateOnChange="false"
            className="input_to-do"
            label="Select Another Day:"
            maska="##/##/####"
            validationTag="div"
            icon
            @click="togglePopover"
          >
            <template #icon>
              <icon
                data="@icon/calendar.svg"
                :fill="false"
                color="#7D92A7"
                width="16"
                height="16"
              />
            </template>
          </BorderedInput>
        </template>
      </v-date-picker>
    </div>
  </div>
</template>

<script lang="ts">
import { Emit, Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import BasicCheckbox from "@/components/Form/BasicCheckbox.vue"
import BorderedInput from "@/components/Form/BorderedInput.vue"
import { maska } from "maska"
import format from "date-fns/format"

const components = {
  BasicCheckbox,
  BorderedInput,
}

@Options({
  name: "TodoWidgetDatePicker",
  components,
  directives: { maska },
})
export default class TodoWidgetDatePicker extends Vue {
  @Prop({ default: false }) today: boolean
  @Prop({ default: false }) tomorrow: boolean
  @Prop({ default: null }) date: Date
  @Prop({ default: "" }) readonly rules: () => boolean | string
  popoverConfig = {
    positionFixed: false,
    placement: "bottom",
    visibility: "click",
  }

  get parseDate() {
    return this.date ? format(new Date(this.date), "MM/dd/yyyy") : null
  }

  @Emit()
  onCheckboxToday(value) {
    return value
  }

  @Emit()
  onCheckboxTomorrow(value) {
    return value
  }

  @Emit()
  updateDate(date) {
    return date
  }
}
</script>

