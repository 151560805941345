<template>
  <div>
    <router-view />

    <Alert />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import Alert from "@/components/Alert/index.vue"

@Options({
  components: {
    Alert,
  },
  name: "EmptyLayout",
})
export default class App extends Vue {}
</script>
