export interface EpicMetrics extends BasicMetrics {
  totalItemCount: number;
  remaining: number;
  storyPoints: number;
  percentComplete:number;
}

export interface BacklogMetricsById {
  backlogProduct: BacklogMetrics
  backlogUnprioritized: BacklogMetrics
  backlogSupportCases: BacklogMetrics
  backlogTechDebt: BacklogMetrics
  backlogDevOps: BacklogMetrics
  backlogEpic: BacklogMetrics
  backlogBug: BacklogMetrics
}

export interface DistributionBySeverityExtended {
  [key: string]: {
    total: number;
    bugBacklog: number;
    sprintBacklog: number;
    unprioritizedBacklog: number;
    inProgress: number;
  }
}

export class BugMetrics {
  distributionBySeverity: DistributionBySeverityExtended = {}
  openedLast30Days = 0
  closedLast30Days = 0
  openedLast90Days = 0
  closedLast90Days = 0
  totalCount = 0
  unassigned = 0
  notEstimated = 0

  constructor(props?: Partial<BugMetrics>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }
}

export interface SeverityMetrics extends BasicMetrics {
  distributionBySeverity: {
    [key: string]: number;
  };
  totalCount: number;
}

export interface AllItemsMetrics extends BasicMetrics {
  assignedToMe: number;
  prioritized: BacklogsTotalCounts
  unprioritized: BacklogsTotalCounts
}

export interface MetricsByType {
  total: number
  storyAndSpike: number
  bug: number
  supportCase: number
  techDebt: number
  devOps: number
  bugWaterlineExceeded: boolean
  containsHighSupportCases: boolean
}

export interface TeamBacklogsMetrics {
  productBacklog: BacklogMetrics;
  bugBacklog: BacklogMetrics;
  techDebtBacklog: BacklogMetrics;
  supportIssueBacklog: BacklogMetrics;
  devOpsBacklog: BacklogMetrics;
}

export interface BasicMetrics {
  unassigned?: number;
  notEstimated?: number;
}
export interface BacklogsTotalCounts {
  total: number
  storyAndSpike: number
  bug: number
  supportCase: number
  techDebt: number
  devOps: number
}

export class BacklogMetrics {
  totalItemCount = 0
  effort = 0
  notEstimated = 0
  unassigned = 0
  unprioritized = 0
  storyPoints = 0
  velocity = 0
  completed = 0
  notStarted = 0
  avgItemAge = 0
  sprintCapacity = 0
  constructor(props?: Partial<BacklogMetrics>) {
    if (!props) return
    Object.keys(props).forEach((field) => {
      this[field] = props[field]
    })
  }

  sumWith(otherMetrics?) {
    if (!otherMetrics) return
    this.totalItemCount += otherMetrics.totalItemCount
    this.effort += otherMetrics.effort
    this.notEstimated += otherMetrics.notEstimated
    this.unassigned += otherMetrics.unassigned
    this.unprioritized += otherMetrics.unprioritized
    this.storyPoints += otherMetrics.storyPoints
    this.velocity += otherMetrics.velocity
    this.completed += otherMetrics.completed
    this.notStarted += otherMetrics.notStarted
    this.avgItemAge += otherMetrics.avgItemAge
    this.sprintCapacity += otherMetrics.sprintCapacity
  }

  static create(props?) {
    return new BacklogMetrics(props ?? {})
  }
}
