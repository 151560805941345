import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reference-item__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_BaseCaption = _resolveComponent("BaseCaption")!
  const _component_ReferenceComponentParentList = _resolveComponent("ReferenceComponentParentList")!

  return (_openBlock(), _createBlock(_component_ReferenceComponentParentList, {
    searchList: _ctx.list,
    componentType: _ctx.componentType,
    selectedIndex: _ctx.selectedIndex,
    onOnClick: _ctx.onItemSelected
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        weight: "600",
        size: "medium",
        color: "monochrome-06"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Backlog ")
        ]),
        _: 1
      })
    ]),
    item: _withCtx(({ option }) => [
      _createElementVNode("div", {
        class: _normalizeClass(['reference-item', `is-${option.itemTypeParsed?.id.toLowerCase()}`])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BaseSubHeading, {
            weight: "500",
            size: "medium",
            color: "monochrome-06"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" #" + _toDisplayString(option.sequenceNumber), 1)
            ]),
            _: 2
          }, 1024),
          _createElementVNode("span", {
            class: _normalizeClass(['reference-item__status', `is_${option.statusParsed?.id.toLowerCase()}`])
          }, _toDisplayString(option.statusParsed?.label), 3)
        ]),
        _createVNode(_component_BaseCaption, {
          size: "large",
          color: "monochrome-05",
          className: "reference-item__body"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.title), 1)
          ]),
          _: 2
        }, 1024)
      ], 2)
    ]),
    _: 1
  }, 8, ["searchList", "componentType", "selectedIndex", "onOnClick"]))
}