import { Module, Mutation, Action } from "vuex-class-modules"
import { BaseModule } from "@/models/BaseModule"
import { projectModule, ProjectActions } from "./project.module"
import { Endpoint } from "@/services/endpoints"
import { store } from "../store"
import { Modules } from "../modules"
import { apiService } from "@/services/api.service"
import { PostMortem, PostMortemCreate } from "@/models/PostMortem"
import { UpdateObjectValue } from "@/models/common"
import { AlertActions, alertModule } from "./alert.module"
import { AlertContentItem, AlertType } from "@/models/Alert"

export enum PostMortemActions {
  GET_FIVE_WHYS = "GET_FIVE_WHYS",
  CREATE_FIVE_WHYS = "CREATE_FIVE_WHYS",
  UPDATE_FIVE_WHYS = "UPDATE_FIVE_WHYS",
  UPDATE_FIVE_WHYS_VALUE = "UPDATE_FIVE_WHYS_VALUE",
  RESET_POST_MORTEM = "RESET_POST_MORTEM",
}


@Module
class PostMortemModule extends BaseModule {
  postMortem: PostMortem = PostMortem.create({
    itemId: "",
    resolution: "",
    whys: [],
  })

  get projectId() {
    return projectModule.currentProjectId
  }

  get [`get/${PostMortemActions.GET_FIVE_WHYS}`]() {
    return this.postMortem
  }

  @Mutation
  setPostMortem(postMortem: PostMortem) {
    this.postMortem = postMortem
  }

  @Mutation
  setPostMortemValue(payload: UpdateObjectValue<PostMortem>) {
    Object.assign(this.postMortem, { [payload["id"]]: payload["value"] })
  }

  @Mutation
  resetPostMortem() {
    this.postMortem = new PostMortem({
      itemId: "",
      resolution: "",
      whys: [],
    })
  }

  @Action
  async [PostMortemActions.GET_FIVE_WHYS](itemId: string) {
    const data = await apiService.get<PostMortemCreate>(Endpoint.FIVE_WHYS_BY_ID(this.projectId, itemId))
    return this.handleResponse<PostMortemCreate>(data, (data) => {
      this.setPostMortem(PostMortem.create(data))
    }, true)
  }

  @Action
  async [PostMortemActions.CREATE_FIVE_WHYS](postMortem: PostMortem) {
    const data = await apiService.post<PostMortem>(Endpoint.FIVE_WHYS(this.projectId), postMortem.getJsonObj())
    return this.handleResponse<PostMortem>(data, (data) => {
      const createPostMortem = PostMortem.create(data)
      this.setPostMortem(createPostMortem)
      const alertContent: AlertContentItem[] = [
        { text: "Post Mortem ", type: "bold" },
        { text: "saved", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return createPostMortem
    })
  }

  @Action
  async [PostMortemActions.UPDATE_FIVE_WHYS](postMortem: PostMortem) {
    const data = await apiService.put<PostMortem>(Endpoint.FIVE_WHYS(this.projectId), postMortem.getJsonObj())
    return this.handleResponse<PostMortem>(data, (data) => {
      const createPostMortem = PostMortem.create(data)
      this.setPostMortem(createPostMortem)
      const alertContent: AlertContentItem[] = [
        { text: "Post Mortem ", type: "bold" },
        { text: "saved", type: "regular" },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.SUCCESS,
        theme: "toast",
        content: alertContent,
      })
      return createPostMortem
    })
  }

  @Action
  [PostMortemActions.RESET_POST_MORTEM]() {
    this.resetPostMortem()
  }

  @Action
  [PostMortemActions.UPDATE_FIVE_WHYS_VALUE](payload: UpdateObjectValue<PostMortem>) {
    this.setPostMortemValue(payload)
  }
}

export const postMortemModule = new PostMortemModule({
  store,
  name: Modules.POSTMORTEM,
})
