<template>
  <DropdownTooltip
    v-if="showTooltip && status!=='NOT_STARTED'"
    theme="info-tooltip"
    :triggers="['hover']"
    :hideTriggers="['hover']"
    :popperHideTriggers="['hover']"
    :popperShowTriggers="['hover']"
    :distance="0"
    placement="top"
    popperClass="gradient-bar-popper"
    class="gradient-bar-tooltip"
  >
    <template #tooltip-label>
      <div
        class="gradient-bar"
        :class="{'all-sprints__sprint-health_not-started': status==='NOT_STARTED'}"
      >
        <div class="gradient-bar__bg">
          <dt :style="{ left: percent + '%' }" />
        </div>
      </div>
    </template>

    <template #tooltip-content>
      <div class="gradient-bar-tooltip__content">
        <span class="body-text-large-400 gradient-bar-tooltip__left-text">
          {{ remaining }} Days remaining
        </span>
        <span class="body-text-large-400">
          {{ timeLeft }} hr Time left
        </span>
      </div>
    </template>
  </DropdownTooltip>
  <div
    v-else
    class="gradient-bar-tooltip"
  >
    <div
      class="gradient-bar"
      :class="{'all-sprints__sprint-health_not-started': status==='NOT_STARTED'}"
    >
      <div class="gradient-bar__bg">
        <dt :style="{ left: percent + '%' }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import DropdownTooltip from "@/components/Dropdown/DropdownTooltip.vue"

@Options({
  name: "GradientBar",
  components: {
    DropdownTooltip,
  },
})
export default class GradientBar extends Vue {
  @Prop({ default: 0 }) readonly percent: number
  @Prop({ default: 0 }) readonly remaining: number
  @Prop({ default: 0 }) readonly timeLeft: number
  @Prop() readonly status: string
  @Prop({ default: false }) readonly showTooltip: boolean
}
</script>
