export enum EndpointTypes {
  CHAT_GPT_ASK = "CHAT_GPT_ASK",
  CHAT_GPT_RESET = "CHAT_GPT_RESET",
  LOGIN = "LOGIN",
  USER_CURRENT = "USER_CURRENT",
  USER_CURRENT_ROLE = "USER_CURRENT_ROLE",
  USER_CURRENT_PROFILE = "USER_CURRENT_PROFILE",
  USER_CURRENT_ACCOUNTS = "USER_CURRENT_ACCOUNTS",
  USER_CURRENT_PICTURE = "USER_CURRENT_PICTURE",
  USER_CURRENT_PROJECTS = "USER_CURRENT_PROJECTS",
  USER_CURRENT_PROJECTS_BY_ACCOUNT = "USER_CURRENT_PROJECTS_BY_ACCOUNT",
  UPDATE_PROFILE_SETTINGS = "UPDATE_PROFILE_SETTINGS",
  GET_USERS = "GET_USERS",
  GET_USERS_EXTENDED = "GET_USERS_EXTENDED",
  GET_USER_BY_ID = "GET_USER_BY_ID",
  ACCOUNT_CHECK = "ACCOUNT_CHECK",
  ACCOUNT_ENABLE = "ACCOUNT_ENABLE",
  GET_ACCOUNT = "GET_ACCOUNT",
  GET_ACCOUNTS = "GET_ACCOUNTS",
  ACCOUNT_RESOLVE = "ACCOUNT_RESOLVE",
  POST_ACCOUNT = "POST_ACCOUNT",
  START_SUBSCRIPTION = "START_SUBSCRIPTION",
  CONFIRM_SUBSCRIPTION = "CONFIRM_SUBSCRIPTION",
  GET_ITEMS = "GET_ITEMS",
  GET_ITEM = "GET_ITEM",
  POST_ITEM = "POST_ITEM",
  GET_ITEM_FILES = "GET_ITEM_FILES",
  DELETE_ITEM_FILE = "DELETE_ITEM_FILE",
  RESOLVE_ITEM_ID = "RESOLVE_ITEM_ID",
  GET_COMMENTS = "GET_COMMENTS",
  GET_COMMENT = "GET_COMMENT",
  GET_COMMENTS_BY_SOURCE = "GET_COMMENTS_BY_SOURCE",
  POST_COMMENT = "POST_COMMENT",
  UPDATE_COMMENT_DESCRIPTION = "UPDATE_COMMENT_DESCRIPTION",
  DELETE_COMMENT = "DELETE_COMMENT",
  GET_COMMENT_FILES = "GET_COMMENT_FILES",
  UPLOAD_COMMENT_FILE = "UPLOAD_COMMENT_FILE",
  PROJECT = "PROJECT",
  PROJECT_BACKLOG = "PROJECT_BACKLOG",
  PROJECT_BY_ID = "PROJECT_BY_ID",
  PROJECT_SEARCH = "PROJECT_SEARCH",
  ADD_PROJECT_TO_TEAM = "ADD_PROJECT_TO_TEAM",
  ATTACHMENT_UPLOAD = "ATTACHMENT_UPLOAD",
  ATTACHMENT_UPLOAD_TARGET = "ATTACHMENT_UPLOAD_TARGET",
  ATTACHMENT_VIEW = "ATTACHMENT_VIEW",
  DELETE_FILE_FROM_TARGET = "DELETE_FILE_FROM_TARGET",
  DELETE_FILE = "DELETE_FILE",
  ITEM_TASKS = "ITEM_TASKS",
  TEAM = "TEAM",
  TEAMS = "TEAMS",
  TEAM_RESOLVE = "TEAM_RESOLVE",
  INVITATION = "INVITATION",
  INVITATION_WITH_FEEDBACK = "INVITATION_WITH_FEEDBACK",
  INVITATION_RESEND_BY_ID = "INVITATION_RESEND_BY_ID",
  INVITATION_RESEND_BY_EMAIL = "INVITATION_RESEND_BY_EMAIL",
  INVITATION_ACCEPT = "INVITATION_ACCEPT",
  INVITATION_CANCEL = "INVITATION_CANCEL",
  INVITATION_CHECK = "INVITATION_CHECK",
  TEAM_PICTURE = "TEAM_PICTURE",
  TEAM_USER_ADD = "TEAM_USER_ADD",
  TEAM_USER_DELETE = "TEAM_USER_DELETE",
  PICTURE = "PICTURE",
  BACKLOG = "BACKLOG",
  PRODUCT_BACKLOG = "PRODUCT_BACKLOG",
  BACKLOG_RESULT_COUNT = "BACKLOG_RESULT_COUNT",
  CHANGE_ITEM_PRIORITY = "CHANGE_ITEM_PRIORITY",
  CHANGE_ITEM_PRIORITY_BATCH = "CHANGE_ITEM_PRIORITY_BATCH",
  GET_PROJECT_STATS = "GET_PROJECT_STATS",
  TEAM_IN_PROJECT = "TEAM_IN_PROJECT",
  GET_EPIC = "GET_EPIC",
  GET_EPICS = "GET_EPICS",
  POST_EPIC = "POST_EPIC",
  UPDATE_EPIC = "UPDATE_EPIC",
  CHANGE_EPIC_PRIORITY = "CHANGE_EPIC_PRIORITY",
  CHANGE_EPIC_PRIORITY_IN_MILESTONE = "CHANGE_EPIC_PRIORITY_IN_MILESTONE",
  MOVE_EPIC_TO_MILESTONE = "MOVE_EPIC_TO_MILESTONE",
  SPRINTS = "SPRINTS",
  SPRINT = "SPRINT",
  SPRINT_ITEMS = "SPRINT_ITEMS",
  SPRINT_NOT_COMPLETED = "SPRINT_NOT_COMPLETED",
  SPRINT_METRICS = "SPRINT_METRICS",
  SPRINT_START = "SPRINT_START",
  SPRINT_COMPLETE = "SPRINT_COMPLETE",
  SPRINT_HALT = "SPRINT_HALT",
  SPRINTS_WITH_STATS = "SPRINTS_WITH_STATS",
  SPRINT_START_REVIEW = "SPRINT_START_REVIEW",
  SPRINT_COMPLETE_REVIEW = "SPRINT_COMPLETE_REVIEW",
  CAN_START_SPRINT = "CAN_START_SPRINT",
  SPRINT_REVIEW = "SPRINT_REVIEW",
  RESOLVE_SPRINT_ID = "RESOLVE_SPRINT_ID",
  ITEM_DEFINITION_OF_DONE = "ITEM_DEFINITION_OF_DONE",
  GET_STORY_POKER = "GET_STORY_POKER",
  CREATE_STORY_POKER = "CREATE_STORY_POKER",
  ASSIGN_EPIC = "ASSIGN_EPIC",
  DELETE_ITEM_FROM_EPIC = "DELETE_ITEM_FROM_EPIC",
  UNASSIGN_EPIC = "UNASSIGN_EPIC",
  RESOLVE_EPIC_ID = "RESOLVE_EPIC_ID",
  METRICS_EPIC = "METRICS_EPIC",
  METRICS_BUG = "METRICS_BUG",
  METRICS_BACKLOG = "METRICS_BACKLOG",
  METRICS_TEAM_BACKLOGS = "METRICS_TEAM_BACKLOGS",
  TODO = "TODO",
  TODO_ITEM = "TODO_ITEM",
  TODO_COUNT = "TODO_COUNT",
  GET_MILESTONE = "GET_MILESTONE",
  GET_MILESTONES = "GET_MILESTONES",
  MILESTONES_PERIOD = "MILESTONES_PERIOD",
  POST_MILESTONE = "POST_MILESTONE",
  POST_MILESTONE_EPIC = "POST_MILESTONE_EPIC",
  DELETE_MILESTONE_EPIC = "DELETE_MILESTONE_EPIC",
  GET_ALL_USERS = "GET_ALL_USERS",
  GET_ALL_USERS_SUPERADMIN = "GET_ALL_USERS_SUPERADMIN",
  USER_BY_ID = "USER_BY_ID",
  USER_ADD_PROJECT = "USER_ADD_PROJECT",
  USER_DEACTIVATION = "USER_DEACTIVATION",
  USER_PERMISSIONS = "USER_PERMISSIONS",
  CHANGE_STATUS_ITEM = "CHANGE_STATUS_ITEM",
  CHANGE_OWNER_ITEM = "CHANGE_OWNER_ITEM",
  PROJECT_OVERVIEW = "PROJECT_OVERVIEW",
  PROJECT_OVERVIEW_SEARCH = "PROJECT_OVERVIEW_SEARCH",
  PROJECT_OVERVIEW_BY_ID = "PROJECT_OVERVIEW_BY_ID",
  SPRINT_WITH_STATS = "SPRINT_WITH_STATS",
  GET_SUBSCRIPTION = "GET_SUBSCRIPTION",
  SET_SUBSCRIPTION = "SET_SUBSCRIPTION",
  GET_SUBSCRIPTION_TOKEN = "GET_SUBSCRIPTION_TOKEN",
  STREAM_SUBSCRIPTION = "STREAM_SUBSCRIPTION",
  FIVE_WHYS = "FIVE_WHYS",
  FIVE_WHYS_BY_ID = "FIVE_WHYS_BY_ID",
  GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES",
  GET_EMAIL_TEMPLATE_TYPE = "GET_EMAIL_TEMPLATES_TYPE",
  POST_EMAIL_TEMPLATE_RENDER = "POST_EMAIL_TEMPLATE_RENDER",
  EMAIL_SEND = "EMAIL_SEND",
  MOVE_TO_ITEM = "MOVE_TO_ITEM",
  ITEM_PROGRESS = "ITEM_PROGRESS",
  SEARCH = "SEARCH",
  SEARCH_ITEM = "SEARCH_ITEM",
  SEARCH_PROJECT = "SEARCH_PROJECT",
  SEARCH_SPRINT = "SEARCH_SPRINT",
  SEARCH_EPIC = "SEARCH_EPIC",
  SEARCH_TEAM = "SEARCH_TEAM",
  CHECK_TEAM_NAME = "CHECK_TEAM_NAME",
  SEARCH_MILESTONE = "SEARCH_MILESTONE",
  SEARCH_LABELS = "SEARCH_LABELS",
  TASKS_LIST = "TASKS_LIST",
  MY_WORK = "MY_WORK",
  GET_SPRINT_NUMBER = "GET_SPRINT_NUMBER",
  LOG_METRIC_PAGELOAD = "LOG_METRIC_PAGELOAD",
  LOG_METRIC_COUNT = "LOG_METRIC_COUNT",
  LOG_METRIC_TIME = "LOG_METRIC_TIME",
  LOG_METRIC = "LOG_METRIC",
  LOG_ERROR = "LOG_ERROR",
  PROJECT_RESOLVE = "PROJECT_RESOLVE",
  UPDATE_ITEM_DETAILS = "UPDATE_ITEM_DETAILS",
  UPDATE_TEAM = "UPDATE_TEAM",
  IMPORT_ITEM_TO_EPIC = "IMPORT_ITEM_TO_EPIC",
  CHANGE_TEAM_FOR_ITEM = "CHANGE_TEAM_FOR_ITEM",
  ARCHIVE_EPIC = "ARCHIVE_EPIC",
  MERGE_EPIC = "MERGE_EPIC",
  ADD_STORY_POKER_ESTIMATION = "ADD_STORY_POKER_ESTIMATION",
  RESTORE_EPIC = "RESTORE_EPIC",
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT",
  NOTIFICATIONS_MARK_AS_READ = "NOTIFICATIONS_MARK_AS_READ",
  SET_NOTIFICATIONS_AS_READ = "SET_NOTIFICATIONS_AS_READ",
  GET_PROJECT_WITH_STATS_TEAM = "GET_PROJECT_WITH_STATS_TEAM",
  GET_PROJECT_WITH_STATS_USER = "GET_PROJECT_WITH_STATS_USER",
  TEAM_CREATE_WITH_USERS = "TEAM_CREATE_WITH_USERS",
  TEAMS_WITH_USERS = "TEAMS_WITH_USERS",
  TEAMS_WITH_USERS_SEARCH = "TEAMS_WITH_USERS_SEARCH",
  TEAMS_SEARCH_ADMIN = "TEAMS_SEARCH_ADMIN",
  TEAM_UPDATE_ADMIN = "TEAM_UPDATE_ADMIN",
  GET_ACCOUNT_SUPERADMIN = "GET_ACCOUNT_SUPERADMIN",
  ACCOUNT_PICTURE = "ACCOUNT_PICTURE",
  COMPLETE_STORY_POKER = "COMPLETE_STORY_POKER",
  TEAM_WITH_EXTENDED_USERS = "TEAM_WITH_EXTENDED_USERS",
  RETROSPECTIVE_COMPLETE = "RETROSPECTIVE_COMPLETE",
  RETROSPECTIVE_POST = "RETROSPECTIVE_POST",
  RETROSPECTIVE_CHANGE_PRIORITY = "RETROSPECTIVE_CHANGE_PRIORITY",
  GET_RETROSPECTIVE = "GET_RETROSPECTIVE",
  GET_RETROSPECTIVE_STATUS = "GET_RETROSPECTIVE_STATUS",
  RETROSPECTIVE_PUT_STACK = "RETROSPECTIVE_PUT_STACK",
  RETROSPECTIVE_POST_STACK = "RETROSPECTIVE_POST_STACK",
  RETROSPECTIVE_DELETE_STACK = "RETROSPECTIVE_DELETE_STACK",
  RETROSPECTIVE_POST_STACK_NOTE = "RETROSPECTIVE_POST_STACK_NOTE",
  RETROSPECTIVE_PUT_NOTE = "RETROSPECTIVE_PUT_NOTE",
  RETROSPECTIVE_REVIEWED = "RETROSPECTIVE_REVIEWED",
  RETROSPECTIVE_NOTES = "RETROSPECTIVE_NOTES",
  RETROSPECTIVE_DELETE_NOTE = "RETROSPECTIVE_DELETE_NOTE",
  GET_RETROSPECTIVE_BY_SPRINT_ID = "GET_RETROSPECTIVE_BY_SPRINT_ID",
  ADMIN_PROJECT_SEARCH = "ADMIN_PROJECT_SEARCH",
  ACCOUNT_CONTACT_INFO = "ACCOUNT_CONTACT_INFO",
  GOAL = "GOAL",
  UPDATE_GOAL = "UPDATE_GOAL",
  CHANGE_GOAL_PRIORITY = "CHANGE_GOAL_PRIORITY",
  ADD_SPECIFIC_FEATURE = "ADD_SPECIFIC_FEATURE",
  FEATURE_GOAL = "FEATURE_GOAL",
  FEATURE = "FEATURE",
  CHANGE_FEATURE_PRIORITY = "CHANGE_FEATURE_PRIORITY",
  ADD_PROJECT_FEATURE = "ADD_PROJECT_FEATURE",
  UPDATE_FEATURE = "UPDATE_FEATURE",
  UPDATE_FEATURE_EPIC = "UPDATE_FEATURE_EPIC",
  USER_PICTURE_UPDATE = "USER_PICTURE_UPDATE",
  USER_SETTINGS = "USER_SETTINGS",
  ADMIN_USER_BY_ID = "ADMIN_USER_BY_ID",
  SUPERADMIN_USER_BY_ID = "SUPERADMIN_USER_BY_ID",
  SPRINT_QUOTE = "SPRINT_QUOTE",
  ITEMS_METRICS = "ITEMS_METRICS",
  CHANGE_MILESTONE_DURATION = "CHANGE_MILESTONE_DURATION",
  GET_MILESTONES_REVIEW = "GET_MILESTONES_REVIEW",
  GET_ACCOUNT_ADMIN = "GET_ACCOUNT_ADMIN",
  PROJECT_OVERVIEW_AT_RISK = "PROJECT_OVERVIEW_AT_RISK",
  EMAIL_VERIFICATION_SEND = "EMAIL_VERIFICATION_SEND",
  EMAIL_VERIFICATION_RESEND = "EMAIL_VERIFICATION_RESEND",
  SPRINT_EXISTS_CONTINUOUS = "SPRINT_EXISTS_CONTINUOUS",
  DEFINITION_OF_DONE = "DEFINITION_OF_DONE",
  METRICS_BY_TYPE = "METRICS_BY_TYPE",
  EDIT_SUMMARY = "EDIT_SUMMARY",
  UPDATE_ITEM_READINESS = "UPDATE_ITEM_READINESS",
  GET_LIST_OF_RETROSPECTIVE = "GET_LIST_OF_RETROSPECTIVE",
  RETROSPECTIVE_START = "RETROSPECTIVE_START",
  CHANGE_TEAM_FOR_ITEMS = "CHANGE_TEAM_FOR_ITEMS",
  LIST_OF_MILESTONE_CHARTS = "LIST_OF_MILESTONE_CHARTS",
  CHANGE_TEAM_FOR_ITEMS_BATCH = "CHANGE_TEAM_FOR_ITEMS_BATCH",
  GET_COMMENT_COUNT = "GET_COMMENT_COUNT",
  CREATE_DEMO_PROJECT = "CREATE_DEMO_PROJECT",
  RECREATE_DEMO_PROJECT = "RECREATE_DEMO_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  SAVE_HEALTH_CHECK = "SAVE_HEALTH_CHECK",
  SEARCH_FEATURE = "SEARCH_FEATURE",
  POST_GOAL = "POST_GOAL",
  SEARCH_GOAL = "SEARCH_GOAL",
  GET_GOAL = "GET_GOAL",
  HEALTH_CHECK_SUMMARY = "HEALTH_CHECK_SUMMARY",
  TODO_ITEM_RESOLVE = "TODO_ITEM_RESOLVE",
  PUT_TODO = "PUT_TODO",
  TODO_ITEM_UNRESOLVE = "TODO_ITEM_UNRESOLVE",
  METRICS_SEVERITY = "METRICS_SEVERITY",
  ATTACHMENT_UPLOAD_TARGET_BY_ACCOUNT = "ATTACHMENT_UPLOAD_TARGET_BY_ACCOUNT",
  ATTACHMENT_UPLOAD_BY_ACCOUNT = "ATTACHMENT_UPLOAD_BY_ACCOUNT",
  ATTACHMENT_BY_ACCOUNT = "ATTACHMENT_BY_ACCOUNT",
  ATTACHMENT_VIDEO_URL = "ATTACHMENT_VIDEO_URL",
  GET_STORY_POKER_URL = "GET_STORY_POKER_URL",
  ACCOUNT_CONTACT_INFO_SUPER_ADMIN = "ACCOUNT_CONTACT_INFO_SUPER_ADMIN",
  SPRINT_CAPACITY = "SPRINT_CAPACITY",
  MY_WORK_STATS = "MY_WORK_STATS",
  MY_WORK_CHANGE_PRIORITY = "MY_WORK_CHANGE_PRIORITY",
  MY_WORK_PRIORITIZED = "MY_WORK_PRIORITIZED",
  SPRINT_TIME_OFFS = "SPRINT_TIME_OFFS",
  CHECK_EXIST_PROJECT_BY_URL_OR_NAME = "CHECK_EXIST_PROJECT_BY_URL_OR_NAME",
  TASKS_ESTIMATION = "TASKS_ESTIMATION",
  JIRA_IMPORT_START = "JIRA_IMPORT_START",
  JIRA_IMPORT_STATUS = "JIRA_IMPORT_STATUS",
  JIRA_IMPORT_USERS_START = "JIRA_IMPORT_USERS_START",
  JIRA_IMPORT_USERS = "JIRA_IMPORT_USERS",
  JIRA_IMPORT_TEST = "JIRA_IMPORT_TEST",
  JIRA_IMPORT_BACKLOG_START = "JIRA_IMPORT_BACKLOG_START",
  JIRA_IMPORT_BACKLOG_FINISH = "JIRA_IMPORT_BACKLOG_FINISH",
  JIRA_IMPORT_BACKLOG_SYNC_ENABLE = "JIRA_IMPORT_BACKLOG_SYNC_ENABLE",
  JIRA_IMPORT_BACKLOG_SYNC_DISABLE = "JIRA_IMPORT_BACKLOG_SYNC_DISABLE",
  GET_ITEM_COMMENT = "GET_ITEM_COMMENT",
  UPDATE_STATUS_DESCRIPTION = "UPDATE_STATUS_DESCRIPTION",
  BACKLOG_ANALYTICS = "BACKLOG_ANALYTICS",
  ADD_TEAMS_TO_PROJECT = "ADD_TEAMS_TO_PROJECT",
  INVITE_TO_TEAM_LINK = "INVITE_TO_TEAM_LINK",
  SPRINT_ANALYTICS = "SPRINT_ANALYTICS",
  SPRINT_ANALYTICS_PERFORMANCE = "SPRINT_ANALYTICS_PERFORMANCE",
  SPRINT_ANALYTICS_QUALITY = "SPRINT_ANALYTICS_QUALITY",
  SPRINT_ANALYTICS_TEAM = "SPRINT_ANALYTICS_TEAM"
}
