import { RouteLocationRaw } from "vue-router"
import { store, Actions, Getters } from "@/store"
import { Team } from "@/models/Team"

export default async (to, from, next: (arg?: RouteLocationRaw) => void): Promise<void | boolean> => {
  if (to.params?.teamId) return next()

  const teams = await store.dispatch(Actions["GET_TEAMS_IN_PROJECT"])
  const teamsInProjectWhoHasUser: Team[] = []
  const currentProfile = store.getters[Getters.GET_USER_CURRENT_PROFILE]

  currentProfile.teams?.forEach((team) => {
    const foundTeam = teams.find(t => t.id === team.id)
    if (foundTeam) teamsInProjectWhoHasUser.push(foundTeam)
  })

  if (!teamsInProjectWhoHasUser.length) {
    return next({ name: "projects" })
  }

  const teamId = teamsInProjectWhoHasUser[0].urlName
  const params = { ...to.params, teamId }

  next({
    ...to,
    params,
  })
}
