<template>
  <CustomModal
    :id="id"
    modalClass="modal-chat-gpt"
    width="80%"
    @onClosed="onClosed"
  >
    <template #header>
      <BaseHeading
        level="3"
      >
        Ask ChatGPT
      </BaseHeading>
    </template>

    <template #body>
      <div
        ref="chatGptItems"
        class="modal-chat-gpt__items-wrapper custom-scroll"
      >
        <div
          v-for="item in chatItems"
          :key="item.id"
          class="modal-chat-gpt__item"
          :class="[{ 'modal-chat-gpt__item--mine': item.mine }]"
        >
          <div class="modal-chat-gpt__avatar">
            <Avatar
              v-if="item.mine"
              :src="user?.pictureUrl ?? ''"
              :gap="user?.getInitials() ?? ''"
            />
            <icon
              v-else
              data="@icon/chat-gpt.svg"
              :fill="true"
              color="white"
              width="22px"
              height="22px"
            />
          </div>
          <ChatGPTResponseContent :content="item.content" />
        </div>
      </div>
      <div class="modal-chat-gpt__spinner-wrapper">
        <Spinner
          v-if="$wait.is('chatgpt.get')"
          :loading="true"
          :size="32"
        />
        <div
          v-else-if="chatItems.length > 0"
        >
          <button @click="resetChatContext">
            Clear History and context
          </button>
        </div>
      </div>
      <div class="bordered-textarea">
        <textarea
          id="promptTextarea"
          v-model="prompt"
          rows="2"
          placeholder="Send a message"
          class="modal-chat-gpt__text-area custom-scroll"
          :maxlength="tokensLeft"
          @keydown.enter.prevent="onPromptInput"
        />
        <icon
          data="@icon/paperplane.svg"
          :fill="true"
          color="#336DFF"
          width="24"
          height="24"
          class="modal-chat-gpt__send-icon"
          :class="[{ 'is-disabled': !prompt }]"
          @click="onPromptInput"
        />
        <BaseSubHeading
          size="extra-small"
          weight="400"
          color="monochrome-05"
          class="custom-modal_halted-sprint_input_lenght"
        >
          {{ prompt.length }}/{{ tokensLeft }}
        </BaseSubHeading>
      </div>
    </template>

    <template #footer>
      <ControlButtons
        :hideSave="true"
        @cancel="hide"
      >
        <template #cancel>
          Hide
        </template>
      </ControlButtons>
    </template>
  </CustomModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"

import Avatar from "@/components/Avatar/index.vue"
import CustomModal from "@/components/Modal/index.vue"
import ControlButtons from "@/components/ControlButtons/index.vue"
import Spinner from "@/components/Spinner/index.vue"
import ChatGPTResponseContent from "./ChatGPTResponseContent.vue"

import { User, ChatGPTItem, ChatGPTResponse } from "@/models"

const DEFAULT_TOKEN_COUNT = 300

@Options({
  name: "ModalChatGPT",
  components: {
    CustomModal,
    ControlButtons,
    Spinner,
    Avatar,
    ChatGPTResponseContent,
  },
})
export default class ModalChatGPT extends Vue {
  @Prop({ default: "modal-chat-gpt" }) id: string

  @Action(Actions.CHAT_GPT_ASK) askChatGPT: (prompt: string) => Promise<ChatGPTResponse>
  @Action(Actions.CHAT_GPT_RESET) resetChatGPTContext: () => Promise<void>
  @Getter(Getters.GET_SESSION_ACCOUNT_ID) accountId: string
  @Getter(Getters.GET_USER_CURRENT_PROFILE) user: User

  @Ref("chatGptItems") readonly chatGptItems!: HTMLElement

  chatItems: ChatGPTItem[] = []
  prompt = ""
  tokensLeft = DEFAULT_TOKEN_COUNT

  async onPromptInput() {
    const value = this.prompt.trim()
    if (!value) {
      return
    }

    this.chatItems.push({
      id: Date.now().toString(),
      content: [[value]],
      mine: true,
    })
    this.prompt = ""

    this.$wait.start("chatgpt.get")
    const { content, tokensLeft } = await this.askChatGPT(value)
    this.tokensLeft = tokensLeft ?? DEFAULT_TOKEN_COUNT

    if (content.length) {
      this.chatItems.push({
        id: Date.now().toString(),
        mine: false,
        content: content,
      })
    }
    this.$wait.end("chatgpt.get")
  }

  hide() {
    this.$modal.hide(this.id)
    this.onClosed()
  }

  onClosed() {
    this.resetChatContext()
    this.prompt = ""
  }

  resetChatContext() {
    this.chatItems = []
    this.resetChatGPTContext()
  }

  @Watch("chatItems.length")
  onChatItemsChange() {
    if (!this.chatGptItems) {
      return
    }

    this.$nextTick(() => {
      this.chatGptItems.scrollTop = this.chatGptItems.scrollHeight
    })
  }
}
</script>
