<template>
  <ReferenceComponentParentList
    :searchList="result"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    :hideFooter="showTeams"
    :hideHeader="!currentTeam"
    class="reference-list_md"
    @onClick="onSprintSelected"
  >
    <template #header>
      <div
        v-if="currentTeam"
        class="reference-team reference-team_heading"
      >
        <div class="reference-team__left">
          <Avatar
            :src="currentTeam.picture?.pictureUrl || null"
            size="md"
          />
        </div>
        <div class="reference-team__right">
          <BaseSubHeading
            weight="400"
            size="medium"
            color="monochrome-07"
          >
            {{ currentTeam.name }} ({{ currentTeam.size }})
          </BaseSubHeading>
        </div>
      </div>
      <div v-else>
        <BaseSubHeading
          weight="600"
          size="medium"
          color="monochrome-06"
        >
          Sprints
        </BaseSubHeading>
      </div>
    </template>

    <template #item="{ option }">
      <div class="reference-list__item-in reference-sprint">
        <SprintHeading
          :sprint="option"
          class="reference-sprint__title"
        />
      </div>
    </template>

    <template #footer>
      <button
        class="reference-list__link"
        @click.prevent="onSelectAnotherTeam"
      >
        Select Another Team
      </button>
    </template>

    <template
      v-if="showTeams"
      #screen
    >
      <ul class="reference-list__list">
        <li
          v-for="(item, index) in teams"
          :key="index"
          class="reference-list__item"
        >
          <button
            class="reference-list__button"
            @click.prevent="onTeamSelect(item)"
          >
            <div class="reference-team">
              <div class="reference-team__left">
                <Avatar
                  :src="item.picture?.pictureUrl || null"
                  size="md"
                />
              </div>
              <div class="reference-team__right">
                <BaseSubHeading
                  weight="400"
                  size="medium"
                  color="monochrome-07"
                >
                  {{ item.name }} ({{ item.size }})
                </BaseSubHeading>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import Avatar from "@/components/Avatar/index.vue"
import { Action, Getter } from "s-vuex-class"
import { Actions, Getters } from "@/store"
import {
  SearchSprintResult,
  FilterTeam,
  Project,
  Item,
  SearchTeamResult,
  ServerResponse,
} from "@/models"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"
import { FilterSprint } from "@/models/Filter/FilterSprint"
import SprintHeading from "@/components/Sprint/SprintHeading.vue"

const components = {
  ReferenceComponentParentList,
  Avatar,
  SprintHeading,
}

@Options({
  name: "SprintList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.GET_PROJECT) declare currentProject: Project
  @Getter(Getters.GET_ITEM) declare currentItem: Item
  @Getter(Getters.GET_SESSION_PROJECT_ID) readonly sessionProjectId: string

  @Action(Actions.SEARCH_TEAM) getTeams: (params: FilterTeam) => Promise<SearchTeamResult[]>
  @Action(Actions.SEARCH_SPRINT) searchSprint: ({ filter, onlyGet } : { filter: FilterSprint, onlyGet?: boolean }) => Promise<ServerResponse<SearchSprintResult>>

  waitTitle = "reference.search.sprint"
  componentType = ReferenceType.SPRINT
  showTeams = false
  currentTeam: SearchTeamResult | null = null
  teams: SearchTeamResult[] = []
  result: SearchSprintResult[] | null = []

  filterSprints = FilterSprint.createDefault()
  filterTeam = FilterTeam.createDefault()

  get teamId() {
    return this.currentItem?.team?.id ?? this.currentTeam?.id ?? ""
  }

  async created() {
    this.$wait.start("referenceSprintSearch")
    this.filterTeam.demo = this.currentProject?.demo ?? false
    this.filterTeam.projectId = this.sessionProjectId
    this.teams = await this.getTeams(this.filterTeam)
    this.currentTeam = this.teams.find(team => team.id === this.teamId) || null
    this.searchAction = this.onSearchSprint
    await this.onSearchSprint()
    this.$wait.end("referenceSprintSearch")
  }

  onSelectAnotherTeam() {
    this.currentTeam = null
    this.onChangeTeam()
  }

  onChangeTeam() {
    this.showTeams = !this.showTeams
  }

  queryUpdated(q) {
    this.filterSprints.q = q.substring(1)
    this.search()
  }

  onEnterHandler() {
    if (this.result) {
      this.onSprintSelected(this.result[this.selectedIndex])
    }
  }

  onSprintSelected(payload) {
    this.selectItem({
      item: Object.assign({}, payload, {
        team: this.currentTeam,
      }),
      payload: {},
    })
  }

  onTeamSelect(team) {
    this.currentTeam = team
    this.onChangeTeam()
    this.search()
  }

  async onSearchSprint() {
    if (!this.currentTeam) {
      return
    }
    this.result = null
    this.filterSprints.teamId = this.currentTeam.id
    this.filterSprints.projectId = this.currentProject.id
    const serverResponse = await this.searchSprint({
      filter: this.filterSprints,
      onlyGet: true,
    })
    this.result = serverResponse.content
    this.selectedIndex = 0
  }
}
</script>
