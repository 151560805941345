<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-grey">
        <icon
          data="@icon/backlog-icon.svg"
          class="label-pic__icon"
          :fill="true"
          width="24px"
          height="24px"
          color="#616161"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Unprioritized Backlog Item has been added
      </BaseSubHeading>
    </template>
    <template #content>
      <BaseSubHeading
        size="medium"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="400"
        className="notification__unprioritized-add-item notification__item-content"
      >
        <a
          class="link is-medium-500"
          target="_blank"
          :href="notification.itemUrl"
        >
          {{ notification.itemSequenceNumber }}
        </a>
        <span class="notification__unprioritized-add-item__title notification__item-content__title">
          {{ notification.getItemValue('title') }}
        </span>
      </BaseSubHeading>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import { NotificationItem } from "@/models/Notification/NotificationItem"

@Options({
  name: "UNPRIORITIZED_BACKLOG_ADD_ITEM",
  components: {
    NotificationTemplate,
  },
})
export default class UNPRIORITIZED_BACKLOG_ADD_ITEM extends Vue {
  @Prop() readonly notification: NotificationItem
}
</script>
