import { Action, Module, Mutation } from "vuex-class-modules"

import { apiService } from "@/services/api.service"
import { Endpoint } from "@/services/endpoints"
import fileSaver from "@/utils/fileSaver"
import { projectModule } from "./project.module"
import {
  FileMetaData,
  UploadAttachment,
  UploadAttachmentTarget,
  UploadQueryFile,
} from "@/models/File"
import { ServerResponse } from "@/models/ServerResponse"
import { store } from "../store"
import { Modules } from "../modules"
import { BaseModule } from "@/models/BaseModule"
import { HttpError } from "@/models/Error"
import { AlertContentItem, AlertType } from "@/models/Alert"
import { AlertActions, alertModule } from "./alert.module"
import { config } from "@/app.config"
import { accountModule } from "./account.module"
import { getToken } from "@/plugins/keycloak"

export enum FileActions {
  ATTACHMENT_UPLOAD = "ATTACHMENT_UPLOAD",
  ATTACHMENT_UPLOAD_TARGET = "ATTACHMENT_UPLOAD_TARGET",
  ATTACHMENT_VIEW = "ATTACHMENT_VIEW",
  ATTACHMENT_VIDEO_URL = "ATTACHMENT_VIDEO_URL",
  ATTACHMENT_DOWNLOAD = "ATTACHMENT_DOWNLOAD",
  DELETE_FILE_FROM_TARGET="DELETE_FILE_FROM_TARGET",
  DELETE_FILE = "DELETE_FILE",
  ADD_FILE_BY_ACCOUNT = "ADD_FILE_BY_ACCOUNT",
}

@Module
class FileModule extends BaseModule {
  files: ServerResponse<FileMetaData>
  attachments: FileMetaData[]
  fileByAccount = false

  get projectId() {
    return projectModule.currentProjectId
  }

  get accountId() {
    return accountModule.accountId
  }

  get [`get/${FileActions.ATTACHMENT_UPLOAD}`]() {
    return this.attachments?.map(file => file.id)
  }

  @Mutation
  setAttachment(files: FileMetaData[]) {
    this.attachments = files
  }

  @Mutation
  toggleFileByAccount(fileByAccount: boolean) {
    this.fileByAccount = fileByAccount
  }

  @Action
  async [FileActions.ATTACHMENT_UPLOAD]({ attachments, onUploadProgress }: UploadAttachment) {
    const file = new FormData()
    for (let i = 0; i < attachments.length; i++) {
      file.append("file", attachments[i])
    }
    const endPoint = this.fileByAccount ? Endpoint.ATTACHMENT_UPLOAD_BY_ACCOUNT(this.accountId) : Endpoint.ATTACHMENT_UPLOAD(this.projectId)
    const data = await apiService.post<FileMetaData[]>(endPoint, file, {
      onUploadProgress,
    })
    return this.handleResponse<FileMetaData[]>(data, (data) => {
      const files = data.map(file => FileMetaData.create(file))
      this.setAttachment(files)

      return files
    })
  }

  @Action
  async [FileActions.ATTACHMENT_UPLOAD_TARGET]({ attachments, resourceType, targetId, onUploadProgress, excludeDuplicate } : UploadAttachmentTarget) {
    const file = new FormData()
    const endPoint = this.fileByAccount ? Endpoint.ATTACHMENT_UPLOAD_TARGET_BY_ACCOUNT(this.accountId, resourceType, targetId) : Endpoint.ATTACHMENT_UPLOAD_TARGET(this.projectId, resourceType, targetId)
    for (let i = 0; i < attachments.length; i++) {
      file.append("file", attachments[i])
    }

    const queryFile: UploadQueryFile = {}

    if (excludeDuplicate !== undefined) {
      queryFile.params = {
        excludeDuplicate: excludeDuplicate,
      }
    }

    if (onUploadProgress) {
      queryFile.onUploadProgress = onUploadProgress
    }

    const data = await apiService.post<FileMetaData[]>(endPoint, file, queryFile)

    if (data instanceof HttpError && data.response.status === 413) {
      const alertContent: AlertContentItem[] = [
        { text: "File size limit exceeded: ", type: "regular" },
        {
          text: `${Number(config.VUE_APP_UPLOAD_MAX_FILE_SIZE_KB) / 1024} mb`,
          type: "bold",
        },
      ]
      alertModule[AlertActions.SHOW_ALERT]({
        type: AlertType.ERROR,
        theme: "toast",
        content: alertContent,
      })

      return false
    }

    return this.handleResponse<FileMetaData[]>(data, (data) => {
      const files = data.map(file => FileMetaData.create(file))
      this.setAttachment(files)

      return files
    }, true)
  }

  @Action
  async [FileActions.ATTACHMENT_VIEW]({ fileMetadataId } : { fileMetadataId: string }) {
    const endPoint = this.fileByAccount ? Endpoint.ATTACHMENT_BY_ACCOUNT(this.accountId, fileMetadataId) : Endpoint.ATTACHMENT_VIEW(this.projectId, fileMetadataId)
    const data = await apiService.getBlob<string>(endPoint)
    return this.handleResponse<string>(data, async (data) => {
      return data
    },
    true,
    )
  }

  @Action
  async [FileActions.ATTACHMENT_DOWNLOAD]({ fileMetadataId, fileName } : { fileMetadataId: string, fileName: string }) {
    const data = await this[FileActions.ATTACHMENT_VIEW]({
      fileMetadataId,
    })

    await fileSaver(data, fileName)
  }

  @Action
  async [FileActions.DELETE_FILE_FROM_TARGET]({ fileMetadataId, resourceType, targetId }:{fileMetadataId:string, resourceType:string,
  targetId:string}) {
    const data = await apiService.delete<string>(Endpoint.DELETE_FILE_FROM_TARGET(this.projectId, fileMetadataId, resourceType, targetId))
    return this.handleResponse<string>(data)
  }

  @Action
  async [FileActions.DELETE_FILE](fileMetadataId) {
    const endPoint = this.fileByAccount ? Endpoint.ATTACHMENT_BY_ACCOUNT(this.accountId, fileMetadataId) : Endpoint.DELETE_FILE(this.projectId, fileMetadataId)
    const data = await apiService.delete<string>(endPoint)
    return this.handleResponse<string>(data)
  }

  @Action
  [FileActions.ADD_FILE_BY_ACCOUNT](fileByAccount: boolean) {
    this.toggleFileByAccount(fileByAccount)
  }

  @Action
  async [FileActions.ATTACHMENT_VIDEO_URL]({ projectId, attachmentId }:{projectId:string, attachmentId:string}) {
    const accessToken = getToken() || ""
    return Endpoint.ATTACHMENT_VIDEO_URL(projectId, attachmentId, accessToken).externalUrl
  }
}

export const fileModule = new FileModule({ store, name: Modules.FILE })
