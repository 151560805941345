import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@icon/story-poker-notification.svg'


const _hoisted_1 = { class: "label-pic is-grey" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_BaseSubHeading = _resolveComponent("BaseSubHeading")!
  const _component_NotificationTemplate = _resolveComponent("NotificationTemplate")!

  return (_openBlock(), _createBlock(_component_NotificationTemplate, { notification: _ctx.notification }, {
    pic: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          data: _imports_0,
          class: "label-pic__icon",
          width: "24px",
          height: "24px"
        })
      ])
    ]),
    title: _withCtx(() => [
      _createVNode(_component_BaseSubHeading, {
        size: "large",
        color: "monochrome-06",
        darkColor: "monochrome-03",
        weight: "500"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Start Story Point Voting now! ")
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.notification.url,
        class: "link is-small-400 is-ellipsis"
      }, " Story #" + _toDisplayString(_ctx.notification.sequenceNumber) + " Summary: " + _toDisplayString(_ctx.notification.title), 9, _hoisted_2)
    ]),
    _: 1
  }, 8, ["notification"]))
}