<template>
  <NotificationTemplate
    :notification="notification"
  >
    <template #pic>
      <div class="label-pic is-grey">
        <icon
          data="@icon/story-poker-notification.svg"
          class="label-pic__icon"
          width="24px"
          height="24px"
        />
      </div>
    </template>
    <template #title>
      <BaseSubHeading
        size="large"
        color="monochrome-06"
        darkColor="monochrome-03"
        weight="500"
      >
        Start Story Point Voting now!
      </BaseSubHeading>
    </template>
    <template #content>
      <a
        :href="notification.url"
        class="link is-small-400 is-ellipsis"
      >
        Story #{{ notification.sequenceNumber }} Summary:
        {{ notification.title }}
      </a>
    </template>
  </NotificationTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import {
  NotificationStoryPoker,
} from "@/models/Notification/NotificationStoryPoker"

@Options({
  name: "STORY_POKER_CREATED",
  components: {
    NotificationTemplate,
  },
})
export default class STORY_POKER_CREATED extends Vue {
  @Prop() readonly notification: NotificationStoryPoker
}
</script>
