<template>
  <NodeViewWrapper
    as="div"
    draggable="false"
    @click="onSelectNode"
  >
    <Attachment
      :node="node"
      :editor="editor"
      :updateAttributes="updateAttributes"
      :getPos="getPos"
    />
  </NodeViewWrapper>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import { Options, Vue } from "vue-class-component"
import { NodeViewWrapper } from "@tiptap/vue-3"
import Attachment from "@/components/Attachment/index.vue"
import { Editor } from "@tiptap/core"
import { Node as ProseMirrorNode } from "@tiptap/pm/model"

@Options({
  name: "EditorAttachment",
  components: {
    NodeViewWrapper,
    Attachment,
  },
})
export default class EditorAttachment extends Vue {
  @Prop() node: ProseMirrorNode
  @Prop() editor: Editor
  @Prop() updateAttributes: (attributes: Record<string, any>) => void
  @Prop() getPos: () => number

  onSelectNode() {
    this.editor.commands.selectParentNode()
  }
}
</script>
