<template>
  <SprintRetrospectiveRequested
    v-if="notification.reminder"
    :notification="notification"
  />

  <SprintRetrospectiveToDo
    v-else
    :notification="notification"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import NotificationTemplate from "@/components/Notification/NotificationTemplate.vue"
import { Prop } from "vue-property-decorator"
import {
  NotificationRetrospective,
} from "@/models/Notification/NotificationRetrospective"
import SprintRetrospectiveRequested from "@/components/Notification/NotificationByType/SPRINT_RETROSPECTIVE_REQUESTED.vue"
import SprintRetrospectiveToDo from "@/components/Notification/NotificationByType/SPRINT_RETROSPECTIVE_TODO.vue"

@Options({
  name: "SPRINT_RETROSPECTIVE",
  components: {
    NotificationTemplate,
    SprintRetrospectiveRequested,
    SprintRetrospectiveToDo,
  },
})
export default class SPRINT_RETROSPECTIVE extends Vue {
  @Prop() readonly notification: NotificationRetrospective
}
</script>
