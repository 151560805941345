<template>
  <ReferenceComponentParentList
    :searchList="list"
    :componentType="componentType"
    :selectedIndex="selectedIndex"
    @onClick="onProjectSelected"
  >
    <template #header>
      <BaseSubHeading
        weight="600"
        size="medium"
        color="monochrome-06"
      >
        Projects
      </BaseSubHeading>
    </template>

    <template #item="{ option }">
      <BaseSubHeading
        weight="400"
        size="medium"
        color="monochrome-06"
        className="reference-list__item-in"
      >
        {{ option.name }}
      </BaseSubHeading>
    </template>
  </ReferenceComponentParentList>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component"
import ReferenceComponentParentList from "@/components/Reference/helpers/ReferenceComponentParentList.vue"
import ReferenceComponentListCommon from "@/components/Reference/helpers/ReferenceComponentListCommon"
import { Getter, Action } from "s-vuex-class"
import { Getters, Actions } from "@/store"
import { SearchProjectResult } from "@/models"
import { ReferenceType } from "@/plugins/extensions/ReferenceType"


const components = {
  ReferenceComponentParentList,
}

@Options({
  name: "ProjectList",
  components,
})
export default class extends mixins(ReferenceComponentListCommon) {
  @Getter(Getters.SEARCH_RESOURCE) declare result: SearchProjectResult[]

  @Action(Actions.SEARCH_PROJECT) findProjects: (query) => Promise<void>

  componentType = ReferenceType.PROJECT
  waitTitle = "reference.search.project"
  filter = ""

  get list() {
    return this.result?.length > 0 ? this.result : []
  }

  async mounted() {
    this.searchAction = this.findProjects
  }

  queryUpdated(q) {
    this.filter = q.substring(1) ?? ""
    this.search()
  }

  onEnterHandler() {
    this.onProjectSelected(this.result[this.selectedIndex])
  }

  onProjectSelected(payload: SearchProjectResult) {
    this.selectItem({
      item: payload,
      payload: {},
    })
  }
}
</script>
