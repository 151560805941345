<template>
  <div
    v-if="editor"
    :id="id"
    class="custom-editor"
    :class="{'custom-editor_read': typeRead }"
    :type="type"
  >
    <EditorMenuBar
      v-if="!typeRead && !taskInput"
      :id="id"
      :editor="editor"
      :taskEditor="taskEditor"
      :targetConfig="targetConfig"
      class="custom-editor__header"
      @focus.stop
    />

    <div class="custom-editor__content">
      <div
        class="custom-editor__content-in"
      >
        <EditorContent
          :editor="editor"
          class="custom-editor__in"

        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Emit, Prop, Watch } from "vue-property-decorator"

import { Editor, EditorContent, Extension } from "@tiptap/vue-3"
import StarterKit from "@tiptap/starter-kit"

import TextAlign from "@tiptap/extension-text-align"
import Link from "@tiptap/extension-link"
import Placeholder from "@tiptap/extension-placeholder"
import Underline from "@tiptap/extension-underline"
import TextStyle from "@tiptap/extension-text-style"
import Table from "@tiptap/extension-table"
import TableRow from "@tiptap/extension-table-row"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"
import { Attachment } from "@/plugins/extensions/Attachment"
import { CustomHeader } from "@/plugins/extensions/Header"
import { Color } from "@/plugins/extensions/Color"
import {
  StarterKitCustomHeading,
} from "@/plugins/extensions/StarterKitCustomHeading"
import EditorMenuBar from "@/components/Editor/EditorMenuBar.vue"
import { Options, Vue } from "vue-class-component"
import {
  FileMetaData,
  TypeConfig,
  UploadAttachment,
  UploadAttachmentTarget,
} from "@/models"
import { Actions, Getters } from "@/store"
import { Action, Getter } from "s-vuex-class"
import { handleMouseScroll } from "@/utils/scrollUtils"
import { EditorData } from "@/models/EditorData"
import EditorAttachment from "./EditorAttachment.vue"
import { InitComponent } from "../Reference"

@Options({
  name: "EditorComponent",
  components: {
    EditorContent,
    EditorMenuBar,
  },
})
export default class EditorComponent extends Vue {
  @Prop({
    type: String,
    default: "",
  }) readonly id!: string
  @Prop({
    type: String,
    default: "",
  }) value: string
  @Prop({ default: null }) targetConfig: TypeConfig | null
  @Prop() file: { url: string, type: string }
  @Prop({ default: "edit" }) readonly type: string
  @Prop({ default: "" }) readonly placeholder: string
  @Prop({ default: false }) readonly focusEditor: boolean
  @Prop({ default: false }) readonly taskEditor: boolean
  @Prop({ default: false }) readonly taskInput: boolean
  @Prop({ default: false }) readonly addSpace: boolean
  @Prop({ default: true }) readonly isAttachmentResizable: boolean
  @Prop({ default: () => [] }) readonly extensions: []
  @Prop({ default: false }) readonly triggerHandleInputOnMount: boolean

  editor: Editor | null = null
  @Getter(Getters.ATTACHMENT_UPLOAD) attachmentIds: string[]

  @Action(Actions.ATTACHMENT_UPLOAD) attachmentUpload: (params: UploadAttachment) => FileMetaData[]
  @Action(Actions.ATTACHMENT_UPLOAD_TARGET) attachmentUploadTarget: (params: UploadAttachmentTarget) => FileMetaData[]

  customKeyboardShortcuts = Extension.create({})
  editorData: EditorData

  mounted() {
    if (this.focusEditor) {
      this.$nextTick(() => this.focus())
    }
    if (this.taskInput) {
      this.customKeyboardShortcuts = Extension.create({
        addKeyboardShortcuts() {
          return {
            "Enter": () => true,
            "ArrowDown": () => true,
            "ArrowUp": () => true,
          }
        },
      })
    }
    this.init()
  }

  @Watch("focusEditor")
  focus() {
    if (this.focusEditor) {
      this.editor?.commands.focus("end")
    }
  }

  init() {
    this.editorData = new EditorData({
      "USER": InitComponent,
      "PROJECT": InitComponent,
      "EPIC": InitComponent,
      "MILESTONE": InitComponent,
      "TEAM": InitComponent,
      "SPRINT": InitComponent,
      "ITEM": InitComponent,
      "TASK": InitComponent,
      "ATTACHMENT": EditorAttachment,
    })
    this.editor = new Editor({
      extensions: [
        StarterKitCustomHeading.configure({
          levels: [1, 2],
        }),
        StarterKit.configure({
          code: false,
          heading: false,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Link.configure({
          validate: href => /^https?:\/\//.test(href),
        }),
        Underline,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        this.customKeyboardShortcuts,
        TextStyle,
        Color,
        Attachment(this.onAttachmentUpload() as any, this.targetConfig, this.isAttachmentResizable),
        CustomHeader,
        ...this.extensions,
      ],
      content: this.value,
      onCreate: () => {
        if (this.triggerHandleInputOnMount) {
          this.handleInput({ isCreating: true })
        }
      },
      onUpdate: () => {
        this.handleInput({ isCreating: false })
      },
      onFocus: (event) => {
        this.$emit("focus", {
          event: event,
        })
      },
      onBlur: (event) => {
        this.$emit("blur", {
          event: event,
        })
      },
      editorProps: {
        attributes: {
          class: "custom-editor__prose-mirror custom-scroll",
        },
      },
    })
    if (this.typeRead) {
      this.editor.setEditable(false)
    }
    this.editor.view.dom.addEventListener("dragover", this.handleDrag)
  }

  onAttachmentUpload() {
    return this.targetConfig !== null ? this.onAttachmentUploadTarget : this.onAttachmentUploadWithoutTarget
  }

  async onAttachmentUploadTarget(params) {
    const attachments = await this.attachmentUploadTarget(params)
    this.uploadedAttachment(attachments)
    return attachments
  }

  async onAttachmentUploadWithoutTarget(params) {
    const attachments = await this.attachmentUpload(params)
    this.uploadedAttachment(attachments)
    return attachments
  }

  @Emit()
  uploadedAttachment(attachments: FileMetaData[]) {
    return attachments
  }

  handleDrag(event) {
    if (!this.editor) return
    const container = this.editor.view.dom
    handleMouseScroll({
      event,
      container,
      threshold: 100,
      maxScrollSpeed: 20,
    })
  }

  get typeRead(): boolean {
    return this.type === "read"
  }

  beforeUnmount() {
    if (!this.editor) return

    this.editor.view.dom.removeEventListener("dragover", this.handleDrag)
    this.editor.destroy()
  }

  handleInput({ isCreating }) {
    if (!this.editor) {
      return
    }
    this.editorData.setText(this.editor.getText())
    this.editorData.setJson(this.editor.getJSON())
    this.editorData.setHtml(this.editor.getHTML())

    if (isCreating || !this.attachmentIds?.length && this.$wait.is("get.attachment")) {
      this.$emit("createData", this.editorData)
    } else {
      this.$emit("handleInput", this.editorData)
    }
  }
}
</script>
